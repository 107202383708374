import React from "react";

const Globe = (props) => {
  return (
    <svg
      width="565"
      height="548"
      viewBox="0 0 565 548"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="globe3">
        <g id="globe-circle">
          <g clip-path="url(#clip0_352_87)">
            <rect
              x="25.7012"
              y="40.3906"
              width="521.803"
              height="507"
              rx="253.5"
              fill="#3DB6FB"
            />
            <g id="gc-white-shade" filter="url(#filter0_f_352_87)">
              <rect
                x="-56.2988"
                y="-85.6094"
                width="310"
                height="259"
                rx="129.5"
                fill="white"
              />
            </g>
          </g>
        </g>
        <g id="globe-map" clip-path="url(#clip1_352_87)">
          <path
            id="Vector"
            d="M509.485 419.78C508.963 420.588 508.545 421.455 508.24 422.362C508.611 422.293 510.801 418.575 510.995 417.672C510.73 417.672 509.538 419.711 509.485 419.797"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2"
            d="M517.484 401.348C517.025 402.277 516.345 403.869 516.46 404.162C516.884 404.248 518.941 400.091 518.88 399.283C518.888 399.257 518.888 399.23 518.88 399.205C518.624 399.205 517.908 400.565 517.52 401.348"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3"
            d="M516.725 409.144C516.327 409.927 515.559 411.485 515.453 411.82C516.372 410.269 517.189 408.662 517.899 407.01C517.462 407.712 517.066 408.439 516.716 409.187"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_4"
            d="M428.28 87.3773C429.163 87.9625 429.975 88.5735 430.858 89.0984C430.745 88.9968 430.621 88.9073 430.487 88.8316L426.682 86.25L428.28 87.3171"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_5"
            d="M444.661 99.1839C445.429 99.7862 446.189 100.397 446.957 100.991L444.043 98.6934L444.661 99.1839Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_6"
            d="M529.652 378.664C529.22 379.946 528.699 381.452 528.619 381.659L530.332 376.642C530.111 377.321 529.882 377.993 529.652 378.664Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_7"
            d="M535.233 358.819C535.021 359.68 534.597 361.358 534.57 361.702C535.136 359.957 535.596 358.181 535.948 356.384V356.332C535.614 357.133 535.371 357.967 535.224 358.819"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_8"
            d="M541.413 325.318C541.351 325.851 541.201 327.211 541.068 328.347L541.598 323.554L541.413 325.318Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_9"
            d="M524.573 390.109C524.163 391.063 523.81 392.04 523.514 393.034V393.077C524.391 391.481 525.087 389.796 525.589 388.052V388.009C525.191 388.687 524.849 389.395 524.564 390.126"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_10"
            d="M515.012 410.056C514.512 410.943 514.092 411.87 513.758 412.827V412.87H513.802C514.83 411.447 515.644 409.888 516.221 408.241V408.163C515.724 408.721 515.319 409.351 515.021 410.03"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_11"
            d="M521.529 399.179C521.485 399.274 520.417 401.64 520.293 402.062C520.452 401.915 522.147 398.077 522.439 397.294V397.217C522.079 397.851 521.766 398.51 521.503 399.187"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_12"
            d="M522.234 391.426C521.802 392.424 521.21 394.007 521.351 394.257C521.758 394.257 523.48 390.333 523.471 389.386C523.471 389.26 523.391 389.286 523.232 389.464C522.84 390.087 522.506 390.744 522.234 391.426Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_13"
            d="M430.664 89.4421C429.563 88.5333 428.398 87.7022 427.176 86.9551C428.353 87.7755 429.516 88.6045 430.664 89.4421Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_14"
            d="M528.567 380.024C528.21 380.957 527.915 381.911 527.684 382.881C528.429 381.279 529.02 379.614 529.45 377.907C529.454 377.881 529.454 377.855 529.45 377.829C529.081 378.524 528.785 379.254 528.567 380.006"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_15"
            d="M540.779 329.715C540.779 329.715 540.426 332.461 540.426 332.839C540.558 332.478 541.07 328.373 541.132 327.495V327.409C541.07 327.538 540.806 329.5 540.779 329.724"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_16"
            d="M528.636 377.244C528.241 378.194 527.971 379.188 527.832 380.204C528.265 379.954 529.598 375.944 529.598 375.204C529.606 375.176 529.606 375.146 529.598 375.118C529.172 375.777 528.848 376.493 528.636 377.244Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_17"
            d="M528.195 382.484C527.913 383.242 527.162 385.333 527.127 385.548C527.277 385.358 528.681 381.486 528.937 380.608V380.557C528.621 381.177 528.367 381.825 528.178 382.493"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_18"
            d="M526.571 381.048C526.059 382.373 525.626 383.81 525.776 383.991C526.227 383.991 527.719 379.688 527.657 379.017C527.665 378.989 527.665 378.959 527.657 378.931C527.436 378.879 526.774 380.54 526.597 381.048"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_19"
            d="M525.828 389.05C525.149 390.771 524.751 391.804 524.707 391.959C524.822 391.761 526.305 387.983 526.65 387.028C526.647 387.005 526.647 386.982 526.65 386.959C526.65 387.045 526.579 387.105 525.811 389.05"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_20"
            d="M532.178 369.439C531.86 370.399 531.597 371.376 531.393 372.365C532.047 370.711 532.573 369.011 532.964 367.279V367.219C532.626 367.94 532.363 368.692 532.178 369.465"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_21"
            d="M533.624 365.376C533.394 366.185 532.926 367.863 532.803 368.406V368.457C532.988 368.147 534.118 364.034 534.269 363.346C534.269 363.346 534.269 363.294 534.269 363.26C534.269 363.225 534.163 363.5 533.624 365.368"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_22"
            d="M450.037 103.495L451.901 105.044L449.967 103.435L450.037 103.495Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_23"
            d="M520.009 400.246C519.522 401.146 519.149 402.099 518.896 403.086C519.276 402.905 520.998 399.102 521.139 398.301C521.148 398.279 521.148 398.255 521.139 398.232C520.706 398.87 520.336 399.548 520.036 400.255"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_24"
            d="M514.578 412.586C514.225 413.266 513.422 414.824 513.254 415.34V415.409C514.212 413.954 515.031 412.415 515.7 410.814C515.7 410.814 515.7 410.762 515.7 410.736C515.267 411.324 514.886 411.945 514.561 412.595"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_25"
            d="M515.021 407.578C514.483 408.611 513.759 410.203 513.909 410.366C514.333 410.366 516.364 406.433 516.355 405.685C516.363 405.657 516.363 405.627 516.355 405.599C516.116 405.599 515.224 407.242 515.048 407.578"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_26"
            d="M512.214 411.227C511.41 412.69 510.942 413.929 511.207 413.877C511.649 413.782 513.671 410.246 513.706 409.247C513.662 409.032 513.415 409.247 512.991 409.91C512.682 410.375 512.373 410.934 512.214 411.227Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_27"
            d="M513.695 415.297C513.642 415.392 512.397 417.715 512.344 417.931C512.547 417.741 514.507 414.058 514.816 413.335V413.258C514.375 413.906 513.988 414.588 513.659 415.297"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_28"
            d="M522.171 396.529C521.736 397.46 521.352 398.414 521.023 399.386V399.437C521.881 397.897 522.594 396.284 523.152 394.618V394.532C523.152 394.532 523.001 394.601 522.154 396.503"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_29"
            d="M522.419 393.896C521.945 394.842 521.595 395.842 521.377 396.873C521.704 396.804 523.426 392.889 523.496 392.063C523.501 392.037 523.501 392.011 523.496 391.985C523.053 392.57 522.696 393.214 522.437 393.896"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_30"
            d="M431.108 89.4595C431.108 89.4595 433.421 91.0946 433.633 91.1806C432.375 90.1903 431.066 89.2626 429.712 88.4011L429.615 88.3408C430.087 88.7522 430.592 89.1263 431.125 89.4595"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_31"
            d="M433.932 91.2495C434.735 91.8175 435.521 92.4199 436.351 92.9706L436.21 92.8587L432.404 90.1738L433.932 91.2581"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_32"
            d="M521.643 388.836C521.06 390.221 520.628 391.71 520.945 391.615C521.422 391.486 523.171 387.596 522.985 386.736C522.835 386.357 522.288 387.416 521.979 388.078C521.873 388.293 521.731 388.612 521.643 388.836Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_33"
            d="M533.864 362.683C533.572 363.668 533.334 364.668 533.148 365.678C533.449 365.497 534.552 361.211 534.597 360.583C534.601 360.555 534.601 360.526 534.597 360.497C534.288 361.189 534.042 361.906 533.864 362.64"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_34"
            d="M509.642 417.13C509.099 417.986 508.669 418.905 508.362 419.866C508.359 419.886 508.359 419.907 508.362 419.927C508.839 419.858 511.082 415.994 511.099 415.228C511.104 415.205 511.104 415.182 511.099 415.159C510.826 415.159 509.775 416.923 509.651 417.13"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_35"
            d="M495.027 403.551C494.197 405.117 494.082 406.468 494.789 406.287C495.946 405.978 497.932 402.467 497.129 401.58C496.634 401.193 495.698 402.286 495.027 403.551Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_36"
            d="M523.258 395.496C522.851 396.434 522.454 397.372 522.057 398.31V398.258L524.167 393.275C523.867 394.015 523.549 394.756 523.24 395.496"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_37"
            d="M524.099 392.82C523.79 393.551 523.013 395.479 522.986 395.694C523.786 394.069 524.488 392.399 525.088 390.694C524.718 391.4 524.385 392.124 524.09 392.863"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_38"
            d="M524.549 387.622C524.134 388.523 523.837 389.471 523.666 390.444C524.125 390.246 525.697 386.236 525.741 385.384C525.741 385.384 525.741 385.333 525.741 385.315C525.244 386.028 524.849 386.804 524.567 387.622"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_39"
            d="M524.276 384.757C523.855 385.706 523.559 386.704 523.393 387.725C523.499 387.992 524.09 386.925 524.47 386.056C524.965 384.99 525.312 383.864 525.503 382.708C525.492 382.684 525.477 382.661 525.459 382.64C525.212 382.683 524.62 383.896 524.276 384.748"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_40"
            d="M530.518 373.328C530.179 374.291 529.916 375.277 529.732 376.28C530.068 376.047 531.357 372.028 531.498 371.116V371.073C531.14 371.811 530.845 372.577 530.615 373.362"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_41"
            d="M517.617 403.912C517.142 404.801 516.772 405.739 516.514 406.709C516.964 406.58 518.818 402.699 518.942 401.847V401.804C518.712 401.804 517.847 403.465 517.626 403.912"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_42"
            d="M530.305 375.952C530.084 376.615 529.422 378.603 529.369 378.964C530.066 377.28 530.656 375.556 531.135 373.801C531.144 373.776 531.144 373.749 531.135 373.724C531.02 373.904 530.526 375.341 530.323 375.952"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_43"
            d="M517.36 406.537C516.906 407.418 516.507 408.327 516.168 409.256C517.144 407.727 517.955 406.103 518.587 404.411C518.124 405.108 517.711 405.835 517.351 406.588"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_44"
            d="M526.43 386.486C526.054 387.401 525.73 388.334 525.459 389.283C526.207 387.694 526.839 386.055 527.349 384.378C527.353 384.35 527.353 384.321 527.349 384.292C526.972 385.001 526.653 385.738 526.395 386.495"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_45"
            d="M512.187 416.114C511.689 416.978 511.25 417.872 510.871 418.791V418.851C511.251 418.705 513.255 415.013 513.441 414.264C513.446 414.241 513.446 414.218 513.441 414.195C512.974 414.796 512.564 415.435 512.213 416.106"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_46"
            d="M518.702 405.461C517.961 407.001 517.528 407.922 517.422 408.189C517.554 408.043 519.382 404.179 519.682 403.482C519.685 403.456 519.685 403.43 519.682 403.404C519.682 403.473 519.585 403.568 518.676 405.461"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_47"
            d="M519.966 397.82C519.587 398.68 518.907 400.272 519.083 400.479C519.445 400.479 521.318 396.451 521.309 395.668C521.314 395.643 521.314 395.616 521.309 395.591C521.061 395.539 520.117 397.587 520.011 397.82"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_48"
            d="M539.894 333.923C539.717 334.929 539.599 335.943 539.541 336.961C539.961 335.201 540.238 333.411 540.371 331.609C540.375 331.58 540.375 331.551 540.371 331.522C540.152 332.31 539.995 333.113 539.903 333.923"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_49"
            d="M514.701 404.971C514.233 405.876 513.885 406.836 513.668 407.828C514.154 407.888 516.149 404.033 516.096 403.138C516.026 402.811 515.496 403.602 515.337 403.843C515.01 404.36 514.745 404.876 514.701 404.971Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_50"
            d="M433.49 91.4304C434.33 92.0979 435.215 92.7103 436.139 93.2634C434.921 92.2107 433.622 91.2498 432.254 90.3892C432.209 90.3557 432.158 90.3322 432.104 90.3203C432.521 90.7399 432.986 91.1123 433.49 91.4304Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_51"
            d="M526.649 383.775C526.254 384.693 525.958 385.647 525.766 386.624C526.084 386.624 527.576 382.536 527.673 381.693C527.677 381.67 527.677 381.647 527.673 381.624C527.252 382.305 526.908 383.03 526.649 383.784"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_52"
            d="M531.781 371.943C531.781 371.943 530.898 374.748 530.836 375.058C530.968 374.748 532.169 370.884 532.417 369.998V369.946C532.417 370.015 532.337 370.118 531.763 371.951"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_53"
            d="M439.14 94.9924C440.023 95.6206 440.809 96.2833 441.675 96.8857L438.143 94.2266L439.185 95.0011"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_54"
            d="M515.805 411.528C515.346 412.389 514.869 413.327 514.428 414.239C514.534 414.058 514.622 413.869 514.719 413.688L516.812 409.558V409.515C516.459 410.186 516.123 410.866 515.779 411.537"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_55"
            d="M512.415 413.584C511.938 414.445 511.064 416.218 511.214 416.355C511.682 416.269 513.705 412.431 513.758 411.76C513.762 411.734 513.762 411.708 513.758 411.683C513.546 411.683 512.804 412.922 512.433 413.584"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_56"
            d="M519.586 402.862C519.586 402.862 518.491 405.168 518.402 405.616C519.285 404.104 520.038 402.523 520.654 400.891C520.663 400.869 520.663 400.844 520.654 400.822C520.258 401.481 519.901 402.162 519.586 402.862Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_57"
            d="M519.709 394.988C519.283 395.879 518.986 396.823 518.826 397.794C518.95 398.095 519.656 396.933 520.204 395.737C520.645 394.891 520.943 393.982 521.087 393.044C520.981 392.699 520.389 393.758 520.204 394.085C520.124 394.24 519.956 394.567 519.762 394.945"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_58"
            d="M530.067 371.616C529.852 372.254 529.725 372.917 529.688 373.587C530.164 373.432 531.401 369.215 531.401 368.424C531.403 368.404 531.403 368.383 531.401 368.363C530.904 369.416 530.47 370.497 530.102 371.599"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_59"
            d="M483.903 430.408C483.719 430.674 483.574 430.964 483.47 431.268L482.984 433.084C482.986 433.147 483.001 433.208 483.029 433.265C485.386 431.656 486.243 429.169 485.996 428.859C485.581 428.523 484.459 429.556 483.903 430.391"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_60"
            d="M506.782 423.008C505.793 424.617 506.093 424.961 506.111 424.97C506.99 423.892 507.679 422.679 508.151 421.381C508.151 421.226 508.001 421.381 507.948 421.381C507.504 421.875 507.114 422.411 506.782 422.982"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_61"
            d="M447.256 101.378C447.582 101.644 448.439 102.35 449.075 102.78C449.004 102.677 446.664 100.775 445.949 100.199L445.852 100.13C446.294 100.559 446.763 100.961 447.256 101.335"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_62"
            d="M497.758 435.244C497.228 436.2 496.61 437.112 496.15 438.101C496.892 437.757 498.914 434.659 498.958 434.126C498.958 433.988 498.42 434.126 497.837 435.055C497.801 435.113 497.769 435.174 497.74 435.236"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_63"
            d="M540.037 335.748L539.428 339.767C539.525 339.207 539.649 338.433 539.702 338.14L540.134 335.343C540.178 335.008 540.231 334.672 540.275 334.328V334.276C540.275 334.38 540.037 335.739 540.028 335.748"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_64"
            d="M500.681 432.232C500.09 433.05 499.604 433.934 499.232 434.866C499.798 434.301 500.29 433.671 500.698 432.99L500.893 432.714C502.261 430.64 502.279 430.597 502.314 430.356C501.689 430.919 501.146 431.562 500.698 432.267"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_65"
            d="M488.334 426.837C487.204 428.498 486.647 430.073 487.23 429.953C487.744 429.659 488.198 429.277 488.572 428.825C489.101 427.771 489.515 426.667 489.809 425.529C489.23 425.861 488.728 426.307 488.334 426.837Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_66"
            d="M537.556 349.285C537.256 350.799 537.256 350.799 537.238 350.937C537.38 350.653 538.174 346.677 538.271 346.058C538.21 346.23 537.565 349.259 537.556 349.285Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_67"
            d="M538.837 338.226C538.762 338.684 538.736 339.148 538.757 339.612C539.158 338.456 539.39 337.251 539.446 336.032C539.446 335.954 539.446 335.954 539.331 335.825C539.278 335.937 538.881 337.968 538.837 338.226Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_68"
            d="M517.131 398.861C516.79 399.665 516.495 400.486 516.248 401.322C516.76 401.615 518.553 397.071 518.473 396.555C517.949 397.286 517.502 398.067 517.14 398.887"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_69"
            d="M531.947 367.649C531.506 369.602 531.506 369.602 531.559 369.688C532.124 368.523 532.528 367.291 532.76 366.022C532.76 365.953 532.76 365.945 532.76 365.919C532.574 365.919 532.045 367.347 531.974 367.64"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_70"
            d="M527.789 376.512C527.621 377.029 527.436 377.614 527.648 377.493C527.86 377.373 529.078 374.671 529.334 372.786C529.255 372.967 529.167 373.148 529.087 373.337C528.813 373.991 528.601 374.662 528.354 375.325C528.195 375.738 527.921 376.091 527.789 376.512Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_71"
            d="M508.989 422.362C508.615 422.979 508.279 423.617 507.982 424.273C508.186 423.989 508.609 423.3 508.945 422.741C509.28 422.181 509.625 421.725 509.943 421.2C510.135 420.877 510.309 420.544 510.464 420.202V420.159C509.928 420.864 509.442 421.603 509.007 422.371"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_72"
            d="M525.589 379.834C525.461 380.188 525.361 380.55 525.289 380.918C525.722 381.701 526.622 378.758 526.631 378.724C526.715 378.461 526.771 378.19 526.799 377.915C526.311 378.5 525.905 379.146 525.589 379.834Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_73"
            d="M492.115 415.46C491.505 416.459 490.666 418.326 491.479 418.154C492.291 417.982 493.36 414.781 493.095 414.462C492.83 414.35 492.318 415.125 492.115 415.46Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_74"
            d="M511.479 418.783C511.399 418.955 511.311 419.127 511.24 419.299C511.426 419.024 511.479 418.938 511.523 418.921C511.567 418.903 511.523 418.921 511.47 419.093C511.417 419.265 511.39 419.316 511.399 419.334C511.906 418.497 512.369 417.635 512.786 416.752C512.786 416.752 511.655 418.473 511.488 418.843"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_75"
            d="M539.012 321.816C538.965 322.235 539.004 322.659 539.127 323.064V322.857C539.286 323.296 539.286 323.296 539.374 323.124C539.617 322.335 539.71 321.51 539.648 320.689C539.603 320.577 539.559 320.603 539.48 320.775C539.4 320.947 539.4 320.981 539.365 321.093C539.25 321.412 539.233 321.42 539.215 321.429C539.197 321.437 539.215 321.308 539.091 321.282C538.968 321.257 539.03 321.369 538.977 321.816"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_76"
            d="M426.259 86.2414C426.453 86.3705 427.857 87.2999 428.025 87.3601C427.449 86.9086 426.841 86.4974 426.206 86.1296L425.861 85.9316C425.861 85.9316 425.861 85.9316 426.285 86.2586"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_77"
            d="M487.506 423.335C486.914 424.369 486.394 425.442 485.952 426.545L485.793 427.078C486.967 426.072 486.967 426.072 487.418 425.306C488.08 424.17 488.159 423.43 488.009 423.266C487.859 423.103 487.709 423.266 487.559 423.215L487.506 423.335Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_78"
            d="M536.541 354.215C536.303 355.231 536.073 356.246 535.844 357.262C536.117 356.143 536.453 354.792 536.956 352.503C536.815 353.079 536.674 353.647 536.541 354.224"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_79"
            d="M492.415 405.401C492.275 405.937 492.077 406.456 491.824 406.95C491.483 407.502 491.266 408.118 491.188 408.757C491.188 408.947 491.135 409.127 491.1 409.317C492.248 408.284 492.248 408.284 492.627 407.467C493.228 406.193 493.157 405.479 492.972 405.324C492.786 405.169 492.627 405.324 492.46 405.272C492.46 405.272 492.46 405.358 492.46 405.401"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_80"
            d="M490.109 421.536C489.226 422.999 488.847 424.256 489.429 424.066C490.012 423.877 490.312 423.206 490.595 422.56C490.679 422.38 490.736 422.189 490.763 421.993C490.763 421.795 490.763 421.597 490.763 421.399C490.763 421.201 490.842 421.011 490.851 420.822C490.86 420.633 490.851 420.728 490.851 420.702C490.692 420.702 490.153 421.562 490.145 421.562"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_81"
            d="M489.209 417.913C489.209 417.913 488.087 419.987 487.893 420.495C487.893 420.564 487.655 421.132 488.008 421.063C488.564 420.942 490.436 418.171 489.774 417.371L489.686 417.319C489.536 417.509 489.385 417.698 489.253 417.905"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_82"
            d="M489.781 412.707C489.781 412.888 489.87 413.077 489.905 413.266C490.002 413.266 490.081 413.395 490.187 413.438H490.311C490.552 413.285 490.761 413.089 490.929 412.862C491.313 412.432 491.539 411.89 491.574 411.321C491.578 411.301 491.578 411.281 491.574 411.261C491.463 411.211 491.339 411.196 491.219 411.218C491.098 411.239 490.988 411.296 490.903 411.382C490.77 411.476 490.77 411.476 489.808 412.621V412.707"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_83"
            d="M506.976 424.875C506.803 425.205 506.672 425.555 506.588 425.917C506.764 425.796 506.844 425.745 507.144 425.237C507.421 424.777 507.655 424.293 507.842 423.791C507.846 423.763 507.846 423.734 507.842 423.705C507.512 424.071 507.216 424.466 506.959 424.884"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_84"
            d="M536.063 328.803C536.063 329.079 536.063 329.363 536.063 329.664C536.673 328.528 536.752 328.304 536.752 328.304C536.808 327.983 536.808 327.654 536.752 327.332C536.602 327.1 536.275 327.771 536.116 328.494C536.116 328.494 536.064 328.717 536.055 328.829"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_85"
            d="M540.999 320.594C540.938 321.309 540.917 322.028 540.937 322.745H541.008C541.07 321.549 541.078 321.454 541.078 320.052V320.129L541.025 320.611"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_86"
            d="M540.777 325.8C540.777 326.015 540.777 326.23 540.777 326.454C540.95 325.971 541.057 325.467 541.095 324.957C541.095 324.664 541.095 324.664 541.042 324.664C540.928 325.034 540.854 325.415 540.821 325.8"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_87"
            d="M536.364 353.82C536.276 354.181 536.214 354.534 536.135 354.895C536.392 354.184 536.576 353.449 536.682 352.701V352.649C536.54 353.029 536.433 353.421 536.364 353.82Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_88"
            d="M492.908 402.295C492.855 402.398 492.855 402.398 492.793 402.957C493.166 402.473 493.508 401.967 493.817 401.443C493.842 401.384 493.863 401.323 493.879 401.262C493.941 400.987 493.985 400.711 494.047 400.401C493.667 401.021 493.234 401.615 492.908 402.26"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_89"
            d="M509.519 415.409C509.351 415.762 509.175 416.115 508.998 416.468C509.458 415.913 509.838 415.299 510.128 414.644C509.908 414.885 509.704 415.141 509.519 415.409Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_90"
            d="M435.361 92.3424L435.794 92.6436L435.908 92.721C435.636 92.4713 435.34 92.2467 435.025 92.0498L435.405 92.3424"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_91"
            d="M537.723 324.329C537.723 324.552 537.723 324.768 537.723 324.991C538.279 323.27 538.279 323.27 538.208 323.029C537.995 323.438 537.849 323.876 537.776 324.329"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_92"
            d="M500.716 430.632L500.08 431.674C500.58 431.136 501.002 430.534 501.334 429.884C500.778 430.546 500.778 430.546 500.716 430.632Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_93"
            d="M510.445 420.771C510.216 421.209 510.216 421.209 510.233 421.227C510.251 421.244 510.313 421.227 510.852 420.177C510.7 420.364 510.564 420.562 510.445 420.771Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_94"
            d="M541.22 315C541.205 315.223 541.205 315.448 541.22 315.671C541.273 315.671 541.344 315.361 541.344 315.361C541.361 315.026 541.361 314.69 541.344 314.354C541.28 314.566 541.229 314.782 541.193 315"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_95"
            d="M507.913 418.851C507.815 419.1 507.701 419.341 507.621 419.591C507.621 419.642 507.621 419.651 507.692 419.591L507.921 419.453C507.982 419.324 508.035 419.192 508.08 419.057C508.08 418.98 508.08 418.98 507.957 418.722L507.913 418.816"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_96"
            d="M483.168 434.762C483.089 434.909 483.001 435.055 482.912 435.201C482.824 435.348 482.912 435.27 482.912 435.279C482.936 435.313 482.966 435.342 483.001 435.365L483.177 435.451C483.294 435.293 483.397 435.126 483.486 434.952C483.486 434.952 483.486 434.909 483.486 434.848C483.421 434.793 483.35 434.744 483.274 434.702V434.762"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_97"
            d="M501.289 395.048V395.418L501.439 395.22L501.563 395.057C501.612 395.003 501.656 394.946 501.695 394.885C501.71 394.794 501.71 394.701 501.695 394.609C501.695 394.609 501.695 394.609 501.589 394.687C501.499 394.8 501.419 394.921 501.351 395.048"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_98"
            d="M500.405 398.499V398.396L500.511 398.336L500.379 398.499M500.529 398.327C500.723 398.086 500.926 397.845 501.112 397.596L501.032 398L500.547 398.284"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_99"
            d="M499.771 398.912L500.283 398.602L499.709 399.325C499.709 399.187 499.709 399.058 499.78 398.929M500.309 398.576L500.451 398.404V398.508L500.345 398.568"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_100"
            d="M494.613 408.843V408.791L494.666 408.74L494.596 408.843M494.799 408.585L494.666 408.74L494.763 408.576L495.39 407.655C495.39 407.716 495.39 407.776 495.452 407.836L494.79 408.55"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_101"
            d="M494.233 408.646L494.559 408.861L493.959 409.196L494.233 408.671M494.577 408.852H494.63V408.904"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_102"
            d="M424.924 85.1825L425.295 85.4234L424.633 84.9932L424.933 85.1911"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_103"
            d="M435.537 93.1171C435.402 92.9737 435.244 92.8515 435.07 92.7549L435.52 93.1085"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_104"
            d="M118.823 172.734C117.808 174.128 116.298 174.963 116.086 173.93C115.741 172.209 118.17 169.387 119.397 169.628C120.13 170.024 119.556 171.684 118.814 172.7"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_105"
            d="M114.274 170.721C113.48 171.814 111.696 173.174 111.431 172.072C110.998 170.239 113.851 167.442 114.787 167.769C115.361 168.114 114.981 169.714 114.274 170.695"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_106"
            d="M109.638 168.811C108.526 170.343 107.077 171.066 106.91 170.162C106.636 168.596 109.108 165.524 110.345 165.859C110.91 166.135 110.583 167.477 109.638 168.785"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_107"
            d="M105.338 166.823C104.747 167.614 103.572 168.699 102.91 168.475C101.841 167.692 104.34 163.931 105.974 163.854C106.044 163.846 106.115 163.846 106.186 163.854C106.68 164.138 106.274 165.575 105.303 166.797"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_108"
            d="M101.426 164.792C100.799 165.584 99.5719 166.6 98.9714 166.393C98.5211 165.997 99.0421 164.612 99.6072 163.811C100.411 162.667 101.656 161.789 102.159 161.832C102.203 161.822 102.248 161.822 102.292 161.832C102.76 162.219 102.203 163.803 101.409 164.775"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_109"
            d="M97.656 162.744C96.6229 164.026 95.2807 164.835 95.1924 163.966C95.0334 162.477 97.7002 159.336 98.7686 159.767C99.1837 160.059 98.6273 161.488 97.656 162.744Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_110"
            d="M94.2576 160.687C93.2686 161.849 92.0147 162.641 91.944 161.883C91.8116 160.481 94.5931 157.245 95.4761 157.658C95.8558 158.097 95.1141 159.62 94.2134 160.687"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_111"
            d="M91.3168 158.544C90.4338 159.508 89.0915 160.454 89.0474 159.766C88.9503 158.38 91.7406 155.11 92.6413 155.541C92.9327 155.962 92.138 157.589 91.308 158.535"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_112"
            d="M88.7555 156.307C87.6782 157.581 86.592 158.218 86.5391 157.607C86.7434 156.616 87.2229 155.699 87.9254 154.956C88.8085 153.915 89.8946 153.175 90.1683 153.398C90.4862 153.837 89.559 155.369 88.7555 156.298"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_113"
            d="M86.3976 154.216C85.3733 155.369 84.3931 155.937 84.3401 155.472C84.2077 154.302 87.2541 150.774 87.9959 151.238C88.2078 151.694 87.3248 153.174 86.3976 154.216Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_114"
            d="M84.525 152.082C83.642 153.037 82.5382 153.803 82.5117 153.338C82.8015 152.319 83.3408 151.382 84.0835 150.61C84.6575 149.948 85.8496 148.889 86.1763 149.139C86.3264 149.552 85.3727 151.144 84.525 152.073"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_115"
            d="M54.9623 274.434C54.5561 275.837 53.576 276.827 53.0197 275.975C51.8541 274.202 53.1521 270.338 54.3883 270.614L54.5031 270.657C55.342 271.25 55.3067 273.238 54.9623 274.434Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_116"
            d="M60.2681 276.973C59.9149 278.169 58.7405 279.606 58.0782 278.419C56.9215 276.328 58.3873 272.98 59.5441 273.204H59.6588C60.3918 273.703 60.6302 275.725 60.2681 276.939"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_117"
            d="M251.083 310.905C251.003 311.92 250.297 313.486 248.69 313.529C246.491 313.288 245.431 309.924 247.586 308.237C247.943 307.933 248.401 307.765 248.875 307.764C250.129 307.815 251.242 309.373 251.118 310.905"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_118"
            d="M261.308 311.283C261.141 312.608 259.154 315.233 257.211 312.944C255.268 310.655 257.467 308.073 258.889 308.099C259.612 308.268 260.251 308.679 260.693 309.262C261.136 309.844 261.354 310.56 261.308 311.283Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_119"
            d="M271.331 311.421C271.252 312.385 270.519 313.96 268.938 314.003C266.704 313.77 265.601 310.406 267.764 308.71C268.12 308.408 268.572 308.235 269.044 308.22C270.334 308.22 271.508 309.846 271.367 311.378"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_120"
            d="M281.61 311.67C281.371 313.468 279.243 315.361 277.53 313.158C275.817 310.955 277.468 308.486 279.296 308.365C280.877 308.365 281.83 310.138 281.636 311.67"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_121"
            d="M390.337 391.564C390.26 392.212 389.988 392.823 389.554 393.319C389.12 393.816 388.544 394.175 387.9 394.352C385.825 394.464 384.765 391.719 386.708 389.739C387.092 389.298 387.617 388.995 388.2 388.879C389.542 388.733 390.549 389.972 390.363 391.538"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_122"
            d="M153.853 404.85C153.853 406.313 152.475 407.853 150.709 406.021C148.705 403.938 149.826 401.597 151.283 401.58H151.487C152.176 401.822 152.773 402.264 153.197 402.848C153.62 403.431 153.849 404.127 153.853 404.842"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_123"
            d="M145.181 403.482C145.137 404.524 144.016 406.537 142.126 404.627C140.033 402.51 141.164 400.118 142.594 400.135H142.735C143.424 400.411 144.018 400.871 144.451 401.462C144.883 402.052 145.136 402.75 145.181 403.474"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_124"
            d="M136.431 401.804C136.528 403.525 135.194 404.584 133.781 403.379C131.671 401.529 132.192 398.775 133.72 398.586C133.84 398.573 133.961 398.573 134.082 398.586C135.221 398.81 136.298 400.307 136.386 401.787"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_125"
            d="M127.945 400.151C128.033 401.683 126.867 403.017 125.375 401.657C123.326 399.79 123.839 397.079 125.313 396.924C125.434 396.916 125.555 396.916 125.675 396.924C126.717 397.148 127.856 398.775 127.945 400.134"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_126"
            d="M119.865 398.577C119.865 399.636 118.92 401.348 117.41 400.014C115.229 398.078 115.794 395.152 117.41 395.204H117.569C118.744 395.574 119.865 397.407 119.883 398.56"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_127"
            d="M111.812 396.624C111.962 398.173 110.929 399.326 109.684 398.345C107.653 396.701 107.679 393.56 109.286 393.388C109.397 393.371 109.511 393.371 109.622 393.388C110.629 393.655 111.671 395.195 111.812 396.624Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_128"
            d="M104.05 394.868C104.121 396.219 103.282 397.45 101.993 396.357C99.8998 394.636 100.156 391.624 101.551 391.435C101.656 391.418 101.764 391.418 101.869 391.435C103.123 391.865 103.979 393.715 104.032 394.877"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_129"
            d="M380.773 188.224C381.162 189.739 380.376 191.365 378.769 190.358C376.49 188.939 376.596 186.056 378.036 185.668C379.36 185.582 380.49 187.123 380.764 188.25"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_130"
            d="M373.391 189.189C373.692 190.549 372.95 192.45 371.184 191.357C368.72 189.843 369.418 186.969 370.513 186.71H370.583C371.669 186.659 373.118 187.941 373.391 189.206"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_131"
            d="M365.63 190.238C366.001 191.908 364.924 193.311 363.44 192.416C361.277 191.099 361.453 188.113 362.857 187.683H363.016C364.023 187.579 365.321 188.862 365.665 190.264"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_132"
            d="M357.752 191.047C358.088 192.579 357.169 194.257 355.598 193.336C353.222 191.951 353.726 188.921 355.059 188.577H355.174C356.357 188.534 357.478 189.859 357.752 191.064"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_133"
            d="M350.044 191.916C350.22 193.181 349.258 195.307 347.474 193.947C345.293 192.277 346.088 189.584 347.421 189.352C348.569 189.352 349.858 190.797 350.008 191.933"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_134"
            d="M341.999 192.528C342.326 194.326 341.054 195.806 339.535 194.705C337.345 193.113 338.149 190.23 339.491 190.023H339.562C340.754 190.023 341.778 191.314 341.999 192.536"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_135"
            d="M333.982 193.25C334.088 194.111 333.796 195.531 332.56 195.729C330.662 195.729 329.213 192.648 330.873 191.022C331.075 190.804 331.352 190.666 331.651 190.634C332.666 190.583 333.832 191.874 333.999 193.216"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_136"
            d="M158.763 183.715C157.968 185.195 156.193 186.434 155.663 185.058C154.992 183.337 157.155 180.479 158.542 180.755C159.425 181.039 159.425 182.553 158.763 183.732"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_137"
            d="M152.448 182.287C151.565 183.853 149.746 185.067 149.331 183.552C148.757 181.478 151.238 179.12 152.289 179.344C153.172 179.645 153.075 181.065 152.448 182.287Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_138"
            d="M146.418 180.876C145.914 181.737 144.652 182.872 143.769 182.761C142.886 182.391 143.221 180.842 143.442 180.325C143.903 179.278 144.746 178.435 145.809 177.959C145.964 177.916 146.129 177.916 146.285 177.959C147.062 178.26 147.001 179.886 146.382 180.919"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_139"
            d="M140.352 179.499C139.468 180.85 137.764 181.951 137.429 180.66C136.952 178.879 139.195 176.194 140.519 176.358C141.402 176.736 141.005 178.397 140.334 179.447"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_140"
            d="M123.926 174.456C122.937 175.893 121.383 176.78 121.092 175.739C120.65 174.19 122.999 171.135 124.394 171.436C125.03 171.677 124.8 173.157 123.926 174.456Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_141"
            d="M148.095 388.121C148.007 389.61 146.541 391.081 144.96 389.334C142.974 387.131 144.21 384.627 145.649 384.636H145.844C147.071 384.851 148.184 386.563 148.095 388.078"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_142"
            d="M176.086 126.059C174.983 127.247 173.49 128.004 173.437 127.169C173.323 125.921 175.848 123.365 177.031 123.529C177.667 123.83 176.89 125.25 176.104 126.059"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_143"
            d="M180.503 127.126C179.62 128.09 177.854 129.243 177.766 128.271C177.58 126.894 180.097 124.484 181.227 124.622C181.783 124.871 181.289 126.248 180.494 127.126"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_144"
            d="M184.997 128.262C184.008 129.441 182.348 130.405 182.348 129.32C182.268 127.745 184.679 125.611 185.712 125.715C186.392 125.999 185.712 127.436 185.006 128.296"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_145"
            d="M139.213 386.435C139.213 387.339 138.701 388.724 137.358 388.586C135.292 388.01 134.171 384.413 136.228 383.268C136.453 383.126 136.725 383.074 136.988 383.122C138.312 383.458 139.23 385.136 139.23 386.435"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_146"
            d="M210.003 132.694C209.553 133.434 208.476 134.492 207.69 134.467C206.648 134.183 207.195 131.696 209.226 130.508C209.44 130.36 209.697 130.282 209.959 130.284C210.639 130.474 210.56 131.799 210.012 132.702"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_147"
            d="M215.136 133.58C214.359 134.897 212.787 135.912 212.487 134.673C212.089 132.952 214.111 130.99 215.136 131.007C216.019 131.239 215.692 132.676 215.136 133.589"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_148"
            d="M220.609 134.209C219.832 135.706 218.287 136.584 217.96 135.396C217.527 133.907 219.267 131.679 220.486 131.704C221.166 131.808 221.236 133.047 220.627 134.209"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_149"
            d="M226.182 134.802C225.732 135.705 224.071 137.452 223.533 136.101C222.862 134.44 224.875 132.254 225.82 132.34C226.562 132.512 226.659 133.872 226.191 134.802"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_150"
            d="M231.719 135.344C231.418 136.05 230.606 137.186 229.696 137.186C228.425 136.919 228.813 134.243 230.579 133.116C230.78 132.974 231.021 132.899 231.268 132.9C231.957 132.978 232.151 134.191 231.683 135.344"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_151"
            d="M237.388 135.834C236.84 137.314 235.375 138.356 234.827 137.288C234.085 135.817 235.551 133.433 236.84 133.416C237.573 133.485 237.829 134.621 237.388 135.834Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_152"
            d="M243.127 136.394C242.703 137.478 241.184 139.053 240.522 137.616C239.859 136.179 241.316 133.907 242.438 133.881C243.241 134.044 243.489 135.421 243.118 136.394"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_153"
            d="M130.717 384.92C130.717 386.005 129.499 388.035 127.848 386.366C125.702 384.206 126.806 381.538 128.254 381.512C128.318 381.506 128.383 381.506 128.448 381.512C129.649 381.857 130.762 383.741 130.717 384.903"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_154"
            d="M320.621 136.402C320.886 137.719 320.197 138.855 319.146 138.218C317.327 137.116 317.336 134.432 318.431 134.104H318.555C319.508 134.104 320.409 135.344 320.621 136.428"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_155"
            d="M326.617 135.886C326.926 137.117 326.388 138.468 325.152 137.736C323.386 136.678 323.271 134.01 324.313 133.657H324.463C325.496 133.657 326.37 134.965 326.617 135.912"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_156"
            d="M332.568 135.275C332.798 136.041 332.674 137.237 331.87 137.401C330.246 137.401 328.824 134.398 329.919 133.313C330.018 133.208 330.153 133.14 330.299 133.124C331.182 133.124 332.303 134.337 332.586 135.275"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_157"
            d="M122.223 383.181C122.223 384.902 120.925 386.056 119.627 384.782C117.49 382.699 118.275 379.722 119.909 379.799H120.095C121.189 380.049 122.178 381.615 122.223 383.164"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_158"
            d="M114.002 381.349C114.002 382.27 113.614 383.655 112.36 383.483C111.788 383.188 111.297 382.764 110.928 382.246C110.559 381.728 110.324 381.131 110.241 380.506C110.157 380.039 110.214 379.559 110.405 379.124C110.597 378.688 110.914 378.316 111.318 378.053C111.498 377.965 111.705 377.943 111.901 377.993C113.216 378.406 113.967 380.205 114.011 381.349"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_159"
            d="M106.194 379.679C106.194 380.738 105.152 382.467 103.793 381.125C101.691 379.059 102.344 375.961 103.907 376.099H104.084C105.223 376.529 106.239 378.466 106.186 379.653"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_160"
            d="M380.183 392.699C380.111 393.358 379.836 393.981 379.393 394.483C378.949 394.986 378.359 395.345 377.701 395.513C375.75 395.582 374.363 393.017 376.492 390.892C376.878 390.448 377.407 390.145 377.993 390.031C379.264 389.902 380.359 391.21 380.209 392.665"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_161"
            d="M370.115 393.973C369.7 395.9 367.077 397.802 365.585 395.393C364.472 393.594 366.176 391.374 367.801 391.09C368.153 391.113 368.496 391.207 368.808 391.367C369.121 391.528 369.395 391.75 369.613 392.021C369.831 392.291 369.988 392.603 370.075 392.936C370.161 393.269 370.175 393.617 370.115 393.955"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_162"
            d="M359.758 394.756C359.564 396.58 357.188 398.68 355.52 396.615C354.01 394.747 355.714 392.312 357.489 392.02C357.833 392.026 358.171 392.106 358.48 392.253C358.789 392.4 359.061 392.612 359.278 392.873C359.494 393.133 359.649 393.437 359.732 393.762C359.815 394.088 359.824 394.427 359.758 394.756Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_163"
            d="M349.365 395.565C349.127 397.665 346.592 399.386 344.95 397.287C343.308 395.187 345.621 392.932 346.972 392.82C347.318 392.835 347.657 392.92 347.967 393.07C348.277 393.22 348.551 393.431 348.772 393.69C348.993 393.95 349.156 394.251 349.251 394.576C349.345 394.9 349.369 395.24 349.321 395.574"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_164"
            d="M339.138 396.158C338.979 397.879 336.56 400.298 334.652 397.879C333.081 395.892 335.315 393.577 336.648 393.465C337.34 393.505 337.988 393.807 338.454 394.308C338.919 394.808 339.165 395.466 339.138 396.141"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_165"
            d="M328.595 396.77C328.444 398.74 326.096 400.504 324.418 398.68C322.74 396.856 324.365 394.231 326.237 394.024C326.578 394.049 326.911 394.14 327.216 394.293C327.521 394.445 327.791 394.655 328.011 394.911C328.231 395.167 328.396 395.464 328.496 395.783C328.596 396.102 328.63 396.438 328.595 396.77Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_166"
            d="M185.367 392.975C185.226 394.455 183.477 396.365 181.597 394.145C179.716 391.925 181.535 389.593 182.859 389.713C183.579 389.926 184.211 390.357 184.662 390.944C185.113 391.53 185.36 392.242 185.367 392.975Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_167"
            d="M131.936 92.48C131.335 92.8734 130.693 93.2023 130.02 93.461C130.373 92.8587 134.329 90.3631 134.726 90.4921C133.871 91.2455 132.939 91.9116 131.945 92.48"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_168"
            d="M131.379 94.089C131.194 94.2095 129.613 95.1991 129.375 95.0872C129.375 94.6742 133.543 91.972 134.09 92.0495C133.267 92.8184 132.354 93.4913 131.371 94.0546"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_169"
            d="M131.052 95.767C130.448 96.1892 129.777 96.5119 129.065 96.7222C128.941 96.3349 133.251 93.5295 133.763 93.6586C132.947 94.4587 132.034 95.1581 131.043 95.7411"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_170"
            d="M131.106 97.2735C130.443 97.7548 129.703 98.1269 128.916 98.375C128.696 97.9791 133.031 95.0619 133.596 95.2254C132.865 96.0184 132.013 96.6976 131.071 97.2391"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_171"
            d="M131.239 98.9433C130.532 99.408 129.164 100.217 129.031 100.002C129.641 99.2634 130.376 98.6318 131.203 98.1343C132.625 97.1877 133.632 96.7661 133.729 96.878C133.729 97.1791 132.396 98.1774 131.23 98.926"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_172"
            d="M134.417 105.535C133.419 106.206 131.989 106.929 132.051 106.542C132.192 105.681 136.077 102.97 136.678 103.306C136.757 103.607 135.468 104.812 134.417 105.535Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_173"
            d="M175.734 391.761C175.734 392.708 175.195 394.111 173.712 394.051C171.522 393.62 170.295 390.109 172.458 388.827C172.754 388.651 173.101 388.572 173.447 388.603C174.621 388.758 175.778 390.324 175.769 391.761"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_174"
            d="M141.648 111.98C140.324 112.979 139.087 113.495 139.14 113.013C139.273 112.006 142.77 109.339 143.6 109.623C143.891 109.958 142.628 111.232 141.648 111.98Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_175"
            d="M144.184 113.555C142.877 114.587 141.588 115.129 141.588 114.648C141.588 113.787 145.023 110.844 146.003 111.205C146.33 111.464 145.305 112.668 144.175 113.563"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_176"
            d="M291.966 311.541C291.746 313.262 289.529 315.465 287.71 313.047C287.396 312.651 287.189 312.184 287.11 311.689C287.031 311.194 287.082 310.688 287.259 310.218C287.435 309.748 287.731 309.329 288.119 309C288.507 308.672 288.974 308.445 289.476 308.34C290.96 308.452 292.125 310.233 291.966 311.541Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_177"
            d="M166.285 390.565C166.285 392.235 164.722 393.715 163.15 392.235C160.863 390.083 162.187 387.398 163.786 387.355H163.989C165.163 387.528 166.276 389.077 166.285 390.548"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_178"
            d="M157.217 389.292C157.217 390.608 155.734 392.579 153.977 390.824C151.795 388.638 153.173 386.013 154.727 386.056H154.824C155.498 386.315 156.081 386.756 156.505 387.328C156.93 387.9 157.177 388.579 157.217 389.283"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_179"
            d="M367.802 425.15C367.641 425.774 367.309 426.343 366.843 426.797C366.376 427.252 365.793 427.575 365.153 427.732C363.573 427.784 361.921 425.908 363.882 423.791C364.339 423.27 364.96 422.91 365.648 422.767C365.958 422.766 366.264 422.829 366.548 422.952C366.831 423.075 367.084 423.255 367.291 423.48C367.498 423.705 367.653 423.97 367.748 424.258C367.842 424.546 367.872 424.85 367.838 425.15"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_180"
            d="M357.665 426.209C357.25 428.352 354.583 429.591 353.32 427.689C352.208 426.011 353.983 423.782 355.572 423.653C355.9 423.644 356.226 423.709 356.523 423.844C356.821 423.979 357.082 424.18 357.285 424.431C357.489 424.682 357.629 424.976 357.695 425.289C357.761 425.602 357.751 425.926 357.665 426.235"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_181"
            d="M86.0802 175.6C85.4356 176.461 83.8284 177.837 83.7313 176.805C83.5812 175.187 85.9919 172.012 87.0427 172.45C87.4577 172.898 86.8572 174.593 86.0802 175.6Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_182"
            d="M82.7867 173.277C81.7359 174.714 80.5792 175.359 80.4909 174.559C80.3319 172.932 83.1399 169.714 83.8375 170.213C84.2791 170.678 83.5108 172.287 82.7867 173.277Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_183"
            d="M79.8375 171.014C78.9544 172.202 77.78 173.062 77.6741 172.356C77.4621 170.954 80.1289 167.469 81.0296 167.959C81.3475 168.269 80.7558 169.783 79.8375 171.014Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_184"
            d="M77.3556 168.673C76.7551 169.473 75.6601 170.506 75.254 170.282C74.9802 169.861 75.6602 168.432 76.2253 167.606C77.1083 166.384 78.1238 165.627 78.4417 165.661C78.457 165.655 78.4736 165.651 78.4903 165.651C78.507 165.651 78.5235 165.655 78.5388 165.661C78.7861 166.066 78.0885 167.683 77.3556 168.639"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_185"
            d="M347.528 427.027C347.13 428.971 344.596 430.469 343.175 428.447C341.859 426.596 344.058 424.514 345.373 424.428C345.7 424.427 346.024 424.498 346.319 424.635C346.615 424.772 346.875 424.971 347.082 425.218C347.288 425.466 347.435 425.755 347.512 426.065C347.59 426.375 347.595 426.697 347.528 427.009"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_186"
            d="M337.55 427.707C337.161 429.428 334.601 431.201 333.135 429.041C331.881 427.216 333.824 425.271 335.316 425.082C335.642 425.097 335.962 425.176 336.256 425.316C336.55 425.456 336.811 425.653 337.024 425.894C337.236 426.136 337.396 426.418 337.492 426.722C337.589 427.027 337.621 427.347 337.585 427.664"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_187"
            d="M327.307 428.136C327.104 429.978 324.658 431.579 323.139 429.789C321.62 427.999 323.298 425.804 325.082 425.606C325.406 425.608 325.726 425.677 326.021 425.81C326.315 425.943 326.577 426.136 326.788 426.376C326.999 426.616 327.154 426.897 327.244 427.201C327.334 427.505 327.355 427.824 327.307 428.136Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_188"
            d="M178.357 423.3C178.428 425.021 176.785 426.063 175.213 424.677C173.191 422.887 173.995 420.435 175.611 420.306C175.702 420.298 175.793 420.298 175.885 420.306C176.564 420.491 177.168 420.878 177.611 421.413C178.054 421.948 178.315 422.605 178.357 423.292"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_189"
            d="M169.147 422.242C169.147 423.808 167.682 424.987 165.986 423.369C163.911 421.381 165.041 419.135 166.49 419.066H166.701C167.388 419.282 167.99 419.696 168.427 420.255C168.864 420.813 169.116 421.489 169.147 422.19"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_190"
            d="M160.307 420.873C160.329 421.117 160.3 421.363 160.222 421.595C160.145 421.828 160.02 422.043 159.855 422.228C159.69 422.413 159.489 422.563 159.263 422.67C159.037 422.778 158.792 422.84 158.541 422.853C156.395 422.336 155.062 419.049 157.102 417.991C157.326 417.873 157.583 417.828 157.835 417.861C158.497 418.097 159.081 418.501 159.527 419.033C159.973 419.564 160.264 420.203 160.369 420.882"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_191"
            d="M151.425 419.497C151.566 421.106 150.25 422.173 148.829 421.037C146.639 419.316 147.116 416.648 148.661 416.459C148.784 416.442 148.909 416.442 149.032 416.459C150.18 416.657 151.301 418.077 151.425 419.488"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_192"
            d="M142.753 417.991C142.921 419.48 141.8 420.814 140.21 419.514C137.914 417.638 138.638 415.117 140.016 414.962C140.124 414.946 140.234 414.946 140.343 414.962C140.988 415.203 141.553 415.61 141.978 416.141C142.402 416.672 142.671 417.305 142.753 417.974"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_193"
            d="M134.506 416.622C134.568 417.724 133.446 419.342 131.857 417.836C129.57 415.71 130.506 413.335 131.954 413.404H132.104C133.128 413.619 134.462 415.383 134.532 416.605"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_194"
            d="M126.213 414.91C126.354 416.339 125.33 417.544 123.881 416.322C121.948 414.73 121.965 411.942 123.475 411.726C123.601 411.702 123.73 411.702 123.855 411.726C124.501 412.004 125.059 412.443 125.473 412.999C125.888 413.556 126.143 414.209 126.213 414.893"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_195"
            d="M118.195 413.137C118.301 413.998 118.018 415.211 116.835 415.039C115.617 414.66 114.778 413.06 114.601 412.517C114.115 410.96 114.919 410.203 115.272 410.048C115.466 409.953 115.69 409.932 115.899 409.987C116.888 410.22 118.027 411.786 118.204 413.146"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_196"
            d="M150.797 97.9877C149.914 98.5299 148.527 99.2441 148.227 99.0978C148.103 98.7966 149.401 97.7382 150.125 97.2563C150.967 96.6233 151.941 96.177 152.978 95.9482C153.084 96.1978 151.83 97.2993 150.779 97.9705"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_197"
            d="M154.127 100.999C152.97 101.756 151.672 102.333 151.654 101.971C151.654 101.386 155.46 98.4691 156.352 98.8133C156.555 99.1403 155.169 100.311 154.127 100.999Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_198"
            d="M324.1 209.549C324.25 211.27 322.811 213.042 321.195 211.605C319.208 209.841 320.135 207.113 321.777 206.881C322.855 206.838 323.985 208.129 324.1 209.549Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_199"
            d="M315.747 209.911C315.809 211.279 314.537 213.534 312.727 211.993C310.555 210.126 312.082 207.372 313.354 207.277C314.564 207.329 315.659 208.766 315.712 209.911"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_200"
            d="M307.165 210.513C307.094 212.234 305.566 213.697 304.065 212.234C302.193 210.375 303.562 207.647 304.948 207.578C306.335 207.509 307.209 209.179 307.156 210.53"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_201"
            d="M298.599 210.667C298.52 212.251 297.01 214.024 295.447 212.388C293.522 210.34 295.235 207.853 296.48 207.802C297.937 207.879 298.652 209.523 298.599 210.693"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_202"
            d="M155.627 199.343C155.239 200.203 154.25 201.503 153.11 201.399C151.592 200.917 152.095 197.69 154.32 196.537C154.584 196.378 154.896 196.314 155.203 196.356C156.033 196.537 156.272 198.017 155.689 199.343"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_203"
            d="M148.925 198.112C148.263 199.351 146.232 201.021 145.702 199.205C145.101 197.157 147.185 194.816 148.545 194.954C149.428 195.264 149.516 196.994 148.925 198.112Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_204"
            d="M142.347 196.572C141.464 198.293 139.628 199.248 139.195 197.759C138.603 195.746 140.908 193.198 142.109 193.457C143.125 193.809 142.895 195.488 142.347 196.563"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_205"
            d="M135.918 195.109C135.176 196.417 133.384 197.802 132.898 196.279C132.262 194.291 134.505 191.736 135.821 191.916C136.784 192.304 136.519 194.033 135.909 195.109"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_206"
            d="M129.887 193.474C129.348 194.334 127.37 196.236 126.894 194.653C126.284 192.631 128.536 190.075 129.834 190.281C130.585 190.514 130.611 192.269 129.887 193.474Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_207"
            d="M123.989 191.667C123.106 193.139 121.384 194.309 121.022 192.855C120.527 190.867 122.991 188.208 124.077 188.552C124.96 188.948 124.616 190.617 123.989 191.676"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_208"
            d="M118.319 189.799C117.843 190.66 116.739 191.865 115.812 191.684C114.452 191.013 115.988 187.502 117.957 186.796C118.115 186.744 118.286 186.744 118.443 186.796C119.282 187.192 118.911 188.818 118.328 189.843"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_209"
            d="M113.013 187.932C112.492 188.793 111.291 189.963 110.47 189.825C109.278 189.214 110.558 185.721 112.633 184.981C112.804 184.912 112.992 184.897 113.172 184.938C113.843 185.29 113.649 186.917 113.031 187.941"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_210"
            d="M107.749 186.074C106.866 187.519 105.285 188.509 104.976 187.511C104.455 185.893 106.742 182.657 108.111 183.01C108.817 183.32 108.491 184.869 107.749 186.074Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_211"
            d="M102.796 184.06C102.284 184.869 101.163 186.056 100.368 185.781C99.1496 185.015 101.163 181.254 102.964 180.936C103.059 180.907 103.161 180.907 103.256 180.936C103.962 181.366 103.441 183.018 102.805 183.999"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_212"
            d="M98.2839 182.003C97.7187 182.864 96.5178 183.948 95.829 183.724C94.6811 182.95 96.7121 179.215 98.4781 178.905C98.5706 178.881 98.6682 178.881 98.7607 178.905C99.317 179.292 98.955 180.936 98.275 181.96"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_213"
            d="M93.8513 179.92C93.3745 180.617 92.2707 181.874 91.5113 181.59C91.0079 181.185 91.3788 179.868 91.9793 178.827C92.4826 177.906 93.3058 177.189 94.3016 176.805C94.3923 176.795 94.4841 176.81 94.5665 176.848C95.1758 177.295 94.5665 178.922 93.869 179.929"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_214"
            d="M89.6999 177.821C88.658 179.241 87.4217 179.869 87.2893 179.034C87.042 177.425 89.435 174.224 90.5741 174.637C91.1393 175.102 90.4417 176.806 89.6911 177.821"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_215"
            d="M406.558 420.435C406.073 422.423 403.653 424.127 402.461 422.216C401.393 420.495 403.406 418.318 404.81 418.008C406.029 417.845 406.894 419.049 406.576 420.435"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_216"
            d="M377.541 424.316C377.064 426.312 374.512 427.827 373.214 425.77C372.128 424.049 374.097 422.027 375.528 421.76C375.854 421.745 376.18 421.807 376.476 421.942C376.773 422.077 377.03 422.281 377.227 422.536C377.424 422.79 377.554 423.088 377.606 423.403C377.657 423.718 377.629 424.04 377.523 424.342"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_217"
            d="M306.918 293.978C306.723 295.561 304.719 297.799 302.838 295.553C302.499 295.172 302.266 294.712 302.163 294.217C302.061 293.723 302.091 293.211 302.25 292.731C302.41 292.252 302.695 291.82 303.076 291.479C303.458 291.138 303.923 290.899 304.427 290.785C305.628 290.785 307.121 292.412 306.918 293.978Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_218"
            d="M316.86 293.4C316.86 295.38 314.908 297.239 313.107 295.535C310.873 293.426 312.904 290.552 314.352 290.475C316.003 290.526 316.868 292.196 316.86 293.4Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_219"
            d="M326.997 293.117C326.926 295.13 324.851 296.834 323.129 295.001C321.275 293.031 322.706 290.182 324.498 290.07C326.194 290.07 327.041 291.86 326.997 293.117Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_220"
            d="M337.126 292.583C337.011 294.304 334.971 296.602 333.055 294.304C332.717 293.934 332.482 293.485 332.371 293.002C332.26 292.518 332.277 292.015 332.421 291.539C332.565 291.064 332.831 290.632 333.194 290.285C333.557 289.937 334.004 289.686 334.495 289.554C335.96 289.554 337.214 291.275 337.144 292.583"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_221"
            d="M346.98 291.8C346.98 293.737 345.116 295.613 343.315 293.986C341.24 292.119 342.547 289.176 344.428 288.909C345.788 288.832 346.971 290.432 346.98 291.8Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_222"
            d="M357.001 291.094C357.001 293.263 355.006 294.812 353.31 293.22C351.332 291.37 352.648 288.306 354.423 288.151C355.942 288.151 356.984 289.657 357.001 291.094Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_223"
            d="M366.98 290.225C366.927 291.998 365.09 294.089 363.174 292.239C361.073 290.208 362.892 287.437 364.243 287.282C364.991 287.358 365.684 287.701 366.189 288.244C366.694 288.787 366.975 289.492 366.98 290.225Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_224"
            d="M386.452 288.143C386.452 290.165 384.562 291.964 382.761 290.105C380.959 288.246 382.107 285.518 383.802 285.183C385.489 285.114 386.452 286.904 386.452 288.151"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_225"
            d="M396.138 286.637C396.271 288.212 394.62 290.802 392.606 289.004C390.593 287.205 391.838 284.297 393.436 283.944C394.593 283.806 396.085 285.235 396.147 286.637"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_226"
            d="M405.34 285.467C405.419 287.481 403.785 289.331 401.958 287.679C400.13 286.026 401.075 283.032 402.717 282.636C404.324 282.507 405.278 284.228 405.366 285.467"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_227"
            d="M384.262 408.275C383.918 409.936 381.401 412.251 379.847 409.944C378.584 408.077 380.818 405.84 382.072 405.693C382.398 405.702 382.719 405.778 383.012 405.916C383.305 406.055 383.565 406.253 383.773 406.498C383.981 406.742 384.133 407.027 384.219 407.334C384.305 407.64 384.323 407.961 384.271 408.275"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_228"
            d="M374.09 409.532C373.666 411.511 371.211 413.069 369.825 411.116C368.58 409.394 370.31 407.062 371.979 406.813C372.324 406.807 372.665 406.881 372.974 407.029C373.284 407.176 373.552 407.394 373.759 407.663C373.965 407.932 374.102 408.245 374.16 408.576C374.217 408.907 374.193 409.246 374.09 409.567"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_229"
            d="M363.935 410.332C363.652 412.354 361.127 414.066 359.617 412.053C358.283 410.332 360.05 407.974 361.754 407.75C362.081 407.752 362.404 407.824 362.7 407.961C362.995 408.098 363.256 408.297 363.464 408.543C363.672 408.79 363.822 409.077 363.904 409.386C363.985 409.696 363.996 410.018 363.935 410.332Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_230"
            d="M353.868 411.296C353.471 413.163 350.919 415.022 349.365 412.759C348.067 410.874 350.248 408.706 351.537 408.603C351.88 408.607 352.218 408.683 352.528 408.826C352.838 408.969 353.113 409.175 353.334 409.431C353.555 409.686 353.717 409.985 353.809 410.307C353.901 410.629 353.922 410.966 353.868 411.296Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_231"
            d="M343.519 412.054C343.165 414.265 340.481 415.496 339.103 413.525C337.858 411.692 339.589 409.524 341.293 409.3C341.634 409.313 341.968 409.398 342.272 409.55C342.575 409.701 342.841 409.916 343.05 410.178C343.26 410.44 343.408 410.744 343.484 411.068C343.56 411.392 343.563 411.728 343.492 412.054"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_232"
            d="M333.161 412.578C333.079 413.211 332.788 413.8 332.333 414.258C331.877 414.716 331.282 415.017 330.635 415.116C328.869 415.116 327.165 412.81 329.319 410.702C329.714 410.267 330.257 409.986 330.847 409.91C331.185 409.921 331.517 410 331.822 410.143C332.127 410.286 332.397 410.49 332.616 410.742C332.835 410.993 332.997 411.286 333.093 411.603C333.188 411.919 333.214 412.251 333.17 412.578"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_233"
            d="M181.447 408.525C181.447 410.168 179.752 411.45 178.11 409.884C175.928 407.785 177.279 405.409 178.878 405.375H179.072C180.282 405.539 181.483 407.096 181.447 408.516"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_234"
            d="M172.238 407.32C172.25 407.86 172.045 408.383 171.668 408.779C171.29 409.174 170.769 409.41 170.215 409.437C168.237 408.99 166.683 405.883 168.803 404.463C169.075 404.269 169.411 404.177 169.747 404.205C170.428 404.436 171.027 404.852 171.473 405.404C171.918 405.956 172.193 406.621 172.264 407.32"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_235"
            d="M162.939 406.253C162.895 407.681 161.429 409.11 159.787 407.466C157.694 405.375 158.904 402.948 160.361 402.939H160.564C161.986 403.172 162.992 404.876 162.939 406.235"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_236"
            d="M164.175 106.516C163.39 107.118 161.986 107.919 161.526 107.798C161.217 107.514 162.136 106.456 162.886 105.836C163.703 105.135 164.672 104.623 165.721 104.339H165.818C166.118 104.588 165.164 105.776 164.202 106.507"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_237"
            d="M173.623 110.302C172.899 110.931 171.531 111.8 170.974 111.714C170.206 111.275 172.811 108.65 174.506 108.16C174.639 108.12 174.78 108.12 174.913 108.16C175.301 108.392 174.524 109.571 173.641 110.337"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_238"
            d="M180.502 112.78C179.363 113.813 177.95 114.502 177.915 113.873C177.862 112.763 180.687 110.25 181.791 110.431C182.312 110.715 181.359 112.006 180.502 112.789"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_239"
            d="M184.467 113.744C183.584 114.605 181.747 115.749 181.712 114.941C181.642 113.589 184.67 111.3 185.447 111.498C185.898 111.722 185.28 112.953 184.467 113.744Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_240"
            d="M214.428 120.087C213.598 121.292 212.088 122.307 211.885 121.249C211.611 119.837 213.792 117.626 214.719 117.737C215.444 117.944 215.011 119.252 214.428 120.087Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_241"
            d="M219.214 120.775C218.331 122.144 216.891 122.987 216.68 121.954C216.37 120.491 218.446 118.323 219.382 118.443C220.123 118.633 219.761 119.923 219.214 120.775Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_242"
            d="M224.167 121.308C223.629 122.263 221.96 123.804 221.58 122.668C221.068 121.153 223.205 118.993 224.079 119.088C224.706 119.243 224.653 120.439 224.167 121.308Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_243"
            d="M228.92 122.058C228.213 123.314 226.765 124.338 226.474 123.202C226.076 121.705 227.825 119.691 228.858 119.674C229.617 119.855 229.405 121.18 228.911 122.058"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_244"
            d="M233.935 122.643C233.273 123.908 231.86 124.923 231.525 123.753C231.092 122.273 232.779 120.225 233.785 120.225C234.465 120.311 234.536 121.481 233.935 122.643Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_245"
            d="M239.128 122.995C238.801 123.709 237.918 124.716 237.167 124.759C236.019 124.484 236.514 121.876 238.244 120.87C238.391 120.766 238.567 120.709 238.748 120.706C239.419 120.852 239.534 122.117 239.145 122.995"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_246"
            d="M244.186 123.409C243.726 124.613 242.322 125.99 241.784 124.708C241.122 123.168 242.861 121.085 243.718 121.137C244.574 121.188 244.504 122.574 244.186 123.409Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_247"
            d="M249.379 123.839C248.884 125.293 247.56 126.231 247.03 125.156C246.332 123.744 247.869 121.481 248.796 121.524C249.555 121.653 249.679 122.823 249.343 123.839"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_248"
            d="M56.7196 256.406C56.2957 257.577 55.0948 258.988 54.5738 257.826C53.6908 255.769 55.2891 252.233 56.4458 252.758C57.0993 253.248 57.1523 255.227 56.7196 256.406Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_249"
            d="M61.602 258.953C61.1252 260.33 59.9684 261.535 59.4032 260.356C58.4407 258.334 60.0832 254.806 61.2841 255.305C62.0435 255.804 62.0523 257.619 61.5932 258.945"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_250"
            d="M66.9627 261.414C66.3975 263.135 65.2496 263.901 64.6844 262.955C63.5806 261.104 65.1171 257.516 66.4505 257.791C66.4887 257.788 66.5271 257.788 66.5653 257.791C67.2452 258.136 67.4483 259.917 66.9538 261.388"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_251"
            d="M256.532 293.925C256.267 295.698 254.165 297.54 252.531 295.328C252.212 294.933 252.002 294.465 251.921 293.968C251.839 293.472 251.889 292.963 252.066 292.491C252.243 292.018 252.541 291.598 252.932 291.269C253.322 290.94 253.792 290.714 254.298 290.612C255.852 290.767 256.691 292.488 256.487 293.925"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_252"
            d="M266.581 294.072C266.36 296.025 264.249 297.643 262.651 295.75C260.938 293.719 262.589 290.844 264.347 290.87C266.104 290.896 266.731 292.824 266.581 294.072Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_253"
            d="M276.753 294.072C276.585 295.75 274.439 298.048 272.62 295.793C270.801 293.538 272.956 291.026 274.324 291.034C275.029 291.185 275.658 291.571 276.102 292.125C276.545 292.68 276.776 293.369 276.753 294.072Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_254"
            d="M286.598 294.158C286.439 296.24 284.258 297.858 282.598 295.827C280.938 293.797 282.439 291.172 284.267 291.034C285.574 291.034 286.731 292.592 286.598 294.158Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_255"
            d="M296.744 294.072C296.594 296.052 294.518 297.747 292.832 295.879C291.004 293.849 292.567 290.974 294.377 290.949C296.187 290.923 296.85 292.825 296.744 294.072Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_256"
            d="M117.329 107.893C116.526 108.478 115.298 109.244 115.166 109.08C115.263 108.4 119.113 105.466 119.643 105.638C118.992 106.484 118.208 107.224 117.321 107.833"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_257"
            d="M117.454 109.794C117.383 109.854 115.688 111.102 115.432 110.887C115.432 110.525 116.809 109.312 117.357 108.89C118.787 107.78 119.776 107.341 119.9 107.419C119.838 107.78 118.549 108.942 117.427 109.768"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_258"
            d="M118.055 111.636C116.942 112.496 115.971 112.961 115.971 112.703C115.971 111.997 119.883 108.934 120.386 109.261C120.386 109.639 119.008 110.878 117.993 111.636"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_259"
            d="M119.166 113.237C118.124 114.037 116.809 114.777 116.809 114.45C116.809 113.71 120.862 110.517 121.224 110.965C121.277 111.274 120.12 112.462 119.149 113.228"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_260"
            d="M120.245 115.026C119.459 115.646 118.188 116.498 117.896 116.291C117.799 115.947 119.009 114.734 119.662 114.2C120.881 113.202 122.099 112.6 122.311 112.694C122.417 113.047 121.181 114.261 120.236 115.009"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_261"
            d="M121.702 116.8C121.03 117.35 119.662 118.323 119.291 118.116C119.141 117.772 120.271 116.585 120.942 116.025C122.408 114.786 123.432 114.399 123.591 114.468C123.759 114.734 122.708 115.931 121.622 116.782"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_262"
            d="M123.334 118.658C122.451 119.39 120.968 120.224 120.924 119.828C121.326 118.894 121.99 118.089 122.84 117.505C123.67 116.817 125.03 116.059 125.277 116.223C125.445 116.541 124.306 117.884 123.334 118.649"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_263"
            d="M127.619 122.135C126.974 122.694 125.623 123.684 125.137 123.46C124.89 123.099 125.853 121.937 126.585 121.266C127.304 120.508 128.219 119.955 129.235 119.665C129.269 119.655 129.306 119.655 129.34 119.665C129.632 120.018 128.51 121.309 127.574 122.101"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_264"
            d="M130.17 123.924C129.128 124.785 127.521 125.645 127.609 125.017C127.922 124.136 128.485 123.36 129.234 122.78C130.117 122.022 131.503 121.222 131.803 121.403C132.104 121.583 131.247 122.995 130.17 123.924Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_265"
            d="M132.915 125.68C131.661 126.782 130.408 127.332 130.372 126.799C130.302 125.68 133.569 122.78 134.54 123.098C134.937 123.451 133.86 124.82 132.915 125.68Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_266"
            d="M136.051 127.401C134.868 128.468 133.402 129.174 133.482 128.434C133.579 127.177 136.616 124.492 137.597 124.802C138.056 125.206 136.943 126.592 136.042 127.384"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_267"
            d="M139.627 128.898C138.912 129.595 137.552 130.525 136.978 130.379C136.148 129.768 138.965 126.687 140.607 126.411C140.688 126.39 140.773 126.39 140.854 126.411C141.252 126.713 140.501 128.046 139.636 128.89"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_268"
            d="M143.08 130.568C142.012 131.635 140.325 132.581 140.352 131.695C140.396 130.387 143.248 127.745 144.317 128.029C144.811 128.27 144.061 129.595 143.08 130.568Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_269"
            d="M146.948 132.014C145.756 133.287 144.166 134.122 144.122 133.27C144.052 131.85 147.107 129.191 148.008 129.535C148.502 129.828 147.858 131.05 146.948 132.014Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_270"
            d="M150.992 133.545C150.206 134.406 148.21 135.774 148.131 134.776C148.007 133.218 151.098 130.748 151.919 131.015C152.404 131.299 151.804 132.676 150.992 133.545Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_271"
            d="M410.894 354.921C410.656 357.099 408.484 358.932 406.956 356.927C405.429 354.921 407.159 352.323 408.599 351.987C410.144 351.867 411.036 353.433 410.877 354.921"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_272"
            d="M401.26 356.247C401.039 358.183 398.876 360.446 397.101 358.303C395.326 356.161 397.463 353.588 398.779 353.39C399.138 353.41 399.488 353.501 399.81 353.657C400.131 353.813 400.417 354.032 400.649 354.299C400.881 354.566 401.055 354.877 401.16 355.212C401.265 355.546 401.299 355.899 401.26 356.247Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_273"
            d="M391.158 357.417C391.078 359.534 388.844 361.582 387.149 359.749C385.453 357.916 386.955 355.016 388.782 354.663C390.036 354.517 391.22 355.885 391.158 357.417Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_274"
            d="M380.896 358.793C380.693 360.945 378.415 362.761 376.746 360.773C374.98 358.647 377.108 355.997 378.512 355.842C380.278 355.773 381.046 357.563 380.923 358.793"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_275"
            d="M130.02 142.177C128.827 143.459 127.309 144.208 127.326 143.253C127.326 141.841 130.09 139.148 131.176 139.449C131.653 139.707 131.088 141.032 130.02 142.177Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_276"
            d="M126.169 140.482C124.933 141.738 123.617 142.297 123.6 141.566C123.6 140.155 126.372 137.366 127.485 137.693C128.006 138.106 127.035 139.604 126.169 140.482Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_277"
            d="M122.796 138.614C122.019 139.389 120.146 140.731 120.146 139.699C120.226 138.072 123.361 135.568 124.049 135.904C124.473 136.256 123.687 137.711 122.769 138.614"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_278"
            d="M119.573 136.738C118.292 138.012 117.003 138.588 117.012 137.883C117.012 136.446 120.314 133.657 121.012 134.079C121.427 134.458 120.438 135.869 119.573 136.738Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_279"
            d="M114.045 132.969C113.118 133.83 111.608 134.759 111.572 134.208C111.852 133.3 112.384 132.484 113.109 131.85C114.107 130.912 115.36 130.129 115.643 130.362C115.89 130.697 114.954 132.083 114.045 132.943"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_280"
            d="M111.732 131.041C110.531 132.169 109.33 132.762 109.312 132.306C109.312 131.446 112.589 128.004 113.445 128.485C113.728 128.795 112.783 130.052 111.732 131.067"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_281"
            d="M109.621 129.208C108.464 130.249 107.413 130.8 107.369 130.395C107.736 129.451 108.344 128.613 109.135 127.96C109.895 127.263 111.228 126.377 111.502 126.583C111.714 126.988 110.513 128.39 109.594 129.165"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_282"
            d="M108.093 127.229C107.343 127.892 106.107 128.752 105.824 128.555C105.7 128.15 106.813 126.833 107.44 126.266C108.605 125.181 109.771 124.544 109.965 124.691C110.08 125.035 109.003 126.412 108.085 127.221"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_283"
            d="M106.593 125.344C105.428 126.36 104.421 126.919 104.43 126.532C104.43 125.671 108.138 122.367 108.624 122.797C108.739 123.193 107.512 124.518 106.584 125.379"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_284"
            d="M105.541 123.357C104.826 123.976 103.616 124.88 103.404 124.648C103.404 124.269 104.552 122.987 105.17 122.41C106.38 121.326 107.404 120.775 107.581 120.87C107.669 121.162 106.53 122.436 105.506 123.331"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_285"
            d="M104.677 121.524C104.033 122.066 102.911 122.875 102.673 122.737C102.629 122.358 103.892 121.016 104.439 120.586C105.702 119.45 106.744 118.864 106.894 118.968C106.894 119.321 105.658 120.689 104.677 121.498"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_286"
            d="M104.201 119.553C103.132 120.457 102.258 120.99 102.223 120.767C102.125 120.19 105.949 116.834 106.435 117.066C106.435 117.462 105.066 118.787 104.174 119.519"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_287"
            d="M103.96 117.659C103.369 118.158 102.3 118.967 102.08 118.873C101.912 118.374 105.779 115.009 106.3 115.198C105.65 116.12 104.858 116.938 103.952 117.625"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_288"
            d="M104.024 115.741C103.379 116.266 102.39 116.98 102.258 116.911C102.161 116.429 105.993 113.142 106.487 113.28C105.792 114.19 104.979 115.008 104.068 115.715"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_289"
            d="M104.499 113.71C104.332 113.847 102.839 115.078 102.627 114.992C102.583 114.57 106.53 111.214 106.857 111.395C106.156 112.234 105.366 112.998 104.499 113.675"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_290"
            d="M117.886 102.583C117.296 103.051 116.642 103.436 115.943 103.727C116.323 103.047 119.82 100.483 120.359 100.466C119.593 101.256 118.747 101.968 117.833 102.591"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_291"
            d="M117.286 104.424C117.118 104.553 115.617 105.612 115.414 105.5C115.414 105.044 119.388 102.058 119.891 102.161C119.109 103.011 118.219 103.76 117.242 104.39"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_292"
            d="M117.233 106.111C117.057 106.249 115.467 107.41 115.158 107.316C115.379 106.61 119.149 103.788 119.644 103.96C118.943 104.782 118.133 105.508 117.233 106.12"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_293"
            d="M271.049 346.514C270.775 348.631 268.4 350.119 266.775 347.916C265.247 345.86 266.855 343.33 268.629 343.218C270.396 343.355 271.19 345.231 271.031 346.514"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_294"
            d="M281.582 346.445C281.45 348.501 279.198 350.223 277.511 348.26C275.648 346.109 277.22 343.433 279.172 343.304C280.567 343.304 281.697 344.792 281.582 346.445Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_295"
            d="M292.17 346.367C292.02 348.088 289.768 350.446 287.834 348.088C287.505 347.691 287.287 347.217 287.2 346.714C287.114 346.21 287.161 345.693 287.339 345.213C287.516 344.732 287.817 344.304 288.213 343.97C288.609 343.636 289.087 343.406 289.6 343.304C290.336 343.433 290.999 343.815 291.471 344.38C291.942 344.945 292.19 345.656 292.17 346.384"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_296"
            d="M302.492 346.35C302.254 348.484 299.914 350.067 298.227 347.925C297.91 347.525 297.702 347.053 297.621 346.554C297.541 346.055 297.591 345.544 297.767 345.068C297.943 344.593 298.24 344.169 298.629 343.835C299.018 343.5 299.487 343.268 299.993 343.157C300.371 343.209 300.734 343.333 301.062 343.522C301.39 343.711 301.677 343.962 301.905 344.259C302.133 344.557 302.299 344.896 302.392 345.256C302.485 345.616 302.504 345.991 302.448 346.358"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_297"
            d="M313.064 346.17C312.825 347.985 310.635 349.93 308.878 347.744C308.537 347.346 308.308 346.868 308.214 346.357C308.12 345.846 308.164 345.32 308.343 344.831C308.521 344.342 308.827 343.906 309.231 343.568C309.634 343.229 310.121 342.999 310.644 342.899C312.075 342.899 313.293 344.526 313.09 346.17"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_298"
            d="M323.687 345.481C323.636 346.148 323.37 346.783 322.925 347.294C322.481 347.804 321.882 348.164 321.214 348.321C319.095 348.252 317.505 345.36 319.757 343.157C320.107 342.778 320.588 342.536 321.108 342.478C321.842 342.577 322.513 342.931 323 343.475C323.487 344.019 323.756 344.716 323.757 345.438"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_299"
            d="M333.972 345.154C333.76 347.314 331.438 348.975 329.725 346.875C329.414 346.471 329.21 345.997 329.133 345.497C329.055 344.998 329.106 344.487 329.281 344.011C329.457 343.535 329.75 343.109 330.136 342.771C330.521 342.432 330.987 342.193 331.491 342.073C333.081 342.073 334.14 343.726 333.964 345.18"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_300"
            d="M344.506 344.328C344.454 346.445 342.175 348.364 340.409 346.427C340.045 346.033 339.793 345.553 339.676 345.035C339.56 344.517 339.583 343.979 339.744 343.472C339.906 342.966 340.199 342.509 340.595 342.146C340.992 341.783 341.479 341.527 342.008 341.402C343.306 341.333 344.542 342.77 344.506 344.302"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_301"
            d="M354.988 343.734C354.794 345.455 352.578 347.839 350.706 345.559C350.384 345.158 350.17 344.685 350.083 344.183C349.996 343.682 350.039 343.167 350.207 342.685C350.375 342.203 350.664 341.77 351.047 341.425C351.43 341.079 351.895 340.832 352.401 340.705C353.148 340.792 353.832 341.154 354.314 341.717C354.795 342.281 355.036 343.003 354.988 343.734Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_302"
            d="M365.162 342.96C364.967 344.819 362.927 346.893 361.091 344.819C360.745 344.423 360.507 343.948 360.4 343.438C360.293 342.929 360.32 342.401 360.479 341.905C360.638 341.408 360.923 340.959 361.308 340.599C361.693 340.239 362.165 339.979 362.68 339.845C364.446 339.845 365.268 341.686 365.144 342.96"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_303"
            d="M375.511 341.815C375.396 344.138 373.012 345.722 371.414 343.889C369.816 342.056 371.299 339.061 373.012 338.881C374.725 338.7 375.573 340.541 375.511 341.815Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_304"
            d="M385.78 340.723C385.613 342.444 383.484 344.939 381.604 342.702C381.287 342.3 381.075 341.83 380.986 341.33C380.896 340.831 380.932 340.319 381.09 339.836C381.248 339.353 381.523 338.915 381.892 338.558C382.262 338.202 382.714 337.938 383.211 337.788C384.677 337.737 385.904 339.398 385.78 340.723Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_305"
            d="M395.714 339.475C395.59 341.42 393.488 343.597 391.678 341.531C391.361 341.131 391.146 340.664 391.051 340.167C390.956 339.67 390.984 339.158 391.133 338.673C391.281 338.189 391.546 337.746 391.905 337.381C392.264 337.016 392.707 336.74 393.197 336.575C394.848 336.471 395.758 338.201 395.678 339.475"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_306"
            d="M138.328 352.297C138.266 353.269 137.639 354.767 136.262 354.646C134.178 354.078 133.312 350.343 135.511 349.018C135.751 348.865 136.039 348.8 136.323 348.837C137.754 349.207 138.425 351.118 138.337 352.28"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_307"
            d="M129.771 350.869C129.586 352.185 128.182 354.156 126.628 352.314C124.641 349.948 126.151 347.099 127.643 347.246H127.74C129.021 347.607 129.939 349.569 129.771 350.86"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_308"
            d="M121.225 349.147C121.057 350.868 119.6 352.263 118.249 350.627C116.483 348.528 117.534 345.275 119.326 345.533C120.73 345.955 121.313 347.9 121.189 349.147"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_309"
            d="M112.942 347.288C112.8 348.949 111.441 350.377 110.16 348.889C108.279 346.694 109.357 343.588 110.964 343.674H111.149C112.182 343.94 113.074 345.713 112.915 347.262"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_310"
            d="M105.082 345.524C104.914 346.849 103.545 348.656 102.221 346.866C100.455 344.474 101.665 341.574 103.272 341.815C104.393 342.228 105.241 344.259 105.082 345.524Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_311"
            d="M97.2856 343.459C97.1443 345.223 95.9346 346.367 94.7778 345.128C93.0824 343.312 93.78 339.758 95.3871 339.784C95.4605 339.776 95.5345 339.776 95.6079 339.784C96.7558 340.206 97.3739 342.004 97.2856 343.433"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_312"
            d="M370.804 359.697C370.715 361.358 368.49 364 366.6 361.891C364.711 359.783 366.6 357.167 368.287 356.883C369.541 356.78 370.883 358.277 370.804 359.697Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_313"
            d="M200.406 145.369C199.647 146.651 197.889 147.951 197.563 146.47C197.174 144.792 199.187 142.658 200.344 142.71C201.165 142.934 201.033 144.293 200.397 145.369"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_314"
            d="M206.235 146.135C205.449 147.659 203.789 148.665 203.391 147.512C202.844 145.937 204.698 143.511 206.04 143.562C206.791 143.683 206.924 144.888 206.252 146.144"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_315"
            d="M212.115 146.978C211.497 148.131 209.704 149.706 209.219 148.14C208.645 146.324 210.861 144.25 211.726 144.345C212.592 144.44 212.689 145.877 212.115 146.978Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_316"
            d="M218.031 147.564C217.404 149.036 215.77 150.301 215.187 149.019C214.401 147.246 216.512 144.957 217.545 145.06C218.578 145.163 218.428 146.643 218.031 147.564Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_317"
            d="M360.367 360.704C360.261 362.571 358.133 364.792 356.331 362.873C355.967 362.473 355.715 361.989 355.599 361.467C355.482 360.945 355.505 360.403 355.666 359.892C355.826 359.382 356.119 358.92 356.515 358.551C356.911 358.182 357.399 357.919 357.93 357.787C359.325 357.683 360.464 359.052 360.367 360.704Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_318"
            d="M314.431 150.274C314.546 151.049 314.334 152.323 313.301 152.46C311.534 152.366 310.545 149.242 311.949 148.158C312.094 148.032 312.277 147.956 312.47 147.942C313.301 147.942 314.281 149.087 314.448 150.309"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_319"
            d="M321.045 149.947C321.283 151.462 320.33 152.649 319.12 151.763C317.354 150.489 317.53 147.744 318.978 147.521C319.817 147.521 320.842 148.691 321.045 149.947Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_320"
            d="M327.615 149.38C327.827 150.43 327.226 152.271 325.778 151.325C323.853 150.068 324.065 147.34 325.31 147.091H325.363C326.29 147.091 327.421 148.399 327.615 149.388"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_321"
            d="M334.025 148.975C334.343 150.447 333.531 151.634 332.259 150.799C330.272 149.457 330.414 146.815 331.694 146.565C332.789 146.565 333.769 147.968 333.99 148.975"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_322"
            d="M340.517 148.364C340.843 149.663 340.252 151.006 338.91 150.223C336.941 149.07 336.808 146.342 338.027 145.98H338.168C339.051 145.911 340.234 147.125 340.552 148.39"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_323"
            d="M349.884 361.548C349.854 362.212 349.6 362.849 349.164 363.36C348.727 363.871 348.13 364.229 347.465 364.379C345.434 364.379 343.853 361.565 345.955 359.388C346.323 358.927 346.856 358.62 347.447 358.527C348.922 358.45 349.99 360.085 349.893 361.496"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_324"
            d="M339.572 362.374C339.307 364.095 336.976 366.401 335.156 363.966C334.862 363.558 334.676 363.085 334.615 362.59C334.553 362.095 334.617 361.593 334.803 361.128C334.988 360.664 335.288 360.25 335.676 359.926C336.064 359.601 336.529 359.375 337.029 359.267C338.415 359.207 339.801 360.894 339.58 362.374"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_325"
            d="M328.958 363.019C328.728 364.843 326.547 366.848 324.772 364.697C324.441 364.291 324.22 363.81 324.132 363.299C324.043 362.787 324.089 362.262 324.266 361.773C324.442 361.283 324.743 360.846 325.141 360.501C325.538 360.156 326.019 359.915 326.538 359.8C327.978 359.74 329.187 361.332 328.958 363.019Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_326"
            d="M318.38 363.285C318.351 363.944 318.095 364.574 317.652 365.072C317.21 365.571 316.607 365.909 315.942 366.031C313.823 365.944 312.26 362.821 314.635 360.807C314.995 360.476 315.459 360.273 315.951 360.23C317.461 360.23 318.503 361.848 318.397 363.285"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_327"
            d="M79.9866 320.697C79.7482 322.367 78.5473 323.468 77.6466 322.307C76.0042 320.19 77.0638 316.92 78.4501 316.989C78.5148 316.983 78.5798 316.983 78.6444 316.989C79.7747 317.488 80.1544 319.475 79.9866 320.663"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_328"
            d="M109.691 328.976C109.426 330.697 107.978 332.074 106.751 330.413C105.197 328.313 106.345 325.103 108.04 325.361H108.093C109.4 325.792 109.859 327.762 109.691 328.958"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_329"
            d="M250.342 345.937C250.326 346.582 250.07 347.2 249.622 347.675C249.174 348.15 248.563 348.45 247.905 348.519C245.573 348.209 244.523 344.689 246.88 343.072C247.208 342.823 247.614 342.696 248.028 342.71C249.733 342.882 250.492 344.707 250.377 345.954"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_330"
            d="M260.858 346.11C260.735 347.736 258.669 350.034 256.735 347.96C254.801 345.886 256.655 342.977 258.342 343.003C259.066 343.157 259.712 343.55 260.171 344.117C260.63 344.683 260.873 345.388 260.858 346.11Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_331"
            d="M151.212 165.893C150.329 167.253 148.563 168.355 148.271 167.038C147.918 165.317 150.179 162.735 151.512 162.959C152.201 163.165 152.051 164.68 151.212 165.893Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_332"
            d="M121.577 156.066C120.986 156.797 119.811 157.881 119.052 157.692C118.557 157.374 118.955 156.152 119.608 155.197C120.213 154.315 121.078 153.634 122.089 153.243C122.205 153.211 122.327 153.211 122.443 153.243C122.955 153.493 122.531 154.887 121.56 156.066"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_333"
            d="M117.569 154.173C116.924 154.948 115.635 155.98 114.991 155.825C114.019 155.146 116.465 151.712 118.143 151.411C118.235 151.381 118.334 151.381 118.426 151.411C118.902 151.746 118.355 153.235 117.542 154.165"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_334"
            d="M113.454 152.46C112.394 153.648 110.99 154.414 110.919 153.571C110.805 152.108 113.569 149.156 114.646 149.526C115.176 149.973 114.275 151.548 113.454 152.46Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_335"
            d="M109.86 150.421C108.854 151.54 107.353 152.495 107.344 151.549C107.344 149.957 110.116 147.246 111.088 147.556C111.573 147.969 110.699 149.501 109.86 150.421Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_336"
            d="M106.593 148.493C105.709 149.414 104.235 150.335 104.155 149.69C103.988 148.373 106.919 145.206 107.935 145.611C108.27 146.006 107.458 147.564 106.584 148.485"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_337"
            d="M103.679 146.402C102.54 147.632 101.374 148.26 101.286 147.692C101.091 146.522 104.315 143.08 105.118 143.605C105.489 144.027 104.526 145.498 103.679 146.402Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_338"
            d="M100.951 144.431C99.7943 145.619 98.6729 146.152 98.6817 145.601C98.6817 144.285 101.702 141.178 102.549 141.591C102.858 142.03 101.799 143.545 100.951 144.431Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_339"
            d="M98.787 142.314C98.0895 143.029 96.8885 143.941 96.5265 143.743C96.3234 143.338 97.1799 142.022 97.8599 141.282C98.4735 140.487 99.3156 139.888 100.279 139.561C100.305 139.553 100.333 139.553 100.359 139.561C100.571 139.922 99.6524 141.402 98.7959 142.28"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_340"
            d="M96.7374 140.309C95.6513 141.402 94.6358 142.03 94.5563 141.591C94.3974 140.679 97.6999 137.237 98.4417 137.538H98.4946C98.6801 137.831 97.8235 139.165 96.7286 140.275"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_341"
            d="M95.0252 138.313C93.9215 139.388 92.9678 139.948 92.9766 139.509C92.9766 138.476 96.341 135.111 96.9149 135.542C97.0474 135.946 96.0319 137.323 95.0252 138.313Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_342"
            d="M93.8778 136.093C93.1714 136.799 92.0058 137.719 91.7497 137.521C91.6703 137.194 92.4473 136.033 93.4098 135.034C94.1163 134.312 95.3967 133.313 95.6527 133.468C95.7322 133.83 94.7255 135.189 93.8867 136.05"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_343"
            d="M395.961 374.31C395.67 376.427 393.418 378.26 391.802 376.263C390.186 374.267 392.138 371.754 393.692 371.435C395.228 371.358 396.147 372.89 395.952 374.31"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_344"
            d="M386.045 375.385C385.95 376.053 385.665 376.681 385.222 377.198C384.778 377.716 384.195 378.101 383.537 378.311C381.524 378.388 380.085 375.626 382.319 373.406C382.656 373.025 383.112 372.762 383.617 372.657C384.31 372.708 384.956 373.023 385.413 373.533C385.871 374.043 386.104 374.709 386.063 375.385"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_345"
            d="M365.346 377.778C365.002 379.92 362.6 381.538 361.037 379.395C359.474 377.253 361.47 374.998 363.006 374.74C364.419 374.654 365.593 376.186 365.346 377.778Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_346"
            d="M355.166 378.405C355.066 379.066 354.77 379.684 354.315 380.183C353.86 380.683 353.266 381.043 352.605 381.219C350.495 381.219 349.02 378.423 351.36 376.254C351.716 375.896 352.18 375.658 352.685 375.574C353.39 375.65 354.04 375.985 354.501 376.511C354.962 377.037 355.2 377.714 355.166 378.405Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_347"
            d="M344.614 379.395C344.305 381.521 341.841 383.13 340.278 380.962C340 380.547 339.83 380.073 339.783 379.58C339.736 379.087 339.813 378.59 340.008 378.132C340.203 377.675 340.51 377.271 340.902 376.956C341.294 376.641 341.759 376.423 342.256 376.323C344.022 376.323 344.791 378.165 344.605 379.395"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_348"
            d="M334.088 379.842C333.937 381.761 331.712 383.818 329.849 381.71C328.083 379.756 330.087 377.046 331.668 376.942C332.025 376.97 332.374 377.068 332.691 377.23C333.009 377.393 333.289 377.616 333.515 377.887C333.741 378.159 333.909 378.472 334.007 378.808C334.105 379.144 334.133 379.496 334.088 379.842Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_349"
            d="M98.4514 377.734C98.4514 379.197 97.4094 380.402 96.1732 379.128C94.1776 377.055 94.8398 373.965 96.2968 374.111H96.4558C97.7627 374.602 98.4426 376.512 98.4249 377.7"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_350"
            d="M90.9978 375.566C90.9978 377.098 90.1148 378.147 88.9845 377.192C87.086 375.471 87.2185 372.132 88.7903 372.029C88.8899 372.016 88.9908 372.016 89.0905 372.029C89.9735 372.322 90.9802 374.094 90.9978 375.549"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_351"
            d="M302.096 311.283C301.999 313.091 300.065 315.173 298.149 313.228C296.233 311.283 297.99 308.28 299.615 308.237C301.239 308.194 302.158 310.087 302.096 311.283Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_352"
            d="M312.427 311.076C312.294 313.098 310.14 314.785 308.436 312.909C306.59 310.887 308.127 308.038 309.954 307.961C311.72 308.021 312.498 309.811 312.427 311.076Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_353"
            d="M322.793 310.594C322.742 311.244 322.482 311.862 322.051 312.361C321.62 312.859 321.04 313.214 320.391 313.374C318.29 313.279 316.691 310.138 319.005 308.125C319.323 307.824 319.738 307.638 320.179 307.6C320.917 307.705 321.59 308.066 322.077 308.616C322.563 309.166 322.83 309.868 322.828 310.594"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_354"
            d="M332.878 310.07C332.878 311.042 332.242 312.652 330.617 312.893C328.507 312.841 326.9 309.717 329.213 307.643C329.505 307.343 329.902 307.159 330.326 307.127C332.012 307.127 332.895 308.848 332.886 310.07"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_355"
            d="M343.148 309.475C343.148 311.532 341.047 313.391 339.289 311.558C337.391 309.587 338.768 306.782 340.623 306.532C341.983 306.455 343.157 307.832 343.148 309.475Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_356"
            d="M353.418 308.701C353.418 310.284 351.475 312.797 349.497 310.878C349.098 310.519 348.805 310.062 348.648 309.555C348.492 309.048 348.478 308.509 348.608 307.995C348.737 307.481 349.006 307.01 349.387 306.632C349.767 306.254 350.244 305.982 350.769 305.844C352.005 305.749 353.418 307.273 353.418 308.701Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_357"
            d="M363.359 308.021C363.298 309.811 361.373 311.92 359.483 309.958C359.114 309.591 358.848 309.139 358.71 308.644C358.573 308.148 358.568 307.627 358.696 307.129C358.825 306.632 359.083 306.175 359.445 305.802C359.806 305.429 360.261 305.153 360.763 305.001C362.529 305.001 363.412 306.765 363.342 308.021"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_358"
            d="M373.462 306.852C373.526 307.5 373.352 308.15 372.973 308.687C372.593 309.224 372.032 309.613 371.387 309.786C369.197 309.846 367.776 306.654 369.718 304.683C370.031 304.331 370.464 304.102 370.937 304.038C372.173 303.926 373.445 305.32 373.498 306.852"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_359"
            d="M393.224 304.7C393.224 306.696 391.335 308.564 389.542 306.86C387.617 305.036 388.827 302.161 390.611 301.783C392.129 301.697 393.26 303.254 393.26 304.7"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_360"
            d="M402.956 303.332C403 305.26 401.243 307.213 399.512 305.664C397.473 303.857 398.629 300.845 400.395 300.449C401.64 300.294 402.894 301.714 402.93 303.332"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_361"
            d="M371.564 173.785C372.094 175.351 371.317 176.822 369.921 176.074C367.705 174.895 367.475 171.9 368.774 171.461H368.924C369.851 171.332 371.114 172.459 371.573 173.819"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_362"
            d="M364.385 174.912C364.755 176.332 364.022 177.89 362.495 176.995C360.217 175.652 360.278 172.761 361.718 172.408C362.892 172.348 364.067 173.682 364.367 174.912"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_363"
            d="M357.196 175.842C357.382 176.883 356.755 179.051 354.927 177.726C352.852 176.22 353.161 173.578 354.538 173.286C355.501 173.191 356.966 174.602 357.187 175.867"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_364"
            d="M349.726 176.581C350.026 178.079 349.152 179.662 347.589 178.638C345.611 177.347 345.637 174.335 347.183 174.068C348.472 174.068 349.487 175.54 349.699 176.59"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_365"
            d="M342.237 177.373C342.458 178.87 341.522 180.368 339.994 179.318C337.91 177.881 338.326 175.015 339.791 174.766C341.063 174.766 342.07 176.289 342.229 177.347"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_366"
            d="M312.135 179.327C312.135 180.549 310.987 182.528 309.486 181.117C307.596 179.395 308.656 176.891 309.972 176.693C311.066 176.754 312.126 178.225 312.144 179.327"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_367"
            d="M156.987 167.184C156.104 168.63 154.25 169.826 153.959 168.466C153.588 166.694 155.725 164.164 157.093 164.362C157.826 164.542 157.782 165.893 156.987 167.184Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_368"
            d="M349.055 326.772C348.888 328.631 346.866 330.662 344.994 328.545C344.641 328.16 344.395 327.692 344.281 327.187C344.167 326.682 344.188 326.158 344.343 325.663C344.497 325.169 344.779 324.721 345.162 324.364C345.545 324.007 346.016 323.751 346.53 323.623C347.952 323.563 349.179 325.12 349.055 326.772Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_369"
            d="M359.404 325.783C359.333 327.978 357.205 329.561 355.527 327.978C353.532 326.093 355.024 322.978 356.87 322.814C358.45 322.814 359.457 324.329 359.404 325.783Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_370"
            d="M369.717 324.75C369.717 326.351 367.739 328.916 365.779 327.014C365.388 326.642 365.104 326.176 364.956 325.664C364.808 325.152 364.801 324.611 364.935 324.095C365.069 323.579 365.34 323.106 365.721 322.725C366.101 322.343 366.578 322.065 367.103 321.919C368.331 321.825 369.752 323.331 369.752 324.75"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_371"
            d="M379.705 323.795C379.661 325.714 377.594 327.909 375.731 325.86C373.868 323.812 375.731 321.05 377.126 320.895C378.769 320.835 379.776 322.539 379.705 323.795Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_372"
            d="M389.781 322.771C389.667 324.595 387.706 326.738 385.887 324.733C385.533 324.351 385.282 323.889 385.158 323.388C385.035 322.888 385.041 322.365 385.178 321.868C385.315 321.37 385.578 320.914 385.943 320.542C386.307 320.169 386.762 319.891 387.265 319.733C388.642 319.596 389.914 321.102 389.781 322.771Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_373"
            d="M409.304 319.992C409.251 322.049 407.449 323.916 405.701 322.212C405.333 321.839 405.066 321.383 404.921 320.885C404.777 320.387 404.76 319.862 404.872 319.357C404.985 318.851 405.223 318.38 405.566 317.985C405.909 317.59 406.347 317.283 406.84 317.092C408.332 316.989 409.33 318.538 409.286 319.992"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_374"
            d="M368.914 159.095C369.452 160.55 368.914 161.892 367.58 161.264C365.417 160.24 364.931 157.34 366.088 156.841C366.139 156.82 366.192 156.805 366.247 156.798C367.307 156.729 368.472 157.934 368.896 159.095"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_375"
            d="M362.406 160.085C362.697 161.066 362.406 162.968 360.834 162.185C358.644 161.083 358.344 158.2 359.589 157.753H359.712C360.728 157.753 362.088 159.052 362.406 160.119"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_376"
            d="M355.571 160.92C355.828 161.737 355.827 163.028 354.777 163.243C353.691 163.243 352.746 162.03 352.463 161.436C352.21 161.028 352.088 160.556 352.115 160.08C352.142 159.604 352.315 159.147 352.613 158.768C352.734 158.657 352.889 158.588 353.055 158.57C354.114 158.527 355.227 159.741 355.598 160.911"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_377"
            d="M348.684 161.866C349.002 163.243 348.269 164.698 346.83 163.76C344.675 162.366 344.808 159.655 346.185 159.362C347.377 159.362 348.446 160.817 348.684 161.866Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_378"
            d="M341.842 162.477C342.054 163.578 341.471 165.549 339.802 164.404C337.621 162.924 338.257 160.274 339.272 160.102H339.343C340.411 160.102 341.63 161.444 341.842 162.52"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_379"
            d="M334.795 163.14C335.095 164.723 334.132 165.988 332.773 165.076C330.856 163.785 331.077 161.014 332.358 160.679H332.499C333.382 160.619 334.547 161.849 334.795 163.157"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_380"
            d="M327.721 163.691C327.925 165.128 327.024 166.608 325.593 165.575C323.677 164.181 324.128 161.393 325.452 161.195C326.618 161.195 327.545 162.658 327.695 163.691"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_381"
            d="M313.61 164.637C313.689 166.117 312.55 167.494 311.243 166.298C309.654 164.844 310.086 162.202 311.543 161.995C312.515 161.995 313.539 163.26 313.61 164.628"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_382"
            d="M306.449 164.86C306.502 166.28 305.415 167.915 303.976 166.582C302.21 164.86 303.261 162.408 304.471 162.339C305.68 162.27 306.413 163.871 306.449 164.921"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_383"
            d="M203.284 160.317C202.621 161.685 200.864 163.079 200.308 161.539C199.628 159.663 201.782 157.434 202.869 157.554C203.955 157.675 203.752 159.276 203.284 160.317Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_384"
            d="M197.122 159.457C196.468 160.653 194.57 162.262 194.084 160.61C193.545 158.786 195.735 156.548 196.733 156.677C197.545 156.884 197.74 158.312 197.113 159.457"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_385"
            d="M167.726 154.07C166.843 155.343 165.147 156.445 164.865 155.283C164.423 153.562 166.896 151.058 168.008 151.273C168.785 151.548 168.485 152.994 167.726 154.07Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_386"
            d="M134.02 143.907C133.242 144.767 131.317 146.075 131.309 144.991C131.309 143.399 133.958 140.877 135.053 141.136C135.547 141.488 134.894 142.986 134.02 143.907Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_387"
            d="M323.747 380.385C323.535 382.106 321.266 384.404 319.332 382.106C317.566 379.955 319.782 377.502 321.204 377.425C321.926 377.523 322.585 377.879 323.052 378.425C323.519 378.971 323.76 379.667 323.729 380.377"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_388"
            d="M151.822 371.9C151.822 372.838 151.177 374.284 149.765 374.163C147.619 373.587 146.63 369.86 148.881 368.656C149.122 368.512 149.407 368.457 149.685 368.501C151.054 368.819 151.937 370.549 151.866 371.9"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_389"
            d="M142.983 370.523C142.851 371.9 141.305 373.793 139.689 371.865C137.676 369.464 139.204 366.874 140.731 367.038C141.985 367.365 143.054 369.309 142.948 370.48"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_390"
            d="M134.107 369.086C133.957 370.738 132.518 372.021 131.078 370.411C129.056 368.148 130.425 365.334 131.882 365.437H132.014C133.365 365.799 134.231 367.649 134.107 369.069"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_391"
            d="M125.48 367.39C125.365 369.017 124.014 370.334 122.646 368.819C120.562 366.53 121.807 363.733 123.264 363.759H123.449C124.765 364.155 125.577 365.979 125.48 367.373"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_392"
            d="M117.285 365.498C117.285 366.487 116.561 367.855 115.413 367.726C114.124 367.322 113.488 365.541 113.409 364.93C113.279 364.394 113.328 363.833 113.549 363.326C113.77 362.82 114.151 362.396 114.636 362.116C114.818 362.031 115.023 362.01 115.219 362.055C116.367 362.425 117.338 364.284 117.303 365.498"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_393"
            d="M109.101 363.673C109.101 365.342 107.839 366.556 106.664 365.463C104.686 363.613 105.26 360.042 107.008 360.145H107.185C108.236 360.429 109.137 362.064 109.101 363.647"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_394"
            d="M101.205 361.728C101.205 363.449 99.978 364.602 98.8212 363.449C96.8698 361.513 97.5673 358.286 99.0508 358.191C99.1386 358.178 99.2279 358.178 99.3157 358.191C100.464 358.596 101.232 360.369 101.205 361.711"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_395"
            d="M93.735 359.637C93.735 360.979 92.693 362.64 91.4744 361.479C89.4611 359.551 90.0528 356.315 91.5451 356.143C91.6358 356.129 91.7281 356.129 91.8188 356.143C92.8696 356.548 93.7527 358.389 93.735 359.585"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_396"
            d="M71.9512 299.924C71.6421 301.645 70.4235 302.772 69.6111 301.55C68.163 299.373 69.5228 296.068 70.8209 296.292H70.9092C71.9247 296.791 72.1719 298.702 71.9512 299.889"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_397"
            d="M245.625 328.15C245.528 329.182 244.583 330.731 243.135 330.792C240.777 330.516 239.735 327.152 242.102 325.413C242.42 325.152 242.824 325.008 243.241 325.009C243.953 325.184 244.583 325.592 245.023 326.166C245.464 326.739 245.689 327.443 245.66 328.158"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_398"
            d="M255.7 328.648C255.48 330.421 253.422 332.314 251.656 330.249C249.766 328.055 251.744 325.309 253.36 325.361C254.976 325.413 255.859 327.392 255.7 328.648Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_399"
            d="M266.05 328.985C265.767 330.964 263.604 332.479 261.97 330.491C260.204 328.314 262.111 325.612 263.736 325.62C265.361 325.629 266.297 327.513 266.085 328.985"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_400"
            d="M276.523 328.864C276.435 329.905 275.49 331.445 274.007 331.566C271.861 331.385 270.386 328.124 272.885 326.213C273.197 325.947 273.593 325.793 274.007 325.774C274.733 325.918 275.385 326.306 275.847 326.871C276.308 327.436 276.551 328.142 276.532 328.864"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_401"
            d="M286.721 329.079C286.509 330.878 284.381 332.797 282.597 330.637C282.251 330.245 282.016 329.771 281.916 329.262C281.816 328.754 281.854 328.228 282.028 327.739C282.201 327.25 282.503 326.813 282.904 326.473C283.304 326.133 283.789 325.901 284.311 325.801C285.944 325.93 286.889 327.625 286.721 329.079Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_402"
            d="M297.221 328.735C297.141 330.74 295.031 332.555 293.282 330.834C291.278 328.872 292.859 325.774 294.748 325.723C295.474 325.829 296.131 326.198 296.59 326.757C297.048 327.315 297.273 328.021 297.221 328.735Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_403"
            d="M307.721 328.649C307.562 330.172 305.407 332.788 303.394 330.258C303.077 329.862 302.869 329.394 302.789 328.898C302.709 328.402 302.759 327.895 302.936 327.423C303.113 326.951 303.409 326.531 303.798 326.202C304.187 325.873 304.656 325.646 305.16 325.542C305.896 325.68 306.558 326.068 307.028 326.637C307.499 327.205 307.747 327.918 307.729 328.649"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_404"
            d="M317.963 328.382C317.796 330.181 315.72 332.203 313.884 330.146C313.52 329.764 313.266 329.296 313.147 328.788C313.028 328.28 313.048 327.75 313.205 327.252C313.363 326.754 313.652 326.305 314.044 325.951C314.436 325.596 314.917 325.349 315.438 325.232C317.08 325.284 318.087 326.954 317.946 328.382"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_405"
            d="M328.436 327.918C328.268 330.095 326.052 331.541 324.427 329.863C322.661 328.012 323.88 325.044 325.981 324.794C327.315 324.794 328.551 326.334 328.436 327.918Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_406"
            d="M338.901 327.16C338.868 327.824 338.62 328.461 338.194 328.981C337.767 329.5 337.184 329.875 336.526 330.051C334.477 330.051 332.835 327.1 334.963 324.966C335.299 324.584 335.767 324.336 336.278 324.269C337.003 324.36 337.67 324.702 338.156 325.233C338.643 325.764 338.916 326.448 338.927 327.16"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_407"
            d="M97.1 200.066C96.4643 201.09 94.7335 202.647 94.3626 201.288C93.8593 199.394 96.0581 196.46 97.312 196.787C97.9919 197.191 97.7711 198.981 97.1 200.066Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_408"
            d="M74.635 246.028C74.2994 246.931 73.4429 248.282 72.5422 248.024C71.0676 247.164 72.1802 242.981 73.9904 242.594C74.0995 242.56 74.2168 242.56 74.3259 242.594C75.0942 243.042 75.1118 244.72 74.6438 246.036"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_409"
            d="M337.902 468.944C337.336 470.665 334.643 471.775 333.698 469.805C333.071 468.471 334.581 466.87 336.091 466.681C336.386 466.657 336.683 466.705 336.953 466.822C337.224 466.938 337.46 467.119 337.641 467.348C337.821 467.576 337.939 467.846 337.985 468.131C338.03 468.416 338.002 468.707 337.902 468.979"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_410"
            d="M216.936 83.4792C215.805 84.2451 214.472 84.8561 214.525 84.4689C214.613 83.7632 217.766 81.6721 218.64 81.7409C218.949 81.913 217.828 82.8854 216.936 83.462"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_411"
            d="M212.601 94.5969C211.559 95.4575 210.234 96.1631 210.234 95.6382C210.234 94.7776 212.954 92.4886 213.917 92.5746C214.349 92.7898 213.431 93.8827 212.601 94.5969Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_412"
            d="M219.542 84.098C218.297 85.0016 217.025 85.5609 217.069 85.1737C217.149 84.4422 220.213 82.3081 221.061 82.3941C221.387 82.5146 220.54 83.3752 219.542 84.1152"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_413"
            d="M119.661 207.811C118.902 209.248 117.215 210.393 116.739 209.05C116.006 206.968 118.187 204.309 119.52 204.515C120.403 204.894 120.324 206.555 119.661 207.811Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_414"
            d="M126.036 209.489C125.224 211.158 123.537 212.156 122.981 210.857C122.195 208.99 124.367 205.926 125.763 206.279C126.778 206.666 126.575 208.387 126.036 209.489Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_415"
            d="M132.678 211.064C132.254 211.985 131.097 213.241 130.108 213.12C128.607 212.561 129.225 209.162 131.468 208.086C131.688 207.967 131.943 207.922 132.192 207.957C133.075 208.284 133.207 209.971 132.695 211.064"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_416"
            d="M222.092 84.847C221.209 85.4924 219.62 86.3529 219.717 85.8452C219.885 84.9847 222.764 83.0226 223.532 83.0742C223.823 83.2205 222.958 84.2016 222.092 84.847Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_417"
            d="M139.23 212.81C138.47 214.402 136.669 215.573 136.095 213.998C135.318 211.881 137.358 209.394 138.744 209.54C139.857 209.91 139.716 211.709 139.195 212.81"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_418"
            d="M286.033 226.786C285.989 227.698 285.379 229.178 283.975 229.282C282.112 229.109 280.832 226.098 282.995 224.299C283.25 224.047 283.595 223.902 283.958 223.895C285.282 223.989 286.121 225.495 286.051 226.786"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_419"
            d="M294.987 226.854C294.837 228.627 293.08 230.133 291.623 228.498C289.927 226.605 291.199 223.971 292.85 223.816C294.395 223.937 295.084 225.658 294.978 226.854"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_420"
            d="M303.985 226.605C303.861 228.257 302.131 230.098 300.453 228.231C298.687 226.2 300.4 223.722 301.627 223.67C302.855 223.619 304.064 225.245 303.967 226.605"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_421"
            d="M215.955 95.2854C215.24 95.9481 213.483 97.1012 213.518 96.3869C213.58 95.251 216.317 93.2029 217.05 93.332C217.421 93.4955 216.768 94.5712 215.991 95.2854"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_422"
            d="M312.753 226.175C312.753 227.095 312.276 228.618 310.872 228.756C309.027 228.662 307.525 225.718 309.495 223.834C309.615 223.698 309.763 223.587 309.928 223.507C310.093 223.427 310.273 223.38 310.457 223.369C311.905 223.421 312.744 225.039 312.771 226.14"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_423"
            d="M156.774 460.872C156.942 461.62 156.589 462.653 155.503 462.541C153.56 462.076 151.874 459.254 153.463 458.359C153.655 458.251 153.88 458.212 154.099 458.247C154.712 458.466 155.268 458.815 155.726 459.269C156.184 459.723 156.533 460.27 156.748 460.872"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_424"
            d="M321.699 225.779C321.814 227.69 320.215 229.222 318.688 227.965C316.683 226.364 317.425 223.275 319.359 223.034C320.339 223.034 321.602 224.222 321.699 225.779Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_425"
            d="M330.661 225.46C330.661 227.095 329.027 229.006 327.288 227.181C325.415 225.245 326.705 222.75 328.171 222.552C329.495 222.603 330.67 224.204 330.67 225.426"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_426"
            d="M227.532 86.0514C226.401 87.0238 225.271 87.566 225.245 87.1443C225.2 86.2837 228.088 84.1668 228.777 84.2787C229.183 84.4422 228.291 85.4318 227.567 86.0514"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_427"
            d="M339.351 224.738C339.492 226.459 338.106 228.18 336.437 226.941C334.388 225.383 334.989 222.285 336.887 222.01C337.991 221.949 339.227 223.326 339.351 224.738Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_428"
            d="M348.207 224.238C348.304 225.959 346.873 227.577 345.178 226.157C343.173 224.436 344.048 221.502 345.646 221.347C347.023 221.347 348.136 222.861 348.207 224.238Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_429"
            d="M219.275 95.8964C218.392 96.757 216.829 97.8069 216.856 97.0238C216.9 95.8534 219.505 93.8397 220.202 93.9688C220.67 94.1409 219.946 95.2166 219.319 95.8964"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_430"
            d="M230.393 86.6804C229.616 87.3688 228.132 88.324 228.159 87.7388C228.159 86.7492 230.869 84.7786 231.47 84.8818C231.814 85.0195 231.161 86.0005 230.393 86.6804Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_431"
            d="M365.541 222.302C365.823 223.98 364.658 225.83 362.936 224.72C360.552 223.146 361.17 220.056 362.936 219.712C364.04 219.6 365.32 220.865 365.585 222.294"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_432"
            d="M351.253 441.225C350.785 442.946 348.004 444.667 346.768 442.404C345.885 440.752 347.774 438.962 349.134 438.781C349.446 438.78 349.755 438.846 350.038 438.974C350.322 439.102 350.572 439.29 350.773 439.523C350.973 439.756 351.119 440.03 351.199 440.324C351.279 440.618 351.291 440.926 351.236 441.225"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_433"
            d="M360.913 440.485C360.401 442.336 357.867 443.721 356.63 441.785C355.641 440.236 357.443 438.222 359.006 437.981C359.32 437.972 359.631 438.038 359.914 438.171C360.197 438.305 360.442 438.503 360.629 438.749C360.817 438.995 360.94 439.281 360.99 439.583C361.039 439.886 361.013 440.195 360.913 440.485Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_434"
            d="M370.76 439.504C370.239 441.363 367.599 442.791 366.486 440.821C365.603 439.246 367.369 437.275 368.888 437.026C370.654 436.957 371.025 438.463 370.734 439.504"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_435"
            d="M64.2514 241.277C63.5979 242.938 62.4853 243.704 61.9908 242.792C61.1078 240.993 62.9357 237.327 64.1189 237.74H64.1631C64.9313 238.265 64.7106 240.124 64.2514 241.26"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_436"
            d="M363.845 453.806C363.324 455.45 360.569 457.145 359.474 455.046C358.635 453.428 360.763 451.758 361.92 451.552C362.211 451.538 362.501 451.591 362.767 451.706C363.033 451.821 363.268 451.995 363.453 452.214C363.638 452.433 363.769 452.69 363.834 452.967C363.899 453.243 363.896 453.531 363.827 453.806"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_437"
            d="M354.159 454.805C353.585 456.578 351.015 457.851 349.938 455.924C349.055 454.4 350.821 452.619 352.331 452.404C352.635 452.385 352.939 452.441 353.216 452.566C353.492 452.691 353.732 452.882 353.914 453.12C354.096 453.359 354.213 453.638 354.256 453.932C354.299 454.227 354.265 454.527 354.159 454.805Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_438"
            d="M344.507 455.365C344.366 455.94 344.052 456.461 343.605 456.861C343.158 457.261 342.599 457.522 341.999 457.611C340.48 457.611 338.953 455.89 340.869 454.031C341.316 453.572 341.906 453.27 342.547 453.17C342.839 453.149 343.132 453.194 343.403 453.303C343.673 453.411 343.914 453.58 344.106 453.796C344.297 454.012 344.434 454.269 344.505 454.546C344.577 454.823 344.58 455.112 344.516 455.391"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_439"
            d="M335.032 456.027C334.67 457.628 331.968 459.315 330.617 457.249C329.522 455.528 331.862 453.807 332.887 453.807C333.181 453.801 333.474 453.856 333.746 453.967C334.018 454.078 334.263 454.244 334.465 454.453C334.667 454.662 334.822 454.911 334.92 455.182C335.017 455.453 335.056 455.741 335.032 456.027Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_440"
            d="M72.8616 207.32C72.0757 208.731 70.813 209.652 70.5393 208.688C70.0094 206.847 72.3053 203.447 73.2855 203.972C73.9213 204.48 73.4356 206.27 72.8528 207.32"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_441"
            d="M157.066 448.248C157.225 449.031 156.987 450.167 155.706 450.055C153.578 449.564 152.263 446.613 153.764 445.666C154.011 445.525 154.302 445.473 154.585 445.52C155.204 445.733 155.759 446.094 156.197 446.571C156.635 447.048 156.943 447.624 157.093 448.248"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_442"
            d="M148.811 446.957C148.988 448.076 148.184 449.35 146.577 448.214C144.378 446.665 144.493 444.307 145.88 444.083C146.003 444.066 146.127 444.066 146.25 444.083C146.893 444.307 147.466 444.689 147.914 445.19C148.363 445.692 148.671 446.298 148.811 446.949"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_443"
            d="M69.3203 243.799C68.8081 244.987 67.4836 246.45 66.9185 245.185C65.9736 243.042 68.0223 239.815 69.0466 240.219C69.7265 240.658 69.8501 242.491 69.2938 243.79"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_444"
            d="M140.907 445.529C141.022 446.441 140.324 447.878 138.761 446.751C136.474 445.099 136.545 442.741 137.949 442.577C138.061 442.564 138.173 442.564 138.285 442.577C138.931 442.833 139.507 443.232 139.967 443.743C140.427 444.254 140.758 444.862 140.934 445.52"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_445"
            d="M193.094 88.4016C191.84 89.1847 190.445 89.7526 190.551 89.3224C190.736 88.5307 194.277 86.3707 195.116 86.5342C195.381 86.7751 194.118 87.7734 193.094 88.4016Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_446"
            d="M132.987 443.893C133.296 445.305 132.404 446.027 131.221 445.322C129.331 444.212 128.572 441.467 129.976 441.019C130.137 440.975 130.308 440.975 130.47 441.019C131.096 441.271 131.652 441.664 132.091 442.167C132.531 442.67 132.84 443.268 132.995 443.91"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_447"
            d="M77.3464 209.609C76.896 210.47 75.9335 211.726 75.1565 211.468C73.982 210.607 75.6156 206.7 77.2934 206.356C77.4047 206.317 77.5264 206.317 77.6378 206.356C78.1941 206.666 78.0616 208.232 77.364 209.583"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_448"
            d="M125.312 442.173C125.507 442.939 125.383 444.074 124.244 443.894C123.118 443.377 122.246 442.45 121.816 441.312C121.641 440.971 121.597 440.58 121.691 440.209C121.784 439.839 122.01 439.513 122.328 439.29C122.488 439.238 122.662 439.238 122.822 439.29C123.435 439.555 123.978 439.951 124.412 440.449C124.846 440.946 125.16 441.532 125.33 442.164"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_449"
            d="M69.0647 184.128C68.2612 185.299 67.2457 186.133 67.0867 185.514C66.7423 184.171 69.2678 180.35 70.1597 180.875C70.4776 181.349 69.8506 182.975 69.0647 184.128Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_450"
            d="M203.532 92.1793C202.366 93.0399 200.98 93.6595 201.086 93.1431C201.262 92.2826 204.282 90.0796 205.183 90.2001C205.545 90.4324 204.432 91.5167 203.532 92.1793Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_451"
            d="M71.9962 186.504C71.2633 187.58 70.0182 188.595 69.8681 187.829C69.5767 186.366 71.9785 182.769 72.9146 183.251C73.2766 183.681 72.7026 185.445 72.0315 186.495"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_452"
            d="M75.184 188.871C74.3009 190.239 73.1883 190.893 73.0117 190.187C72.6408 188.681 75.0074 185.101 76.0582 185.557C76.5173 186.048 75.9699 187.692 75.1751 188.871"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_453"
            d="M78.953 191.142C78.1582 192.381 76.7895 193.491 76.6041 192.415C76.2862 190.634 78.7675 187.398 79.6594 187.906C80.2334 188.396 79.6594 190.1 78.953 191.142Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_454"
            d="M83.0857 193.388C82.5471 194.249 81.4256 195.368 80.7722 195.109C79.7037 194.249 81.6552 190.437 83.2888 190.161C83.3719 190.134 83.4619 190.134 83.5449 190.161C84.0747 190.583 83.7303 192.287 83.0857 193.337"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_455"
            d="M87.2904 195.788C86.3456 197.269 85.0828 197.923 84.8267 197.053C84.3499 195.401 86.531 192.045 87.8202 192.45C88.456 192.923 88.0498 194.584 87.2904 195.788Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_456"
            d="M347.395 468.161C346.821 469.882 344.11 471.121 343.085 469.176C342.388 467.834 344.119 466.19 345.523 466.001C345.806 465.975 346.093 466.016 346.356 466.121C346.62 466.226 346.854 466.392 347.038 466.604C347.222 466.816 347.351 467.068 347.413 467.34C347.475 467.611 347.469 467.893 347.395 468.161Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_457"
            d="M92.0763 197.889C91.1932 199.412 89.7362 200.315 89.4272 199.18C88.9239 197.321 91.3522 194.171 92.4295 194.653C93.1712 195.118 92.6944 196.83 92.0675 197.889"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_458"
            d="M133.701 456.483C133.939 457.344 133.436 458.6 132.156 457.86C129.966 456.595 129.294 453.97 130.699 453.729C130.815 453.705 130.935 453.705 131.052 453.729C132.272 454.264 133.224 455.248 133.701 456.466"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_459"
            d="M126.382 454.943C126.682 456.036 126.117 456.844 125.022 456.242C123.133 455.192 122.029 452.413 123.362 452.06C123.505 452.03 123.652 452.03 123.795 452.06C124.41 452.333 124.958 452.73 125.402 453.226C125.847 453.721 126.178 454.304 126.373 454.934"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_460"
            d="M252.663 100.346C252.389 100.957 251.709 101.929 251.038 101.955C250.031 101.74 250.87 99.2528 252.204 98.5127C252.303 98.449 252.42 98.416 252.539 98.4181C253.087 98.5213 253.051 99.511 252.672 100.363"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_461"
            d="M391.079 235.977C391.264 237.534 390.134 239.488 388.315 238.145C386.098 236.519 386.716 233.524 388.315 233.154C389.507 233.008 390.867 234.402 391.052 235.977"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_462"
            d="M256.663 100.716C256.195 101.86 255.162 102.721 254.791 101.989C254.199 100.828 255.674 98.6334 256.442 98.6936C257.078 98.8055 256.981 99.9414 256.663 100.716Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_463"
            d="M80.3053 248.463C79.7843 249.814 78.3626 251.285 77.7004 249.814C76.8173 247.809 78.654 244.47 79.9344 244.934C80.8704 245.425 80.7557 247.301 80.3053 248.463Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_464"
            d="M86.3983 250.589C86.0363 251.561 85.012 252.809 84.1554 252.611C83.2724 252.146 83.2724 250.391 83.4225 249.806C83.5154 249.261 83.7358 248.745 84.0666 248.298C84.3974 247.85 84.8297 247.482 85.3299 247.224C85.484 247.17 85.6525 247.17 85.8067 247.224C86.6367 247.646 86.831 249.496 86.4072 250.666"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_465"
            d="M302.36 101.008C302.616 102.119 302.166 102.936 301.477 102.557C300.091 101.766 299.906 99.3562 300.674 99.1152H300.762C301.424 99.1152 302.113 100.165 302.325 101.06"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_466"
            d="M82.1233 211.95C81.5758 212.991 79.9511 214.695 79.4742 213.378C78.7766 211.296 81.2403 208.215 82.1851 208.68C82.8121 209.102 82.6531 210.857 82.088 211.95"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_467"
            d="M92.5003 252.895C91.9087 254.384 90.4605 255.614 89.7541 254.186C88.721 252.077 90.434 249.023 91.9705 249.307C92.8535 249.616 93.0831 251.423 92.5003 252.895Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_468"
            d="M86.9815 214.342C86.1603 215.874 84.7651 216.752 84.4119 215.582C83.8202 213.62 85.86 210.573 87.1316 210.917C87.8822 211.356 87.6437 213.069 86.9726 214.36"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_469"
            d="M346.388 240.864C346.388 242.318 344.851 244.607 342.935 242.714C341.019 240.821 342.273 238.205 343.756 237.964C345.125 237.964 346.352 239.608 346.405 240.864"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_470"
            d="M271.764 277.016C271.516 278.814 269.503 280.665 267.843 278.479C267.521 278.09 267.306 277.626 267.22 277.133C267.134 276.64 267.179 276.134 267.351 275.663C267.523 275.191 267.816 274.771 268.202 274.441C268.588 274.112 269.054 273.884 269.556 273.78C271.243 273.944 271.931 275.794 271.764 277.016Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_471"
            d="M337.125 241.416C337.24 243.24 335.589 245.013 333.929 243.541C331.854 241.708 332.904 238.851 334.653 238.602C335.871 238.541 337.028 239.849 337.125 241.416Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_472"
            d="M92.402 216.425C91.6072 218.009 90.1591 219.007 89.6999 217.811C88.9581 215.935 91.201 212.647 92.402 213.103C93.1879 213.525 93.0289 215.16 92.402 216.425Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_473"
            d="M290.986 243.179C290.916 244.16 290.041 245.606 288.788 245.718C286.889 245.537 285.529 242.482 287.675 240.675C287.96 240.414 288.327 240.257 288.717 240.228C290.006 240.331 291.11 241.949 291.013 243.179"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_474"
            d="M281.654 276.956C281.486 279.09 279.393 280.467 277.874 278.797C275.958 276.68 277.786 273.875 279.367 273.866C281.009 273.987 281.751 275.734 281.654 276.956Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_475"
            d="M281.662 243.214C281.547 245.047 279.728 246.742 278.209 245.107C276.372 243.145 277.768 240.374 279.534 240.245C280.743 240.245 281.75 241.674 281.662 243.214Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_476"
            d="M291.588 276.861C291.526 278.221 289.592 280.854 287.693 278.737C287.324 278.367 287.062 277.908 286.936 277.407C286.809 276.906 286.822 276.381 286.973 275.886C287.125 275.392 287.409 274.945 287.796 274.593C288.183 274.241 288.66 273.996 289.177 273.884C290.351 273.884 291.658 275.51 291.588 276.861Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_477"
            d="M272.382 243.119C272.302 244.049 271.64 245.563 270.174 245.701C268.276 245.512 267.039 242.439 269.291 240.615C269.567 240.35 269.937 240.199 270.324 240.193C271.817 240.322 272.505 242.026 272.417 243.154"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_478"
            d="M98.0723 218.559C97.5248 219.6 95.8117 221.278 95.2642 219.901C94.496 217.974 96.4916 214.936 97.9133 215.194C98.6727 215.599 98.6374 217.423 98.0546 218.55"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_479"
            d="M123.105 226.089C122.425 227.646 120.703 228.911 120.023 227.431C119.14 225.461 121.065 222.526 122.672 222.776C123.555 223.017 123.75 224.686 123.131 226.089"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_480"
            d="M116.58 224.367C116.077 225.409 114.187 227.336 113.507 225.658C112.624 223.524 114.691 220.856 116.095 221.037C116.978 221.398 117.092 223.249 116.563 224.367"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_481"
            d="M301.284 276.715C301.213 278.487 299.385 280.535 297.522 278.634C295.526 276.586 297.292 273.78 298.891 273.729C300.506 273.815 301.328 275.544 301.284 276.715Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_482"
            d="M103.899 220.478C103.502 221.338 102.513 222.629 101.533 222.423C100.182 221.777 101.021 218.051 103.113 217.259C103.301 217.174 103.514 217.156 103.714 217.208C104.456 217.483 104.553 219.11 103.908 220.486"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_483"
            d="M311.19 276.525C311.12 278.47 309.115 280.165 307.455 278.436C305.486 276.379 307.19 273.539 308.762 273.487C310.334 273.436 311.243 275.105 311.19 276.525Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_484"
            d="M382.787 220.383C382.937 221.588 381.974 224.075 379.882 222.371C377.789 220.667 378.566 217.974 379.979 217.655C381.074 217.526 382.628 219.032 382.787 220.383Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_485"
            d="M390.901 219.204C391.219 220.925 390.071 222.646 388.34 221.373C386.027 219.652 386.76 216.881 388.137 216.528C389.444 216.433 390.636 217.862 390.883 219.204"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_486"
            d="M233.298 87.1278C232.76 87.6613 231.753 88.453 231.223 88.4272C230.605 88.1948 232.318 86.1037 233.872 85.4411C233.985 85.3798 234.114 85.35 234.243 85.355C234.623 85.4411 234.031 86.3791 233.272 87.1278"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_487"
            d="M398.036 269.116C398.204 271.061 396.579 272.962 394.742 271.345C392.906 269.727 393.745 266.586 395.361 266.319C396.941 266.216 397.93 267.877 398.01 269.116"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_488"
            d="M388.695 270.27C388.801 271.233 388.413 272.851 386.929 273.195C384.96 273.281 383.106 270.338 385.039 268.161C385.156 268.004 385.304 267.872 385.473 267.771C385.643 267.671 385.832 267.604 386.028 267.576C387.45 267.49 388.563 268.901 388.678 270.27"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_489"
            d="M379.396 271.612C379.396 272.946 377.886 275.665 375.74 273.557C373.771 271.629 375.113 269.022 376.623 268.721C378.027 268.721 379.351 270.33 379.351 271.621"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_490"
            d="M222.649 96.6109C221.766 97.5747 220.317 98.4525 220.309 97.7124C220.309 96.6539 222.737 94.5025 223.532 94.623C224.035 94.7865 223.346 95.888 222.649 96.6109Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_491"
            d="M236.266 87.6786C235.383 88.5392 234.12 89.3997 234.147 88.7199C234.191 87.7647 236.452 85.7682 237.158 85.8543C237.582 85.9919 236.902 87.016 236.275 87.6786"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_492"
            d="M226.156 97.2647C225.396 98.1253 223.904 99.1063 223.868 98.349C223.807 97.2647 226.005 95.1994 226.915 95.2338C227.339 95.3973 226.853 96.516 226.156 97.2647Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_493"
            d="M229.776 97.7294C229.299 98.3145 228.319 99.2267 227.683 99.2181C226.906 98.9772 228.177 96.8086 229.873 95.9394C230.015 95.85 230.18 95.8022 230.35 95.8018C230.862 95.9567 230.35 97.0237 229.776 97.738"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_494"
            d="M239.347 88.1512C238.711 88.8482 237.263 89.9583 237.272 89.1924C237.272 88.1598 239.435 86.2494 240.071 86.3182C240.468 86.4473 239.991 87.4455 239.347 88.1512Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_495"
            d="M242.481 88.5043C241.642 89.5542 240.53 90.2255 240.415 89.7522C240.212 88.8916 242.181 86.7488 243.064 86.7402C243.497 86.8865 242.976 87.8848 242.481 88.5043Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_496"
            d="M245.573 89.0377C244.823 90.0102 243.763 90.7072 243.666 90.122C243.507 89.1582 245.335 87.1531 246.13 87.1445C246.642 87.2908 246.13 88.3579 245.573 89.0377Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_497"
            d="M248.92 89.262C248.39 90.0623 247.092 91.1638 246.959 90.4581C246.765 89.4599 248.407 87.5065 249.237 87.4893C249.608 87.5323 249.441 88.4789 248.92 89.262Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_498"
            d="M252.151 89.5976C251.559 90.5873 250.429 91.5425 250.261 90.811C250.014 89.7267 251.691 87.7819 252.363 87.8249C252.839 87.9368 252.548 88.9264 252.151 89.5976Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_499"
            d="M233.485 98.2635C232.602 99.4252 231.321 100.251 231.215 99.4855C231.065 98.3754 233.025 96.31 233.953 96.3273C234.385 96.4047 234.156 97.3685 233.485 98.2635Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_500"
            d="M237.229 98.7276C236.805 99.3472 235.851 100.259 235.26 100.277C234.447 100.053 235.419 97.8843 237.026 96.9807C237.181 96.8804 237.361 96.8211 237.547 96.8086C238.023 96.9377 237.785 97.9703 237.264 98.7276"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_501"
            d="M255.409 90.0452C254.817 91.0692 253.872 91.7663 253.643 91.1381C253.334 90.1743 254.755 88.1606 255.559 88.1348C255.948 88.1778 255.939 89.0728 255.382 90.0452"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_502"
            d="M258.881 90.1225C258.607 90.7077 257.918 91.5855 257.389 91.6285C256.505 91.4478 257.336 89.1587 258.528 88.4617C258.613 88.401 258.714 88.3652 258.819 88.3584C259.252 88.4531 259.216 89.4083 258.89 90.0795"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_503"
            d="M240.92 99.2613C240.531 99.8723 239.71 100.776 239.065 100.767C238.121 100.483 239.41 98.0221 240.832 97.3251C240.931 97.27 241.045 97.246 241.158 97.2562C241.723 97.3939 241.37 98.5126 240.911 99.2355"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_504"
            d="M262.165 90.5008C261.758 91.4646 260.831 92.3854 260.54 91.6367C260.116 90.5266 261.308 88.6851 262.059 88.6162C262.597 88.7195 262.456 89.7952 262.156 90.5008"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_505"
            d="M244.795 99.683C244.124 100.862 242.941 101.697 242.711 100.905C242.376 99.7346 244.168 97.6348 244.928 97.6865C245.519 97.8242 245.228 98.9343 244.795 99.683Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_506"
            d="M265.652 90.6216C265.299 91.6887 264.407 92.5492 264.072 91.8952C263.577 90.9056 264.593 88.8661 265.405 88.8145C265.838 88.8145 265.953 89.675 265.652 90.6216Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_507"
            d="M148.846 459.642C149.094 460.855 148.272 461.638 146.957 460.838C144.899 459.581 144.431 457.172 145.782 456.836C145.928 456.806 146.078 456.806 146.224 456.836C146.867 457.057 147.445 457.428 147.907 457.917C148.369 458.406 148.701 458.998 148.873 459.642"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_508"
            d="M248.707 100.13C248.177 101.077 246.941 102.169 246.676 101.249C246.341 100.018 247.877 98.0905 248.716 98.0732C249.228 98.2023 249.14 99.3382 248.716 100.13"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_509"
            d="M141.048 457.972C141.445 459.28 140.651 460.063 139.52 459.461C138.606 458.905 137.883 458.096 137.445 457.137C137.119 456.216 137.498 455.485 138.046 455.33C138.194 455.3 138.348 455.3 138.496 455.33C139.097 455.559 139.638 455.916 140.079 456.374C140.521 456.833 140.852 457.381 141.048 457.981"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_510"
            d="M149.747 471.448C150.012 472.412 149.376 473.341 148.113 472.627C146.012 471.44 145.332 469.185 146.585 468.841C146.737 468.811 146.893 468.811 147.045 468.841C147.651 469.07 148.203 469.418 148.668 469.861C149.132 470.305 149.499 470.836 149.747 471.422"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_511"
            d="M255.26 112.04C254.845 113.194 253.688 114.269 253.184 113.366C252.513 112.152 253.75 109.924 254.836 109.863C255.41 109.915 255.622 111.016 255.26 112.04Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_512"
            d="M250.72 111.636C250.35 112.609 248.954 114.08 248.495 112.953C247.939 111.602 249.378 109.571 250.332 109.511C250.871 109.571 251.074 110.69 250.72 111.61"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_513"
            d="M142.31 469.882C142.752 471.052 142.169 471.758 141.136 471.301C139.37 470.536 137.878 467.937 139.158 467.377C139.336 467.321 139.528 467.321 139.706 467.377C140.304 467.601 140.846 467.948 141.295 468.393C141.745 468.838 142.091 469.372 142.31 469.959"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_514"
            d="M135.14 468.307C135.591 469.469 135.043 470.175 134.045 469.71C132.138 468.807 130.857 466.268 132.058 465.76C132.204 465.708 132.363 465.708 132.509 465.76C133.699 466.257 134.648 467.182 135.158 468.342"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_515"
            d="M128.403 466.784C128.721 467.696 128.323 468.505 127.414 468.117C126.419 467.57 125.604 466.758 125.065 465.777C124.623 464.916 124.844 464.219 125.321 464.056C125.44 464.03 125.564 464.03 125.683 464.056C126.892 464.623 127.855 465.59 128.403 466.784Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_516"
            d="M246.066 111.343C245.81 111.92 245.122 112.995 244.3 113.004C243.205 112.737 243.965 110.173 245.386 109.338C245.525 109.234 245.697 109.182 245.872 109.192C246.579 109.329 246.446 110.534 246.084 111.343"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_517"
            d="M59.2791 197.579C58.6433 198.628 57.5749 199.696 57.4071 199.016C57.0274 197.441 59.4646 193.792 60.1357 194.205C60.4271 194.627 59.9767 196.357 59.2526 197.553"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_518"
            d="M241.511 110.913C241.193 111.55 240.407 112.574 239.665 112.574C238.685 112.333 239.286 109.915 240.857 108.96C241.024 108.842 241.225 108.779 241.431 108.779C241.979 108.848 242.023 109.907 241.52 110.913"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_519"
            d="M62.0612 199.963C61.2577 201.365 60.2952 202.114 60.0832 201.486C59.6771 200.143 61.8493 196.323 62.7765 196.65H62.8471C63.2445 197.045 62.75 198.732 62.0612 199.928"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_520"
            d="M59.6505 219.092C58.9617 220.461 57.8844 221.381 57.6195 220.521C57.1073 218.877 58.997 215.125 60.0831 215.624C60.5776 216.106 60.2597 217.853 59.6416 219.067"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_521"
            d="M237.122 110.449C236.61 111.361 235.109 112.781 234.782 111.714C234.359 110.311 236.301 108.272 237.078 108.323C237.652 108.47 237.582 109.631 237.122 110.449Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_522"
            d="M63.5715 221.7C62.9092 223.008 61.8055 223.903 61.4787 223.094C60.79 221.425 62.7591 217.836 63.8364 218.112H63.9336C64.5782 218.688 64.1278 220.581 63.5715 221.666"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_523"
            d="M232.601 110.078C231.912 111.154 230.57 112.135 230.341 111.222C230.023 109.983 231.78 107.78 232.813 107.78C233.502 107.952 233.131 109.192 232.592 110.026"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_524"
            d="M68.0845 224.007C67.5636 225.117 66.1772 226.588 65.7269 225.547C64.9321 223.748 66.9278 220.255 68.111 220.599H68.164C68.7468 221.046 68.6055 222.862 68.0845 223.981"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_525"
            d="M228.309 109.407C227.894 110.035 226.967 111.025 226.243 110.999C225.36 110.723 226.305 108.34 228.009 107.436C228.182 107.326 228.385 107.269 228.592 107.273C229.113 107.359 228.981 108.434 228.336 109.407"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_526"
            d="M224.08 108.882C223.515 109.631 221.793 111.085 221.669 109.992C221.51 108.641 223.523 106.662 224.451 106.688C224.989 106.851 224.698 108.065 224.08 108.882Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_527"
            d="M332.621 209.032C332.719 209.953 332.383 211.407 331.05 211.614C328.895 211.614 327.783 208.275 329.416 206.769C329.655 206.543 329.967 206.406 330.299 206.382C331.35 206.322 332.533 207.63 332.683 209.024"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_528"
            d="M341.126 208.439C341.214 209.377 340.693 210.831 339.527 211.02C337.479 211.02 335.995 207.759 337.894 206.115C338.081 205.943 338.319 205.832 338.574 205.797C339.775 205.797 341.037 207.294 341.143 208.43"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_529"
            d="M349.373 207.785C349.594 209.506 348.349 211.167 346.68 209.893C344.472 208.172 345.408 205.289 346.812 205.126C348.102 205.126 349.188 206.511 349.356 207.785"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_530"
            d="M357.762 207.097C357.965 208.723 356.729 210.324 355.113 209.11C353.135 207.613 353.453 204.661 355.166 204.343C356.632 204.343 357.621 205.935 357.762 207.097Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_531"
            d="M366.098 206.107C366.292 207.389 365.259 209.618 363.387 208.181C361.1 206.46 361.974 203.74 363.387 203.456C364.42 203.353 365.877 204.799 366.08 206.107"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_532"
            d="M374.116 204.997C374.434 206.417 373.656 208.439 371.811 207.346C369.391 205.926 369.939 202.845 371.308 202.493H371.405C372.773 202.424 373.877 203.938 374.116 205.014"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_533"
            d="M382.221 204.093C382.504 205.608 381.577 207.252 379.987 206.262C377.815 204.911 377.78 201.787 379.475 201.391C380.561 201.262 381.939 202.622 382.221 204.093Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_534"
            d="M151.486 500.784C151.998 501.498 152.069 502.144 151.301 501.989C149.632 501.498 147.539 499.158 148.396 498.744C148.53 498.697 148.677 498.697 148.811 498.744C149.884 499.182 150.815 499.896 151.504 500.81"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_535"
            d="M256.364 80.2001C255.702 81.1209 254.774 81.8265 254.668 81.3102C254.509 80.5013 256.249 78.4962 256.858 78.5479C257.238 78.6511 256.814 79.5719 256.364 80.2001Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_536"
            d="M259.093 80.4064C258.687 81.0432 257.6 82.1274 257.433 81.5939C257.177 80.7334 258.854 78.7713 259.402 78.7971C259.72 78.8918 259.499 79.7696 259.093 80.4064Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_537"
            d="M261.742 80.6563C261.459 81.164 260.859 81.9815 260.364 82.0246C259.755 81.8697 260.541 79.8818 261.68 79.1589C261.763 79.0949 261.865 79.0588 261.971 79.0557C262.281 79.0987 262.166 79.9162 261.751 80.6563"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_538"
            d="M147.441 491.258C147.962 492.119 147.75 492.764 146.982 492.566C145.499 492.188 143.273 489.606 144.333 489.064C144.479 489.013 144.638 489.013 144.783 489.064C145.89 489.509 146.819 490.29 147.432 491.293"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_539"
            d="M141.234 489.812C141.587 490.423 141.649 491.232 140.836 491.086C139.807 490.644 138.917 489.943 138.258 489.055C137.781 488.435 137.71 487.695 138.108 487.531C138.225 487.492 138.352 487.492 138.47 487.531C139.594 488.03 140.553 488.822 141.242 489.82"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_540"
            d="M144.51 480.991C144.792 481.646 144.713 482.515 143.803 482.386C142.099 481.886 140.13 479.339 141.278 478.642C141.427 478.558 141.604 478.533 141.772 478.573C142.943 479.057 143.911 479.912 144.518 481"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_541"
            d="M137.737 479.512C138.135 480.372 137.932 481.13 137.022 480.811C135.477 480.278 133.49 477.636 134.532 477.059C134.697 476.996 134.88 476.996 135.044 477.059C136.203 477.559 137.142 478.443 137.693 479.555"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_542"
            d="M53.0898 213.946C52.6659 214.807 51.8535 216.003 51.3237 215.667C50.9528 215.168 51.3678 213.61 51.7829 212.655C52.3568 211.339 53.2134 210.435 53.5489 210.512C53.5889 210.518 53.626 210.537 53.6549 210.564C54.0699 211.08 53.5489 212.853 53.0191 213.937"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_543"
            d="M56.1631 216.622C55.5538 217.758 54.4589 218.817 54.2469 217.982C53.8143 216.321 55.7039 212.578 56.7018 213.103C57.1521 213.636 56.7018 215.616 56.1631 216.622Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_544"
            d="M341.268 441.949C340.879 443.911 338.221 445.098 336.994 443.36C335.766 441.622 337.762 439.599 339.201 439.496C339.516 439.487 339.828 439.548 340.114 439.675C340.4 439.802 340.653 439.991 340.852 440.228C341.052 440.465 341.193 440.743 341.265 441.041C341.337 441.339 341.338 441.65 341.268 441.949Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_545"
            d="M331.253 442.542C330.899 444.427 328.242 445.83 326.899 443.756C325.849 442.138 327.562 440.253 329.151 440.081C329.465 440.08 329.776 440.146 330.06 440.275C330.345 440.404 330.597 440.593 330.798 440.828C330.999 441.064 331.144 441.339 331.222 441.636C331.301 441.932 331.311 442.242 331.253 442.542Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_546"
            d="M59.5279 238.782C58.9981 240.03 57.8855 241.209 57.444 240.279C56.561 238.558 58.327 234.927 59.4661 235.168C59.4984 235.164 59.531 235.164 59.5633 235.168C60.2432 235.701 59.9959 237.612 59.5633 238.748"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_547"
            d="M55.377 236.089C54.9973 237.018 54.1231 238.223 53.5138 237.973C52.9398 237.457 53.2047 235.667 53.4696 234.875C53.9288 233.507 54.8825 232.569 55.3063 232.603C55.3571 232.609 55.4056 232.627 55.4476 232.655C55.9598 233.12 55.8361 234.961 55.3858 236.097"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_548"
            d="M166.992 436.363C167.045 437.663 165.756 438.945 164.069 437.456C161.924 435.529 162.895 433.343 164.299 433.343H164.493C165.166 433.546 165.76 433.941 166.201 434.476C166.641 435.011 166.908 435.662 166.966 436.346"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_549"
            d="M158.223 435.081C158.303 436.527 157.014 437.611 155.389 436.122C153.084 434.005 154.268 432.009 155.53 432.009H155.716C156.39 432.23 156.986 432.635 157.43 433.177C157.875 433.719 158.15 434.374 158.223 435.064"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_550"
            d="M149.808 433.576C149.896 434.436 149.384 435.529 148.227 435.426C146.249 434.901 144.739 431.751 146.461 430.77C146.686 430.638 146.953 430.589 147.212 430.633C147.856 430.88 148.428 431.278 148.877 431.791C149.327 432.304 149.64 432.917 149.79 433.576"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_551"
            d="M141.392 432.156C141.56 433.498 140.58 434.634 139.061 433.498C137.057 432.01 136.995 429.359 138.531 429.135C138.663 429.114 138.797 429.114 138.929 429.135C139.578 429.377 140.149 429.783 140.583 430.313C141.016 430.843 141.296 431.476 141.392 432.147"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_552"
            d="M133.199 430.495C133.411 431.829 132.563 433.076 131.106 431.992C128.952 430.452 128.802 427.689 130.426 427.517C130.538 427.509 130.65 427.509 130.762 427.517C131.385 427.78 131.932 428.187 132.357 428.703C132.782 429.219 133.071 429.828 133.199 430.477"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_553"
            d="M125.393 428.851C125.578 429.978 124.819 431.433 123.335 430.331C121.172 428.756 121.19 426.149 122.505 425.865C122.63 425.838 122.76 425.838 122.885 425.865C123.504 426.151 124.05 426.567 124.483 427.083C124.917 427.6 125.228 428.203 125.393 428.851Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_554"
            d="M169.509 92.0931C168.06 92.9536 166.709 93.4958 166.78 93.1343C166.93 92.3684 171.036 89.8987 171.61 90.2343C171.804 90.4924 170.515 91.4735 169.509 92.0931Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_555"
            d="M399.141 251.707C399.353 253.549 398.029 255.347 396.263 254.013C393.896 252.241 394.929 249.203 396.439 248.945C397.861 248.841 398.982 250.287 399.141 251.707Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_556"
            d="M51.421 233.662C50.8559 234.952 49.964 235.882 49.655 235.202C48.9838 233.851 50.6174 229.686 51.6859 230.039L51.7742 230.082C52.2687 230.607 52.0126 232.414 51.4563 233.679"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_557"
            d="M390.39 252.766C390.523 253.755 390.028 255.348 388.774 255.7C386.602 255.829 384.907 252.602 386.77 250.675C387.002 250.424 387.312 250.255 387.653 250.193C388.942 250.124 390.302 251.552 390.435 252.774"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_558"
            d="M381.207 254.168C381.41 256.277 379.662 257.825 377.931 256.311C376.032 254.659 376.686 251.733 378.54 251.363C379.732 251.242 381.048 252.628 381.189 254.168"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_559"
            d="M72.6667 226.537C71.9779 227.974 70.724 228.92 70.3266 227.956C69.5319 226.055 71.5541 222.612 72.7373 223.043C73.4438 223.516 73.2495 225.254 72.649 226.485"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_560"
            d="M219.947 108.134C219.462 108.779 218.481 109.743 217.748 109.717C216.865 109.45 218.066 107.136 219.815 106.206C219.993 106.094 220.203 106.04 220.415 106.051C220.91 106.137 220.689 107.153 219.956 108.134"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_561"
            d="M215.754 107.548C214.871 108.581 213.449 109.51 213.334 108.701C213.158 107.531 215.392 105.311 216.407 105.362C216.999 105.552 216.407 106.765 215.754 107.548Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_562"
            d="M77.7792 228.851C77.0552 230.443 75.6953 231.433 75.2361 230.22C74.5032 228.326 76.596 224.927 77.7351 225.426C78.5475 225.917 78.2826 227.733 77.7351 228.869"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_563"
            d="M184.123 99.3475C183.372 99.9154 182.074 100.724 181.526 100.612C180.864 100.174 183.831 97.6866 185.447 97.3338C185.54 97.3093 185.637 97.3093 185.73 97.3338C186.092 97.5145 185.156 98.573 184.123 99.3647"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_564"
            d="M178.436 97.1878C177.244 98.0053 175.787 98.6249 175.866 98.1688C175.946 97.3083 179.478 94.8987 180.361 95.1053C180.697 95.3893 179.407 96.508 178.427 97.1792"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_565"
            d="M83.2459 231.028C82.8485 231.957 81.8066 233.205 80.9854 233.016C79.546 232.155 80.8529 228.076 82.6719 227.698C82.7818 227.668 82.8977 227.668 83.0075 227.698C83.7316 228.111 83.7581 229.866 83.2636 231.011"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_566"
            d="M65.2836 202.45C64.5065 203.801 63.3939 204.739 63.1732 203.887C62.7758 202.39 64.9392 198.621 65.9282 199.145C66.3874 199.602 65.9282 201.245 65.2659 202.441"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_567"
            d="M88.7196 233.438C87.9867 235.013 86.5739 235.925 86.0705 234.72C85.1875 232.672 87.0949 229.557 88.5077 229.901C89.4526 230.418 89.223 232.319 88.702 233.438"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_568"
            d="M94.7946 235.4C94.097 237.121 92.5252 238.163 91.9866 236.846C91.1035 234.763 92.9844 231.726 94.4414 232.053C95.2361 232.345 95.3951 233.911 94.7946 235.4Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_569"
            d="M68.9057 204.945C68.3052 205.952 66.9807 207.277 66.6893 206.322C66.5693 205.287 66.7987 204.241 67.3428 203.344C67.9609 202.252 69.0028 201.348 69.4002 201.563C69.9035 202.028 69.5327 203.835 68.8881 204.937"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_570"
            d="M101.03 237.559C100.517 238.747 98.6984 240.434 98.0714 238.764C97.1884 236.484 99.2105 233.833 100.579 234.109C101.356 234.393 101.604 236.26 101.03 237.551"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_571"
            d="M107.431 239.557C106.751 241.14 105.118 242.302 104.473 240.684C103.59 238.464 105.568 235.813 106.946 236.054C107.829 236.338 108.067 238.102 107.431 239.557Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_572"
            d="M75.069 166.436C74.133 167.666 73.2146 168.338 73.0733 167.899C72.7643 166.918 75.7224 162.933 76.42 163.415C76.6761 163.88 75.7931 165.463 75.0513 166.419"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_573"
            d="M321.135 276.146C321.037 277.816 319.059 280.096 317.161 277.868C315.262 275.639 317.24 273.238 318.583 273.169C319.925 273.1 321.232 274.89 321.135 276.138"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_574"
            d="M318.776 242.31C318.776 243.317 318.07 244.831 316.816 245.012C315.933 244.917 314.547 243.222 314.352 242.49C314.3 242.005 314.373 241.513 314.566 241.062C314.759 240.611 315.065 240.215 315.456 239.909C315.689 239.719 315.975 239.602 316.277 239.573C317.567 239.625 318.741 241.148 318.776 242.318"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_575"
            d="M118.567 100.845C117.993 101.286 117.372 101.664 116.713 101.972C116.713 101.637 120.589 98.7624 121.128 98.7021V98.728C120.325 99.4983 119.457 100.201 118.532 100.828"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_576"
            d="M399.494 435.891C398.902 437.922 396.297 439.238 395.37 437.5C394.549 435.968 396.403 433.757 397.842 433.541C399.282 433.326 399.847 434.669 399.494 435.891Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_577"
            d="M334.847 177.975C334.98 179.189 333.964 181.185 332.331 179.8C330.45 178.234 331.024 175.643 332.428 175.402C333.567 175.402 334.724 176.857 334.847 177.984"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_578"
            d="M99.1572 254.719C98.8305 255.494 97.5766 256.94 96.8436 256.845C95.1747 256.07 95.9165 252.172 97.8768 251.432C98.0744 251.348 98.2959 251.333 98.5037 251.389C99.3868 251.682 99.6428 253.592 99.166 254.719"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_579"
            d="M101.798 327.126C101.507 328.847 100.147 329.923 99.0959 328.623C97.3298 326.463 98.5395 323.296 100.103 323.408H100.261C101.568 323.873 102.028 325.895 101.798 327.109"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_580"
            d="M167.841 123.52C167.169 124.2 165.854 125.129 165.192 125.026C164.247 124.509 166.587 121.695 168.397 121.162C168.521 121.126 168.653 121.126 168.777 121.162C169.262 121.411 168.635 122.711 167.814 123.554"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_581"
            d="M147.052 115.078C146.258 115.724 144.871 116.541 144.403 116.404C144.253 116.145 145.286 114.682 145.949 114.115C146.611 113.547 148.218 112.652 148.598 112.763C148.898 113.047 147.962 114.304 147.017 115.078"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_582"
            d="M165.544 449.53C165.721 450.606 164.749 452.198 162.895 450.838C160.608 449.117 161.253 446.966 162.595 446.837C162.694 446.821 162.796 446.821 162.895 446.837C163.532 447.033 164.108 447.381 164.571 447.85C165.034 448.319 165.368 448.894 165.544 449.522"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_583"
            d="M304.417 179.37C304.417 180.136 303.41 183.088 301.688 181.289C299.966 179.491 301.026 177.107 302.448 176.943C303.446 176.943 304.435 178.337 304.417 179.37Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_584"
            d="M190.913 87.3855C189.906 87.9707 188.529 88.5644 188.317 88.4095C188.273 87.8244 191.928 85.3288 192.944 85.5611C193.138 85.7677 191.964 86.7659 190.913 87.3855Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_585"
            d="M473.888 210.444C474.577 212.01 474.436 213.645 473.332 213.249C471.46 212.561 470.356 208.792 471.469 208.258C472.352 208 473.402 209.385 473.87 210.452"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_586"
            d="M457.995 216.485C458.56 217.974 458.295 219.807 456.909 219.161C454.913 218.241 454.181 214.635 455.47 214.179C456.503 213.946 457.589 215.418 457.995 216.485Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_587"
            d="M466.658 130.404C467.744 131.506 468.424 132.547 468.115 132.581C467.559 132.642 464.177 129.483 464.247 128.683C464.468 128.442 465.81 129.544 466.658 130.404Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_588"
            d="M463.459 136.377C464.484 137.487 465.049 138.537 464.634 138.571C463.69 138.204 462.866 137.595 462.25 136.807C461.817 136.342 460.696 134.957 460.872 134.553C461.234 134.312 462.638 135.482 463.459 136.377Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_589"
            d="M457.686 142.185C458.674 143.347 459.09 144.397 458.569 144.388C457.412 144.388 454.427 140.946 454.975 140.249C455.337 140.008 456.652 140.946 457.694 142.185"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_590"
            d="M449.315 147.616C450.322 148.864 450.648 150.051 449.96 149.965C448.538 149.802 445.8 146.359 446.586 145.74C447.213 145.507 448.573 146.729 449.297 147.624"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_591"
            d="M467.134 124.269C467.87 124.887 468.489 125.627 468.962 126.454C468.503 126.721 465.068 123.116 465.006 122.711C465.209 122.573 466.419 123.572 467.134 124.303"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_592"
            d="M393.879 229.901C394.205 232.009 392.422 232.912 391.018 231.906C389.251 230.623 389.304 227.491 391.15 227.198C392.819 227.044 393.693 228.73 393.879 229.901Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_593"
            d="M422.788 313.417C422.788 315.318 421.022 317.151 419.423 315.594C417.578 313.83 418.69 310.938 420.377 310.517C421.596 310.31 422.814 311.773 422.788 313.417Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_594"
            d="M128.324 451.32C128.748 452.937 127.582 453.041 126.611 452.438C124.774 451.354 123.9 448.721 125.295 448.471C125.456 448.445 125.62 448.445 125.781 448.471C126.405 448.709 126.962 449.09 127.404 449.581C127.847 450.072 128.162 450.659 128.324 451.294"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_595"
            d="M487.054 204.222C487.699 205.676 487.69 207.199 486.781 206.803C485.271 206.192 483.752 202.587 484.697 201.855C485.447 201.614 486.569 203.086 487.054 204.196"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_596"
            d="M330.862 317.134C330.868 317.603 330.729 318.063 330.464 318.455C330.198 318.846 329.818 319.151 329.372 319.329C328.927 319.507 328.436 319.55 327.965 319.454C327.494 319.357 327.063 319.124 326.73 318.786C325.405 317.401 325.846 314.268 328.363 314.001C329.873 313.933 331.012 315.37 330.862 317.134Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_597"
            d="M340.923 235.684C341.011 237.965 338.883 238.731 337.505 237.405C336.128 236.08 336.313 233.163 338.389 232.879C340.155 232.879 340.843 234.6 340.887 235.667"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_598"
            d="M201.192 86.8608C199.638 87.8591 198.632 88.1086 198.773 87.6611C198.985 86.9813 202.199 84.916 203.029 85.0795C203.321 85.2946 202.296 86.1466 201.192 86.8522"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_599"
            d="M497.183 197.399C497.907 198.896 498.004 200.213 497.369 200.058C496.115 199.748 494.26 195.944 494.976 195.144C495.558 194.886 496.653 196.314 497.183 197.399Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_600"
            d="M504.158 190.127C504.909 191.495 505.359 193.07 504.812 192.958C503.849 192.777 501.748 188.81 502.242 188.147C502.622 187.829 503.611 189.137 504.158 190.127Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_601"
            d="M506.967 288.84C507.135 290.681 506.429 292.394 505.484 291.568C504.133 290.372 504.195 286.878 505.29 286.189C506.173 285.914 506.844 287.583 506.967 288.84Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_602"
            d="M364.172 319.423C364.214 320.064 364.008 320.696 363.597 321.198C363.186 321.699 362.597 322.033 361.947 322.134H361.903C359.536 322.134 358.477 319.174 360.208 317.367C360.572 316.955 361.083 316.693 361.638 316.635C363.404 316.566 364.172 318.029 364.172 319.415"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_603"
            d="M271.208 323.202C271.171 323.798 270.907 324.36 270.468 324.777C270.028 325.194 269.445 325.436 268.832 325.457C266.281 325.284 265.662 322.014 267.349 320.543C267.773 320.162 268.326 319.947 268.903 319.94C270.201 319.94 271.482 321.291 271.234 323.202"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_604"
            d="M63.6961 290.699C63.2192 292.772 62.0978 292.859 61.5062 292.007C60.3847 290.397 61.2854 286.706 62.7247 287.015L62.866 287.058C63.9433 287.644 63.9433 289.554 63.6872 290.69"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_605"
            d="M97.2682 131.093C95.9172 132.375 95.3167 132.564 95.3344 132.203C95.3344 131.342 98.6634 128.193 99.1667 128.537C99.2638 128.847 98.4338 129.931 97.2417 131.058"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_606"
            d="M68.5964 237.103C67.7134 239.143 66.6184 239.169 66.2475 238.299C65.5146 236.578 67.1306 233.136 68.4728 233.687C69.294 234.203 69.0909 235.812 68.5611 237.086"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_607"
            d="M101.515 137.254C100.217 138.511 99.4483 138.717 99.4836 138.184C99.5631 137.039 102.733 134.01 103.289 134.535C103.546 134.948 102.565 136.256 101.523 137.254"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_608"
            d="M108.871 143.107C107.414 144.638 106.505 144.733 106.496 144.131C106.496 142.848 109.339 140.017 110.24 140.439C110.602 140.723 109.993 141.919 108.871 143.107Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_609"
            d="M55.3409 229.471C54.5903 231.192 53.7249 231.605 53.3894 230.848C52.7095 229.316 54.4578 225.814 55.438 226.063C55.4884 226.075 55.5362 226.096 55.5793 226.124C56.2681 226.735 55.7294 228.576 55.3409 229.471Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_610"
            d="M118.991 148.898C117.534 150.558 116.474 150.619 116.412 149.913C116.289 148.553 119.061 145.696 120.024 146.187C120.607 146.574 119.9 147.865 118.991 148.898Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_611"
            d="M302.281 322.806C302.308 323.45 302.077 324.079 301.637 324.561C301.197 325.042 300.583 325.339 299.923 325.387C297.344 325.293 296.638 322.083 298.307 320.525C298.722 320.129 299.272 319.896 299.852 319.871C300.215 319.894 300.57 319.989 300.893 320.151C301.217 320.313 301.503 320.539 301.733 320.813C301.963 321.088 302.133 321.406 302.232 321.747C302.331 322.088 302.356 322.446 302.307 322.797"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_612"
            d="M307.659 174.516C307.756 176.538 306.096 177.201 304.948 176.108C303.447 174.68 304.153 172.038 305.655 171.978C306.953 171.978 307.606 173.32 307.659 174.516Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_613"
            d="M418.037 226.95C418.444 228.576 417.614 230.392 415.918 229.342C413.799 228.068 413.711 224.884 415.344 224.411C416.704 224.248 417.755 225.831 417.993 226.993"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_614"
            d="M351.81 171.117C352.393 173.131 351.06 173.871 349.885 173.294C348.057 172.434 347.581 169.336 349.196 168.923H349.373C350.768 168.923 351.563 170.369 351.793 171.143"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_615"
            d="M373.004 168.337C373.772 170.325 372.589 171.108 371.397 170.575C369.551 169.714 368.747 166.702 370.257 166.272H370.425C371.767 166.203 372.686 167.649 372.995 168.415"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_616"
            d="M393.011 166.264C393.709 167.813 393.126 169.009 391.925 168.493C389.744 167.572 389.029 164.56 390.106 164.052C390.153 164.027 390.204 164.01 390.256 164C391.387 163.88 392.57 165.274 393.011 166.264Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_617"
            d="M439.344 221.992C439.9 223.714 439.15 225.349 437.702 224.574C435.803 223.516 435.159 220.168 436.757 219.6C437.64 219.394 438.858 220.547 439.344 222.027"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_618"
            d="M471.019 302.393C471.107 304.355 469.853 306.008 468.617 304.898C467.01 303.461 467.46 300.208 468.944 299.631C469.915 299.364 470.94 300.733 471.019 302.393Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_619"
            d="M410.478 162.409C411.141 163.717 410.955 164.99 409.798 164.586C407.785 163.906 406.425 160.67 407.529 160.145C407.555 160.138 407.583 160.138 407.609 160.145C408.633 159.973 409.931 161.367 410.478 162.434"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_620"
            d="M425.72 157.899C425.72 157.968 426.798 159.706 425.923 160.136C424.343 160.446 422.038 156.987 422.639 155.911C422.691 155.818 422.775 155.745 422.877 155.705C423.548 155.507 424.934 156.565 425.729 157.899"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_621"
            d="M438.708 152.942C439.591 154.25 439.715 155.369 438.894 155.206C437.216 154.861 435.079 151.531 435.794 150.903L435.874 150.851C436.651 150.671 438.019 151.944 438.708 152.934"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_622"
            d="M529.104 329.01C529.007 330.731 528.406 332.59 527.93 332.091C527.214 331.333 527.532 327.418 528.398 326.506C528.936 326.179 529.184 327.555 529.095 329.018"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_623"
            d="M135.547 138.838C134.054 140.387 133.012 140.395 132.968 139.698C132.897 138.442 135.714 135.748 136.694 136.127C137.286 136.48 136.544 137.779 135.547 138.82"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_624"
            d="M148.298 143.416C146.877 145.137 145.649 145.249 145.517 144.475C145.314 143.055 148.024 140.525 149.049 140.835C149.852 141.205 148.96 142.633 148.316 143.416"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_625"
            d="M516.248 302.247C516.248 302.342 516.337 304.553 515.453 305.181C514.403 305.612 513.546 302.402 514.447 300.182C514.632 299.734 514.8 299.631 514.871 299.588C515.683 299.252 516.195 300.913 516.257 302.247"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_626"
            d="M380.306 335.456C380.191 337.943 377.656 338.838 376.279 337.444C375.93 337.064 375.688 336.604 375.575 336.107C375.461 335.611 375.481 335.094 375.632 334.606C375.782 334.119 376.059 333.677 376.435 333.324C376.812 332.971 377.275 332.719 377.78 332.59C379.599 332.495 380.35 334.01 380.288 335.456"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_627"
            d="M316.25 159.681C316.409 161.625 314.89 162.064 313.884 161.23C312.506 160.085 312.453 157.383 314.175 157.176C315.164 157.125 316.135 158.295 316.25 159.681Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_628"
            d="M349.479 338.27C349.311 340.748 346.733 341.557 345.347 340.112C344.996 339.732 344.753 339.268 344.645 338.768C344.536 338.267 344.565 337.748 344.728 337.262C344.891 336.776 345.182 336.34 345.573 335.999C345.964 335.658 346.44 335.423 346.954 335.318C348.949 335.318 349.55 337.039 349.462 338.27"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_629"
            d="M336.799 157.985C336.878 158.312 337.232 159.938 335.792 160.162C333.894 160.162 332.781 157.176 333.85 156.066C334.026 155.882 334.264 155.766 334.521 155.739C335.457 155.67 336.454 156.651 336.808 157.985"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_630"
            d="M357.047 155.842C357.682 157.684 356.482 158.329 355.378 157.77C353.479 156.823 353.038 154.07 354.318 153.639L354.495 153.596C355.881 153.51 356.773 155.033 357.047 155.842Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_631"
            d="M318.175 340.043C317.937 342.521 315.314 343.192 313.954 341.66C312.594 340.129 313.274 337.177 315.72 336.953C317.486 337.005 318.316 338.545 318.175 340.043Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_632"
            d="M376.633 154.078C377.233 155.42 376.871 156.702 375.618 156.221C373.578 155.437 372.58 152.382 373.807 151.918H373.94C375.008 151.815 376.183 153.14 376.589 154.129"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_633"
            d="M147.265 354.053C147.123 355.576 145.419 357.211 143.936 355.361C142.17 353.149 143.494 350.197 145.154 350.412C146.311 350.757 147.344 352.994 147.247 354.053"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_634"
            d="M67.9696 217.681C67.0424 219.471 66.0622 219.514 65.7708 218.765C65.135 217.13 67.2631 213.783 68.2345 214.204H68.2875C68.9762 214.721 68.6407 216.364 67.9696 217.647"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_635"
            d="M56.9759 210.039C56.1459 211.666 55.3688 212.019 55.1304 211.408C54.6006 210.082 56.6139 206.244 57.5499 206.752C58.0179 207.268 57.5499 208.8 56.9494 210.013"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_636"
            d="M144.218 466.439C144.703 467.868 143.803 468.246 142.655 467.704C140.889 466.844 139.794 464.391 141.03 463.978C141.232 463.923 141.446 463.923 141.648 463.978C142.24 464.176 142.779 464.502 143.224 464.931C143.669 465.36 144.008 465.882 144.218 466.457"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_637"
            d="M217.801 79.5371C217.377 79.8038 215.947 80.6472 215.426 80.5525C214.896 80.2599 217.872 78.4011 219.196 78.0569C219.31 78.0318 219.427 78.0318 219.541 78.0569C219.797 78.1688 219.046 78.8228 217.775 79.5801"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_638"
            d="M224.654 81.5172C224.16 81.87 222.968 82.6789 222.42 82.6015C221.837 82.3519 224.283 80.5103 225.643 80.0198C225.961 79.9079 226.058 79.9338 226.094 79.9424C226.473 80.1059 225.37 81.0095 224.654 81.5172Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_639"
            d="M240.548 84.7954C239.48 85.9141 238.57 86.2411 238.57 85.7334C238.57 84.9503 240.575 83.0571 241.387 83.0743C241.882 83.2206 241.052 84.2619 240.504 84.7954"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_640"
            d="M249.379 85.8965C248.496 87.084 247.613 87.4885 247.499 86.9894C247.278 86.1288 249.132 84.1582 249.812 84.2356C250.333 84.3561 249.812 85.3371 249.379 85.8965Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_641"
            d="M393.843 150.627C394.514 151.849 394.426 153.097 393.366 152.83C391.335 152.305 389.834 149.069 390.947 148.527L391.062 148.484C392.077 148.355 393.313 149.689 393.834 150.627"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_642"
            d="M258.676 86.7665C257.979 88.1348 257.105 88.4188 256.91 87.8594C256.698 87.1021 257.846 85.1315 258.676 85.1143C259.083 85.1143 259.047 85.8974 258.615 86.7665"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_643"
            d="M409.118 146.738C409.948 148.038 409.86 149.096 408.941 148.872C406.999 148.408 405.215 145.232 406.151 144.656L406.248 144.613C406.902 144.44 408.323 145.473 409.118 146.738Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_644"
            d="M422.258 142.288C423.141 143.493 423.335 144.577 422.611 144.517C421.11 144.388 418.461 141.075 419.317 140.378L419.397 140.326C420.156 140.154 421.542 141.359 422.249 142.288"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_645"
            d="M396.059 351.049C395.918 353.631 393.278 354.569 391.944 353.089C391.631 352.683 391.427 352.208 391.348 351.706C391.27 351.204 391.321 350.691 391.496 350.212C391.67 349.734 391.964 349.306 392.351 348.965C392.737 348.625 393.204 348.383 393.71 348.261C394.938 348.115 396.183 349.199 396.077 351.049"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_646"
            d="M487.965 223.034C488.539 224.695 488.221 226.063 487.338 225.702C485.767 225.057 484.539 221.33 485.661 220.59C486.305 220.306 487.427 221.52 487.965 223.034Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_647"
            d="M365.426 354.311C365.311 357.082 362.547 357.753 361.249 356.428C359.827 354.965 360.587 351.91 362.971 351.557C364.392 351.436 365.496 352.667 365.426 354.311Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_648"
            d="M472.935 229.531C473.439 231.191 472.935 232.732 471.743 232.112C469.977 231.174 469.165 227.706 470.507 227.035C471.39 226.742 472.512 228.154 472.935 229.565"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_649"
            d="M334.149 356.711C333.99 359.293 331.235 360.05 329.858 358.432C329.527 358.032 329.311 357.554 329.23 357.047C329.15 356.539 329.209 356.019 329.4 355.541C329.592 355.062 329.91 354.642 330.322 354.321C330.734 354.001 331.226 353.792 331.748 353.717C332.993 353.665 334.273 354.775 334.149 356.668"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_650"
            d="M302.554 357.916C302.368 360.497 299.596 361.134 298.236 359.508C297.905 359.113 297.689 358.639 297.61 358.136C297.531 357.632 297.592 357.117 297.787 356.644C297.981 356.171 298.303 355.758 298.717 355.447C299.132 355.137 299.624 354.94 300.143 354.878C301.424 354.878 302.695 356.083 302.554 357.916Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_651"
            d="M271.004 358.131C271 358.606 270.848 359.068 270.569 359.457C270.29 359.846 269.897 360.143 269.441 360.31C268.985 360.477 268.488 360.505 268.015 360.391C267.542 360.277 267.116 360.026 266.792 359.672C265.45 358.192 265.962 355.059 268.558 354.947C268.935 354.993 269.298 355.113 269.625 355.3C269.952 355.487 270.236 355.737 270.46 356.036C270.685 356.334 270.844 356.674 270.928 357.034C271.013 357.394 271.02 357.768 270.951 358.131"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_652"
            d="M125.507 344.242C125.215 346.522 123.281 346.6 122.284 345.343C120.756 343.416 121.86 340.387 123.529 340.593H123.644C125.109 340.98 125.692 342.753 125.507 344.216"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_653"
            d="M101.215 338.7C100.933 341.006 99.2903 341.04 98.3632 339.844C96.968 338.037 97.6302 334.844 99.361 335.016H99.5464C100.668 335.335 101.418 336.979 101.215 338.665"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_654"
            d="M454.993 235.357C455.47 237.078 454.755 238.705 453.395 237.939C451.408 236.777 451.046 233.455 452.459 232.879C453.651 232.655 454.666 234.247 454.984 235.391"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_655"
            d="M434.24 240.58C434.682 242.25 433.869 244.151 432.236 243.222C429.975 241.931 430.169 238.523 431.644 238.145C432.933 237.964 433.931 239.461 434.223 240.589"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_656"
            d="M499.895 215.934C500.557 217.492 500.628 219.17 499.754 218.843C498.244 218.283 497.016 214.385 497.838 213.791C498.5 213.482 499.436 214.859 499.895 215.934Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_657"
            d="M135.706 485.432C136.254 486.405 135.892 486.74 135.256 486.568C133.693 486.138 131.653 483.651 132.43 483.126C132.612 483.054 132.814 483.054 132.996 483.126C134.137 483.599 135.084 484.429 135.689 485.484"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_658"
            d="M384.615 247.87C384.995 250.374 382.849 251.148 381.436 250.141C379.759 248.928 379.785 245.753 381.94 245.331C383.158 245.185 384.386 246.338 384.589 247.87"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_659"
            d="M357.055 250.907C357.294 253.221 355.289 254.22 353.797 253.135C352.031 251.845 352.163 248.643 354.477 248.291C355.74 248.187 356.896 249.358 357.055 250.872"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_660"
            d="M139 476.318C139.548 477.532 138.956 477.859 138.117 477.566C136.351 476.972 134.806 474.503 135.821 473.952C136.014 473.875 136.229 473.875 136.422 473.952C137.008 474.158 137.545 474.478 138 474.892C138.455 475.306 138.82 475.806 139.071 476.361"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_661"
            d="M272.161 254.255C271.967 256.621 269.662 257.284 268.4 255.855C266.837 254.082 268.17 251.277 270.042 251.303C271.914 251.329 272.241 253.291 272.161 254.255Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_662"
            d="M99.697 231.252C99.4321 231.829 98.6904 233.137 97.5248 232.844C95.8735 232.13 96.9685 228.585 98.6374 227.87C98.8846 227.752 99.169 227.73 99.4321 227.81C100.315 228.12 100.439 229.695 99.7147 231.252"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_663"
            d="M82.4868 224.704C81.6037 226.571 80.3233 226.872 79.9171 225.805C79.2725 224.084 81.0562 220.952 82.4338 221.356C83.3874 221.881 82.9813 223.645 82.4779 224.704"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_664"
            d="M108.403 111.266C108.023 111.567 106.778 112.514 106.813 112.196C107.052 111.542 110.345 108.822 110.91 108.857C110.183 109.754 109.333 110.55 108.385 111.223"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_665"
            d="M108.702 116.799C107.581 117.711 106.786 118.133 106.821 117.806C106.901 117.092 110.53 114.063 110.919 114.364C110.923 114.378 110.923 114.393 110.919 114.407C110.919 114.82 109.541 116.085 108.685 116.773"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_666"
            d="M111.625 122.35C110.318 123.477 109.382 123.951 109.409 123.469C109.409 123.185 110.027 122.281 111.351 121.171C111.837 120.758 113.17 119.751 113.48 120.018C113.621 120.465 112.332 121.739 111.625 122.35Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_667"
            d="M116.924 127.986C115.503 129.285 114.576 129.569 114.602 129.019C114.646 127.9 117.966 125 118.593 125.525C118.894 125.895 117.94 127.065 116.924 127.986Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_668"
            d="M124.952 133.597C123.407 135.077 122.532 135.085 122.506 134.526C122.506 133.45 125.349 130.568 126.382 130.981C126.877 131.411 125.791 132.779 124.943 133.562"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_669"
            d="M341.911 143.425C342.494 145.198 341.381 145.792 340.339 145.241C338.617 144.32 338.202 141.652 339.377 141.274H339.562C340.666 141.231 341.549 142.375 341.911 143.459"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_670"
            d="M323.084 145.198C323.394 146.988 322.157 147.469 321.186 146.833C319.676 145.843 319.42 143.175 320.815 142.866H320.965C321.901 142.823 322.846 143.872 323.084 145.215"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_671"
            d="M255.559 340.378C255.553 340.857 255.393 341.322 255.101 341.706C254.809 342.091 254.4 342.377 253.932 342.522C253.464 342.668 252.961 342.667 252.494 342.518C252.027 342.369 251.619 342.081 251.33 341.695C249.899 339.922 251.074 337.031 253.211 337.074C254.977 337.229 255.754 338.889 255.559 340.378Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_672"
            d="M228.591 144.122C227.876 145.903 226.34 146.333 225.845 145.283C225.174 143.855 226.816 141.514 228.044 141.626C229.042 141.807 229.033 143.046 228.591 144.122Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_673"
            d="M211.117 142.082C210.234 143.872 208.627 144.268 208.292 143.184C207.841 141.721 209.696 139.466 210.879 139.612C212.062 139.759 211.497 141.333 211.117 142.082Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_674"
            d="M179.054 136.592C177.756 138.235 176.476 138.313 176.335 137.556C176.114 136.368 178.189 133.829 179.558 134.01C180.255 134.182 180.026 135.361 179.054 136.592Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_675"
            d="M152.474 128.846C150.955 130.361 149.896 130.413 149.825 129.776C149.772 128.7 152.403 126.05 153.578 126.334C154.231 126.686 153.251 128.055 152.43 128.846"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_676"
            d="M141.649 124.303C140.228 125.559 139.177 125.783 139.186 125.164C139.186 124.148 142.108 121.455 143.159 121.825C143.61 122.066 142.93 123.193 141.649 124.337"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_677"
            d="M133.12 119.183C131.468 120.569 130.621 120.655 130.647 120.173C130.691 119.132 134.082 116.412 134.771 116.868C135.159 117.256 133.888 118.504 133.12 119.183Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_678"
            d="M126.92 114.037C125.446 115.199 124.58 115.474 124.598 115.07C124.598 114.209 128.36 111.344 128.854 111.808C129.075 112.196 127.742 113.392 126.92 114.037Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_679"
            d="M122.982 108.977C121.799 109.837 121.004 110.199 121.004 109.881C121.004 109.209 124.81 106.352 125.295 106.662C125.295 107.127 123.609 108.478 122.947 108.951"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_680"
            d="M121.746 103.684C120.554 104.545 119.821 104.898 119.812 104.674C119.812 104.14 123.733 101.292 124.139 101.524V101.567C124.051 101.954 122.576 103.082 121.72 103.684"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_681"
            d="M122.708 98.5213C122.178 98.9412 121.581 99.2753 120.942 99.5109C120.819 99.1667 124.863 96.3183 125.269 96.456C124.486 97.2147 123.625 97.8924 122.699 98.4783"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_682"
            d="M512.663 416.666C512.215 417.594 511.692 418.486 511.1 419.334C511.745 417.691 512.556 416.114 513.519 414.626C513.54 414.601 513.564 414.578 513.59 414.558C513.353 415.288 513.042 415.995 512.663 416.666Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_683"
            d="M112.941 358.785C112.888 360.979 111.228 361.47 110.08 360.213C108.446 358.441 109.126 355.394 110.724 355.368C110.827 355.359 110.931 355.359 111.033 355.368C112.588 355.833 112.958 357.709 112.941 358.75"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_684"
            d="M138.321 363.871C138.321 364.43 138.1 366.22 136.316 366.005C133.888 365.394 133.588 361.633 135.354 360.704C135.636 360.552 135.965 360.503 136.281 360.566C137.756 360.919 138.383 362.537 138.339 363.879"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_685"
            d="M318.37 374.808C318.229 377.338 315.465 378.156 314.061 376.624C313.729 376.228 313.511 375.753 313.429 375.247C313.347 374.742 313.404 374.224 313.594 373.747C313.785 373.27 314.102 372.851 314.512 372.533C314.923 372.214 315.414 372.008 315.933 371.934C317.39 371.883 318.459 373.148 318.37 374.808Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_686"
            d="M343.218 464.314C343.061 464.828 342.743 465.281 342.31 465.611C341.876 465.941 341.348 466.131 340.798 466.155C338.644 466.216 338.211 464.254 339.553 462.937C340.048 462.451 340.7 462.147 341.399 462.076C341.693 462.037 341.993 462.072 342.269 462.18C342.546 462.287 342.788 462.463 342.974 462.69C343.159 462.916 343.28 463.186 343.326 463.472C343.372 463.758 343.341 464.051 343.235 464.322"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_687"
            d="M349.851 373.243C349.542 375.824 346.698 376.47 345.436 374.817C345.156 374.411 344.985 373.942 344.939 373.454C344.892 372.967 344.972 372.475 345.17 372.025C345.369 371.575 345.68 371.181 346.074 370.878C346.469 370.575 346.936 370.373 347.431 370.291C349.436 370.291 349.966 372.09 349.824 373.243"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_688"
            d="M151.362 455.932C151.689 457.653 150.162 457.705 149.005 456.887C147.318 455.7 146.868 453.445 148.263 453.187C148.444 453.156 148.629 453.156 148.811 453.187C149.45 453.376 150.025 453.73 150.474 454.212C150.923 454.694 151.23 455.285 151.362 455.923"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_689"
            d="M380.87 370.351C380.632 372.933 377.859 373.793 376.596 372.201C375.21 370.48 376.596 367.752 378.539 367.546C380.482 367.339 381.003 368.931 380.87 370.351Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_690"
            d="M430.488 88.8491C429.287 87.9885 427.256 86.6805 426.691 86.2675L426.611 86.2158L426.682 86.2675L430.488 88.8491Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_691"
            d="M433.262 137.495C434.312 138.709 434.639 139.819 433.924 139.741C432.52 139.595 429.659 136.299 430.445 135.688C431.098 135.49 432.502 136.661 433.244 137.513"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_692"
            d="M442.207 132.556C443.231 133.666 443.664 134.69 443.09 134.673C441.862 134.63 438.745 131.334 439.346 130.706C439.893 130.508 441.376 131.67 442.189 132.556"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_693"
            d="M435.176 92.5919C435.987 93.1213 436.765 93.6959 437.507 94.313C436.005 93.4201 434.563 92.4342 433.189 91.3613C433.893 91.7296 434.569 92.1466 435.212 92.6091"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_694"
            d="M448.642 127.126C449.808 128.253 450.453 129.346 449.941 129.346C449.058 129.346 445.525 126.239 446.02 125.491C446.311 125.267 447.645 126.162 448.669 127.126"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_695"
            d="M452.812 121.627C454.004 122.694 454.807 123.778 454.428 123.813C453.607 123.89 450.013 120.672 450.339 120.104C450.604 119.854 451.946 120.844 452.812 121.627Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_696"
            d="M439.539 97.8671C440.305 98.3574 441.007 98.9352 441.632 99.5882C441.235 99.6571 437.57 97.0066 437.217 96.4902C438.043 96.8685 438.825 97.333 439.548 97.8757"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_697"
            d="M454.638 116.068C455.424 116.747 456.616 117.909 456.457 118.15C455.821 118.236 452.351 115.25 452.271 114.57C452.342 114.338 453.605 115.181 454.638 116.068Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_698"
            d="M453.914 110.413C454.686 110.982 455.365 111.661 455.928 112.427C455.469 112.548 451.901 109.441 451.742 108.985C451.875 108.865 452.961 109.648 453.923 110.431"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_699"
            d="M441.295 103.03C442.178 103.684 443.415 104.708 443.317 104.915C442.655 104.992 438.902 102.084 438.902 101.688C439.132 101.567 440.501 102.471 441.304 103.065"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_700"
            d="M450.568 104.752C451.36 105.352 452.104 106.011 452.793 106.722C451.314 105.737 449.925 104.628 448.643 103.409C449.327 103.824 449.979 104.287 450.594 104.795"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_701"
            d="M446.956 100.991C446.655 100.741 446.655 100.741 442.911 97.8153L442.85 97.7637C443.238 98.0477 443.618 98.3574 444.006 98.6242L446.92 100.922"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_702"
            d="M440.775 108.194C442.082 109.192 442.965 110.173 442.541 110.173C441.746 110.173 438.126 107.325 438.196 106.791C438.364 106.576 439.653 107.351 440.739 108.194"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_703"
            d="M437.976 113.305C439.222 114.321 440.025 115.336 439.601 115.362C438.718 115.414 434.965 112.324 435.336 111.842C435.645 111.619 437.102 112.583 437.985 113.305"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_704"
            d="M208.106 80.3297C207.082 80.8976 205.245 81.7151 205.413 81.2849C205.757 80.4243 209.616 78.6086 210.04 78.7721C210.04 78.9958 209.078 79.7617 208.089 80.3125"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_705"
            d="M433.049 118.322C434.197 119.347 434.815 120.31 434.391 120.388C433.508 120.551 429.976 117.436 430.249 116.765C430.249 116.765 430.249 116.765 430.249 116.722C430.576 116.515 432.015 117.393 433.022 118.322"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_706"
            d="M425.984 123.142C427.088 124.235 427.565 125.242 426.965 125.207C425.684 125.147 422.47 121.997 423.106 121.429C423.644 121.231 425.119 122.29 425.976 123.151"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_707"
            d="M416.73 127.616C417.79 128.761 418.152 129.802 417.472 129.767C415.98 129.681 413.322 126.54 413.799 125.929C413.828 125.903 413.861 125.88 413.896 125.861C414.558 125.688 415.971 126.807 416.73 127.582"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_708"
            d="M405.676 131.962C406.559 133.098 406.709 134.079 405.994 134.019C404.501 133.89 401.808 130.646 402.673 130.026L402.762 129.983C403.557 129.863 404.969 131.059 405.676 131.962Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_709"
            d="M392.43 135.654C393.313 136.867 393.313 137.969 392.518 137.865C390.752 137.65 388.712 134.492 389.542 133.83C389.587 133.794 389.638 133.768 389.693 133.752C390.576 133.623 391.812 134.785 392.43 135.654Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_710"
            d="M116.669 134.776C115.45 135.989 114.17 136.609 114.188 136.127C114.465 135.15 114.998 134.261 115.733 133.545C116.236 133.054 117.658 132.005 118.144 132.211C118.488 132.495 117.649 133.795 116.669 134.793"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_711"
            d="M377.489 139.174C378.143 140.336 378.063 141.523 377.03 141.248C375.034 140.714 373.577 137.616 374.646 137.117H374.761C375.758 137.014 376.968 138.305 377.48 139.208"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_712"
            d="M286.748 340.739C286.43 343.218 283.79 343.76 282.474 342.159C281.159 340.558 281.944 337.676 284.32 337.529C284.704 337.566 285.075 337.681 285.411 337.867C285.746 338.053 286.038 338.305 286.266 338.608C286.495 338.911 286.657 339.257 286.74 339.624C286.823 339.991 286.825 340.371 286.748 340.739Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_713"
            d="M379.476 403.715C379.374 404.306 379.08 404.85 378.636 405.265C378.192 405.681 377.623 405.946 377.012 406.021C374.646 406.167 374.116 403.568 375.644 401.925C376.083 401.424 376.69 401.092 377.357 400.987C379.123 400.883 379.785 402.312 379.502 403.715"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_714"
            d="M224.529 104.82C223.381 106.344 222.251 106.542 222.18 105.827C222.066 104.709 224.132 102.678 224.988 102.79C225.695 102.97 225.033 104.149 224.529 104.82Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_715"
            d="M497.686 172.477C498.569 173.862 498.958 175.058 498.517 174.972C497.633 174.783 495.249 171.057 495.585 170.385C495.876 170.05 496.998 171.392 497.686 172.477Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_716"
            d="M236.867 106.421C236.028 107.876 234.854 108.323 234.606 107.592C234.227 106.516 236.037 104.33 236.955 104.425C237.715 104.58 237.229 105.793 236.867 106.421Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_717"
            d="M492.212 179.172C493.042 180.506 493.501 182.029 492.856 181.909C491.7 181.693 489.421 177.89 490.057 177.227C490.516 176.926 491.594 178.182 492.212 179.172Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_718"
            d="M484.088 186.039C484.873 187.476 484.971 188.75 484.273 188.62C483.359 188.152 482.627 187.406 482.189 186.495C481.748 185.686 481.227 184.257 481.677 183.836C482.313 183.586 483.505 184.989 484.088 186.056"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_719"
            d="M249.715 107.798C249.008 109.339 247.887 109.519 247.622 108.805C247.26 107.807 248.381 105.673 249.512 105.664C250.315 105.819 250.077 107.024 249.715 107.798Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_720"
            d="M472.9 192.346C473.686 193.878 473.58 195.238 472.697 194.928C471.081 194.395 469.368 190.832 470.383 190.1C471.204 189.834 472.352 191.236 472.9 192.329"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_721"
            d="M458.948 198.276C459.716 199.937 459.372 201.314 458.294 200.857C456.343 200.049 455.221 196.451 456.352 196.013C457.305 195.772 458.436 197.183 458.939 198.267"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_722"
            d="M442.355 203.826C442.991 205.435 442.523 206.958 441.172 206.313C439.141 205.332 438.382 202.01 439.689 201.451C440.784 201.244 441.932 202.742 442.338 203.826"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_723"
            d="M423.186 208.878C423.186 208.955 423.777 210.934 422.462 211.382C420.572 211.623 418.753 208.181 419.963 206.657C420.089 206.491 420.27 206.369 420.475 206.313C421.428 206.124 422.744 207.363 423.194 208.895"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_724"
            d="M401.446 213.129C401.923 214.85 400.898 216.39 399.318 215.478C397.04 214.161 397.234 211.02 398.682 210.65C400.051 210.521 401.093 212.027 401.402 213.137"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_725"
            d="M388.987 90.423C390.293 91.2836 390.859 91.9117 390.541 91.9548C389.755 92.0839 385.675 89.5711 385.843 89.0719C386.276 88.9515 387.962 89.812 388.96 90.4574"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_726"
            d="M521.484 240.322C521.979 242.043 521.926 243.532 521.378 243.282C520.336 242.818 519.25 238.928 519.895 238.024C520.407 237.672 521.14 239.195 521.475 240.331"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_727"
            d="M512.071 247.946C512.495 249.538 512.336 251.234 511.541 250.855C510.27 250.244 509.334 246.389 510.261 245.563C510.967 245.236 511.753 246.793 512.027 247.955"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_728"
            d="M499.374 255.176C499.816 256.991 499.303 258.523 498.412 258.05C496.928 257.267 496.195 253.558 497.361 252.757C498.156 252.482 499.021 253.799 499.356 255.184"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_729"
            d="M483.522 261.982C483.867 263.703 483.266 265.622 481.933 264.684C480.238 263.488 480.087 260.123 481.341 259.521C482.366 259.254 483.258 260.794 483.514 262.016"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_730"
            d="M464.617 268.402C464.873 270.123 463.981 271.965 462.489 270.838C460.634 269.426 461.014 266.087 462.277 265.674C463.566 265.416 464.44 267.137 464.617 268.377"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_731"
            d="M129.385 410.53C129.614 412.578 127.989 412.802 126.815 411.855C125.049 410.435 124.969 407.552 126.7 407.458C126.824 407.449 126.948 407.449 127.071 407.458C127.715 407.694 128.276 408.106 128.686 408.645C129.097 409.184 129.339 409.826 129.385 410.496"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_732"
            d="M442.763 274.064C442.948 275.854 441.774 277.661 440.202 276.431C438.339 274.968 438.674 271.81 440.299 271.267C441.359 271.035 442.604 272.438 442.763 274.038"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_733"
            d="M79.5712 162.898C78.5381 164.224 77.6286 164.809 77.5049 164.224C77.2842 163.148 79.9951 159.68 80.7633 160.05L80.8251 160.093C81.1342 160.635 80.1452 162.167 79.5712 162.898Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_734"
            d="M87.5989 169.723C86.3009 171.444 85.4443 171.444 85.3472 170.807C85.1706 169.559 87.5548 166.246 88.6232 166.737C89.1619 167.244 88.3053 168.802 87.5989 169.723Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_735"
            d="M377.02 215.479C377.452 217.639 375.748 218.594 374.273 217.613C372.295 216.296 372.569 213.31 374.273 212.992H374.362C376.048 212.88 376.843 214.584 377.011 215.496"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_736"
            d="M145.923 175.264C144.731 177.261 143.204 177.355 142.921 176.357C142.444 174.714 144.758 172.106 145.985 172.416C146.921 172.743 146.612 174.137 145.923 175.264Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_737"
            d="M164.13 179.524C163.044 181.495 161.428 181.581 161.066 180.522C160.58 179.094 162.302 176.366 163.927 176.564C164.942 176.796 164.81 178.285 164.13 179.524Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_738"
            d="M344.745 187.192C345.116 189.206 343.5 190.058 342.175 189.086C340.409 187.778 340.683 184.998 342.246 184.731H342.308C343.871 184.688 344.586 186.323 344.745 187.201"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_739"
            d="M367.792 184.713C368.26 186.632 366.909 187.476 365.567 186.71C363.625 185.6 363.492 182.717 364.967 182.295H365.143C366.618 182.2 367.554 183.749 367.792 184.748"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_740"
            d="M389.993 182.614C390.461 183.999 389.922 185.583 388.403 184.851C386.16 183.758 385.86 180.721 387.096 180.256L387.247 180.213C388.509 180.101 389.622 181.581 389.975 182.614"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_741"
            d="M409.568 178.578C410.222 180.024 409.904 181.461 408.606 181.022C406.557 180.316 405.462 177.081 406.645 176.418C406.703 176.378 406.77 176.352 406.84 176.341C407.935 176.194 409.118 177.589 409.568 178.578Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_742"
            d="M426.912 174.034C427.689 175.471 427.583 176.934 426.355 176.538C424.333 175.884 422.982 172.493 424.13 171.943H424.201C425.181 171.762 426.373 173.07 426.912 174.06"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_743"
            d="M327.968 131.351C328.41 132.891 327.527 133.563 326.52 133.02C324.886 132.16 324.542 129.518 325.681 129.217H325.814C326.935 129.217 327.712 130.499 327.968 131.368"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_744"
            d="M442.028 169.207C442.832 170.601 442.744 171.788 441.852 171.599C439.989 171.203 438.231 167.718 439.15 167.055L439.247 167.004C440.13 166.823 441.419 168.166 442.02 169.207"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_745"
            d="M454.586 163.733C455.469 164.99 455.69 166.358 454.807 166.177C453.209 165.842 450.957 162.365 451.902 161.694C452.617 161.462 453.915 162.727 454.551 163.733"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_746"
            d="M241.493 131.833C240.848 133.502 239.4 133.932 238.941 132.951C238.402 131.807 239.541 129.44 240.937 129.44C241.82 129.613 241.881 130.8 241.484 131.833"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_747"
            d="M464.59 157.882C465.535 159.129 466.021 160.541 465.235 160.403C464.247 160.001 463.419 159.298 462.877 158.398C462.383 157.718 461.676 156.298 462.074 156.014C462.648 155.765 463.893 156.952 464.59 157.882Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_748"
            d="M472.114 151.901C473.148 153.192 473.616 154.396 473.059 154.353C472.106 154.267 469.147 150.851 469.686 150.051C470.004 149.784 471.231 150.808 472.114 151.901Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_749"
            d="M225.175 130.258C224.292 131.979 222.932 132.203 222.597 131.377C222.111 130.163 223.674 127.84 224.945 127.874C225.899 128.064 225.59 129.406 225.157 130.258"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_750"
            d="M476.873 145.61C477.818 146.703 478.639 148.045 478.171 148.071C477.5 148.071 474.374 144.749 474.639 143.88C474.974 143.622 476.326 144.965 476.882 145.601"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_751"
            d="M209.643 128.176C208.557 129.897 207.215 130.052 206.994 129.209C206.703 127.987 208.522 125.715 209.74 125.766C210.57 125.973 210.261 127.152 209.617 128.15"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_752"
            d="M181.649 122.444C180.201 124.105 178.93 124.269 178.903 123.494C178.85 122.212 181.446 119.966 182.373 120.198C183.115 120.474 182.223 121.79 181.649 122.444Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_753"
            d="M169.421 119.036C168.017 120.396 166.771 120.688 166.771 119.966C166.771 118.907 169.421 116.463 170.551 116.704C171.089 116.979 170.365 118.115 169.403 119.036"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_754"
            d="M149.896 110.672C148.174 112.015 147.308 112.067 147.37 111.585C147.503 110.578 150.796 108.142 151.618 108.469C151.944 108.693 151.194 109.666 149.852 110.672"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_755"
            d="M115.06 392.346C115.113 394.394 113.524 394.498 112.456 393.482C110.822 391.925 110.857 389.05 112.509 388.956C112.635 388.943 112.762 388.943 112.888 388.956C114.266 389.377 115.016 390.961 115.052 392.303"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_756"
            d="M140.104 397.338C140.104 399.317 138.435 399.696 137.154 398.543C135.494 397.02 135.653 394.24 137.41 394.076C137.545 394.059 137.682 394.059 137.817 394.076C138.507 394.303 139.103 394.742 139.515 395.327C139.928 395.912 140.134 396.612 140.104 397.321"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_757"
            d="M167.009 401.348C167.058 401.614 167.043 401.887 166.963 402.146C166.884 402.405 166.743 402.643 166.552 402.839C166.361 403.035 166.126 403.184 165.864 403.275C165.603 403.366 165.324 403.395 165.048 403.361C162.797 402.931 161.808 399.764 163.538 398.585C163.698 398.471 163.88 398.389 164.072 398.345C164.265 398.301 164.465 398.295 164.66 398.327C165.35 398.495 165.961 398.888 166.391 399.441C166.822 399.993 167.046 400.672 167.026 401.365"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_758"
            d="M348.887 406.374C348.525 408.835 345.576 409.454 344.428 407.828C343.28 406.201 344.781 403.843 346.618 403.714C346.963 403.695 347.307 403.755 347.623 403.889C347.94 404.024 348.22 404.228 348.44 404.487C348.661 404.745 348.816 405.051 348.894 405.378C348.972 405.706 348.969 406.047 348.887 406.374Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_759"
            d="M457.774 180.634C458.657 182.123 458.533 183.44 457.606 183.216C455.752 182.699 454.127 179.094 455.116 178.526C455.999 178.285 457.191 179.662 457.765 180.669"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_760"
            d="M528.619 381.658L530.385 376.495V376.443L530.332 376.615L528.619 381.632"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_761"
            d="M131.433 205.109C130.462 207.097 128.784 207.45 128.333 206.262C127.618 204.481 129.729 201.727 131.124 202.054C132.183 202.415 132.007 203.896 131.433 205.109Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_762"
            d="M525.273 386.848C524.969 387.829 524.539 388.768 523.993 389.644C523.728 389.3 525.176 385.342 525.759 384.481C525.759 384.481 525.812 384.43 525.839 384.421C525.805 385.25 525.607 386.066 525.256 386.822"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_763"
            d="M97.2584 193.809C96.1016 195.814 94.7683 195.943 94.5122 194.962C94.1325 193.499 96.1193 190.358 97.5233 190.806C98.1768 191.107 98.062 192.424 97.2584 193.809Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_764"
            d="M83.8184 187.252C82.7411 188.973 81.6285 189.317 81.3901 188.474C80.9751 186.994 83.3416 183.664 84.4012 184.231C85.0988 184.756 84.3129 186.434 83.8008 187.243"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_765"
            d="M73.4871 180.48C72.4363 182.003 71.5974 182.304 71.4738 181.624C71.2707 180.437 73.5225 176.796 74.5026 177.373C74.9088 177.846 74.2995 179.292 73.4783 180.471"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_766"
            d="M469.845 174.852C470.675 176.254 470.728 177.433 470.004 177.313C468.503 176.977 466.357 173.38 467.249 172.666C467.955 172.442 469.236 173.81 469.845 174.852Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_767"
            d="M479.045 168.501C479.928 169.852 480.273 171.082 479.672 171.082C478.471 170.962 475.911 167.15 476.635 166.539C477.173 166.255 478.401 167.554 479.045 168.518"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_768"
            d="M238.138 118.735C237.352 120.456 236.054 120.663 235.754 119.837C235.33 118.675 236.761 116.506 237.935 116.524C238.5 116.592 238.739 117.453 238.138 118.735Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_769"
            d="M485.491 161.961C486.427 163.226 487.045 164.697 486.374 164.543C485.306 164.284 482.886 160.842 483.257 160.094C483.531 159.81 484.643 160.842 485.456 161.961"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_770"
            d="M489.209 155.43C490.198 156.746 490.763 157.942 490.339 157.83C489.509 157.624 486.984 154.268 487.081 153.528C487.302 153.235 488.476 154.483 489.209 155.455"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_771"
            d="M223.771 117.178C222.729 118.77 221.493 118.899 221.334 118.125C221.122 117.049 222.711 114.829 223.93 114.889C224.76 115.095 224.292 116.369 223.771 117.178Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_772"
            d="M361.321 284.795C361.462 287.179 359.166 288.237 357.656 286.972C355.996 285.63 356.376 282.42 358.733 282.076C360.085 281.964 361.224 283.16 361.321 284.795Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_773"
            d="M404.933 430.434C404.271 432.646 401.498 433.352 400.783 431.923C400.068 430.495 401.534 428.309 403.273 428.042C404.863 427.887 405.313 429.161 404.933 430.434Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_774"
            d="M55.9957 249.495C55.2893 251.509 54.3356 251.586 53.9471 250.872C53.064 249.254 54.5917 245.769 55.7131 245.984C55.7694 245.995 55.8233 246.015 55.8721 246.044C56.6138 246.578 56.4637 248.213 56.0134 249.487"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_775"
            d="M271.622 288.229C271.481 290.612 269.07 291.395 267.702 290.019C266.201 288.513 266.96 285.372 269.362 285.26C270.722 285.26 271.719 286.594 271.622 288.229Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_776"
            d="M301.593 288.203C301.452 290.622 298.944 291.327 297.61 289.864C295.959 288.083 297.248 285.252 299.191 285.209C301.134 285.166 301.646 287.197 301.593 288.203Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_777"
            d="M346.679 436.665C346.317 438.954 343.403 439.66 342.317 438.179C341.355 436.871 342.37 434.643 344.551 434.384C344.858 434.349 345.17 434.385 345.46 434.491C345.75 434.596 346.01 434.767 346.218 434.991C346.426 435.214 346.576 435.482 346.657 435.773C346.737 436.064 346.745 436.37 346.679 436.665Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_778"
            d="M170.559 432.026C170.683 433.945 168.793 434.281 167.425 433.076C165.712 431.604 166.082 429.333 167.628 429.143C167.777 429.122 167.929 429.122 168.078 429.143C168.755 429.297 169.363 429.659 169.81 430.178C170.257 430.696 170.52 431.341 170.559 432.017"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_779"
            d="M144.51 428.042C144.704 429.986 142.983 430.073 141.79 429.1C140.086 427.697 140.024 425.15 141.667 425.03C141.799 425.021 141.932 425.021 142.064 425.03C142.73 425.237 143.318 425.632 143.754 426.165C144.189 426.698 144.452 427.345 144.51 428.024"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_780"
            d="M120.235 423.017C120.588 424.944 119.272 425.159 118.213 424.462C116.446 423.275 115.846 420.375 117.329 420.091C117.499 420.06 117.672 420.06 117.842 420.091C118.463 420.332 119.009 420.727 119.426 421.237C119.843 421.748 120.115 422.355 120.217 422.999"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_781"
            d="M331.589 286.878C331.669 289.623 329.037 290.234 327.686 288.883C326.203 287.395 326.803 284.417 329.082 284.159C330.468 284.107 331.545 285.278 331.589 286.878Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_782"
            d="M351.518 218.473C351.57 218.724 351.571 218.983 351.52 219.234C351.468 219.486 351.366 219.724 351.219 219.937C351.072 220.149 350.882 220.331 350.662 220.471C350.442 220.611 350.196 220.707 349.937 220.754C347.677 220.831 346.635 217.699 347.942 216.236C348.069 216.085 348.227 215.96 348.404 215.869C348.581 215.777 348.775 215.721 348.975 215.702C350.432 215.702 351.421 217.079 351.536 218.473"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_783"
            d="M325.452 220.177C325.584 222.578 323.447 223.129 322.22 222.062C320.71 220.745 320.983 217.759 323.103 217.509C324.295 217.458 325.372 218.68 325.452 220.177Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_784"
            d="M299.128 221.313C299.013 223.516 296.991 224.153 295.764 222.905C294.395 221.519 294.881 218.602 296.956 218.447C298.634 218.516 299.172 220.246 299.11 221.313"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_785"
            d="M155.062 415.245C155.062 417.337 153.137 417.345 151.954 416.244C150.338 414.755 150.524 412.225 152.246 412.079C152.387 412.07 152.528 412.07 152.669 412.079C153.362 412.284 153.969 412.701 154.4 413.268C154.831 413.835 155.063 414.522 155.062 415.228"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_786"
            d="M98.9448 176.263C97.6556 178.053 96.5783 178.036 96.437 177.244C96.1898 175.885 98.4062 172.761 99.6601 173.209C100.314 173.656 99.7926 175.085 98.936 176.263"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_787"
            d="M113.101 182.098C112.819 182.562 111.786 184.051 110.7 183.75C109.287 183.053 111.265 179.645 112.907 179.181C113.074 179.131 113.252 179.131 113.419 179.181C114.223 179.576 113.817 180.97 113.11 182.106"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_788"
            d="M182.383 418.929C182.33 420.899 180.22 421.175 178.851 419.867C177.279 418.335 177.827 415.796 179.734 415.814H179.902C180.622 415.962 181.264 416.355 181.714 416.923C182.164 417.49 182.392 418.195 182.357 418.912"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_789"
            d="M147.83 192.148C146.832 194.179 145.075 194.567 144.642 193.293C144.051 191.572 146.188 188.99 147.521 189.248C148.713 189.618 148.272 191.245 147.83 192.148Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_790"
            d="M332.612 422.965C332.555 423.439 332.355 423.887 332.038 424.251C331.722 424.616 331.302 424.882 330.831 425.016C330.361 425.15 329.86 425.146 329.392 425.005C328.923 424.863 328.508 424.591 328.197 424.221C327.971 423.838 327.85 423.404 327.846 422.962C327.841 422.519 327.953 422.083 328.172 421.696C328.39 421.308 328.706 420.981 329.091 420.747C329.476 420.512 329.916 420.378 330.369 420.357C330.705 420.338 331.041 420.395 331.35 420.524C331.659 420.654 331.932 420.852 332.148 421.103C332.365 421.354 332.518 421.65 332.595 421.969C332.673 422.288 332.672 422.62 332.595 422.939"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_791"
            d="M167.989 196.141C167.044 198.422 165.208 198.611 164.687 197.432C164.033 195.961 165.57 193.129 167.336 193.301C168.272 193.474 168.563 194.747 167.98 196.141"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_792"
            d="M390.435 281.68C390.585 284.098 388.28 285.053 386.805 283.745C385.039 282.145 385.922 279.193 387.777 278.952C389.631 278.711 390.373 280.673 390.426 281.68"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_793"
            d="M363.122 420.573C363.042 421.194 362.749 421.771 362.291 422.21C361.832 422.649 361.235 422.924 360.596 422.991C358.036 423.094 357.833 420.409 359.263 419.032C359.729 418.551 360.355 418.247 361.029 418.172C361.345 418.144 361.663 418.191 361.957 418.306C362.251 418.422 362.513 418.604 362.72 418.838C362.928 419.071 363.076 419.35 363.152 419.65C363.228 419.95 363.23 420.263 363.157 420.564"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_794"
            d="M285.769 205.358C285.69 207.673 283.685 208.198 282.608 207.165C281.195 205.831 281.725 202.862 283.791 202.716C284.93 202.716 285.813 203.903 285.769 205.358Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_795"
            d="M310.881 205.109C310.925 205.613 310.765 206.115 310.435 206.506C310.105 206.896 309.631 207.146 309.115 207.2C306.889 207.148 306.201 203.999 307.631 202.699C307.922 202.432 308.3 202.273 308.7 202.252C309.909 202.252 310.916 203.526 310.89 205.109"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_796"
            d="M335.915 203.568C336.153 205.779 334.29 206.373 333.08 205.47C331.314 204.136 331.597 201.218 333.548 200.952C334.546 200.891 335.738 201.924 335.915 203.533"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_797"
            d="M360.535 201.443C360.844 203.586 359.086 204.18 357.886 203.319C356.19 202.14 355.943 199.145 357.956 198.801C359.131 198.681 360.349 199.911 360.605 201.443"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_798"
            d="M532.655 366.995C532.412 367.994 532.078 368.971 531.657 369.912C531.462 369.637 532.61 365.497 533.061 364.62L533.123 364.551C533.058 365.38 532.901 366.199 532.655 366.995Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_799"
            d="M384.359 198.319C384.809 200.238 383.405 201.107 382.054 200.341C380.226 199.3 379.838 196.279 381.471 195.789H381.613C382.973 195.677 384.041 197.028 384.359 198.37"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_800"
            d="M406.619 195.677C407.175 197.347 406.372 198.603 405.118 198.035C402.831 197.011 402.469 193.793 403.705 193.276L403.855 193.225C405.118 193.078 406.239 194.602 406.601 195.677"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_801"
            d="M400.076 101.679C400.668 102.144 401.71 103.082 401.436 103.4C400.959 103.546 399.467 102.634 398.858 102.195C397.63 101.335 396.862 100.405 396.986 100.19C396.986 100.19 396.986 100.19 397.03 100.19C397.542 100.061 399.131 101.051 400.076 101.756"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_802"
            d="M426.152 191.236C426.814 192.88 426.205 194.136 425.004 193.594C422.999 192.691 421.887 189.395 423.388 188.844C424.492 188.672 425.701 190.126 426.143 191.245"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_803"
            d="M443.213 186.125C443.929 187.57 443.787 189.05 442.613 188.655C440.547 187.94 439.284 184.447 440.494 183.913C441.474 183.706 442.675 185.109 443.143 186.133"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_804"
            d="M392.429 105.302C393.559 106.257 394.071 107.118 393.523 107.143C392.393 107.187 388.958 104.312 389.479 103.77C389.479 103.77 389.479 103.77 389.532 103.77C389.903 103.632 391.298 104.39 392.429 105.336"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_805"
            d="M259.824 97.2651C259.268 98.7108 258.288 98.9862 258.014 98.3322C257.564 97.2823 258.765 95.3117 259.595 95.3633C260.425 95.4149 260.063 96.6541 259.824 97.2651Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_806"
            d="M469.747 248.532C470.012 249.487 470.047 251.062 469.067 251.492C467.363 251.828 465.844 248.196 466.992 246.501C467.09 246.342 467.239 246.218 467.416 246.148C468.572 245.89 469.473 247.473 469.765 248.532"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_807"
            d="M449.889 254.547C450.225 256.329 449.289 257.946 447.902 257.129C445.845 255.873 445.792 252.525 447.381 251.966C448.653 251.776 449.642 253.368 449.88 254.59"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_808"
            d="M510.543 268.152C510.869 269.873 510.481 271.594 509.66 271.104C508.353 270.346 507.779 266.577 508.838 265.725C509.651 265.398 510.357 266.982 510.604 268.161"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_809"
            d="M422.232 105.311C423.442 106.172 424.237 107.118 423.901 107.221C422.914 106.984 422.004 106.511 421.252 105.845C420.422 105.234 419.274 104.184 419.433 103.891C419.83 103.728 421.331 104.648 422.223 105.311"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_810"
            d="M365.055 388.878C364.719 391.322 361.973 392.071 360.737 390.6C360.455 390.195 360.283 389.728 360.237 389.241C360.191 388.754 360.272 388.264 360.474 387.816C360.675 387.368 360.989 386.978 361.387 386.68C361.785 386.383 362.254 386.189 362.75 386.116C364.516 386.056 365.249 387.467 365.055 388.896"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_811"
            d="M248.495 96.3441C247.753 97.6693 246.729 98.0652 246.508 97.4284C246.225 96.499 247.709 94.3992 248.601 94.4767C249.183 94.5886 248.954 95.5266 248.495 96.3441Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_812"
            d="M319.561 178.758C319.561 179.257 318.873 182.347 317.098 180.815C315.19 179.18 315.941 176.512 317.433 176.34C318.413 176.289 319.517 177.717 319.561 178.758Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_813"
            d="M333.885 391.107C333.873 391.596 333.71 392.07 333.418 392.468C333.126 392.866 332.718 393.169 332.247 393.339C331.777 393.509 331.264 393.537 330.777 393.42C330.29 393.302 329.85 393.045 329.514 392.682C329.225 392.287 329.047 391.825 328.997 391.342C328.947 390.86 329.027 390.373 329.23 389.929C329.432 389.486 329.75 389.102 330.151 388.815C330.552 388.528 331.024 388.348 331.518 388.293C333.346 388.293 334.026 389.747 333.885 391.107Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_814"
            d="M427.133 259.65C427.486 261.638 426.25 263.29 424.581 262.137C422.559 260.708 422.868 257.533 424.581 257.042C425.605 256.853 426.841 258.118 427.106 259.624"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_815"
            d="M180.194 386.899C180.088 389.102 177.924 389.533 176.591 388.138C174.94 386.417 175.708 383.543 177.818 383.672H177.854C179.796 384.008 180.229 385.918 180.185 386.891"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_816"
            d="M495.956 275.226C496.141 276.207 496.106 277.808 495.232 278.315C493.748 278.72 492.582 275.002 493.651 273.212C493.736 273.049 493.872 272.916 494.039 272.834C495.002 272.541 495.744 274.056 495.964 275.226"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_817"
            d="M478.242 282.119C478.463 283.952 477.536 285.639 476.326 284.778C474.56 283.53 474.692 280.088 476.123 279.46C477.165 279.262 478.048 280.682 478.224 282.128"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_818"
            d="M416.872 109.915C418.011 110.887 418.576 111.756 418.046 111.748C417.101 111.748 413.313 108.882 413.86 108.305C414.364 108.142 415.962 109.166 416.872 109.949"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_819"
            d="M401.436 262.8C401.684 265.106 399.741 266.079 398.284 264.977C396.518 263.6 396.774 260.563 398.761 260.124C400.377 259.977 401.268 261.406 401.41 262.8"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_820"
            d="M433.675 293.521C433.861 295.561 432.395 297.256 430.832 296.103C428.88 294.649 429.472 291.387 431.3 290.879C432.377 290.682 433.525 291.972 433.675 293.547"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_821"
            d="M237.609 95.1478C236.549 96.5247 235.578 96.714 235.525 96.103C235.436 95.165 237.185 93.2202 238.085 93.2374C238.686 93.3837 238.085 94.4508 237.6 95.1392"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_822"
            d="M145.809 164.448C145.103 165.42 143.16 166.944 142.904 165.61C142.462 163.458 145.438 161.496 146.153 161.531C146.833 161.832 146.524 163.45 145.8 164.439"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_823"
            d="M511.533 228.214C512.098 229.875 511.992 231.312 511.33 230.994C510.102 230.426 508.875 226.648 509.643 225.83C510.243 225.538 511.126 227.026 511.533 228.223"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_824"
            d="M500.699 235.365C501.22 236.983 501.017 238.678 500.089 238.205C498.491 237.344 497.688 233.61 498.65 233.042C499.427 232.723 500.337 234.255 500.699 235.382"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_825"
            d="M541.07 328.348V328.58C541.07 328.201 541.662 323.047 541.671 323.004V322.952C541.671 323.159 541.671 323.357 541.618 323.555L541.088 328.348"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_826"
            d="M314.724 136.85C314.83 137.797 314.22 139.432 312.958 138.468C312.351 138.011 311.939 137.352 311.801 136.618C311.801 135.964 312.278 134.612 312.799 134.483C313.682 134.544 314.653 135.895 314.75 136.859"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_827"
            d="M200.9 91.1637C199.867 91.9123 198.551 92.5922 198.312 92.377C198.312 92.0328 199.266 90.9915 199.822 90.5871C200.644 89.9847 202.118 89.2446 202.472 89.3393C202.825 89.434 201.792 90.5269 200.917 91.1637"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_828"
            d="M135.803 107.144C134.479 108.082 133.348 108.563 133.428 108.168C133.899 107.412 134.528 106.761 135.273 106.257C136.156 105.62 137.878 104.786 138.037 104.915C138.17 105.216 136.81 106.438 135.803 107.144Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_829"
            d="M521.997 398.198C521.538 399.282 520.929 400.616 520.752 400.9C521.354 399.21 522.056 397.555 522.854 395.943V395.892C522.608 396.677 522.313 397.447 521.971 398.198"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_830"
            d="M132.818 90.8195C132.2 91.2293 131.536 91.5699 130.84 91.835C130.84 91.4822 134.805 89.0038 135.467 88.9521C134.632 89.6316 133.746 90.25 132.818 90.8023"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_831"
            d="M516.151 405.771C515.595 407.053 514.791 408.524 514.535 408.567C514.155 408.309 516.01 404.308 516.654 403.68C516.725 403.628 516.743 403.611 516.787 403.619C516.73 404.371 516.513 405.104 516.151 405.771Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_832"
            d="M359.67 449.599C359.193 451.819 356.138 452.482 355.317 451.019C354.592 449.78 355.732 447.732 357.754 447.456C358.039 447.423 358.328 447.459 358.596 447.56C358.865 447.661 359.103 447.824 359.292 448.035C359.481 448.246 359.614 448.499 359.68 448.771C359.746 449.044 359.742 449.328 359.67 449.599Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_833"
            d="M330.423 451.862C330.017 453.807 327.2 454.565 326.123 453.024C325.24 451.759 326.247 449.728 328.357 449.522C328.667 449.492 328.979 449.536 329.267 449.649C329.555 449.763 329.811 449.943 330.012 450.174C330.214 450.405 330.355 450.68 330.423 450.975C330.492 451.271 330.486 451.578 330.406 451.871"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_834"
            d="M160.192 444.479C160.368 446.2 158.682 446.449 157.34 445.339C155.574 443.902 155.777 441.647 157.287 441.544C157.404 441.533 157.522 441.533 157.64 441.544C158.315 441.719 158.92 442.089 159.375 442.605C159.831 443.121 160.115 443.759 160.192 444.436"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_835"
            d="M426.691 91.2405C427.636 91.8257 428.952 92.7465 428.899 92.9616C428.431 93.0305 424.483 90.5607 424.254 90.0874C424.431 89.9755 425.782 90.7242 426.691 91.2921"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_836"
            d="M427.345 96.0337C427.902 96.3952 429.456 97.4967 429.359 97.7548C428.776 97.9011 424.811 95.3281 424.688 94.7343C424.811 94.5106 426.312 95.3453 427.337 96.0251"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_837"
            d="M135.538 440.004C135.9 441.819 134.469 441.966 133.33 441.183C131.396 439.849 131.211 437.327 132.633 437.155C132.773 437.131 132.916 437.131 133.056 437.155C133.689 437.372 134.252 437.748 134.688 438.245C135.124 438.743 135.417 439.343 135.538 439.986"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_838"
            d="M425.756 100.681C426.568 101.249 427.76 102.205 427.698 102.523C427.46 102.704 426.038 101.895 425.367 101.439C424.464 100.919 423.68 100.225 423.062 99.3992C423.301 99.184 424.784 100.036 425.712 100.707"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_839"
            d="M486.79 242.362C487.223 244.005 486.79 245.614 485.686 245.021C484.079 244.16 483.293 240.529 484.547 239.814C485.262 239.539 486.375 240.795 486.79 242.396"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_840"
            d="M115.088 164.99C113.719 166.875 112.438 166.995 112.315 166.161C112.085 164.827 114.381 161.858 115.688 162.254C116.28 162.521 116.015 163.725 115.088 164.99Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_841"
            d="M102.867 159.207C101.471 160.928 100.465 160.928 100.412 160.214C100.297 158.768 102.964 155.825 103.882 156.359C104.518 156.858 103.494 158.441 102.867 159.207Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_842"
            d="M93.7178 152.805C92.305 154.465 91.4749 154.595 91.4131 154.052C91.3072 153.028 94.0622 149.621 94.9453 150.137C95.3338 150.636 94.3448 152.047 93.7002 152.805"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_843"
            d="M87.5555 146.428C86.4076 147.701 85.5245 148.243 85.498 147.684C85.498 146.712 88.4474 143.381 89.0302 143.768C89.2244 144.276 88.1471 145.705 87.4937 146.35"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_844"
            d="M58.7854 270.02C58.2379 271.879 57.1695 272.214 56.622 271.268C55.7389 269.684 56.8692 266.027 58.1849 266.371L58.3086 266.423C59.1916 266.957 59.1916 268.678 58.7766 270.02"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_845"
            d="M256.204 305.422C255.877 307.858 253.325 308.357 252.08 306.756C250.835 305.156 251.674 302.264 253.969 302.195C255.877 302.385 256.345 304.269 256.186 305.422"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_846"
            d="M286.669 305.586C286.563 308.039 284.02 308.839 282.651 307.402C280.885 305.603 282.245 302.652 284.293 302.626C286.263 302.729 286.713 304.536 286.669 305.586Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_847"
            d="M85.0468 243.936C84.2432 246.139 82.848 246.277 82.327 245.296C81.5147 243.73 82.9363 240.219 84.6052 240.684C85.4 241.011 85.5942 242.405 85.0468 243.936Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_848"
            d="M101.771 373.079C101.771 375.213 100.279 375.316 99.2901 374.352C97.6918 372.786 97.7801 369.654 99.4314 369.568C99.5548 369.559 99.6788 369.559 99.8022 369.568C101.109 369.998 101.78 371.642 101.771 373.01"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_849"
            d="M317.293 305.139C317.108 307.591 314.564 308.254 313.205 306.739C311.845 305.225 312.507 302.29 314.821 302.066C316.781 302.127 317.355 303.934 317.267 305.139"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_850"
            d="M399.997 118.065C401.012 119.097 401.383 120.052 400.774 120.052C399.511 120.052 396.429 117.049 397.03 116.387C397.05 116.362 397.078 116.344 397.109 116.335C397.719 116.197 399.087 117.127 399.997 118.056"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_851"
            d="M208.936 89.4335C207.355 90.5952 206.551 90.6812 206.595 90.294C206.693 89.5884 209.571 87.437 210.498 87.5833C210.905 87.807 209.783 88.8139 208.936 89.4335Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_852"
            d="M347.81 303.349C347.81 305.758 345.399 306.662 343.925 305.337C342.159 303.77 343.042 300.767 345.311 300.466C346.521 300.397 347.819 301.533 347.81 303.349Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_853"
            d="M286.156 237.68C286.033 240.012 283.816 240.701 282.554 239.306C281 237.585 282.209 234.823 284.019 234.814C285.582 234.901 286.227 236.363 286.156 237.68Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_854"
            d="M152.245 383.182C152.165 385.359 150.161 385.72 148.871 384.326C147.326 382.605 147.9 379.8 149.754 379.851H149.975C150.674 380.08 151.273 380.531 151.676 381.132C152.08 381.733 152.265 382.449 152.2 383.164"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_855"
            d="M406.902 296.653C407.131 299.175 404.95 300.328 403.44 299.072C401.93 297.815 402.098 294.597 404.323 294.115C405.612 293.934 406.734 295.053 406.875 296.696"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_856"
            d="M286.466 271.147C286.377 273.548 283.976 274.331 282.625 272.937C280.964 271.215 282.28 268.281 284.196 268.264C286.113 268.247 286.501 270.123 286.466 271.147Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_857"
            d="M69.7182 198.551C68.7469 200.272 67.7844 200.616 67.5371 199.859C67.0956 198.491 69.1796 195.032 70.1862 195.402L70.2745 195.445C70.7337 195.797 70.4776 197.166 69.6829 198.551"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_858"
            d="M227.302 93.5646C226.163 94.7521 225.139 95.0877 225.095 94.5198C225.042 93.7367 227.161 91.5939 228.159 91.6628C228.618 91.8263 228.062 92.7643 227.276 93.5646"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_859"
            d="M82.0539 205.797C80.9678 207.707 79.864 207.681 79.6079 206.821C79.1576 205.298 81.0119 202.002 82.3895 202.518C83.1224 202.991 82.7692 204.532 82.0451 205.805"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_860"
            d="M409.286 114.054C410.39 115.052 410.911 115.99 410.372 116.024C409.251 116.102 405.833 113.055 406.354 112.453C406.354 112.453 406.399 112.453 406.407 112.453C406.787 112.298 408.173 113.09 409.286 114.097"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_861"
            d="M125.985 378.552C125.932 380.669 124.218 381.013 123 379.705C121.296 377.872 121.976 375.058 123.645 375.145H123.848C125.455 375.575 125.993 377.408 125.967 378.526"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_862"
            d="M97.497 212.233C96.455 214.307 95.0598 214.368 94.7154 213.309C94.1591 211.588 96.0841 208.662 97.497 209.006C98.2299 209.316 98.2387 210.727 97.497 212.199"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_863"
            d="M377.86 300.706C377.86 303.185 375.405 304.028 373.975 302.6C372.544 301.171 373.021 298.202 375.255 297.824C376.96 297.755 377.851 299.226 377.843 300.706"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_864"
            d="M491.099 163.174C492.124 164.585 492.636 165.902 492.115 165.756C491.303 165.558 488.848 162.313 489.139 161.453C489.466 161.109 490.561 162.425 491.099 163.174Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_865"
            d="M485.942 169.843C486.418 170.54 487.231 171.986 486.886 172.425C486.259 172.786 483.443 169.491 483.734 168.208C483.748 168.151 483.775 168.098 483.813 168.053C484.308 167.709 485.421 169.052 485.933 169.775"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_866"
            d="M477.969 176.384C478.79 177.718 479.117 179.146 478.305 178.965C476.954 178.716 474.773 175.05 475.656 174.456C476.309 174.138 477.422 175.411 477.987 176.349"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_867"
            d="M313.601 237.069C313.601 238.868 312.047 240.391 310.378 238.842C308.364 236.983 309.495 234.479 311.261 234.315C312.541 234.315 313.592 235.52 313.601 237.069Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_868"
            d="M461.783 257.275C462.154 259.125 461.227 260.717 459.814 259.814C457.951 258.583 457.969 255.279 459.426 254.831C460.742 254.547 461.554 256.139 461.783 257.275Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_869"
            d="M495.965 244.212C496.212 245.021 496.468 246.699 495.665 247.19C494.322 247.629 492.795 244.092 493.616 242.457C493.688 242.291 493.815 242.154 493.978 242.07C494.658 241.76 495.515 242.681 495.974 244.204"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_870"
            d="M508.503 237.215C509.059 239.014 508.777 240.485 507.973 240.038C506.781 239.375 505.642 235.847 506.64 235.004C507.258 234.625 508.07 235.864 508.503 237.215Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_871"
            d="M517.714 229.574C518.005 230.374 518.429 232.026 518.005 232.577C517.22 233.111 515.639 229.428 515.992 227.896C516.015 227.781 516.063 227.672 516.133 227.577C516.69 227.173 517.414 228.705 517.723 229.574"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_872"
            d="M480.362 250.907C480.583 251.767 480.742 253.402 479.753 253.884C478.181 254.254 476.663 250.752 477.696 249.143C477.812 248.952 477.989 248.804 478.199 248.721C479.356 248.394 480.124 250.003 480.371 250.907"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_873"
            d="M467.239 182.708C467.345 182.906 468.246 184.636 467.486 185.152C466.224 185.548 463.76 181.9 464.519 180.764C464.564 180.688 464.632 180.627 464.714 180.591C465.597 180.29 466.709 181.727 467.239 182.708Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_874"
            d="M397.886 202.854C398.345 204.515 397.435 205.978 395.899 205.134C393.815 203.99 393.524 201.012 395.016 200.522L395.192 200.479C396.27 200.307 397.435 201.339 397.842 202.854"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_875"
            d="M421.491 301.817C421.614 303.874 419.875 305.569 418.268 304.261C416.661 302.953 416.855 299.803 419.045 299.278C420.546 299.037 421.411 300.371 421.491 301.817Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_876"
            d="M418.965 198.706C419.035 198.913 419.6 200.72 418.311 201.142C416.492 201.382 414.594 198.147 415.706 196.718C415.772 196.622 415.856 196.54 415.955 196.477C416.053 196.413 416.164 196.369 416.28 196.348C417.614 196.142 418.62 197.648 418.929 198.706"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_877"
            d="M469.254 290.931C469.439 292.918 468.282 294.605 466.949 293.512C465.368 292.264 465.483 289.012 467.126 288.401C468.141 288.125 469.095 289.261 469.254 290.888"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_878"
            d="M469.253 137.341C469.916 138.046 470.869 139.251 470.746 139.664C470.49 139.896 469.386 138.993 468.724 138.304C467.743 137.306 466.958 136.213 467.046 135.869C467.042 135.849 467.042 135.829 467.046 135.809C467.337 135.482 468.609 136.669 469.245 137.341"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_879"
            d="M493.412 156.531C493.967 157.227 494.403 158.005 494.701 158.837C494.507 159.285 492.229 156.497 491.62 155.068C491.552 154.946 491.516 154.81 491.514 154.672C491.628 154.294 492.741 155.61 493.412 156.531Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_880"
            d="M464.839 143.52C464.989 143.692 466.243 145.241 465.934 145.705C465.527 145.946 464.371 144.957 463.735 144.277C462.852 143.287 462.154 142.16 462.296 141.807C462.291 141.784 462.291 141.761 462.296 141.738C462.764 141.42 464.062 142.668 464.803 143.52"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_881"
            d="M457.518 149.208C458.578 150.533 458.896 151.712 458.163 151.583C456.812 151.342 454.233 147.986 454.993 147.452C455.584 147.168 456.856 148.382 457.518 149.173"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_882"
            d="M504.883 277.455C505.165 279.262 504.6 281.009 503.611 280.389C502.243 279.529 501.916 275.759 503.152 275.148C504.097 274.787 504.706 276.327 504.918 277.455"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_883"
            d="M447.866 154.801C448.82 156.118 448.97 157.254 448.193 157.142C446.762 156.927 444.36 153.639 445.137 152.96L445.217 152.908C445.826 152.632 447.045 153.648 447.866 154.801Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_884"
            d="M435.698 159.826C436.158 160.506 436.838 161.909 436.096 162.288C434.771 162.597 432.21 159.405 432.837 158.217C432.894 158.116 432.985 158.038 433.093 157.993C433.977 157.744 435.213 159.086 435.743 159.826"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_885"
            d="M437.561 193.784C438.268 195.453 437.729 196.744 436.537 196.236C434.771 195.496 433.323 192.2 434.93 191.581C435.813 191.348 436.952 192.338 437.579 193.784"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_886"
            d="M453.65 188.379C454.471 190.006 454.171 191.34 453.076 190.961C451.434 190.341 449.756 187.02 451.107 186.348C451.928 186.064 453.032 187.209 453.65 188.414"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_887"
            d="M421.331 164.817C422.117 166.263 421.843 167.399 420.801 167.098C419.035 166.53 417.331 163.389 418.479 162.795L418.594 162.752C419.477 162.52 420.678 163.613 421.331 164.835"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_888"
            d="M404.625 169.129C405.322 170.618 404.872 171.78 403.741 171.418C401.816 170.816 400.386 167.615 401.781 167.029L401.914 166.986C402.894 166.78 404.059 167.916 404.616 169.129"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_889"
            d="M385.754 172.881C386.328 174.422 385.701 175.764 384.253 175.093C382.31 174.181 381.463 171.203 383.008 170.721H383.123C384.394 170.584 385.41 171.986 385.772 172.907"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_890"
            d="M488.617 284.33C488.75 285.191 488.723 286.912 487.681 287.42C486.162 287.79 485.032 284.468 486.039 282.686C486.177 282.417 486.408 282.203 486.692 282.084C487.876 281.749 488.458 283.409 488.608 284.339"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_891"
            d="M481.906 304.846C482.047 306.834 480.935 308.606 479.716 307.634C478.356 306.541 478.303 303.151 480.007 302.471C480.97 302.161 481.774 303.194 481.906 304.872"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_892"
            d="M463.283 165.394C463.769 166.091 464.528 167.511 463.972 167.976C462.824 168.363 460.113 164.869 460.704 163.777C460.745 163.722 460.792 163.673 460.846 163.63C461.587 163.329 462.771 164.671 463.292 165.42"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_893"
            d="M451.134 224.73C451.664 226.451 451.001 228.034 449.597 227.26C447.831 226.304 446.948 223.051 448.626 222.406C449.509 222.157 450.666 223.198 451.134 224.73Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_894"
            d="M514.827 290.63C515.065 292.618 514.403 294.27 513.599 293.676C512.504 292.859 512.142 289.236 513.37 288.384C514.253 288.023 514.694 289.632 514.818 290.639"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_895"
            d="M451.805 171.221C452.688 172.701 452.609 173.931 451.664 173.656C450.119 173.217 447.973 169.904 449.191 169.216C449.898 168.94 451.099 170.076 451.841 171.221"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_896"
            d="M437.764 176.615C438.523 178.113 438.232 179.283 437.19 178.939C435.424 178.354 433.79 175.213 434.911 174.516L435.035 174.464C436.077 174.214 437.243 175.66 437.737 176.624"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_897"
            d="M440.255 262.834C440.564 264.779 439.372 266.379 437.826 265.295C436.06 264.03 435.998 260.88 437.826 260.338C439.089 260.071 440.069 261.397 440.299 262.834"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_898"
            d="M421.217 181.409C421.915 182.993 421.429 184.18 420.255 183.758C418.418 183.104 417.076 179.972 418.338 179.292C418.398 179.261 418.46 179.236 418.524 179.215C419.698 179.008 420.776 180.411 421.217 181.409Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_899"
            d="M402.382 185.781C402.453 185.979 403.018 187.752 401.72 188.139C400.634 188.251 399.653 187.124 399.247 186.418C398.762 185.557 398.585 184.361 399.133 183.742C399.271 183.582 399.461 183.472 399.671 183.432C401.013 183.26 402.029 184.731 402.391 185.764"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_900"
            d="M452.132 105.32C453.165 106.18 454.251 107.11 454.366 107.256C454.207 107.196 451.072 104.588 450.463 104.02L450.41 103.96C450.507 103.96 451.646 104.915 452.176 105.32"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_901"
            d="M165.208 185.212C164.837 185.841 163.707 187.037 162.754 186.934C161.279 186.417 161.959 183.233 164.237 182.149C164.42 182.048 164.628 182.001 164.838 182.011C165.297 182.209 165.915 184.034 165.226 185.195"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_902"
            d="M456.891 111.103C457.645 111.695 458.329 112.367 458.931 113.108C458.648 113.159 455.398 110.397 454.966 109.743C454.962 109.726 454.962 109.708 454.966 109.691C455.668 110.086 456.32 110.56 456.908 111.103"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_903"
            d="M458.842 116.8C459.606 117.401 460.267 118.115 460.803 118.917C460.423 119.089 457.006 116.111 456.82 115.474C456.82 115.182 458.057 116.111 458.851 116.8"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_904"
            d="M415.926 267.576C416.059 268.436 415.926 270.097 414.416 270.467C412.271 270.648 410.884 267.3 412.429 265.691C412.682 265.422 413.016 265.238 413.383 265.166C415.087 264.977 415.803 266.663 415.944 267.576"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_905"
            d="M458.313 122.599C459.028 123.262 460.079 124.381 460.079 124.768C459.885 124.983 458.746 124.157 458.03 123.52C457.233 122.909 456.576 122.141 456.105 121.265C456.105 121.265 456.105 121.222 456.105 121.205C456.3 120.913 457.536 121.876 458.331 122.599"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_906"
            d="M340.666 275.14C340.666 277.093 338.697 278.806 336.948 276.981C334.776 274.718 337.46 272.18 338.229 272.128C339.783 272.128 340.692 273.892 340.666 275.14Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_907"
            d="M455.266 128.305C456.432 129.441 457.112 130.551 456.644 130.551C455.867 130.551 452.52 127.548 452.865 126.816C453.227 126.524 454.587 127.677 455.258 128.314"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_908"
            d="M414.506 216.253C414.753 217.044 414.877 218.619 413.623 218.981C411.751 219.17 410.02 216.029 411.159 214.506C411.33 214.277 411.576 214.113 411.857 214.041C413.331 213.852 414.223 215.427 414.506 216.278"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_909"
            d="M449.906 134.01C451.045 135.249 451.318 136.118 450.85 136.118C449.72 136.118 446.638 132.84 447.221 132.263C447.742 131.979 449.137 133.184 449.87 133.984"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_910"
            d="M435.239 211.537C435.91 213.353 435.124 214.747 433.8 214.118C431.981 213.258 430.903 209.962 432.669 209.351C433.968 209.136 434.886 210.633 435.221 211.554"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_911"
            d="M441.931 139.251C442.912 140.396 443.256 141.445 442.673 141.463C441.455 141.463 438.541 138.227 439.141 137.53C439.141 137.53 439.141 137.53 439.203 137.478C439.662 137.246 440.854 138.029 441.852 139.251"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_912"
            d="M431.646 144.173C432.661 145.447 432.873 146.583 432.087 146.506C430.63 146.351 428.078 143.115 428.908 142.478L428.988 142.427C429.809 142.203 431.081 143.468 431.637 144.148"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_913"
            d="M419.255 148.898C420.138 150.189 420.138 151.307 419.317 151.195C417.674 150.989 415.387 147.71 416.438 147.073L416.544 147.03C417.312 146.798 418.513 147.822 419.255 148.898Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_914"
            d="M404.685 153.175C405.497 154.552 405.312 155.679 404.323 155.455C402.61 155.077 400.791 152.013 401.806 151.299C401.866 151.26 401.932 151.231 402.001 151.213C403.016 151.032 404.164 152.289 404.65 153.175"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_915"
            d="M388.015 156.918C388.359 157.606 388.757 158.983 387.75 159.276C386.125 159.422 384.059 156.436 384.942 155.24C385.054 155.088 385.221 154.983 385.41 154.947C386.602 154.801 387.644 156.169 388.059 156.918"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_916"
            d="M430.851 229.841C431.08 230.649 431.195 232.267 429.968 232.663C428.034 232.896 426.356 229.428 427.698 227.965C427.862 227.78 428.082 227.65 428.325 227.595C429.756 227.371 430.621 228.963 430.886 229.841"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_917"
            d="M383.494 305.956C383.494 307.531 381.613 309.897 379.688 308.021C379.312 307.666 379.034 307.225 378.879 306.737C378.725 306.25 378.698 305.732 378.803 305.232C378.908 304.732 379.14 304.266 379.478 303.877C379.817 303.487 380.25 303.187 380.739 303.004C381.543 302.927 383.529 304.725 383.494 305.956Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_918"
            d="M498.5 180.737C498.614 180.927 499.603 182.648 499.294 183.233C498.57 183.81 496.266 179.877 496.398 178.93C496.407 178.872 496.428 178.817 496.46 178.767C496.875 178.397 497.935 179.799 498.5 180.772"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_919"
            d="M276.972 259.693C276.902 260.227 275.153 264.039 273.158 261.655C272.81 261.282 272.569 260.826 272.459 260.333C272.348 259.84 272.373 259.328 272.529 258.847C272.685 258.367 272.968 257.934 273.35 257.593C273.731 257.253 274.198 257.015 274.703 256.905C275.754 256.905 277.105 258.583 276.972 259.693Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_920"
            d="M362.68 256.07C362.627 256.819 361.603 258.652 360.702 258.893C358.812 258.893 357.081 255.915 358.989 253.945C359.262 253.631 359.641 253.424 360.057 253.359C361.196 253.273 362.751 255.081 362.706 256.07"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_921"
            d="M491.576 187.605C492.362 189.043 492.512 190.333 491.885 190.187C490.79 189.963 488.592 186.366 489.386 185.531C489.828 185.222 490.79 186.125 491.576 187.58"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_922"
            d="M481.668 194.136C482.498 195.771 482.427 197.114 481.518 196.795C480.034 196.271 478.383 192.682 479.372 192.114C480.131 191.787 481.138 193.181 481.668 194.136Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_923"
            d="M468.901 200.324C469.272 201.073 469.784 202.622 468.901 203.078C467.505 203.439 465.369 199.911 466.181 198.586C466.247 198.469 466.349 198.376 466.472 198.319C467.452 198.018 468.468 199.498 468.865 200.324"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_924"
            d="M424.422 248.79C424.731 250.683 423.46 252.232 421.897 251.062C420.042 249.711 420.016 246.759 421.897 246.234C423.071 246.019 424.202 247.095 424.466 248.816"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_925"
            d="M446.709 243.61C447.124 245.374 446.25 246.992 444.82 246.191C443.054 245.185 442.383 241.837 444.237 241.209C445.252 240.968 446.339 242.026 446.709 243.627"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_926"
            d="M466.243 237.869C466.728 239.59 466.066 241.251 464.75 240.451C463.117 239.496 462.287 236.148 463.867 235.503C464.75 235.227 465.81 236.277 466.234 237.835"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_927"
            d="M482.905 231.587C483.479 233.377 482.984 234.806 481.96 234.324C480.432 233.601 479.258 230.09 480.662 229.324C481.492 228.997 482.472 230.245 482.905 231.587Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_928"
            d="M496.539 224.712C496.839 225.503 497.237 227.13 496.539 227.637C495.338 228.076 493.537 224.402 494.243 222.965C494.3 222.847 494.389 222.746 494.499 222.672C495.382 222.311 496.204 223.851 496.539 224.712Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_929"
            d="M507.126 217.586C507.452 218.369 507.938 219.987 507.435 220.512C506.552 220.977 504.697 217.362 505.112 215.908C505.145 215.795 505.206 215.692 505.289 215.607C505.925 215.228 506.764 216.734 507.126 217.577"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_930"
            d="M503.815 198.853C504.592 200.436 504.698 201.804 504.08 201.563C503.055 201.185 501.21 197.665 501.863 196.813C502.243 196.486 503.082 197.364 503.815 198.853Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_931"
            d="M495.029 205.746C495.391 206.511 495.912 208.095 495.356 208.594C494.323 209.024 492.292 205.479 492.769 204.076C492.802 203.965 492.87 203.866 492.963 203.792C493.687 203.431 494.632 204.911 495.029 205.746Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_932"
            d="M483.381 212.596C484.026 214.179 483.84 215.762 482.763 215.263C481.323 214.609 479.902 211.167 481.094 210.436C481.756 210.134 482.763 211.081 483.381 212.596Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_933"
            d="M468.626 218.843C469.279 220.564 468.793 222.009 467.743 221.579C466.118 220.925 464.776 217.388 466.268 216.665C467.054 216.39 468.087 217.319 468.661 218.843"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_934"
            d="M453.403 206.167C453.73 206.933 454.109 208.491 453.147 208.912C451.593 209.213 449.615 205.926 450.498 204.455C450.595 204.278 450.755 204.144 450.948 204.076C452.087 203.801 453.076 205.315 453.438 206.158"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_935"
            d="M455.256 276.741C455.592 278.685 454.497 280.45 453.031 279.52C451.265 278.384 451.168 275.045 452.943 274.426C454.409 274.15 455.097 275.82 455.256 276.741Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_936"
            d="M475.813 270.837C476.157 272.739 475.213 274.503 473.906 273.557C472.422 272.489 472.016 269.176 473.65 268.488C474.586 268.195 475.513 269.202 475.813 270.837Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_937"
            d="M493.334 264.202C493.714 266.018 493.113 267.756 491.974 267.059C490.597 266.198 489.935 262.67 491.347 261.896C492.23 261.543 493.043 262.756 493.334 264.185"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_938"
            d="M507.699 257.017C507.911 257.877 508.114 259.555 507.373 260.089C506.207 260.519 504.883 257.017 505.607 255.339C505.666 255.149 505.791 254.985 505.96 254.874C506.843 254.495 507.505 256.105 507.726 257.008"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_939"
            d="M518.897 249.754C519.321 251.475 519.1 252.947 518.473 252.628C517.449 252.103 516.398 248.325 517.299 247.465C517.846 247.078 518.553 248.325 518.897 249.771"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_940"
            d="M480.326 133.623C481 134.341 481.62 135.106 482.18 135.912C480.9 134.741 479.738 133.452 478.71 132.065C478.706 132.045 478.706 132.025 478.71 132.005C479.284 132.516 479.824 133.062 480.326 133.64"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_941"
            d="M478.497 153.115C479.027 153.769 479.963 155.137 479.734 155.602C479.063 156.075 476.025 152.461 476.308 151.531V151.471C476.705 151.127 477.897 152.4 478.471 153.115"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_942"
            d="M472.238 159.379C472.83 160.162 473.572 161.479 473.201 161.901C472.318 162.314 469.51 158.906 469.828 157.805C469.848 157.749 469.878 157.696 469.916 157.65C470.49 157.331 471.682 158.639 472.238 159.371"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_943"
            d="M407.768 102.979C408.987 103.908 409.658 104.76 409.208 104.803C408.236 104.906 404.616 102.222 404.943 101.662C404.943 101.662 404.943 101.662 404.987 101.662C405.64 101.413 407.751 103.013 407.768 103.022"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_944"
            d="M436.29 92.9108C437.235 93.5906 438.303 94.3737 438.736 94.7007H438.789C438.648 94.6233 435.31 92.2481 434.727 91.8093L434.656 91.749C434.78 91.7921 435.937 92.6096 436.29 92.8591"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_945"
            d="M415.177 134.01C416.06 135.154 416.307 136.135 415.609 136.118C414.196 136.118 411.45 132.848 412.307 132.237H412.395C412.969 132.039 414.161 132.839 415.177 134.053"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_946"
            d="M442.09 98.3315C442.882 98.8754 443.627 99.4828 444.315 100.147C442.821 99.2793 441.421 98.2643 440.139 97.1182C440.842 97.468 441.505 97.8891 442.117 98.3746"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_947"
            d="M371.792 145.085C372.499 146.471 372.101 147.589 371.015 147.254C369.249 146.729 367.845 143.854 369.002 143.209C369.077 143.169 369.157 143.14 369.24 143.123C370.388 143.02 371.377 144.311 371.775 145.085"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_948"
            d="M383.846 98.581C385.002 99.4416 385.612 100.302 385.082 100.302C384.075 100.371 380.569 97.7205 381.046 97.2386H381.099C381.682 97.0837 383.139 98.0991 383.837 98.5896"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_949"
            d="M400.774 106.911C401.393 107.436 402.479 108.495 402.09 108.796C401.622 108.925 400.421 108.219 399.503 107.505C398.585 106.791 397.825 105.913 397.949 105.578C397.949 105.578 398.002 105.517 398.028 105.491C398.417 105.337 399.627 105.956 400.783 106.911"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_950"
            d="M535.197 356.187C535.016 357.219 534.72 358.229 534.314 359.199C534.457 357.419 534.849 355.668 535.48 353.992C535.48 353.932 535.48 353.923 535.56 353.906C535.573 354.683 535.469 355.457 535.25 356.204"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_951"
            d="M424.219 111.076C424.881 111.61 426.047 112.703 425.773 113.047C425.031 113.357 421.234 110.397 421.534 109.751V109.699C421.994 109.467 423.468 110.5 424.184 111.076"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_952"
            d="M425.692 129.492C426.69 130.602 427.026 131.583 426.399 131.566C425.118 131.566 422.178 128.425 422.867 127.805L422.929 127.754C423.414 127.547 424.695 128.33 425.728 129.475"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_953"
            d="M444.282 114.433C445.465 115.431 446.189 116.344 445.862 116.421C445.315 116.55 441.553 113.719 441.747 112.979C441.942 112.712 443.187 113.512 444.282 114.433Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_954"
            d="M392.173 110.775C393.215 111.774 393.595 112.643 392.977 112.634C391.679 112.634 388.615 109.76 389.294 109.192H389.365C389.824 109.037 391.131 109.751 392.173 110.793"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_955"
            d="M429.031 106.473C430.258 107.402 431.017 108.288 430.655 108.357C430.037 108.478 426.081 105.776 426.381 105.113C426.849 104.812 429.031 106.455 429.031 106.473Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_956"
            d="M432.007 96.6536C432.82 97.1323 433.548 97.7368 434.161 98.4435C433.729 98.5984 429.914 96.0684 429.746 95.5004C429.746 95.2423 431.141 96.034 432.033 96.6277"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_957"
            d="M433.931 124.544C434.54 125.129 435.591 126.325 435.22 126.695C434.337 127.031 430.805 123.83 431.273 123.09V123.038C431.838 122.78 433.233 123.899 433.922 124.544"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_958"
            d="M440.255 119.605C441.412 120.638 442.021 121.601 441.633 121.67C440.926 121.782 437.164 118.839 437.641 118.099C437.959 117.841 439.231 118.684 440.29 119.605"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_959"
            d="M390.885 95.2165C392.006 95.9996 392.651 96.7483 392.35 96.8515C391.547 97.0839 387.609 94.442 387.935 93.9085C387.935 93.9085 387.935 93.9085 387.935 93.8654C388.297 93.6933 389.701 94.4162 390.814 95.2165"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_960"
            d="M430.4 91.8434C431.198 92.2948 431.923 92.8573 432.554 93.5129C430.955 92.774 429.469 91.8211 428.139 90.6817C428.136 90.6675 428.136 90.6528 428.139 90.6387C428.947 90.9353 429.708 91.3408 430.4 91.8434Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_961"
            d="M445.959 109.097C446.683 109.674 447.778 110.663 447.814 111.008C447.478 111.386 443.646 108.426 443.584 107.738C443.752 107.462 445.209 108.512 445.959 109.097Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_962"
            d="M398.469 123.95C399.405 125.052 399.599 125.99 398.866 125.921C397.427 125.783 394.795 122.866 395.555 122.246C395.59 122.221 395.629 122.201 395.67 122.186C396.464 122.014 397.807 123.176 398.469 123.95Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_963"
            d="M532.963 368.139C532.61 369.344 532.16 370.867 532.027 371.211C532.027 371.005 533.29 366.736 533.493 366.125C533.492 366.099 533.492 366.074 533.493 366.048C533.493 366.203 533.175 367.278 532.928 368.148"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_964"
            d="M520.098 395.694C519.745 396.719 519.256 397.694 518.641 398.594C518.57 398.645 518.429 398.757 518.473 398.344C518.805 396.721 519.455 395.175 520.389 393.792C520.46 393.723 520.61 393.603 520.601 393.904C520.511 394.517 520.345 395.118 520.107 395.694"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_965"
            d="M528.602 376.211C528.323 377.249 527.926 378.254 527.419 379.206C527.366 379.258 527.286 379.335 527.277 379.094C527.517 377.392 528.045 375.74 528.84 374.206C528.902 374.146 528.92 374.129 528.964 374.12C529.149 374.258 528.734 375.738 528.602 376.194"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_966"
            d="M171.382 93.4524C169.986 94.313 168.732 94.8035 168.732 94.4334C168.847 93.6245 172.644 91.3097 173.518 91.5248C173.651 91.8346 172.309 92.8586 171.355 93.4524"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_967"
            d="M409.004 119.958C410.028 121.007 410.39 121.937 409.772 121.937C408.465 121.937 405.419 118.942 406.116 118.34H406.187C406.734 118.133 408.05 119.02 409.004 120.001"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_968"
            d="M388.121 141.755C389.004 143.115 388.801 144.225 387.768 143.958C386.002 143.502 384.236 140.456 385.366 139.931H385.49C386.549 139.793 387.671 141.093 388.139 141.798"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_969"
            d="M417.649 90.1222C418.258 90.4837 419.821 91.4905 419.733 91.7573C419.212 91.9036 415.044 89.4338 415.088 89.0035C415.203 88.7712 416.722 89.5629 417.649 90.1222Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_970"
            d="M417.613 115.637C418.726 116.644 419.265 117.574 418.752 117.625C417.719 117.737 414.337 114.76 414.797 114.183L414.858 114.14C415.247 113.942 416.43 114.588 417.613 115.655"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_971"
            d="M402.628 138.098C403.511 139.355 403.511 140.404 402.672 140.258C401.012 139.983 398.752 136.868 399.794 136.282H399.891C400.633 136.085 401.86 137.066 402.628 138.133"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_972"
            d="M526.013 388.086C525.731 388.844 525.174 390.264 524.883 390.806L524.83 390.875C525.313 389.188 525.927 387.54 526.667 385.944L526.729 385.866C526.563 386.627 526.323 387.37 526.013 388.086Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_973"
            d="M517.299 407.028C516.897 407.983 516.403 408.899 515.824 409.764C516.365 408.121 517.1 406.544 518.014 405.066L518.102 404.979C517.936 405.7 517.675 406.397 517.325 407.053"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_974"
            d="M445.26 103.719C446.143 104.399 447.371 105.44 447.274 105.603C446.823 105.603 443.362 103.022 443.07 102.437C443.07 102.437 443.07 102.393 443.07 102.385C443.273 102.29 444.536 103.185 445.26 103.719Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_975"
            d="M412.995 98.8566C414.257 99.7172 415.008 100.578 414.593 100.578C413.931 100.647 409.842 98.1079 410.178 97.5142C410.425 97.3335 411.661 97.9186 412.995 98.8394"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_976"
            d="M431.672 101.645C432.431 102.187 433.553 103.108 433.535 103.418C433.12 103.762 429.058 100.957 429.12 100.354C429.367 100.105 430.886 101.086 431.689 101.645"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_977"
            d="M464.193 202.476C464.679 203.68 464.661 205.574 463.354 204.989C461.721 204.24 460.281 200.978 461.65 200.29C462.63 200.04 463.752 201.391 464.184 202.476"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_978"
            d="M458.948 204.368C459.69 206.089 459.222 207.458 458.065 206.95C456.432 206.219 455.01 202.88 456.458 202.217C457.341 201.916 458.418 203.078 458.957 204.386"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_979"
            d="M397.815 175.91C398.495 177.631 397.621 178.595 396.446 178.087C394.415 177.226 393.638 174.171 394.927 173.664L395.078 173.595C396.464 173.423 397.462 175.015 397.806 175.884"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_980"
            d="M415.98 171.779C416.757 173.415 416.13 174.361 415.097 174.008C413.331 173.44 411.812 170.179 413.075 169.594H413.207C414.17 169.37 415.371 170.558 415.98 171.823"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_981"
            d="M473.572 198.457C474.234 199.911 474.155 201.374 473.113 201.038C471.647 200.522 469.916 197.037 471.126 196.305C471.885 195.987 472.963 197.166 473.572 198.491"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_982"
            d="M101.338 151.807C100.208 153.063 99.1309 153.579 99.025 152.908C98.8307 151.635 101.727 148.485 102.557 149.036C103.087 149.535 101.965 151.067 101.303 151.807"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_983"
            d="M394.576 125.146C395.37 126.136 395.662 127.289 394.858 127.16C393.278 126.919 390.814 124.054 391.679 123.46L391.794 123.408C392.58 123.262 393.913 124.329 394.576 125.129"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_984"
            d="M111.961 157.779C110.733 159.285 109.409 159.801 109.312 158.812C109.206 157.34 111.784 154.552 112.844 155.034C113.55 155.498 112.623 156.979 111.961 157.779Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_985"
            d="M390.345 126.265C390.83 126.867 391.572 128.081 390.874 128.365C389.426 128.58 386.963 125.551 387.457 124.75C387.514 124.668 387.598 124.608 387.696 124.578C388.579 124.415 389.771 125.568 390.345 126.299"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_986"
            d="M67.5192 190.178C66.5567 191.701 65.709 192.054 65.55 191.426C65.2145 190.118 67.4221 186.598 68.3227 186.994L68.3934 187.037C68.7996 187.407 68.3934 188.758 67.5103 190.178"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_987"
            d="M157.323 172.623C156.219 174.542 154.595 174.955 154.224 173.845C153.773 172.468 155.601 169.671 157.2 169.903C158.038 170.11 158.083 171.272 157.323 172.623Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_988"
            d="M502.111 173.294C502.649 174.154 503.55 175.97 503.329 176.177C502.746 176.177 500.327 172.614 500.495 171.693C500.645 171.358 501.431 172.244 502.111 173.294Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_989"
            d="M501.369 175.91C502.022 176.977 502.694 178.587 502.252 178.535C501.572 178.466 499.206 174.964 499.488 174.06C499.762 173.716 500.751 174.921 501.351 175.91"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_990"
            d="M500.089 178.303C500.972 179.722 501.325 181.022 500.839 180.927C500.089 180.772 497.775 177.244 498.19 176.392C498.473 176.013 499.4 177.098 500.133 178.303"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_991"
            d="M210.764 98.3149C209.466 99.5196 208.362 99.8294 208.397 99.2356C208.45 98.3751 210.808 96.2065 211.823 96.3356C212.592 96.6024 210.834 98.246 210.764 98.3149Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_992"
            d="M496.547 182.992C497.13 184.033 497.713 185.849 496.971 185.574C495.902 185.178 493.88 181.856 494.454 181.03C494.922 180.712 495.973 181.959 496.547 182.992Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_993"
            d="M494.162 185.204C494.648 186.021 495.231 187.407 494.869 187.854C493.862 188.431 491.699 184.309 491.999 183.44C492.023 183.386 492.056 183.337 492.096 183.293C492.785 182.872 494.03 184.963 494.171 185.204"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_994"
            d="M354.892 181.168C355.281 182.941 354.098 184.008 352.667 183.165C350.901 182.141 350.574 179.249 352.146 178.81C352.224 178.79 352.304 178.776 352.384 178.767C353.93 178.69 354.68 180.239 354.892 181.168Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_995"
            d="M488.574 189.636C489.042 190.497 489.519 191.908 489.103 192.381C488.22 192.768 485.748 189.412 486.242 187.975C486.269 187.893 486.318 187.818 486.384 187.76C486.984 187.467 488.044 188.621 488.583 189.627"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_996"
            d="M377.003 178.217C377.56 179.99 376.553 181.126 375.114 180.351C373.18 179.327 372.791 176.513 374.134 176.048C374.214 176.018 374.296 175.995 374.381 175.98C375.838 175.859 376.712 177.348 377.03 178.251"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_997"
            d="M485.341 192.028C486.083 193.474 486.224 194.954 485.27 194.609C483.972 194.136 482.118 190.711 483.045 189.945C483.637 189.61 484.679 190.746 485.341 192.028Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_998"
            d="M221.493 100.346C221.281 100.587 220.186 101.774 219.365 101.714C218.482 101.447 220.063 99.3129 221.431 98.6072C221.643 98.473 221.894 98.4096 222.147 98.4265C222.641 98.5298 222.358 99.3731 221.502 100.346"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_999"
            d="M477.802 196.228C478.226 197.088 478.561 198.508 477.978 198.921C476.68 199.291 474.446 195.608 475.241 194.395C475.292 194.331 475.359 194.281 475.435 194.248C476.212 193.973 477.316 195.238 477.819 196.262"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1000"
            d="M146.541 437.706C146.788 439.427 145.322 439.84 144.015 438.851C142.099 437.405 142.126 434.996 143.635 434.849C143.776 434.831 143.919 434.831 144.059 434.849C144.713 435.031 145.297 435.396 145.738 435.9C146.18 436.404 146.459 437.023 146.541 437.681"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1001"
            d="M520.009 356.832C519.868 357.77 519.417 359.371 518.738 359.904C517.854 360.343 517.899 357.908 518.455 356.247C518.571 355.652 518.881 355.109 519.338 354.698C519.938 354.345 520.221 355.214 519.974 356.824"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1002"
            d="M172.087 87.1787C170.965 87.7983 169.296 88.4782 169.376 88.1167C169.526 87.4455 173.641 85.2339 174.109 85.5781C174.285 85.8449 172.82 86.7743 172.087 87.1787Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1003"
            d="M424.412 266.225C424.412 266.32 424.624 268.437 423.008 268.91C420.854 269.16 419.67 265.829 420.862 264.272C421.111 263.941 421.475 263.711 421.887 263.626C423.723 263.385 424.35 265.416 424.439 266.208"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1004"
            d="M540.54 332.427C540.364 333.701 540.134 335.301 540.055 335.654C540.055 335.336 540.664 330.852 540.77 330.293V330.198C540.77 330.345 540.69 331.059 540.514 332.427"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1005"
            d="M432.554 264.332C432.81 265.743 431.821 268.23 429.985 266.974C428.148 265.717 428.086 262.576 429.985 262.043C431.486 261.871 432.325 263.368 432.51 264.341"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1006"
            d="M122.663 432.904C122.963 434.565 121.78 434.952 120.65 434.16C118.884 432.938 118.275 430.254 119.767 429.987C119.931 429.96 120.098 429.96 120.261 429.987C120.888 430.223 121.44 430.613 121.864 431.12C122.288 431.627 122.57 432.233 122.681 432.878"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1007"
            d="M102.734 384.155C102.805 386.056 101.516 386.495 100.421 385.514C98.7253 384 98.6547 380.816 100.35 380.781C100.463 380.787 100.575 380.801 100.686 380.824C101.798 381.125 102.681 382.545 102.734 384.155Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1008"
            d="M527.286 385.333C526.827 386.59 526.324 387.915 526.191 388.199C526.244 387.898 527.843 383.672 528.01 383.268C528.009 383.242 528.009 383.216 528.01 383.19C528.01 383.337 527.428 384.868 527.251 385.333"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1009"
            d="M524.496 390.496C524.136 391.488 523.705 392.455 523.206 393.388L523.145 393.448C522.959 393.164 524.478 389.266 524.972 388.569L525.043 388.5C524.919 389.174 524.726 389.834 524.469 390.471"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1010"
            d="M126.805 389.446C126.911 391.511 125.393 392.071 124.156 390.995C122.39 389.455 122.39 386.4 124.254 386.331C124.377 386.322 124.501 386.322 124.624 386.331C126.249 386.77 126.752 388.474 126.797 389.412"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1011"
            d="M522.42 393.276C521.969 394.463 521.201 396.107 520.998 396.064C520.786 395.72 522.208 392.011 522.87 391.176C522.932 391.107 522.958 391.099 522.994 391.099C523.197 391.219 522.499 393.069 522.42 393.276Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1012"
            d="M515.224 258.893C515.277 259.125 515.71 261.191 514.933 261.819C513.776 262.378 512.725 258.557 513.29 257.026C513.337 256.857 513.439 256.709 513.582 256.604C514.147 256.26 514.871 257.258 515.233 258.884"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1013"
            d="M447.805 261.061C448.105 262.912 447.037 264.504 445.571 263.574C443.893 262.499 443.469 259.168 445.35 258.566C446.586 258.282 447.567 259.616 447.805 261.061Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1014"
            d="M502.368 266.319C502.765 268.436 501.944 269.684 501.07 269.03C499.71 268.023 499.303 264.624 500.478 263.918C501.529 263.531 502.156 265.321 502.35 266.328"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1015"
            d="M455 259.022C455.203 259.994 455 261.552 453.958 261.956C452.192 262.257 450.691 259.047 451.821 257.318C451.985 257.036 452.254 256.826 452.572 256.732C453.738 256.517 454.753 257.757 455.009 259.022"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1016"
            d="M152.907 394.266C152.854 396.219 151.071 396.847 149.675 395.384C147.909 393.525 148.607 390.97 150.391 391.021H150.594C152.475 391.417 152.916 393.302 152.89 394.248"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1017"
            d="M486.251 273.006C486.701 275.261 485.624 276.646 484.555 275.88C483.169 274.882 482.719 271.449 484.22 270.717C485.235 270.373 485.986 271.647 486.251 272.972"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1018"
            d="M431.742 166.883C432.74 168.501 432.458 169.568 431.548 169.404C429.782 169.086 427.822 165.678 429.005 165.015L429.111 164.964C430.126 164.749 431.239 166.065 431.76 166.883"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1019"
            d="M232.919 102.084C232.743 102.334 231.842 103.573 230.959 103.547C229.979 103.306 231.277 101.086 232.575 100.363C232.763 100.233 232.99 100.17 233.219 100.182C233.908 100.346 233.361 101.456 232.928 102.093"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1020"
            d="M447.742 208.017C448.095 208.878 448.201 210.375 447.327 210.754C445.632 211.081 443.654 207.579 444.749 206.185C444.853 206.054 444.994 205.955 445.155 205.901C446.223 205.685 447.354 206.985 447.751 208.017"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1021"
            d="M445.411 161.892C446.453 163.458 446.294 164.473 445.411 164.284C443.804 163.94 441.694 160.583 442.691 159.981C443.574 159.706 444.793 161.083 445.34 161.935"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1022"
            d="M441.623 209.781C441.968 210.642 442.136 212.131 441.164 212.518C439.319 212.854 437.632 209.179 438.586 207.957C438.707 207.8 438.878 207.685 439.071 207.63C439.954 207.398 441.049 208.301 441.632 209.781"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1023"
            d="M456.466 156.385C457.623 157.925 457.632 158.966 456.863 158.803C455.645 158.605 452.978 155.266 453.914 154.5C454.4 154.25 455.495 155.042 456.466 156.341"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1024"
            d="M428.704 213.112C429.286 214.79 428.554 216.434 427.088 215.737C424.968 214.73 424.439 211.434 425.949 210.978H426.037C427.158 210.806 428.289 211.968 428.686 213.138"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1025"
            d="M421.763 214.833C422.329 216.691 421.348 218.017 419.936 217.277C418.09 216.304 417.437 213.129 418.964 212.561H419.123C420.174 212.346 421.287 213.327 421.772 214.876"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1026"
            d="M465.174 150.688C466.269 152.082 466.455 153.055 465.881 152.986C464.839 152.874 461.978 149.612 462.631 148.838C463.187 148.537 464.459 149.784 465.174 150.697"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1027"
            d="M244.901 103.589C244.15 104.949 243.046 105.31 242.808 104.631C242.499 103.701 243.797 101.541 244.91 101.55C245.749 101.713 245.192 103.073 244.91 103.589"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1028"
            d="M471.222 144.673C472.37 146.032 472.688 146.987 472.211 146.927C471.328 146.824 468.405 143.64 468.803 142.883C469.191 142.556 470.463 143.743 471.222 144.673Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1029"
            d="M257.521 104.476C257.423 104.734 256.929 105.999 256.011 106.017C254.916 105.845 255.49 103.521 256.576 102.747C256.736 102.613 256.939 102.54 257.15 102.54C257.944 102.661 257.759 103.814 257.529 104.476"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1030"
            d="M474.551 138.416C475.672 139.655 476.317 140.731 475.858 140.671C475.169 140.585 472.255 137.547 472.325 136.747C472.555 136.368 473.994 137.822 474.533 138.416"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1031"
            d="M472.784 125.689C473.513 126.366 474.167 127.116 474.736 127.926C473.392 126.822 472.173 125.581 471.098 124.226V124.157C471.722 124.608 472.296 125.121 472.811 125.689"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1032"
            d="M521.599 396.864C521.233 397.845 520.778 398.793 520.24 399.695C520.218 399.723 520.191 399.746 520.16 399.764C520.596 398.071 521.267 396.444 522.156 394.928C522.183 394.894 522.216 394.865 522.253 394.842C522.111 395.528 521.895 396.197 521.608 396.838"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1033"
            d="M407.378 269.167C407.502 270.802 406.01 272.937 404.12 271.37C402.354 269.925 402.689 267.068 404.65 266.62C406.266 266.474 407.299 268.006 407.299 269.202"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1034"
            d="M513.899 404.661C513.21 406.382 512.3 407.69 512.133 407.294C511.903 406.743 513.289 403.31 514.19 402.587C514.287 402.518 514.561 402.337 514.455 402.974C514.332 403.564 514.149 404.141 513.908 404.695"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1035"
            d="M403.026 440.03C402.364 442.069 399.776 443.05 398.946 441.579C398.213 440.279 399.573 438.223 401.41 437.844C403.282 437.646 403.291 439.135 402.999 440.038"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1036"
            d="M381.375 89.0898C381.569 89.2103 383.3 90.3204 382.964 90.6216C381.993 90.8969 378.267 88.1862 378.452 87.8592C378.761 87.7215 380.006 88.2637 381.348 89.1156"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1037"
            d="M345.628 446.303C345.231 448.334 342.511 449.272 341.346 447.775C340.18 446.277 341.619 444.229 343.606 444.074C343.906 444.03 344.213 444.06 344.498 444.162C344.783 444.263 345.038 444.432 345.239 444.653C345.44 444.875 345.581 445.142 345.649 445.43C345.717 445.718 345.71 446.018 345.628 446.303Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1038"
            d="M286.696 317.418C286.493 319.802 283.941 320.732 282.493 318.942C281.044 317.152 282.325 314.398 284.303 314.372C286.387 314.475 286.793 316.334 286.696 317.418Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1039"
            d="M512.662 412.087C511.991 413.499 511.082 414.987 510.949 414.712C510.817 414.437 512.389 410.771 513.175 410.074C513.254 410.005 513.404 409.884 513.378 410.16C513.213 410.826 512.973 411.472 512.662 412.087Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1040"
            d="M255.948 316.877C255.772 319.459 253.167 320.156 251.825 318.598C250.483 317.041 251.251 314.011 253.644 313.951C256.063 314.192 255.966 316.619 255.948 316.894"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1041"
            d="M516.856 348.286C516.609 350.455 515.638 351.728 515.09 351.16C514.34 350.3 514.808 346.711 516.026 345.902C516.689 345.558 517.042 346.582 516.848 348.286"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1042"
            d="M56.3653 281.362C55.8884 283.195 54.8641 283.582 54.3166 282.679C53.2923 280.957 54.2372 277.343 55.6147 277.696H55.7471C56.8068 278.35 56.6302 280.346 56.3653 281.319"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1043"
            d="M513.988 272.472C514.156 273.462 514.12 275.054 513.493 275.536C512.478 275.931 511.233 272.696 511.983 270.785C512.049 270.573 512.182 270.386 512.363 270.252C513.061 269.951 513.767 271.155 513.997 272.463"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1044"
            d="M528.477 340.301C528.274 342.478 527.541 343.949 527.144 343.459C526.552 342.744 526.958 339.001 527.903 338.115C528.557 337.727 528.574 339.302 528.477 340.301Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1045"
            d="M541.087 323.89C541.015 324.955 540.867 326.013 540.645 327.057C540.634 327.087 540.619 327.116 540.601 327.143C540.389 326.928 540.848 322.479 541.096 321.67C541.096 321.601 541.096 321.601 541.158 321.584C541.205 322.353 541.182 323.125 541.087 323.89Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1046"
            d="M141.278 117.996C139.777 119.261 138.717 119.545 138.699 119.011C138.699 118.151 141.816 115.337 142.814 115.724C143.256 116.016 142.328 117.109 141.278 117.996Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1047"
            d="M127.166 215.461C126.283 217.561 124.517 218.043 124.005 216.769C123.334 215.168 124.888 212.165 126.654 212.466C127.758 212.776 127.687 214.23 127.166 215.444"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1048"
            d="M134.02 113.065C132.501 114.252 131.486 114.553 131.592 114.028C131.795 113.039 135.291 110.449 135.848 110.87C136.21 111.335 134.249 112.893 134.02 113.065Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1049"
            d="M129.04 108.193C127.609 109.209 126.832 109.433 126.912 109.054C127.062 108.4 130.7 105.483 131.265 105.956C131.442 106.472 129.066 108.168 129.04 108.185"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1050"
            d="M126.523 103.125C125.314 103.934 124.545 104.278 124.598 103.986C124.722 103.314 128.616 100.604 129.013 100.931C129.013 101.335 127.389 102.531 126.532 103.108"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1051"
            d="M126.382 98.0484C125.905 98.3495 124.792 99.0552 124.616 98.9691C124.501 98.5647 128.554 95.7593 129.031 95.94C129.031 95.94 129.031 95.94 129.031 95.9916C128.265 96.7915 127.388 97.4837 126.426 98.0484"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1052"
            d="M91.8896 204.059C90.883 205.84 89.6114 206.193 89.2405 205.238C88.6577 203.594 90.7594 200.367 92.0486 200.935C93.0111 201.46 92.3488 203.224 91.8631 204.076"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1053"
            d="M509.607 274.942C509.969 276.93 509.369 278.53 508.477 277.954C507.285 277.171 506.711 273.548 507.947 272.73C508.83 272.369 509.413 273.961 509.589 274.959"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1054"
            d="M128.404 93.0651C127.904 93.4209 127.358 93.7103 126.779 93.9256C126.903 93.4351 130.894 90.9912 131.124 91.0428V91.0944C130.276 91.8136 129.369 92.4641 128.413 93.0393"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1055"
            d="M78.1493 197.175C78.0257 197.39 76.9308 199.231 76.0036 198.844C74.8468 197.984 77.0456 194.18 78.2995 194.085C78.4072 194.078 78.5147 194.102 78.6085 194.154C79.3326 194.688 78.6703 196.314 78.167 197.183"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1056"
            d="M424.474 85.2604C425.428 85.8456 426.593 86.6114 426.832 86.818C425.357 86.0435 423.933 85.1813 422.567 84.2363H422.514C423.196 84.5493 423.851 84.9147 424.474 85.3292"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1057"
            d="M478.313 202.372C479.196 204.214 478.719 205.341 477.792 204.954C476.326 204.308 474.763 200.935 475.928 200.204C476.573 199.911 477.624 200.875 478.313 202.389"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1058"
            d="M408.245 308.305C408.245 308.71 408.245 310.749 406.32 311.197C403.989 311.403 403.026 308.245 404.377 306.558C404.717 306.125 405.212 305.833 405.764 305.741C407.124 305.551 408.174 306.601 408.263 308.322"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1059"
            d="M378.875 312.091C378.981 314.492 376.65 315.878 375.034 314.363C373.418 312.849 374.151 309.871 376.306 309.51C378.699 309.381 378.867 311.816 378.875 312.091Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1060"
            d="M463.955 208.344C464.785 210.229 464.246 211.442 463.222 211.115C461.562 210.582 460.22 207.045 461.403 206.322L461.535 206.262C462.851 205.943 463.84 208.095 463.946 208.344"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1061"
            d="M165.818 379.628C165.818 380.006 165.615 381.865 163.707 381.71H163.663C161.208 381.168 160.749 377.597 162.497 376.616C162.821 376.436 163.198 376.369 163.566 376.426C165.438 376.779 165.862 378.612 165.818 379.636"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1062"
            d="M446.869 214.041C447.61 216.046 446.745 217.225 445.526 216.674C443.849 215.925 442.692 212.466 444.317 211.838C445.2 211.605 446.312 212.552 446.869 214.041Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1063"
            d="M221.501 138.864C220.68 140.585 219.179 141.024 218.773 140.051C218.208 138.657 219.974 136.316 221.148 136.454C222.323 136.592 221.881 138.063 221.501 138.864Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1064"
            d="M318.255 386.504C318.223 386.99 318.042 387.456 317.735 387.841C317.429 388.226 317.011 388.513 316.535 388.664C316.06 388.815 315.548 388.824 315.068 388.689C314.587 388.554 314.159 388.281 313.839 387.906C313.548 387.511 313.368 387.047 313.317 386.563C313.267 386.078 313.347 385.589 313.551 385.144C313.755 384.7 314.074 384.315 314.478 384.028C314.882 383.741 315.356 383.562 315.853 383.509C317.919 383.509 318.405 385.316 318.237 386.504"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1065"
            d="M427.203 219.428C427.732 221.451 426.505 222.423 425.207 221.674C423.37 220.625 422.867 217.329 424.483 216.916C425.905 216.692 426.858 218.301 427.132 219.437"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1066"
            d="M349.673 384.679C349.346 387.003 346.767 388.001 345.355 386.357C345.053 385.96 344.864 385.493 344.805 385.003C344.746 384.513 344.82 384.016 345.019 383.562C345.218 383.107 345.535 382.712 345.94 382.414C346.344 382.117 346.821 381.928 347.324 381.865C349.487 381.865 349.805 383.69 349.673 384.679Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1067"
            d="M519.1 404.403C518.684 405.354 518.218 406.285 517.705 407.191C518.304 405.579 519.021 404.012 519.851 402.501L519.921 402.406C519.707 403.104 519.432 403.784 519.1 404.437"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1068"
            d="M379.758 226.158C379.802 226.39 380.155 228.438 378.319 228.808C375.996 228.963 374.787 225.693 376.146 224.239C376.397 223.953 376.751 223.771 377.135 223.731C378.822 223.611 379.599 225.194 379.785 226.158"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1069"
            d="M380.641 381.65C380.447 384.085 377.718 385.367 376.341 383.672C376.057 383.266 375.882 382.797 375.833 382.308C375.783 381.82 375.861 381.326 376.057 380.874C376.254 380.422 376.565 380.025 376.96 379.72C377.355 379.415 377.822 379.212 378.319 379.128C380.085 378.973 380.747 380.325 380.641 381.65Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1070"
            d="M326.714 231.131C326.714 231.355 326.714 233.351 324.851 233.549C322.502 233.549 321.733 230.271 323.235 228.902C323.534 228.615 323.928 228.441 324.347 228.412C325.654 228.36 326.696 229.548 326.74 231.131"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1071"
            d="M162.117 126.876C160.696 128.365 159.38 128.709 159.389 127.892C159.389 126.489 162.153 124.398 163.089 124.544C163.76 124.871 162.744 126.205 162.117 126.876Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1072"
            d="M348.516 315.069C348.419 317.453 345.92 318.555 344.401 316.876C342.883 315.198 344.004 312.35 345.964 312.195C348.066 312.152 348.552 313.976 348.516 315.069Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1073"
            d="M299.641 232.181C299.544 234.315 297.522 235.27 296.153 233.842C294.784 232.413 295.27 229.539 297.442 229.358C298.749 229.358 299.712 230.589 299.641 232.181Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1074"
            d="M317.68 316.704C317.684 317.344 317.444 317.962 317.007 318.439C316.57 318.916 315.966 319.218 315.314 319.286C312.753 319.2 312.064 315.999 313.725 314.45C314.131 314.056 314.671 313.821 315.243 313.787C316.718 313.787 317.769 315 317.707 316.696"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1075"
            d="M515.258 409.385C514.836 410.359 514.315 411.29 513.704 412.165C513.643 412.225 513.634 412.234 513.598 412.225C513.422 411.821 515.179 408.206 515.779 407.552C515.841 407.492 515.859 407.483 515.894 407.483C516.018 407.621 515.629 408.585 515.267 409.377"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1076"
            d="M460.238 147.322C461.297 148.613 461.668 149.749 460.997 149.655C459.911 149.508 457.015 146.281 457.721 145.567C458.171 145.266 459.355 146.247 460.238 147.288"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1077"
            d="M462.692 145.412C463.478 146.376 464.158 147.779 463.522 147.701C462.453 147.572 459.531 144.44 460.158 143.674C460.661 143.398 461.924 144.534 462.692 145.395"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1078"
            d="M426.135 109.588C427.354 110.551 428.025 111.429 427.619 111.507C427.354 111.55 426.33 111.111 424.97 110.027C424.97 110.027 423.159 108.581 423.442 108.176C423.804 107.91 425.261 108.873 426.144 109.588"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1079"
            d="M466.603 141.428C467.698 142.658 468.228 143.768 467.707 143.751C466.824 143.751 463.76 140.55 464.228 139.767C464.67 139.474 465.923 140.67 466.603 141.428Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1080"
            d="M468.157 139.466C468.978 140.396 469.817 141.781 469.288 141.73C468.404 141.635 465.429 138.597 465.755 137.806C466.091 137.522 467.354 138.589 468.14 139.475"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1081"
            d="M470.348 135.448C471.231 136.446 472.053 137.607 471.691 137.651C471.17 137.719 467.894 134.587 468.052 133.778C468.247 133.434 469.509 134.544 470.348 135.448Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1082"
            d="M470.913 133.287C471.575 133.984 472.511 135.146 472.493 135.533C472.308 135.766 471.248 134.862 470.577 134.2C469.84 133.527 469.24 132.724 468.811 131.833C468.803 131.808 468.803 131.781 468.811 131.756C468.979 131.463 470.162 132.453 470.957 133.279"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1083"
            d="M482.605 286.646C482.782 288.221 481.926 290.467 480.539 289.339C479.153 288.212 479.02 284.994 480.539 284.271C481.696 283.987 482.491 285.467 482.614 286.655"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1084"
            d="M476.089 288.909C476.283 290.794 475.206 292.489 473.987 291.568C472.53 290.51 472.3 287.093 473.987 286.405C475.144 286.069 475.921 287.437 476.062 288.909"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1085"
            d="M422.285 112.591C423.036 113.219 423.972 114.252 423.742 114.57C423.336 114.742 422.055 113.951 421.287 113.348C420.228 112.488 419.442 111.584 419.521 111.266C419.522 111.249 419.528 111.234 419.537 111.22C419.547 111.207 419.559 111.196 419.574 111.188C420.024 110.99 421.437 111.877 422.276 112.591"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1086"
            d="M468.229 120.801C468.989 121.503 469.695 122.26 470.34 123.064C468.997 121.989 467.761 120.794 466.649 119.493C466.641 119.47 466.641 119.446 466.649 119.424C467.236 119.826 467.777 120.287 468.265 120.801"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1087"
            d="M430.321 300.259C430.391 302.419 428.555 304.02 427.054 302.488C425.552 300.956 425.915 298.031 427.937 297.635C429.818 297.394 430.294 299.45 430.321 300.259Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1088"
            d="M420.015 114.089C421.269 115.164 421.852 116.137 421.243 116.128C420.272 116.128 416.695 113.288 417.331 112.686C417.87 112.462 419.274 113.478 419.98 114.114"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1089"
            d="M412.58 303.22C412.677 304.941 410.947 307.23 409.048 305.647C407.282 304.175 407.883 301.198 409.931 300.742C411.22 300.535 412.421 301.697 412.51 303.22"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1090"
            d="M415.027 117.109C415.91 117.969 416.546 119.045 416.007 119.079C414.859 119.148 411.592 116.214 412.139 115.594C412.157 115.591 412.175 115.591 412.192 115.594C412.775 115.405 414.206 116.377 415.018 117.16"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1091"
            d="M412.078 118.52C413.164 119.57 413.605 120.491 413.023 120.525C411.83 120.594 408.607 117.582 409.234 116.98H409.305C409.808 116.756 411.071 117.634 412.078 118.563"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1092"
            d="M93.8073 145.559C92.7212 146.721 91.7763 147.28 91.7145 146.729C91.5909 145.68 94.6992 142.349 95.335 142.857C95.6617 143.356 94.5226 144.785 93.7985 145.551"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1093"
            d="M405.746 121.291C406.558 122.152 407.026 123.27 406.417 123.288C405.119 123.288 402.099 120.284 402.832 119.673C402.855 119.67 402.879 119.67 402.902 119.673C403.582 119.501 404.978 120.534 405.728 121.334"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1094"
            d="M402.249 122.694C402.752 123.262 403.458 124.303 402.946 124.596C402.107 124.777 400.597 123.34 400.58 123.331C399.37 122.152 399.052 121.309 399.264 121.025C399.3 120.981 399.345 120.946 399.397 120.921C400.28 120.706 401.754 122.126 402.249 122.694Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1095"
            d="M431.098 93.3578C431.914 93.8411 432.667 94.419 433.341 95.0789C433.05 95.2596 429.12 92.7296 428.926 92.2305C429.723 92.4609 430.467 92.841 431.116 93.3492"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1096"
            d="M431.838 95.1046C432.403 95.4832 433.869 96.5245 433.878 96.8257C433.542 97.015 429.604 94.4334 429.463 93.9171C429.463 93.6503 430.867 94.4678 431.865 95.1304"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1097"
            d="M499.894 279.701C500.15 281.336 499.549 283.401 498.445 282.713C496.997 281.852 496.679 278.109 498.039 277.498C498.922 277.197 499.717 278.582 499.894 279.701Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1098"
            d="M432.166 98.2886C432.957 98.7692 433.659 99.3774 434.241 100.087C433.729 100.251 429.826 97.5915 429.826 97.0924C429.923 96.8859 431.16 97.5399 432.21 98.2541"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1099"
            d="M379.104 173.983C379.386 174.843 379.333 176.117 378.318 176.375C376.446 176.478 374.671 173.475 375.81 172.15C375.968 171.967 376.187 171.845 376.428 171.806C377.673 171.702 378.786 173.002 379.077 173.983"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1100"
            d="M431.962 99.9328C432.704 100.44 433.967 101.396 433.993 101.749C433.605 102.213 429.658 99.1669 429.578 98.7796V98.728C429.737 98.4698 431.221 99.3992 431.989 99.9242"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1101"
            d="M392.314 171.676C392.959 173.225 392.314 174.404 391.148 173.965C389.259 173.277 388.067 170.256 389.382 169.594C389.457 169.556 389.538 169.53 389.621 169.516C390.857 169.361 391.89 170.721 392.27 171.676"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1102"
            d="M398.673 170.428C399.203 171.702 398.867 173.251 397.472 172.657C395.388 171.796 394.505 168.853 395.768 168.354H395.9C397.057 168.208 398.24 169.499 398.646 170.488"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1103"
            d="M410.469 167.709C411.229 169.224 410.84 170.386 409.701 170.024C407.873 169.439 406.346 166.212 407.635 165.653H407.758C408.721 165.437 409.869 166.565 410.469 167.744"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1104"
            d="M416.034 166.16C416.696 167.356 416.634 168.983 415.407 168.578C413.535 167.976 411.945 164.869 413.19 164.276L413.323 164.233C414.1 164.035 415.336 164.93 416.034 166.177"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1105"
            d="M431.116 103.288C432.131 104.046 433.112 105.009 432.776 105.087C432.202 105.156 428.317 102.626 428.44 101.946C428.714 101.688 430.312 102.694 431.089 103.288"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1106"
            d="M426.4 163.209C427.283 164.663 427.071 165.859 426.02 165.541C424.254 165.016 422.488 161.849 423.601 161.238L423.707 161.195C424.431 160.98 425.597 161.84 426.4 163.2"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1107"
            d="M431.282 161.591C431.971 162.736 432.165 164.242 431.026 163.949C429.322 163.484 427.423 160.386 428.421 159.698L428.536 159.638C429.419 159.431 430.717 160.636 431.282 161.591Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1108"
            d="M430.223 104.906C431.327 105.767 432.245 106.688 431.804 106.714C431.186 106.757 427.23 104.08 427.53 103.512C427.804 103.254 429.296 104.227 430.179 104.906"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1109"
            d="M440.114 158.338C440.908 159.552 441.085 160.817 440.114 160.627C438.586 160.326 436.387 157.039 437.288 156.393L437.376 156.342C438.1 156.075 439.337 157.151 440.105 158.338"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1110"
            d="M444.14 156.513C444.908 157.641 445.173 158.992 444.378 158.897C442.868 158.725 440.511 155.455 441.349 154.715L441.438 154.646C442.215 154.414 443.504 155.584 444.14 156.513Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1111"
            d="M451.291 152.839C452.378 154.242 452.607 155.421 451.759 155.3C450.453 155.068 447.83 151.815 448.766 151.118C449.481 150.834 450.691 152.065 451.291 152.839Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1112"
            d="M454.639 151.092C455.46 152.176 455.892 153.51 455.221 153.449C454.275 153.104 453.457 152.489 452.872 151.685C451.857 150.369 451.707 149.551 451.989 149.319C452.608 149.069 453.897 150.179 454.639 151.109"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1113"
            d="M427.752 108.031C428.749 108.822 429.65 109.838 429.27 109.915C428.485 110.087 424.687 107.153 425.041 106.654C425.394 106.439 426.869 107.333 427.752 108.039"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1114"
            d="M316.357 282.119C316.357 282.36 316.198 284.451 314.131 284.623C311.659 284.554 310.935 281.413 312.498 279.873C312.878 279.488 313.391 279.255 313.937 279.219C314.296 279.243 314.646 279.338 314.966 279.499C315.286 279.659 315.569 279.881 315.798 280.152C316.027 280.423 316.197 280.736 316.297 281.072C316.398 281.409 316.427 281.762 316.383 282.11"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1115"
            d="M430.824 201.253C431.495 202.974 430.824 204.265 429.509 203.732C427.637 202.966 426.63 199.575 428.122 199.025H428.193C429.5 198.792 430.462 200.333 430.842 201.279"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1116"
            d="M346.025 280.544C345.981 282.773 343.809 283.849 342.264 282.412C340.718 280.974 341.381 277.842 343.482 277.687C345.584 277.532 346.043 279.546 346.025 280.544Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1117"
            d="M410.663 205.805C411.352 207.922 410.116 208.981 408.721 208.198C406.955 207.225 406.186 204.041 407.997 203.542C409.03 203.344 410.169 204.317 410.646 205.805"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1118"
            d="M375.255 277.834C375.361 280.071 373.286 281.276 371.723 279.916C369.798 278.281 370.716 275.321 372.694 275.106C374.672 274.891 375.246 276.767 375.299 277.834"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1119"
            d="M360.968 119.114C361.851 120.387 361.621 121.179 360.853 121.033C359.37 120.74 357.4 118.004 358.257 117.41C358.318 117.375 358.383 117.349 358.451 117.332C359.219 117.203 360.297 118.124 360.968 119.114Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1120"
            d="M403.52 274.15C403.706 276.387 401.754 277.592 400.209 276.301C398.664 275.011 398.743 271.93 400.915 271.474C402.266 271.293 403.388 272.446 403.52 274.15Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1121"
            d="M387.705 208.525C387.802 208.895 388.208 210.745 386.575 211.107C384.393 211.287 383.042 208.112 384.208 206.675C384.312 206.537 384.444 206.421 384.596 206.335C384.748 206.249 384.916 206.195 385.091 206.176C386.636 206.029 387.475 207.578 387.74 208.516"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1122"
            d="M429.64 270.889C429.94 273.273 428.165 274.331 426.77 273.281C425.189 272.051 425.136 268.892 427.132 268.35C428.306 268.126 429.428 269.271 429.64 270.889Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1123"
            d="M363.148 211.933C363.413 213.887 361.814 214.979 360.295 213.869C358.618 212.647 358.476 209.67 360.534 209.317C362.185 209.257 363.006 210.849 363.183 211.899"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1124"
            d="M452.714 265.494C453.173 267.731 451.831 269.013 450.506 268.204C448.74 267.103 448.661 263.592 450.365 263.136C451.787 262.869 452.511 264.495 452.714 265.494Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1125"
            d="M337.682 214.204C337.823 216.183 336.145 217.242 334.679 216.097C333.063 214.832 333.116 211.864 335.209 211.554C336.975 211.554 337.611 213.275 337.682 214.204Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1126"
            d="M472.97 259.564C473.482 261.785 472.352 263.084 471.204 262.327C469.729 261.372 469.173 257.929 470.754 257.258C471.637 256.991 472.626 257.981 472.997 259.564"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1127"
            d="M311.845 215.616C311.845 217.664 309.937 218.662 308.586 217.337C306.953 215.711 307.862 213.034 309.602 212.931C311.341 212.828 311.845 214.652 311.845 215.65"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1128"
            d="M490.235 253.058C490.782 255.158 490.005 256.5 488.998 255.95C487.612 255.227 486.764 251.569 488.115 250.786C488.884 250.485 489.776 251.449 490.191 253.024"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1129"
            d="M164.201 206.572C163.318 208.749 161.385 209.153 160.819 207.742C160.184 206.167 161.702 203.439 163.468 203.637C164.475 203.826 164.775 205.091 164.184 206.572"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1130"
            d="M504.416 246.105C504.522 246.484 505.025 248.412 504.186 249.023C502.897 249.556 501.581 245.761 502.191 244.298C502.252 244.128 502.37 243.983 502.526 243.885C503.153 243.558 503.974 244.513 504.425 246.097"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1131"
            d="M143.354 202.484C142.409 204.369 140.652 204.936 140.175 203.517C139.601 201.795 141.42 199.154 142.956 199.446C144.272 199.851 143.839 201.606 143.354 202.484Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1132"
            d="M515.418 238.799C515.974 240.632 515.762 242.087 515.012 241.639C513.89 240.968 512.919 237.233 513.705 236.579C514.403 236.157 515.127 237.844 515.418 238.799Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1133"
            d="M394.019 192.528C394.655 194.429 393.524 195.471 392.253 194.86C390.354 193.999 389.657 190.806 391.237 190.282L391.405 190.238C392.421 190.075 393.569 191.056 394.054 192.528"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1134"
            d="M409.746 101.637C410.983 102.54 411.671 103.358 411.203 103.418C410.453 103.478 406.417 100.836 406.93 100.268C407.274 100.062 408.607 100.802 409.746 101.637Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1135"
            d="M414.364 188.448C415.15 190.324 414.223 191.391 412.96 190.823C411.106 189.963 410.152 186.805 411.592 186.28H411.697C412.996 186.082 413.985 187.57 414.347 188.483"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1136"
            d="M405.745 104.364C406.725 105.147 407.511 106.085 407.007 106.154C405.983 106.206 402.469 103.572 402.831 102.97C403.316 102.798 404.818 103.684 405.701 104.407"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1137"
            d="M403.441 105.724C404.377 106.498 405.101 107.445 404.633 107.496C403.75 107.677 400.05 104.803 400.492 104.261L400.544 104.218C400.986 104.02 402.364 104.829 403.441 105.724Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1138"
            d="M432.378 183.775C432.476 183.982 433.341 185.832 432.184 186.279C430.418 186.615 428.493 183.104 429.358 181.908C429.448 181.78 429.583 181.688 429.738 181.65C430.904 181.409 431.981 182.923 432.387 183.767"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1139"
            d="M64.8963 247.938C64.2164 249.84 63.0419 250.253 62.5563 249.263C61.7527 247.611 63.2274 244.1 64.5608 244.556H64.6402C65.5851 245.133 65.2495 246.931 64.8963 247.904"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1140"
            d="M447.928 178.552C448.051 178.758 449.067 180.591 448.078 181.133C446.488 181.529 444.378 177.777 445.111 176.779C445.175 176.691 445.264 176.622 445.367 176.581C446.347 176.323 447.451 177.717 447.945 178.578"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1141"
            d="M475.868 184.24C476.901 186.03 476.698 187.149 475.868 186.882C474.561 186.478 472.547 183.027 473.528 182.296C474.066 182.012 475.091 182.846 475.894 184.24"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1142"
            d="M398.115 108.194C399.175 109.106 399.661 110.044 399.263 110.104C398.195 110.268 394.698 107.351 395.29 106.791H395.343C395.996 106.653 397.365 107.574 398.115 108.228"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1143"
            d="M461.041 173.019C462.039 174.637 461.863 175.678 461.041 175.506C459.487 175.179 457.509 171.72 458.392 171.057H458.445C459.328 170.756 460.441 172.167 460.988 173.045"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1144"
            d="M81.6028 255.089C80.8787 257.146 79.4747 257.481 78.9537 256.242C78.1148 254.383 79.6425 251.165 81.0995 251.707C82.2739 252.267 81.9825 254.1 81.6381 255.089"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1145"
            d="M463.549 190.411C464.432 192.209 464.043 193.311 463.054 192.949C461.394 192.338 459.858 188.827 461.032 188.302C462.012 188.001 463.072 189.481 463.549 190.411Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1146"
            d="M471.505 167.055C471.638 167.244 472.768 168.974 472.176 169.49C471.629 169.74 470.657 168.811 469.995 167.941C469.227 166.943 468.75 165.721 468.953 165.274C468.981 165.221 469.02 165.174 469.068 165.136C469.536 164.861 470.613 165.704 471.514 167.055"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1147"
            d="M395.343 109.562C396.226 110.362 396.782 111.283 396.226 111.352C395.167 111.498 391.811 108.555 392.376 107.996L392.447 107.953C393.189 107.746 394.778 109.046 395.334 109.562"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1148"
            d="M286.537 282.73C286.539 283.348 286.302 283.943 285.873 284.398C285.444 284.852 284.855 285.133 284.223 285.183C281.716 284.985 281.204 281.663 282.943 280.269C283.315 279.952 283.792 279.778 284.285 279.778C286.705 279.899 286.599 282.437 286.581 282.73"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1149"
            d="M479.311 160.868C480.38 162.348 480.539 163.312 480.018 163.261C479.046 163.157 476.353 159.775 476.954 158.958C477.483 158.605 478.72 160.051 479.311 160.868Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1150"
            d="M448.494 196.107C449.315 197.931 448.688 198.999 447.611 198.594C445.845 197.923 444.52 194.515 445.845 193.921H445.898C447.063 193.646 448.088 195.178 448.547 196.124"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1151"
            d="M484.265 154.311C485.431 155.808 485.775 156.849 485.29 156.746C484.407 156.54 481.81 153.132 482.128 152.547C482.446 152.185 483.665 153.536 484.265 154.311Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1152"
            d="M431.681 195.324C432.184 196.503 432.016 198.414 430.621 197.906C428.731 197.243 427.486 193.896 428.855 193.259L428.987 193.207C429.87 192.992 431.071 193.973 431.636 195.307"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1153"
            d="M425.402 196.925C425.764 197.785 425.941 199.231 424.952 199.584C423.071 199.876 421.323 196.279 422.303 195.109C422.428 194.957 422.602 194.851 422.797 194.808C423.733 194.593 424.811 195.47 425.447 196.925"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1154"
            d="M412.28 200.031C412.563 200.892 412.51 202.303 411.486 202.613C409.411 202.811 407.883 199.386 408.978 198.129C409.128 197.961 409.329 197.843 409.552 197.794C410.815 197.63 411.945 198.99 412.289 200.031"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1155"
            d="M405.216 201.503C405.728 203.224 404.801 204.67 403.256 203.766C401.34 202.639 400.827 199.601 402.479 199.188H402.558C403.936 199.016 404.916 200.531 405.207 201.52"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1156"
            d="M390.443 203.99C390.69 204.893 390.522 206.305 389.365 206.572C387.432 206.718 385.754 203.68 386.937 202.183C387.133 201.925 387.425 201.752 387.749 201.701C389.092 201.58 390.195 202.94 390.469 203.955"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1157"
            d="M219.399 112.307C219.214 112.566 218.242 113.856 217.28 113.788C216.22 113.486 217.545 111.206 218.975 110.406C219.196 110.26 219.46 110.19 219.726 110.208C220.326 110.311 220.194 111.197 219.408 112.307"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1158"
            d="M529.67 377.391C529.366 378.443 528.976 379.471 528.505 380.463C528.337 380.291 529.679 376.238 530.112 375.42L530.174 375.334C530.05 376.023 529.873 376.702 529.644 377.365"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1159"
            d="M232.839 114.08C231.956 115.603 230.685 116.068 230.429 115.242C230.111 114.201 231.577 111.955 232.804 111.998C233.431 112.075 233.449 112.97 232.804 114.08"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1160"
            d="M362.106 430.736C361.983 431.338 361.667 431.887 361.203 432.303C360.74 432.719 360.153 432.981 359.527 433.051C357.311 433.12 356.816 430.856 358.185 429.385C358.677 428.843 359.355 428.494 360.092 428.404C360.403 428.358 360.721 428.391 361.015 428.498C361.31 428.606 361.571 428.785 361.775 429.019C361.978 429.252 362.117 429.533 362.178 429.833C362.239 430.134 362.22 430.444 362.123 430.736"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1161"
            d="M246.889 115.509C246.227 117.058 244.982 117.488 244.637 116.61C244.178 115.448 245.414 113.34 246.554 113.349C247.225 113.409 247.375 114.364 246.889 115.509Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1162"
            d="M331.995 432.938C331.892 433.529 331.588 434.07 331.131 434.473C330.675 434.875 330.093 435.117 329.479 435.158C327.121 435.158 326.582 432.689 328.11 431.269C328.589 430.823 329.215 430.558 329.876 430.52C330.196 430.491 330.518 430.537 330.817 430.654C331.115 430.77 331.381 430.954 331.592 431.19C331.803 431.426 331.954 431.708 332.032 432.012C332.109 432.316 332.112 432.633 332.039 432.938"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1163"
            d="M156.52 425.323C156.635 427.122 154.983 427.698 153.518 426.364C151.752 424.755 152.114 422.457 153.703 422.354C153.832 422.346 153.962 422.346 154.092 422.354C154.772 422.52 155.378 422.898 155.816 423.431C156.255 423.964 156.502 424.623 156.52 425.306"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1164"
            d="M131.176 420.891C131.326 422.612 129.922 423.128 128.527 421.915C126.849 420.443 126.761 417.862 128.359 417.793C128.489 417.78 128.619 417.78 128.748 417.793C129.416 418.011 130.002 418.42 130.43 418.966C130.858 419.512 131.109 420.17 131.15 420.856"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1165"
            d="M391.944 100.104C392.13 100.242 393.772 101.507 393.339 101.825C392.315 102.127 388.924 99.2953 389.092 98.7704C389.106 98.7374 389.131 98.7101 389.163 98.6929C389.719 98.5208 391.238 99.5535 391.962 100.07"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1166"
            d="M530.438 373.604C530.173 374.635 529.797 375.636 529.316 376.59C529.573 374.843 530.072 373.138 530.8 371.522C530.822 371.489 530.848 371.46 530.879 371.436C530.844 372.173 530.695 372.901 530.438 373.596"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1167"
            d="M531.948 370.876C531.674 371.797 531.189 373.329 530.959 373.837C530.949 373.862 530.93 373.883 530.906 373.897C531.266 372.169 531.765 370.471 532.399 368.82L532.452 368.742C532.351 369.463 532.185 370.175 531.957 370.868"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1168"
            d="M398.477 96.4907C399.864 97.4631 400.305 98.0999 399.925 98.1601C399.042 98.2978 395.139 95.5785 395.563 95.1654C396.04 94.9675 397.665 95.9571 398.46 96.5079"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1169"
            d="M124.324 197.691C123.336 199.412 121.764 199.937 121.358 198.68C120.837 197.028 122.85 194.18 124.28 194.567C125.384 194.989 124.916 196.667 124.28 197.691"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1170"
            d="M107.44 192.123C106.31 193.948 104.906 194.223 104.685 193.087C104.349 191.366 106.398 188.621 107.687 189.034C108.703 189.533 107.97 191.271 107.44 192.123Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1171"
            d="M92.9682 185.866C91.9174 187.484 90.6194 188.044 90.3986 186.968C90.0719 185.402 92.3766 182.33 93.4804 182.855C94.2928 183.371 93.5422 184.989 92.9682 185.866Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1172"
            d="M81.2138 179.163C80.11 180.747 79.1034 181.099 78.9533 180.359C78.6619 178.982 81.152 175.609 82.0439 176.16C82.6443 176.702 81.7966 178.294 81.1608 179.155"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1173"
            d="M392.906 94.0374C393.93 94.743 394.787 95.5605 394.389 95.6466C393.621 95.8187 389.727 93.2801 389.974 92.7638L390.027 92.7121C390.469 92.5314 392.049 93.4264 392.932 94.0374"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1174"
            d="M394.681 92.7899C395.723 93.4869 396.65 94.3647 396.235 94.4163C395.352 94.5282 391.387 91.9465 391.82 91.5421C392.217 91.3786 393.78 92.1789 394.708 92.7899"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1175"
            d="M376.234 434.212C375.66 436.432 372.702 436.983 371.899 435.494C371.095 434.005 372.623 432.052 374.239 431.768C374.549 431.761 374.856 431.825 375.137 431.953C375.418 432.081 375.665 432.271 375.858 432.508C376.051 432.744 376.186 433.021 376.251 433.317C376.316 433.613 376.311 433.919 376.234 434.212Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1176"
            d="M492.953 158.674C493.483 159.397 494.418 160.928 494.207 161.178C493.58 161.238 490.984 157.736 491.054 156.944C491.231 156.608 492.273 157.744 492.953 158.665"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1177"
            d="M492.211 160.937C493.209 162.323 493.757 163.579 493.306 163.519C492.609 163.364 490.021 159.965 490.278 159.216C490.507 158.863 491.558 160.077 492.211 160.937Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1178"
            d="M402.54 82.6527C403.295 82.9926 403.985 83.4553 404.58 84.021C404.015 84.1587 399.821 81.9987 399.75 81.5426C399.882 81.3361 401.516 82.0417 402.558 82.6183"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1179"
            d="M489.731 165.378C490.561 166.556 491.188 168.105 490.667 168.028C489.837 167.899 487.294 164.526 487.7 163.674C488.088 163.372 489.148 164.534 489.731 165.395"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1180"
            d="M488 167.632C488.557 168.423 489.29 169.766 489.034 170.213C488.257 170.798 485.696 166.84 485.855 166.014C485.868 165.962 485.888 165.913 485.917 165.867C486.208 165.575 487.091 166.315 488 167.588"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1181"
            d="M483.726 172.15C484.468 173.329 484.865 174.731 484.256 174.731C483.196 174.594 480.724 171.143 481.412 170.291C481.951 169.981 483.099 171.151 483.726 172.193"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1182"
            d="M480.997 174.275C481.978 175.824 482.057 176.935 481.465 176.857C480.371 176.719 477.863 173.191 478.693 172.374C479.187 172.047 480.247 173.105 480.997 174.275Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1183"
            d="M474.692 178.397C475.372 179.499 475.77 181.22 474.948 181.082C473.633 180.867 471.293 177.365 472.299 176.547C472.794 176.272 473.88 177.115 474.666 178.397"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1184"
            d="M471.117 180.583C472 182.081 472 183.372 471.117 183.165C469.766 182.795 467.638 179.336 468.662 178.621C469.227 178.329 470.287 179.189 471.108 180.618"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1185"
            d="M463.073 184.55C463.709 185.772 463.85 187.416 462.834 187.132C461.068 186.641 459.302 183.182 460.503 182.554C461.386 182.295 462.534 183.56 463.055 184.567"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1186"
            d="M458.453 186.426C459.336 188.035 459.124 189.403 458.073 189.008C456.528 188.483 454.691 185.092 455.954 184.412C457.137 184.085 458.303 186.133 458.435 186.4"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1187"
            d="M448.704 190.307C449.208 191.486 449.181 193.302 447.821 192.691C446.135 191.933 444.642 188.749 446.055 188.121C447.194 187.889 448.369 189.429 448.704 190.307Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1188"
            d="M443.24 192.019C443.964 193.594 443.584 194.893 442.507 194.558C440.741 193.999 439.257 190.582 440.511 189.928L440.626 189.877C441.571 189.601 442.657 190.737 443.275 192.019"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1189"
            d="M415.228 182.76C415.599 183.62 415.714 184.972 414.813 185.342C413.047 185.574 410.955 182.226 412.094 180.97C412.205 180.846 412.353 180.759 412.518 180.72C413.63 180.557 414.813 181.822 415.237 182.794"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1190"
            d="M491.178 246.441C491.69 248.162 491.178 250.003 490.065 249.375C488.414 248.463 487.937 244.849 489.068 244.324C489.951 244.048 490.834 245.184 491.178 246.441Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1191"
            d="M427.036 179.86C427.672 181.262 427.407 182.622 426.153 182.209C424.387 181.598 422.895 178.319 424.157 177.708L424.281 177.657C425.235 177.416 426.409 178.586 427.001 179.86"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1192"
            d="M432.537 178.13C432.952 178.991 433.191 180.29 432.414 180.626C430.648 180.901 428.714 177.382 429.517 176.323C429.605 176.211 429.725 176.127 429.862 176.082C430.86 175.884 432.069 177.149 432.511 178.13"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1193"
            d="M400.793 112.41C401.879 113.417 402.311 114.26 401.764 114.329C400.625 114.475 397.296 111.489 397.932 110.887H397.993C398.514 110.698 399.821 111.55 400.793 112.445"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1194"
            d="M442.666 174.705C443.549 176.211 443.417 177.468 442.419 177.287C440.75 176.917 438.887 173.5 439.955 172.829L440.114 172.735C441.129 172.511 442.216 173.862 442.701 174.705"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1195"
            d="M447.495 173.079C448.219 174.422 448.175 175.799 447.089 175.429C445.322 174.835 443.556 171.53 444.748 171.014C445.579 170.799 446.824 171.874 447.468 173.096"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1196"
            d="M390.92 116.128C392.085 117.341 392.147 118.133 391.476 118.081C390.116 117.969 387.335 115.052 388.094 114.527L388.165 114.484C388.694 114.338 389.878 115.043 390.92 116.128Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1197"
            d="M455.884 169.224C456.343 169.921 457.041 171.358 456.378 171.806C455.001 172.201 452.687 168.665 453.155 167.632C453.203 167.526 453.287 167.441 453.394 167.391C454.277 167.124 455.363 168.424 455.893 169.241"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1198"
            d="M459.795 167.339C460.325 168.148 460.846 169.473 460.334 169.861C459.751 170.05 458.718 169.207 457.994 168.269C457.27 167.331 456.846 166.203 457.111 165.687C457.146 165.611 457.204 165.548 457.278 165.506C457.985 165.265 459.203 166.41 459.813 167.339"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1199"
            d="M234.835 126.953C234.093 128.597 232.707 129.053 232.318 128.132C231.771 126.798 233.369 124.587 234.49 124.69C235.612 124.793 235.179 126.205 234.835 126.97"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1200"
            d="M466.604 163.398C467.646 164.861 467.884 166.134 467.072 165.98C465.853 165.747 463.346 162.391 464.202 161.677C464.873 161.367 466.03 162.632 466.604 163.398Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1201"
            d="M469.58 161.367C470.463 162.537 470.913 164.06 470.216 163.949C469.068 163.794 466.383 160.446 467.204 159.646C467.823 159.388 468.97 160.558 469.562 161.367"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1202"
            d="M219.427 125.155C219.268 125.439 218.402 126.833 217.308 126.764C216.054 126.454 217.131 123.993 218.606 123.132C218.841 122.967 219.129 122.887 219.418 122.909C220.107 123.012 220.116 123.984 219.418 125.155"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1203"
            d="M474.738 157.425C475.718 158.742 476.097 159.921 475.506 159.852C474.499 159.749 471.744 156.41 472.371 155.601C472.76 155.283 473.881 156.281 474.738 157.425Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1204"
            d="M476.733 155.179C477.554 156.221 478.331 157.847 477.678 157.761C476.733 157.615 473.996 154.319 474.517 153.561C474.985 153.295 476.124 154.422 476.724 155.196"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1205"
            d="M178.851 117.014C177.42 118.443 176.148 118.735 176.148 118.03C176.148 117.006 178.639 114.587 179.804 114.803C180.334 114.975 179.919 115.947 178.851 117.014Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1206"
            d="M480.114 151.135C481.139 152.435 481.624 153.579 481.121 153.493C480.238 153.347 477.474 150.051 477.88 149.345C478.216 149.001 479.399 150.206 480.114 151.135Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1207"
            d="M481.289 148.975C482.066 149.948 482.888 151.394 482.402 151.325C481.643 151.213 478.87 148.037 479.099 147.229C479.391 146.953 480.556 148.089 481.28 148.95"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1208"
            d="M180.723 398.018C180.599 400.032 178.427 400.599 177.023 399.007C175.619 397.415 176.299 394.653 178.259 394.808H178.347C179.077 394.965 179.723 395.377 180.161 395.968C180.599 396.558 180.799 397.285 180.723 398.009"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1209"
            d="M333.548 402.261C333.186 404.429 330.484 405.307 329.177 403.672C328.9 403.278 328.735 402.82 328.696 402.345C328.658 401.869 328.748 401.392 328.958 400.961C329.168 400.529 329.49 400.16 329.893 399.888C330.296 399.616 330.764 399.452 331.252 399.412C333.292 399.412 333.742 401.133 333.548 402.261Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1210"
            d="M517.36 398.284C517.005 399.294 516.489 400.244 515.833 401.098C515.524 401.296 515.612 400.677 515.665 400.401C515.994 398.934 516.635 397.551 517.546 396.34C517.793 396.159 517.873 396.34 517.784 396.864C517.694 397.347 517.555 397.82 517.369 398.276"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1211"
            d="M467.107 279.443C467.16 279.838 467.389 281.835 465.976 282.368C464.069 282.756 463.036 279.116 464.21 277.558C464.39 277.309 464.647 277.122 464.943 277.024C465.941 276.758 466.912 277.816 467.16 279.434"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1212"
            d="M391.953 293.091C391.953 293.34 392.059 295.492 389.993 295.922C387.494 296.094 386.549 292.893 388.059 291.206C388.387 290.816 388.854 290.561 389.366 290.492C391.282 290.346 391.909 292.127 392.015 293.074"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1213"
            d="M362.406 296.438C362.457 297.067 362.263 297.69 361.864 298.186C361.465 298.682 360.89 299.013 360.252 299.115C357.709 299.115 356.914 295.827 358.547 294.27C358.895 293.928 359.354 293.715 359.845 293.667C361.841 293.59 362.406 295.389 362.424 296.43"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1214"
            d="M364.571 399.851C364.226 402.191 361.427 403.224 360.155 401.469C359.034 399.92 360.314 397.424 362.319 397.243C364.323 397.063 364.712 398.844 364.562 399.825"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1215"
            d="M468.37 255.287C468.732 257.111 467.876 258.729 466.534 257.869C465.024 256.862 464.467 253.497 466.048 252.86C467.134 252.551 468.079 253.859 468.37 255.313"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1216"
            d="M474.613 253.153C474.904 254.564 474.401 256.699 472.971 255.821C471.284 254.78 470.834 251.467 472.291 250.821C473.43 250.546 474.366 252 474.604 253.153"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1217"
            d="M332.286 298.831C332.119 301.215 329.637 301.929 328.304 300.552C326.706 298.9 327.633 295.931 329.787 295.811C331.774 295.811 332.366 297.635 332.286 298.866"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1218"
            d="M422.629 90.8019C423.37 91.258 424.624 92.1014 424.571 92.3423C424.2 92.5316 420.068 90.036 419.971 89.5713C420.033 89.3218 421.56 90.1393 422.62 90.8019"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1219"
            d="M419.574 99.8037C419.768 99.9414 421.499 101.146 421.34 101.525C420.872 101.852 416.731 99.1067 416.828 98.5301C416.823 98.5161 416.823 98.501 416.828 98.4871C417.19 98.2719 418.762 99.2272 419.539 99.7693"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1220"
            d="M415.149 104.175C415.343 104.312 417.03 105.586 416.756 105.999C416.103 106.309 412.094 103.417 412.341 102.841C412.721 102.652 414.036 103.409 415.096 104.201"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1221"
            d="M358.089 106.473C358.972 107.48 359.096 108.297 358.416 108.254C357.118 108.168 354.689 105.466 355.396 104.898C355.44 104.865 355.492 104.842 355.546 104.829C356.111 104.726 357.206 105.432 358.089 106.473Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1222"
            d="M301.857 299.838C301.689 302.204 299.208 303.168 297.724 301.412C296.241 299.657 297.495 296.912 299.428 296.852C301.512 296.92 301.936 298.762 301.857 299.838Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1223"
            d="M485.978 248.842C486.446 250.623 485.854 252.327 484.635 251.561C483.223 250.657 482.428 247.258 483.814 246.544C484.627 246.243 485.58 247.233 485.978 248.842Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1224"
            d="M388.588 188.311C388.588 188.397 389.118 190.281 387.705 190.66C385.692 190.832 384.173 187.588 385.162 186.357C385.346 186.134 385.612 185.988 385.903 185.953C387.502 185.781 388.367 187.605 388.553 188.319"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1225"
            d="M395.68 187.038C396.042 188.303 395.503 190.118 393.825 189.18C391.803 188.044 391.388 185.239 392.775 184.766L392.925 184.723C393.922 184.559 395.282 185.704 395.68 187.038Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1226"
            d="M408.951 108.461C410.241 109.536 410.559 110.233 410.126 110.294C409.154 110.423 405.711 107.626 406.037 107.006C406.05 106.985 406.068 106.967 406.09 106.955C406.664 106.74 408.157 107.815 408.951 108.461Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1227"
            d="M408.934 184.309C409.543 185.824 409.022 187.227 407.636 186.641C405.729 185.85 404.66 182.683 406.1 182.132H406.25C407.195 181.943 408.343 182.907 408.899 184.352"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1228"
            d="M447.901 278.789C448.007 280.166 447.018 282.541 445.252 281.233C443.486 279.925 443.663 276.818 445.42 276.267C446.859 276.052 447.76 277.593 447.857 278.789"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1229"
            d="M140.819 185.015C140.705 185.221 139.68 187.028 138.32 186.83C136.784 186.288 138.073 183.182 139.795 182.338C139.925 182.258 140.07 182.204 140.222 182.179C140.373 182.154 140.528 182.158 140.678 182.192C141.782 182.571 141.279 184.154 140.802 185.023"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1230"
            d="M439.99 280.407C440.131 281.371 439.884 282.989 438.612 283.376C436.476 283.668 435.407 280.278 436.643 278.66C436.869 278.34 437.209 278.113 437.597 278.023C438.745 277.774 439.76 278.78 440.008 280.407"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1231"
            d="M150.567 105.052C148.907 106.214 148.094 106.326 148.138 105.913C148.236 105.139 151.671 102.522 152.554 102.875C152.898 103.245 151.432 104.441 150.602 105.018"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1232"
            d="M159.76 189.369C159.592 189.705 158.692 191.391 157.279 191.211C155.654 190.712 156.67 187.64 158.365 186.719C158.665 186.529 159.026 186.453 159.38 186.504C160.316 186.71 160.484 187.941 159.778 189.378"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1233"
            d="M167.973 412.07C167.973 413.938 166.154 414.566 164.706 413.198C163.028 411.632 163.408 409.007 165.315 409.007H165.562C166.266 409.161 166.892 409.55 167.332 410.106C167.772 410.662 167.999 411.351 167.973 412.053"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1234"
            d="M511.93 234.591C512.204 235.392 512.575 237.044 512.018 237.586C511.038 238.085 509.431 234.316 509.996 232.853C510.038 232.736 510.108 232.629 510.199 232.543C510.879 232.156 511.612 233.653 511.965 234.591"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1235"
            d="M515.1 232.233C515.515 233.575 515.603 235.391 514.906 235.107C513.89 234.703 512.521 230.976 513.352 230.09C513.934 229.729 514.738 231.106 515.118 232.233"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1236"
            d="M348.277 417.052C347.827 419.264 344.948 420.082 343.818 418.317C342.829 416.777 344.127 414.531 346.096 414.385C348.065 414.238 348.507 415.951 348.277 417.052Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1237"
            d="M492.212 171.211C493.254 172.812 493.439 173.879 492.892 173.741C492.009 173.5 489.651 170.101 490.102 169.292C490.375 168.991 491.311 169.835 492.212 171.211Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1238"
            d="M378.627 414.075C378.248 416.476 375.378 417.32 374.292 415.857C373.205 414.394 374.53 411.924 376.561 411.692C378.592 411.459 378.777 413.137 378.627 414.075Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1239"
            d="M322.67 199.309C322.785 201.365 321.063 202.131 319.809 201.099C318.105 199.704 318.493 196.882 320.41 196.667C321.593 196.615 322.582 197.777 322.67 199.309Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1240"
            d="M388.694 96.3007C389.462 96.8601 390.46 97.7551 390.16 98.0218C389.18 98.2972 385.63 95.5521 385.824 95.0874C385.824 95.0443 385.868 95.0357 385.877 95.0271C386.354 94.8808 387.82 95.6897 388.694 96.3179"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1241"
            d="M485.536 177.967C486.419 179.473 486.569 180.703 485.792 180.445C484.618 180.058 482.489 176.719 483.222 175.936C483.893 175.557 485.121 177.27 485.536 177.967Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1242"
            d="M347.14 197.553C347.387 199.463 345.868 200.496 344.438 199.403C342.671 198.06 342.822 195.212 344.641 194.98C346.336 194.928 347.007 196.641 347.122 197.561"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1243"
            d="M415.449 95.9912C416.209 96.5075 417.304 97.3852 417.215 97.652C416.624 97.9102 412.482 95.2597 412.641 94.7348C412.941 94.5368 414.451 95.3371 415.432 95.9912"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1244"
            d="M371.078 194.859C371.511 196.779 370.098 197.889 368.57 196.847C366.76 195.608 366.645 192.717 368.402 192.424C370.054 192.304 370.866 193.973 371.052 194.868"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1245"
            d="M414.257 97.3854C415.564 98.246 416.35 99.0549 416.023 99.1065C415.546 99.2184 411.246 96.783 411.546 96.1118C411.997 95.8364 414.195 97.2907 414.275 97.351"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1246"
            d="M411.423 100.191C412.589 101.052 413.392 101.912 413.048 101.998C412.227 102.179 408.324 99.4166 408.633 98.9261C409.012 98.7454 410.478 99.5543 411.397 100.208"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1247"
            d="M271.454 300.045C271.172 302.291 268.849 303.16 267.454 301.55C265.909 299.778 266.933 297.007 269.167 296.912C271.278 297.058 271.587 299.02 271.454 300.045Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1248"
            d="M500.548 241.983C501.086 243.79 500.671 245.27 499.762 244.814C498.446 244.152 497.369 240.554 498.57 239.746C499.223 239.427 500.071 240.382 500.548 241.983Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1249"
            d="M521.837 246.957C522.102 247.895 522.34 249.487 521.908 250.012C521.025 250.571 519.833 246.733 520.142 245.288C520.166 245.133 520.236 244.989 520.345 244.874C520.769 244.522 521.378 245.382 521.819 246.948"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1250"
            d="M504.716 239.47C505.131 240.796 505.096 242.999 504.054 242.413C502.764 241.699 501.705 238.231 502.8 237.405C503.444 237.052 504.31 238.188 504.716 239.47Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1251"
            d="M515.559 252.052C515.806 253.007 515.921 254.59 515.4 255.098C514.376 255.606 513.042 251.931 513.634 250.314C513.679 250.172 513.765 250.047 513.881 249.952C514.535 249.617 515.268 250.95 515.541 252.043"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1252"
            d="M511.833 254.65C512.292 256.491 511.912 258.032 511.109 257.602C509.952 256.982 509.042 253.299 510.12 252.438C511.144 251.991 511.771 254.392 511.833 254.658"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1253"
            d="M116.465 402.914C116.607 404.635 115.423 405.323 114.108 404.265C112.341 402.862 112.174 399.962 113.79 399.755C113.936 399.73 114.085 399.73 114.231 399.755C115.829 400.212 116.395 401.941 116.465 402.888"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1254"
            d="M503.382 259.65C503.629 260.976 503.382 263.093 502.287 262.447C500.98 261.673 500.194 258.144 501.457 257.284C502.34 257 503.135 258.463 503.356 259.633"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1255"
            d="M498.516 261.87C498.966 263.85 498.322 265.381 497.315 264.745C495.982 263.884 495.267 260.382 496.6 259.581C497.324 259.254 498.145 260.235 498.516 261.844"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1256"
            d="M386.504 97.5743C387.582 98.4348 388.112 99.1319 387.688 99.1921C386.752 99.3298 383.087 96.6621 383.52 96.163H383.573C384.191 95.9737 385.895 97.1526 386.496 97.6173"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1257"
            d="M86.433 161.952C85.2762 163.364 84.402 163.673 84.3225 163.088C84.1459 161.823 86.9098 158.502 87.6781 159.044C88.1372 159.577 87.0599 161.186 86.4242 161.952"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1258"
            d="M487.911 266.379C488.114 267.352 488.008 268.961 487.161 269.383C485.642 269.778 484.309 266.302 485.333 264.607C485.447 264.401 485.627 264.238 485.845 264.142C486.861 263.858 487.708 265.252 487.929 266.371"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1259"
            d="M482.039 268.669C482.392 270.545 481.553 272.283 480.273 271.405C478.728 270.321 478.507 266.853 479.92 266.319C481.068 266.01 481.792 267.559 482.004 268.669"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1260"
            d="M96.1646 168.312C96.0144 168.51 94.734 170.205 93.851 169.878C92.8179 169.069 95.4405 165.575 96.6855 165.455C96.7839 165.445 96.883 165.466 96.9681 165.515C97.6216 166.005 96.7738 167.511 96.1734 168.32"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1261"
            d="M141.305 407.949C141.367 409.859 139.751 410.272 138.497 409.179C136.731 407.647 136.793 404.877 138.594 404.816C138.723 404.803 138.853 404.803 138.983 404.816C139.66 405.023 140.252 405.436 140.67 405.996C141.088 406.555 141.311 407.231 141.305 407.923"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1262"
            d="M469.412 272.885C469.633 274.452 468.794 276.655 467.275 275.519C465.509 274.211 465.686 270.949 467.125 270.51C468.352 270.252 469.235 271.724 469.412 272.885Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1263"
            d="M108.668 174.594C107.493 176.246 106.222 176.581 106.019 175.704C105.674 174.301 107.864 171.229 109.259 171.659C109.895 171.952 109.648 173.243 108.685 174.594"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1264"
            d="M462.481 274.838C462.852 276.912 461.66 278.453 460.362 277.584C458.755 276.525 458.41 273.143 460.238 272.489C461.942 272.188 462.419 274.572 462.463 274.847"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1265"
            d="M495.902 164.293C496.891 165.704 497.394 166.918 496.971 166.875C496.335 166.746 493.862 163.364 494.012 162.503C494.154 162.202 494.984 162.994 495.902 164.328"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1266"
            d="M123.759 179.99C122.549 181.978 121.11 182.227 120.819 181.341C120.342 179.921 122.311 176.866 123.892 177.236C125.066 177.752 123.892 179.766 123.759 179.99Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1267"
            d="M438.78 140.895C439.389 141.635 440.051 142.822 439.601 143.158C438.435 143.459 435.336 140.112 435.998 139.26C436.026 139.229 436.058 139.202 436.095 139.182C436.74 138.967 438.073 140.043 438.744 140.903"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1268"
            d="M435.433 142.667C436.422 143.932 436.59 144.991 435.769 144.836C434.444 144.586 431.627 141.394 432.661 140.817C433.499 140.542 434.93 142.065 435.407 142.685"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1269"
            d="M476.83 240.124C477.395 242.078 476.644 243.472 475.426 242.706C473.819 241.751 473.201 238.274 474.543 237.749C475.726 237.422 476.538 239.066 476.83 240.09"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1270"
            d="M427.794 145.731C428.624 146.815 428.96 148.149 428.156 148.097C426.717 148.002 424.209 144.844 424.968 144.078C425.005 144.04 425.05 144.011 425.101 143.992C425.807 143.812 427.052 144.75 427.75 145.713"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1271"
            d="M423.689 147.391C424.625 148.699 424.687 149.792 423.821 149.663C422.205 149.405 419.883 146.221 420.872 145.567L420.96 145.524C421.684 145.283 422.903 146.307 423.689 147.391Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1272"
            d="M445.164 112.703C445.791 113.22 446.851 114.209 446.833 114.579C446.356 115.121 442.639 111.765 442.621 111.301C442.621 111.301 442.621 111.258 442.621 111.24C442.842 110.948 444.29 111.98 445.155 112.703"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1273"
            d="M414.681 150.309C415.193 151.083 415.564 152.314 414.937 152.641C414.222 152.77 413.118 151.926 412.394 151.023C411.67 150.119 411.343 149.061 411.732 148.605C411.796 148.54 411.875 148.49 411.961 148.458C412.844 148.295 414.116 149.422 414.69 150.309"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1274"
            d="M409.833 151.832C410.663 153.166 410.548 154.268 409.604 154.078C407.926 153.76 405.939 150.714 406.91 149.956C406.972 149.913 407.04 149.881 407.113 149.862C407.996 149.647 409.144 150.722 409.833 151.832Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1275"
            d="M225.617 89.4767C224.46 90.5352 223.33 91.0171 223.383 90.4319C223.453 89.5714 225.864 87.5663 226.641 87.704C227.418 87.8417 225.687 89.4251 225.617 89.4767Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1276"
            d="M448.335 104.261C449.095 104.803 449.789 105.43 450.401 106.128C448.908 105.24 447.537 104.17 446.321 102.944C446.317 102.924 446.317 102.904 446.321 102.884C447.061 103.259 447.754 103.716 448.387 104.243"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1277"
            d="M399.493 154.526C400.076 155.627 400.014 157.107 398.858 156.737C396.986 156.169 395.325 153.157 396.597 152.555L396.712 152.512C397.754 152.366 398.99 153.588 399.485 154.526"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1278"
            d="M393.86 155.764C394.655 157.253 394.267 158.398 393.101 157.993C391.247 157.348 389.799 154.31 391.097 153.776L391.22 153.733C392.377 153.579 393.454 154.99 393.869 155.764"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1279"
            d="M443.106 116.05C444.325 117.074 445.119 118.15 444.678 118.167C443.795 118.167 440.333 115.215 440.643 114.725C440.925 114.493 442.258 115.362 443.106 116.076"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1280"
            d="M382.186 158.165C382.689 159.379 382.345 160.876 380.994 160.291C378.972 159.43 378.106 156.59 379.334 156.109H379.466C380.614 155.997 381.78 157.253 382.177 158.208"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1281"
            d="M375.908 159.224C376.57 160.791 375.952 161.909 374.698 161.41C372.826 160.653 371.731 157.641 373.153 157.168H373.303C374.354 157.013 375.422 158.132 375.908 159.259"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1282"
            d="M395.141 327.797C395.171 328.443 394.968 329.078 394.568 329.594C394.169 330.109 393.596 330.472 392.951 330.62C390.496 330.792 389.657 327.599 391.185 325.921C391.561 325.485 392.089 325.2 392.668 325.121C394.072 324.949 395.194 326.145 395.185 327.789"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1283"
            d="M443.759 98.7277C444.642 99.4248 445.808 100.303 446.046 100.561C444.646 99.6124 443.297 98.5956 442.002 97.5143L441.949 97.4541C442.563 97.8445 443.153 98.2698 443.715 98.7277"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1284"
            d="M441.923 117.944C443.089 118.976 443.689 119.906 443.265 119.932C442.55 119.932 438.788 117.014 439.283 116.403C439.645 116.128 441.093 117.204 441.932 117.944"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1285"
            d="M209.087 85.2597C207.48 86.3182 206.588 86.4817 206.685 86.1202C206.862 85.4318 209.899 83.3837 210.782 83.5386C211.135 83.6935 210.199 84.511 209.087 85.2597Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1286"
            d="M438.515 121.325C439.398 122.186 440.166 123.305 439.724 123.365C438.974 123.46 435.247 120.491 435.795 119.777C436.254 119.553 437.658 120.534 438.506 121.334"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1287"
            d="M436.449 123.047C437.023 123.615 437.933 124.665 437.668 125.017C437.2 125.233 435.902 124.234 435.248 123.684C434.162 122.72 433.482 121.765 433.659 121.463L433.712 121.403C434.215 121.145 435.663 122.264 436.458 123.038"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1288"
            d="M396.147 362.916C395.864 365.274 393.277 366.445 391.953 364.81C391.662 364.396 391.479 363.92 391.42 363.422C391.36 362.924 391.426 362.42 391.611 361.952C391.796 361.485 392.095 361.068 392.482 360.738C392.87 360.409 393.333 360.175 393.833 360.059C395.803 359.904 396.297 361.686 396.147 362.916Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1289"
            d="M431.415 126.145C432.369 127.083 433.058 128.348 432.466 128.365C431.301 128.365 428.183 125.327 428.722 124.69L428.775 124.639C429.393 124.449 430.718 125.499 431.424 126.145"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1290"
            d="M428.633 127.78C429.463 128.641 430.108 129.63 429.755 129.94C429.313 130.095 428.351 129.544 427.256 128.52C426.443 127.763 425.693 126.705 425.878 126.309C425.907 126.268 425.942 126.233 425.984 126.205C426.417 125.99 427.538 126.653 428.633 127.78Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1291"
            d="M231.834 79.7264C230.456 80.7418 229.653 80.9311 229.706 80.5869C229.803 79.9932 232.416 78.0053 233.238 78.0999C233.635 78.2548 232.54 79.1842 231.825 79.7006"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1292"
            d="M458.532 246.122C459.106 248.325 457.923 249.564 456.652 248.747C455.08 247.748 454.479 244.444 456.139 243.781C457.226 243.489 458.179 244.771 458.532 246.122Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1293"
            d="M358.618 79.8907C359.89 80.6136 360.464 81.1471 360.27 81.2332C359.758 81.4483 355.634 79.2367 355.722 78.7892C355.726 78.7792 355.731 78.7702 355.739 78.7627C355.747 78.7552 355.756 78.7496 355.766 78.7462C356.181 78.6257 357.735 79.383 358.618 79.8907Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1294"
            d="M437.464 251.715C437.888 253.961 436.44 255.028 435.089 254.099C433.42 252.937 433.049 249.753 434.938 249.185C436.042 248.953 437.155 250.046 437.464 251.715Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1295"
            d="M422.354 130.878C423.238 131.833 423.741 133.107 423.07 133.098C421.71 133.098 418.831 129.957 419.538 129.312L419.608 129.26C420.156 129.045 421.463 129.923 422.328 130.878"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1296"
            d="M413.534 256.458C413.596 256.845 413.808 258.781 412.059 259.194C409.728 259.375 408.651 255.976 410.037 254.444C410.263 254.18 410.574 253.998 410.92 253.928C413.022 253.687 413.49 256.174 413.569 256.458"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1297"
            d="M418.964 132.556C419.918 133.7 420.13 134.647 419.432 134.612C418.055 134.552 415.273 131.403 416.059 130.783L416.13 130.74C417.013 130.49 418.452 131.971 418.938 132.564"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1298"
            d="M399.926 235.83C400.111 236.785 399.811 238.265 398.548 238.584C396.464 238.756 394.919 235.572 396.323 233.98C396.549 233.718 396.861 233.539 397.206 233.472C398.654 233.335 399.723 234.754 399.926 235.83Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1299"
            d="M100.049 118.297C99.5718 118.71 98.4415 119.665 98.2825 119.544C98.2031 119.097 101.815 115.801 102.203 115.982C102.203 115.982 102.203 115.982 102.203 116.042C101.586 116.883 100.859 117.641 100.04 118.297"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1300"
            d="M271.127 334.802C271.115 335.437 270.855 336.043 270.401 336.499C269.947 336.954 269.332 337.224 268.681 337.255H268.628C265.979 337.005 265.511 333.615 267.339 332.22C267.743 331.909 268.245 331.742 268.761 331.747C270.818 331.876 271.216 333.726 271.127 334.802Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1301"
            d="M446.735 120.586C448.113 121.808 448.634 122.694 448.254 122.763C447.636 122.866 443.936 119.897 444.289 119.175C444.643 118.899 445.994 119.932 446.735 120.586Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1302"
            d="M302.414 334.69C302.211 337.073 299.615 338.046 298.149 336.299C296.551 334.397 297.946 331.729 299.968 331.686C301.99 331.643 302.503 333.683 302.414 334.69Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1303"
            d="M445.783 105.509C446.472 106.051 447.796 107.23 447.717 107.394C447.196 107.463 443.743 104.812 443.514 104.21C443.708 104.081 444.953 104.915 445.774 105.552"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1304"
            d="M333.725 333.511C333.575 335.921 331.006 336.953 329.496 335.232C327.986 333.511 329.063 330.697 331.262 330.577C333.461 330.456 333.814 332.418 333.752 333.502"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1305"
            d="M449.827 115.302C451.214 116.472 451.779 117.298 451.417 117.298C450.675 117.298 447.24 114.364 447.381 113.856C447.593 113.555 449.077 114.674 449.827 115.319"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1306"
            d="M492.115 233.378C492.839 235.443 492.265 236.76 491.285 236.269C489.925 235.589 488.795 232.027 490.031 231.269C490.967 230.942 491.797 232.448 492.106 233.378"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1307"
            d="M364.747 331.118C364.774 331.771 364.559 332.411 364.143 332.923C363.726 333.434 363.135 333.783 362.477 333.906H362.407C359.899 333.993 359.087 330.774 360.641 329.148C361.043 328.725 361.589 328.459 362.177 328.399C363.616 328.287 364.703 329.457 364.694 331.118"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1308"
            d="M449.827 103.34L449.942 103.435L451.875 105.044L452.184 105.302C450.895 104.227 449.588 103.177 448.308 102.101L448.229 102.041C448.343 102.118 449.226 102.85 449.827 103.34Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1309"
            d="M358.177 458.72C357.665 460.717 354.778 461.603 353.894 460.028C353.153 458.72 354.548 456.827 356.376 456.655C356.652 456.605 356.937 456.627 357.202 456.72C357.466 456.813 357.7 456.973 357.88 457.183C358.06 457.394 358.179 457.647 358.225 457.917C358.271 458.187 358.242 458.465 358.142 458.72"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1310"
            d="M453.977 107.195C454.742 107.814 455.473 108.472 456.167 109.166C454.791 108.173 453.487 107.089 452.264 105.922V105.861C452.88 106.272 453.47 106.721 454.03 107.204"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1311"
            d="M511.735 415.624C511.099 416.898 510.278 418.275 510.048 418.275C509.819 417.999 511.549 414.506 512.335 413.654C512.415 413.593 512.512 413.499 512.512 413.654C512.328 414.338 512.064 414.999 511.726 415.624"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1312"
            d="M517.547 408.026C517.026 409.11 516.354 410.435 516.16 410.754C516.24 410.409 518.209 406.451 518.43 406.064L518.483 405.978C518.483 406.115 517.962 407.165 517.547 408.026Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1313"
            d="M457.775 112.952C458.542 113.559 459.23 114.256 459.823 115.026C459.276 114.966 456.053 112.083 455.867 111.635C455.867 111.635 455.867 111.592 455.867 111.575C456.579 111.925 457.229 112.384 457.792 112.935"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1314"
            d="M445.993 107.333C446.541 107.764 447.954 108.943 447.901 109.244C447.494 109.433 443.715 106.525 443.68 106C443.75 105.716 445.137 106.671 445.993 107.333Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1315"
            d="M458.551 114.957C459.301 115.611 460.493 116.747 460.458 117.005C460.078 117.117 456.74 114.226 456.484 113.563V113.512C456.714 113.365 458.365 114.785 458.551 114.949"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1316"
            d="M255.613 93.409C254.977 94.7687 253.944 95.2334 253.732 94.5364C253.441 93.5984 254.712 91.6105 255.569 91.6621C256.204 91.774 255.895 92.8153 255.613 93.3832"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1317"
            d="M458.948 118.718C459.928 119.579 461.032 120.835 460.803 120.912C460.573 120.99 456.961 118.184 456.855 117.393C456.979 117.117 458.207 118.064 458.948 118.718Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1318"
            d="M458.877 120.766C460.016 121.799 460.802 122.78 460.555 122.866C460.157 123.004 456.616 120.078 456.607 119.295C456.713 119.002 457.853 119.837 458.877 120.766Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1319"
            d="M450.18 109.691C451.063 110.405 452.326 111.532 452.167 111.748C451.602 111.748 448.211 109.045 448.043 108.426V108.374C448.299 108.211 449.986 109.545 450.18 109.691Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1320"
            d="M457.711 124.604C458.718 125.577 459.592 126.773 459.124 126.721C458.347 126.652 455.124 123.787 455.291 123.081C455.548 122.797 456.854 123.787 457.711 124.604Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1321"
            d="M456.642 126.489C457.755 127.556 458.409 128.563 458.073 128.64C457.499 128.778 453.923 125.74 454.214 124.948C454.453 124.656 455.618 125.499 456.642 126.489Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1322"
            d="M245.078 92.4539C244.195 93.6845 243.233 94.1148 243.074 93.5468C242.853 92.7723 244.407 90.6382 245.396 90.6554C245.864 90.7242 245.723 91.5159 245.078 92.4539Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1323"
            d="M453.711 130.129C454.656 131.067 455.477 132.367 455.018 132.41C454.285 132.479 450.788 129.424 451.265 128.641C451.698 128.391 453.031 129.424 453.711 130.129Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1324"
            d="M452.008 132.152C453.059 133.262 453.553 134.26 453.059 134.277C452.008 134.32 448.873 131.076 449.377 130.465C449.898 130.129 451.443 131.575 452.026 132.186"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1325"
            d="M234.994 91.1725C233.96 92.3342 232.971 92.6957 232.918 92.1707C232.839 91.3962 234.746 89.2965 235.718 89.3395C236.309 89.4944 235.541 90.5701 234.994 91.1725Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1326"
            d="M447.398 135.585C448.369 136.635 449.014 137.909 448.378 137.9C447.151 137.9 444.148 134.63 444.846 134.036C445.358 133.804 446.665 134.794 447.416 135.603"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1327"
            d="M444.812 137.453C445.862 138.631 446.269 139.699 445.695 139.699C444.485 139.699 441.553 136.437 442.162 135.792C442.177 135.788 442.192 135.788 442.207 135.792C442.789 135.534 444.096 136.652 444.856 137.513"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1328"
            d="M445.545 110.775C446.79 111.765 447.717 112.806 447.381 112.841C446.71 112.91 443.152 110.044 443.24 109.51C443.381 109.278 444.556 109.992 445.545 110.775Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1329"
            d="M432.211 247.137C432.581 249.246 431.186 250.528 429.729 249.426C428.113 248.204 427.804 245.038 429.659 244.625C431.345 244.366 432.069 246.346 432.211 247.137Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1330"
            d="M487.611 210.383C488.353 212.104 488.115 213.507 487.143 213.06C485.757 212.414 484.282 208.989 485.377 208.241C485.995 207.948 486.958 208.869 487.611 210.383Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1331"
            d="M439.672 245.202C440.14 247.061 439.062 248.799 437.517 247.844C435.469 246.587 435.495 243.352 437.093 242.947C438.427 242.741 439.398 244.143 439.672 245.202Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1332"
            d="M491.505 208.017C492.212 209.558 492.229 211.244 491.258 210.874C489.81 210.315 488.362 206.675 489.342 206.072C489.995 205.788 490.958 206.838 491.505 208.017Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1333"
            d="M498.295 203.422C498.728 204.282 499.178 205.771 498.737 206.253C497.66 206.812 495.743 202.923 496.088 201.735C496.116 201.639 496.171 201.553 496.247 201.485C496.706 201.167 497.554 201.985 498.26 203.422"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1334"
            d="M501.325 201.253C501.828 202.372 502.208 204.23 501.395 203.912C500.274 203.482 498.508 199.997 499.258 199.17C499.841 198.869 500.821 200.194 501.307 201.262"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1335"
            d="M506.049 196.434C506.72 197.768 507.064 199.283 506.49 199.171C505.652 198.999 503.594 195.29 504.159 194.412C504.521 194.042 505.404 195.152 506.049 196.434Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1336"
            d="M507.895 193.913C508.336 194.774 508.866 196.202 508.645 196.658C508.063 197.131 505.917 193.483 505.996 192.218C505.996 192.145 506.018 192.073 506.058 192.011C506.42 191.667 507.339 192.872 507.824 193.904"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1337"
            d="M386.797 259.272C386.956 261.483 385.031 262.645 383.406 261.277C381.64 259.779 382.081 256.974 384.086 256.561C386.055 256.423 386.735 258.282 386.797 259.272Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1338"
            d="M300.93 265.519C300.798 267.73 298.617 268.728 297.169 267.24C295.72 265.751 296.286 262.773 298.608 262.593C300.03 262.593 301.027 263.841 300.93 265.51"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1339"
            d="M95.9267 241.983C95.1673 243.834 93.6485 244.41 93.0745 243.059C92.2886 241.217 93.9045 238.197 95.4498 238.636C96.6949 239.161 96.3329 241.054 95.9267 241.983Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1340"
            d="M64.4977 208.663C63.6147 210.384 62.6257 210.866 62.3431 210.108C61.831 208.689 63.7472 205.126 64.8156 205.522C64.8507 205.534 64.8835 205.551 64.9128 205.573C65.681 206.296 64.6125 208.422 64.4889 208.663"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1341"
            d="M78.31 235.468C77.5329 237.19 76.2525 237.749 75.7669 236.553C75.1223 234.961 76.6499 231.57 78.1422 232.061C79.2019 232.672 78.7162 234.556 78.31 235.503"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1342"
            d="M77.1877 215.978C77.0818 216.193 76.1193 218.095 75.042 217.699C73.7351 216.838 75.3864 213.112 76.808 212.768C76.961 212.708 77.1319 212.708 77.2849 212.768C78.1679 213.31 77.6293 215.005 77.1877 215.917"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1343"
            d="M63.5626 228.103C62.7325 229.944 61.6464 230.289 61.302 229.342C60.7633 227.845 62.3351 224.179 63.6597 224.79C64.269 225.168 64.2248 226.623 63.5626 228.103Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1344"
            d="M92.9501 222.647C92.853 222.87 91.9523 224.807 90.6807 224.497C89.1001 223.722 90.5483 219.97 92.1466 219.514C92.3406 219.446 92.553 219.446 92.747 219.514C93.683 219.893 93.5153 221.399 92.959 222.638"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1345"
            d="M52.2592 220.435C52.1003 220.788 51.2614 222.518 50.5903 222.079C49.6631 221.003 51.6499 216.993 52.5683 217.105C52.6249 217.119 52.6787 217.142 52.7272 217.174C53.1599 217.57 52.9656 218.946 52.268 220.444"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1346"
            d="M509.97 215.134C510.349 215.995 510.782 217.544 510.385 218.043C509.502 218.611 507.736 214.601 508.053 213.422C508.08 213.324 508.128 213.233 508.195 213.155C508.61 212.828 509.343 213.637 509.961 215.126"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1347"
            d="M111.273 228.67C110.39 230.804 108.73 231.252 108.2 229.952C107.52 228.291 109.03 225.236 110.752 225.598C111.944 226.071 111.635 227.749 111.264 228.661"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1348"
            d="M503.983 219.944C504.521 221.252 504.654 223.231 503.85 222.93C502.631 222.483 501.095 218.86 502.084 217.991C502.773 217.681 503.629 219.032 504 219.953"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1349"
            d="M500.468 222.44C501.122 224.161 500.963 225.616 500.141 225.297C498.896 224.798 497.386 221.046 498.473 220.323C499.258 219.953 500.124 221.545 500.468 222.44Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1350"
            d="M131.6 459.994C132.085 461.414 131.22 461.827 130.116 461.242C128.35 460.313 127.282 457.731 128.536 457.413C128.709 457.364 128.892 457.364 129.065 457.413C129.66 457.628 130.198 457.973 130.637 458.42C131.076 458.867 131.405 459.405 131.6 459.994Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1351"
            d="M492.442 227.138C492.857 228.403 492.83 230.426 491.7 229.858C490.323 229.161 489.051 225.701 490.243 224.927C491.126 224.617 492.071 226.011 492.442 227.138Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1352"
            d="M487.797 229.333C488.424 231.097 488.018 232.56 487.038 232.13C485.598 231.493 484.389 227.913 485.616 227.156C486.42 226.803 487.32 228.016 487.797 229.333Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1353"
            d="M477.748 233.636C478.172 234.961 477.907 237.078 476.591 236.416C475.011 235.598 473.942 232.208 475.417 231.468C476.3 231.149 477.342 232.328 477.748 233.636Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1354"
            d="M369.957 244.229C370.169 246.363 368.244 247.542 366.672 246.234C365.1 244.926 365.197 241.932 367.334 241.562C368.668 241.433 369.798 242.586 369.983 244.229"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1355"
            d="M472.168 235.821C472.68 237.543 472.088 239.203 470.808 238.455C469.271 237.551 468.353 234.204 469.819 233.524C470.799 233.197 471.761 234.462 472.168 235.821Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1356"
            d="M396.607 240.606C396.607 240.839 396.996 242.912 395.194 243.334C392.943 243.523 391.662 240.245 392.916 238.756C393.178 238.437 393.553 238.225 393.967 238.162C395.221 237.99 396.307 239.023 396.616 240.606"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1357"
            d="M460.105 239.634C460.626 241.355 459.911 243.137 458.551 242.465C456.688 241.536 456.052 237.999 457.668 237.483C458.71 237.259 459.743 238.429 460.105 239.634Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1358"
            d="M453.562 241.69C454.047 243.514 453.2 245.175 451.796 244.272C449.959 243.144 449.544 239.823 451.186 239.332C452.537 239.065 453.359 240.735 453.597 241.664"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1359"
            d="M247.383 82.3777C247.197 82.5843 246.27 83.6255 245.617 83.6255C244.954 83.4534 246.35 81.6291 247.462 80.9923C247.619 80.8739 247.812 80.8102 248.01 80.8115C248.442 80.8804 248.01 81.6721 247.383 82.3777Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1360"
            d="M411.194 135.267C412.077 136.334 412.377 137.573 411.547 137.504C410.099 137.384 407.573 134.355 408.333 133.649C408.37 133.614 408.415 133.587 408.465 133.572C409.225 133.4 410.54 134.432 411.194 135.293"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1361"
            d="M415.945 233.016C416.448 235.012 415.185 236.406 413.684 235.52C411.856 234.427 411.335 231.217 413.34 230.675C415.229 230.477 415.865 232.758 415.927 233.016"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1362"
            d="M255.843 83.2804C255.03 84.554 254.2 84.8811 254.077 84.425C253.882 83.6505 255.313 81.7401 256.099 81.7314C256.699 81.8605 255.922 83.1341 255.834 83.2804"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1363"
            d="M423.619 231.407C423.999 232.793 423.248 234.961 421.561 234.04C419.53 232.93 419.142 229.738 420.979 229.178C422.4 229.032 423.336 230.469 423.628 231.416"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1364"
            d="M407.062 136.764C407.945 137.926 408.042 138.976 407.283 138.907C405.826 138.786 403.38 135.774 404.13 135.017C404.182 134.973 404.242 134.938 404.307 134.914C405.031 134.707 406.267 135.714 407.062 136.764Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1365"
            d="M398.055 139.32C398.567 140.06 398.991 141.239 398.311 141.532C397.595 141.635 396.492 140.817 395.776 139.948C395.061 139.079 394.717 138.072 395.105 137.65C395.169 137.586 395.248 137.539 395.335 137.513C396.218 137.375 397.472 138.468 398.055 139.32Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1366"
            d="M509.616 200.152C510.455 201.873 510.499 203.043 510.013 202.888C509.069 202.596 507.294 198.749 507.806 198.104C508.256 197.699 509.192 199.265 509.616 200.152Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1367"
            d="M437.993 228.352C438.435 230.116 437.543 231.648 436.05 230.736C434.382 229.712 433.657 226.364 435.423 225.926C436.969 225.659 437.799 227.586 437.993 228.352Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1368"
            d="M393.232 140.645C394.116 141.944 393.974 142.968 393.021 142.787C391.396 142.486 389.382 139.517 390.371 138.82C390.432 138.78 390.497 138.748 390.566 138.726C391.369 138.545 392.57 139.586 393.268 140.645"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1369"
            d="M382.973 142.917C383.405 143.674 383.67 144.871 382.902 145.146C381.374 145.309 379.123 142.323 379.97 141.248C380.062 141.139 380.19 141.066 380.332 141.041C381.295 140.921 382.496 142.057 382.981 142.917"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1370"
            d="M502.429 207.544C502.632 208 503.233 209.524 502.721 210.126C501.714 210.694 499.842 206.727 500.204 205.556C500.233 205.461 500.288 205.375 500.363 205.307C500.999 204.937 501.952 206.451 502.438 207.562"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1371"
            d="M444.776 226.511C445.156 227.896 444.617 229.884 443.072 229.032C441.156 227.965 440.538 224.73 442.189 224.213C443.416 223.998 444.485 225.418 444.785 226.519"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1372"
            d="M504.284 427.603C503.798 428.464 502.703 430.185 502.438 430.185C502.182 429.892 504.045 426.527 504.902 425.744C504.963 425.701 505.131 425.555 505.096 425.813C504.908 426.45 504.634 427.061 504.284 427.629"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1373"
            d="M377.55 144.173C378.248 145.568 377.815 146.583 376.667 146.179C374.901 145.533 373.488 142.642 374.707 142.16L374.875 142.108C376.129 141.962 377.206 143.545 377.524 144.173"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1374"
            d="M457.297 222.775C457.951 224.669 457.244 226.071 455.973 225.409C454.313 224.548 453.262 221.192 454.834 220.546C455.717 220.28 456.759 221.235 457.297 222.775Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1375"
            d="M492.017 214.428C492.767 216.27 492.361 217.466 491.505 217.01C490.048 216.304 488.856 212.767 489.818 212.165C490.701 211.803 491.637 213.464 492.017 214.394"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1376"
            d="M463.168 220.753C463.495 221.674 463.592 223.154 462.762 223.576C461.146 223.928 459.23 220.374 460.316 218.937C460.417 218.797 460.558 218.689 460.722 218.628C461.738 218.387 462.806 219.703 463.186 220.744"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1377"
            d="M366.062 146.058C366.398 146.833 366.477 148.012 365.603 148.235C363.837 148.33 362.151 145.353 362.954 144.32C363.013 144.246 363.086 144.184 363.169 144.139C363.253 144.093 363.345 144.064 363.44 144.053C364.517 143.967 365.638 145.163 366.036 146.058"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1378"
            d="M365.489 366.169C365.468 366.828 365.212 367.461 364.763 367.956C364.315 368.451 363.703 368.777 363.034 368.879C360.464 368.94 359.873 365.764 361.577 364.12C361.989 363.707 362.535 363.446 363.122 363.38C364.606 363.26 365.63 364.456 365.507 366.169"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1379"
            d="M478.588 220.926C478.729 221.296 479.374 223.155 478.385 223.705C476.857 224.17 475.214 220.496 475.921 219.119C475.999 218.951 476.137 218.816 476.309 218.74C477.025 218.456 478.075 219.42 478.614 220.917"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1380"
            d="M101.03 350.498C100.845 352.477 99.3966 352.856 98.3811 351.771C96.8888 350.153 97.2597 346.806 98.9375 346.797C99.0538 346.794 99.17 346.809 99.2818 346.84C100.774 347.331 101.154 349.293 101.048 350.498"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1381"
            d="M462.303 227.095C462.956 229.109 462.065 230.288 460.917 229.677C459.151 228.816 458.418 225.262 459.857 224.78C461.093 224.479 461.976 226.08 462.303 227.078"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1382"
            d="M125.339 355.79C125.206 358.062 123.387 358.544 122.213 357.176C120.667 355.377 121.33 352.21 123.255 352.4H123.414C125.065 352.839 125.392 354.792 125.339 355.764"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1383"
            d="M443.169 232.664C443.239 232.896 443.787 234.918 442.347 235.409C440.361 235.745 438.974 232.165 440.051 230.745C440.198 230.541 440.414 230.395 440.661 230.332C442.047 230.082 442.904 231.683 443.186 232.664"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1384"
            d="M408.111 251.673C408.42 253.644 406.963 255.236 405.286 253.996C403.449 252.628 403.59 249.53 405.515 249.186C407.105 249.014 407.944 250.58 408.111 251.673Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1385"
            d="M334.15 368.424C334.134 369.077 333.879 369.704 333.43 370.19C332.982 370.677 332.369 370.99 331.704 371.074C328.905 371.074 328.56 367.735 330.123 366.272C330.547 365.863 331.108 365.616 331.704 365.575C333.214 365.515 334.291 366.763 334.167 368.424"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1386"
            d="M473.889 216.812C474.587 218.602 474.136 219.997 473.006 219.48C471.452 218.757 470.127 215.332 471.505 214.627C472.255 214.343 473.271 215.281 473.871 216.812"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1387"
            d="M421.339 237.603C421.401 237.835 421.869 239.909 420.236 240.374C418.125 240.632 416.809 237.353 417.913 235.804C418.012 235.655 418.142 235.527 418.294 235.43C418.446 235.332 418.617 235.266 418.796 235.236C420.315 235.03 421.136 236.622 421.375 237.594"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1388"
            d="M478.773 214.557C479.285 215.77 479.338 217.827 478.181 217.431C476.671 216.906 475.126 213.326 476.415 212.561C477.36 212.311 478.322 213.662 478.702 214.566"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1389"
            d="M527.18 336.11C527.021 338.106 526.297 339.733 525.82 339.122C525.202 338.261 525.538 334.655 526.5 333.804C527.101 333.39 527.295 334.664 527.18 336.11Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1390"
            d="M345.338 152.452C345.479 152.908 345.833 154.388 344.543 154.629C342.716 154.698 341.365 151.703 342.336 150.627C342.505 150.444 342.738 150.328 342.989 150.3C343.934 150.206 344.95 151.161 345.356 152.452"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1391"
            d="M410.656 343.123C410.656 343.372 410.558 345.575 408.51 346.04C406.134 346.247 405.384 343.063 406.885 341.35C407.251 340.922 407.761 340.635 408.324 340.541C409.737 340.343 410.7 341.402 410.673 343.123"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1392"
            d="M364.641 150C365.303 151.617 364.473 152.469 363.36 152.005C361.55 151.239 360.543 148.313 361.947 147.883H362.08C362.963 147.762 364.093 148.683 364.641 150.034"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1393"
            d="M380.72 346.97C380.72 349.388 378.256 350.782 376.702 349.276C376.337 348.897 376.082 348.43 375.962 347.924C375.842 347.417 375.862 346.888 376.02 346.392C376.178 345.895 376.468 345.448 376.861 345.096C377.254 344.744 377.735 344.5 378.256 344.388C380.685 344.251 380.72 346.695 380.72 346.97Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1394"
            d="M143.16 484.106C143.601 485.044 143.257 485.552 142.374 485.268C140.74 484.735 138.983 482.351 139.84 481.826C140.038 481.732 140.264 481.714 140.475 481.774C141.634 482.194 142.583 483.032 143.125 484.115"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1395"
            d="M458.824 111.585C459.6 112.217 460.323 112.908 460.988 113.65C459.613 112.594 458.317 111.444 457.111 110.208V110.147C457.739 110.563 458.33 111.029 458.877 111.542"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1396"
            d="M514.994 343.872C514.8 345.946 513.828 347.52 513.228 346.772C512.424 345.816 512.839 342.305 514.111 341.514C514.897 341.118 515.188 342.434 515.056 343.872"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1397"
            d="M349.761 349.93C349.628 352.512 346.961 353.373 345.557 351.918C344.153 350.464 345.107 347.297 347.279 347.125C349.354 347.125 349.814 348.846 349.761 349.93Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1398"
            d="M318.317 351.806C318.07 354.199 315.456 355.137 314.008 353.347C313.68 352.962 313.464 352.498 313.383 352.004C313.301 351.51 313.356 351.004 313.542 350.538C313.729 350.072 314.04 349.663 314.443 349.353C314.846 349.044 315.327 348.845 315.836 348.777C318.017 348.777 318.396 350.774 318.29 351.806"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1399"
            d="M462.373 117.582C463.371 118.443 464.343 119.501 464.246 119.648C463.848 119.648 460.581 116.73 460.36 116.137C460.351 116.115 460.351 116.09 460.36 116.068C460.625 116.016 462.197 117.419 462.373 117.582Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1400"
            d="M382.248 133.356C383.131 134.724 382.822 135.533 381.956 135.344C380.287 134.974 378.327 131.988 379.404 131.446L379.519 131.403C380.587 131.256 381.762 132.616 382.239 133.356"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1401"
            d="M463.222 123.597C464.325 124.647 465.041 125.585 464.785 125.654C464.378 125.757 460.979 122.831 460.926 122.014C461.076 121.652 462.63 123.029 463.222 123.597Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1402"
            d="M520.548 401.692C519.992 402.923 519.374 404.274 519.197 404.601C519.294 404.3 521.149 400.255 521.343 399.876V399.799C521.343 399.954 520.866 400.918 520.513 401.692"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1403"
            d="M461.305 129.415C462.586 130.68 463.071 131.566 462.639 131.575C461.817 131.575 458.63 128.374 458.894 127.815C459.204 127.488 460.608 128.675 461.305 129.415Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1404"
            d="M407.716 125.853C408.713 126.971 408.961 127.901 408.245 127.875C406.877 127.823 404.069 124.803 404.802 124.157L404.89 124.106C405.711 123.899 407.098 125.156 407.716 125.827"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1405"
            d="M382.918 148.019C383.687 149.551 383.095 150.377 382.097 150.042C380.331 149.448 378.83 146.419 380.075 145.937H380.199C381.426 145.791 382.512 147.262 382.918 148.054"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1406"
            d="M456.952 135.224C458.1 136.463 458.409 137.332 457.985 137.367C457.226 137.418 453.8 134.269 454.338 133.477C454.842 133.167 456.272 134.484 456.988 135.198"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1407"
            d="M417.508 121.481C418.832 122.763 419.053 123.606 418.444 123.598C417.225 123.598 414.143 120.568 414.735 119.983L414.797 119.932C415.477 119.742 416.819 120.792 417.499 121.481"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1408"
            d="M449.933 140.628C451.214 142.065 451.39 143.037 450.701 142.942C449.58 142.779 446.631 139.621 447.364 138.95C447.77 138.7 448.874 139.423 449.933 140.628Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1409"
            d="M440.705 145.971C441.861 147.434 441.861 148.381 441.084 148.26C439.707 148.037 437.234 144.818 437.941 144.207L438.011 144.156C438.532 143.923 439.716 144.715 440.661 145.971"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1410"
            d="M114.213 115.938C112.889 117.023 111.935 117.505 112.014 117.066C112.537 116.194 113.245 115.44 114.09 114.854C114.558 114.484 115.944 113.486 116.173 113.761C116.271 114.252 114.558 115.663 114.213 115.938Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1411"
            d="M138.611 375.385C138.611 377.588 136.739 378.078 135.467 376.77C133.781 375.049 134.487 372.158 136.147 372.115C136.259 372.102 136.371 372.102 136.483 372.115C138.196 372.519 138.629 374.344 138.611 375.35"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1412"
            d="M112.589 110.551C112.2 110.844 110.92 111.79 110.752 111.601C110.84 110.741 114.717 108.038 114.929 108.262C114.832 108.796 112.668 110.439 112.58 110.508"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1413"
            d="M489.793 195.85C490.676 197.571 490.464 198.681 489.793 198.431C488.566 198.027 486.658 194.524 487.568 193.707C488.283 193.354 489.334 194.791 489.837 195.832"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1414"
            d="M118.028 121.523C116.589 122.737 115.75 122.986 115.803 122.522C115.891 121.661 119.194 118.649 119.909 119.14C120.156 119.458 119.15 120.585 118.028 121.523Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1415"
            d="M480.273 293.332C480.547 295.639 479.267 296.947 478.154 296.121C476.662 295.028 476.75 291.431 478.278 290.957C479.585 290.639 480.15 292.326 480.273 293.367"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1416"
            d="M113.666 105.044C113.153 105.48 112.579 105.842 111.961 106.12C111.957 106.11 111.955 106.1 111.955 106.09C111.955 106.08 111.957 106.069 111.961 106.06C112.359 105.397 115.697 102.841 116.112 102.884C115.374 103.672 114.551 104.379 113.657 104.993"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1417"
            d="M124.607 126.937C123.159 128.236 122.135 128.52 122.188 127.909C122.276 126.902 125.305 124.062 126.196 124.467C126.541 124.742 125.861 125.801 124.607 126.919"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1418"
            d="M498.235 188.861C498.421 189.205 499.348 190.944 498.915 191.529C498.085 192.105 495.939 188.353 496.098 187.226C496.108 187.13 496.148 187.04 496.213 186.968C496.575 186.65 497.449 187.484 498.244 188.904"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1419"
            d="M133.808 132.203C132.36 133.615 131.159 133.924 131.221 133.141C131.292 131.945 133.994 129.32 135.08 129.699C135.557 129.957 135.027 131.016 133.808 132.203Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1420"
            d="M503.762 181.9C504.645 183.44 504.875 184.481 504.495 184.481C503.789 184.395 501.51 180.79 501.846 179.895C502.164 179.507 503.162 180.893 503.753 181.908"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1421"
            d="M145.305 136.901C143.777 138.622 142.506 138.812 142.479 138.037C142.435 136.806 144.89 134.182 146.126 134.5C147.009 134.956 145.473 136.695 145.296 136.893"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1422"
            d="M104.138 250.055C103.829 250.872 102.779 252.258 101.763 252.094C100.094 251.432 100.809 247.671 102.726 247C102.949 246.925 103.191 246.925 103.414 247C104.651 247.49 104.448 249.332 104.165 250.098"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1423"
            d="M173.332 145.12C173.12 145.412 172.025 146.884 170.886 146.746C169.597 146.316 171.063 143.614 172.652 142.788C172.915 142.626 173.228 142.562 173.535 142.607C174.259 142.796 174.153 143.872 173.279 145.12"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1424"
            d="M312.594 141.376C312.815 142.959 311.791 143.837 310.669 143.02C309.195 141.953 309.177 139.311 310.669 139.139H310.713C311.967 139.139 312.479 140.55 312.621 141.376"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1425"
            d="M140.501 492.566C140.986 493.306 141.092 493.952 140.227 493.754C138.514 493.229 136.695 490.854 137.375 490.458C137.544 490.402 137.727 490.402 137.896 490.458C138.983 490.882 139.909 491.624 140.545 492.583"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1426"
            d="M113.285 370.42C113.223 372.494 111.66 372.899 110.53 371.685C108.764 369.783 109.426 366.806 111.122 366.969H111.298C112.482 367.262 113.338 368.733 113.285 370.412"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1427"
            d="M207.107 151.118C206.18 152.977 204.502 153.373 204.149 152.211C203.752 150.886 205.235 148.493 206.798 148.579C207.681 148.708 207.805 149.827 207.152 151.118"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1428"
            d="M436.22 92.8505C435.337 92.2051 433.191 90.6905 432.343 90.114L432.264 90.0537L432.414 90.1656L436.22 92.8505Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1429"
            d="M331.049 139.931C331.102 140.12 331.57 141.781 330.272 142.005C328.594 142.005 327.394 139.157 328.303 138.15C328.462 137.981 328.679 137.874 328.912 137.848C329.795 137.788 330.678 138.709 331.076 139.931"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1430"
            d="M512.936 279.443C513.227 281.483 512.565 282.98 511.691 282.257C510.604 281.353 510.189 277.885 511.32 277.094C512.291 276.663 512.803 278.677 512.918 279.486"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1431"
            d="M90.1945 364.353C90.1945 366.375 88.9759 366.874 87.9516 365.936C86.3533 364.473 86.3887 360.997 87.9958 360.971C88.1119 360.972 88.2275 360.986 88.3402 361.014C89.391 361.332 90.1857 362.735 90.1945 364.353Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1432"
            d="M438.558 94.7432C439.441 95.4058 440.722 96.3438 440.889 96.5159C440.571 96.4041 437.304 94.0204 436.819 93.6073L436.766 93.5557C437.376 93.9234 437.965 94.3226 438.532 94.7518"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1433"
            d="M441.579 125.939C442.727 127.057 443.248 128.03 442.718 128.055C441.667 128.124 438.426 124.983 438.903 124.364C439.415 124.088 440.855 125.284 441.552 125.956"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1434"
            d="M436.803 102.376C438.277 103.435 438.94 104.192 438.622 104.201C437.968 104.201 434.304 101.559 434.313 101.068C434.436 100.793 435.867 101.697 436.803 102.376Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1435"
            d="M156.775 156.711C155.565 158.433 154.126 158.734 153.914 157.744C153.613 156.264 155.724 153.76 157.093 153.975C158.126 154.345 157.34 155.928 156.775 156.711Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1436"
            d="M360.825 165.309C361.364 167.133 360.207 167.968 358.945 167.262C357.011 166.187 356.711 163.424 358.106 163.046H358.256C359.28 162.934 360.402 163.949 360.825 165.343"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1437"
            d="M424.422 135.851C425.517 137.168 425.526 138.037 424.802 137.934C423.363 137.727 420.784 134.681 421.517 134.061C421.542 134.038 421.573 134.021 421.605 134.01C422.374 133.786 423.707 134.982 424.422 135.851Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1438"
            d="M225.033 77.9889C223.629 78.8495 222.737 79.1249 222.781 78.8495C222.852 78.3332 225.933 76.3281 226.763 76.4486C227.196 76.6637 225.05 77.9889 224.997 77.9976"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1439"
            d="M436.043 97.2214C436.829 97.7635 438.171 98.7445 438.206 99.0371C437.968 99.2523 434.127 96.6448 433.791 96.0596V96.0166C434.592 96.2933 435.338 96.7006 435.999 97.2214"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1440"
            d="M339.607 167.572C339.969 169.293 338.847 170.334 337.496 169.516C335.73 168.458 335.545 165.549 337.187 165.257H337.258C338.715 165.214 339.421 166.694 339.607 167.58"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1441"
            d="M113.32 335.731C113.082 337.874 111.44 338.373 110.38 337.168C108.773 335.335 109.665 332.203 111.334 332.246C111.426 332.251 111.518 332.265 111.607 332.289C113.206 332.753 113.435 334.672 113.32 335.731Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1442"
            d="M440.511 96.5674C441.288 97.1001 442.034 97.6747 442.745 98.2885C441.304 97.3793 439.927 96.3789 438.621 95.2938V95.2422C439.302 95.6157 439.952 96.0416 440.564 96.5158"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1443"
            d="M434.011 130.929C434.17 131.11 435.619 132.65 435.071 133.098C434.55 133.296 433.455 132.495 432.678 131.747C431.901 130.998 431.053 129.836 431.239 129.415C431.261 129.37 431.294 129.332 431.336 129.303C431.777 129.088 432.899 129.776 433.985 130.938"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1444"
            d="M480.272 323.331C480.272 325.37 478.913 327.065 477.765 325.912C476.617 324.759 476.82 321.429 478.48 320.749C479.628 320.413 480.246 321.799 480.246 323.331"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1445"
            d="M108.809 136.024C108.535 136.308 107.113 137.676 106.522 137.375C105.842 136.514 109.294 133.39 110.142 133.476L110.283 133.528C110.645 133.881 109.771 135.068 108.809 136.024Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1446"
            d="M398.665 144.277C398.788 144.466 399.83 146.101 398.806 146.471C397.216 146.721 395.018 143.588 395.653 142.573C395.688 142.518 395.735 142.47 395.789 142.433C395.844 142.396 395.906 142.37 395.971 142.358C396.996 142.177 398.152 143.485 398.673 144.277"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1447"
            d="M433.049 92.1613C433.874 92.6587 434.647 93.2353 435.354 93.8824C433.829 93.072 432.392 92.1149 431.062 91.0254C431.767 91.3583 432.441 91.7499 433.076 92.1957"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1448"
            d="M415.388 337.96C415.388 339.75 413.304 342.099 411.573 340.327C410.054 338.778 410.858 335.912 412.942 335.464C414.293 335.249 415.414 336.394 415.388 337.96Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1449"
            d="M475.294 306.885C475.409 308.339 474.358 310.93 472.883 309.682C471.409 308.434 471.612 305.207 473.263 304.57C474.243 304.286 475.179 305.353 475.294 306.885Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1450"
            d="M412.492 140.172C412.633 140.353 413.843 141.97 412.978 142.383C411.529 142.685 409.022 139.372 409.569 138.52C409.624 138.445 409.701 138.388 409.79 138.356C410.673 138.158 411.918 139.397 412.501 140.163"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1451"
            d="M255.393 351.995C255.377 352.633 255.111 353.24 254.649 353.692C254.188 354.143 253.566 354.405 252.912 354.422C250.263 354.13 249.803 350.722 251.631 349.362C252.026 349.054 252.521 348.892 253.026 348.906C253.398 348.941 253.759 349.05 254.085 349.228C254.411 349.405 254.695 349.647 254.921 349.938C255.146 350.228 255.306 350.561 255.392 350.915C255.478 351.27 255.487 351.637 255.419 351.995"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1452"
            d="M427.75 87.1701C428.765 87.8241 430.002 88.6588 430.134 88.7879C429.975 88.7879 426.487 86.5075 425.94 86.0944L425.869 86.0342C426.517 86.3797 427.144 86.7589 427.75 87.1701Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1453"
            d="M418.84 320.009C418.769 322.349 416.659 323.623 415.255 322.169C413.851 320.714 414.372 317.676 416.465 317.298C418.487 317.074 418.866 319.174 418.84 320.009Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1454"
            d="M117.356 141.755C115.917 143.304 114.707 143.639 114.707 142.882C114.707 141.703 117.356 138.803 118.46 139.251C119.113 139.81 117.515 141.557 117.338 141.755"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1455"
            d="M429.076 150.894C430.18 152.417 430.038 153.389 429.173 153.243C427.628 152.976 425.367 149.723 426.294 149.104C426.324 149.082 426.357 149.064 426.392 149.052C427.01 148.828 428.158 149.629 429.041 150.894"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1456"
            d="M425.56 117.066C426.832 118.22 427.141 118.968 426.708 119.028C425.772 119.166 422.363 116.214 422.699 115.586L422.752 115.535C423.317 115.276 424.836 116.447 425.56 117.101"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1457"
            d="M431.442 112.324C432.767 113.408 433.208 114.166 432.855 114.234C432.502 114.303 431.336 113.641 430.285 112.78C429.65 112.255 428.519 111.171 428.714 110.835C428.908 110.5 430.162 111.257 431.442 112.324Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1458"
            d="M415.256 155.472C415.379 155.67 416.439 157.383 415.432 157.83C413.772 158.14 411.556 154.672 412.28 153.751C412.357 153.661 412.458 153.593 412.571 153.553C413.392 153.338 414.549 154.354 415.264 155.472"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1459"
            d="M286.757 352.331C286.598 354.869 283.861 355.601 282.492 354.052C280.965 352.288 282.095 349.405 284.32 349.379C286.386 349.474 286.828 351.289 286.757 352.356"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1460"
            d="M444.405 101.86C445.194 102.404 445.919 103.03 446.569 103.727C446.357 103.917 442.736 101.232 442.366 100.612C442.361 100.602 442.359 100.592 442.359 100.582C442.359 100.572 442.361 100.561 442.366 100.552C443.096 100.895 443.78 101.323 444.405 101.825"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1461"
            d="M443.353 100.053C444.159 100.602 444.899 101.237 445.56 101.946C444.043 101.102 442.643 100.073 441.393 98.8829C441.393 98.8829 441.393 98.8829 441.393 98.8398C442.108 99.1499 442.774 99.5585 443.371 100.053"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1462"
            d="M399.388 159.826C400.156 161.401 399.538 162.279 398.505 161.943C396.739 161.358 395.211 158.217 396.483 157.641H396.615C397.569 157.426 398.796 158.613 399.388 159.826Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1463"
            d="M435.238 107.419C436.616 108.486 437.181 109.244 436.837 109.278C436.201 109.347 432.474 106.696 432.58 106.016C432.854 105.758 434.346 106.722 435.229 107.428"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1464"
            d="M81.0652 309.261C80.7208 311.137 79.4139 311.842 78.5221 310.577C77.2505 308.813 78.0364 305.414 79.6612 305.672H79.829C81.2065 306.231 81.233 308.254 81.0475 309.209"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1465"
            d="M364.34 82.162C365.532 82.859 366.159 83.4442 365.806 83.5044C365.108 83.6249 361.09 81.3358 361.329 80.9228C361.797 80.8109 363.368 81.6026 364.304 82.162"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1466"
            d="M100.244 124.234C99.9531 124.492 98.4784 125.766 98.293 125.456C98.293 125.009 99.5116 123.787 99.9796 123.348C101.26 122.16 102.072 121.687 102.214 121.833C102.214 122.186 101.242 123.279 100.218 124.2"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1467"
            d="M381.223 163.2C382.106 165.05 381.153 165.885 380.128 165.541C378.318 164.938 377.082 161.944 378.362 161.238C378.448 161.199 378.536 161.167 378.627 161.143C380.181 160.989 381.117 162.951 381.223 163.174"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1468"
            d="M519.003 306.507C519.056 307.531 518.836 309.201 518.12 309.7C517.034 310.122 516.407 306.688 517.237 304.864C517.331 304.628 517.49 304.423 517.697 304.27C518.403 303.951 518.906 305.173 518.977 306.499"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1469"
            d="M504.433 226.502C505.113 228.387 504.786 229.634 504.044 229.333C502.852 228.851 501.484 225.168 502.473 224.325C503.011 223.998 503.877 224.953 504.433 226.502Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1470"
            d="M519.074 288.195C519.295 290.131 518.739 291.715 518.015 291.207C516.999 290.51 516.575 286.723 517.679 285.88C518.368 285.476 518.907 286.741 519.074 288.195Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1471"
            d="M138.213 449.34C138.505 450.795 137.418 451.32 136.059 450.382C134.292 449.177 133.736 446.767 135.176 446.535C135.354 446.504 135.536 446.504 135.714 446.535C136.351 446.741 136.921 447.107 137.366 447.597C137.812 448.086 138.116 448.682 138.248 449.323"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1472"
            d="M162.311 453.394C162.682 455.115 161.304 455.597 159.971 454.736C158.099 453.54 157.675 451.165 159.273 450.89C159.449 450.864 159.628 450.864 159.803 450.89C160.422 451.033 160.986 451.344 161.43 451.787C161.874 452.231 162.179 452.788 162.311 453.394Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1473"
            d="M128.342 147.4C127.088 148.803 125.754 149.268 125.693 148.33C125.631 147.056 128.086 144.32 129.295 144.681C130.037 145.146 128.986 146.66 128.324 147.4"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1474"
            d="M103.026 130.267C101.71 131.463 100.995 131.712 100.986 131.334C100.986 130.413 104.395 127.237 104.898 127.719C105.101 128.201 103.759 129.595 103.026 130.301"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1475"
            d="M510.331 293.281C510.419 294.546 509.916 297.041 508.847 296.095C507.638 295.027 507.558 291.628 508.733 290.888C509.616 290.553 510.243 292.067 510.322 293.281"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1476"
            d="M161.836 430.821C161.906 432.439 160.202 433.214 158.63 431.733C156.864 430.098 157.429 427.835 159.089 427.87H159.293C159.963 428.066 160.56 428.448 161.011 428.969C161.463 429.49 161.749 430.127 161.836 430.804"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1477"
            d="M179.62 433.084C179.788 435.047 177.854 435.485 176.441 434.298C174.764 432.895 174.993 430.4 176.821 430.314C176.93 430.303 177.039 430.303 177.148 430.314C177.808 430.453 178.405 430.797 178.851 431.293C179.296 431.788 179.566 432.411 179.62 433.067"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1478"
            d="M255.603 86.4993C254.95 87.6266 253.934 88.2204 253.793 87.5836C253.59 86.6714 255.012 84.8815 255.745 84.8643C256.195 84.9159 256.036 85.7248 255.603 86.4993Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1479"
            d="M441.518 238.928C441.906 240.486 441.227 242.371 439.752 241.562C437.677 240.452 437.403 236.949 438.931 236.45C440.22 236.235 441.209 237.775 441.5 238.937"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1480"
            d="M426.867 242.362C427.123 243.807 426.231 245.804 424.571 244.806C422.311 243.377 422.637 240.219 424.165 239.754C425.339 239.651 426.62 241.105 426.814 242.336"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1481"
            d="M252.478 86.233C251.639 87.5152 250.765 87.7992 250.642 87.3087C250.465 86.603 251.922 84.5549 252.787 84.5635C253.185 84.6151 253.052 85.3466 252.478 86.233Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1482"
            d="M153.067 429.453C153.226 431.303 151.54 431.648 150.206 430.538C148.581 429.204 148.573 426.674 150.206 426.502C150.35 426.486 150.495 426.486 150.639 426.502C151.298 426.703 151.881 427.089 152.318 427.61C152.754 428.132 153.024 428.767 153.094 429.436"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1483"
            d="M136.35 426.596C136.482 428.179 135.087 428.747 133.701 427.663C131.829 426.157 131.935 423.662 133.436 423.533C133.556 423.515 133.678 423.515 133.798 423.533C134.474 423.761 135.071 424.169 135.522 424.71C135.973 425.251 136.259 425.904 136.35 426.596Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1484"
            d="M128.166 424.823C128.422 426.544 127.08 426.992 125.843 426.08C124.077 424.798 123.786 421.975 125.358 421.829C125.495 421.808 125.635 421.808 125.773 421.829C126.406 422.07 126.962 422.471 127.383 422.991C127.803 423.512 128.074 424.133 128.166 424.789"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1485"
            d="M448.52 237.276C448.776 238.523 448.237 240.718 446.656 239.745C444.669 238.515 444.431 235.245 445.888 234.703C446.815 234.47 448.21 235.847 448.537 237.284"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1486"
            d="M461.306 233.351C461.827 235.072 461.208 236.751 459.893 236.028C457.924 234.935 457.473 231.364 458.842 230.933C459.963 230.692 460.979 232.241 461.306 233.351Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1487"
            d="M467.364 231.467C467.655 232.569 467.452 234.798 466.066 234.049C464.096 233.033 463.54 229.565 464.847 228.946C465.633 228.696 467.002 230.056 467.364 231.441"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1488"
            d="M311.659 288.048C311.491 290.432 308.957 291.077 307.65 289.589C306.175 287.868 307.147 285.045 309.239 284.959C311.173 284.959 311.747 286.826 311.659 288.048Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1489"
            d="M321.787 287.489C321.707 289.632 319.279 291.009 317.672 289.21C316.065 287.412 317.495 284.675 319.208 284.598C319.936 284.659 320.611 284.993 321.091 285.529C321.572 286.064 321.821 286.761 321.787 287.472"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1490"
            d="M341.612 286.439C341.612 288.891 339.166 289.726 337.744 288.315C336.163 286.74 337.002 283.728 339.068 283.573C339.43 283.595 339.783 283.687 340.108 283.843C340.432 284 340.721 284.218 340.958 284.485C341.195 284.752 341.375 285.062 341.487 285.398C341.599 285.733 341.642 286.087 341.612 286.439Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1491"
            d="M146.064 496.766C146.497 497.428 146.514 498.031 145.684 497.841C144.174 497.488 142.02 495.07 142.841 494.64C142.915 494.609 142.995 494.594 143.075 494.594C143.155 494.594 143.235 494.609 143.309 494.64C144.42 495.076 145.377 495.818 146.064 496.774"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1492"
            d="M478.268 227.251C478.807 228.86 478.471 230.487 477.385 229.996C475.69 229.247 474.639 225.616 475.911 224.893C476.882 224.618 477.889 226.141 478.268 227.26"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1493"
            d="M483.381 225.159C483.734 226.286 483.699 228.455 482.498 227.818C480.732 226.854 479.919 223.412 481.014 222.75C481.668 222.491 482.957 223.799 483.39 225.159"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1494"
            d="M261.803 87.1014C261.211 88.3578 260.416 88.6332 260.196 88.1427C259.895 87.437 260.929 85.3889 261.794 85.3545C262.288 85.4492 262.165 86.3269 261.794 87.0756"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1495"
            d="M156.598 507.152C157.146 507.849 157.128 508.245 156.536 508.159C155.159 507.952 152.695 505.62 153.437 505.216C153.583 505.166 153.742 505.166 153.887 505.216C154.956 505.612 155.893 506.284 156.598 507.16"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1496"
            d="M492.255 220.546C492.617 221.467 492.935 222.99 492.255 223.455C490.878 223.911 489.2 219.875 489.827 218.602C489.877 218.496 489.957 218.407 490.056 218.343C490.63 218.068 491.637 219.101 492.229 220.546"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1497"
            d="M60.2338 212.656C59.3507 214.437 58.4677 214.506 58.2646 213.869C57.7701 212.51 59.6598 208.887 60.6664 209.257L60.7548 209.3C61.2757 209.825 60.8784 211.417 60.2338 212.656Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1498"
            d="M64.0388 215.013C63.3147 216.494 62.1933 217.208 61.8666 216.408C61.2749 214.996 63.1116 211.382 64.2243 211.752L64.3214 211.795C64.8777 212.191 64.6305 213.817 64.0388 215.013Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1499"
            d="M375.651 248.945C375.943 251.045 374.097 252.387 372.464 251.277C370.53 249.952 370.83 246.836 372.923 246.441C374.168 246.303 375.448 247.482 375.651 248.945Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1500"
            d="M72.5519 220.065C71.6689 221.854 70.5298 222.147 70.1766 221.2C69.5761 219.591 71.4835 216.201 72.6932 216.717C73.4703 217.242 73.0729 218.989 72.5431 220.056"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1501"
            d="M77.4342 222.431C76.6306 224.032 75.3414 224.608 74.9352 223.558C74.2817 221.897 76.1184 218.627 77.443 219.075C78.1759 219.565 78.0169 221.235 77.443 222.422"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1502"
            d="M88.0582 226.795C87.1751 228.903 85.7888 229.032 85.3561 228.086C84.5967 226.442 86.451 223.077 87.8374 223.585C88.7204 224.041 88.588 225.521 88.0405 226.795"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1503"
            d="M291.764 288.237C291.649 290.397 289.203 291.757 287.623 289.958C286.042 288.16 287.623 285.389 289.309 285.354C290.027 285.431 290.686 285.778 291.145 286.321C291.604 286.865 291.826 287.562 291.764 288.263"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1504"
            d="M93.8683 228.954C93.1619 230.572 91.5901 231.493 90.9985 230.185C90.1772 228.343 92.2082 225.306 93.4974 225.727C94.5041 226.209 94.3098 227.939 93.8683 228.954Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1505"
            d="M281.654 288.298C281.504 290.767 279.084 291.516 277.715 290.019C276.135 288.349 277.186 285.312 279.331 285.312C281.159 285.415 281.724 287.127 281.654 288.255"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1506"
            d="M60.7021 252.232C60.0045 254.134 58.936 254.203 58.5563 253.411C57.6733 251.845 59.0861 248.248 60.393 248.583L60.499 248.635C61.382 249.22 61.1171 251.105 60.7021 252.232Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1507"
            d="M106.133 233.051C105.294 235.056 103.687 235.426 103.139 234.221C102.353 232.5 104.137 229.324 105.656 229.789C106.636 230.185 106.663 231.76 106.133 233.051Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1508"
            d="M112.711 235.09C111.943 236.811 110.203 237.534 109.612 235.994C108.905 234.186 110.495 231.424 112.128 231.691C113.091 231.983 113.373 233.627 112.711 235.073"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1509"
            d="M132.359 474.787C132.792 475.776 132.359 476.318 131.476 475.983C129.763 475.355 128.129 472.79 129.154 472.325C129.323 472.274 129.505 472.274 129.675 472.325C130.852 472.807 131.812 473.687 132.377 474.804"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1510"
            d="M366.388 250.124C366.583 252.413 364.561 253.325 363.095 252.207C361.329 250.821 361.735 247.646 363.748 247.414C365.249 247.362 366.274 248.747 366.397 250.124"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1511"
            d="M281.662 254.306C281.494 256.672 279.145 257.37 277.891 255.941C276.47 254.315 277.476 251.38 279.454 251.389C281.22 251.501 281.732 253.239 281.662 254.306Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1512"
            d="M506.34 211.314C506.852 212.587 507.064 214.394 506.34 214.059C505.121 213.499 503.638 209.851 504.318 209.05C504.865 208.809 505.907 210.238 506.349 211.314"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1513"
            d="M65.9294 254.556C65.6292 255.416 64.8521 256.647 63.9691 256.337C62.4856 255.416 63.6159 251.509 65.0552 251.131C65.2036 251.079 65.366 251.079 65.5144 251.131C66.3974 251.656 66.3003 253.48 65.9382 254.573"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1514"
            d="M261.838 288.194C261.537 290.32 258.994 291.438 257.599 289.494C257.315 289.098 257.14 288.639 257.089 288.159C257.039 287.68 257.115 287.195 257.31 286.752C257.506 286.309 257.814 285.922 258.206 285.628C258.598 285.333 259.061 285.142 259.55 285.07C260.271 285.205 260.91 285.604 261.336 286.186C261.762 286.768 261.941 287.487 261.838 288.194Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1515"
            d="M96.1285 266.087C95.4751 268.032 93.8944 268.299 93.241 267.077C92.2608 265.235 93.9209 262.094 95.2543 262.455C96.0667 262.808 96.6318 264.59 96.1373 266.087"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1516"
            d="M246.474 85.5351C245.732 86.4731 244.584 87.1787 244.505 86.6022C244.399 85.8019 246.147 83.8742 246.977 83.8828C247.392 83.9517 247.057 84.8122 246.474 85.5351Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1517"
            d="M419.008 243.833C419.203 244.78 419.008 246.311 417.79 246.647C415.715 246.776 414.258 243.36 415.697 241.716C415.874 241.499 416.124 241.35 416.403 241.294C417.825 241.122 418.805 242.671 419.053 243.824"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1518"
            d="M326.706 437.912C326.397 440.124 323.554 440.786 322.406 439.366C322.169 438.995 322.036 438.569 322.021 438.132C322.006 437.695 322.109 437.261 322.32 436.875C322.531 436.489 322.843 436.163 323.224 435.932C323.605 435.7 324.041 435.57 324.49 435.554C324.808 435.52 325.129 435.559 325.429 435.668C325.728 435.778 325.997 435.955 326.213 436.184C326.429 436.414 326.586 436.69 326.672 436.991C326.758 437.291 326.769 437.607 326.706 437.912Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1519"
            d="M243.524 85.1228C242.412 86.4222 241.529 86.6545 241.493 86.1726C241.44 85.5014 243.304 83.4705 244.142 83.4963C244.522 83.5566 244.248 84.2536 243.515 85.1228"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1520"
            d="M237.722 84.3647C236.636 85.3888 235.683 85.733 235.691 85.2855C235.691 84.511 237.846 82.6092 238.667 82.635C239.073 82.7899 238.367 83.7537 237.722 84.3561"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1521"
            d="M402.638 246.828C402.797 248.3 401.675 250.58 399.636 248.98C397.596 247.379 398.479 244.393 399.848 244.161C401.216 243.928 402.497 245.684 402.638 246.828Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1522"
            d="M235.048 83.771C233.723 84.9844 232.822 85.2081 232.875 84.7606C232.955 84.0722 235.18 82.1015 236.019 82.179C236.416 82.265 235.816 83.0395 235.048 83.7882"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1523"
            d="M496.282 218.206C496.777 219.419 496.953 221.536 495.982 221.132C494.357 220.461 493.236 216.674 494.137 216.089C494.808 215.814 495.903 217.165 496.282 218.206Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1524"
            d="M229.651 82.7904C228.485 83.7112 227.382 84.1501 227.47 83.651C227.611 82.9023 230.119 81.0693 230.931 81.0693C231.258 81.2156 230.41 82.145 229.651 82.7388"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1525"
            d="M336.799 437.371C336.663 437.961 336.338 438.494 335.872 438.893C335.405 439.292 334.821 439.536 334.203 439.591C332.11 439.591 331.262 437.474 332.746 435.933C333.22 435.417 333.875 435.091 334.582 435.021C334.896 435.003 335.21 435.053 335.501 435.167C335.793 435.282 336.055 435.457 336.269 435.682C336.482 435.906 336.642 436.174 336.737 436.466C336.831 436.758 336.859 437.067 336.816 437.371"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1526"
            d="M227.047 82.2394C225.722 83.2118 224.848 83.4785 224.865 83.0999C224.865 82.5491 227.647 80.4494 228.486 80.5183C228.901 80.7162 227.603 81.8005 227.047 82.2394Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1527"
            d="M503.251 213.577C503.639 214.437 504.045 215.986 503.568 216.468C502.588 216.89 500.628 213.026 501.105 211.649C501.128 211.565 501.177 211.49 501.246 211.434C501.961 211.047 502.933 212.845 503.251 213.577Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1528"
            d="M142.002 486.834C142.567 487.815 142.346 488.332 141.454 488.091C139.891 487.652 137.966 485.191 138.805 484.649C138.992 484.577 139.201 484.577 139.388 484.649C140.516 485.046 141.457 485.829 142.037 486.852"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1529"
            d="M222.348 80.8975C221.165 81.6978 219.832 82.2744 219.955 81.8097C220.167 81.0352 223.275 79.2281 223.841 79.3399C224.15 79.4948 223.17 80.3468 222.348 80.8975Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1530"
            d="M356.658 436.011C356.172 438.274 353.232 438.894 352.287 437.379C351.342 435.865 352.781 433.799 354.636 433.618C354.949 433.587 355.265 433.631 355.557 433.747C355.848 433.864 356.106 434.048 356.306 434.285C356.506 434.522 356.643 434.804 356.705 435.105C356.766 435.406 356.75 435.717 356.658 436.011Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1531"
            d="M220.061 80.2083C218.357 81.3098 217.589 81.4303 217.598 81.1635C217.598 80.673 220.821 78.5819 221.642 78.6938C222.013 78.9347 220.264 80.0793 220.061 80.2083Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1532"
            d="M366.663 434.978C366.292 436.897 363.51 438.24 362.248 436.622C360.985 435.004 362.927 432.904 364.543 432.741C364.845 432.706 365.152 432.741 365.437 432.844C365.722 432.948 365.978 433.115 366.183 433.334C366.388 433.553 366.537 433.816 366.618 434.102C366.698 434.388 366.707 434.688 366.645 434.978"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1533"
            d="M215.735 78.8232C214.41 79.5977 213.139 80.0452 213.227 79.6838C213.386 79.0642 216.759 77.1624 217.519 77.3001C217.775 77.4206 216.786 78.1606 215.752 78.7974"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1534"
            d="M393.586 246.742C393.921 248.971 392.076 250.012 390.619 249.014C388.8 247.766 388.95 244.548 390.848 244.161H390.901C392.376 244.049 393.383 245.409 393.551 246.742"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1535"
            d="M129.825 463.316C130.355 464.675 129.657 465.037 128.677 464.641C126.911 463.875 125.489 461.121 126.717 460.76C126.898 460.708 127.091 460.708 127.273 460.76C127.86 460.992 128.39 461.342 128.83 461.787C129.269 462.232 129.608 462.761 129.825 463.342"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1536"
            d="M136.995 464.925C137.392 466.035 136.774 466.818 135.476 466.164C133.648 465.235 132.606 462.774 133.878 462.412C134.036 462.368 134.205 462.368 134.363 462.412C134.96 462.629 135.503 462.964 135.959 463.397C136.415 463.829 136.774 464.35 137.012 464.925"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1537"
            d="M151.813 468.006C152.202 469.375 151.177 469.727 150.003 469.116C148.237 468.256 147.354 465.855 148.634 465.468C148.831 465.417 149.038 465.417 149.235 465.468C149.847 465.663 150.401 466 150.85 466.449C151.299 466.899 151.629 467.448 151.813 468.049"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1538"
            d="M73.8404 239.358C72.9574 241.595 71.7035 241.681 71.2708 240.64C70.6262 239.074 72.1008 235.623 73.5667 236.114C74.2907 236.467 74.4497 237.835 73.8404 239.358Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1539"
            d="M79.3507 241.76C78.7414 243.352 77.2579 244.273 76.7016 243.025C75.9775 241.416 77.3815 237.973 78.9621 238.447C79.7922 238.808 79.8452 240.495 79.3683 241.76"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1540"
            d="M518.439 403.362C518.077 404.307 517.594 405.203 516.999 406.029C516.743 405.771 518.562 401.727 519.11 401.116C519.171 401.047 519.189 401.038 519.224 401.038C519.375 401.227 518.483 403.276 518.439 403.362Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1541"
            d="M206.339 88.5817C205.076 89.4423 203.867 89.9156 203.893 89.4939C203.937 88.7625 207.045 86.6627 207.902 86.8004C208.246 86.9897 207.222 87.9708 206.339 88.5817Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1542"
            d="M520.469 400.607C520.06 401.592 519.585 402.55 519.047 403.473C519.584 401.773 520.294 400.13 521.166 398.568L521.228 398.499C521.061 399.231 520.816 399.943 520.495 400.625"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1543"
            d="M522.075 398.258C522.782 396.623 523.471 394.979 524.159 393.336C524.159 393.336 524.159 393.336 524.159 393.284L522.049 398.267"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1544"
            d="M211.955 90.122C210.401 91.3698 209.43 91.5592 209.448 91.1461C209.448 90.4749 212.273 88.2719 213.209 88.401C213.624 88.5386 212.891 89.3648 211.955 90.122Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1545"
            d="M91.1479 246.148C90.3797 248.256 88.8697 248.626 88.2957 247.352C87.4922 245.563 89.1788 242.379 90.6181 242.878C91.7042 243.36 91.5011 245.098 91.1479 246.148Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1546"
            d="M128.554 221.726C127.759 223.378 125.975 224.144 125.366 222.673C124.554 220.693 126.735 218.138 128.015 218.43C129.101 218.8 129.181 220.461 128.572 221.726"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1547"
            d="M121.781 220.013C120.898 221.949 119.202 222.216 118.717 220.942C118.072 219.221 119.909 216.33 121.41 216.709C122.628 217.139 122.293 218.92 121.781 220.013Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1548"
            d="M206.65 92.9103C205.987 93.4439 204.522 94.2356 204.504 94.0721C204.548 93.8914 204.76 93.7967 204.778 93.5988C204.795 93.4008 204.654 93.332 204.725 93.1857C204.795 93.0394 205.299 92.3252 205.299 92.3252C205.299 92.3252 207.648 90.9569 208.071 91.043C208.495 91.129 207.294 92.4284 206.65 92.9448"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1549"
            d="M97.4973 248.377C96.8085 250.098 95.1484 250.907 94.5126 249.358C93.7532 247.499 95.2897 244.634 96.8615 244.969C97.824 245.4 97.9829 247.121 97.4884 248.369"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1550"
            d="M109.224 216.209C108.412 217.93 106.637 218.713 106.169 217.216C105.604 215.374 107.652 212.664 108.906 213.051C109.789 213.438 109.789 215.03 109.224 216.209Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1551"
            d="M185.483 83.857C184.441 84.4164 182.755 85.2167 182.375 85.0962C182.52 84.8976 182.703 84.7281 182.914 84.5971C183.346 84.2959 183.797 84.1066 184.265 83.7968C184.512 83.6161 184.75 83.4182 185.015 83.2547L185.236 83.1256C185.881 82.7769 186.583 82.5383 187.311 82.4199C187.417 82.5748 186.119 83.5472 185.492 83.8915"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1552"
            d="M494.164 199.627C494.746 200.866 494.985 202.639 494.164 202.312C492.769 201.769 491.126 198.207 491.903 197.424C492.459 197.191 493.607 198.439 494.164 199.627Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1553"
            d="M350.131 450.408C349.69 452.49 346.688 453.385 345.672 451.742C344.789 450.27 346.555 448.377 348.047 448.257C348.342 448.217 348.642 448.246 348.922 448.342C349.202 448.438 349.455 448.598 349.659 448.809C349.863 449.019 350.012 449.274 350.094 449.552C350.176 449.831 350.189 450.124 350.131 450.408Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1554"
            d="M513.483 408.258C512.892 409.592 511.806 411.425 511.717 410.986C511.523 410.315 513.139 406.933 513.889 406.193C513.969 406.141 514.154 406.02 514.066 406.554C513.946 407.146 513.76 407.723 513.51 408.275"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1555"
            d="M490.763 201.916C491.505 203.508 491.452 204.859 490.666 204.601C489.421 204.188 487.513 200.488 488.467 199.679C489.147 199.403 490.233 200.806 490.754 201.925"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1556"
            d="M483.072 206.296C483.593 207.552 483.628 209.325 482.683 208.963C481.094 208.361 479.61 204.747 480.644 204.007C481.394 203.792 482.63 205.246 483.072 206.296Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1557"
            d="M478.63 208.413C479.302 209.953 479.16 211.571 478.118 211.115C476.352 210.349 475.231 206.657 476.246 206.167C477.129 205.891 478.171 207.346 478.63 208.413Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1558"
            d="M469.059 212.655C469.492 213.877 469.395 215.848 468.176 215.237C466.234 214.282 465.377 210.934 466.543 210.28C467.24 210.039 468.627 211.338 469.103 212.672"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1559"
            d="M463.638 214.506C464.256 216.063 463.965 217.707 462.817 217.268C460.83 216.511 459.903 212.776 461.121 212.26C462.163 212.002 463.196 213.508 463.603 214.523"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1560"
            d="M452.165 218.344C452.589 219.506 452.342 221.786 450.867 221.046C448.828 220.005 448.218 216.623 449.569 216.055C450.452 215.823 451.724 217.139 452.165 218.344Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1561"
            d="M445.959 220.375C446.436 222.002 445.826 223.49 444.537 222.854C442.595 221.898 441.756 218.422 443.319 217.828C444.202 217.596 445.509 218.835 445.968 220.41"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1562"
            d="M432.624 223.602C432.986 224.781 432.492 227.044 430.929 226.261C428.757 225.151 428.395 221.752 429.949 221.235C430.832 221.037 432.209 222.234 432.598 223.602"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1563"
            d="M425.489 225.349C425.94 227.07 425.012 228.671 423.538 227.81C421.569 226.648 421.109 223.344 422.849 222.819C423.891 222.612 425.145 224.041 425.498 225.349"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1564"
            d="M410.469 228.223C410.708 229.161 410.469 230.71 409.321 231.037C407.255 231.226 405.701 227.844 407.105 226.209C407.266 226.009 407.494 225.872 407.75 225.822C409.207 225.667 410.275 227.336 410.496 228.223"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1565"
            d="M59.386 232.052C58.6354 233.833 57.6199 234.212 57.2844 233.369C56.6221 231.888 58.2469 228.266 59.4037 228.653H59.5096C60.0571 229.014 59.9953 230.555 59.386 232.009"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1566"
            d="M63.827 234.694C63.1382 236.226 62.0609 236.811 61.6018 235.873C60.8335 234.152 62.5731 230.856 63.7122 231.157L63.8182 231.2C64.4539 231.57 64.4539 233.3 63.8182 234.694"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1567"
            d="M385.516 230.968C385.79 232.852 384.209 234.315 382.487 233.085C380.554 231.708 380.986 228.713 382.717 228.403C384.059 228.317 385.313 229.711 385.499 230.985"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1568"
            d="M350.045 234.814C350.283 237.121 348.279 238.041 346.822 236.88C345.365 235.718 345.232 232.525 347.493 232.207C348.782 232.104 349.877 233.222 350.045 234.788"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1569"
            d="M331.986 236.192C332.048 238.188 330.043 239.539 328.454 237.99C326.864 236.441 327.721 233.584 329.469 233.464C330.16 233.541 330.797 233.861 331.264 234.363C331.73 234.865 331.993 235.515 332.003 236.192"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1570"
            d="M491.805 240.09C492.265 241.811 491.885 243.369 490.852 242.783C489.28 241.923 488.432 238.309 489.616 237.62C490.322 237.31 491.382 238.481 491.805 240.124"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1571"
            d="M296.277 271.233C296.082 273.634 293.628 274.254 292.338 272.739C291.049 271.224 291.756 268.359 293.946 268.195C295.597 268.281 296.356 269.83 296.241 271.233"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1572"
            d="M481.518 244.402C481.827 245.624 481.518 247.964 480.193 247.121C478.427 246.019 477.994 242.629 479.204 241.958C480.087 241.76 481.2 243.128 481.518 244.384"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1573"
            d="M475.831 246.647C476.211 248.239 475.672 250.089 474.339 249.229C472.678 248.153 472.113 244.736 473.456 244.065C474.286 243.79 475.407 245.012 475.796 246.647"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1574"
            d="M256.09 97.0064C255.542 98.2112 254.474 98.8307 254.173 98.1165C253.758 97.1097 255.101 95.0788 255.94 95.139C256.478 95.2423 256.487 96.2061 256.125 97.0064"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1575"
            d="M463.549 250.545C463.778 251.518 463.602 253.084 462.666 253.471C460.847 253.781 459.363 250.029 460.661 248.428C460.773 248.287 460.923 248.18 461.094 248.119C462.18 247.912 463.293 249.435 463.549 250.537"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1576"
            d="M306.149 270.949C306.008 272.989 303.738 274.271 302.184 272.619C300.63 270.966 301.584 268.221 303.659 268.015C304.379 268.101 305.037 268.454 305.497 269C305.957 269.546 306.184 270.244 306.131 270.949"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1577"
            d="M456.89 252.766C456.89 252.852 457.287 254.951 456.007 255.468C454.152 255.795 452.642 252.086 453.967 250.442C454.09 250.273 454.267 250.149 454.47 250.089C455.91 249.814 456.757 251.81 456.943 252.766"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1578"
            d="M252.231 96.7659C251.551 98.0396 250.588 98.4354 250.341 97.8072C250.023 97.0069 251.224 94.8211 252.24 94.8125C252.778 94.8641 252.769 95.7419 252.24 96.7659"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1579"
            d="M383.149 265.338C383.308 267.722 381.021 268.643 379.617 267.309C378.213 265.975 378.398 263.006 380.5 262.593C382.107 262.499 383.087 263.936 383.149 265.338Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1580"
            d="M442.761 256.303C442.991 257.774 442.099 259.882 440.51 258.841C438.346 257.421 438.744 254.186 440.174 253.678C441.313 253.557 442.541 255.012 442.744 256.26"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1581"
            d="M392.544 264.082C392.712 266.303 390.636 267.525 389.012 266.13C387.131 264.53 388.129 261.638 389.709 261.397C390.439 261.418 391.134 261.706 391.656 262.203C392.178 262.701 392.489 263.372 392.526 264.082"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1582"
            d="M435.097 258.135C435.38 260.08 434.082 261.621 432.545 260.424C430.779 259.065 430.779 255.924 432.545 255.407C433.631 255.175 434.876 256.5 435.106 258.135"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1583"
            d="M244.875 95.9996C244.089 97.2044 242.958 97.7207 242.817 96.9893C242.64 96.0341 244.133 94.1151 245.069 94.1064C245.616 94.2355 245.325 95.294 244.866 95.9996"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1584"
            d="M241.237 95.5518C240.248 96.989 239.232 97.2127 239.144 96.5845C239.011 95.6551 240.733 93.607 241.563 93.6931C242.093 93.8135 241.802 94.7257 241.237 95.5518Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1585"
            d="M419.105 261.371C419.229 262.8 418.019 265.14 416.12 263.514C414.222 261.887 414.866 259.039 416.412 258.626C417.551 258.437 418.99 259.9 419.105 261.371Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1586"
            d="M410.523 262.68C410.788 264.521 409.437 266.44 407.644 265.089C405.569 263.523 406.311 260.339 407.9 260.063C409.49 259.788 410.364 261.587 410.549 262.645"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1587"
            d="M220.857 92.3255C219.603 93.47 218.597 93.7884 218.57 93.2893C218.57 92.4976 221.034 90.3807 221.917 90.4581C222.367 90.6216 221.705 91.5424 220.857 92.3255Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1588"
            d="M92.1286 210.177C91.3074 211.691 89.8062 212.492 89.4 211.235C88.8614 209.514 90.7687 206.58 92.1551 206.932C92.8968 207.354 92.7467 209.015 92.1551 210.16"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1589"
            d="M509.873 414.377C509.184 415.9 508.195 417.38 508.054 417.122C507.798 416.64 509.202 413.43 510.182 412.57C510.279 412.509 510.518 412.346 510.412 412.888C510.275 413.399 510.092 413.897 509.864 414.377"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1590"
            d="M86.9797 207.932C85.9731 209.808 84.675 210.126 84.3306 209.059C83.845 207.432 85.9024 204.266 87.121 204.756C87.8804 205.204 87.6155 206.684 86.9532 207.914"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1591"
            d="M224.16 92.8933C223.621 93.4268 222.606 94.2013 222.014 94.1497C221.281 93.9001 222.897 91.9639 224.398 91.241C224.583 91.1359 224.794 91.0823 225.008 91.0861C225.476 91.2496 224.805 92.2565 224.125 92.8933"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1592"
            d="M77.7092 203.353C77.25 204.162 76.2875 205.272 75.5369 204.962C74.2477 203.99 76.42 200.221 77.7445 200.135C77.8398 200.129 77.9347 200.15 78.0182 200.195C78.6364 200.642 78.3538 202.217 77.718 203.353"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1593"
            d="M73.4788 200.986C72.481 202.707 71.4125 203.052 71.1829 202.157C70.7855 200.616 72.949 197.26 73.9645 197.854C74.5561 198.327 74.1676 199.799 73.4788 201.012"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1594"
            d="M230.693 94.1324C229.492 95.4749 228.538 95.6384 228.512 95.0962C228.512 94.3045 230.446 92.1876 231.408 92.2392C231.911 92.3855 231.408 93.3235 230.693 94.1324Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1595"
            d="M66.3435 196.253C65.5223 197.587 64.5774 198.181 64.339 197.501C63.8975 196.227 66.0168 192.639 66.9881 192.983L67.0676 193.026C67.4826 193.508 67.0234 195.126 66.3258 196.253"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1596"
            d="M303.314 97.2815C303.624 98.3658 303.147 99.0973 302.431 98.667C301.283 98.013 300.886 95.7411 301.646 95.5088H301.725C302.078 95.5088 303.12 96.4898 303.35 97.2901"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1597"
            d="M63.4116 193.698C62.4668 195.29 61.6456 195.651 61.4778 195.066C61.0716 193.861 63.2439 190.144 64.1269 190.548C64.1539 190.56 64.178 190.578 64.1975 190.6C64.5507 190.953 64.1975 192.321 63.3763 193.698"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1598"
            d="M514.438 225.719C514.977 227.251 515.03 228.834 514.323 228.481C513.325 227.982 511.895 224.178 512.619 223.318C513.016 222.999 513.899 224.178 514.438 225.702"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1599"
            d="M299.297 97.4287C299.35 97.6783 299.606 98.8917 298.784 99.0121C297.619 98.9691 296.886 96.4821 297.566 95.8281C297.606 95.7854 297.656 95.7511 297.71 95.7275C297.765 95.7038 297.824 95.6912 297.884 95.6904C298.519 95.6904 299.093 96.551 299.305 97.4115"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1600"
            d="M508.328 230.581C508.716 231.829 508.831 233.834 507.948 233.404C506.544 232.715 505.564 228.817 506.35 228.241C506.994 227.922 507.966 229.428 508.328 230.564"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1601"
            d="M504.645 232.896C505.202 234.496 505.096 236.235 504.23 235.83C502.711 235.107 501.811 231.226 502.694 230.667C503.436 230.331 504.283 231.872 504.645 232.922"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1602"
            d="M276.886 271.147C276.853 271.762 276.596 272.346 276.161 272.793C275.725 273.239 275.14 273.519 274.511 273.582C272.224 273.427 271.394 270.381 273.204 268.78C273.576 268.44 274.063 268.244 274.572 268.229C275.271 268.332 275.902 268.692 276.335 269.235C276.769 269.778 276.973 270.462 276.904 271.147"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1603"
            d="M496.46 237.655C496.857 238.971 496.734 241.097 495.648 240.495C494.058 239.634 493.343 236.063 494.314 235.331C495.03 235.082 496.08 236.407 496.451 237.663"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1604"
            d="M234.128 94.7001C233.184 95.8102 232.053 96.2835 232.009 95.6037C231.947 94.6226 233.987 92.6606 234.729 92.7553C235.241 92.8929 234.817 93.8912 234.128 94.7001Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1605"
            d="M190.295 101.49C189.889 101.826 187.575 103.41 187.646 102.532C187.829 101.937 188.204 101.414 188.715 101.043C189.617 100.394 190.599 99.8559 191.637 99.4424C191.805 99.6059 190.957 100.94 190.278 101.49"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1606"
            d="M307.968 220.926C308.038 223.172 305.981 224.006 304.674 222.776C303.367 221.545 303.482 218.473 305.725 218.258C306.917 218.258 307.924 219.428 307.968 220.926Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1607"
            d="M316.825 220.676C316.825 222.75 314.802 223.739 313.407 222.397C311.791 220.805 312.692 218.172 314.44 217.974C315.109 218.074 315.718 218.407 316.154 218.912C316.589 219.417 316.821 220.059 316.807 220.719"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1608"
            d="M334.219 219.6C334.422 221.778 332.559 222.69 331.182 221.64C329.416 220.315 329.795 217.251 331.712 217.027H331.809C333.398 217.027 334.122 218.568 334.219 219.609"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1609"
            d="M342.98 218.989C343.071 219.542 342.937 220.108 342.608 220.568C342.278 221.027 341.778 221.343 341.214 221.45C339.068 221.45 337.849 218.378 339.448 216.872C339.707 216.605 340.06 216.442 340.437 216.416C341.107 216.485 341.73 216.781 342.198 217.253C342.667 217.725 342.949 218.343 342.998 218.998"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1610"
            d="M360.155 217.587C360.394 219.764 358.495 220.564 357.118 219.437C355.564 218.155 355.546 215.237 357.524 214.902C358.795 214.781 359.97 215.986 360.173 217.587"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1611"
            d="M368.738 216.623C368.95 218.456 367.352 219.772 365.692 218.481C363.723 216.958 364.473 214.179 366.001 213.972C367.423 213.903 368.588 215.315 368.738 216.623Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1612"
            d="M385.365 214.454C385.763 216.502 384.182 217.423 382.769 216.528C381.056 215.435 380.685 212.277 382.637 211.864C383.838 211.7 385.065 212.862 385.365 214.446"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1613"
            d="M393.806 214.437C394.124 215.883 393.215 217.879 391.413 216.718C389.17 215.315 389.48 212.243 390.998 211.916C392.217 211.83 393.524 213.224 393.789 214.437"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1614"
            d="M387.043 91.4475C387.846 91.9724 388.906 92.8588 388.712 93.0911C388.27 93.1944 386.76 92.3941 386.063 91.938C384.915 91.2151 383.943 90.3374 384.093 90.1653C384.491 90.0448 386.133 90.8623 387.043 91.4647"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1615"
            d="M409.021 211.821C409.48 213.542 408.527 215.048 406.964 214.11C404.809 212.802 404.871 209.583 406.222 209.282C407.608 209.119 408.694 210.711 408.986 211.864"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1616"
            d="M416.272 210.28C416.66 211.623 416.095 213.568 414.506 212.733C412.184 211.528 412.104 208.241 413.508 207.854C414.718 207.716 415.981 209.222 416.29 210.28"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1617"
            d="M385.224 92.7036C386.222 93.4007 386.99 94.1924 386.602 94.2612C385.763 94.3989 381.798 91.7915 382.089 91.2923C382.584 91.1719 384.209 92.0582 385.189 92.7381"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1618"
            d="M429.869 207.156C430.443 208.809 429.799 210.358 428.377 209.618C426.276 208.525 425.728 205.203 427.159 204.755C428.368 204.566 429.49 206.081 429.861 207.156"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1619"
            d="M436.298 205.435C436.634 206.296 436.643 207.828 435.768 208.163C434.885 208.301 433.746 207.139 433.367 206.442C433.088 205.993 432.927 205.485 432.896 204.962C432.865 204.438 432.966 203.916 433.19 203.439C433.287 203.299 433.43 203.196 433.596 203.146C434.665 202.974 435.919 204.385 436.307 205.427"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1620"
            d="M271.454 87.5404C271.127 88.8054 270.377 89.1152 270.05 88.6419C269.547 87.9019 270.297 85.8193 271.074 85.8193C271.622 85.8968 271.622 86.8778 271.454 87.5404Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1621"
            d="M71.0073 177.984C70.1243 179.249 69.1618 179.903 68.994 179.335C68.6673 178.174 71.1751 174.525 71.9875 175.033C72.3319 175.514 71.6255 177.063 70.9808 178.001"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1622"
            d="M76.6219 182.666C76.3216 183.122 75.2797 184.585 74.5821 184.198C73.6991 183.294 76.1274 179.654 77.2312 179.602C77.3166 179.596 77.4013 179.621 77.4696 179.671C77.9553 180.136 77.3813 181.556 76.6395 182.666"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1623"
            d="M79.9695 185.161C79.007 186.529 77.9032 187.037 77.7266 186.185C77.4352 184.696 79.7223 181.512 80.7554 181.968C81.2499 182.33 80.8702 183.879 79.9695 185.161Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1624"
            d="M520.565 391.821C520.241 392.853 519.759 393.83 519.135 394.721C519.055 394.773 518.834 394.936 518.94 394.214C519.254 392.612 519.887 391.086 520.804 389.722C520.865 389.661 521.06 389.498 521.033 389.988C520.95 390.615 520.793 391.23 520.565 391.821Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1625"
            d="M87.9435 189.644C86.8574 191.365 85.7006 191.606 85.4711 190.694C85.1267 189.309 87.2371 186.03 88.491 186.503C89.1533 186.959 88.7029 188.465 87.9347 189.636"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1626"
            d="M92.553 191.736C91.6699 193.138 90.2571 193.982 89.9039 192.949C89.3299 191.271 91.7936 188.224 92.8797 188.698C93.5508 189.128 93.2329 190.608 92.553 191.736Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1627"
            d="M523.162 389.265C522.747 390.419 521.952 392.165 521.696 392.208C521.317 391.942 522.791 387.983 523.462 387.14C523.524 387.088 523.657 386.976 523.63 387.372C523.537 388.021 523.374 388.658 523.144 389.274"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1628"
            d="M102.266 196.013C101.171 197.846 99.7669 198.113 99.5108 196.977C99.1752 195.479 101.127 192.485 102.531 192.88C103.211 193.173 103.087 194.602 102.266 196.013Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1629"
            d="M527.171 384.016C526.851 385.043 526.438 386.039 525.935 386.994C525.749 386.692 527.356 382.407 527.701 381.83L527.763 381.753C527.651 382.515 527.452 383.262 527.171 383.982"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1630"
            d="M119.034 201.744C117.886 203.827 116.385 203.827 116.049 202.717C115.626 201.228 117.418 198.293 118.946 198.612C119.899 198.999 119.705 200.514 119.034 201.744Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1631"
            d="M125.225 203.43C124.28 205.246 122.523 205.831 122.09 204.48C121.516 202.682 123.644 200.005 124.943 200.375C125.905 200.754 125.764 202.406 125.225 203.43Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1632"
            d="M528.592 381.409C528.168 382.614 527.594 384.154 527.471 384.335C527.952 382.581 528.541 380.857 529.237 379.172C529.06 379.939 528.836 380.695 528.566 381.435"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1633"
            d="M138.02 206.709C137.049 208.783 135.248 209.119 134.806 207.768C134.259 206.09 136.139 203.336 137.596 203.672C138.638 204.016 138.594 205.496 138.02 206.709Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1634"
            d="M144.864 208.19C144.087 209.954 142.215 210.883 141.544 209.36C140.811 207.587 142.842 204.911 144.237 205.204C145.288 205.531 145.385 207.011 144.864 208.19Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1635"
            d="M281.661 221.321C281.538 223.559 279.471 224.29 278.262 223.042C277.052 221.795 277.379 218.697 279.613 218.576C280.858 218.576 281.741 219.764 281.661 221.313"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1636"
            d="M290.492 221.407C290.324 223.352 288.258 224.496 286.898 222.861C285.538 221.226 286.43 218.705 288.311 218.559C289.68 218.645 290.58 220.133 290.475 221.407"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1637"
            d="M220.662 104.184C219.611 105.44 218.278 105.991 218.233 105.174C218.18 104.055 220.326 102.05 221.174 102.162C221.721 102.325 221.342 103.358 220.662 104.184Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1638"
            d="M216.759 103.504C215.479 104.967 214.34 105.156 214.34 104.494C214.34 103.487 216.556 101.327 217.439 101.49C217.996 101.662 217.501 102.661 216.759 103.504Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1639"
            d="M209.403 101.998C208.917 102.488 207.822 103.383 207.116 103.306C206.321 103.005 207.999 100.896 209.588 100.173C209.789 100.059 210.019 100.005 210.251 100.019C210.78 100.216 210.101 101.292 209.412 101.998"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1640"
            d="M508.106 421.58C507.532 422.681 506.578 424.23 506.34 424.247C506.084 423.972 507.974 420.375 508.804 419.549C508.883 419.48 508.901 419.489 508.927 419.489C508.802 420.23 508.529 420.941 508.124 421.58"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1641"
            d="M352.773 463.342C352.296 465.425 349.523 466.104 348.605 464.779C347.872 463.721 348.738 461.629 350.927 461.337C351.2 461.28 351.483 461.294 351.748 461.378C352.013 461.461 352.251 461.611 352.438 461.813C352.625 462.014 352.754 462.26 352.813 462.525C352.872 462.79 352.858 463.066 352.773 463.325"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1642"
            d="M333.885 464.908C333.328 466.844 330.352 467.489 329.514 465.768C328.807 464.348 330.503 462.748 331.862 462.662C332.162 462.616 332.469 462.645 332.754 462.745C333.039 462.844 333.294 463.013 333.496 463.234C333.697 463.455 333.838 463.721 333.906 464.009C333.974 464.297 333.967 464.597 333.885 464.882"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1643"
            d="M159.442 457.206C159.76 458.789 158.435 459.176 157.137 458.376C155.371 457.309 154.877 454.934 156.307 454.607C156.508 454.57 156.715 454.57 156.916 454.607C157.534 454.78 158.094 455.111 158.537 455.565C158.981 456.018 159.293 456.579 159.442 457.188"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1644"
            d="M181.836 93.3406C180.45 94.2011 179.187 94.683 179.24 94.2011C179.381 93.3922 182.931 91.1634 183.611 91.456C183.92 91.7055 182.728 92.7124 181.845 93.3234"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1645"
            d="M143.583 454.469C143.857 455.803 142.771 456.371 141.473 455.562C139.548 454.366 139.15 452.025 140.448 451.733C140.615 451.702 140.785 451.702 140.952 451.733C141.587 451.946 142.16 452.307 142.622 452.783C143.083 453.26 143.419 453.838 143.601 454.469"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1646"
            d="M135.83 452.92C136.209 454.461 135.088 454.77 133.869 454.004C132.103 452.92 131.432 450.433 132.766 450.149C132.929 450.118 133.097 450.118 133.26 450.149C133.885 450.368 134.445 450.734 134.89 451.214C135.336 451.695 135.652 452.275 135.812 452.903"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1647"
            d="M120.218 436.613C120.324 437.06 120.58 438.506 119.097 438.231C116.977 437.602 115.856 434.375 117.119 433.816C117.337 433.748 117.572 433.748 117.79 433.816C118.396 434.067 118.934 434.454 119.357 434.947C119.781 435.439 120.079 436.021 120.227 436.647"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1648"
            d="M127.883 438.48C128.122 439.9 127 440.468 125.755 439.582C123.866 438.257 123.548 435.83 124.872 435.529C125.036 435.498 125.203 435.498 125.367 435.529C126.005 435.782 126.57 436.183 127.013 436.698C127.456 437.212 127.764 437.824 127.91 438.48"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1649"
            d="M143.592 441.535C143.91 443.257 142.551 443.627 141.252 442.732C139.486 441.51 139.089 438.928 140.661 438.73C140.801 438.706 140.944 438.706 141.085 438.73C141.725 438.927 142.297 439.29 142.739 439.782C143.181 440.274 143.476 440.874 143.592 441.518"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1650"
            d="M505.864 187.76C506.518 188.939 507.127 190.66 506.597 190.539C505.714 190.324 503.586 186.426 503.992 185.738C504.319 185.411 505.308 186.753 505.864 187.76Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1651"
            d="M502.279 192.665C502.844 193.792 503.259 195.47 502.694 195.332C501.732 195.109 499.586 191.417 500.186 190.496C500.504 190.203 501.626 191.357 502.279 192.665Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1652"
            d="M151.883 443.007C152.095 444.59 150.594 445.167 149.163 444.056C147.318 442.619 147.397 440.373 148.889 440.21C149.018 440.197 149.149 440.197 149.278 440.21C149.923 440.413 150.502 440.777 150.958 441.266C151.413 441.756 151.729 442.353 151.874 442.998"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1653"
            d="M499.841 194.937C500.565 196.331 500.865 197.906 500.176 197.691C499.011 197.33 497.095 193.474 497.757 192.837C498.269 192.519 499.302 193.905 499.841 194.937Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1654"
            d="M340.33 451.096C339.977 453.291 337.125 453.962 336.074 452.551C335.191 451.329 336.189 449.177 338.29 448.945C338.583 448.903 338.882 448.931 339.162 449.027C339.441 449.122 339.692 449.283 339.893 449.496C340.094 449.708 340.239 449.964 340.315 450.243C340.391 450.522 340.396 450.815 340.33 451.096Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1655"
            d="M448.219 201.993C448.855 203.568 448.475 205.108 447.212 204.575C445.314 203.714 444.184 200.272 445.597 199.696C446.427 199.455 447.707 200.78 448.246 202.036"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1656"
            d="M453.773 200.092C454.162 200.952 454.303 202.441 453.561 202.802C451.901 203.086 449.817 199.36 450.912 198.035C450.968 197.957 451.049 197.9 451.142 197.871C452.078 197.665 453.34 199.042 453.791 200.083"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1657"
            d="M463.955 196.331C464.67 197.863 464.529 199.395 463.399 198.913C461.686 198.199 460.255 194.774 461.377 194.085C462.26 193.836 463.452 195.273 463.946 196.331"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1658"
            d="M468.609 194.248C469.183 195.427 469.342 197.355 468.168 196.89C466.34 196.159 465.051 192.587 466.049 192.08C466.843 191.856 468.115 193.241 468.609 194.248Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1659"
            d="M254.199 108.013C253.643 109.511 252.477 109.976 252.115 109.158C251.674 108.194 252.716 106.008 253.881 105.991C254.455 106.034 254.623 106.964 254.226 108.013"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1660"
            d="M476.962 190.256C477.677 191.641 477.774 193.139 476.865 192.837C475.505 192.416 473.439 188.793 474.481 188.027C475.249 187.769 476.415 189.189 476.953 190.239"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1661"
            d="M480.618 187.932C481.104 188.793 481.501 190.264 481.077 190.694C479.823 191.168 477.678 187.252 478.101 186.125C478.13 186.048 478.182 185.982 478.252 185.936C478.931 185.66 480.141 187.063 480.627 187.923"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1662"
            d="M245.44 107.385C244.813 108.616 243.524 109.365 243.241 108.383C242.906 107.213 244.345 105.311 245.299 105.303C245.943 105.44 245.89 106.507 245.44 107.385Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1663"
            d="M487.187 183.827C487.99 185.256 488.141 186.529 487.496 186.409C486.339 186.168 483.964 182.493 484.847 181.65C485.483 181.375 486.613 182.795 487.213 183.836"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1664"
            d="M489.952 181.539C490.641 182.761 490.985 184.284 490.385 184.12C489.175 183.879 487.02 180.179 487.638 179.404C488.142 179.163 489.405 180.506 489.952 181.496"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1665"
            d="M241.087 106.981C240.249 108.487 239.101 108.702 238.889 108.013C238.615 107.067 239.993 104.881 241.105 104.89C241.776 105.027 241.6 106.069 241.105 106.981"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1666"
            d="M494.367 176.891C494.888 177.752 495.568 179.103 495.303 179.568C494.517 180.093 492.071 176.048 492.23 175.084C492.243 175.033 492.264 174.983 492.292 174.938C492.566 174.662 493.546 175.575 494.376 176.891"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1667"
            d="M496.15 174.559C496.671 175.42 497.333 176.814 497.148 177.244C496.53 177.743 493.88 173.897 494.066 172.77C494.057 172.739 494.057 172.706 494.066 172.675C494.393 172.391 495.496 173.604 496.115 174.559"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1668"
            d="M232.768 105.913C231.982 107.118 230.578 107.919 230.401 106.998C230.19 105.879 232.053 103.822 232.918 103.926C233.563 104.081 233.245 105.182 232.768 105.913Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1669"
            d="M498.765 170.102C499.559 171.332 500.16 172.727 499.701 172.615C498.994 172.434 496.557 168.914 496.751 168.054C497.034 167.753 498.12 169.095 498.765 170.102Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1670"
            d="M228.618 105.371C227.629 106.791 226.419 107.221 226.269 106.49C226.066 105.492 227.973 103.254 228.918 103.383C229.589 103.547 229.103 104.657 228.609 105.371"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1671"
            d="M510.658 418.998C510.177 419.953 509.622 420.87 508.998 421.743C508.979 421.765 508.954 421.783 508.927 421.795C508.724 421.459 510.888 417.587 511.356 417.096L511.435 417.036C511.242 417.706 510.973 418.352 510.632 418.964"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1672"
            d="M413.781 314.897C413.711 316.515 411.865 318.89 410.046 316.937C408.395 315.155 409.596 312.419 411.229 312.032C412.474 311.86 413.878 313.409 413.781 314.897Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1673"
            d="M404.102 314.725C404.102 315.13 404.102 317.152 402.098 317.565C399.572 317.772 398.936 314.433 400.217 312.884C400.559 312.462 401.05 312.18 401.594 312.092C402.981 311.912 404.067 313.039 404.12 314.725"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1674"
            d="M384.483 317.256C384.504 317.905 384.292 318.541 383.882 319.054C383.472 319.566 382.891 319.923 382.24 320.061C379.882 320.19 378.77 317.247 380.474 315.354C380.816 314.941 381.304 314.668 381.843 314.588C382.197 314.588 382.548 314.657 382.875 314.792C383.202 314.926 383.497 315.123 383.744 315.371C383.991 315.619 384.185 315.913 384.313 316.235C384.442 316.557 384.502 316.901 384.492 317.247"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1675"
            d="M374.344 318.46C374.344 321.042 371.695 321.962 370.3 320.465C368.905 318.968 369.549 316.033 371.81 315.672C373.17 315.543 374.353 316.851 374.344 318.46Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1676"
            d="M354.097 320.439C353.929 322.556 351.536 323.881 349.92 322.22C349.575 321.847 349.335 321.392 349.226 320.901C349.116 320.41 349.14 319.9 349.296 319.421C349.451 318.941 349.732 318.51 350.111 318.169C350.49 317.829 350.954 317.591 351.457 317.479C351.835 317.489 352.207 317.576 352.549 317.734C352.891 317.892 353.196 318.117 353.445 318.395C353.694 318.673 353.881 318.998 353.995 319.349C354.108 319.701 354.146 320.072 354.106 320.439"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1677"
            d="M343.731 321.033C343.757 321.675 343.536 322.303 343.111 322.795C342.686 323.287 342.089 323.607 341.435 323.692C338.733 323.692 338.292 320.327 339.757 318.873C340.138 318.472 340.657 318.221 341.214 318.167C341.575 318.182 341.929 318.268 342.255 318.421C342.58 318.574 342.87 318.79 343.106 319.056C343.343 319.322 343.521 319.632 343.63 319.967C343.739 320.303 343.776 320.657 343.74 321.007"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1678"
            d="M323.174 322.246C322.98 324.664 320.286 325.688 318.803 323.855C318.488 323.47 318.281 323.011 318.202 322.525C318.123 322.038 318.175 321.539 318.353 321.078C318.531 320.617 318.829 320.208 319.217 319.893C319.606 319.577 320.071 319.365 320.569 319.277C321.316 319.339 322.008 319.685 322.494 320.241C322.98 320.797 323.221 321.518 323.165 322.246"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1679"
            d="M312.709 322.565C312.725 323.207 312.489 323.832 312.05 324.312C311.61 324.791 311 325.089 310.343 325.146C307.623 325.026 307.261 321.704 308.762 320.267C309.171 319.887 309.707 319.663 310.272 319.639C311.694 319.639 312.797 320.921 312.736 322.565"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1680"
            d="M292.074 322.97C291.95 325.147 289.425 326.515 287.782 324.691C286.14 322.866 287.597 320.061 289.548 320.035C289.912 320.067 290.267 320.168 290.591 320.332C290.915 320.497 291.202 320.723 291.437 320.996C291.672 321.27 291.849 321.585 291.958 321.926C292.067 322.266 292.106 322.623 292.074 322.978"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1681"
            d="M281.626 323.029C281.467 325.516 278.977 326.282 277.538 324.819C275.904 323.098 276.99 320.052 279.215 320.043C279.578 320.078 279.929 320.184 280.248 320.354C280.568 320.525 280.849 320.756 281.074 321.035C281.299 321.314 281.464 321.635 281.559 321.977C281.654 322.32 281.676 322.678 281.626 323.029Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1682"
            d="M261.113 322.789C261.069 323.428 260.788 324.03 260.321 324.481C259.855 324.933 259.235 325.203 258.579 325.241C256.168 325.043 255.32 321.902 257.237 320.293C257.638 319.951 258.151 319.759 258.685 319.751C259.414 319.856 260.074 320.229 260.529 320.794C260.984 321.36 261.2 322.073 261.131 322.789"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1683"
            d="M250.782 322.47C250.588 324.965 248.133 325.662 246.72 324.191C245.131 322.47 246.27 319.398 248.486 319.458C250.208 319.613 250.879 321.179 250.782 322.513"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1684"
            d="M76.0665 295.458C75.6691 297.179 74.3004 298.04 73.4615 296.628C72.2871 294.675 73.4615 291.62 74.8567 291.844C74.9067 291.84 74.9569 291.84 75.0068 291.844C75.987 292.308 76.3667 294.116 76.0665 295.424"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1685"
            d="M69.7352 292.997C69.6293 293.539 69.1789 295.269 67.9692 294.907C66.2031 294.047 66.7594 289.968 68.2341 289.503C68.4254 289.437 68.6344 289.437 68.8257 289.503C69.7087 289.873 70.0973 291.327 69.7706 292.945"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1686"
            d="M58.1855 288.048C57.8323 289.812 56.7197 290.561 56.0221 289.502C54.8211 287.678 55.9603 284.235 57.2407 284.519L57.3643 284.563C58.0884 284.924 58.4769 286.585 58.1855 288.048Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1687"
            d="M96.4017 129.139C95.4127 130.051 94.6356 130.533 94.6356 130.241C94.5738 129.527 97.9999 126.222 98.4503 126.549C98.4503 126.928 97.3995 128.201 96.4193 129.131"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1688"
            d="M98.3812 133.193C97.1185 134.389 96.4385 134.621 96.4474 134.234C96.4474 133.511 99.6969 130.009 100.289 130.577C100.43 131.041 99.2289 132.392 98.3812 133.158"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1689"
            d="M99.8108 135.241C98.7335 136.256 97.8328 136.712 97.7975 136.256C97.7445 135.292 101.029 132.117 101.621 132.564C101.815 132.883 100.932 134.182 99.855 135.241"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1690"
            d="M103.678 139.217C102.362 140.516 101.524 140.757 101.506 140.232C101.506 139.372 104.455 136.033 105.294 136.532C105.612 136.988 104.464 138.417 103.669 139.208"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1691"
            d="M106.151 141.187C105.029 142.314 103.917 142.822 103.855 142.237C103.74 141.075 106.751 138.072 107.608 138.502C107.926 138.88 107.095 140.223 106.151 141.187Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1692"
            d="M111.979 145.042C110.611 146.531 109.498 146.815 109.498 146.058C109.498 144.741 112.359 141.893 113.198 142.392C113.648 142.779 112.924 144.018 111.979 145.042Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1693"
            d="M115.405 147.005C114.213 148.304 112.88 148.829 112.818 148.011C112.703 146.703 115.546 143.838 116.474 144.311C116.95 144.69 116.288 146.032 115.405 147.005Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1694"
            d="M122.859 150.851C122.444 151.325 121.384 152.4 120.572 152.159C119.53 151.523 121.941 148.399 123.407 148.02C123.56 147.961 123.73 147.961 123.883 148.02C124.537 148.339 123.883 149.741 122.877 150.851"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1695"
            d="M151.363 160.498C150.039 162.391 148.608 162.434 148.44 161.453C148.22 160.136 150.268 157.477 151.672 157.71C152.502 158.028 152.131 159.379 151.363 160.498Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1696"
            d="M130.886 117.436C129.437 118.641 128.325 119.003 128.36 118.503C128.44 117.505 131.804 114.846 132.546 115.199C132.811 115.612 131.663 116.765 130.886 117.436Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1697"
            d="M128.651 115.827C127.176 116.988 126.32 117.229 126.346 116.79C126.39 115.835 129.976 113.047 130.558 113.512C130.806 113.873 129.587 115.087 128.651 115.827Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1698"
            d="M125.393 112.359C124.139 113.357 123.105 113.77 123.105 113.357C123.256 112.496 126.973 109.682 127.379 110.147C127.512 110.5 126.293 111.688 125.357 112.393"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1699"
            d="M123.988 110.689C122.716 111.627 121.86 112.006 121.904 111.61C121.975 110.827 125.754 108.021 126.204 108.409C126.31 108.804 124.942 109.992 123.988 110.689Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1700"
            d="M122.337 107.23C121.216 108.022 120.342 108.435 120.342 108.168C120.342 107.505 124.183 104.665 124.66 104.967C124.66 105.337 123.256 106.516 122.311 107.195"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1701"
            d="M121.949 105.423C120.704 106.283 119.883 106.696 119.945 106.387C120.077 105.75 124.095 102.876 124.289 103.28C124.289 103.607 123.044 104.623 121.94 105.414"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1702"
            d="M121.834 101.963C121.658 102.083 120.165 103.108 119.945 102.961C119.865 102.488 123.75 99.6911 124.271 99.846C123.563 100.651 122.739 101.353 121.825 101.929"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1703"
            d="M122.038 100.303C121.155 100.897 120.271 101.439 120.271 101.163C120.271 100.888 124.236 97.8159 124.616 98.1085C123.829 98.9208 122.939 99.6322 121.967 100.225"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1704"
            d="M123.608 96.7828C123.06 97.1818 122.467 97.5196 121.842 97.7896V97.738C123.113 96.5573 124.556 95.5657 126.124 94.7949V94.838C125.339 95.5593 124.491 96.2129 123.59 96.7914"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1705"
            d="M97.2766 355.119C97.1354 357.176 95.6695 357.546 94.6275 356.324C93.1529 354.603 93.6121 351.298 95.2986 351.479H95.4753C96.7468 351.935 97.3385 353.673 97.2413 355.076"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1706"
            d="M105.075 357.133C104.907 358.991 103.406 359.62 102.267 358.312C100.624 356.418 101.384 353.363 102.973 353.484H103.15C104.306 353.794 105.225 355.523 105.075 357.107"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1707"
            d="M121.216 360.652C121.119 362.795 119.45 363.277 118.311 362.089C116.677 360.446 117.207 357.064 119.079 357.201H119.273C120.386 357.451 121.295 359.052 121.216 360.644"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1708"
            d="M129.763 362.409C129.613 364.276 127.856 365.214 126.487 363.579C124.774 361.548 125.931 358.863 127.485 358.932H127.661C128.986 359.302 129.86 361.066 129.745 362.374"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1709"
            d="M147.265 365.498C147.088 367.701 145.198 368.123 143.962 366.815C142.399 365.162 143.035 361.995 144.916 362.039H145.128C146.744 362.417 147.335 364.276 147.247 365.481"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1710"
            d="M328.948 374.559C328.905 375.171 328.639 375.747 328.199 376.186C327.759 376.624 327.173 376.895 326.546 376.951H326.502C323.897 376.951 323.244 373.827 324.939 372.201C325.365 371.789 325.93 371.541 326.529 371.504C328.586 371.504 329.098 373.337 328.948 374.559Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1711"
            d="M339.572 373.733C339.413 375.936 336.79 377.399 335.157 375.6C334.841 375.203 334.636 374.733 334.563 374.235C334.49 373.738 334.551 373.23 334.74 372.762C334.928 372.295 335.239 371.883 335.64 371.567C336.041 371.252 336.52 371.043 337.029 370.962C337.387 370.966 337.741 371.043 338.068 371.188C338.394 371.332 338.687 371.542 338.926 371.802C339.165 372.063 339.346 372.369 339.457 372.701C339.568 373.034 339.607 373.385 339.572 373.733Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1712"
            d="M360.331 372.296C360.128 374.878 357.347 375.738 356.031 374.164C354.715 372.589 355.863 369.68 357.947 369.499C359.66 369.448 360.437 370.868 360.331 372.296Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1713"
            d="M370.787 371.281C370.619 373.423 368.05 374.989 366.478 373.337C364.906 371.685 366.363 368.776 368.315 368.578C368.667 368.573 369.017 368.642 369.339 368.78C369.662 368.918 369.951 369.122 370.185 369.378C370.42 369.635 370.595 369.937 370.699 370.266C370.803 370.594 370.833 370.94 370.787 371.281Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1714"
            d="M391.115 369.249C391.074 369.87 390.823 370.46 390.401 370.927C389.979 371.394 389.41 371.712 388.783 371.831C386.329 372.003 385.702 368.888 387.309 367.184C387.711 366.746 388.258 366.46 388.854 366.375C390.823 366.246 391.309 368.036 391.159 369.266"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1715"
            d="M401.259 367.812C400.994 369.955 398.46 371.504 397.021 369.826C395.581 368.148 396.95 365.471 398.875 365.084C399.226 365.081 399.573 365.154 399.891 365.296C400.21 365.439 400.492 365.649 400.718 365.91C400.944 366.172 401.108 366.478 401.199 366.808C401.289 367.139 401.304 367.484 401.241 367.821"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1716"
            d="M512.919 339.26C512.795 340.852 511.85 343.201 511.047 342.177C510.102 340.972 510.746 337.513 511.806 336.781C512.601 336.489 512.998 338.089 512.91 339.268"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1717"
            d="M525.67 331.643C525.564 333.08 524.875 335.335 524.302 334.672C523.418 333.683 523.975 329.888 524.858 329.105C525.414 328.864 525.741 330.301 525.661 331.686"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1718"
            d="M516.141 283.745C516.292 285.174 515.912 287.377 514.994 286.654C513.625 285.57 513.722 281.775 514.579 281.25C515.338 280.923 516.018 282.506 516.141 283.745Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1719"
            d="M511.709 286.095C511.965 287.902 511.418 289.803 510.526 289.201C509.148 288.272 509.086 284.313 510.12 283.737C511.091 283.349 511.594 285.311 511.709 286.095Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1720"
            d="M484.45 297.927C484.574 299.502 483.682 301.783 482.357 300.707C480.68 299.339 481.183 296.017 482.437 295.397C483.426 295.174 484.335 296.602 484.441 297.979"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1721"
            d="M477.871 300.096C478.039 301.903 477.068 303.943 475.734 302.919C473.968 301.567 474.569 298.005 475.858 297.575C477.13 297.282 477.765 298.969 477.871 300.096Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1722"
            d="M109.649 374.86C109.693 377.063 108.094 377.304 106.999 376.298C105.428 374.8 105.569 371.59 107.211 371.521C107.333 371.527 107.454 371.542 107.573 371.565C108.713 371.848 109.587 373.286 109.613 374.86"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1723"
            d="M117.807 376.779C117.807 378.698 116.155 379.361 114.875 377.984C113.153 376.151 113.789 373.32 115.44 373.389H115.634C116.897 373.776 117.816 375.48 117.798 376.754"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1724"
            d="M134.584 380.015C134.584 382.355 132.756 382.596 131.608 381.504C129.992 379.972 130.257 376.951 132.006 376.814C132.141 376.8 132.277 376.8 132.412 376.814C133.604 377.046 134.558 378.44 134.584 379.989"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1725"
            d="M143.416 381.615C143.416 383.439 141.694 384.481 140.184 382.975C138.321 381.133 139.301 378.44 140.846 378.388C140.94 378.373 141.035 378.373 141.129 378.388C141.804 378.627 142.386 379.064 142.796 379.638C143.206 380.213 143.422 380.898 143.416 381.598"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1726"
            d="M161.438 384.55C161.324 386.753 159.222 387.132 157.906 385.678C156.396 383.957 157.023 381.211 158.913 381.229H159.134C159.845 381.44 160.46 381.884 160.873 382.486C161.287 383.089 161.474 383.812 161.403 384.533"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1727"
            d="M170.851 385.815C170.692 387.665 168.706 388.586 167.196 386.917C165.686 385.247 166.392 382.399 168.467 382.519C169.189 382.725 169.817 383.162 170.25 383.761C170.684 384.36 170.895 385.084 170.851 385.815Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1728"
            d="M323.597 391.46C323.429 393.715 320.736 394.842 319.226 393.293C317.513 391.529 319.226 388.87 321.071 388.775C321.421 388.78 321.766 388.855 322.086 388.995C322.405 389.134 322.692 389.336 322.928 389.587C323.165 389.839 323.346 390.134 323.461 390.456C323.576 390.779 323.622 391.12 323.597 391.46Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1729"
            d="M344.392 390.496C344.066 392.94 341.196 393.646 339.977 392.088C339.709 391.685 339.548 391.223 339.511 390.744C339.473 390.265 339.559 389.784 339.761 389.346C339.963 388.908 340.275 388.526 340.667 388.235C341.06 387.945 341.521 387.755 342.008 387.682C342.368 387.674 342.725 387.745 343.052 387.891C343.38 388.038 343.668 388.255 343.897 388.526C344.125 388.797 344.287 389.116 344.369 389.457C344.452 389.799 344.454 390.154 344.375 390.496"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1730"
            d="M354.893 389.653C354.61 391.779 351.952 393.173 350.477 391.418C349.003 389.662 350.548 387.115 352.455 386.951C352.806 386.948 353.154 387.019 353.475 387.158C353.795 387.297 354.081 387.502 354.314 387.758C354.547 388.014 354.72 388.315 354.823 388.642C354.926 388.97 354.956 389.314 354.91 389.653"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1731"
            d="M375.386 387.872C375.033 390.324 372.207 391.133 371.059 389.593C370.807 389.18 370.66 388.714 370.63 388.233C370.601 387.753 370.691 387.273 370.892 386.834C371.092 386.395 371.398 386.009 371.784 385.708C372.17 385.408 372.624 385.203 373.108 385.11C375.068 385.023 375.545 386.745 375.386 387.846"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1732"
            d="M385.666 386.642C385.348 388.905 382.619 390.084 381.313 388.561C380.006 387.037 381.454 384.258 383.326 384.026C383.663 384.015 383.999 384.078 384.308 384.208C384.618 384.339 384.895 384.534 385.118 384.781C385.342 385.027 385.507 385.319 385.601 385.634C385.696 385.95 385.718 386.282 385.666 386.607"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1733"
            d="M540.954 328.089C540.813 329.354 540.566 331.101 540.469 331.376C540.538 329.512 540.741 327.654 541.078 325.817V325.766C541.097 326.542 541.053 327.319 540.946 328.089"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1734"
            d="M150.383 115.81L149.694 116.189C149.736 116.103 149.783 116.019 149.835 115.939L149.915 115.793C149.933 115.726 149.966 115.664 150.012 115.612C150.012 115.612 150.577 115.276 150.63 115.345C150.589 115.498 150.523 115.643 150.436 115.776C150.436 115.776 150.436 115.776 150.383 115.776M149.844 116.636C151.018 115.664 151.981 114.338 151.433 114.184C149.844 114.184 146.771 117.307 147.301 117.858C147.548 118.125 148.714 117.566 149.844 116.636Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1735"
            d="M425.984 89.623C426.819 90.0979 427.588 90.6764 428.271 91.3441C427.962 91.5592 423.767 88.8141 423.617 88.5215C424.469 88.7871 425.271 89.1852 425.993 89.7004"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1736"
            d="M427.149 92.8161C427.94 93.2944 428.662 93.8732 429.295 94.5372C428.712 94.5802 424.88 92.2051 424.633 91.6113C425.538 91.8884 426.391 92.3069 427.158 92.8505"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1737"
            d="M427.459 94.4942C428.262 95.0191 429.507 95.9313 429.446 96.1464C428.986 96.3013 424.925 93.7111 424.766 93.1689C425.719 93.4782 426.614 93.9375 427.415 94.5286"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1738"
            d="M427.088 97.5919C427.891 98.134 429.022 99.0462 429.039 99.313C428.669 99.6142 424.439 96.8518 424.377 96.2666C424.545 96.1031 426.037 96.8604 427.088 97.5747"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1739"
            d="M426.524 99.1406C427.292 99.6655 428.51 100.612 428.493 100.931C428.06 101.266 423.795 98.4349 423.83 97.8411C424.033 97.6174 425.517 98.4693 426.479 99.1492"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1740"
            d="M424.899 102.274C425.667 102.824 426.754 103.788 426.665 104.081C426.294 104.27 424.572 103.169 424.14 102.859C423.045 102.101 421.985 101.086 422.074 100.888C422.33 100.716 423.84 101.508 424.89 102.274"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1741"
            d="M518.518 263.196C518.73 264.383 518.606 266.707 517.749 266.061C516.487 265.106 516.072 261.457 516.866 260.709C517.37 260.399 518.235 261.716 518.5 263.196"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1742"
            d="M514.729 265.778C514.729 265.864 515.108 267.989 514.358 268.643C513.122 269.185 512.133 265.149 512.777 263.575C512.826 263.429 512.922 263.302 513.051 263.213C513.819 262.852 514.508 264.521 514.738 265.795"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1743"
            d="M423.803 103.891C425.022 104.803 425.755 105.664 425.34 105.681C424.651 105.681 420.589 103.056 420.871 102.394C421.234 102.213 422.805 103.151 423.803 103.891Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1744"
            d="M506.111 270.51C506.368 271.93 505.979 274.194 504.919 273.454C503.48 272.455 503.242 268.875 504.257 268.127C505.069 267.851 505.882 269.357 506.094 270.519"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1745"
            d="M501.236 273.057C501.51 274.83 500.936 276.499 499.938 275.871C498.428 274.899 498.234 271.043 499.346 270.484C500.309 270.157 501.042 271.826 501.236 273.066"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1746"
            d="M420.589 106.766C421.375 107.359 422.417 108.401 422.249 108.702C421.552 109.012 417.463 105.965 417.781 105.38C418.161 105.208 419.689 106.094 420.58 106.783"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1747"
            d="M490.508 277.627C490.684 278.978 490.119 281.405 488.742 280.363C487.029 279.038 487.258 275.682 488.415 275.079C489.298 274.89 490.278 276.319 490.455 277.661"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1748"
            d="M156.847 161.918C155.823 163.398 154.118 164.13 153.88 162.856C153.597 161.367 155.646 158.915 157.023 159.104C157.827 159.431 157.562 160.825 156.829 161.909"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1749"
            d="M300.303 174.654C300.354 175.151 300.214 175.648 299.908 176.049C299.603 176.45 299.155 176.726 298.652 176.822C296.815 176.745 296.003 173.888 297.433 172.571C297.668 172.34 297.983 172.205 298.316 172.192C299.473 172.253 300.303 173.535 300.33 174.654"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1750"
            d="M315.128 174.241C315.234 176.134 313.707 176.823 312.559 175.911C311.164 174.783 311.208 171.892 313.009 171.677C314.44 171.677 315.049 173.277 315.102 174.258"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1751"
            d="M344.746 172.098C345.037 173.819 343.695 174.791 342.361 173.819C340.666 172.614 340.595 169.895 342.238 169.602C343.297 169.516 344.516 170.721 344.746 172.072"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1752"
            d="M359.087 170.463C359.635 172.442 358.204 173.044 357.003 172.39C355.299 171.409 354.849 168.535 356.341 168.088H356.5C357.674 168.036 358.716 169.258 359.052 170.463"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1753"
            d="M366.213 169.388C366.708 170.885 365.86 172.245 364.359 171.531C362.443 170.618 362.037 167.753 363.343 167.228C363.413 167.197 363.488 167.18 363.564 167.176C364.792 167.099 365.887 168.407 366.213 169.379"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1754"
            d="M380.315 168.656C380.853 170.162 380.218 171.461 378.814 170.772C376.809 169.791 376.164 166.78 377.436 166.315H377.56C378.769 166.203 379.935 167.614 380.315 168.681"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1755"
            d="M386.858 167.485C387.291 168.621 386.955 170.316 385.551 169.671C383.308 168.647 382.787 165.687 383.944 165.205H384.076C385.065 165.153 386.452 166.452 386.849 167.519"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1756"
            d="M399.131 165.068C399.723 166.402 399.414 167.701 398.248 167.271C396.182 166.531 395.008 163.312 396.226 162.787L396.35 162.744C397.18 162.581 398.549 163.708 399.158 165.068"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1757"
            d="M404.96 163.682C405.463 164.775 405.436 166.419 404.076 165.911C401.878 165.051 400.959 161.944 402.001 161.479H402.09C403.123 161.342 404.5 162.727 404.951 163.708"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1758"
            d="M415.795 160.867C416.563 162.253 416.369 163.389 415.398 163.165C413.481 162.718 411.865 159.284 412.854 158.707H412.952C413.967 158.527 415.247 159.912 415.795 160.902"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1759"
            d="M420.827 159.189C421.321 159.99 421.71 161.323 421.012 161.659C420.262 161.78 419.131 160.859 418.452 159.938C417.772 159.017 417.516 157.804 417.842 157.356C417.895 157.302 417.962 157.263 418.037 157.244C418.92 157.072 420.324 158.423 420.827 159.224"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1760"
            d="M430.197 156.092C431.151 157.469 431.195 158.734 430.277 158.536C428.599 158.183 426.374 154.793 427.398 154.165H427.469C428.44 153.924 429.774 155.49 430.197 156.118"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1761"
            d="M434.604 154.474C435.328 155.515 435.654 157.124 434.692 156.892C432.882 156.453 430.939 153.2 431.734 152.589L431.822 152.538C432.423 152.331 433.88 153.45 434.604 154.474Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1762"
            d="M442.55 151.221C443.486 152.521 443.636 153.648 442.859 153.51C441.385 153.235 438.903 149.931 439.654 149.208L439.733 149.156C440.51 148.958 441.87 150.249 442.55 151.187"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1763"
            d="M446.118 149.422C446.948 150.55 447.301 151.901 446.524 151.755C445.528 151.339 444.671 150.659 444.052 149.793C443.557 149.138 442.895 147.796 443.319 147.469C443.911 147.323 445.376 148.441 446.109 149.422"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1764"
            d="M452.414 145.842C453.385 147.056 453.739 148.149 453.138 148.132C451.937 148.132 448.953 144.552 449.606 143.949C450.189 143.7 451.584 144.904 452.361 145.868"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1765"
            d="M455.169 143.941C456.052 145.008 456.591 146.351 455.946 146.265C454.622 146.084 451.858 142.771 452.467 142.091C452.944 141.91 454.357 142.951 455.169 143.941Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1766"
            d="M459.805 140.137C460.882 141.351 461.412 142.504 460.856 142.461C459.699 142.375 456.644 138.933 457.218 138.365C457.668 138.107 458.984 139.268 459.805 140.137Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1767"
            d="M461.765 138.209C462.692 139.251 463.408 140.576 462.86 140.507C461.756 140.386 458.842 137.177 459.196 136.462C459.566 136.239 460.962 137.323 461.765 138.183"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1768"
            d="M464.837 134.397C465.393 135 466.312 136.118 466.188 136.523C465.588 137.031 462.303 133.425 462.259 132.685C462.247 132.654 462.247 132.621 462.259 132.59C462.568 132.298 463.963 133.451 464.811 134.389"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1769"
            d="M465.826 132.289C466.709 133.21 467.698 134.595 467.204 134.552C466.365 134.483 463.309 131.325 463.407 130.628C463.627 130.404 464.908 131.351 465.817 132.289"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1770"
            d="M467.168 128.408C467.839 129.079 468.855 130.25 468.731 130.525C468.193 130.723 464.802 127.419 464.793 126.687C464.978 126.438 466.294 127.548 467.168 128.408Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1771"
            d="M467.347 126.352C468.23 127.212 469.192 128.451 468.963 128.503C468.548 128.606 465.192 125.543 465.051 124.699V124.656C465.921 125.071 466.694 125.657 467.32 126.377"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1772"
            d="M466.781 122.29C467.585 123.056 468.68 124.226 468.609 124.424C468.185 124.424 464.936 121.403 464.662 120.689C464.803 120.586 465.854 121.438 466.781 122.324"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1773"
            d="M94.3356 371.022C94.3356 372.692 93.117 373.604 91.8543 372.287C90.15 370.506 90.618 367.425 92.1457 367.545H92.3223C93.479 367.976 94.3356 369.714 94.3267 370.988"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1774"
            d="M400.571 332.84C400.5 335.034 398.028 336.652 396.456 334.923C394.884 333.193 396.147 330.396 398.001 330.146C398.354 330.149 398.703 330.222 399.027 330.361C399.35 330.5 399.641 330.702 399.881 330.954C400.122 331.206 400.307 331.503 400.426 331.828C400.544 332.152 400.594 332.496 400.571 332.84Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1775"
            d="M167.893 148.949C166.631 150.722 165.244 150.834 165.024 149.956C164.759 148.717 166.79 146.067 168.229 146.273C169.041 146.566 168.662 147.874 167.893 148.949Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1776"
            d="M173.376 150.025C172.396 151.523 170.577 152.357 170.356 151.084C170.1 149.569 172.343 147.246 173.464 147.478C174.268 147.736 174.038 149.01 173.376 150.06"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1777"
            d="M390.488 334.156C390.435 336.643 387.971 337.675 386.558 336.367C384.933 334.844 385.843 331.703 388.006 331.419C389.658 331.316 390.523 332.753 390.488 334.156Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1778"
            d="M202.127 155.3C201.182 157.125 199.478 157.417 199.187 156.23C198.789 154.801 200.529 152.409 201.836 152.572C202.966 152.822 202.63 154.345 202.127 155.3Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1779"
            d="M208.45 155.946C207.735 157.521 205.986 158.467 205.412 157.194C204.75 155.714 206.604 153.252 207.885 153.407C208.9 153.623 208.865 155.017 208.45 155.989"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1780"
            d="M370.275 336.488C370.098 338.855 367.396 339.982 365.957 338.296C365.66 337.902 365.465 337.443 365.391 336.96C365.317 336.476 365.365 335.982 365.531 335.521C365.698 335.059 365.977 334.645 366.345 334.312C366.713 333.98 367.159 333.74 367.644 333.614C368.016 333.62 368.383 333.7 368.721 333.851C369.06 334.001 369.363 334.218 369.612 334.488C369.861 334.757 370.05 335.074 370.167 335.419C370.284 335.763 370.327 336.127 370.293 336.488"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1781"
            d="M359.889 337.444C359.739 339.913 357.134 340.782 355.765 339.363C354.397 337.943 355.059 334.862 357.381 334.526C359.147 334.483 359.977 335.998 359.889 337.444Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1782"
            d="M309.425 159.93C309.513 161.651 308.18 162.512 307.006 161.565C305.504 160.361 305.769 157.684 307.403 157.486C308.489 157.547 309.363 158.829 309.425 159.93Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1783"
            d="M330.115 158.604C330.415 160.274 329.231 161.272 327.942 160.42C326.335 159.336 326.229 156.53 327.801 156.298C328.878 156.298 329.903 157.52 330.097 158.604"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1784"
            d="M343.695 157.443C344.163 159.285 342.892 159.887 341.814 159.302C340.154 158.441 339.686 155.515 341.134 155.137H341.293C342.415 155.137 343.395 156.307 343.695 157.477"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1785"
            d="M339.262 338.743C339.2 341.187 336.506 342.409 334.943 340.696C334.601 340.308 334.371 339.838 334.276 339.334C334.182 338.83 334.227 338.311 334.407 337.83C334.587 337.349 334.895 336.923 335.3 336.597C335.704 336.27 336.191 336.054 336.71 335.972C337.07 335.978 337.425 336.055 337.754 336.2C338.082 336.344 338.377 336.553 338.62 336.812C338.863 337.072 339.049 337.377 339.167 337.709C339.285 338.04 339.332 338.392 339.306 338.743"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1786"
            d="M363.598 154.887C364.322 156.703 363.218 157.409 362.114 156.901C360.242 156.04 359.624 153.244 360.834 152.762C360.897 152.738 360.962 152.721 361.028 152.71C362.123 152.641 363.156 153.751 363.598 154.887Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1787"
            d="M328.718 339.44C328.586 341.91 326.007 342.761 324.586 341.35C322.987 339.758 324.038 336.643 326.228 336.522C326.593 336.541 326.951 336.633 327.278 336.792C327.605 336.952 327.895 337.176 328.129 337.45C328.363 337.724 328.536 338.042 328.637 338.385C328.739 338.727 328.766 339.086 328.718 339.44Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1788"
            d="M370.574 155.111C370.989 156.204 370.663 157.821 369.285 157.202C367.122 156.221 366.636 153.329 367.731 152.899H367.863C368.826 152.847 370.177 154.112 370.574 155.145"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1789"
            d="M382.62 152.942C383.282 154.336 382.867 155.463 381.816 155.128C379.812 154.5 378.584 151.307 379.759 150.825L379.882 150.782C380.977 150.67 382.161 152.013 382.611 152.959"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1790"
            d="M388.403 151.798C388.976 152.925 388.853 154.379 387.634 154.001C385.489 153.295 384.482 150.188 385.497 149.698H385.603C386.486 149.612 387.864 150.799 388.394 151.841"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1791"
            d="M399.229 149.439C399.6 150.093 400.006 151.272 399.229 151.574C398.558 151.668 397.463 150.963 396.713 149.895C396.121 149.112 395.741 147.847 396.121 147.391C396.189 147.311 396.282 147.253 396.386 147.228C397.516 147.056 398.797 148.648 399.238 149.439"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1792"
            d="M404.245 147.925C404.731 148.699 405.066 149.964 404.395 150.248C403.636 150.343 402.488 149.388 401.799 148.527C401.234 147.779 400.863 146.531 401.243 146.092C401.297 146.038 401.364 145.997 401.437 145.971C402.32 145.842 403.689 147.056 404.254 147.951"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1793"
            d="M413.719 145.197C414.602 146.471 414.602 147.581 413.719 147.435C412.067 147.142 409.754 143.829 410.778 143.209C410.807 143.204 410.837 143.204 410.866 143.209C411.529 143.02 412.95 144.156 413.683 145.232"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1794"
            d="M418.205 143.829C418.876 144.818 419.168 146.204 418.205 145.997C417.269 145.629 416.457 145.015 415.856 144.225C414.911 142.934 414.885 142.03 415.159 141.832L415.247 141.789C415.847 141.609 417.402 142.693 418.17 143.829"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1795"
            d="M426.144 140.671C427.124 141.884 427.372 143.037 426.63 142.951C425.182 142.787 422.559 139.621 423.248 138.872C423.279 138.842 423.315 138.816 423.354 138.795C424.131 138.597 425.473 139.836 426.144 140.671Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1796"
            d="M429.941 139.173C430.78 140.232 431.142 141.505 430.365 141.359C428.785 141.075 426.18 137.848 427.054 137.254C427.69 137.142 429.164 138.218 429.933 139.182"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1797"
            d="M430.885 89.1244C431.971 89.8559 433.172 90.6906 433.349 90.8455C433.119 90.725 429.455 88.2638 429.119 87.9971C429.216 87.9971 430.885 89.1588 430.885 89.176"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1798"
            d="M436.431 135.774C437.508 136.962 437.932 138.124 437.314 138.089C436.024 138.012 433.057 134.759 433.675 134.088C433.692 134.066 433.713 134.049 433.737 134.036C434.205 133.804 435.556 134.767 436.466 135.757"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1799"
            d="M439.512 134.2C440.395 135.25 440.881 136.369 440.395 136.386C439.221 136.386 436.148 133.159 436.651 132.427L436.704 132.376C437.216 132.229 438.717 133.236 439.538 134.2"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1800"
            d="M415.75 349.741C415.573 351.359 413.569 353.777 411.865 351.832C410.16 349.887 411.864 347.228 413.401 346.867C414.646 346.686 415.918 348.244 415.75 349.741Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1801"
            d="M406.038 349.56C405.985 352.142 403.389 353.252 402.055 351.781C400.722 350.309 401.649 347.22 403.68 346.91C405.349 346.772 406.064 348.141 406.038 349.56Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1802"
            d="M386.089 352.322C385.877 354.543 383.254 356.023 381.771 354.181C381.483 353.775 381.301 353.307 381.24 352.817C381.179 352.327 381.241 351.83 381.42 351.368C381.6 350.907 381.893 350.495 382.272 350.168C382.651 349.841 383.107 349.609 383.599 349.491C383.96 349.498 384.316 349.579 384.643 349.729C384.97 349.879 385.26 350.095 385.495 350.362C385.731 350.63 385.905 350.943 386.007 351.28C386.11 351.618 386.137 351.973 386.089 352.322Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1803"
            d="M375.784 353.449C375.581 355.919 372.976 356.831 371.652 355.437C370.327 354.043 371.042 350.937 373.347 350.558C375.113 350.489 375.899 351.995 375.784 353.449Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1804"
            d="M355.209 355.352C354.97 357.598 352.33 358.975 350.794 357.073C350.497 356.669 350.31 356.199 350.249 355.706C350.187 355.213 350.254 354.713 350.443 354.252C350.633 353.791 350.938 353.384 351.331 353.068C351.724 352.752 352.192 352.537 352.692 352.443C353.062 352.456 353.425 352.544 353.758 352.701C354.091 352.859 354.387 353.083 354.626 353.358C354.864 353.634 355.041 353.955 355.145 354.302C355.248 354.648 355.276 355.012 355.226 355.369"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1805"
            d="M344.685 356.031C344.517 358.613 341.726 359.473 340.384 357.838C339.042 356.203 340.128 353.286 342.203 353.148C342.569 353.157 342.928 353.241 343.257 353.395C343.587 353.549 343.879 353.77 344.113 354.043C344.348 354.315 344.521 354.634 344.619 354.977C344.718 355.32 344.74 355.679 344.685 356.031Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1806"
            d="M323.809 357.073C323.703 359.319 321.063 360.816 319.394 358.949C319.068 358.556 318.853 358.086 318.77 357.587C318.688 357.088 318.741 356.576 318.924 356.103C319.107 355.63 319.413 355.212 319.813 354.89C320.213 354.568 320.693 354.353 321.204 354.268C321.566 354.276 321.923 354.356 322.252 354.504C322.581 354.651 322.875 354.863 323.117 355.126C323.359 355.389 323.542 355.697 323.657 356.032C323.771 356.367 323.814 356.721 323.783 357.073"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1807"
            d="M313.15 357.692C312.929 360.145 310.254 360.876 308.903 359.413C307.552 357.95 308.32 354.869 310.669 354.654C311.047 354.674 311.416 354.771 311.752 354.941C312.088 355.112 312.382 355.35 312.616 355.64C312.849 355.93 313.016 356.266 313.105 356.624C313.194 356.983 313.204 357.356 313.133 357.718"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1808"
            d="M281.582 358.002C281.45 360.524 278.854 361.315 277.423 359.827C275.993 358.338 276.655 355.162 279.119 355.042C279.486 355.063 279.845 355.159 280.172 355.322C280.499 355.486 280.788 355.714 281.02 355.993C281.252 356.271 281.421 356.594 281.518 356.94C281.615 357.286 281.637 357.648 281.582 358.002Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1809"
            d="M260.716 357.881C260.477 359.964 257.996 361.169 256.433 359.404C254.87 357.64 255.983 354.844 258.199 354.74C258.952 354.849 259.633 355.236 260.101 355.821C260.57 356.405 260.79 357.143 260.716 357.881Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1810"
            d="M134.117 345.972C133.808 348.037 131.927 348.364 130.841 347.03C129.366 345.232 130.214 342.099 132.121 342.306C133.146 342.512 134.346 344.44 134.117 345.972Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1811"
            d="M117.322 342.263C117.171 344.13 115.555 345.223 114.24 343.734C112.624 341.858 113.551 338.778 115.246 338.872H115.432C116.536 339.122 117.436 340.731 117.322 342.237"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1812"
            d="M109.119 340.662C108.845 342.874 107.176 342.977 106.222 341.876C104.765 340.155 105.445 336.833 107.22 336.979H107.397C108.933 337.453 109.269 339.423 109.119 340.628"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1813"
            d="M108.977 109.364C108.492 109.776 107.951 110.12 107.37 110.389C107.255 110.234 108.615 109.037 109.136 108.616C109.808 107.965 110.591 107.432 111.45 107.041C110.703 107.877 109.871 108.638 108.968 109.313"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1814"
            d="M108.376 113.004C107.254 113.864 106.53 114.338 106.539 114.097C106.539 113.503 110.407 110.353 110.645 110.723C110.02 111.591 109.25 112.35 108.367 112.97"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1815"
            d="M108.333 114.992C107.282 115.853 106.496 116.257 106.567 115.973C106.682 115.276 110.311 112.264 110.664 112.591C110.594 112.987 109.251 114.243 108.351 114.958"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1816"
            d="M109.418 118.667C108.208 119.657 107.387 120.095 107.405 119.725C107.449 118.934 111.122 115.87 111.502 116.283C111.502 116.679 110.239 117.927 109.409 118.624"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1817"
            d="M110.222 120.663C109.286 121.429 108.297 121.997 108.271 121.635C108.2 120.861 111.803 117.772 112.332 118.15C112.421 118.52 111.282 119.759 110.204 120.637"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1818"
            d="M113.093 124.26C111.68 125.499 110.841 125.792 110.85 125.353C110.85 124.656 114.232 121.377 114.903 121.911C115.106 122.195 114.346 123.202 113.093 124.295"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1819"
            d="M114.928 126.136C113.745 127.186 112.632 127.719 112.597 127.254C112.561 126.79 113.4 125.774 114.177 125.086C115.361 124.045 116.385 123.537 116.606 123.718C116.835 124.096 115.776 125.361 114.919 126.127"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1820"
            d="M119.228 129.982C117.78 131.299 116.976 131.377 116.95 130.92C116.897 129.828 120.191 126.885 120.923 127.384C121.321 127.797 120.129 129.165 119.228 129.965"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1821"
            d="M122.117 131.678C121.446 132.332 120.236 133.201 119.706 132.969C118.938 132.212 122.108 129.226 123.292 129.157C123.361 129.137 123.434 129.137 123.504 129.157C123.866 129.509 123.062 130.749 122.117 131.644"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1822"
            d="M128.404 135.24C126.965 136.72 125.755 137.047 125.755 136.342C125.685 135.275 128.519 132.375 129.596 132.762C130.056 133.02 129.552 134.087 128.422 135.24"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1823"
            d="M131.936 137.108C130.673 138.39 129.287 138.829 129.287 137.969C129.287 136.678 132.025 134.148 133.058 134.458C133.543 134.819 132.846 136.127 131.901 137.039"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1824"
            d="M139.688 140.344C138.196 142.022 137.039 142.065 136.968 141.342C136.898 140.06 139.617 137.392 140.615 137.779C141.216 138.106 140.615 139.32 139.688 140.361"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1825"
            d="M520.513 299.674C520.602 301.473 520.028 303.185 519.304 302.54C518.2 301.559 518.315 297.617 519.215 297.032C519.992 296.662 520.443 298.392 520.513 299.674Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1826"
            d="M420.059 126.145C421.004 127.143 421.587 128.313 420.889 128.279C419.582 128.21 416.571 125.146 417.136 124.467C417.155 124.442 417.179 124.422 417.207 124.406C417.843 124.226 419.282 125.336 420.059 126.127"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1827"
            d="M413.234 128.985C413.861 129.699 414.523 130.886 414.055 131.179C413.49 131.3 412.2 130.413 411.529 129.725C410.646 128.821 410.055 127.659 410.32 127.341C410.344 127.307 410.378 127.28 410.417 127.264C411.07 127.117 412.527 128.184 413.242 128.985"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1828"
            d="M409.515 130.456C410.513 131.635 410.76 132.71 410.001 132.616C408.367 132.426 405.966 129.328 406.584 128.709C406.616 128.678 406.656 128.654 406.699 128.64C407.431 128.476 408.782 129.604 409.515 130.456Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1829"
            d="M401.401 133.09C401.966 133.813 402.478 135.017 401.922 135.301C401.268 135.405 400.112 134.553 399.352 133.709C398.725 133.012 398.178 131.825 398.469 131.403C398.508 131.342 398.567 131.296 398.637 131.274C399.396 131.145 400.809 132.255 401.445 133.081"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1830"
            d="M387.688 136.833C388.368 137.865 388.456 139.173 387.609 139.053C385.772 138.777 383.9 135.559 384.792 134.974C384.832 134.947 384.877 134.927 384.924 134.914C385.807 134.81 387.132 135.981 387.688 136.833Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1831"
            d="M382.663 138.003C383.493 139.32 383.325 140.43 382.345 140.172C380.482 139.664 378.883 136.669 379.81 136.093C379.858 136.06 379.912 136.04 379.969 136.033C380.95 135.903 382.142 137.177 382.663 138.003Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1832"
            d="M372.12 140.077C372.544 140.843 372.729 142.074 371.97 142.323C370.336 142.444 368.164 139.243 369.206 138.244C369.279 138.175 369.371 138.128 369.471 138.107C370.477 138.021 371.722 139.329 372.12 140.077Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1833"
            d="M366.556 141.273C367.121 142.495 366.821 143.674 365.673 143.244C363.739 142.529 362.635 139.517 363.765 139.104H363.889C364.772 138.975 366.035 140.163 366.538 141.307"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1834"
            d="M297.3 340.404C297.167 342.985 294.492 343.751 293.079 342.185C291.666 340.619 292.275 337.624 294.845 337.452C296.355 337.452 297.415 338.709 297.326 340.404"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1835"
            d="M348.154 142.728C348.763 144.449 347.695 145.051 346.661 144.561C345.046 143.786 344.251 140.946 345.549 140.568H345.69C346.573 140.482 347.695 141.488 348.154 142.762"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1836"
            d="M335.748 143.967C336.154 145.361 335.351 146.548 334.061 145.86C332.393 144.948 332.083 142.314 333.258 141.893H333.443C334.529 141.893 335.483 143.115 335.748 144.01"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1837"
            d="M276.47 340.541C276.267 342.718 273.592 343.983 272.055 342.03C270.519 340.077 272.002 337.478 273.945 337.486C274.689 337.577 275.369 337.947 275.838 338.517C276.308 339.087 276.532 339.813 276.462 340.541"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1838"
            d="M329.391 144.509C329.912 146.359 328.746 147.005 327.713 146.445C326.167 145.585 325.726 142.754 327.095 142.427H327.245C328.128 142.366 329.011 143.287 329.391 144.535"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1839"
            d="M316.78 145.429C317.089 147.039 315.985 148.063 314.811 147.254C313.133 146.101 313.442 143.571 314.608 143.304C314.655 143.3 314.702 143.3 314.749 143.304C315.632 143.304 316.559 144.276 316.78 145.455"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1840"
            d="M265.953 340.472C265.723 342.959 263.127 343.596 261.776 342.039C260.266 340.318 261.405 337.331 263.542 337.349C263.912 337.395 264.269 337.513 264.592 337.696C264.914 337.88 265.195 338.124 265.418 338.416C265.642 338.707 265.802 339.04 265.891 339.393C265.98 339.746 265.995 340.113 265.935 340.472"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1841"
            d="M222.8 143.477C222.482 144.199 221.643 145.275 220.645 145.198C219.241 144.914 219.762 142.298 221.457 141.256C221.696 141.09 221.985 141.005 222.279 141.015C223.232 141.205 223.162 142.564 222.817 143.434"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1842"
            d="M216.936 142.718C216.053 144.689 214.463 144.965 214.092 144.001C213.615 142.779 215.072 140.283 216.529 140.352C217.315 140.447 217.492 141.488 216.936 142.718Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1843"
            d="M205.509 141.402C204.626 142.788 203.072 143.545 202.71 142.409C202.225 140.886 204.335 138.683 205.359 138.821C206.242 139.036 206.136 140.352 205.492 141.402"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1844"
            d="M199.991 140.447C198.905 142.263 197.43 142.469 197.165 141.574C196.732 140.154 198.931 137.951 200.044 137.986C200.865 138.235 200.494 139.604 199.991 140.447Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1845"
            d="M184.158 137.547C183.046 139.148 181.509 139.552 181.341 138.614C181.077 137.272 183.372 134.871 184.397 135.06C184.918 135.258 184.909 136.463 184.158 137.547Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1846"
            d="M174.32 135.344C173.128 136.807 171.583 137.323 171.512 136.29C171.424 134.956 173.623 132.676 174.833 132.848C175.504 133.098 175.115 134.38 174.32 135.344Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1847"
            d="M160.723 131.609C159.469 132.952 158.021 133.433 158.012 132.47C158.012 131.17 160.449 128.838 161.606 129.027C162.259 129.363 161.517 130.749 160.723 131.54"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1848"
            d="M148.829 127.298C147.549 128.529 146.18 129.019 146.18 128.305C146.092 127.126 148.829 124.622 149.986 124.863C150.515 125.19 149.685 126.498 148.864 127.298"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1849"
            d="M145.18 125.766C143.715 127.152 142.531 127.427 142.531 126.739C142.531 125.62 145.551 122.987 146.443 123.348C146.938 123.675 146.152 124.837 145.163 125.766"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1850"
            d="M138.709 122.556C137.429 123.684 136.14 124.174 136.104 123.555C136.043 122.513 139.319 119.76 140.131 120.173C140.52 120.5 139.698 121.687 138.709 122.556Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1851"
            d="M135.696 120.929C134.186 122.212 133.153 122.453 133.233 121.79C133.348 120.818 136.403 118.158 137.312 118.52C137.736 118.898 136.579 120.163 135.696 120.912"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1852"
            d="M433.207 90.8711C434.187 91.5424 435.397 92.4029 435.565 92.5922C435.388 92.5922 431.786 90.0881 431.291 89.6836C431.423 89.6836 433.057 90.8023 433.251 90.9228"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1853"
            d="M444.528 130.672C445.208 131.36 446.108 132.522 445.799 132.875C445.305 133.055 444.033 132.1 443.371 131.42C442.364 130.448 441.605 129.32 441.817 129.019V128.968C442.179 128.735 443.53 129.665 444.466 130.689"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1854"
            d="M446.701 128.847C447.672 129.811 448.467 131.127 447.946 131.119C447.024 130.805 446.197 130.269 445.544 129.561C444.546 128.571 443.831 127.47 444.052 127.255C444.467 127.04 445.897 128.055 446.701 128.847Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1855"
            d="M436.873 94.313C437.665 94.8374 438.42 95.4123 439.134 96.0341C437.595 95.1729 436.141 94.1763 434.789 93.0566C435.519 93.4262 436.217 93.8551 436.873 94.3388"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1856"
            d="M450.32 125.31C451.495 126.42 452.192 127.53 451.707 127.505C450.665 127.462 447.291 124.226 447.733 123.71C448.077 123.46 449.499 124.501 450.32 125.31Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1857"
            d="M451.715 123.451C452.687 124.312 453.632 125.663 453.199 125.663C452.448 125.663 448.907 122.651 449.172 121.902C449.393 121.678 450.753 122.565 451.715 123.451Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1858"
            d="M438.223 95.9995C439.047 96.5341 439.815 97.1451 440.519 97.8239C440.157 97.8755 436.413 95.1648 436.104 94.7861C436.833 95.1467 437.527 95.5701 438.179 96.0511"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1859"
            d="M453.703 119.776C454.409 120.396 455.469 121.497 455.469 121.893C455.001 122.281 451.204 119.011 451.274 118.279C451.495 118.004 452.89 119.062 453.712 119.794"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1860"
            d="M454.391 117.97C455.354 118.83 456.334 119.984 456.06 120.044C455.592 120.156 451.928 117.247 451.91 116.421C452.096 116.232 453.455 117.135 454.391 117.97Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1861"
            d="M440.306 99.5107C441.11 100.026 441.833 100.653 442.452 101.37C441.975 101.438 438.381 98.7879 438.037 98.2285C438.869 98.5326 439.639 98.9752 440.315 99.5366"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1862"
            d="M454.603 114.114C455.619 114.975 456.652 116.016 456.581 116.231C456.157 116.421 452.413 113.288 452.369 112.712C452.44 112.428 453.773 113.426 454.603 114.114Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1863"
            d="M454.426 112.273C455.168 112.893 456.449 114.106 456.387 114.33C455.919 114.416 452.51 111.593 452.201 110.888C453.034 111.225 453.79 111.719 454.426 112.342"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1864"
            d="M440.872 101.223C441.691 101.739 442.419 102.381 443.026 103.125C442.656 103.28 438.717 100.457 438.611 99.9495C438.77 99.8118 440.068 100.655 440.898 101.258"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1865"
            d="M453.05 108.478C453.825 109.074 454.538 109.743 455.178 110.474C454.719 110.474 451.249 107.574 450.993 107.092C450.988 107.083 450.986 107.073 450.986 107.062C450.986 107.052 450.988 107.042 450.993 107.032C451.736 107.42 452.428 107.897 453.05 108.452"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1866"
            d="M451.857 106.515C452.658 107.134 453.411 107.81 454.109 108.538C453.809 108.624 450.242 105.655 449.959 105.216C450.627 105.596 451.257 106.037 451.84 106.532"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1867"
            d="M441.446 104.751C442.144 105.285 443.424 106.378 443.345 106.627C442.833 106.765 438.93 103.899 438.93 103.357C439.036 103.134 440.51 104.028 441.464 104.751"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1868"
            d="M448.819 102.807C449.702 103.495 450.788 104.407 451.124 104.743C449.693 103.731 448.32 102.646 447.009 101.49C446.989 101.474 446.974 101.453 446.965 101.43C447.602 101.84 448.212 102.289 448.793 102.772"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1869"
            d="M446.867 100.973C447.839 101.739 449.04 102.694 449.207 102.884C448.943 102.737 445.569 100.061 445.137 99.6738C445.26 99.6738 446.903 100.982 446.903 100.999"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1870"
            d="M441.181 106.473C442.452 107.437 443.415 108.426 443.079 108.443C442.39 108.443 438.664 105.647 438.664 105.113C438.805 104.863 440.201 105.758 441.145 106.473"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1871"
            d="M307.871 340.361C307.606 342.546 304.886 343.751 303.394 341.763C303.093 341.368 302.904 340.903 302.843 340.415C302.783 339.927 302.853 339.431 303.048 338.978C303.243 338.524 303.556 338.127 303.955 337.827C304.355 337.526 304.828 337.332 305.327 337.263C305.705 337.298 306.072 337.407 306.406 337.582C306.74 337.758 307.035 337.998 307.272 338.287C307.509 338.576 307.684 338.908 307.787 339.265C307.89 339.621 307.918 339.994 307.871 340.361Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1872"
            d="M440.166 109.915C440.899 110.508 441.976 111.533 441.932 111.86C441.641 112.049 440.21 111.085 439.557 110.586C438.515 109.777 437.464 108.693 437.526 108.478C437.747 108.288 439.151 109.114 440.175 109.923"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1873"
            d="M439.178 111.636C439.955 112.265 441.023 113.297 440.944 113.624C440.67 113.839 439.284 112.91 438.657 112.428C437.491 111.568 436.529 110.526 436.555 110.234C436.556 110.219 436.556 110.205 436.555 110.191C436.758 109.967 438.074 110.759 439.204 111.636"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1874"
            d="M436.582 114.958C437.624 115.818 438.578 117.057 438.083 117.04C437.16 116.742 436.315 116.248 435.611 115.594C434.657 114.777 433.712 113.71 433.889 113.486C434.233 113.28 435.716 114.218 436.538 114.958"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1875"
            d="M435.027 116.73C436.051 117.634 436.846 118.675 436.369 118.727C435.672 118.804 431.769 115.809 432.201 115.121C432.616 114.923 434.109 115.93 435.018 116.739"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1876"
            d="M430.966 119.932C431.849 120.792 432.643 121.937 432.184 122.006C431.213 122.152 427.769 119.088 428.087 118.365C428.546 118.185 430.1 119.226 430.922 119.983"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1877"
            d="M428.536 121.515C429.623 122.531 430.197 123.537 429.737 123.615C428.731 123.787 425.154 120.482 425.711 119.897C426.232 119.699 427.662 120.758 428.51 121.541"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1878"
            d="M423.08 124.544C423.76 125.233 424.555 126.394 424.192 126.704C423.663 126.859 422.365 125.947 421.649 125.276C420.766 124.415 420.033 123.296 420.21 122.987C420.21 122.987 420.21 122.987 420.281 122.918C420.828 122.754 422.312 123.778 423.089 124.544"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1879"
            d="M471.284 266.216C471.611 267.98 470.799 269.908 469.377 268.867C467.761 267.679 467.514 264.349 468.989 263.703C470.172 263.445 471.037 265.063 471.258 266.233"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1880"
            d="M121.34 408.8C121.551 410.521 120.262 411.27 118.92 410.22C116.951 408.68 117.048 405.917 118.637 405.762C118.752 405.75 118.867 405.75 118.982 405.762C119.618 406.009 120.173 406.42 120.588 406.95C121.004 407.481 121.263 408.111 121.34 408.774"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1881"
            d="M457.774 270.114C457.932 271.087 457.606 272.696 456.59 273.101C454.824 273.324 453.314 270.037 454.745 268.101C454.883 267.875 455.104 267.709 455.363 267.636C456.564 267.43 457.597 268.961 457.782 270.114"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1882"
            d="M450.392 272.085C450.665 273.849 449.659 275.768 448.105 274.667C446.189 273.342 446.4 269.865 447.99 269.504C449.323 269.289 450.215 270.915 450.392 272.085Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1883"
            d="M434.93 275.501C435.071 276.491 434.639 278.126 433.482 278.496C431.575 278.72 430.038 275.622 431.575 273.582C431.761 273.295 432.056 273.091 432.396 273.014C433.72 272.851 434.798 274.374 434.957 275.501"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1884"
            d="M426.629 277.197C426.912 279.081 425.517 281.103 423.83 279.778C422.144 278.453 422.223 275.157 424.113 274.658C425.367 274.434 426.426 275.802 426.629 277.24"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1885"
            d="M409.34 280.303C409.481 281.86 407.962 284.356 405.966 282.6C403.971 280.845 405.216 277.919 406.655 277.67C408.139 277.549 409.234 279.15 409.304 280.303"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1886"
            d="M137.781 412.131C138.002 414.187 136.333 414.437 135.132 413.508C133.366 412.157 133.313 409.205 135.026 409.11C135.155 409.097 135.285 409.097 135.414 409.11C136.064 409.331 136.633 409.733 137.052 410.265C137.471 410.797 137.721 411.436 137.772 412.105"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1887"
            d="M81.92 165.179C80.719 166.711 79.836 167.055 79.7742 166.367C79.6771 165.265 82.2202 161.771 83.0767 162.331C83.4034 162.658 82.8913 163.906 81.92 165.179Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1888"
            d="M84.6933 167.382C83.669 168.742 82.5387 169.379 82.4063 168.665C82.1502 167.339 84.7552 164.078 85.6735 164.56C86.0444 164.887 85.594 166.186 84.6933 167.382Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1889"
            d="M146.426 413.688C146.479 414.506 146.055 415.676 144.66 415.521C142.532 415.013 141.411 411.89 142.991 410.874C143.29 410.688 143.651 410.624 143.998 410.693C144.658 410.911 145.241 411.309 145.676 411.84C146.111 412.371 146.381 413.012 146.453 413.688"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1890"
            d="M91.1396 171.874C89.9652 173.492 88.8525 173.845 88.7113 173.044C88.4552 171.616 91.016 168.415 91.9785 168.974C92.5171 169.422 91.9255 170.79 91.1396 171.874Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1891"
            d="M95.0068 174.009C94.0443 175.36 92.6226 176.152 92.4284 175.145C92.1017 173.518 94.7243 170.618 95.6691 171.1C96.2696 171.548 95.6691 173.079 95.0068 174.009Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1892"
            d="M164.043 416.365C164.184 418.421 162.277 418.869 160.873 417.613C159.213 416.124 159.478 413.508 161.297 413.43C161.412 413.422 161.527 413.422 161.641 413.43C162.308 413.601 162.901 413.976 163.334 414.499C163.766 415.023 164.015 415.667 164.043 416.339"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1893"
            d="M399.908 280.398C400.067 282.816 397.789 283.797 396.376 282.472C394.963 281.147 395.202 278.109 397.259 277.67C398.84 277.541 399.794 278.978 399.908 280.398Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1894"
            d="M108.173 180.282C107.078 181.874 105.577 182.382 105.409 181.211C105.17 179.645 107.343 176.952 108.588 177.27C109.268 177.657 108.932 179.189 108.173 180.282Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1895"
            d="M173.253 417.655C173.253 419.376 171.487 420.237 169.845 418.8C168.079 417.173 168.732 414.712 170.472 414.686C170.56 414.68 170.648 414.68 170.737 414.686C171.419 414.862 172.029 415.24 172.48 415.77C172.931 416.299 173.201 416.952 173.253 417.638"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1896"
            d="M118.319 184C117.171 185.867 115.67 186.168 115.431 185.024C115.114 183.449 117.312 180.618 118.54 181.039C119.423 181.47 118.893 183.079 118.319 184Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1897"
            d="M123.857 185.755C122.921 187.338 121.128 188.173 120.828 186.796C120.448 185.075 122.7 182.493 123.892 182.82C124.722 183.19 124.528 184.627 123.892 185.755"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1898"
            d="M135.3 189.3C134.161 191.219 132.651 191.254 132.324 190.247C131.874 188.827 133.737 185.901 135.291 186.185C136.174 186.435 136.174 187.829 135.291 189.3"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1899"
            d="M141.543 190.806C140.536 192.587 138.735 193.104 138.382 191.718C137.914 189.86 140.148 187.519 141.296 187.768C142.179 188.078 142.267 189.489 141.543 190.806Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1900"
            d="M322.563 423.343C322.272 425.357 319.437 426.544 318.042 424.711C316.85 423.128 318.36 420.908 320.179 420.822C320.511 420.815 320.842 420.878 321.148 421.005C321.454 421.132 321.728 421.321 321.954 421.559C322.179 421.798 322.35 422.08 322.455 422.387C322.56 422.695 322.597 423.021 322.563 423.343Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1901"
            d="M154.408 193.586C153.455 195.599 151.645 196.012 151.185 194.756C150.585 193.155 152.501 190.376 153.985 190.677C155.132 191.021 154.868 192.605 154.408 193.586Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1902"
            d="M161.147 195.049C160.264 196.864 158.365 197.449 157.853 196.073C157.173 194.231 159.354 191.813 160.573 192.028C161.527 192.303 161.8 193.749 161.147 195.049Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1903"
            d="M152.642 112.23C151.123 113.452 150.046 113.684 150.125 113.09C150.249 112.23 153.348 109.648 154.258 109.966C154.514 110.216 153.649 111.429 152.642 112.247"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1904"
            d="M147.451 109.141C146.232 110.061 144.802 110.672 144.917 110.053C145.076 108.96 148.625 106.611 149.191 106.972C149.5 107.299 148.36 108.461 147.424 109.141"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1905"
            d="M99.6081 388.44C99.7317 390.359 98.4248 390.772 97.3122 389.748C95.7492 388.311 95.6345 385.23 97.1356 385.101C97.2667 385.095 97.3979 385.109 97.5241 385.144C98.7604 385.574 99.5198 387.158 99.6081 388.44Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1906"
            d="M107.3 390.35C107.353 391.211 106.973 392.424 105.728 392.2C103.521 391.469 102.902 387.949 104.377 387.192C104.604 387.08 104.864 387.052 105.11 387.115C105.737 387.417 106.272 387.876 106.659 388.444C107.047 389.012 107.274 389.669 107.318 390.35"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1907"
            d="M136.35 90.3885C135.97 90.6123 134.708 91.3265 134.584 91.1975C134.628 90.7586 138.752 88.2544 139.132 88.4351C138.288 89.1768 137.36 89.8235 136.368 90.3627"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1908"
            d="M123.229 394.016C123.317 396.055 121.728 396.451 120.483 395.263C118.814 393.68 119.008 390.694 120.774 390.763C120.857 390.756 120.939 390.756 121.021 390.763C122.337 391.124 123.176 392.69 123.229 393.981"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1909"
            d="M131.67 395.763C131.67 397.433 130.099 398.285 128.668 396.856C126.902 395.092 127.467 392.39 129.145 392.467H129.339C130.005 392.74 130.577 393.193 130.987 393.773C131.397 394.352 131.628 395.034 131.653 395.738"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1910"
            d="M148.944 398.792C148.944 400.823 147.178 401.21 145.844 400.014C144.167 398.465 144.52 395.565 146.392 395.582H146.604C147.289 395.804 147.884 396.233 148.302 396.807C148.72 397.382 148.938 398.071 148.926 398.775"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1911"
            d="M157.995 400.272C157.906 401.993 156.017 402.802 154.515 401.132C152.749 399.248 153.88 396.881 155.398 396.942H155.522C156.242 397.148 156.871 397.581 157.312 398.172C157.752 398.764 157.98 399.481 157.959 400.212"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1912"
            d="M176.387 402.699C176.317 404.791 174.153 405.135 172.855 403.766C171.336 402.174 171.919 399.464 173.782 399.515H174.012C174.733 399.68 175.37 400.09 175.807 400.673C176.245 401.255 176.453 401.971 176.396 402.691"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1913"
            d="M185.968 403.818C185.827 405.685 183.61 406.477 182.109 404.678C180.608 402.88 181.659 400.574 183.478 400.66C184.214 400.807 184.872 401.209 185.329 401.79C185.786 402.372 186.013 403.095 185.968 403.826"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1914"
            d="M328.278 407.518C328.031 409.97 325.108 410.684 323.863 409.101C323.593 408.711 323.432 408.258 323.398 407.789C323.363 407.319 323.455 406.849 323.665 406.425C323.875 406.001 324.196 405.638 324.594 405.372C324.993 405.106 325.456 404.947 325.938 404.91C326.276 404.896 326.613 404.957 326.924 405.086C327.235 405.216 327.512 405.411 327.736 405.659C327.959 405.906 328.123 406.199 328.215 406.516C328.308 406.834 328.326 407.167 328.269 407.492"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1915"
            d="M338.741 406.933C338.529 408.92 335.809 410.306 334.326 408.654C332.975 407.139 334.149 404.626 336.321 404.351C336.662 404.34 337.001 404.401 337.316 404.53C337.63 404.658 337.912 404.852 338.142 405.098C338.372 405.343 338.545 405.634 338.648 405.951C338.751 406.268 338.783 406.603 338.741 406.933Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1916"
            d="M359.236 405.444C358.945 407.913 356.04 408.722 354.821 407.165C354.571 406.757 354.428 406.296 354.407 405.821C354.386 405.347 354.486 404.875 354.699 404.448C354.912 404.02 355.231 403.652 355.627 403.375C356.023 403.098 356.484 402.922 356.967 402.862C357.3 402.832 357.635 402.881 357.945 403.002C358.255 403.124 358.531 403.317 358.749 403.563C358.967 403.81 359.122 404.103 359.201 404.42C359.28 404.737 359.28 405.067 359.201 405.383"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1917"
            d="M369.55 404.489C369.276 406.597 366.468 408.052 365.082 406.27C363.696 404.489 365.559 402.183 367.237 402.011C367.566 401.991 367.895 402.044 368.201 402.165C368.506 402.287 368.78 402.473 369.002 402.71C369.224 402.948 369.388 403.231 369.483 403.539C369.578 403.847 369.601 404.172 369.55 404.489Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1918"
            d="M389.613 402.501C389.18 404.799 386.407 405.633 385.339 404.222C384.385 402.991 385.118 400.264 387.494 399.85C388.774 399.696 389.931 400.711 389.613 402.475"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1919"
            d="M113.392 407.019C113.463 407.518 113.56 409.119 112.006 408.852C109.887 408.215 109.004 404.739 110.355 404.042C110.607 403.922 110.897 403.897 111.167 403.973C112.677 404.429 113.295 406.167 113.41 407.019"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1920"
            d="M518.783 242.938C518.969 243.643 519.207 245.278 518.783 245.786C517.9 246.294 516.523 242.671 516.92 240.881C516.933 240.751 516.992 240.629 517.088 240.537C517.653 240.193 518.492 241.75 518.792 242.929"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1921"
            d="M515.551 245.253C515.833 246.209 516.072 247.835 515.551 248.334C514.597 248.825 513.193 245.219 513.652 243.438C513.681 243.284 513.762 243.145 513.882 243.042C514.526 242.68 515.242 244.161 515.56 245.245"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1922"
            d="M508.257 250.339C508.583 251.656 508.442 253.782 507.515 253.257C506.04 252.396 505.449 248.713 506.358 247.982C507.056 247.706 507.974 249.195 508.257 250.339Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1923"
            d="M504.001 252.817C504.407 254.496 504.054 256.182 503.118 255.674C501.714 254.909 500.955 251.148 502.041 250.382C502.924 250.081 503.692 251.664 503.975 252.826"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1924"
            d="M494.49 257.344C494.782 258.583 494.552 261.036 493.307 260.295C491.479 259.211 491.355 255.519 492.424 255.029C493.307 254.762 494.19 256.225 494.499 257.344"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1925"
            d="M489.157 259.65C489.581 261.422 489.016 263.221 487.886 262.576C486.12 261.586 485.828 257.808 487.073 257.283C488.107 256.973 488.884 258.531 489.157 259.65Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1926"
            d="M477.624 263.996C477.889 265.226 477.359 267.679 476.025 266.913C474.171 265.837 473.995 262.24 475.372 261.603C476.176 261.345 477.315 262.558 477.624 263.996Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1927"
            d="M395.21 104.141C396.341 105.087 396.853 105.913 396.297 105.913C395.21 105.913 391.731 103.116 392.164 102.592C392.711 102.462 394.248 103.452 395.157 104.184"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1928"
            d="M448.413 184.387C449.182 185.919 448.943 187.175 447.945 186.883C446.082 186.332 444.616 182.847 445.605 182.193L445.711 182.141C446.594 181.934 447.866 183.311 448.36 184.396"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1929"
            d="M453.289 182.468C453.907 183.647 454.013 185.342 452.971 185.049C451.002 184.499 449.483 180.876 450.587 180.334C451.47 180.127 452.768 181.487 453.236 182.468"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1930"
            d="M389.551 106.472C390.239 107.083 391.034 108.107 390.663 108.348C389.401 108.572 386.248 105.483 386.593 104.975C386.608 104.953 386.63 104.935 386.654 104.923C387.219 104.803 388.774 105.784 389.551 106.472Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1931"
            d="M462.065 178.621C462.568 179.481 463.08 180.85 462.453 181.254C461.809 181.435 460.749 180.48 460.078 179.49C459.407 178.5 459.018 177.184 459.371 176.702C459.416 176.65 459.47 176.606 459.53 176.573C460.148 176.306 461.367 177.433 462.074 178.621"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1932"
            d="M466.18 176.779C466.842 177.95 467.063 179.602 466.127 179.266C464.485 178.707 462.595 175.325 463.54 174.637C464.246 174.439 465.633 175.798 466.189 176.779"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1933"
            d="M248.09 119.648C247.498 121.085 246.156 121.661 245.741 120.655C245.194 119.338 246.712 117.324 247.604 117.376C248.496 117.428 248.487 118.675 248.09 119.648Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1934"
            d="M473.183 172.666C474.066 174.129 474.269 175.445 473.492 175.247C472.017 174.843 469.828 171.237 470.702 170.652C471.346 170.385 472.565 171.702 473.183 172.683"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1935"
            d="M476.317 170.644C477.058 171.823 477.394 173.285 476.732 173.165C475.389 172.907 473.094 169.318 473.8 168.587C474.365 168.372 475.716 169.688 476.317 170.644Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1936"
            d="M243.065 119.244C242.341 120.879 241.087 121.12 240.743 120.336C240.284 119.295 241.476 116.972 242.739 116.972C243.622 117.144 243.427 118.426 243.065 119.244Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1937"
            d="M481.544 166.349C482.427 167.614 482.904 169.034 482.215 168.931C481.041 168.698 478.56 165.033 479.169 164.422C479.646 164.129 480.856 165.368 481.544 166.375"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1938"
            d="M483.665 164.13C484.239 164.939 484.927 166.324 484.689 166.711C484.336 166.918 483.276 165.954 482.676 165.171C481.907 164.173 481.245 162.779 481.351 162.374C481.347 162.343 481.347 162.311 481.351 162.28C481.757 162.013 482.967 163.192 483.62 164.113"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1939"
            d="M233.353 118.271C232.584 119.673 231.242 120.267 230.924 119.355C230.527 118.219 232.037 116.016 233.211 116.033C233.918 116.214 233.847 117.359 233.344 118.271"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1940"
            d="M487.134 159.878C488.087 161.203 488.555 162.417 488.079 162.322C487.275 162.167 484.546 158.734 484.882 157.908C485.235 157.606 486.436 158.906 487.134 159.878Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1941"
            d="M488.202 157.434C488.794 158.218 489.606 159.594 489.518 160.016C488.979 160.584 486.153 156.574 486.127 155.791C486.119 155.76 486.119 155.727 486.127 155.696C486.365 155.429 487.505 156.557 488.184 157.417"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1942"
            d="M228.556 117.66C227.673 119.218 226.34 119.648 226.093 118.762C225.801 117.703 227.329 115.431 228.556 115.474C229.166 115.56 229.166 116.576 228.556 117.66Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1943"
            d="M489.775 153.184C490.344 153.874 490.789 154.654 491.09 155.49C490.852 155.963 488.185 152.762 487.735 151.462C487.719 151.392 487.719 151.318 487.735 151.247C487.885 150.903 489.059 152.194 489.81 153.175"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1944"
            d="M219.267 116.472C218.331 117.814 216.918 118.382 216.741 117.487C216.52 116.343 218.322 114.191 219.461 114.243C220.123 114.432 219.885 115.568 219.267 116.472Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1945"
            d="M351.633 285.75C351.58 287.962 349.231 289.081 347.704 287.618C345.849 285.854 347.2 283.04 348.922 282.885C349.668 282.925 350.368 283.247 350.874 283.782C351.38 284.317 351.653 285.023 351.633 285.75Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1946"
            d="M214.764 115.689C213.607 117.332 212.309 117.573 212.186 116.782C212.027 115.74 213.846 113.46 215.055 113.555C215.656 113.658 215.532 114.596 214.764 115.689Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1947"
            d="M172.908 104.924C171.689 105.887 170.179 106.55 170.259 105.905C170.347 104.846 173.65 102.609 174.356 102.875C174.78 103.142 173.8 104.235 172.934 104.924"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1948"
            d="M169.959 103.65C168.351 104.889 167.204 105.19 167.31 104.631C167.424 103.77 170.577 101.37 171.513 101.645C171.884 101.834 171.23 102.695 169.985 103.65"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1949"
            d="M158.09 97.0063C156.739 97.8668 155.574 98.2885 155.6 97.8668C155.653 97.1095 159.609 94.6311 160.148 95.0098C160.333 95.2766 159.212 96.2576 158.09 96.9719"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1950"
            d="M156.457 95.5005C154.982 96.4299 153.896 96.8343 153.922 96.4557C153.967 95.9049 157.825 93.2458 158.532 93.6331C158.744 94.0548 156.545 95.4402 156.457 95.5005Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1951"
            d="M342.909 422.216C342.832 422.831 342.538 423.4 342.077 423.825C341.616 424.251 341.017 424.507 340.383 424.548C337.822 424.548 337.602 421.881 339.023 420.521C339.479 420.074 340.083 419.799 340.728 419.746C341.051 419.729 341.375 419.783 341.674 419.906C341.973 420.029 342.239 420.217 342.452 420.455C342.665 420.693 342.819 420.976 342.903 421.281C342.986 421.586 342.998 421.906 342.935 422.216"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1952"
            d="M353.143 421.622C352.649 423.799 349.717 424.625 348.604 422.904C347.492 421.183 349.311 419.169 350.891 419.032C351.23 419.006 351.569 419.059 351.883 419.186C352.196 419.313 352.474 419.51 352.694 419.762C352.913 420.014 353.069 420.313 353.147 420.635C353.225 420.957 353.224 421.292 353.143 421.613"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1953"
            d="M380.95 282.885C380.95 284.968 378.831 286.431 377.153 284.864C375.475 283.298 376.164 280.493 378.23 280.114C378.961 280.149 379.65 280.456 380.156 280.972C380.662 281.487 380.946 282.172 380.95 282.885Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1954"
            d="M373.206 419.712C372.764 422.113 369.842 422.862 368.791 421.33C367.74 419.798 369.188 417.492 371.104 417.286C371.422 417.257 371.741 417.304 372.036 417.423C372.33 417.542 372.591 417.729 372.794 417.968C372.998 418.207 373.138 418.491 373.204 418.795C373.27 419.099 373.258 419.414 373.171 419.712"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1955"
            d="M302.642 205.074C302.642 206.984 300.823 208.292 299.366 206.795C297.724 205.143 298.907 202.492 300.408 202.492C301.909 202.492 302.616 204.024 302.642 205.074Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1956"
            d="M383.255 418.516C383.11 419.133 382.781 419.695 382.31 420.132C381.839 420.568 381.246 420.859 380.606 420.968C378.531 421.089 377.762 418.903 379.281 417.208C379.75 416.658 380.404 416.288 381.127 416.166C381.44 416.133 381.757 416.174 382.05 416.285C382.344 416.396 382.606 416.574 382.815 416.804C383.023 417.034 383.172 417.31 383.249 417.608C383.325 417.906 383.327 418.217 383.255 418.516Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1957"
            d="M319.287 204.618C319.358 206.804 317.389 207.518 316.135 206.253C314.881 204.988 315.102 202.131 317.018 201.95C318.148 201.907 319.217 203.198 319.261 204.652"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1958"
            d="M327.702 204.18C327.773 205.961 326.095 207.192 324.603 205.901C322.925 204.403 323.623 201.744 325.301 201.521C326.457 201.478 327.65 202.811 327.702 204.214"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1959"
            d="M411.918 415.891C411.442 417.69 408.907 419.66 407.733 417.552C406.85 415.986 408.616 413.8 410.082 413.464C410.385 413.457 410.686 413.522 410.958 413.652C411.231 413.782 411.467 413.974 411.648 414.212C411.828 414.45 411.946 414.727 411.994 415.019C412.041 415.312 412.015 415.611 411.918 415.891Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1960"
            d="M344.18 202.854C344.516 204.988 342.776 205.883 341.399 204.851C339.73 203.611 339.898 200.548 341.646 200.367H341.726C343.138 200.367 343.986 201.641 344.18 202.863"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1961"
            d="M352.49 202.191C352.71 203.912 351.165 205.272 349.584 204.033C348.004 202.793 348.066 199.919 349.876 199.609C351.174 199.609 352.322 200.969 352.472 202.191"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1962"
            d="M531.066 369.481C530.801 370.526 530.434 371.543 529.971 372.519C529.971 372.519 529.971 372.562 529.909 372.571C529.591 372.14 530.889 367.924 531.322 367.304L531.401 367.235C531.578 367.304 531.313 368.586 531.075 369.473"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1963"
            d="M368.659 200.453C369.048 202.587 367.334 203.233 366.081 202.424C364.314 201.288 364.173 198.336 365.807 197.906H366.001C367.184 197.786 368.376 198.939 368.65 200.488"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1964"
            d="M376.695 199.309C377.092 201.142 375.732 202.312 374.231 201.426C372.288 200.29 372.235 197.364 373.745 196.899L373.931 196.856C375.176 196.787 376.439 198.112 376.695 199.309Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1965"
            d="M533.926 364.259C533.652 365.274 533.202 366.84 533.043 367.176C533.41 365.415 533.873 363.674 534.429 361.961C534.31 362.756 534.142 363.543 533.926 364.319"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1966"
            d="M371.166 283.822C371.334 286.352 368.95 287.153 367.581 286.069C366.044 284.847 366.036 281.542 368.535 281.146C369.877 281.017 371.06 282.222 371.184 283.822"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1967"
            d="M392.342 198.259C392.766 199.816 391.997 201.512 390.302 200.513C388.156 199.248 388.174 196.15 389.498 195.823H389.587C390.911 195.694 392.033 197.166 392.342 198.276"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1968"
            d="M399.705 197.02C400.023 198.19 399.52 200.238 397.807 199.274C395.484 197.949 395.467 194.972 396.924 194.541C398.028 194.49 399.396 195.884 399.705 197.02Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1969"
            d="M404.155 99.0287C405.144 99.7515 405.992 100.664 405.621 100.75C404.629 100.524 403.703 100.076 402.919 99.4417C402.116 98.8824 400.968 97.8411 401.091 97.6088C401.515 97.4366 403.211 98.366 404.138 99.0545"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1970"
            d="M413.374 194.085C414.019 195.694 413.445 197.26 412.014 196.597C409.904 195.625 409.189 192.363 410.575 191.839H410.681C412.076 191.641 413.1 193.413 413.374 194.11"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1971"
            d="M419.945 192.596C420.299 193.456 420.316 194.928 419.425 195.246C417.658 195.453 415.68 192.036 416.775 190.625C416.875 190.493 417.019 190.399 417.182 190.358C418.259 190.212 419.539 191.589 419.945 192.596Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1972"
            d="M397.639 102.807C398.602 103.573 399.405 104.606 398.946 104.649C397.931 104.752 394.16 101.826 394.699 101.353C395.185 101.215 396.765 102.127 397.631 102.816"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1973"
            d="M432.166 189.611C432.802 191.134 432.404 192.562 431.115 191.986C429.181 191.125 427.98 187.795 429.402 187.244C430.285 187.02 431.556 188.156 432.166 189.611Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1974"
            d="M437.853 187.76C438.392 188.87 438.357 190.841 437.068 190.342C435.125 189.602 433.739 186.168 435.143 185.575C435.849 185.377 437.244 186.495 437.853 187.752"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1975"
            d="M384.872 122.875C385.657 123.873 385.896 125.026 385.084 124.897C383.379 124.622 381.225 121.627 381.949 121.093C381.977 121.07 382.01 121.052 382.046 121.042C382.805 120.938 384.253 122.074 384.872 122.875Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1976"
            d="M421.49 175.6C422.046 176.745 422.029 178.56 420.678 178.053C418.594 177.27 417.437 173.931 418.664 173.457C419.645 173.311 420.96 174.636 421.446 175.617"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1977"
            d="M432.298 172.572C433.004 173.914 432.898 175.291 431.776 174.93C429.86 174.301 428.386 170.971 429.392 170.377H429.498C430.311 170.136 431.644 171.358 432.298 172.615"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1978"
            d="M437.27 170.739C437.923 171.875 438.056 173.535 437.031 173.277C435.053 172.787 433.411 169.267 434.479 168.708C435.362 168.527 436.669 169.801 437.226 170.756"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1979"
            d="M322.307 131.679C322.696 133.09 321.875 134.079 320.806 133.494C318.978 132.513 319.243 130 320.188 129.699C320.226 129.695 320.265 129.695 320.303 129.699C321.009 129.699 322.025 130.68 322.307 131.704"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1980"
            d="M446.48 167.357C447.266 168.639 447.433 170.024 446.48 169.818C444.714 169.448 442.833 165.868 443.68 165.309H443.751C444.572 165.076 445.87 166.367 446.497 167.391"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1981"
            d="M450.709 165.463C451.23 166.281 451.689 167.641 451.15 167.993C450.497 168.14 449.384 167.21 448.669 166.272C448.086 165.498 447.592 164.155 447.857 163.691C447.893 163.628 447.949 163.577 448.016 163.544C448.748 163.338 450.099 164.568 450.709 165.506"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1982"
            d="M64.2347 272.386C64.0934 272.928 63.5547 274.649 62.4244 274.245C60.835 273.384 61.6915 269.348 63.1397 268.918C63.3044 268.855 63.4871 268.855 63.6519 268.918C64.6762 269.494 64.5349 271.353 64.2523 272.36"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1983"
            d="M266.378 305.63C266.078 308.048 263.49 308.616 262.254 307.058C261.018 305.5 261.795 302.549 264.1 302.454C266.025 302.635 266.502 304.459 266.36 305.63"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1984"
            d="M276.638 305.68C276.4 307.746 273.989 308.985 272.453 307.255C270.916 305.525 272.02 302.746 274.219 302.6C274.955 302.707 275.62 303.087 276.074 303.661C276.529 304.236 276.737 304.959 276.656 305.68"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1985"
            d="M392.835 120.569C393.718 121.601 394.01 122.574 393.383 122.565C392.032 122.565 389.126 119.407 389.85 118.805L389.921 118.762C390.672 118.624 392.076 119.751 392.791 120.569"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1986"
            d="M458.269 161.866C459.152 163.2 459.346 164.396 458.613 164.293C457.368 164.104 454.613 160.576 455.584 159.836C456.264 159.612 457.571 160.877 458.233 161.875"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1987"
            d="M461.58 159.827C462.357 160.919 462.878 162.554 462.04 162.357C460.388 161.969 458.251 158.398 458.984 157.951C459.585 157.727 460.918 158.906 461.58 159.827Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1988"
            d="M296.938 305.535C296.796 307.944 294.342 308.736 292.946 307.359C291.551 305.982 292.116 302.721 294.545 302.549C295.913 302.549 297.026 303.934 296.938 305.535Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1989"
            d="M307.243 305.474C306.996 307.556 304.594 308.753 303.023 306.989C302.683 306.614 302.452 306.157 302.355 305.667C302.258 305.176 302.297 304.668 302.469 304.197C302.641 303.726 302.94 303.309 303.334 302.989C303.728 302.668 304.203 302.457 304.709 302.376C305.09 302.404 305.46 302.508 305.798 302.682C306.135 302.856 306.432 303.095 306.67 303.386C306.908 303.677 307.081 304.012 307.18 304.372C307.279 304.731 307.3 305.106 307.243 305.474Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1990"
            d="M396.553 119.304C397.357 120.164 397.816 121.309 397.189 121.317C395.908 121.317 392.897 118.245 393.577 117.6H393.648C394.266 117.488 395.741 118.46 396.553 119.321"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1991"
            d="M327.572 304.571C327.475 307.04 325.029 307.875 323.598 306.481C321.964 304.872 322.9 301.757 325.064 301.653C326.83 301.653 327.616 303.374 327.572 304.571Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1992"
            d="M337.831 303.994C337.77 306.077 335.447 307.514 333.778 305.913C332.109 304.313 333.213 301.241 335.191 301.12C335.931 301.165 336.624 301.491 337.12 302.029C337.615 302.566 337.874 303.272 337.84 303.994"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1993"
            d="M403.414 116.842C404.412 117.849 404.765 118.779 404.156 118.744C402.849 118.675 399.741 115.672 400.368 115.087H400.43C401.136 114.949 402.628 116.068 403.414 116.877"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1994"
            d="M352.507 186.375C352.949 188.354 351.527 189.258 350.185 188.466C348.419 187.399 348.207 184.353 349.911 184H350.044C351.483 183.94 352.331 185.394 352.542 186.384"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1995"
            d="M337.037 187.794C337.152 188.655 336.869 189.937 335.58 190.127C333.585 190.127 332.366 187.252 333.735 185.824C333.846 185.699 333.981 185.597 334.132 185.523C334.284 185.449 334.449 185.405 334.618 185.394C335.854 185.394 336.896 186.667 337.064 187.829"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1996"
            d="M360.296 185.643C360.649 187.416 359.272 188.474 357.85 187.545C356.084 186.366 355.916 183.492 357.647 183.147C358.61 183.061 360.005 184.154 360.296 185.643Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1997"
            d="M305.399 189.516C305.505 191.572 303.739 192.381 302.555 191.349C301.063 190.04 301.575 187.046 303.297 186.994C304.825 187.037 305.319 188.603 305.363 189.516"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1998"
            d="M375.298 183.672C375.863 185.711 374.353 186.408 373.117 185.668C371.412 184.644 370.971 181.744 372.454 181.288H372.622C373.903 181.194 374.953 182.467 375.271 183.706"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_1999"
            d="M157.995 178.174C156.979 179.895 155.187 180.454 154.851 179.103C154.41 177.33 156.679 175.033 157.783 175.256C158.666 175.523 158.728 176.917 157.995 178.174Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2000"
            d="M151.805 176.848C150.719 178.638 149.111 178.922 148.802 177.778C148.387 176.229 150.445 173.561 151.813 173.862C152.917 174.224 152.414 175.85 151.813 176.848"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2001"
            d="M124.492 168.776C123.989 169.499 122.877 170.558 122.002 170.342C120.651 169.611 122.832 166.298 124.466 165.936C124.585 165.905 124.709 165.905 124.828 165.936C125.278 166.212 125.172 167.787 124.492 168.776Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2002"
            d="M119.572 167.073C118.204 168.906 116.985 168.897 116.862 167.994C116.685 166.66 118.901 163.794 120.199 164.147C121.021 164.595 120.279 166.143 119.572 167.073Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2003"
            d="M383.087 183.801C383.396 184.937 382.875 186.908 381.171 185.944C378.875 184.644 379.007 181.805 380.164 181.392H380.314C381.418 181.349 382.769 182.725 383.069 183.836"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2004"
            d="M110.742 163.269C109.63 164.663 108.279 165.18 108.093 164.276C107.846 162.847 110.327 159.973 111.493 160.369C112.067 160.765 111.546 162.228 110.707 163.269"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2005"
            d="M106.733 161.178C105.356 162.942 104.243 163.028 104.084 162.314C103.863 161.092 106.336 157.942 107.537 158.398C108.058 158.691 107.696 159.913 106.707 161.178"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2006"
            d="M99.4927 157.167C98.3978 158.432 97.2145 158.974 97.0909 158.243C96.8878 156.961 99.5987 153.94 100.623 154.319C101.038 154.715 100.376 156.177 99.5281 157.167"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2007"
            d="M396.844 181.418C397.365 182.923 396.747 184.266 395.387 183.629C393.294 182.648 392.57 179.533 393.912 179.025L394.063 178.982C395.025 178.819 396.35 179.98 396.844 181.418Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2008"
            d="M403.371 179.921C403.724 180.781 403.733 182.167 402.841 182.451C401.004 182.588 399.053 179.189 400.192 177.898C400.29 177.787 400.424 177.711 400.572 177.683C401.658 177.563 402.938 178.922 403.344 179.912"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2009"
            d="M96.4369 154.973C95.1654 156.479 94.1764 156.806 94.0881 156.152C93.9379 155.102 96.6312 151.721 97.6202 152.237C98.0175 152.547 97.5142 153.726 96.4546 154.973"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2010"
            d="M91.316 150.74C90.2211 151.987 89.0996 152.581 89.0996 151.919C89.0996 150.654 91.9165 147.616 92.6317 148.055C92.8966 148.356 92.252 149.638 91.2895 150.74"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2011"
            d="M415.635 177.098C416.041 177.959 416.324 179.301 415.396 179.628C413.489 179.861 411.617 176.143 412.597 175.102C412.687 175.005 412.804 174.937 412.933 174.904C413.816 174.706 415.052 175.876 415.644 177.09"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2012"
            d="M89.1529 148.726C87.9078 150.085 87.1573 150.344 87.1484 149.793C87.1484 148.881 90.0183 145.413 90.7247 145.938C90.9631 146.368 90.0889 147.659 89.1529 148.726Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2013"
            d="M406.478 115.431C407.361 116.291 407.962 117.376 407.361 117.384C406.169 117.384 402.946 114.424 403.449 113.787L403.511 113.744C404.05 113.632 405.586 114.605 406.461 115.465"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2014"
            d="M397.604 298.065C397.656 298.694 397.477 299.32 397.101 299.834C396.725 300.347 396.174 300.714 395.547 300.87C393.26 301.017 392.015 298.048 393.631 296.189C393.951 295.792 394.41 295.523 394.92 295.432C395.633 295.438 396.315 295.717 396.82 296.207C397.325 296.698 397.613 297.362 397.622 298.056"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2015"
            d="M412.112 112.746C413.189 113.727 413.692 114.631 413.136 114.622C411.953 114.622 408.597 111.688 409.057 111.094C409.057 111.094 409.057 111.094 409.11 111.051C409.701 110.896 411.255 111.972 412.112 112.772"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2016"
            d="M416.325 296.903C416.325 299.037 414.559 300.56 412.926 299.003C411.292 297.445 411.919 294.467 413.809 294.02C415.098 293.822 416.299 295.199 416.325 296.903Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2017"
            d="M470.012 153.975C470.851 155.094 471.381 156.488 470.763 156.385C469.509 156.178 466.913 152.779 467.451 152.082C467.893 151.892 469.217 153.003 470.003 154.001"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2018"
            d="M230.606 130.766C229.829 132.487 228.354 132.926 227.957 131.919C227.497 130.671 229.025 128.339 230.217 128.477C231.162 128.657 230.941 129.991 230.579 130.783"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2019"
            d="M473.994 149.784C474.63 150.55 475.433 151.815 475.23 152.211C474.886 152.443 473.773 151.471 473.138 150.74C472.184 149.638 471.486 148.416 471.61 148.055V148.003C471.901 147.719 473.067 148.692 473.959 149.776"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2020"
            d="M425.26 295.079C425.331 296.086 424.739 297.73 423.494 298.065C421.499 298.229 420.059 295.148 421.728 293.109C421.962 292.795 422.304 292.575 422.691 292.489C424.13 292.334 425.163 293.926 425.251 295.071"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2021"
            d="M475.664 147.779C476.547 148.854 477.262 150.248 476.768 150.18C475.77 150.059 473.015 146.737 473.289 145.971C473.589 145.748 474.843 146.772 475.664 147.779Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2022"
            d="M219.991 129.622C219.108 131.136 217.598 131.713 217.342 130.628C216.971 129.191 218.905 127.126 219.849 127.229C220.565 127.393 220.653 128.529 220.026 129.622"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2023"
            d="M477.899 143.528C478.958 144.75 479.665 145.937 479.25 145.929C478.464 145.929 475.47 142.418 475.718 141.781C475.947 141.488 477.175 142.641 477.934 143.502"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2024"
            d="M414.505 111.232C415.458 112.049 416.271 113.133 415.732 113.176C414.708 112.908 413.773 112.383 413.021 111.653C412.253 111.008 411.308 109.889 411.564 109.674C412.05 109.519 413.648 110.535 414.496 111.24"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2025"
            d="M477.201 130.663C477.899 131.361 478.529 132.122 479.082 132.935C478.711 132.874 475.735 129.63 475.4 129.019C475.395 128.999 475.395 128.979 475.4 128.959C476.066 129.466 476.673 130.043 477.21 130.68"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2026"
            d="M475.911 128.511C476.596 129.22 477.241 129.964 477.845 130.74C476.545 129.544 475.336 128.258 474.225 126.894C474.838 127.41 475.414 127.968 475.947 128.563"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2027"
            d="M464.758 286.276C464.925 288.264 463.68 289.89 462.338 288.702C460.81 287.36 460.925 284.124 462.524 283.539C463.53 283.281 464.616 284.606 464.758 286.276Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2028"
            d="M214.781 128.847C213.837 130.465 212.441 130.895 212.132 130.026C211.708 128.83 213.554 126.377 214.781 126.532C215.664 126.739 215.276 128.03 214.781 128.847Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2029"
            d="M177.428 121.446C176.298 122.616 174.779 123.287 174.779 122.384C174.779 121.196 177.216 118.942 178.311 119.088C178.877 119.329 178.311 120.534 177.428 121.437"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2030"
            d="M173.439 120.164C172.052 121.592 170.79 121.962 170.701 121.248C170.613 120.12 173.35 117.633 174.366 117.926C175.046 118.227 174.057 119.518 173.439 120.164Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2031"
            d="M471.726 283.977C471.858 284.976 471.523 286.637 470.551 287.05C468.909 287.334 467.505 284.038 468.9 282.024C469.036 281.793 469.252 281.616 469.509 281.525C470.631 281.301 471.576 282.833 471.735 283.977"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2032"
            d="M418.92 108.383C420.104 109.347 420.686 110.216 420.263 110.25C419.512 110.311 415.468 107.445 415.971 106.808C416.412 106.636 417.967 107.608 418.92 108.374"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2033"
            d="M484.548 279.976C484.548 280.062 484.804 282.222 483.612 282.799C482.049 283.178 480.857 279.692 481.907 277.825C482.021 277.581 482.226 277.39 482.481 277.291C483.771 276.956 484.442 278.952 484.557 279.976"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2034"
            d="M236.133 131.24C235.85 131.945 235.091 132.961 234.146 132.961C232.821 132.728 233.263 130.284 234.835 129.226C235.053 129.057 235.324 128.966 235.603 128.968C236.442 129.114 236.539 130.259 236.15 131.222"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2035"
            d="M368.076 301.524C368.129 303.616 365.93 305.19 364.182 303.693C362.222 301.972 363.599 298.994 365.383 298.822C366.104 298.842 366.789 299.136 367.292 299.641C367.795 300.145 368.077 300.821 368.076 301.524Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2036"
            d="M467.426 155.92C468.415 157.202 468.803 158.441 468.212 158.407C467.288 158.045 466.502 157.414 465.96 156.6C465.192 155.593 464.591 154.328 464.909 154.018C465.448 153.751 466.675 154.956 467.417 155.894"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2037"
            d="M357.991 302.618C357.991 305.07 355.439 305.922 354.035 304.502C352.631 303.082 353.152 300.036 355.413 299.709C357.117 299.666 358.009 301.18 357.991 302.618Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2038"
            d="M108.924 352.331C108.747 354.517 107.158 354.912 106.045 353.613C104.553 351.823 105.118 348.613 106.928 348.768H107.122C108.473 349.181 109.074 350.894 108.959 352.296"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2039"
            d="M372.119 429.763C371.985 430.36 371.669 430.903 371.212 431.321C370.755 431.739 370.178 432.012 369.559 432.104H369.47C367.325 432.173 366.821 429.892 368.19 428.412C368.662 427.871 369.329 427.526 370.053 427.448C370.361 427.401 370.677 427.432 370.97 427.537C371.262 427.643 371.522 427.82 371.725 428.051C371.928 428.282 372.066 428.56 372.128 428.858C372.189 429.156 372.171 429.465 372.075 429.754"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2040"
            d="M360.129 383.844C359.82 386.305 357.038 387.114 355.82 385.617C355.534 385.213 355.357 384.745 355.305 384.256C355.253 383.768 355.328 383.275 355.524 382.822C355.719 382.37 356.028 381.972 356.422 381.667C356.817 381.361 357.283 381.157 357.78 381.073C359.546 381.013 360.305 382.424 360.129 383.844Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2041"
            d="M110.557 385.935C110.672 387.786 109.339 388.569 108.058 387.398C106.407 385.884 106.451 382.743 108.12 382.726C108.239 382.73 108.357 382.744 108.473 382.769C110.001 383.233 110.504 385.04 110.557 385.935Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2042"
            d="M165.402 426.467C165.596 428.412 163.822 428.937 162.426 427.844C160.731 426.519 160.793 423.843 162.524 423.696C162.664 423.683 162.806 423.683 162.947 423.696C163.606 423.837 164.201 424.181 164.643 424.677C165.085 425.173 165.351 425.795 165.402 426.45"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2043"
            d="M523.338 394.429C522.981 395.369 522.556 396.284 522.066 397.165C522.585 395.501 523.244 393.88 524.036 392.321C524.051 392.291 524.072 392.265 524.097 392.243C523.893 392.984 523.639 393.711 523.338 394.42"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2044"
            d="M133.701 471.758C134.107 472.825 133.622 473.324 132.588 472.885C130.911 472.179 129.418 469.555 130.496 469.193C130.677 469.141 130.87 469.141 131.052 469.193C131.656 469.416 132.205 469.761 132.661 470.206C133.118 470.652 133.472 471.186 133.701 471.775"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2045"
            d="M328.851 385.85C328.639 388.01 326.017 389.378 324.515 387.571C324.198 387.186 323.992 386.724 323.92 386.235C323.847 385.746 323.911 385.247 324.104 384.79C324.297 384.332 324.612 383.934 325.017 383.636C325.422 383.338 325.901 383.152 326.405 383.096C326.766 383.08 327.126 383.145 327.458 383.284C327.789 383.424 328.084 383.635 328.319 383.902C328.555 384.169 328.725 384.484 328.817 384.825C328.91 385.165 328.921 385.522 328.851 385.867"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2046"
            d="M525.926 382.949C525.476 384.309 524.858 385.729 524.602 385.746C524.293 385.479 525.582 381.59 526.262 380.729C526.341 380.66 526.456 380.54 526.438 380.867C526.343 381.577 526.172 382.275 525.926 382.949Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2047"
            d="M492.027 270.94C492.397 273.083 491.453 274.314 490.473 273.66C489.016 272.696 488.706 269.081 490.04 268.496C491.135 268.169 491.806 269.77 492.027 270.932"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2048"
            d="M135.353 391.09C135.45 393.216 133.71 393.758 132.412 392.528C130.787 390.979 130.99 388.104 132.739 388.001C132.862 387.988 132.986 387.988 133.11 388.001C133.774 388.206 134.351 388.616 134.756 389.168C135.16 389.721 135.37 390.386 135.353 391.065"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2049"
            d="M530.871 374.843C530.562 375.816 530.05 377.382 529.873 377.752C529.873 377.571 531.233 373.277 531.471 372.709V372.623C531.309 373.376 531.096 374.117 530.836 374.843"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2050"
            d="M518.755 256.208C519.294 258.325 518.861 259.728 518.137 259.271C517.254 258.712 516.256 254.969 517.254 254.108C518.137 253.652 518.729 255.967 518.791 256.234"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2051"
            d="M139.45 422.294C139.715 424.161 138.267 424.695 136.96 423.74C135.194 422.457 134.92 419.738 136.527 419.437C136.7 419.41 136.876 419.41 137.048 419.437C137.689 419.625 138.259 419.993 138.686 420.495C139.114 420.997 139.379 421.611 139.45 422.259"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2052"
            d="M507.028 263.781C507.426 265.631 506.852 267.223 505.986 266.706C504.785 265.958 504.026 262.3 505.227 261.543C505.995 261.156 506.728 262.404 506.993 263.815"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2053"
            d="M139 461.62C139.335 462.73 138.77 463.514 137.499 462.894C135.733 462.033 134.594 459.564 135.733 459.082C135.962 459.011 136.209 459.011 136.439 459.082C137.041 459.288 137.584 459.63 138.026 460.078C138.467 460.526 138.795 461.069 138.982 461.663"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2054"
            d="M130.435 434.505C130.753 436.054 129.72 436.76 128.378 435.899C126.558 434.737 125.985 431.906 127.495 431.7C127.656 431.677 127.819 431.677 127.98 431.7C128.598 431.917 129.148 432.287 129.574 432.775C130 433.262 130.287 433.85 130.409 434.479"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2055"
            d="M420.158 342.952C420.087 343.933 419.186 345.628 417.985 345.938C415.857 346.127 415.168 342.986 416.511 341.316C416.849 340.866 417.351 340.558 417.915 340.456C419.531 340.189 420.263 341.747 420.166 342.96"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2056"
            d="M390.937 345.929C390.804 348.364 388.288 349.62 386.857 348.003C385.427 346.385 386.389 343.485 388.491 343.218C390.407 343.089 391.008 344.767 390.937 345.929Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2057"
            d="M61.8858 283.746C61.4796 285.467 60.3405 286.258 59.6694 285.208C58.6097 283.548 59.5899 279.873 60.9939 280.226L61.1264 280.277C61.9388 280.656 62.2567 282.119 61.8858 283.72"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2058"
            d="M266.218 317.144C266.068 319.51 263.516 320.586 262.085 318.865C260.655 317.144 261.591 314.192 263.896 314.209C265.874 314.355 266.28 316.206 266.218 317.169"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2059"
            d="M154.964 439.091C155.176 440.743 153.763 441.363 152.315 440.261C150.549 438.945 150.399 436.466 152.024 436.312C152.176 436.287 152.331 436.287 152.483 436.312C153.129 436.483 153.71 436.837 154.151 437.328C154.592 437.82 154.875 438.427 154.964 439.074"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2060"
            d="M297.062 317.358C296.833 319.759 294.263 320.672 292.85 318.864C291.437 317.057 292.497 314.39 294.616 314.295C294.989 314.325 295.35 314.431 295.679 314.605C296.007 314.779 296.294 315.018 296.522 315.306C296.751 315.594 296.915 315.925 297.005 316.279C297.095 316.632 297.109 317 297.045 317.358"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2061"
            d="M328.057 316.283C327.934 318.675 325.408 319.725 323.925 318.064C322.441 316.403 323.377 313.495 325.532 313.383C325.898 313.397 326.258 313.485 326.588 313.641C326.918 313.798 327.211 314.019 327.448 314.292C327.686 314.565 327.863 314.882 327.967 315.225C328.072 315.568 328.103 315.928 328.057 316.283Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2062"
            d="M342.149 432.31C341.813 434.272 339.023 435.494 337.796 433.73C337.57 433.35 337.45 432.92 337.447 432.481C337.444 432.042 337.558 431.611 337.778 431.228C337.998 430.845 338.317 430.525 338.702 430.298C339.087 430.071 339.527 429.946 339.977 429.935C340.297 429.891 340.623 429.924 340.926 430.032C341.23 430.141 341.501 430.32 341.716 430.555C341.931 430.79 342.083 431.074 342.158 431.38C342.234 431.686 342.231 432.006 342.149 432.31Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2063"
            d="M355.501 445.528C355.059 447.55 352.366 448.514 351.262 447.034C350.379 445.881 351.192 443.592 353.514 443.316C353.813 443.266 354.119 443.291 354.405 443.39C354.69 443.488 354.945 443.656 355.145 443.878C355.344 444.101 355.482 444.369 355.544 444.658C355.607 444.947 355.592 445.246 355.501 445.528Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2064"
            d="M360.199 349.251C359.978 351.832 357.267 352.607 355.969 351.032C354.671 349.457 355.422 346.652 357.735 346.333C359.501 346.282 360.331 347.762 360.199 349.251Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2065"
            d="M292.179 357.907C291.949 359.336 289.459 361.952 287.658 359.473C287.364 359.077 287.182 358.613 287.128 358.127C287.074 357.641 287.151 357.15 287.35 356.701C287.549 356.253 287.865 355.863 288.266 355.569C288.666 355.275 289.138 355.087 289.636 355.024C289.997 355.051 290.349 355.146 290.673 355.305C290.997 355.464 291.285 355.684 291.521 355.952C291.757 356.219 291.937 356.53 292.05 356.866C292.163 357.201 292.206 357.555 292.179 357.907Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2066"
            d="M328.895 351.35C328.631 353.889 325.884 354.586 324.586 352.985C323.288 351.384 324.074 348.579 326.414 348.347C328.463 348.347 329.019 350.145 328.895 351.35Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2067"
            d="M390.857 380.506C390.61 382.864 388.084 384.137 386.689 382.605C386.386 382.2 386.194 381.726 386.131 381.229C386.067 380.731 386.134 380.226 386.326 379.761C386.517 379.296 386.827 378.885 387.226 378.569C387.624 378.252 388.099 378.039 388.605 377.95C390.46 377.821 390.972 379.404 390.857 380.531"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2068"
            d="M297.336 352.288C297.124 354.681 294.528 355.662 293.071 353.898C291.614 352.133 292.709 349.319 294.89 349.276C295.261 349.299 295.623 349.397 295.953 349.565C296.282 349.733 296.572 349.967 296.802 350.251C297.033 350.535 297.2 350.863 297.292 351.214C297.384 351.565 297.399 351.931 297.336 352.288Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2069"
            d="M265.865 352.28C265.609 354.646 262.968 355.524 261.582 353.708C260.302 352.03 261.176 349.13 263.463 349.173C263.836 349.21 264.197 349.322 264.523 349.501C264.85 349.68 265.135 349.924 265.36 350.215C265.586 350.507 265.747 350.841 265.834 351.196C265.921 351.552 265.931 351.92 265.865 352.28Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2070"
            d="M121.577 337.589C121.277 339.853 119.449 340.223 118.39 338.768C116.994 336.867 117.895 333.812 119.776 334.096C121.348 334.543 121.692 336.428 121.542 337.581"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2071"
            d="M146.691 494.132C147.115 494.821 147.106 495.457 146.25 495.268C144.828 494.958 142.674 492.454 143.433 492.007C143.619 491.944 143.821 491.944 144.007 492.007C145.116 492.408 146.051 493.167 146.656 494.158"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2072"
            d="M516.936 401.95C516.177 403.861 515.311 405.169 515.17 404.79C515.002 404.265 516.398 400.866 517.21 400.057C517.307 399.98 517.466 399.85 517.44 400.212C517.329 400.803 517.152 401.38 516.91 401.933"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2073"
            d="M312.073 299.562C311.923 301.808 309.6 302.867 308.117 301.369C306.633 299.872 307.278 296.679 309.627 296.61C309.992 296.634 310.348 296.731 310.672 296.895C310.997 297.059 311.283 297.287 311.513 297.564C311.743 297.842 311.911 298.162 312.007 298.506C312.104 298.85 312.126 299.209 312.073 299.562Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2074"
            d="M281.644 299.941C281.467 302.316 278.995 303.323 277.555 301.602C276.116 299.881 277.017 297.007 279.321 296.998C279.676 297.035 280.02 297.141 280.331 297.311C280.643 297.48 280.916 297.709 281.135 297.984C281.353 298.259 281.513 298.574 281.603 298.91C281.694 299.246 281.713 299.597 281.662 299.941"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2075"
            d="M251.445 299.467C251.171 301.774 248.858 302.694 247.454 301.059C246.05 299.424 247.083 296.361 249.22 296.404C249.575 296.455 249.916 296.577 250.222 296.761C250.528 296.945 250.792 297.187 250.998 297.474C251.205 297.76 251.349 298.085 251.423 298.428C251.497 298.77 251.498 299.124 251.427 299.467"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2076"
            d="M57.6201 263.23C57.0549 265.046 56.0218 265.467 55.5185 264.615C54.6354 263.084 55.7657 259.401 57.0814 259.684L57.2404 259.745C58.1234 260.33 57.9468 262.197 57.6201 263.23Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2077"
            d="M101.162 399.239C101.215 399.971 101.012 401.158 99.9081 400.96C98.142 400.298 96.7027 396.658 98.3539 396.004C98.5725 395.938 98.8065 395.938 99.025 396.004C100.447 396.486 101.1 398.267 101.18 399.274"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2078"
            d="M124.571 404.567C124.774 406.529 123.335 407.062 122.072 406.038C120.306 404.627 120.244 401.598 121.957 401.538C122.075 401.529 122.193 401.529 122.31 401.538C124.253 402.097 124.544 404.283 124.571 404.532"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2079"
            d="M150.055 409.317C150.153 411.193 148.501 411.898 147.071 410.607C145.367 409.076 145.561 406.305 147.371 406.305C147.483 406.297 147.595 406.297 147.707 406.305C148.371 406.497 148.957 406.889 149.379 407.426C149.801 407.962 150.038 408.615 150.055 409.291"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2080"
            d="M177.27 413.189C177.27 415.177 175.292 415.831 173.817 414.325C172.342 412.819 172.74 410.272 174.559 410.22C174.656 410.213 174.753 410.213 174.85 410.22C175.541 410.369 176.158 410.744 176.598 411.283C177.039 411.822 177.276 412.492 177.27 413.181"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2081"
            d="M327.837 418.111C327.754 418.722 327.459 419.286 327.001 419.71C326.543 420.135 325.95 420.393 325.32 420.443C323.015 420.443 322.362 417.81 323.784 416.373C324.247 415.897 324.878 415.611 325.55 415.572C325.884 415.552 326.219 415.607 326.528 415.732C326.838 415.857 327.113 416.049 327.335 416.294C327.556 416.539 327.718 416.83 327.806 417.145C327.895 417.46 327.908 417.79 327.846 418.111"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2082"
            d="M405.013 326.351C405.075 328.933 402.655 330.112 401.242 328.727C399.83 327.341 400.121 324.286 402.549 323.804C403.936 323.615 404.978 324.665 405.013 326.386"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2083"
            d="M479.283 311.627C479.381 313.821 478.047 315.336 476.908 314.321C475.531 313.107 475.84 309.631 477.367 309.157C478.533 308.882 479.222 310.224 479.283 311.627Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2084"
            d="M464.989 244.16C465.218 245.021 465.315 246.544 464.265 246.983C462.499 247.361 461.112 243.85 462.022 242.345C462.157 242.113 462.373 241.936 462.631 241.845C463.647 241.544 464.636 242.783 465.006 244.16"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2085"
            d="M406.134 361.487C405.887 363.879 403.397 365.136 402.09 363.526C401.012 362.201 401.498 359.224 403.856 358.716C405.763 358.552 406.275 360.282 406.152 361.487"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2086"
            d="M358.513 416.115C358.133 418.31 355.316 419.299 354.151 417.638C353.93 417.241 353.814 416.796 353.813 416.344C353.811 415.892 353.924 415.446 354.141 415.047C354.359 414.647 354.674 414.307 355.06 414.055C355.445 413.803 355.888 413.647 356.35 413.602C356.68 413.569 357.014 413.615 357.322 413.736C357.63 413.858 357.903 414.051 358.116 414.299C358.33 414.547 358.478 414.842 358.547 415.159C358.616 415.476 358.604 415.804 358.513 416.115Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2087"
            d="M388.659 412.871C388.558 413.501 388.262 414.085 387.811 414.546C387.359 415.008 386.774 415.324 386.134 415.453C383.794 415.608 383.538 413.086 384.73 411.632C385.205 411.053 385.886 410.669 386.637 410.556C388.032 410.393 388.933 411.417 388.686 412.897"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2088"
            d="M55.4299 243.033C54.7147 244.754 53.761 245.064 53.4078 244.143C52.8073 242.551 54.2908 238.98 55.3946 239.453C55.4238 239.462 55.4508 239.477 55.4741 239.496C56.26 240.09 55.8803 241.94 55.4741 243.033"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2089"
            d="M374.839 398.895C374.424 401.227 371.589 402.26 370.424 400.504C370.202 400.094 370.083 399.638 370.078 399.174C370.073 398.71 370.182 398.251 370.395 397.837C370.609 397.422 370.92 397.062 371.305 396.789C371.689 396.515 372.135 396.334 372.605 396.262C374.556 396.167 374.989 397.828 374.795 398.895"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2090"
            d="M520.53 208.877C520.978 209.773 521.236 210.748 521.289 211.743C520.945 212.285 519.567 209.402 519.197 207.845C519.096 207.569 519.072 207.272 519.126 206.984C519.329 206.588 520.009 207.655 520.53 208.912"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2091"
            d="M507.832 224.04C508.468 225.718 508.353 227.224 507.62 226.906C506.534 226.45 505.077 222.741 505.943 221.915C506.428 221.579 507.249 222.517 507.832 224.04Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2092"
            d="M372.43 295.595C372.385 298.013 370.028 298.9 368.624 297.566C367.22 296.232 367.388 293.117 369.807 292.73C371.458 292.575 372.456 294.089 372.456 295.595"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2093"
            d="M401.568 291.946C401.639 294.339 399.44 295.32 398.036 294.072C396.482 292.695 396.765 289.649 398.919 289.201C400.756 289.046 401.48 290.759 401.568 291.946Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2094"
            d="M123.185 446.088C123.503 447.439 122.638 447.973 121.419 447.198C119.697 446.105 118.876 443.412 120.201 443.197C120.364 443.17 120.532 443.17 120.695 443.197C121.322 443.438 121.878 443.826 122.315 444.326C122.752 444.825 123.057 445.421 123.203 446.062"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2095"
            d="M117.666 427.293C117.86 428.687 116.915 429.419 115.9 428.67C115.206 428.16 114.671 427.472 114.355 426.682C114.275 426.071 114.902 424.178 115.238 424.101C116.544 424.514 117.48 426.235 117.631 427.267"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2096"
            d="M342.449 298.151C342.317 300.578 339.897 301.369 338.52 299.975C337.142 298.581 337.433 295.492 339.897 295.19C341.398 295.104 342.546 296.43 342.449 298.151Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2097"
            d="M146.065 450.778C146.374 452.25 145.243 452.852 143.875 451.949C142.162 450.813 141.579 448.403 142.947 448.093C143.137 448.059 143.332 448.059 143.521 448.093C144.153 448.278 144.723 448.624 145.17 449.097C145.617 449.569 145.926 450.15 146.065 450.778Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2098"
            d="M250.536 334.19C250.377 336.772 247.754 337.521 246.385 335.911C245.017 334.302 245.82 331.299 248.151 331.256C249.617 331.325 250.606 332.572 250.509 334.216"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2099"
            d="M281.601 334.862C281.389 337.255 278.757 338.21 277.336 336.403C276.02 334.733 276.859 331.868 279.199 331.842C279.567 331.87 279.925 331.973 280.25 332.144C280.575 332.315 280.859 332.55 281.085 332.834C281.312 333.119 281.474 333.446 281.563 333.795C281.652 334.144 281.664 334.508 281.601 334.862Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2100"
            d="M339.139 460.312C338.609 462.3 335.73 462.989 334.856 461.345C334.15 460.02 335.43 458.264 337.205 458.109C337.498 458.069 337.797 458.102 338.074 458.205C338.351 458.307 338.598 458.476 338.79 458.695C338.983 458.914 339.116 459.178 339.177 459.46C339.237 459.743 339.224 460.036 339.139 460.312Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2101"
            d="M312.957 334.303C312.851 336.523 310.361 337.952 308.745 336.102C307.332 334.484 308.118 331.524 310.511 331.438C310.869 331.46 311.219 331.553 311.539 331.711C311.859 331.868 312.142 332.088 312.373 332.356C312.603 332.624 312.775 332.935 312.879 333.269C312.982 333.604 313.015 333.956 312.975 334.303"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2102"
            d="M496.627 231.252C497.254 233.18 496.742 234.359 495.885 233.955C494.587 233.318 493.386 229.729 494.517 228.955C495.4 228.602 496.283 230.142 496.627 231.252Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2103"
            d="M344.181 332.96C343.96 335.498 341.197 336.23 339.925 334.629C338.768 333.175 339.192 330.275 341.691 329.982C343.413 329.879 344.34 331.445 344.208 332.96"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2104"
            d="M482.279 237.861C482.809 239.539 482.385 241.217 481.219 240.692C479.55 239.944 478.702 236.218 480.071 235.65C481.087 235.34 481.952 236.803 482.279 237.861Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2105"
            d="M375.025 330.215C375.042 330.863 374.823 331.495 374.408 332C373.993 332.506 373.407 332.852 372.755 332.978C370.327 333.047 369.497 329.914 370.989 328.279C371.384 327.83 371.94 327.544 372.544 327.479C374.433 327.393 375.078 329.062 375.051 330.215"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2106"
            d="M514.067 413.052C513.643 414.011 513.113 414.923 512.486 415.771C513.053 414.144 513.824 412.592 514.782 411.15C514.853 411.081 514.862 411.072 514.897 411.072C514.726 411.77 514.459 412.441 514.102 413.069"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2107"
            d="M486.631 291.112C486.737 291.973 486.631 293.625 485.589 294.107C483.903 294.537 483.037 291 484.018 289.357C484.167 289.074 484.414 288.851 484.715 288.728C485.881 288.419 486.481 289.976 486.64 291.104"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2108"
            d="M102.442 243.936C101.745 245.786 100.182 246.475 99.5193 245.227C98.6363 243.601 100.04 240.262 101.842 240.675C102.725 240.959 103.016 242.396 102.442 243.936Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2109"
            d="M83.9695 237.629C83.2454 239.35 81.7973 240.055 81.3204 238.885C80.6228 237.25 82.071 233.859 83.6693 234.341C84.4905 234.686 84.623 236.062 83.996 237.629"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2110"
            d="M459.85 263.617C460.009 264.426 459.991 266.07 458.817 266.5C456.909 266.835 455.717 263.23 456.803 261.732C456.976 261.493 457.224 261.315 457.51 261.225C458.622 260.932 459.576 262.214 459.859 263.617"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2111"
            d="M121.561 372.029C121.561 374.137 120.006 374.722 118.788 373.638C117.128 372.166 117.331 368.905 119.097 368.793C119.229 368.78 119.362 368.78 119.494 368.793C120.836 369.103 121.552 370.643 121.561 371.994"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2112"
            d="M68.1629 230.65C67.3328 232.5 66.1849 232.844 65.814 231.82C65.1959 230.099 66.9443 226.657 68.1629 227.293C69.0459 227.853 68.6309 229.608 68.1629 230.641"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2113"
            d="M473.898 277.592C474.145 279.658 472.891 281.034 471.699 280.028C470.348 278.874 470.127 275.518 471.699 274.993C473.015 274.684 473.739 276.422 473.88 277.575"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2114"
            d="M479.117 257.525C479.549 259.47 478.719 260.967 477.554 260.27C475.911 259.34 475.443 255.709 476.9 255.158C478.066 254.857 478.861 256.406 479.117 257.525Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2115"
            d="M55.6148 223.059C54.926 224.522 54.043 225.185 53.7074 224.47C53.107 223.171 54.8024 219.367 55.8443 219.686L55.9591 219.737C56.5684 220.323 56.0828 222.044 55.6148 223.059Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2116"
            d="M435.203 304.777C435.309 306.292 433.975 308.934 432.227 307.548C430.478 306.163 430.964 302.875 432.907 302.462C434.973 302.178 435.203 304.751 435.203 304.777Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2117"
            d="M495.339 250.769C495.824 252.577 495.339 254.341 494.173 253.609C492.751 252.697 492.151 249.1 493.29 248.541C494.306 248.188 495.056 249.849 495.303 250.769"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2118"
            d="M162.064 395.616C161.994 397.725 160.051 398.103 158.735 396.787C157.093 395.135 157.596 392.338 159.53 392.389H159.733C160.445 392.576 161.067 392.999 161.493 393.585C161.918 394.171 162.121 394.883 162.064 395.599"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2119"
            d="M147.557 376.796C147.557 379.025 145.685 379.619 144.378 378.362C142.612 376.693 143.159 373.569 145.155 373.655H145.349C146.797 373.905 147.557 375.376 147.557 376.77"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2120"
            d="M508.468 243.696C508.953 245.417 508.75 247.069 507.814 246.57C506.516 245.865 505.642 242.113 506.631 241.476C507.399 241.123 508.167 242.646 508.468 243.696Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2121"
            d="M417.72 308.271C417.72 309.132 417.393 310.741 415.848 311.094C413.508 311.292 412.66 307.841 414.144 306.266C414.444 305.939 414.848 305.721 415.292 305.647C416.775 305.414 417.711 306.8 417.764 308.228"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2122"
            d="M358.769 314.08C358.822 316.661 356.287 317.711 354.813 316.248C353.338 314.785 353.877 311.731 356.225 311.412C358.645 311.412 358.76 313.804 358.769 314.08Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2123"
            d="M518.323 236.114C518.906 237.878 518.827 239.435 518.164 239.143C517.281 238.738 515.939 234.797 516.743 234.031C517.326 233.635 518.032 235.219 518.323 236.114Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2124"
            d="M444.812 249.849C444.988 250.641 444.997 252.233 443.787 252.637C441.809 252.93 440.529 249.496 441.58 247.99C441.758 247.723 442.032 247.53 442.348 247.448C443.726 247.207 444.6 248.747 444.847 249.84"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2125"
            d="M513.405 297.627C513.572 299.675 512.795 301.258 511.965 300.535C510.862 299.571 510.844 295.871 511.965 295.251C512.76 294.916 513.316 296.224 513.431 297.627"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2126"
            d="M421.869 255.081C422.134 257.146 420.597 258.394 419.123 257.301C417.48 256.088 417.295 252.818 419.255 252.413C420.88 252.207 421.719 253.945 421.904 255.081"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2127"
            d="M70.1946 250.322C69.5323 252.275 68.2961 252.748 67.7486 251.776C66.8656 250.21 68.3137 246.69 69.7001 247.009L69.8149 247.052C70.8127 247.62 70.5037 249.435 70.1946 250.322Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2128"
            d="M395.961 257.929C396.226 260.141 394.318 261.423 392.729 260.106C390.963 258.652 391.325 255.631 393.276 255.347C394.813 255.218 395.802 256.578 395.926 257.929"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2129"
            d="M344.039 401.322C343.827 403.826 340.913 404.67 339.668 403.112C339.389 402.717 339.221 402.258 339.18 401.78C339.139 401.302 339.226 400.822 339.434 400.387C339.642 399.953 339.963 399.578 340.365 399.302C340.767 399.025 341.236 398.855 341.726 398.809C342.056 398.793 342.386 398.849 342.691 398.974C342.996 399.099 343.269 399.289 343.488 399.53C343.708 399.772 343.869 400.058 343.96 400.368C344.051 400.678 344.069 401.004 344.013 401.322"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2130"
            d="M375.855 365.256C375.59 367.666 373.029 368.81 371.634 367.132C371.325 366.728 371.125 366.254 371.053 365.755C370.981 365.256 371.04 364.747 371.223 364.276C371.406 363.804 371.707 363.385 372.1 363.057C372.493 362.729 372.964 362.503 373.47 362.399C375.466 362.305 375.987 364.06 375.855 365.256Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2131"
            d="M266.827 282.532C266.641 284.821 264.301 285.854 262.915 284.253C261.528 282.653 262.094 279.684 264.601 279.598C266.005 279.598 266.959 280.888 266.827 282.524"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2132"
            d="M296.541 282.532C296.566 283.157 296.349 283.769 295.934 284.247C295.519 284.725 294.935 285.034 294.298 285.113H294.254C291.763 285.001 291.11 281.671 292.779 280.251C293.166 279.908 293.669 279.715 294.192 279.709C296.117 279.786 296.567 281.594 296.541 282.54"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2133"
            d="M97.6738 366.582C97.6738 368.561 96.2521 368.983 95.2013 367.916C93.6913 366.401 93.8326 363.148 95.4133 363.079C95.5354 363.077 95.6574 363.091 95.7753 363.122C97.0645 363.561 97.7091 365.196 97.6738 366.565"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2134"
            d="M344.747 367.959C344.429 370.48 341.621 371.203 340.393 369.62C339.166 368.036 339.802 365.266 342.309 364.964C344.075 364.878 344.958 366.453 344.747 367.959Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2135"
            d="M326.335 281.775C326.211 284.133 323.853 284.899 322.494 283.548C320.992 282.042 321.522 279.073 323.836 278.832C325.752 278.832 326.361 280.605 326.308 281.775"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2136"
            d="M355.89 279.563C356.031 281.998 353.824 283.005 352.358 281.843C350.636 280.484 350.945 277.274 353.347 276.938C354.742 276.835 355.828 277.945 355.925 279.563"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2137"
            d="M133.967 357.469C133.817 359.457 132.121 360.472 130.735 358.786C129.349 357.099 129.773 353.855 131.883 354.078H131.936C133.561 354.491 134.029 356.324 133.949 357.46"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2138"
            d="M190.374 399.007C190.242 401.038 188.043 401.709 186.639 400.134C185.235 398.559 185.756 395.737 187.972 395.9C188.691 396.043 189.331 396.439 189.771 397.011C190.211 397.584 190.419 398.294 190.356 399.007"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2139"
            d="M384.906 276.697C385.038 278.892 382.972 280.139 381.374 278.84C379.775 277.541 379.97 274.4 382.257 273.995C383.784 273.832 384.826 275.269 384.906 276.697Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2140"
            d="M503.515 284.339C503.78 286.499 502.87 287.781 502.005 287.093C500.83 286.232 500.451 282.627 501.766 281.869C502.649 281.551 503.338 282.867 503.532 284.339"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2141"
            d="M437.684 269.177C437.975 271.113 436.669 272.765 435.115 271.672C433.348 270.416 433.348 267.103 435.203 266.681C436.722 266.466 437.516 268.049 437.684 269.177Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2142"
            d="M310.669 265.088C310.669 267.507 308.462 268.41 307.049 267.162C305.433 265.725 305.91 262.567 308.285 262.369C310.581 262.464 310.669 264.822 310.669 265.088Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2143"
            d="M493.748 168.871C494.234 169.576 494.993 170.902 494.79 171.375C494.137 171.969 491.638 168.027 491.699 167.167C491.691 167.118 491.691 167.069 491.699 167.021C491.964 166.685 492.936 167.692 493.722 168.862"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2144"
            d="M436.794 129.277C437.915 130.422 438.374 131.446 437.783 131.472C436.679 131.472 433.562 128.365 434.074 127.72C434.087 127.701 434.106 127.686 434.127 127.677C434.524 127.444 435.663 128.133 436.776 129.277"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2145"
            d="M443.583 124.175C444.166 124.734 445.208 125.896 444.943 126.248C444.519 126.464 443.247 125.491 442.629 124.949C441.499 123.942 440.784 122.978 440.934 122.677V122.626C441.269 122.367 442.523 123.202 443.583 124.166"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2146"
            d="M448.166 118.959C449.517 120.155 449.932 120.964 449.517 120.938C448.775 120.938 445.34 118.072 445.57 117.401C445.87 117.126 447.336 118.21 448.157 118.959"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2147"
            d="M487.982 175.54C488.945 177.02 489.236 178.337 488.582 178.259C487.646 178.147 485.2 174.55 485.845 173.741C486.534 173.328 487.929 175.463 487.982 175.54Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2148"
            d="M450.108 113.357C451.371 114.389 452.227 115.37 451.936 115.448C451.645 115.525 447.874 112.746 447.848 112.006C448.015 111.73 449.349 112.711 450.108 113.331"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2149"
            d="M479.363 182.072C480.45 183.854 480.326 185.007 479.513 184.766C478.277 184.413 476.184 180.928 477.076 180.196C477.8 179.912 478.842 181.246 479.355 182.081"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2150"
            d="M449.923 107.944C450.586 108.469 451.84 109.545 451.866 109.846C451.645 110.087 447.884 107.126 447.725 106.593C447.875 106.447 449.12 107.35 449.923 107.987"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2151"
            d="M447.178 102.479C447.928 102.999 448.615 103.599 449.226 104.269C447.746 103.362 446.367 102.306 445.111 101.12V101.06C445.856 101.463 446.557 101.939 447.204 102.479"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2152"
            d="M467.946 188.31C468.829 189.937 468.697 191.305 467.681 190.978C466.145 190.479 464.449 186.882 465.491 186.34C466.374 186.039 467.469 187.51 467.92 188.328"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2153"
            d="M441.756 97.0063C442.78 97.7808 443.857 98.6156 444.016 98.7274C443.822 98.6328 440.484 96.0856 439.998 95.7155L439.928 95.6553C440.104 95.7413 441.482 96.7654 441.756 96.9719"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2154"
            d="M453.801 194.154C454.551 195.72 454.322 197.252 453.05 196.735C451.284 196.021 449.942 192.579 451.284 192.071C452.291 191.821 453.359 193.181 453.827 194.154"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2155"
            d="M436.997 199.481C437.836 201.417 436.997 202.587 435.787 202.062C433.968 201.253 432.926 197.889 434.339 197.381H434.41C435.628 197.14 436.635 198.672 436.997 199.515"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2156"
            d="M417.701 204.386C418.266 206.055 417.489 207.561 415.935 206.752C414.063 205.763 413.392 202.587 414.946 202.105H415.008C416.332 201.916 417.348 203.413 417.657 204.403"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2157"
            d="M395.962 208.576C396.456 210.409 395.211 211.726 393.772 210.883C391.891 209.781 391.441 206.743 393.083 206.244L393.269 206.201C394.373 206.029 395.556 207.062 395.918 208.576"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2158"
            d="M371.546 210.943C371.89 213.043 370.212 213.929 368.808 212.948C367.104 211.752 366.857 208.766 368.879 208.37C370.504 208.267 371.369 209.859 371.528 210.952"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2159"
            d="M99.6602 120.422C98.8831 121.085 97.9824 121.773 97.9824 121.498C97.9824 120.964 101.7 117.626 101.921 117.961C101.815 118.426 100.393 119.751 99.6513 120.388"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2160"
            d="M346.292 213.593C346.459 215.676 344.632 216.502 343.316 215.469C342 214.437 341.621 211.218 343.793 210.909C345.038 210.814 346.159 212.019 346.292 213.593Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2161"
            d="M320.541 215.134C320.63 217.079 318.855 218.421 317.336 216.976C315.817 215.53 316.179 212.673 318.219 212.544C319.817 212.544 320.471 214.102 320.524 215.126"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2162"
            d="M100.836 126.265C99.6967 127.28 98.8667 127.754 98.8932 127.358C98.9638 126.497 102.487 123.348 102.823 123.795C102.884 124.226 101.666 125.516 100.827 126.248"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2163"
            d="M294.6 216.158C294.441 218.352 292.312 218.98 291.182 217.646C290.052 216.313 290.379 213.464 292.498 213.344C293.946 213.344 294.705 214.798 294.6 216.192"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2164"
            d="M104.659 132.203C103.449 133.339 102.469 133.821 102.513 133.27C102.593 132.237 105.895 129.183 106.398 129.682C106.628 130.112 105.515 131.403 104.632 132.203"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2165"
            d="M130.584 199.179C129.586 201.193 127.891 201.649 127.449 200.462C126.893 198.947 128.641 195.969 130.328 196.279C131.705 196.821 130.707 198.938 130.584 199.179Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2166"
            d="M120.731 143.769C119.344 145.249 118.152 145.542 118.152 144.742C118.152 143.399 120.952 140.654 121.852 141.119C122.444 141.549 121.508 142.935 120.731 143.769Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2167"
            d="M198.27 164.568C197.934 165.283 197.069 166.487 195.93 166.41C194.367 166.031 195.232 163.088 196.813 162.176C197.102 161.99 197.449 161.908 197.793 161.944C198.791 162.099 198.826 163.398 198.288 164.525"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2168"
            d="M112.888 194.007C111.899 195.685 110.38 196.27 110.036 195.092C109.559 193.448 111.661 190.513 112.994 190.961C113.974 191.408 113.471 193.026 112.888 194.007Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2169"
            d="M97.5504 188.035C96.5349 189.601 95.2104 190.187 94.9013 189.188C94.4598 187.657 96.7557 184.481 97.9301 184.998C98.7513 185.479 98.1332 187.08 97.5239 188.035"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2170"
            d="M303.455 169.8C303.508 171.469 302.157 172.648 300.876 171.521C299.455 170.239 299.878 167.459 301.503 167.382C302.881 167.433 303.419 168.871 303.455 169.826"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2171"
            d="M84.7383 181.521C83.6257 183.113 82.5925 183.414 82.4248 182.623C82.1334 181.306 84.3321 177.933 85.5242 178.44C86.16 178.965 85.4006 180.566 84.7383 181.521Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2172"
            d="M492.873 193.388C493.871 195.221 493.756 196.409 493.032 196.177C491.911 195.832 489.968 192.244 490.772 191.469C491.425 191.16 492.396 192.536 492.864 193.397"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2173"
            d="M250.173 82.6875C249.211 83.9181 248.345 84.2279 248.292 83.7718C248.204 83.0833 249.855 81.1213 250.685 81.1299C251.242 81.2934 250.288 82.5498 250.173 82.6875Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2174"
            d="M482.498 200.151C483.284 201.787 483.151 203.112 482.242 202.828C480.732 202.355 479.133 198.663 480.175 198.06C480.89 197.776 481.941 198.921 482.498 200.151Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2175"
            d="M469.137 206.589C469.826 208.31 469.367 209.549 468.254 209.05C466.797 208.379 465.34 204.834 466.647 204.274C467.663 203.964 468.714 205.531 469.137 206.589Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2176"
            d="M452.917 212.32C453.579 214.041 452.917 215.47 451.734 214.902C450.065 214.118 448.811 210.711 450.374 210.074C451.257 209.824 452.343 210.805 452.917 212.311"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2177"
            d="M434.101 217.724C434.674 219.712 433.527 220.762 432.273 220.065C430.78 219.204 429.624 215.865 431.443 215.28C432.458 215.048 433.65 216.141 434.092 217.724"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2178"
            d="M412.581 222.225C413.137 224.195 411.909 225.581 410.4 224.694C408.519 223.602 408.165 220.349 409.879 219.927H409.967C411.468 219.746 412.333 221.364 412.616 222.242"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2179"
            d="M388.305 225.004C388.729 227.027 387.175 228.36 385.612 227.293C384.049 226.226 383.457 223.034 385.612 222.586C386.813 222.414 387.961 223.447 388.261 225.004"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2180"
            d="M184.494 141.359C183.652 142.332 182.718 143.225 181.704 144.027C181.236 144.328 181.095 143.571 181.095 143.562C180.847 141.841 183.161 139.733 184.097 139.801C184.291 139.853 184.388 140.111 184.397 140.12C184.489 140.474 184.56 140.833 184.609 141.196L184.494 141.359Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2181"
            d="M258.721 83.5995C258.121 84.6493 257.238 85.2345 257.061 84.701C256.84 84.0211 258.077 81.9988 258.898 81.9902C259.304 81.9902 259.233 82.7131 258.721 83.5995Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2182"
            d="M353.47 228.946C353.629 230.297 352.269 232.638 350.45 231.235C348.763 229.944 348.808 226.872 350.98 226.545C351.63 226.562 352.249 226.82 352.712 227.266C353.174 227.711 353.445 228.312 353.47 228.946Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2183"
            d="M335.739 230.495C335.898 232.543 334.07 233.757 332.595 232.578C330.917 231.244 331.05 228.172 333.293 227.896C334.573 227.819 335.624 228.938 335.739 230.478"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2184"
            d="M113.144 106.8C112.87 107.015 111.484 108.047 111.281 107.944C111.192 107.574 115.078 104.502 115.44 104.665V104.752C114.757 105.531 113.98 106.228 113.126 106.825"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2185"
            d="M112.881 112.333C111.689 113.262 110.814 113.718 110.903 113.374C111.088 112.634 114.753 109.734 115.071 110.078C115.071 110.396 113.94 111.455 112.863 112.298"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2186"
            d="M308.727 232.001C308.647 234.247 306.501 234.935 305.248 233.61C303.87 232.156 304.365 229.307 306.449 229.152C308.215 229.204 308.744 230.873 308.709 232.001"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2187"
            d="M115.07 117.909C113.851 118.89 112.977 119.303 112.994 118.899C112.994 118.099 116.747 115.087 117.136 115.543C117.259 115.956 115.961 117.186 115.061 117.909"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2188"
            d="M133.834 217.363C132.863 219.385 131.097 219.661 130.629 218.318C130.161 216.976 131.512 213.869 133.331 214.153C134.452 214.428 134.461 216.038 133.834 217.363Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2189"
            d="M127.37 128.761C125.94 130.069 124.898 130.336 124.924 129.708C124.924 128.675 127.9 125.87 128.863 126.266C129.331 126.67 128.218 127.987 127.361 128.77"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2190"
            d="M97.1526 206.15C96.2254 207.871 94.8214 208.456 94.424 207.363C93.8235 205.72 95.8722 202.536 97.2055 203.061C98.0356 203.482 97.7972 204.98 97.1526 206.167"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2191"
            d="M137.473 133.812C136.21 135.112 134.824 135.663 134.771 134.897C134.7 133.649 137.605 130.947 138.568 131.36C139.204 131.756 138.126 133.133 137.473 133.812Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2192"
            d="M82.3885 199.662C81.3377 201.383 80.1633 201.701 79.9337 200.72C79.5363 199.059 81.6556 195.935 82.7947 196.486C83.5718 197.02 82.9713 198.681 82.3885 199.662Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2193"
            d="M70.6981 192.528C69.9034 193.819 68.8261 194.653 68.5877 193.836C68.1903 192.476 70.4332 188.931 71.3869 189.421C71.9256 189.955 71.2545 191.555 70.6628 192.502"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2194"
            d="M213.226 151.866C212.467 153.536 210.754 154.25 210.312 153.08C209.827 151.763 211.195 149.276 212.741 149.353C213.624 149.474 213.809 150.575 213.226 151.866Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2195"
            d="M251.48 155.119C251.048 156.901 249.388 157.649 248.663 156.376C247.842 154.956 248.946 152.572 250.385 152.624C251.595 152.77 251.701 154.233 251.48 155.119Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2196"
            d="M312.073 155.093C312.267 156.746 311.049 157.761 309.901 156.892C308.444 155.79 308.461 153.088 309.901 152.83H310.06C311.031 152.83 311.887 153.768 312.046 155.111"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2197"
            d="M332.163 153.751C332.525 155.334 331.527 156.376 330.317 155.67C328.692 154.723 328.321 151.875 329.778 151.556C329.831 151.552 329.885 151.552 329.937 151.556C330.997 151.479 331.898 152.624 332.163 153.777"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2198"
            d="M371.29 150.033C371.608 150.73 371.881 151.978 370.866 152.228C369.1 152.348 367.378 149.302 368.217 148.261C368.343 148.104 368.527 148.002 368.729 147.977C369.877 147.865 370.901 149.147 371.307 150.033"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2199"
            d="M388.394 146.789C389.163 148.149 388.862 149.19 387.794 148.906C386.028 148.433 384.368 145.369 385.551 144.819L385.71 144.758C386.77 144.621 387.9 145.894 388.359 146.789"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2200"
            d="M403.538 142.96C403.998 143.632 404.563 144.845 403.786 145.146C402.293 145.37 399.971 142.186 400.536 141.274C400.57 141.219 400.615 141.172 400.668 141.135C400.721 141.098 400.781 141.072 400.845 141.058C401.728 140.886 402.973 142.1 403.556 142.952"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2201"
            d="M416.67 138.691C417.738 140.016 417.853 141.109 416.908 140.946C415.592 140.705 412.952 137.633 413.888 136.979H413.976C414.859 136.772 416.113 138.037 416.67 138.7"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2202"
            d="M427.874 134.234C429.031 135.559 429.102 136.419 428.44 136.359C427.106 136.247 424.334 133.106 425.022 132.504L425.093 132.461C425.649 132.22 426.974 133.192 427.874 134.234Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2203"
            d="M195.293 93.9177C194.074 94.7782 192.644 95.4323 192.75 94.8729C192.873 94.0123 196.052 91.8352 196.803 92.0761C197.227 92.3085 196.132 93.3067 195.293 93.9177Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2204"
            d="M146.947 186.684C145.861 188.603 144.183 188.896 143.865 187.648C143.477 186.151 145.225 183.457 146.788 183.707C147.936 184.077 147.512 185.694 146.947 186.684Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2205"
            d="M90.3628 141.462C89.2943 142.547 88.482 142.994 88.5084 142.486C88.5614 141.454 91.6697 138.235 92.1642 138.7C92.3408 139.173 91.1487 140.628 90.3451 141.436"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2206"
            d="M113.534 176.393C112.369 178.182 110.938 178.613 110.691 177.632C110.347 176.272 112.51 173.208 113.896 173.604C114.929 174.19 113.676 176.186 113.525 176.393"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2207"
            d="M96.1103 147.546C94.9094 148.872 93.8233 149.439 93.8145 148.734C93.8145 147.512 96.8079 144.431 97.4525 144.947C97.8234 145.412 96.7373 146.849 96.1103 147.529"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2208"
            d="M100.147 170.42C98.937 172.141 97.7184 172.494 97.4976 171.685C97.1797 170.446 99.5639 167.159 100.8 167.641C101.63 168.269 100.279 170.222 100.12 170.42"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2209"
            d="M89.3375 164.129C88.1366 165.627 87.1388 165.954 87.077 165.231C86.9798 164.035 89.4788 160.721 90.4502 161.238C90.8475 161.573 90.3442 162.864 89.3375 164.129Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2210"
            d="M104.669 153.734C104.103 154.405 102.973 155.455 102.32 155.214C101.437 154.431 104.324 151.075 105.437 151.014C105.535 151.01 105.632 151.031 105.719 151.075C106.276 151.539 105.278 153.019 104.677 153.742"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2211"
            d="M81.851 157.537C80.6854 158.906 79.8995 159.258 79.8906 158.656C79.8906 157.477 82.6898 154.164 83.2815 154.672C83.5729 155.171 82.575 156.651 81.8421 157.529"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2212"
            d="M204.512 83.5394C203.082 84.3999 201.863 84.8302 201.961 84.4429C202.128 83.7459 205.66 81.7064 206.305 81.9301C206.641 82.1453 205.281 83.0747 204.539 83.5394"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2213"
            d="M116.129 159.663C114.858 161.289 113.48 161.677 113.401 160.782C113.259 159.43 115.599 156.539 116.844 156.952C117.427 157.227 117.118 158.389 116.129 159.663Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2214"
            d="M211.753 85.9573C210.208 87.0502 209.104 87.3858 209.219 86.9384C209.36 86.2757 212.354 84.1846 213.202 84.3567C213.661 84.6149 211.948 85.8455 211.753 85.9831"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2215"
            d="M217.034 87.429C216.348 87.9894 215.542 88.3949 214.676 88.6166C214.12 88.3068 216.504 86.2501 217.908 85.8284C218.034 85.7766 218.172 85.7617 218.306 85.7854C218.756 85.9231 217.82 86.8525 217.043 87.4376"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2216"
            d="M145.772 169.792C144.721 171.47 143.061 172.133 142.761 170.997C142.399 169.586 144.333 166.789 145.869 167.073C146.646 167.288 146.611 168.458 145.772 169.792Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2217"
            d="M163.337 174.12C162.242 175.962 160.546 176.271 160.255 175.075C159.964 173.879 161.58 171.031 163.204 171.237C164.202 171.461 164.087 172.907 163.337 174.12Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2218"
            d="M228.734 90.0362C228.248 90.5181 227.259 91.327 226.667 91.284C225.943 91.0258 227.815 88.9691 229.069 88.4097C229.237 88.3202 229.426 88.2756 229.617 88.2807C230.155 88.4528 229.272 89.5026 228.734 90.0018"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2219"
            d="M316.595 184.146C316.648 184.912 316.383 186.246 315.068 186.392C313.037 186.306 312.26 183.285 313.496 182.089C313.751 181.844 314.091 181.7 314.449 181.685C315.695 181.642 316.533 182.915 316.622 184.172"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2220"
            d="M339.748 182.752C339.987 184.696 338.379 185.436 337.143 184.473C335.642 183.268 335.536 180.376 337.373 180.17C338.874 180.17 339.624 181.728 339.748 182.752Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2221"
            d="M362.407 180.144C362.981 182.123 361.603 183.113 360.243 182.295C358.636 181.323 358.036 178.38 359.714 177.932H359.872C361.347 177.846 362.177 179.361 362.407 180.178"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2222"
            d="M238.402 91.5419C237.413 92.7897 236.344 93.263 236.247 92.6778C236.115 91.9033 237.942 89.7691 238.896 89.8122C239.444 89.9757 238.834 90.9739 238.384 91.5333"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2223"
            d="M384.525 178.303C385.117 180.024 384.19 181.16 382.759 180.437C380.94 179.507 380.199 176.513 381.664 176.048H381.823C382.936 175.884 384.084 177.106 384.472 178.337"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2224"
            d="M404.076 174.455C404.42 175.204 404.729 176.555 403.705 176.899C401.886 177.14 400.031 173.724 400.932 172.597C401.052 172.445 401.225 172.341 401.418 172.304C402.389 172.106 403.537 173.225 404.067 174.412"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2225"
            d="M421.57 170.196C422.347 171.702 422.003 172.846 420.881 172.511C419.115 171.977 417.508 168.785 418.682 168.131L418.833 168.062C419.928 167.864 421.093 169.267 421.57 170.196Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2226"
            d="M436.582 165.274C437.465 166.634 437.465 168.019 436.334 167.701C434.621 167.228 432.802 163.975 433.853 163.355H433.924C434.807 163.131 436.016 164.396 436.573 165.309"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2227"
            d="M449.367 160.076C450.329 161.47 450.4 162.658 449.508 162.477C448.051 162.167 445.826 158.871 446.638 158.174L446.727 158.122C447.283 157.882 448.493 158.742 449.376 160.033"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2228"
            d="M459.699 154.535C460.582 155.731 460.997 156.987 460.194 156.875C459.063 156.729 456.273 153.356 457.103 152.667C457.757 152.383 459.037 153.665 459.69 154.543"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2229"
            d="M248.558 92.824C247.869 93.9427 246.792 94.5451 246.624 93.9685C246.368 93.1854 247.772 91.0168 248.761 91.0254C249.309 91.1029 249.07 92.0064 248.558 92.824Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2230"
            d="M467.434 148.639C468.476 149.895 468.891 150.997 468.317 150.997C467.275 150.997 464.405 147.443 464.979 146.875C465.465 146.574 466.746 147.796 467.425 148.596"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2231"
            d="M472.494 142.426C473.783 143.864 474.207 144.896 473.73 144.853C472.944 144.793 469.977 141.6 470.286 140.834C470.639 140.55 471.84 141.695 472.494 142.435"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2232"
            d="M259.207 93.7108C258.986 94.2358 258.448 95.1221 257.794 95.1308C256.911 94.9586 257.591 92.7728 258.562 92.1016C258.704 91.9876 258.882 91.9239 259.066 91.9209C259.693 92.0242 259.498 93.0482 259.216 93.7108"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2233"
            d="M303.906 93.6678C304.356 94.8984 303.906 95.518 303.226 95.1652C302.14 94.6058 301.46 92.2823 302.343 92.0586H302.361C303.102 92.0586 303.659 93.0913 303.871 93.6764"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2234"
            d="M346.795 166.969C347.21 168.785 345.974 169.551 344.755 168.871C343.051 167.847 342.671 165.041 344.181 164.637H344.358C345.585 164.594 346.53 165.824 346.795 167.003"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2235"
            d="M75.2899 174.542C74.4068 175.841 73.3913 176.478 73.2059 175.902C72.8704 174.826 75.4311 171.186 76.2524 171.599L76.323 171.651C76.685 172.003 76.2435 173.234 75.2899 174.576"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2236"
            d="M367.749 164.405C368.323 166.195 367.158 166.986 365.904 166.315C364.199 165.36 363.564 162.537 364.959 162.124H365.118C366.239 161.978 367.352 163.217 367.767 164.439"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2237"
            d="M387.547 162.142C387.873 162.864 388.165 164.155 387.14 164.439C385.374 164.62 383.52 161.307 384.421 160.291C384.543 160.151 384.712 160.057 384.898 160.025C385.887 159.852 387.026 160.963 387.547 162.133"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2238"
            d="M404.933 158.381C405.816 159.947 405.304 160.868 404.315 160.618C402.549 160.17 400.871 156.995 402.046 156.367L402.205 156.307C403.282 156.143 404.447 157.486 404.933 158.381Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2239"
            d="M420.183 154.052C421.066 155.42 420.995 156.513 420.041 156.341C418.434 156.057 416.315 152.813 417.304 152.159C417.349 152.132 417.396 152.109 417.445 152.09C418.328 151.909 419.485 152.95 420.183 154.052Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2240"
            d="M433.261 149.328C434.25 150.67 434.303 151.797 433.384 151.591C432.015 151.298 429.463 148.08 430.505 147.46C431.327 147.237 432.581 148.467 433.225 149.345"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2241"
            d="M444.061 144.207C444.564 144.818 445.394 146.083 444.891 146.462C444.317 146.66 443.284 145.825 442.507 144.982C441.73 144.138 441.014 143.028 441.288 142.555C441.314 142.505 441.354 142.464 441.403 142.435C441.95 142.176 443.169 143.123 444.052 144.156"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2242"
            d="M452.528 138.803C453.066 139.405 454.002 140.645 453.631 141.04C452.81 141.428 449.525 138.08 449.896 137.22C449.911 137.182 449.935 137.15 449.967 137.125C450.488 136.858 451.786 137.985 452.528 138.794"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2243"
            d="M458.586 133.149C459.708 134.294 460.291 135.352 459.84 135.413C459.16 135.49 455.699 132.341 456.14 131.557C456.405 131.299 457.456 131.979 458.586 133.149Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2244"
            d="M484.389 138.622C485.088 139.376 485.709 140.194 486.243 141.066C484.983 139.86 483.85 138.534 482.861 137.108C482.856 137.085 482.856 137.062 482.861 137.039C483.429 137.516 483.946 138.046 484.406 138.622"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2245"
            d="M462.092 127.332C463.363 128.554 464.087 129.63 463.61 129.604C462.966 129.604 459.725 126.583 459.857 125.878C460.175 125.508 462.012 127.254 462.092 127.332Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2246"
            d="M485.245 152.082C486.128 153.252 486.888 154.577 486.428 154.526C485.634 154.431 482.896 150.963 483.188 150.361C483.426 150.025 484.574 151.221 485.245 152.082Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2247"
            d="M481.156 158.519C482.33 160.059 482.622 161.152 482.092 161.1C481.209 161.032 478.507 157.658 478.984 156.798C479.425 156.471 480.555 157.658 481.156 158.519Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2248"
            d="M463.152 121.506C464.247 122.513 465.236 123.666 464.865 123.64C464.255 123.589 461.068 120.594 461.023 120.043C461.094 119.734 462.366 120.775 463.152 121.506Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2249"
            d="M474.445 165.033C475.328 166.393 475.601 167.554 474.921 167.468C473.694 167.305 471.292 163.716 471.999 163.105C472.599 162.795 473.765 164.129 474.436 165.041"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2250"
            d="M464.767 170.936C465.924 172.658 465.783 173.776 464.9 173.518C463.584 173.139 461.368 169.766 462.321 169.078C463.098 168.794 464.229 170.102 464.767 170.919"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2251"
            d="M452.697 176.891C453.58 178.56 453.244 179.541 452.308 179.266C450.825 178.827 448.776 175.419 450.03 174.757C450.798 174.464 452.017 175.617 452.679 176.891"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2252"
            d="M457.156 109.7C458.083 110.509 459.151 111.481 459.266 111.645C459.125 111.645 455.884 108.762 455.416 108.289L455.363 108.229C455.505 108.229 457.129 109.648 457.129 109.7"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2253"
            d="M437.87 182.08C438.683 183.672 438.33 184.946 437.182 184.567C435.575 184.033 433.8 180.677 435.16 180.032H435.239C436.352 179.791 437.447 181.288 437.888 182.114"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2254"
            d="M249.087 103.934C248.407 105.311 247.321 105.741 247.048 105.061C246.615 104.063 247.931 101.869 248.964 101.929C249.714 102.067 249.449 103.203 249.087 103.934Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2255"
            d="M236.876 102.609C236.037 103.917 234.792 104.528 234.642 103.693C234.465 102.661 236.143 100.578 237.07 100.681C237.794 100.836 237.264 102.015 236.876 102.609Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2256"
            d="M420.686 187.003C421.437 188.775 420.686 189.799 419.529 189.369C417.701 188.672 416.483 185.393 417.843 184.808H417.984C418.982 184.584 420.165 185.789 420.686 187.046"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2257"
            d="M401.074 191.151C401.807 193.096 400.809 194.189 399.484 193.621C397.621 192.812 396.756 189.559 398.266 189.034L398.416 188.991C399.546 188.81 400.606 189.937 401.065 191.151"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2258"
            d="M225.201 101.026C224.194 102.239 222.967 102.747 222.896 102.058C222.799 101.069 224.856 98.9174 225.757 99.0637C226.393 99.2359 225.757 100.355 225.201 101.026Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2259"
            d="M378.821 193.646C379.378 195.531 378.141 196.796 376.623 195.901C374.954 194.92 374.309 191.882 376.049 191.391H376.199C377.709 191.271 378.574 192.837 378.848 193.68"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2260"
            d="M355.236 196.752C355.553 198.783 353.937 199.618 352.586 198.663C350.82 197.381 350.82 194.472 352.648 194.222C354.211 194.162 355.041 195.746 355.191 196.804"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2261"
            d="M330.944 198.835C331.085 200.884 329.381 201.658 328.109 200.634C326.582 199.395 326.608 196.452 328.577 196.202C330.176 196.202 330.864 197.777 330.944 198.835Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2262"
            d="M214.385 98.917C213.025 100.32 211.895 100.595 211.877 100.036C211.877 99.1751 214.146 96.9635 215.179 97.0754C215.912 97.3249 214.544 98.7965 214.385 98.917Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2263"
            d="M281.627 200.41C281.433 202.553 279.455 203.172 278.386 201.89C277.106 200.341 277.971 197.656 279.746 197.656C281.292 197.76 281.724 199.377 281.627 200.41Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2264"
            d="M166.471 190.694C165.588 192.502 163.822 193.164 163.283 191.882C162.612 190.281 164.44 187.528 165.994 187.794C167.204 188.121 166.948 189.705 166.471 190.694Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2265"
            d="M378.01 115.19C378.831 116.145 379.07 117.126 378.469 117.126C377.539 116.874 376.709 116.355 376.085 115.637C375.37 114.845 375.034 113.787 375.308 113.58H375.37C376.103 113.503 377.374 114.484 378.019 115.224"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2266"
            d="M428.969 236.071C429.181 236.932 429.189 238.412 427.944 238.782C425.913 239.006 424.536 235.503 425.657 234.126C425.844 233.897 426.106 233.736 426.399 233.67C427.6 233.429 428.668 234.694 429.048 236.063"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2267"
            d="M394.469 99.0293C395.573 99.8899 396.103 100.57 395.688 100.656C394.805 100.828 391.052 98.0741 391.44 97.5922C391.979 97.4201 393.551 98.4011 394.425 99.0637"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2268"
            d="M318.812 140.937C318.936 141.565 318.891 142.804 317.841 142.951C316.154 142.951 315.192 140.016 316.189 139.053C316.357 138.89 316.58 138.792 316.816 138.777C317.797 138.725 318.582 139.836 318.821 140.937"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2269"
            d="M422.302 93.7025C422.302 93.7025 424.545 95.0966 424.403 95.4236C423.944 95.6215 419.873 93.0657 419.82 92.558C419.997 92.317 421.525 93.1862 422.302 93.6767"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2270"
            d="M337.179 139.363C337.673 140.886 336.87 141.876 335.678 141.222C334.159 140.361 333.496 137.78 334.706 137.332L334.927 137.289C336.066 137.246 336.896 138.503 337.179 139.363Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2271"
            d="M449.916 230.907C450.454 232.783 449.571 234.143 448.256 233.446C446.445 232.499 445.81 229.023 447.372 228.55C448.653 228.3 449.58 229.832 449.898 230.916"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2272"
            d="M386.867 102.506C387.961 103.426 388.412 104.227 387.873 104.227C386.805 104.296 383.458 101.585 383.935 101.051C383.956 101.031 383.98 101.014 384.006 101C384.615 100.853 386.081 101.86 386.867 102.506Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2273"
            d="M468.044 224.953C468.803 226.992 468.044 228.266 466.922 227.758C465.377 227.061 464.203 223.498 465.669 222.819C466.552 222.517 467.567 223.679 468.044 224.953Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2274"
            d="M416.961 102.807C418.232 103.745 418.877 104.528 418.4 104.528C417.694 104.571 413.72 101.946 414.109 101.353C414.444 101.163 415.822 101.921 416.961 102.764"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2275"
            d="M386.946 132.074C387.891 133.382 387.829 134.415 386.831 134.2C385.277 133.873 383.246 131.007 384.111 130.37C384.171 130.332 384.236 130.303 384.305 130.284C385.259 130.138 386.451 131.394 386.955 132.074"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2276"
            d="M400.147 128.597C401.03 129.716 401.11 130.585 400.359 130.542C398.982 130.456 396.412 127.47 397.127 126.782C397.173 126.74 397.227 126.708 397.286 126.687C398.169 126.515 399.494 127.763 400.147 128.597Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2277"
            d="M411.185 106.998C412.43 107.988 413.039 108.874 412.483 108.9C411.512 108.9 408.006 106.163 408.368 105.63L408.412 105.587C408.748 105.406 409.94 106.008 411.185 106.998Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2278"
            d="M403.768 111.154C404.881 112.152 405.314 113.022 404.713 113.013C403.583 113.013 400.36 110.182 400.837 109.631C400.854 109.607 400.879 109.589 400.907 109.579C401.526 109.399 402.991 110.44 403.768 111.154Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2279"
            d="M411.283 124.484C412.316 125.603 412.581 126.498 411.875 126.481C410.568 126.481 407.645 123.374 408.342 122.78H408.422C409.066 122.608 410.373 123.555 411.274 124.501"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2280"
            d="M483.381 218.749C484.035 220.418 483.708 221.873 482.754 221.528C481.368 221.021 479.831 217.337 481.094 216.589C481.792 216.288 482.772 217.217 483.381 218.749Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2281"
            d="M394.443 114.923C395.476 115.973 395.803 116.911 395.096 116.859C393.816 116.765 390.875 113.839 391.564 113.34H391.643C392.2 113.159 393.507 114.019 394.443 114.957"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2282"
            d="M383.405 118.426C383.882 118.976 384.624 120.147 383.997 120.362C382.567 120.594 380.015 117.531 380.518 116.851C380.569 116.793 380.637 116.751 380.712 116.73C381.595 116.575 382.955 117.901 383.414 118.451"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2283"
            d="M420.474 120.069C421.578 121.119 422.037 122.109 421.419 122.1C420.245 122.1 417.092 119.106 417.622 118.521L417.684 118.469C418.152 118.254 419.45 119.114 420.474 120.069Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2284"
            d="M495.815 212.071C496.539 213.792 496.371 215.152 495.488 214.773C494.119 214.179 492.751 210.47 493.722 209.928C494.473 209.584 495.4 211.021 495.85 212.071"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2285"
            d="M505.158 205.014C505.899 206.624 505.952 207.975 505.272 207.734C504.301 207.389 502.473 203.637 503.206 202.923C503.763 202.553 504.699 204.007 505.158 205.014Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2286"
            d="M240.194 127.366C239.567 128.941 238.163 129.647 237.687 128.631C237.121 127.401 238.481 125.069 239.709 125.189C240.751 125.361 240.406 126.841 240.194 127.401"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2287"
            d="M130.533 109.751C129.826 110.268 128.563 111.085 128.246 110.861C128.113 110.5 129.19 109.502 129.941 108.925C131.115 108.013 132.219 107.428 132.511 107.557H132.555C132.749 107.858 131.672 108.873 130.541 109.725"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2288"
            d="M127.22 104.751C125.957 105.612 125.056 106.025 125.118 105.689C125.251 104.958 129.18 102.247 129.533 102.626C129.533 102.987 128.182 104.08 127.246 104.734"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2289"
            d="M136.103 114.803C134.637 115.956 133.657 116.223 133.692 115.732C133.763 114.872 137.057 112.127 137.904 112.514C138.249 112.884 137.021 114.08 136.138 114.803"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2290"
            d="M98.7692 224.875C97.8861 226.7 96.3762 227.276 95.9082 225.968C95.3342 224.385 96.9766 221.149 98.5043 221.666C99.5904 222.147 99.2372 223.912 98.7692 224.901"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2291"
            d="M126.399 99.5882C124.942 100.561 124.253 100.836 124.332 100.604C124.535 99.9583 128.491 97.2734 128.747 97.609C128.597 98.0737 126.628 99.4075 126.407 99.5538"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2292"
            d="M154.4 124.132C153.155 125.336 151.751 125.904 151.698 125.216C151.636 124.2 154.347 121.49 155.522 121.774C156.034 121.989 155.522 122.996 154.4 124.106"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2293"
            d="M82.212 218.266C81.329 220.219 79.9426 220.65 79.5629 219.548C79.0331 218.016 80.755 214.678 82.212 215.159C83.3423 215.839 82.3621 218.016 82.212 218.266Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2294"
            d="M127.52 94.7006C126.997 95.0889 126.42 95.4019 125.807 95.6299C125.807 95.1394 129.86 92.6525 130.178 92.6869V92.7041C129.366 93.447 128.478 94.1073 127.529 94.6747"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2295"
            d="M381.691 128.761C382.106 129.389 382.442 130.43 381.85 130.671C380.331 130.852 378.053 127.857 378.645 127.022C378.704 126.946 378.788 126.892 378.883 126.867C379.404 126.764 381.1 127.814 381.709 128.752"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2296"
            d="M117.904 230.547C117.127 232.457 115.378 233.128 114.778 231.76C114.01 229.961 115.74 227.001 117.33 227.457C118.61 227.905 118.266 229.703 117.904 230.59"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2297"
            d="M68.3574 211.175C67.9601 211.958 67.0859 213.189 66.3441 212.896C65.1785 211.958 67.13 208.094 68.3486 207.982C68.4624 207.968 68.5777 207.993 68.6753 208.051C69.3023 208.473 68.9844 209.987 68.3663 211.192"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2298"
            d="M267.675 248.669C267.463 250.89 265.237 251.879 263.957 250.313C262.783 248.885 263.259 245.89 265.6 245.812C266.933 245.812 267.825 247.077 267.675 248.669Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2299"
            d="M286.262 248.617C286.156 250.339 284.161 252.318 282.562 250.476C281.123 248.824 282.006 246.174 284.037 245.976C286.359 246.087 286.236 248.557 286.236 248.617"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2300"
            d="M179.663 131.833C178.382 133.391 177.013 133.718 176.907 132.832C176.784 131.678 178.93 129.217 180.21 129.389C181.04 129.673 180.325 131.007 179.654 131.825"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2301"
            d="M152.068 99.6489C151.401 99.909 150.709 100.105 150.002 100.234C150.585 99.3735 153.852 97.1103 154.558 97.4029C154.761 97.8762 152.112 99.6489 152.077 99.6661"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2302"
            d="M57.9906 203.801C57.3283 205.005 56.41 205.892 56.1539 205.238C55.7035 204.076 57.6727 200.281 58.5822 200.565C58.6198 200.581 58.6553 200.601 58.6882 200.625C59.1474 201.185 58.5027 202.871 57.9906 203.801Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2303"
            d="M342.362 246.51C342.441 247.887 340.878 250.244 339.068 248.764C337.258 247.284 337.585 244.289 339.898 243.988C340.563 244.017 341.191 244.296 341.651 244.766C342.11 245.236 342.365 245.861 342.362 246.51Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2304"
            d="M210.428 137.375C209.607 138.864 208 139.75 207.638 138.571C207.223 137.246 208.989 134.862 210.287 135.034C211.232 135.249 210.905 136.549 210.455 137.375"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2305"
            d="M227.303 139.458C226.587 141.101 225.06 141.781 224.592 140.671C224.027 139.337 225.519 136.962 226.835 137.074C227.718 137.246 227.762 138.425 227.303 139.458Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2306"
            d="M379.043 242.998C379.431 245.348 377.48 246.441 375.97 245.322C374.274 244.091 374.204 240.933 376.385 240.546C378.539 240.468 378.972 242.74 379.034 242.998"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2307"
            d="M227.302 78.5311C226.039 79.3916 224.936 79.8133 224.98 79.4691C225.05 78.9528 228.026 76.8874 228.83 77.0251C229.148 77.137 228.274 77.8857 227.302 78.5311Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2308"
            d="M167.61 107.17C167.275 107.428 165.297 108.813 165.279 108.822C165.162 108.902 165.042 108.977 164.917 109.046C164.529 109.243 164.281 109.114 164.325 108.873C164.52 107.824 167.858 105.319 168.652 105.646C169.12 105.948 167.972 106.886 167.61 107.17Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2309"
            d="M177.324 111.197C175.708 112.66 174.463 113.297 174.507 112.643C174.56 111.705 177.024 109.201 178.269 109.39C178.516 109.493 177.889 110.689 177.324 111.197Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2310"
            d="M435.389 95.5776C436.183 96.0474 436.903 96.6272 437.526 97.2987C437.244 97.4364 433.447 94.8892 433.164 94.3643C433.959 94.6777 434.705 95.0977 435.38 95.6121"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2311"
            d="M171.371 114.648C170.109 115.818 168.722 116.369 168.669 115.75C168.599 114.829 171.398 112.213 172.51 112.471C173.005 112.66 172.51 113.572 171.371 114.648Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2312"
            d="M431.548 90.4582C432.387 90.9674 433.181 91.543 433.923 92.1793C432.413 91.3619 430.974 90.4236 429.623 89.3739V89.3223C430.292 89.6595 430.938 90.0389 431.557 90.4582"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2313"
            d="M425.481 85.5267C426.444 86.1463 427.583 86.895 427.874 87.1015C427.768 87.0585 424.121 84.7178 423.742 84.4596L423.662 84.3994C423.821 84.4683 425.207 85.3546 425.481 85.5267Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2314"
            d="M251.745 115.826C251.171 117.384 249.934 117.857 249.528 117.005C248.998 115.887 250.191 113.641 251.294 113.71C252.177 113.839 252.036 115.078 251.762 115.826"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2315"
            d="M114.805 218.938C114.011 219.755 113.737 220.031 113.137 219.927C112.536 219.824 112.209 219.256 112.165 218.473C112.086 216.993 113.552 214.532 114.929 214.893C115.362 215.039 115.494 215.289 115.494 215.358C115.494 215.9 115.185 216.373 115.115 216.907C115.044 217.44 115.044 218.189 114.92 218.826C114.92 218.869 114.841 218.903 114.805 218.938Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2316"
            d="M237.45 114.673C236.637 116.196 235.375 116.592 235.127 115.706C234.827 114.647 236.143 112.488 237.335 112.496C238.174 112.66 237.847 113.916 237.441 114.673"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2317"
            d="M436.766 100.63C437.428 101.094 438.7 102.067 438.788 102.428C438.356 102.6 434.541 99.9325 434.373 99.3559C434.373 99.1149 435.609 99.7948 436.74 100.595"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2318"
            d="M182.913 118.073C181.677 119.407 180.211 119.992 180.167 119.183C180.105 118.039 182.693 115.655 183.699 115.913C184.397 116.188 183.478 117.445 182.896 118.073"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2319"
            d="M504.856 179.43C505.81 181.031 506.075 182.098 505.739 182.055C505.148 181.977 502.861 178.346 503.09 177.485C503.311 177.141 504.185 178.234 504.856 179.43Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2320"
            d="M436.051 105.767C437.279 106.696 438.011 107.488 437.711 107.582C437.411 107.677 433.349 105.095 433.411 104.364C433.632 104.097 435.177 105.087 436.06 105.767"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2321"
            d="M152.925 106.533C151.424 107.634 150.4 107.892 150.426 107.445C150.47 106.662 153.852 104.003 154.753 104.381C155.124 104.708 153.87 105.844 152.925 106.533Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2322"
            d="M224.46 125.913C223.577 127.513 222.128 127.875 221.899 126.911C221.669 125.947 223.011 123.469 224.371 123.555C225.193 123.675 225.06 124.854 224.46 125.913Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2323"
            d="M427.75 115.448C428.942 116.472 429.516 117.436 429.013 117.462C428.209 117.462 424.43 114.605 425.022 113.951C425.348 113.744 426.523 114.381 427.75 115.448Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2324"
            d="M514.226 190.101C514.718 190.941 515.064 191.854 515.25 192.803C515.073 193.216 513.811 191.134 513.122 189.464C512.924 189.058 512.801 188.621 512.76 188.173C512.901 187.786 513.722 189.111 514.234 190.093"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2325"
            d="M223.833 112.927C222.95 114.321 221.537 114.94 221.369 114.045C221.166 112.927 222.994 110.715 224.018 110.853C224.831 111.042 224.257 112.299 223.833 112.927Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2326"
            d="M234.368 80.1653C234.217 80.2858 232.884 81.3357 232.266 81.3013C231.71 81.0861 233.696 79.3564 234.986 78.7799C235.157 78.6759 235.358 78.6277 235.56 78.6422C235.922 78.7282 235.215 79.5027 234.376 80.1653"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2327"
            d="M432.89 110.638C434.136 111.628 434.868 112.574 434.418 112.609C433.756 112.652 429.906 109.881 430.241 109.235C430.515 108.986 431.778 109.752 432.89 110.638Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2328"
            d="M500.363 186.435C501.405 188.233 501.502 189.429 500.893 189.223C499.966 188.904 497.935 185.385 498.447 184.576C498.915 184.24 499.877 185.591 500.363 186.435Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2329"
            d="M462.534 152.495C463.47 153.699 463.947 155.076 463.152 154.921C461.783 154.637 459.275 151.29 460.026 150.748C460.573 150.49 461.836 151.608 462.534 152.469"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2330"
            d="M108.783 401.003C108.942 402.432 107.9 403.49 106.664 402.561C104.845 401.201 104.43 398.258 106.011 397.923C106.183 397.892 106.359 397.892 106.532 397.923C107.151 398.194 107.686 398.62 108.082 399.158C108.477 399.696 108.719 400.327 108.783 400.986"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2331"
            d="M410.328 157.012C411.026 158.295 410.867 159.594 409.683 159.198C407.794 158.596 406.398 155.558 407.396 154.999H407.511C408.394 154.844 409.719 155.963 410.328 157.056"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2332"
            d="M240.885 103.159C240.178 104.287 238.96 105.018 238.73 104.218C238.439 103.194 239.949 101.128 240.973 101.146C241.644 101.301 241.344 102.419 240.876 103.159"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2333"
            d="M171.433 396.701C171.447 396.973 171.403 397.245 171.303 397.5C171.204 397.754 171.052 397.986 170.856 398.181C170.66 398.376 170.426 398.529 170.166 398.631C169.907 398.733 169.629 398.782 169.35 398.775C167.274 398.37 165.932 395.169 167.866 393.93C168.206 393.715 168.613 393.627 169.014 393.68C169.707 393.852 170.322 394.242 170.763 394.79C171.204 395.338 171.446 396.013 171.451 396.709"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2334"
            d="M509.103 418.068C508.309 419.66 507.337 420.977 507.275 420.65C507.143 420.159 508.715 416.958 509.607 416.106C509.686 416.054 509.854 415.934 509.801 416.287C509.655 416.912 509.42 417.515 509.103 418.077"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2335"
            d="M164.423 112.204C163.487 112.987 161.774 113.874 161.871 113.065C161.959 112.015 164.873 109.683 165.818 109.907C166.101 110.156 165.306 111.438 164.423 112.17"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2336"
            d="M144.148 392.855C144.077 394.636 142.381 395.488 140.907 393.922C139.061 391.96 140.024 389.507 141.631 389.567H141.825C142.516 389.802 143.113 390.244 143.531 390.83C143.948 391.416 144.164 392.116 144.148 392.829"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2337"
            d="M118.689 387.966C118.689 389.619 117.285 390.488 115.908 389.111C114.071 387.269 114.61 384.464 116.314 384.602H116.491C117.151 384.889 117.711 385.357 118.102 385.951C118.493 386.544 118.697 387.236 118.689 387.94"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2338"
            d="M447.124 142.366C448.175 143.588 448.537 144.845 447.804 144.724C446.621 144.535 443.716 141.385 444.528 140.697C445.19 140.482 446.471 141.609 447.124 142.366Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2339"
            d="M469.527 146.669C470.543 147.917 470.967 149.036 470.41 148.941C469.289 148.752 466.578 145.438 467.081 144.87C467.461 144.604 468.671 145.585 469.554 146.669"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2340"
            d="M462.913 119.527C463.522 120.086 464.776 121.334 464.732 121.618C464.291 121.807 460.944 118.563 460.838 118.081C461.006 117.944 462.154 118.864 462.913 119.561"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2341"
            d="M511.339 261.268C511.683 262.851 511.339 264.865 510.385 264.211C509.131 263.35 508.575 259.719 509.599 259.048C510.376 258.703 511.1 260.158 511.365 261.242"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2342"
            d="M132.942 406.443C132.942 408.035 131.574 408.887 130.134 407.579C128.253 405.858 128.571 403.362 130.205 403.276C130.325 403.267 130.446 403.267 130.567 403.276C131.241 403.513 131.827 403.941 132.249 404.506C132.67 405.07 132.909 405.745 132.933 406.443"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2343"
            d="M473.606 140.327C474.71 141.54 475.372 142.814 474.939 142.753C474.18 142.667 471.266 139.604 471.469 138.795C471.743 138.494 472.926 139.578 473.606 140.327Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2344"
            d="M424.889 152.606C425.772 153.94 425.622 155.042 424.66 154.775C422.991 154.327 421.004 151.264 422.011 150.636L422.108 150.584C422.991 150.42 424.342 151.668 424.925 152.606"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2345"
            d="M130.646 447.628C130.938 448.764 130.169 449.659 128.986 449.022C127.3 448.162 126.072 445.46 127.467 444.969C127.676 444.914 127.895 444.914 128.103 444.969C128.713 445.192 129.26 445.551 129.702 446.017C130.144 446.483 130.467 447.044 130.646 447.654"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2346"
            d="M95.3434 382.157C95.3434 383.706 94.3103 384.644 93.0741 383.5C91.308 381.856 91.4405 378.87 92.9151 378.775C93.0316 378.771 93.148 378.785 93.2595 378.818C94.5752 379.283 95.317 381.064 95.3434 382.157Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2347"
            d="M462.807 125.404C463.822 126.394 464.723 127.556 464.343 127.581C463.645 127.581 460.405 124.492 460.537 123.907C460.705 123.623 461.959 124.595 462.807 125.404Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2348"
            d="M126.311 101.395C125.155 102.161 124.289 102.566 124.342 102.316C124.457 101.688 128.404 98.9686 128.757 99.2784C128.687 99.6312 127.274 100.741 126.32 101.37"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2349"
            d="M154.232 452.224C154.453 453.592 153.261 454.375 151.751 453.299C149.888 451.974 149.738 449.857 151.089 449.556C151.272 449.52 151.461 449.52 151.645 449.556C152.282 449.734 152.858 450.076 153.313 450.545C153.768 451.014 154.086 451.594 154.232 452.224Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2350"
            d="M307.72 93.5647C308.073 94.5371 307.791 95.2341 307.146 94.976C305.945 94.494 305.195 92.2308 305.875 91.878C305.916 91.8558 305.961 91.8412 306.007 91.835C306.643 91.835 307.42 92.7557 307.72 93.5561"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2351"
            d="M460.061 131.239C460.697 131.885 461.651 133.055 461.501 133.442C461.218 133.666 460.088 132.814 459.373 132.143C458.59 131.514 457.983 130.703 457.607 129.785C457.6 129.757 457.6 129.727 457.607 129.699C457.925 129.441 459.205 130.43 460.009 131.239"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2352"
            d="M348.835 459.452C348.656 460.005 348.322 460.499 347.869 460.877C347.416 461.254 346.864 461.5 346.274 461.586C344.508 461.629 343.475 459.925 344.959 458.393C345.458 457.857 346.138 457.515 346.875 457.429C347.152 457.392 347.435 457.419 347.699 457.509C347.964 457.599 348.202 457.749 348.396 457.947C348.589 458.144 348.731 458.384 348.81 458.645C348.89 458.907 348.905 459.183 348.853 459.452"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2353"
            d="M126.84 96.3787C126.461 96.6283 125.198 97.4114 125.074 97.2393C125.127 96.8004 129.189 94.0725 129.489 94.2962C128.694 95.0699 127.804 95.7461 126.84 96.3099"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2354"
            d="M253.36 104.183C252.884 105.294 251.683 106.292 251.285 105.345C250.817 104.218 252.168 102.204 253.051 102.256C253.74 102.376 253.678 103.452 253.369 104.183"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2355"
            d="M437.155 147.65C438.117 148.923 438.241 150.094 437.402 149.922C435.795 149.586 433.464 146.411 434.391 145.825C435.124 145.61 436.448 146.746 437.128 147.658"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2356"
            d="M454.798 136.893C455.84 138.003 456.396 139.182 455.902 139.225C455.407 139.268 454.41 138.536 453.412 137.452C453.006 137.013 451.999 135.731 452.299 135.309C452.82 135.068 454.109 136.17 454.798 136.893Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2357"
            d="M155.61 107.953C154.453 108.813 152.961 109.476 152.961 108.942C153.023 108.082 156.219 105.5 157.199 105.836C157.579 106.085 156.67 107.135 155.584 107.953"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2358"
            d="M521.969 253.626C522.19 254.487 522.367 256.139 521.908 256.69C521.025 257.206 519.806 253.566 520.283 251.888C520.309 251.747 520.38 251.617 520.486 251.518C521.06 251.148 521.713 252.55 521.978 253.617"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2359"
            d="M307.518 317.092C307.315 319.303 304.869 320.586 303.235 318.701C301.602 316.816 302.97 314.174 305.001 314.114C305.37 314.143 305.729 314.244 306.057 314.41C306.385 314.576 306.676 314.805 306.912 315.083C307.148 315.36 307.325 315.681 307.432 316.026C307.539 316.371 307.574 316.733 307.535 317.092"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2360"
            d="M276.567 317.445C276.284 319.665 273.715 320.81 272.214 318.787C271.899 318.404 271.695 317.945 271.625 317.458C271.555 316.972 271.62 316.476 271.814 316.023C272.009 315.569 272.324 315.175 272.729 314.882C273.133 314.589 273.611 314.407 274.112 314.355C274.484 314.394 274.844 314.506 275.17 314.684C275.496 314.862 275.781 315.103 276.009 315.392C276.237 315.681 276.402 316.012 276.495 316.364C276.588 316.717 276.606 317.085 276.549 317.445"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2361"
            d="M245.953 316.61C245.777 318.331 243.534 320.156 241.874 318.237C240.328 316.447 241.379 313.624 243.596 313.581C243.956 313.626 244.303 313.741 244.617 313.919C244.931 314.096 245.206 314.333 245.425 314.614C245.645 314.896 245.804 315.218 245.895 315.561C245.986 315.903 246.006 316.26 245.953 316.61Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2362"
            d="M410.188 173.028C410.789 174.301 410.577 175.902 409.199 175.403C407.212 174.688 406.126 171.659 407.265 171.04C407.325 171.004 407.391 170.977 407.46 170.962C408.572 170.799 409.747 172.098 410.188 173.028Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2363"
            d="M92.0757 143.407C91.4487 144.061 90.3096 145.008 90.0006 144.759C89.8151 144.354 90.6629 143.123 91.281 142.409C92.2346 141.334 93.2236 140.688 93.5239 140.757C93.5589 140.763 93.5905 140.781 93.6122 140.809C93.8241 141.247 92.8174 142.616 92.0757 143.39"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2364"
            d="M413.41 105.664C414.381 106.447 415.176 107.385 414.734 107.454C413.904 107.669 410.196 104.872 410.478 104.252C410.893 104.072 412.333 104.872 413.375 105.698"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2365"
            d="M391.326 177.037C391.865 178.431 391.326 180.032 389.754 179.292C387.652 178.276 387.105 175.316 388.536 174.894H388.615C389.851 174.765 390.964 176.108 391.326 177.054"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2366"
            d="M406.506 109.812C407.574 110.75 408.06 111.61 407.548 111.653C406.497 111.739 403.132 108.9 403.556 108.314C404.06 108.16 405.49 109.003 406.453 109.855"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2367"
            d="M98.6184 149.672C97.6029 150.799 96.296 151.565 96.2695 150.816C96.2695 149.525 99.1217 146.462 99.8635 147.004C100.252 147.443 99.3513 148.863 98.6184 149.672Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2368"
            d="M368.747 415.1C368.42 416.967 365.771 418.619 364.331 416.821C363.122 415.28 364.667 412.931 366.521 412.742C366.842 412.71 367.166 412.752 367.467 412.865C367.768 412.977 368.038 413.157 368.254 413.39C368.471 413.623 368.628 413.902 368.714 414.205C368.799 414.508 368.81 414.826 368.747 415.134"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2369"
            d="M214.517 124.544C213.634 125.895 212.097 126.679 211.868 125.594C211.585 124.235 213.519 122.152 214.561 122.273C215.32 122.462 215.126 123.598 214.508 124.544"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2370"
            d="M108.227 155.799C107.273 156.926 105.701 157.881 105.578 156.883C105.463 155.472 108.112 152.58 109.11 153.071C109.693 153.51 108.925 154.956 108.227 155.799Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2371"
            d="M397.727 113.616C398.778 114.614 399.193 115.612 398.557 115.603C397.286 115.603 394.248 112.686 394.902 112.161H394.963C395.44 112.006 396.729 112.703 397.727 113.684"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2372"
            d="M369.947 179.361C370.327 180.919 369.302 182.201 367.783 181.324C365.797 180.17 365.62 177.425 367.086 177.021H367.236C368.49 176.935 369.656 178.191 369.947 179.422"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2373"
            d="M229.723 126.368C229.105 127.625 227.595 128.7 227.144 127.59C226.623 126.282 228.31 124.045 229.405 124.148C230.288 124.311 230.138 125.542 229.723 126.36"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2374"
            d="M120.5 161.547C119.944 162.253 118.787 163.268 118.027 163.071C116.835 162.313 119.184 159.052 120.676 158.768C120.811 158.723 120.957 158.723 121.091 158.768C121.833 159.172 121.162 160.661 120.482 161.53"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2375"
            d="M347.439 181.959C347.704 183.629 346.433 184.894 344.932 183.81C343.06 182.467 343.324 179.739 344.932 179.507C346.185 179.507 347.263 180.763 347.448 181.942"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2376"
            d="M338.185 417.646C337.797 419.66 334.989 420.813 333.673 418.972C332.516 417.363 333.991 415.254 335.863 415.048C336.2 415.031 336.537 415.09 336.848 415.219C337.159 415.348 337.435 415.544 337.656 415.793C337.878 416.042 338.038 416.337 338.125 416.655C338.212 416.973 338.223 417.306 338.159 417.629"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2377"
            d="M151.495 171.306C151.019 172.072 149.959 173.157 148.979 173.027C147.61 172.485 148.767 169.516 150.674 168.682C150.923 168.552 151.209 168.507 151.487 168.553C152.476 168.897 152.087 170.411 151.487 171.332"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2378"
            d="M245.617 127.754C245.378 128.426 244.672 129.475 243.771 129.536C242.455 129.321 242.773 126.825 244.239 125.835C244.439 125.666 244.699 125.58 244.963 125.594C245.846 125.732 245.899 126.971 245.625 127.754"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2379"
            d="M229.07 101.594C228.187 102.738 226.845 103.461 226.765 102.609C226.668 101.482 228.62 99.5196 229.467 99.6401C230.094 99.8122 229.6 100.905 229.07 101.594Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2380"
            d="M354.459 400.633C354.355 401.266 354.049 401.851 353.584 402.305C353.12 402.759 352.521 403.06 351.872 403.163C349.823 403.163 348.605 400.831 350.318 398.998C350.767 398.486 351.397 398.158 352.084 398.078C352.425 398.059 352.766 398.116 353.083 398.243C353.399 398.37 353.681 398.564 353.91 398.812C354.139 399.06 354.308 399.354 354.404 399.674C354.501 399.994 354.523 400.33 354.468 400.659"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2381"
            d="M385.082 397.622C384.963 398.257 384.657 398.845 384.2 399.313C383.744 399.78 383.157 400.108 382.512 400.255C380.508 400.359 379.36 398.044 381.064 396.15C381.511 395.626 382.139 395.277 382.83 395.169C383.158 395.139 383.488 395.184 383.795 395.3C384.102 395.416 384.377 395.601 384.598 395.84C384.818 396.078 384.978 396.363 385.066 396.673C385.153 396.982 385.164 397.307 385.099 397.622"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2382"
            d="M508.468 281.869C508.68 283.659 508.018 285.406 507.064 284.675C505.819 283.711 505.607 280.2 506.755 279.46C507.559 279.167 508.301 280.493 508.468 281.869Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2383"
            d="M473.73 295.586C473.845 297.548 472.582 299.192 471.293 298.073C469.739 296.731 470.172 293.478 471.593 293.022C472.768 292.772 473.651 294.141 473.73 295.603"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2384"
            d="M453.172 158.32C454.055 159.62 454.179 160.747 453.42 160.653C452.016 160.472 449.649 157.15 450.408 156.427L450.479 156.376C451.203 156.126 452.501 157.348 453.128 158.32"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2385"
            d="M426.752 306.619C426.841 308.495 425.19 310.491 423.476 309.088C421.516 307.488 422.593 304.416 424.271 304.097C425.878 303.916 426.7 305.5 426.752 306.619Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2386"
            d="M534.703 360.179C534.49 361.187 534.195 362.177 533.82 363.139C534.071 361.382 534.497 359.653 535.092 357.976C535.104 357.947 535.122 357.921 535.145 357.898C535.096 358.676 534.963 359.446 534.747 360.196"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2387"
            d="M159.044 410.831C159.044 412.552 157.278 413.361 155.786 411.915C153.967 410.194 154.62 407.682 156.386 407.733H156.589C157.284 407.922 157.897 408.323 158.339 408.878C158.782 409.433 159.029 410.112 159.044 410.814"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2388"
            d="M441.235 163.69C442.118 165.11 441.968 166.272 440.988 165.988C439.221 165.489 437.367 162.253 438.471 161.685C439.354 161.461 440.626 162.726 441.226 163.716"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2389"
            d="M217.845 99.7601C216.768 100.862 215.558 101.387 215.479 100.784C215.364 99.8547 217.625 97.6948 218.622 97.7808C219.179 97.9701 218.516 99.0716 217.845 99.7601Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2390"
            d="M422.497 92.179C423.3 92.6253 424.015 93.2082 424.607 93.9001C424.413 94.2529 420.192 91.6971 420.016 91.1377C420.113 90.9226 421.543 91.6627 422.497 92.2478"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2391"
            d="M175.063 115.887C173.862 117.014 172.325 117.703 172.414 116.86C172.502 115.577 175.248 113.417 176.123 113.675C176.714 113.951 175.858 115.156 175.098 115.887"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2392"
            d="M398.849 309.855C398.796 312.083 396.526 313.572 394.875 311.825C394.533 311.466 394.29 311.028 394.17 310.552C394.051 310.075 394.058 309.577 394.192 309.105C394.326 308.632 394.581 308.201 394.934 307.852C395.287 307.502 395.726 307.247 396.208 307.109C396.571 307.109 396.93 307.18 397.262 307.32C397.595 307.46 397.895 307.665 398.144 307.922C398.392 308.18 398.583 308.484 398.706 308.816C398.829 309.149 398.88 309.502 398.858 309.855"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2393"
            d="M198.162 94.881C196.935 95.8104 195.513 96.4214 195.645 95.7846C195.857 94.8552 198.789 92.7813 199.566 93.005C200.007 93.2288 198.992 94.2614 198.162 94.881Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2394"
            d="M426.868 168.587C427.566 169.886 427.468 171.246 426.347 170.97C424.581 170.549 422.815 167.287 423.98 166.607C424.025 166.58 424.073 166.557 424.122 166.539C425.093 166.349 426.338 167.606 426.868 168.587Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2395"
            d="M368.993 313.4C368.834 315.483 366.503 317.135 364.781 315.121C364.463 314.752 364.248 314.309 364.156 313.835C364.064 313.361 364.098 312.873 364.255 312.415C364.411 311.958 364.686 311.547 365.051 311.223C365.417 310.898 365.862 310.67 366.344 310.561H366.388C366.759 310.564 367.125 310.644 367.463 310.794C367.8 310.944 368.101 311.162 368.347 311.433C368.592 311.704 368.777 312.022 368.888 312.367C368.999 312.712 369.035 313.076 368.993 313.435"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2396"
            d="M418.277 101.215C419.08 101.783 420.157 102.721 420.043 103.031C419.477 103.349 415.389 100.561 415.574 99.9846C415.875 99.7952 417.199 100.492 418.224 101.241"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2397"
            d="M338.442 315.586C338.442 317.565 336.102 319.406 334.327 317.599C333.946 317.243 333.675 316.79 333.543 316.292C333.411 315.794 333.425 315.269 333.581 314.778C333.738 314.287 334.032 313.848 334.43 313.51C334.829 313.172 335.316 312.949 335.837 312.866C336.193 312.872 336.545 312.947 336.871 313.088C337.196 313.228 337.49 313.432 337.733 313.686C337.976 313.94 338.164 314.239 338.286 314.565C338.408 314.892 338.461 315.239 338.442 315.586Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2398"
            d="M148.607 103.529C147.397 104.347 146.09 104.923 146.126 104.467C146.205 103.607 149.967 101.025 150.541 101.464C150.779 101.774 149.561 102.858 148.598 103.529"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2399"
            d="M463.488 116.024C464.159 116.653 465.554 118.012 465.695 118.245C464.317 117.143 463.022 115.947 461.819 114.665C461.816 114.645 461.816 114.624 461.819 114.604C462.423 115.036 462.995 115.507 463.532 116.016"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2400"
            d="M141.253 135.491C140.131 136.635 138.603 137.358 138.559 136.532C138.427 135.241 141.208 132.573 142.25 132.969C142.86 133.322 142.056 134.69 141.253 135.491Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2401"
            d="M168.229 144.001C167.169 145.352 165.536 146.11 165.368 144.991C165.147 143.502 167.505 141.161 168.609 141.411C169.359 141.686 169.015 142.994 168.229 143.993"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2402"
            d="M201.27 150.232C200.864 150.998 199.866 152.056 198.93 152.005C197.597 151.669 198.277 149.07 199.972 148.029C200.251 147.825 200.596 147.724 200.944 147.745C201.942 147.986 201.739 149.371 201.279 150.232"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2403"
            d="M429.8 113.951C430.904 114.906 431.478 115.792 431.045 115.844C430.33 115.93 426.498 113.081 426.975 112.402C427.425 112.204 428.926 113.193 429.791 113.951"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2404"
            d="M258.208 155.291C257.944 156.789 256.345 158.123 255.4 156.763C254.456 155.403 255.515 152.977 256.937 152.934C257.82 152.994 258.42 154.052 258.208 155.291Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2405"
            d="M318.819 154.663C318.943 155.464 318.643 156.66 317.627 156.797C315.914 156.797 314.819 154.009 316.02 152.77C316.11 152.669 316.22 152.587 316.342 152.528C316.465 152.469 316.599 152.434 316.735 152.426C317.945 152.426 318.713 153.768 318.837 154.663"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2406"
            d="M423.132 118.52C424.192 119.501 424.73 120.56 424.165 120.551C423.035 120.551 419.856 117.625 420.298 117.023L420.351 116.98C420.774 116.765 422.117 117.617 423.132 118.52Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2407"
            d="M338.909 153.27C339.218 154.732 338.265 155.851 336.914 154.991C335.148 153.863 335.104 151.23 336.419 150.98H336.516C337.647 150.98 338.662 152.151 338.909 153.295"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2408"
            d="M358.441 150.808C358.732 151.6 358.724 152.796 357.779 153.011C356.013 153.106 354.3 150.232 355.359 149.018C355.492 148.861 355.682 148.76 355.889 148.734C357.028 148.665 358.105 149.896 358.45 150.808"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2409"
            d="M401.075 344.491C401.058 345.149 400.828 345.785 400.419 346.309C400.01 346.832 399.443 347.217 398.797 347.409C396.722 347.546 395.37 344.887 397.075 342.839C397.365 342.427 397.793 342.125 398.285 341.983C398.777 341.842 399.304 341.87 399.778 342.063C400.251 342.256 400.642 342.602 400.885 343.042C401.127 343.483 401.207 343.992 401.11 344.483"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2410"
            d="M370.627 348.287C370.397 350.524 367.916 351.936 366.335 350.128C366.018 349.734 365.809 349.267 365.729 348.772C365.648 348.277 365.699 347.771 365.876 347.3C366.053 346.829 366.35 346.411 366.739 346.083C367.129 345.756 367.597 345.531 368.101 345.43C368.47 345.426 368.836 345.5 369.173 345.647C369.51 345.795 369.81 346.012 370.052 346.284C370.294 346.556 370.472 346.876 370.575 347.222C370.677 347.568 370.701 347.931 370.644 348.287"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2411"
            d="M339.493 350.584C339.387 352.417 337.011 354.525 335.175 352.451C334.831 352.068 334.6 351.602 334.505 351.102C334.411 350.601 334.456 350.085 334.637 349.608C334.817 349.131 335.127 348.71 335.532 348.389C335.938 348.069 336.424 347.861 336.941 347.787C337.301 347.796 337.656 347.877 337.983 348.025C338.311 348.173 338.603 348.384 338.844 348.646C339.085 348.908 339.268 349.215 339.382 349.548C339.497 349.881 339.54 350.234 339.51 350.584"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2412"
            d="M307.959 352.159C307.668 354.173 305.107 355.661 303.491 353.553C303.186 353.159 302.993 352.695 302.932 352.205C302.87 351.716 302.942 351.22 303.14 350.766C303.337 350.312 303.654 349.917 304.059 349.621C304.463 349.325 304.94 349.137 305.442 349.078C305.82 349.106 306.188 349.209 306.522 349.382C306.857 349.556 307.151 349.794 307.386 350.084C307.621 350.374 307.791 350.708 307.887 351.065C307.983 351.422 308.001 351.794 307.941 352.159"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2413"
            d="M377.268 149.027C377.895 150.361 377.489 151.609 376.2 151.084C374.266 150.318 373.295 147.469 374.434 147.005H374.593C375.564 146.91 376.765 147.994 377.242 149.07"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2414"
            d="M414.505 122.961C415.521 124.011 415.918 125.069 415.274 125.035C413.905 124.949 410.938 121.928 411.68 121.369C412.307 121.197 413.711 122.229 414.47 122.986"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2415"
            d="M276.434 352.357C276.222 354.371 273.732 355.903 272.019 353.82C270.465 351.901 271.887 349.302 273.9 349.319C274.271 349.355 274.631 349.462 274.959 349.634C275.286 349.807 275.576 350.042 275.809 350.324C276.043 350.607 276.216 350.933 276.319 351.282C276.422 351.631 276.452 351.997 276.408 352.357"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2416"
            d="M393.718 145.559C394.478 146.876 394.239 147.951 393.242 147.702C391.475 147.246 389.709 144.26 390.813 143.64L390.963 143.58C391.917 143.451 393.197 144.664 393.718 145.559Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2417"
            d="M408.254 141.669C409.084 142.891 409.057 143.924 408.192 143.803C406.603 143.571 404.333 140.361 405.331 139.785L405.419 139.742C406.302 139.578 407.644 140.774 408.245 141.669"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2418"
            d="M403.979 127.126C404.862 128.184 405.136 129.277 404.447 129.243C403.008 129.174 400.279 126.084 401.127 125.482H401.206C401.824 125.293 403.158 126.213 403.979 127.16"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2419"
            d="M105.488 333.804C105.303 335.396 103.881 336.747 102.627 335.293C101.011 333.408 101.832 330.336 103.51 330.37C103.6 330.375 103.689 330.389 103.775 330.413C105.179 330.87 105.638 332.703 105.541 333.804"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2420"
            d="M97.6834 332.013C97.4008 333.949 95.9173 334.595 94.9107 333.209C93.3124 331.11 94.6634 328.227 96.1292 328.425H96.2175C97.5244 328.881 97.86 330.791 97.6834 331.996"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2421"
            d="M420.687 137.229C421.57 138.339 421.835 139.492 421.146 139.466C419.707 139.423 417.005 136.188 417.853 135.534L417.941 135.491C418.55 135.275 419.875 136.231 420.687 137.212"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2422"
            d="M431.159 132.642C432.183 133.803 432.483 134.827 431.768 134.75C430.408 134.612 427.671 131.626 428.289 130.946L428.351 130.903C428.933 130.722 430.293 131.669 431.159 132.624"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2423"
            d="M391.626 131.007C392.465 132.186 392.447 133.167 391.626 133.03C390.019 132.771 387.776 129.751 388.765 129.183H388.853C389.639 129.062 390.937 130.043 391.652 131.05"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2424"
            d="M439.283 127.547C439.972 128.236 440.82 129.354 440.537 129.716C439.513 130.146 436.378 126.764 436.599 126.102C436.61 126.067 436.632 126.038 436.661 126.015C437.173 125.783 438.533 126.79 439.31 127.547"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2425"
            d="M174.507 427.68C174.622 429.143 173.103 430.4 171.407 429.057C169.421 427.482 169.818 425.116 171.602 424.935C171.728 424.927 171.855 424.927 171.981 424.935C172.645 425.073 173.246 425.412 173.7 425.904C174.154 426.395 174.436 427.014 174.507 427.672"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2426"
            d="M528.028 380.127C527.737 381.167 527.319 382.17 526.783 383.113C526.518 382.847 527.905 378.811 528.39 378.105L528.461 378.036H528.505C528.428 378.74 528.268 379.433 528.028 380.101"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2427"
            d="M530.888 321.747C530.888 322.754 530.588 324.415 530.102 324.949C529.475 325.345 529.087 323.047 529.59 320.801C529.668 320.325 529.855 319.872 530.137 319.476C530.711 319.097 530.932 320.603 530.897 321.747"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2428"
            d="M215.215 111.558C214.332 112.754 212.786 113.589 212.654 112.711C212.495 111.593 214.42 109.441 215.55 109.519C216.124 109.631 215.965 110.56 215.215 111.558Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2429"
            d="M228.371 113.504C228 114.123 227.108 115.087 226.331 115.079C225.254 114.812 226.19 112.497 227.727 111.636C227.924 111.505 228.159 111.438 228.398 111.447C229.148 111.619 228.83 112.772 228.398 113.504"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2430"
            d="M386.178 364.052C385.948 366.238 383.449 367.761 381.93 366.057C380.412 364.353 381.798 361.531 383.696 361.307C384.055 361.292 384.412 361.356 384.741 361.494C385.07 361.632 385.363 361.841 385.598 362.105C385.834 362.369 386.005 362.681 386.099 363.018C386.193 363.355 386.208 363.708 386.142 364.052"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2431"
            d="M355.263 367.133C354.972 369.344 352.429 370.713 350.848 368.906C350.531 368.508 350.325 368.037 350.25 367.539C350.175 367.04 350.235 366.531 350.423 366.062C350.611 365.593 350.92 365.179 351.321 364.861C351.723 364.544 352.202 364.333 352.711 364.25C353.08 364.254 353.442 364.336 353.775 364.489C354.108 364.642 354.403 364.863 354.641 365.138C354.878 365.412 355.052 365.733 355.15 366.079C355.248 366.425 355.269 366.787 355.21 367.141"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2432"
            d="M410.302 425.942C409.737 427.93 407.167 429.298 406.178 427.663C405.295 426.217 406.902 423.963 408.563 423.696C410.028 423.55 410.646 424.737 410.329 425.933"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2433"
            d="M323.828 368.862C323.776 369.517 323.502 370.137 323.05 370.624C322.598 371.112 321.992 371.439 321.329 371.555C320.785 371.514 320.266 371.319 319.835 370.994C319.405 370.669 319.081 370.228 318.904 369.725C318.728 369.223 318.706 368.681 318.842 368.167C318.978 367.652 319.265 367.188 319.668 366.831C320.096 366.379 320.682 366.1 321.311 366.048C321.67 366.061 322.023 366.144 322.347 366.294C322.672 366.444 322.962 366.656 323.2 366.919C323.438 367.181 323.619 367.488 323.731 367.82C323.844 368.153 323.885 368.504 323.854 368.853"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2434"
            d="M142.86 359.018C142.745 360.49 141.093 362.142 139.601 360.507C137.764 358.527 138.639 355.593 140.484 355.653H140.687C142.082 355.911 142.93 357.624 142.824 358.992"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2435"
            d="M396.951 134.363C397.445 135.069 398.01 136.721 397.18 136.609C395.626 136.402 393.251 133.296 394.116 132.599C394.16 132.57 394.208 132.547 394.257 132.53C395.14 132.384 396.474 133.675 396.906 134.363"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2436"
            d="M117.11 354.104C116.978 355.773 115.468 357.047 114.108 355.446C112.448 353.502 113.225 350.584 114.885 350.636C114.973 350.63 115.062 350.63 115.15 350.636C116.642 351.075 117.207 352.951 117.11 354.078"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2437"
            d="M429.764 88.78C430.591 89.2984 431.389 89.8585 432.157 90.4581C430.688 89.6356 429.275 88.7215 427.927 87.7216C427.908 87.7085 427.893 87.6906 427.883 87.6699C428.533 88.0012 429.161 88.3719 429.764 88.78Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2438"
            d="M434.311 93.8137C435.135 94.3187 435.896 94.9134 436.581 95.5865C436.192 95.6811 432.501 93.1081 432.254 92.7122C432.448 92.6003 434.311 93.8395 434.329 93.8481"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2439"
            d="M436.475 98.8744C437.302 99.3663 438.032 99.9988 438.63 100.742C438.339 100.983 434.489 98.3409 434.215 97.7299V97.6869C434.444 97.5922 435.672 98.3409 436.431 98.8744"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2440"
            d="M112.8 108.642C112.235 109.072 111.113 109.864 110.893 109.777C111.21 108.917 114.981 106.284 115.078 106.533C114.432 107.346 113.668 108.062 112.809 108.659"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2441"
            d="M242.244 115.027C241.953 115.655 241.202 116.636 240.407 116.636C239.259 116.412 239.869 114.054 241.29 113.151C241.475 113.014 241.703 112.944 241.935 112.953C242.73 113.099 242.588 114.278 242.244 115.027Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2442"
            d="M352.269 431.639C351.801 433.601 349.072 434.763 347.854 433.024C346.776 431.518 348.277 429.479 350.114 429.229C350.435 429.194 350.761 429.234 351.062 429.348C351.364 429.461 351.633 429.644 351.846 429.881C352.06 430.118 352.211 430.401 352.288 430.707C352.365 431.013 352.364 431.333 352.286 431.639"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2443"
            d="M113.338 114.218C112.314 115.018 111.263 115.621 111.325 115.233C111.466 114.442 115.201 111.49 115.493 111.938C115.493 112.333 114.221 113.53 113.338 114.209"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2444"
            d="M116.563 119.631C115.494 120.491 114.258 121.206 114.258 120.749C114.697 119.856 115.359 119.084 116.183 118.503C116.739 118.039 118.073 117.118 118.39 117.359C118.532 117.789 117.348 118.977 116.563 119.631Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2445"
            d="M256.673 116.076C256.479 116.704 255.861 117.711 255.048 117.737C253.856 117.53 254.104 115.155 255.419 114.226C255.596 114.085 255.818 114.008 256.046 114.011C256.885 114.131 256.929 115.31 256.682 116.076"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2446"
            d="M122.116 125.207C120.889 126.257 119.776 126.721 119.785 126.188C119.785 125.112 123.035 122.333 123.821 122.746C124.121 123.141 123.026 124.467 122.116 125.215"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2447"
            d="M130.496 130.568C129.234 131.738 127.98 132.211 127.953 131.523C127.953 130.335 130.867 127.702 131.856 128.081C132.333 128.468 131.37 129.802 130.496 130.611"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2448"
            d="M436.58 104.063C437.207 104.528 438.346 105.492 438.346 105.844C438.072 106.103 436.094 104.709 436.077 104.691C434.929 103.831 433.949 102.97 433.975 102.721C434.134 102.506 435.459 103.263 436.545 104.072"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2449"
            d="M434.24 109.054C435.415 109.984 436.113 110.844 435.777 110.93C435.079 111.111 431.274 108.151 431.547 107.626C431.821 107.419 433.19 108.211 434.24 109.054Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2450"
            d="M260.937 334.63C260.672 336.799 258.129 338.003 256.601 336.084C255.073 334.165 256.513 331.506 258.482 331.549C258.851 331.594 259.207 331.709 259.53 331.888C259.854 332.067 260.137 332.307 260.365 332.593C260.593 332.879 260.761 333.207 260.859 333.556C260.957 333.906 260.984 334.271 260.937 334.63Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2451"
            d="M216.027 138.081C215.329 139.492 213.678 140.465 213.227 139.415C212.662 138.089 214.269 135.714 215.682 135.775C216.442 135.895 216.565 136.928 216.027 138.081Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2452"
            d="M99.9089 122.263C98.8493 123.201 97.9574 123.752 98.0016 123.451C98.0899 122.737 101.613 119.561 101.922 119.914C101.86 120.404 100.439 121.747 99.9001 122.229"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2453"
            d="M74.5389 307.083C74.2033 308.658 72.9936 309.57 72.1194 308.348C70.7242 306.412 71.722 303.185 73.2055 303.409H73.3645C74.5212 303.959 74.7861 305.87 74.5389 307.04"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2454"
            d="M163.592 440.366C163.633 440.59 163.62 440.82 163.554 441.039C163.488 441.258 163.37 441.458 163.211 441.625C163.052 441.792 162.855 441.921 162.636 442.001C162.417 442.081 162.182 442.11 161.95 442.087C159.769 441.631 158.506 438.705 160.104 437.784C160.391 437.627 160.725 437.573 161.049 437.629C161.7 437.788 162.288 438.128 162.743 438.608C163.198 439.088 163.499 439.687 163.61 440.331"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2455"
            d="M339.394 385.367C339.058 387.57 336.233 388.81 334.82 386.796C334.571 386.394 334.428 385.938 334.406 385.468C334.383 384.999 334.481 384.532 334.69 384.109C334.9 383.686 335.215 383.32 335.606 383.046C335.997 382.771 336.452 382.596 336.93 382.536C337.294 382.534 337.653 382.609 337.983 382.756C338.314 382.904 338.607 383.12 338.842 383.39C339.077 383.66 339.248 383.977 339.343 384.319C339.439 384.661 339.456 385.019 339.394 385.367Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2456"
            d="M101.912 128.218C100.941 129.079 99.8458 129.793 99.8281 129.397C99.8281 128.537 103.263 125.344 103.731 125.766C103.846 126.179 102.698 127.487 101.894 128.192"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2457"
            d="M106.68 134.079C105.479 135.241 104.278 135.8 104.34 135.215C104.446 134.045 107.51 131.197 108.19 131.635C108.499 132.126 107.201 133.563 106.68 134.079Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2458"
            d="M114.108 140.034C112.881 141.247 111.68 141.755 111.68 141.015C111.68 139.905 114.47 136.953 115.441 137.392C115.874 137.805 114.92 139.216 114.099 140.034"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2459"
            d="M370.556 382.76C370.327 384.808 367.713 386.607 366.141 384.584C364.817 382.863 366.265 380.35 368.155 380.152C368.497 380.135 368.839 380.192 369.157 380.319C369.474 380.446 369.759 380.64 369.99 380.886C370.221 381.133 370.394 381.427 370.495 381.746C370.596 382.066 370.623 382.403 370.574 382.734"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2460"
            d="M335.916 447.025C335.457 448.953 332.525 449.96 331.43 448.101C330.547 446.621 332.022 444.908 333.752 444.71C334.063 444.676 334.378 444.715 334.671 444.822C334.963 444.93 335.225 445.105 335.435 445.331C335.645 445.557 335.796 445.829 335.877 446.124C335.957 446.418 335.965 446.727 335.898 447.025"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2461"
            d="M365.294 444.754C364.667 446.742 361.762 447.766 360.826 445.856C360.19 444.531 361.533 442.723 363.352 442.414C363.656 442.368 363.966 442.401 364.253 442.508C364.54 442.615 364.794 442.794 364.988 443.026C365.183 443.258 365.311 443.536 365.362 443.831C365.413 444.127 365.383 444.43 365.277 444.711"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2462"
            d="M124.448 145.644C123.194 146.995 121.852 147.477 121.799 146.574C121.799 145.171 124.36 142.598 125.437 142.951C125.984 143.312 125.349 144.672 124.413 145.644"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2463"
            d="M396.422 97.6346C397.658 98.4952 398.382 99.3557 397.887 99.416C396.898 99.4676 393.296 96.929 393.596 96.3954C394.073 96.2492 395.512 97.0495 396.386 97.6691"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2464"
            d="M158.143 125.62C156.854 126.859 155.494 127.341 155.494 126.567C155.494 125.336 158.143 122.944 159.238 123.202C159.839 123.52 159 124.811 158.17 125.62"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2465"
            d="M204.758 165.541C203.963 167.21 202.109 168.036 201.632 166.53C201.155 165.024 202.674 162.753 204.149 162.83C205.262 163.071 205.217 164.551 204.758 165.541Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2466"
            d="M310.811 169.482C310.908 170.962 309.707 172.416 308.294 171.28C306.652 169.955 307.173 167.244 308.674 167.115H308.727C309.989 167.115 310.749 168.509 310.811 169.482Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2467"
            d="M332.56 168.269C332.772 169.835 331.598 171.023 330.229 170.05C328.516 168.837 328.631 166.212 330.035 165.894C330.094 165.89 330.153 165.89 330.211 165.894C331.501 165.894 332.41 167.314 332.543 168.295"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2468"
            d="M540.01 326.773C539.931 327.811 539.751 328.84 539.471 329.845C539.456 329.872 539.435 329.895 539.409 329.914C539.092 329.57 539.551 325.344 539.913 324.484L539.966 324.406C540.204 324.518 540.045 326.394 539.966 326.773"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2469"
            d="M353.885 166.005C354.335 167.477 353.452 168.871 352.057 168.174C350.053 167.158 349.77 164.275 351.227 163.871H351.368C352.357 163.768 353.488 164.731 353.885 166.022"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2470"
            d="M132.235 111.386C130.999 112.324 129.78 112.858 129.771 112.462C130.228 111.574 130.924 110.824 131.785 110.293C132.394 109.837 133.727 109.037 134.072 109.243C134.284 109.682 132.818 110.964 132.235 111.386Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2471"
            d="M519.471 399.385C519.089 400.379 518.601 401.331 518.014 402.225C517.943 402.285 517.934 402.294 517.89 402.285C517.634 401.872 519.277 398.103 519.868 397.484C519.93 397.423 520.036 397.32 520.036 397.535C519.934 398.17 519.747 398.789 519.48 399.377"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2472"
            d="M524.814 391.658C524.408 392.665 523.86 393.998 523.631 394.472V394.515C523.631 394.368 525.326 390.126 525.565 389.653C525.562 389.627 525.562 389.601 525.565 389.575C525.565 389.747 525.141 390.797 524.805 391.641"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2473"
            d="M374.91 164.362C375.193 165.119 375.263 166.419 374.23 166.668C372.261 166.789 370.698 163.674 371.714 162.512C371.855 162.353 372.048 162.247 372.261 162.211C373.48 162.108 374.566 163.433 374.91 164.354"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2474"
            d="M393.585 160.85C393.956 161.599 394.212 162.907 393.303 163.226C391.413 163.441 389.612 160.188 390.512 159.086C390.605 158.96 390.744 158.874 390.901 158.845C392.005 158.708 393.162 159.964 393.603 160.85"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2475"
            d="M127.988 106.472C126.964 107.186 125.896 107.72 125.887 107.402C125.887 106.687 129.869 103.805 130.258 104.252C130.328 104.588 129.136 105.655 127.98 106.455"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2476"
            d="M445.411 122.453C446.567 123.538 447.177 124.544 446.647 124.527C445.596 124.527 442.338 121.438 442.779 120.904C443.132 120.689 444.422 121.524 445.428 122.453"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2477"
            d="M377.294 134.277C378.08 135.508 377.894 136.626 376.949 136.42C375.307 136.067 373.497 133.236 374.468 132.547C374.524 132.508 374.587 132.479 374.654 132.461C375.369 132.324 376.57 133.15 377.303 134.277"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2478"
            d="M148.016 424.015C148.069 425.641 146.497 426.373 144.96 424.875C143.15 423.154 143.75 421.02 145.155 420.934C145.269 420.918 145.385 420.918 145.499 420.934C146.185 421.132 146.792 421.529 147.242 422.072C147.691 422.614 147.961 423.277 148.016 423.972"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2479"
            d="M449.024 117.032C449.765 117.66 450.79 118.753 450.71 119.063C450.242 119.476 446.472 116.343 446.587 115.62C446.816 115.371 448.15 116.274 449.015 117.032"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2480"
            d="M389.63 101.387C390.646 102.247 391.122 102.987 390.69 103.056C389.701 103.211 386.124 100.475 386.619 99.9067C386.619 99.9067 386.619 99.9067 386.663 99.9067C387.175 99.769 388.641 100.595 389.63 101.421"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2481"
            d="M450.426 111.636C451.459 112.496 452.448 113.529 452.192 113.589C451.68 113.701 448.077 110.75 448.086 110.233C448.174 109.984 449.393 110.775 450.435 111.636"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2482"
            d="M343.254 138.657C343.528 139.406 343.519 140.559 342.627 140.748C340.976 140.748 339.386 138.063 340.384 136.902C340.513 136.752 340.697 136.656 340.896 136.635C341.982 136.592 342.954 137.805 343.263 138.649"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2483"
            d="M449.173 106.017C449.963 106.57 450.668 107.231 451.265 107.979C450.718 107.979 447.336 105.191 447.15 104.761V104.752C447.891 105.085 448.576 105.526 449.181 106.06"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2484"
            d="M123.264 419.204C123.405 420.762 122.204 421.39 120.933 420.426C119.166 419.075 118.787 416.424 120.341 416.123C120.505 416.097 120.672 416.097 120.835 416.123C121.481 416.38 122.045 416.798 122.47 417.336C122.895 417.875 123.166 418.513 123.255 419.187"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2485"
            d="M445.579 100.56C446.354 101.122 447.088 101.734 447.778 102.393C446.336 101.478 444.974 100.447 443.707 99.3125C444.367 99.7055 444.999 100.143 445.597 100.621"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2486"
            d="M325.053 140.585C325.318 141.97 324.444 143.063 323.217 142.237C321.61 141.144 321.53 138.674 322.766 138.373H322.89C324.02 138.373 324.859 139.672 325.036 140.602"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2487"
            d="M105.445 368.595C105.339 370.316 103.961 371.177 102.69 369.765C101.003 367.838 101.63 364.929 103.255 365.093H103.432C104.845 365.574 105.507 367.416 105.436 368.535"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2488"
            d="M441.667 96.878C441.27 96.5595 439.442 95.1569 437.703 93.9177L437.623 93.8574L438.1 94.2189L441.632 96.878"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2489"
            d="M476.458 318.322C476.461 319.304 476.152 320.263 475.575 321.067C475.319 321.343 475.054 321.635 474.18 320.801C473.797 320.492 473.495 320.1 473.297 319.656C473.099 319.212 473.01 318.729 473.038 318.246C473.067 317.763 473.211 317.293 473.46 316.874C473.709 316.456 474.055 316.1 474.471 315.835C475.61 315.611 476.387 317.16 476.423 318.322"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2490"
            d="M130.091 373.836C130.091 374.696 129.482 376.013 128.14 375.85C125.976 375.221 125.181 371.702 126.974 370.686C127.27 370.518 127.619 370.463 127.954 370.531C129.508 370.936 130.162 372.734 130.118 373.827"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2491"
            d="M360.013 101.533C360.578 102.153 361.267 103.349 360.728 103.358C359.563 103.358 356.737 100.613 357.329 100.036C357.356 100.011 357.389 99.9929 357.426 99.9844C358.106 99.8898 359.492 100.957 360.013 101.533Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2492"
            d="M245.361 339.965C245.301 340.554 245.029 341.104 244.592 341.515C244.156 341.927 243.583 342.173 242.977 342.211C241.096 341.919 239.224 338.864 241.714 337.134C242.084 336.858 242.538 336.713 243.003 336.721C243.758 336.85 244.431 337.261 244.878 337.868C245.326 338.474 245.512 339.227 245.396 339.965"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2493"
            d="M233.114 140.034C232.814 140.628 231.727 141.755 230.995 141.755C229.802 141.488 230.023 138.933 231.683 137.883C231.922 137.701 232.22 137.61 232.522 137.625C233.405 137.719 233.529 139.174 233.123 139.991"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2494"
            d="M517.82 295.01C517.97 296.645 517.414 298.727 516.566 298.047C515.436 297.187 515.339 293.435 516.425 292.738C517.211 292.394 517.723 293.891 517.82 295.01Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2495"
            d="M385.259 329.036C385.206 330.826 382.928 333.02 381.118 331.05C380.773 330.68 380.535 330.228 380.425 329.74C380.316 329.252 380.339 328.745 380.494 328.268C380.648 327.792 380.929 327.363 381.306 327.025C381.684 326.688 382.145 326.452 382.646 326.342C383.009 326.323 383.371 326.381 383.709 326.511C384.047 326.641 384.353 326.841 384.604 327.097C384.856 327.353 385.047 327.658 385.167 327.993C385.286 328.328 385.33 328.683 385.295 329.036"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2496"
            d="M354.628 332.005C354.531 334.044 352.235 335.843 350.442 334.001C348.65 332.16 349.992 329.423 352.014 329.251C352.377 329.25 352.737 329.322 353.07 329.463C353.404 329.603 353.704 329.809 353.953 330.067C354.201 330.325 354.392 330.63 354.515 330.964C354.637 331.297 354.687 331.651 354.663 332.005"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2497"
            d="M323.464 334.035C323.243 336.256 320.735 337.555 319.128 335.679C318.795 335.297 318.573 334.836 318.485 334.342C318.396 333.849 318.444 333.341 318.624 332.872C318.804 332.402 319.11 331.989 319.509 331.673C319.908 331.357 320.386 331.151 320.894 331.075C321.268 331.093 321.634 331.185 321.969 331.346C322.305 331.508 322.602 331.735 322.843 332.014C323.084 332.292 323.264 332.617 323.371 332.966C323.478 333.315 323.509 333.682 323.464 334.044"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2498"
            d="M138.488 436.174C138.594 436.906 138.294 437.999 137.049 437.835C134.974 437.259 133.667 434.281 135.133 433.472C135.403 433.331 135.717 433.291 136.016 433.36C136.659 433.567 137.233 433.937 137.679 434.434C138.125 434.93 138.426 435.534 138.55 436.183"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2499"
            d="M292.144 334.784C291.994 336.609 289.574 338.554 287.79 336.351C287.448 335.973 287.219 335.512 287.129 335.016C287.039 334.521 287.091 334.01 287.279 333.542C287.467 333.073 287.784 332.664 288.195 332.359C288.606 332.055 289.096 331.866 289.609 331.815C289.979 331.84 290.34 331.937 290.67 332.101C291 332.266 291.292 332.493 291.529 332.771C291.766 333.049 291.942 333.37 292.048 333.716C292.154 334.062 292.186 334.426 292.144 334.784Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2500"
            d="M156.704 378.285C156.714 378.561 156.666 378.835 156.564 379.092C156.462 379.349 156.308 379.584 156.111 379.782C155.914 379.979 155.678 380.136 155.418 380.243C155.157 380.349 154.877 380.404 154.594 380.402C152.289 379.869 151.45 376.384 153.358 375.299C153.676 375.114 154.051 375.044 154.417 375.101C155.109 375.307 155.71 375.732 156.126 376.309C156.542 376.886 156.749 377.581 156.713 378.285"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2501"
            d="M73.1869 232.973C72.6041 234.325 71.2266 235.478 70.7145 234.281C69.9021 232.406 71.8182 229.196 72.9838 229.678C73.8669 230.203 73.602 231.941 73.1604 232.956"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2502"
            d="M61.0187 206.391C60.3035 207.664 59.2527 208.473 59.0496 207.621C58.661 206.201 60.6567 202.604 61.5574 203.086C62.0342 203.585 61.6457 205.186 60.9746 206.365"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2503"
            d="M89.9131 239.789C89.3391 241.217 87.8027 242.422 87.1492 241.105C86.1779 239.152 88.0323 236.054 89.4186 236.527C90.3988 236.992 90.3016 238.713 89.8954 239.78"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2504"
            d="M109.181 245.967C108.457 247.766 106.717 248.549 106.055 246.957C105.295 245.089 106.814 242.344 108.448 242.654C109.507 243.058 109.666 244.693 109.154 245.976"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2505"
            d="M291.401 265.674C291.189 267.791 288.832 268.979 287.375 267.12C285.918 265.261 287.163 262.869 289.017 262.723C289.375 262.756 289.722 262.86 290.038 263.029C290.353 263.197 290.63 263.427 290.851 263.703C291.073 263.979 291.234 264.296 291.326 264.635C291.417 264.974 291.437 265.328 291.384 265.674"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2506"
            d="M78.07 176.926C77.1252 178.225 76.1097 178.819 75.9243 178.173C75.571 176.865 78.0965 173.363 78.9972 173.914C79.4475 174.404 78.7323 175.97 78.0523 176.917"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2507"
            d="M88.8077 183.655C87.9864 184.886 86.4941 185.919 86.2734 184.826C85.9378 183.105 88.4015 180.187 89.3463 180.678C90.0086 181.168 89.417 182.743 88.8077 183.655Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2508"
            d="M405.109 257.782C405.251 258.755 404.853 260.261 403.538 260.579C401.286 260.76 399.9 257.593 401.471 255.863C401.715 255.58 402.052 255.388 402.425 255.321C404.006 255.175 404.968 256.698 405.127 257.774"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2509"
            d="M102.415 190.152C101.479 191.581 100.005 192.364 99.6955 191.194C99.2363 189.473 101.585 186.624 102.698 187.054C103.457 187.467 103.157 189.016 102.415 190.152Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2510"
            d="M118.583 195.858C117.7 197.381 116.04 198.224 115.625 196.968C115.034 195.187 117.268 192.416 118.53 192.82C119.413 193.207 119.254 194.696 118.583 195.858Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2511"
            d="M512.132 241.114C512.636 242.732 512.459 244.556 511.629 244.109C510.384 243.386 509.501 239.806 510.375 239.04C511.082 238.713 511.832 240.133 512.141 241.114"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2512"
            d="M429.887 253.36C430.17 255.227 428.863 256.802 427.309 255.735C425.481 254.496 425.437 251.389 427.238 250.847C428.536 250.675 429.666 252.034 429.887 253.36Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2513"
            d="M136.987 200.806C136.166 202.527 134.338 203.49 133.782 202.071C133.084 200.255 135.168 197.587 136.572 197.914C137.579 198.258 137.534 199.635 136.987 200.806Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2514"
            d="M451.945 248.153C452.28 249.874 451.3 251.457 449.887 250.588C447.98 249.427 447.918 246.062 449.393 245.649C450.585 245.425 451.68 246.759 451.945 248.153Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2515"
            d="M500.16 248.575C500.601 250.339 500.16 252.017 499.047 251.269C497.661 250.339 497.052 246.966 498.164 246.243C498.941 245.959 499.851 247.241 500.177 248.575"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2516"
            d="M303.341 215.763C303.341 217.484 301.628 219.084 300.048 217.587C298.282 215.866 299.482 213.198 301.09 213.146C301.729 213.224 302.315 213.532 302.732 214.01C303.148 214.488 303.366 215.101 303.341 215.728"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2517"
            d="M471.135 242.086C471.373 242.947 471.409 244.513 470.472 244.943C468.83 245.296 467.241 241.923 468.274 240.262C468.326 240.161 468.398 240.071 468.486 239.997C468.574 239.923 468.676 239.867 468.786 239.832C469.898 239.556 470.852 241.019 471.144 242.086"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2518"
            d="M484.917 255.348C485.324 257.069 484.67 258.79 483.469 258.127C481.88 257.215 481.359 253.609 482.736 253.05C483.69 252.783 484.608 254.031 484.917 255.348Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2519"
            d="M329.232 214.609C329.373 216.261 327.819 217.939 326.203 216.666C324.437 215.254 324.79 212.363 326.794 212.113C327.445 212.164 328.053 212.446 328.503 212.907C328.952 213.367 329.212 213.973 329.232 214.609Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2520"
            d="M466.666 261.638C466.993 263.359 466.11 265.295 464.626 264.271C462.86 263.041 462.86 259.728 464.326 259.28C465.509 259.031 466.445 260.493 466.666 261.638Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2521"
            d="M354.822 212.569C355.122 214.351 353.7 215.857 352.067 214.798C350.045 213.499 350.301 210.581 352.067 210.186H352.243C352.897 210.223 353.515 210.486 353.988 210.928C354.46 211.369 354.756 211.96 354.822 212.595"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2522"
            d="M277.071 248.893C276.815 250.898 274.678 252.077 273.256 250.399C271.834 248.721 273.009 245.95 274.854 245.941C275.199 245.993 275.53 246.11 275.828 246.286C276.126 246.463 276.386 246.695 276.591 246.969C276.797 247.243 276.946 247.554 277.028 247.884C277.11 248.215 277.125 248.557 277.071 248.893Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2523"
            d="M514.721 413.688C514.968 413.215 516.072 411.055 516.813 409.558L514.721 413.688Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2524"
            d="M153.403 187.923C152.564 189.644 150.656 190.591 150.171 189.171C149.588 187.45 151.389 184.937 153.005 185.144C154.038 185.505 153.888 186.977 153.394 187.915"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2525"
            d="M314.616 199.661C314.616 201.511 312.939 202.75 311.49 201.33C309.786 199.67 310.846 197.114 312.373 197.028C313.901 196.942 314.643 198.568 314.652 199.609"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2526"
            d="M339.148 198.009C339.413 199.731 338.018 201.185 336.499 200.135C334.565 198.836 334.883 196.013 336.543 195.66H336.702C338.15 195.66 339.016 197.037 339.175 198.035"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2527"
            d="M363.289 195.78C363.598 197.372 362.406 198.981 360.64 197.759C358.715 196.4 358.944 193.68 360.56 193.37H360.64C362.106 193.302 363.086 194.773 363.289 195.789"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2528"
            d="M386.848 193.733C387.289 195.247 386.451 196.899 384.782 195.987C382.671 194.843 382.459 191.9 384.031 191.478H384.119C385.488 191.357 386.557 192.76 386.848 193.75"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2529"
            d="M105.004 226.734C104.306 228.395 102.655 229.376 102.028 228.103C101.145 226.244 103.123 223.215 104.527 223.636C105.507 224.041 105.507 225.538 105.004 226.734Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2530"
            d="M407.935 189.834C408.447 191.211 407.935 192.889 406.46 192.227C404.297 191.28 403.67 188.148 405.127 187.666H405.206C406.399 187.511 407.564 188.836 407.935 189.852"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2531"
            d="M426.736 185.368C427.363 186.745 426.965 188.259 425.782 187.838C423.716 187.115 422.656 183.879 423.883 183.285L424.016 183.234C424.952 183.01 426.197 184.18 426.736 185.368Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2532"
            d="M136.704 482.532C137.163 483.462 136.792 484.004 135.935 483.677C134.249 483.031 132.518 480.587 133.463 480.183C133.641 480.126 133.833 480.126 134.01 480.183C135.163 480.634 136.116 481.466 136.704 482.532Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2533"
            d="M443.107 180.316C443.778 181.598 443.787 183.268 442.507 182.82C440.74 182.209 439.204 178.931 440.387 178.328C440.387 178.328 440.431 178.328 440.449 178.328C441.412 178.105 442.604 179.395 443.098 180.342"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2534"
            d="M457.084 174.99C457.905 176.444 457.782 177.658 456.846 177.391C455.159 176.909 453.314 173.561 454.426 172.907C455.256 172.675 456.501 173.966 457.075 174.99"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2535"
            d="M468.29 168.94C468.829 169.749 469.394 171.074 468.935 171.522C467.734 171.978 465.341 168.389 465.703 167.331C465.732 167.254 465.785 167.188 465.853 167.142C466.515 166.866 467.69 168.002 468.299 168.957"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2536"
            d="M476.997 162.839C477.518 163.57 478.207 164.887 477.88 165.3C477.385 165.532 476.317 164.44 475.752 163.76C474.939 162.718 474.418 161.505 474.604 161.109C474.619 161.075 474.644 161.045 474.675 161.023C475.187 160.722 476.379 161.884 477.023 162.83"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2537"
            d="M482.949 156.496C483.894 157.761 484.344 158.94 483.832 158.923C483.037 158.923 480.3 155.533 480.662 154.698C481.015 154.397 482.207 155.558 482.905 156.505"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2538"
            d="M486.03 149.948C487.019 151.204 487.611 152.366 487.214 152.288C486.454 152.134 483.832 148.846 483.955 148.166C484.123 147.865 485.165 148.846 486.03 149.948Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2539"
            d="M87.4305 220.685C86.6181 222.251 85.17 223.042 84.7814 221.743C84.1898 219.97 85.9559 217.001 87.3687 217.397C88.2517 217.888 88.0045 219.652 87.4747 220.685"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2540"
            d="M59.4477 225.572C58.8207 226.949 57.6816 227.87 57.3461 226.949C56.728 225.357 58.4322 221.786 59.5625 222.268H59.6066C60.2424 222.793 59.9245 224.505 59.4477 225.538"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2541"
            d="M72.6494 213.688C71.9871 214.945 70.5831 216.021 70.2476 214.936C69.7707 213.396 71.4838 209.928 72.826 210.479C73.4971 210.874 73.2763 212.492 72.6494 213.688Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2542"
            d="M269.14 205.229C268.866 206.95 266.88 208.439 265.705 206.727C264.778 205.384 265.334 202.656 267.339 202.57C268.584 202.57 269.326 204.025 269.105 205.229"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2543"
            d="M214.746 82.661C214.118 83.113 213.417 83.4592 212.671 83.6851C212.663 83.6676 212.658 83.6485 212.658 83.6291C212.658 83.6098 212.663 83.5907 212.671 83.5732C212.768 83.3925 212.989 83.2978 213.051 83.0913C213.113 82.8848 213.007 82.8848 213.051 82.7815C213.147 82.6548 213.263 82.5443 213.395 82.4545C213.908 82.0845 213.908 82.0845 216.76 80.7334C216.151 81.4496 215.461 82.0963 214.702 82.661"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2544"
            d="M306.581 282.291C306.581 284.469 304.223 285.889 302.563 284.124C300.903 282.36 302.298 279.581 304.082 279.521C304.434 279.539 304.779 279.626 305.096 279.776C305.413 279.926 305.696 280.136 305.929 280.394C306.162 280.652 306.339 280.953 306.451 281.279C306.563 281.605 306.607 281.949 306.581 282.291Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2545"
            d="M458.868 192.261C459.257 193.07 459.601 194.49 458.868 194.886C457.296 195.256 455.336 191.702 456.034 190.471C456.102 190.348 456.211 190.251 456.343 190.196C457.226 189.946 458.418 191.28 458.895 192.252"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2546"
            d="M276.753 282.799C276.461 284.976 274.051 286.104 272.594 284.168C272.285 283.789 272.085 283.337 272.013 282.858C271.942 282.379 272.003 281.89 272.188 281.442C272.374 280.993 272.679 280.6 273.071 280.303C273.463 280.007 273.929 279.817 274.421 279.753C274.783 279.797 275.132 279.911 275.446 280.09C275.761 280.269 276.036 280.507 276.253 280.792C276.471 281.076 276.628 281.401 276.713 281.746C276.799 282.091 276.813 282.449 276.753 282.799Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2547"
            d="M94.3536 259.34C94.0445 260.269 93.1527 261.491 92.1284 261.285C90.4859 260.579 90.9893 256.853 92.8348 256.121C93.0644 256.017 93.3252 255.999 93.5677 256.07C94.6892 256.543 94.7157 258.299 94.3713 259.348"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2548"
            d="M472.114 186.289C472.556 187.081 472.997 188.5 472.423 188.914C471.17 189.335 468.812 185.807 469.492 184.516C469.532 184.431 469.601 184.361 469.686 184.318C470.428 184.051 471.585 185.282 472.123 186.28"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2549"
            d="M75.7917 252.766C75.4739 253.626 74.635 254.84 73.7078 254.564C72.083 253.618 73.2839 249.685 74.8469 249.401C75.0039 249.371 75.1665 249.386 75.3149 249.444C76.3127 249.969 76.1979 251.759 75.8182 252.809"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2550"
            d="M482.684 180.075C483.567 181.529 483.628 182.794 482.887 182.657C481.695 182.373 479.505 178.93 480.317 178.121C480.926 177.829 482.083 179.111 482.684 180.118"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2551"
            d="M60.0842 245.434C59.5543 246.854 58.4152 247.818 57.9031 246.88C57.073 245.365 58.3976 241.776 59.6691 242.017C59.7265 242.024 59.7811 242.045 59.8281 242.078C60.6316 242.637 60.4374 244.41 60.0488 245.434"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2552"
            d="M420.13 220.925C420.554 222.647 419.539 224.17 417.967 223.223C416.033 222.07 415.653 218.92 417.393 218.499C418.735 218.335 419.857 219.833 420.121 220.934"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2553"
            d="M252.911 83.1177C252.169 84.1073 251.269 84.6151 251.145 84.1245C250.977 83.3759 252.558 81.4568 253.344 81.4482C253.785 81.5601 253.344 82.5067 252.894 83.1177"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2554"
            d="M490.34 173.457C491.223 174.895 491.514 176.168 490.87 175.979C489.837 175.678 487.603 172.339 488.141 171.521C488.547 171.237 489.607 172.296 490.34 173.457Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2555"
            d="M495.003 166.548C495.506 167.279 496.248 168.613 496.115 169.061C495.524 169.654 492.963 165.618 493.025 164.835C493.02 164.807 493.02 164.778 493.025 164.749C493.298 164.414 494.358 165.61 494.985 166.539"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2556"
            d="M166.233 212.397C165.695 213.696 163.823 215.332 162.975 213.387C161.96 211.072 164.238 209.144 165.359 209.342C166.481 209.54 166.728 211.192 166.242 212.397"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2557"
            d="M344.745 229.798C344.913 231.519 343.324 233.24 341.628 231.949C339.747 230.546 340.092 227.569 342.176 227.276C342.848 227.312 343.484 227.589 343.958 228.055C344.433 228.521 344.714 229.142 344.745 229.798Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2558"
            d="M290.748 232.328C290.58 234.273 288.584 235.486 287.154 233.92C285.723 232.354 286.536 229.617 288.514 229.479C289.189 229.584 289.796 229.939 290.209 230.469C290.623 231 290.809 231.666 290.73 232.328"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2559"
            d="M120.685 213.938C119.802 215.582 118.124 216.408 117.603 214.971C116.967 213.198 118.707 210.479 120.305 210.763C121.374 211.176 121.188 212.906 120.667 213.938"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2560"
            d="M102.742 208.103C101.956 209.652 100.296 210.616 99.8457 209.42C99.2011 207.699 101.108 204.713 102.601 205.117C103.572 205.616 103.219 207.191 102.742 208.129"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2561"
            d="M87.0786 201.873C86.1955 203.37 84.7562 204.179 84.4736 202.957C84.0321 201.064 86.2397 198.233 87.2993 198.749C88.1029 199.24 87.6437 200.909 87.0786 201.873Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2562"
            d="M74.203 195.014C73.32 196.434 72.1632 196.993 71.9689 196.124C71.6157 194.532 73.735 191.314 74.8123 191.821C75.4039 192.355 74.8123 194.024 74.1942 195.014"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2563"
            d="M64.8252 187.751C63.9422 189.068 63.0591 189.688 62.909 189.034C62.6353 187.665 64.9576 184.06 65.7347 184.645C66.1144 185.161 65.4433 186.77 64.8075 187.734"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2564"
            d="M261.687 83.8227C261.431 84.3218 260.804 85.1135 260.327 85.1393C259.594 84.9844 260.327 82.9708 261.378 82.334C261.475 82.2569 261.598 82.2172 261.723 82.2221C262.182 82.3081 261.996 83.2117 261.723 83.8227"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2565"
            d="M445.455 267.317C445.764 269.039 444.572 271.061 442.983 269.899C441.217 268.6 441.217 265.502 442.983 264.942C444.042 264.701 445.208 265.803 445.464 267.309"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2566"
            d="M379.855 209.815C380.164 211.58 378.795 212.965 377.206 211.889C375.166 210.53 375.44 207.587 377.126 207.328C378.61 207.234 379.696 208.748 379.872 209.824"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2567"
            d="M421.42 272.36C421.552 273.333 421.172 274.873 419.883 275.243C417.711 275.475 416.351 272.283 417.888 270.501C418.125 270.204 418.463 270 418.841 269.925C420.378 269.753 421.287 271.241 421.437 272.36"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2568"
            d="M487.47 235.735C487.974 237.456 487.47 239.108 486.34 238.429C484.662 237.448 484.124 234.005 485.227 233.48C486.164 233.188 487.126 234.599 487.47 235.735Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2569"
            d="M146.559 463.204C146.877 464.521 145.826 465.045 144.528 464.219C142.709 463.066 142.056 460.777 143.46 460.579C143.615 460.553 143.773 460.553 143.928 460.579C144.549 460.773 145.113 461.108 145.574 461.557C146.034 462.006 146.378 462.556 146.577 463.161"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2570"
            d="M500.751 228.86C501.281 230.391 501.113 232.026 500.221 231.656C498.8 231.063 497.652 227.354 498.694 226.674C499.374 226.381 500.31 227.534 500.751 228.86Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2571"
            d="M229.581 79.1413C228.397 80.0018 227.285 80.4149 227.338 80.0534C227.435 79.4166 230.367 77.4718 231.029 77.5923C231.338 77.7386 230.393 78.5905 229.581 79.1413Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2572"
            d="M510.861 221.484C511.444 222.947 511.532 224.72 510.861 224.453C509.819 224.058 508.3 220.383 509.095 219.479C509.704 219.161 510.525 220.538 510.861 221.484Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2573"
            d="M394.469 275.381C394.535 275.982 394.384 276.587 394.04 277.09C393.696 277.593 393.182 277.963 392.588 278.135C390.301 278.238 389.003 275.166 390.601 273.436C390.902 273.093 391.318 272.864 391.775 272.791C393.435 272.67 394.424 274.219 394.504 275.372"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2574"
            d="M51.7656 227.035C51.174 228.292 50.3263 228.989 50.0879 228.395C49.4168 226.743 51.1564 223.128 52.0659 223.507L52.1189 223.55C52.6752 224.118 52.269 225.968 51.7656 227.035Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2575"
            d="M140.678 473.29C141.067 474.383 140.431 474.925 139.354 474.426C137.587 473.565 136.298 471.199 137.464 470.811C137.64 470.765 137.826 470.765 138.003 470.811C138.599 471.024 139.142 471.356 139.599 471.786C140.055 472.215 140.414 472.734 140.652 473.307"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2576"
            d="M403.502 207.156C404.076 208.955 403.016 210.323 401.586 209.652C399.396 208.611 399.087 205.478 400.65 204.945H400.791C402.045 204.799 403.122 205.986 403.502 207.182"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2577"
            d="M244.636 82.05C243.921 82.79 242.773 83.5387 242.711 83.0482C242.605 82.2909 244.733 80.3547 245.36 80.4666C245.625 80.5698 245.21 81.482 244.627 82.0844"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2578"
            d="M502.305 184.232C503.135 185.729 503.409 187.037 502.844 186.814C501.828 186.444 499.948 183.019 500.327 182.236C500.663 181.934 501.634 183.044 502.296 184.232"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2579"
            d="M495.744 191.082C496.221 191.942 496.715 193.362 496.362 193.827C495.382 194.386 493.298 190.582 493.571 189.421C493.586 189.336 493.626 189.258 493.686 189.197C494.225 188.879 495.24 190.135 495.753 191.082"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2580"
            d="M486.252 197.846C486.976 199.257 487.073 200.935 486.173 200.643C484.813 200.204 482.985 196.727 484.018 195.91C484.778 195.617 485.784 196.942 486.252 197.846Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2581"
            d="M365.736 278.901C365.736 281.095 363.44 282.421 361.78 280.622C360.12 278.823 361.259 276.276 363.043 276.087C363.409 276.099 363.769 276.182 364.101 276.332C364.434 276.482 364.733 276.695 364.979 276.958C365.226 277.222 365.416 277.532 365.539 277.868C365.661 278.205 365.713 278.562 365.692 278.918"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2582"
            d="M424.492 202.854C425.031 204.411 424.369 205.934 422.965 205.289C420.987 204.377 420.13 201.141 421.728 200.599H421.781C423.027 200.41 424.139 201.838 424.492 202.862"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2583"
            d="M473.925 204.438C474.579 205.995 474.34 207.51 473.184 207.019C471.417 206.159 470.42 202.777 471.417 202.295C472.239 202.037 473.387 203.242 473.899 204.481"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2584"
            d="M458.657 210.323C459.205 211.692 458.993 213.473 457.704 213C455.938 212.337 454.71 208.886 456.061 208.206H456.132C457.13 207.974 458.242 209.308 458.657 210.358"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2585"
            d="M442.929 197.691C443.671 199.334 443.176 200.745 441.993 200.341C440.015 199.661 438.991 196.288 440.227 195.66L440.333 195.617C441.331 195.41 442.426 196.529 442.982 197.691"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2586"
            d="M440.661 215.763C441.27 217.432 440.608 219.084 439.186 218.422C437.102 217.441 436.537 214.119 437.994 213.62H438.065C439.16 213.422 440.22 214.584 440.661 215.78"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2587"
            d="M411.097 291.8C411.177 293.926 409.331 295.509 407.565 294.021C405.799 292.532 406.594 289.477 408.448 289.184C410.082 289.03 411.009 290.604 411.097 291.766"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2588"
            d="M241.696 92.0928C240.901 93.1169 239.726 93.8139 239.656 93.0653C239.567 92.1273 241.228 90.2427 242.128 90.2599C242.667 90.3976 242.208 91.4388 241.696 92.0928Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2589"
            d="M352.605 297.127C352.666 299.33 350.379 300.905 348.631 299.219C346.883 297.532 347.986 294.666 349.947 294.494C350.654 294.508 351.328 294.791 351.823 295.283C352.319 295.776 352.596 296.438 352.596 297.127"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2590"
            d="M322.326 299.235C322.105 301.447 319.677 302.677 318.088 300.724C316.631 298.951 317.893 296.335 319.748 296.24C320.124 296.257 320.493 296.35 320.831 296.513C321.168 296.676 321.468 296.906 321.71 297.187C321.952 297.469 322.132 297.796 322.238 298.148C322.344 298.501 322.374 298.871 322.326 299.235Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2591"
            d="M499.276 209.67C499.629 210.53 500.017 211.985 499.523 212.449C498.975 212.673 498.154 211.701 497.624 210.728C497.095 209.756 496.794 208.405 497.068 207.828C497.1 207.753 497.152 207.688 497.218 207.639C497.872 207.303 498.843 208.663 499.293 209.687"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2592"
            d="M291.879 299.803C291.8 301.903 289.513 303.349 287.879 301.739C286.246 300.13 287.085 297.144 289.433 296.98C290.132 297.059 290.773 297.395 291.225 297.92C291.677 298.445 291.905 299.119 291.862 299.803"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2593"
            d="M487.929 216.623C488.539 218.172 488.344 219.764 487.311 219.325C485.775 218.662 484.547 215.065 485.66 214.454C486.41 214.179 487.426 215.358 487.929 216.623Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2594"
            d="M261.573 299.605C261.414 301.8 258.986 303.134 257.423 301.283C255.86 299.433 257.237 296.697 259.189 296.714C259.893 296.798 260.535 297.148 260.978 297.689C261.42 298.229 261.628 298.917 261.555 299.605"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2595"
            d="M231.869 90.6299C230.862 91.6712 229.749 92.1961 229.696 91.654C229.599 90.7073 231.913 88.7109 232.646 88.84C233.122 88.9862 232.504 89.9759 231.869 90.6299Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2596"
            d="M62.8834 265.674C62.6008 266.612 61.8325 267.799 61.0025 267.516C59.625 266.655 60.3049 262.619 61.8855 262.24C62.0306 262.187 62.1908 262.187 62.3359 262.24C63.2189 262.791 63.2189 264.598 62.8922 265.683"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2597"
            d="M473.59 223.069C474.128 224.686 473.687 226.244 472.495 225.65C470.667 224.79 469.978 221.253 471.153 220.771C472.036 220.513 473.157 221.743 473.59 223.043"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2598"
            d="M214.225 86.8352C212.971 87.6958 211.805 88.1691 211.876 87.6958C211.991 86.9041 214.852 84.9248 215.7 85.0453C216.079 85.2346 215.081 86.1812 214.216 86.7664"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2599"
            d="M436.308 234.35C436.732 235.968 435.919 237.792 434.312 236.931C432.202 235.744 432.069 232.482 433.668 232.061C434.992 231.854 436.017 233.317 436.317 234.376"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2600"
            d="M206.826 84.3908C205.607 85.1653 204.177 85.7332 204.292 85.3116C204.442 84.6145 207.824 82.5406 208.477 82.7299C208.804 82.9279 207.682 83.8228 206.826 84.3564"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2601"
            d="M84.0394 159.732C83.0327 160.928 82.0172 161.496 81.9466 160.903C81.8053 159.672 84.5957 156.35 85.3109 156.867C85.6376 157.34 84.7723 158.829 84.0305 159.724"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2602"
            d="M92.5354 166.35C91.4493 167.684 90.2925 168.191 90.2042 167.382C90.0276 165.911 92.5266 162.925 93.5421 163.389C94.0542 163.88 93.286 165.42 92.5354 166.35Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2603"
            d="M104.359 172.494C103.326 173.966 101.887 174.654 101.657 173.776C101.26 172.288 103.653 169.276 104.898 169.697C105.604 170.179 104.96 171.634 104.359 172.494Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2604"
            d="M118.585 178.268C117.702 179.714 115.936 180.617 115.671 179.516C115.247 177.915 117.437 175.118 118.805 175.463C119.689 175.919 119.141 177.39 118.585 178.268Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2605"
            d="M134.956 183.526C134.073 184.929 132.307 185.97 131.927 184.61C131.424 182.812 133.622 180.256 134.921 180.618C135.874 180.988 135.556 182.545 134.921 183.526"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2606"
            d="M456.289 228.851C456.863 230.572 456.21 232.293 454.841 231.605C452.89 230.675 452.395 227.164 453.764 226.7C454.894 226.459 455.927 227.844 456.263 228.86"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2607"
            d="M497.447 268.669C497.721 270.39 497.059 272.068 496.008 271.371C494.56 270.416 494.189 266.948 495.46 266.208C496.458 265.906 497.227 267.43 497.447 268.651"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2608"
            d="M262.872 93.9427C262.484 95.0012 261.53 95.7843 261.159 95.113C260.656 94.2525 261.671 92.1183 262.581 92.1528C263.208 92.256 263.128 93.2629 262.872 93.9427Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2609"
            d="M360.977 245.021C361.055 245.6 360.914 246.186 360.581 246.67C360.248 247.155 359.745 247.505 359.166 247.655C356.906 247.715 355.634 244.557 357.233 242.973C357.52 242.679 357.904 242.493 358.319 242.448C359.009 242.485 359.661 242.769 360.15 243.246C360.638 243.722 360.93 244.357 360.968 245.03"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2610"
            d="M151.256 503.461C151.715 504.115 151.636 504.528 151.089 504.442C149.702 504.235 147.291 501.86 148.007 501.421C148.161 501.366 148.33 501.366 148.484 501.421C149.586 501.842 150.546 502.555 151.256 503.478"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2611"
            d="M480.327 275.338C480.6 277.292 479.603 278.961 478.305 277.92C476.821 276.75 476.724 273.548 478.154 272.886C479.196 272.636 480.132 273.97 480.318 275.321"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2612"
            d="M515.982 410.573C515.555 411.497 515.068 412.393 514.525 413.258C515.154 411.648 515.916 410.091 516.804 408.602L516.865 408.533C516.637 409.238 516.341 409.921 515.982 410.573Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2613"
            d="M460.167 281.534C460.379 283.539 459.081 285.174 457.641 283.987C456.061 282.687 456.176 279.624 457.827 279.013C459.037 278.789 460.008 280.14 460.158 281.534"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2614"
            d="M252.107 93.1429C251.577 94.0895 250.465 95.0016 250.2 94.2874C249.846 93.3064 251.339 91.3185 252.142 91.3701C252.725 91.4906 252.469 92.4974 252.142 93.1429"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2615"
            d="M54.1752 207.5C53.5395 208.714 52.7182 209.454 52.4975 209.015C51.9412 207.957 53.822 203.955 54.7492 204.213C54.7846 204.228 54.8174 204.248 54.8463 204.273C55.2614 204.824 54.6962 206.528 54.1752 207.5Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2616"
            d="M388.076 241.905C388.19 242.766 387.846 244.272 386.451 244.564C384.155 244.668 382.769 241.449 384.411 239.857C384.663 239.607 384.991 239.444 385.347 239.392C387.016 239.263 387.996 240.787 388.111 241.905"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2617"
            d="M72.3921 172.735C71.9418 173.311 70.9969 174.103 70.8645 173.518C70.6525 172.545 73.1074 168.69 73.9816 169.258C73.6366 170.48 73.0973 171.641 72.3833 172.7"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2618"
            d="M371.766 255.287C370.203 257.215 369.832 257.68 369.532 257.68C369.081 257.68 367.58 256.2 367.43 255.296C367.236 254.194 368.092 252.542 369.196 252.413C369.655 252.413 371.192 252.542 371.898 255.132C371.862 255.188 371.82 255.239 371.774 255.287"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2619"
            d="M71.3596 256.629C71.3596 256.629 69.2404 259.383 68.7106 258.109C67.9776 256.251 69.4523 253.006 70.7945 253.54C71.9071 254.142 71.4656 256.363 71.342 256.629"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2620"
            d="M328.789 88.2974C329.531 89.158 329.672 89.8206 329.107 89.7431C328.18 89.4143 327.381 88.8128 326.82 88.022C326.695 87.8561 326.635 87.652 326.651 87.4467C326.667 87.2415 326.758 87.0487 326.908 86.9033C327.634 87.2323 328.27 87.7249 328.762 88.3404"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2621"
            d="M113.206 199.851C112.041 201.262 111.326 202.114 111.202 201.064C111.122 200.333 111.122 200.333 110.831 200.152C110.381 199.877 110.195 199.593 110.54 198.895C110.884 198.198 112.571 196.503 113.33 196.71C114.213 197.174 113.613 199.369 113.206 199.851Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2622"
            d="M173.95 139.845C172.591 141.644 171.072 142.427 170.878 141.437C170.838 140.743 171.058 140.058 171.496 139.509C172.411 138.858 173.393 138.301 174.427 137.849C174.48 138.546 174.314 139.242 173.95 139.845Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2623"
            d="M201.837 95.0358C201.837 95.0358 199.444 96.8085 199.25 96.9118C199.055 97.0151 198.614 97.0409 198.631 96.7225C198.631 95.9738 201.395 93.7794 202.437 93.8741C202.79 94.029 202.437 94.5969 201.819 95.0358"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2624"
            d="M194.764 83.8665C192.592 85.1057 191.956 85.0885 192.115 84.7873C192.38 84.0558 195.903 82.2057 196.336 82.3519C195.985 82.99 195.447 83.5114 194.791 83.8493"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2625"
            d="M105.63 404.782C105.738 405.46 105.584 406.153 105.197 406.726C104.985 406.907 104.429 406.563 103.987 406.236C102.38 405.023 102.336 404.782 102.221 404.067C101.965 402.794 102.53 402.062 103.184 402.019C103.303 402.015 103.422 402.03 103.537 402.062C104.104 402.295 104.599 402.669 104.972 403.146C105.345 403.623 105.584 404.187 105.665 404.782"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2626"
            d="M482.878 315.431C482.724 316.746 482.376 318.033 481.845 319.252C481.792 319.338 481.288 319.562 480.705 318.933C479.354 317.47 479.937 314.286 481.315 313.693C482.039 313.469 482.913 314.441 482.878 315.414"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2627"
            d="M253.026 120.078C253.026 120.078 252.019 122.048 251.683 121.153C251.524 120.723 251.524 120.723 251.427 120.628C250.906 120.473 250.544 120.362 250.694 119.682C250.87 118.927 251.33 118.263 251.984 117.823C252.141 117.74 252.317 117.698 252.496 117.702C253.511 117.78 253.211 119.647 253.026 120.078Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2628"
            d="M254.076 159.706C254.023 160.016 253.952 160.205 253.864 160.266C253.138 160.766 252.383 161.226 251.604 161.642C251.012 160.782 250.659 160.24 250.968 159.173C250.968 159.173 251.577 157.323 252.849 157.288C253.82 157.357 254.314 158.329 254.076 159.706Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2629"
            d="M371.882 83.5214C373.003 84.184 373.719 84.8208 373.383 84.8638C372.5 84.9757 368.968 82.7297 368.721 82.3252C369.806 82.6398 370.863 83.0399 371.882 83.5214Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2630"
            d="M455.477 109.054C456.196 109.677 456.883 110.335 457.534 111.025C456.173 110.067 454.905 108.99 453.746 107.806C453.743 107.786 453.743 107.766 453.746 107.746C454.371 108.134 454.961 108.572 455.512 109.054"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2631"
            d="M350.441 156.557C350.918 158.011 350.079 159.302 348.675 158.579C346.67 157.555 346.776 154.956 347.509 154.629H347.588C347.588 154.629 350.185 155.773 350.45 156.582"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2632"
            d="M152.838 144.999C151.469 146.72 150.188 146.72 150.109 145.799C150.038 144.663 151.054 143.82 152.908 142.297C153.176 142.804 153.386 143.338 153.535 143.889C153.368 144.294 153.132 144.669 152.838 144.999Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2633"
            d="M162.286 116.343C160.688 117.781 159.549 118.065 159.593 117.359C159.673 116.283 162.472 114.011 163.125 114.235C162.737 114.743 162.639 114.898 162.86 115.165C163.081 115.431 162.86 115.767 162.216 116.318"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2634"
            d="M518.702 202.613C519.373 204.076 519.832 205.685 519.399 205.495C518.999 205.144 518.695 204.7 518.516 204.205C518.207 203.559 517.342 201.296 517.36 201.21C517.377 201.124 518.084 201.21 518.746 202.613"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2635"
            d="M277.539 205.495C277.301 207.363 275.385 208.49 274.192 206.976C274.015 206.818 273.894 206.61 273.847 206.381C273.799 206.152 273.827 205.914 273.928 205.702C274.051 205.289 274.422 205.014 274.546 204.609C274.669 204.205 274.546 203.68 274.696 203.232C274.707 203.191 274.721 203.15 274.74 203.112C274.804 202.973 274.91 202.857 275.045 202.78C275.179 202.703 275.335 202.668 275.491 202.681C276.824 202.681 277.707 204.248 277.539 205.495Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2636"
            d="M539.728 321.954C539.701 323.199 539.514 324.437 539.172 325.637C539.158 325.66 539.136 325.679 539.11 325.689C538.677 325.534 539.11 321.506 539.587 320.336C539.7 320.871 539.742 321.417 539.71 321.962"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2637"
            d="M521.812 260.726C521.45 263.6 521.45 263.6 521.07 263.127C519.604 261.311 519.94 258.48 520.311 258.179C521.008 257.774 521.891 260.158 521.812 260.76"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2638"
            d="M253.794 79.8641C253.29 80.5009 252.072 81.6541 252.027 80.9742C251.957 80.1137 253.688 78.3926 254.111 78.3926C254.209 78.3926 254.111 79.3048 254.111 79.3134C254.034 79.5016 253.93 79.6783 253.802 79.8383"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2639"
            d="M402.391 100.784C402.435 100.87 402.497 100.948 402.55 101.034C402.557 101.053 402.557 101.075 402.55 101.094C402.332 101.01 402.122 100.906 401.923 100.784C401.819 100.682 401.73 100.566 401.658 100.44C401.658 100.44 401.658 100.44 401.852 100.517C402.347 100.758 402.347 100.758 402.373 100.793M402.153 100.345C401.19 99.6311 399.618 98.7189 399.159 98.8652C398.991 99.7258 403.283 102.307 403.671 102.023C404.06 101.739 403.124 100.999 402.197 100.302"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2640"
            d="M246.183 305.552C245.238 306.731 244.761 307.325 243.94 307.385C243.488 307.382 243.05 307.23 242.696 306.956C242.342 306.681 242.093 306.299 241.989 305.87C241.974 305.503 242.028 305.135 242.148 304.786C242.289 304.33 242.766 304.063 242.845 303.581C242.874 303.136 242.981 302.7 243.163 302.29C243.247 302.138 243.376 302.014 243.534 301.934C243.691 301.854 243.87 301.822 244.046 301.843C245.883 301.938 246.783 304.812 246.201 305.552"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2641"
            d="M204.989 136.592C204.159 138.038 202.481 138.959 202.181 137.823C202.094 137.007 202.342 136.19 202.87 135.551C202.932 135.482 202.994 135.422 204.115 135C204.537 134.895 204.945 134.745 205.334 134.553C205.581 134.699 205.563 135.594 204.989 136.592Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2642"
            d="M513.015 203.767C513.651 205.1 514.031 206.899 513.519 206.692C512.468 206.262 511.09 202.682 511.258 202.278C511.258 202.278 511.32 202.226 511.461 202.278H511.541C511.623 202.34 511.7 202.409 511.77 202.484C511.965 202.665 512.044 202.751 512.115 202.743C512.335 202.743 512.335 202.743 512.459 202.82C512.692 203.075 512.88 203.366 513.015 203.681"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2643"
            d="M108.403 210.039C107.405 212.062 105.895 212.475 105.454 211.313C105.332 210.79 105.33 210.247 105.45 209.723C105.569 209.199 105.805 208.708 106.143 208.284C106.264 208.151 106.424 208.058 106.602 208.017H106.699C106.929 208.017 107.149 208.095 107.379 208.069C107.608 208.043 107.926 207.922 108.209 207.905C108.305 207.905 108.4 207.92 108.491 207.948C109.012 208.163 108.986 208.878 108.403 210.048"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2644"
            d="M522.164 215.16C522.401 216.146 522.569 217.146 522.667 218.154C522.12 217.974 520.495 214.101 520.787 213.18C521.087 212.845 521.996 214.626 522.191 215.142"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2645"
            d="M168.829 138.907C167.946 140.103 166.179 141.161 166.1 140.395C166.038 139.939 166.436 139.414 167.221 138.356L167.416 138.098C168.387 136.815 168.793 136.282 169.411 136.42C170.029 136.557 169.676 137.796 168.837 138.907"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2646"
            d="M507.134 185.247C507.47 185.833 507.47 185.833 507.823 187.906C507.293 187.855 505.077 184.146 505.333 183.32C505.518 183.053 506.331 183.827 507.099 185.247"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2647"
            d="M186.454 95.8879C184.759 96.7485 184.37 96.912 184.494 96.4559C184.812 95.3027 187.735 93.6419 188.256 93.6763C188.185 93.9259 187.823 93.9861 187.84 94.2959C187.845 94.3247 187.857 94.3518 187.876 94.3745C187.894 94.3971 187.919 94.4146 187.946 94.425C188.026 94.468 188.088 94.5024 187.946 94.6831C187.493 95.1267 186.989 95.5193 186.445 95.8535"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2648"
            d="M209.643 123.683C208.433 125.404 207.171 125.482 207.135 125.026C207.131 124.928 207.149 124.832 207.188 124.742C207.209 124.677 207.236 124.613 207.268 124.552C207.427 124.174 207.665 123.838 207.833 123.468C207.94 123.064 208.081 122.67 208.257 122.289C208.649 121.941 209.093 121.651 209.572 121.429C210.959 121.739 209.776 123.511 209.643 123.709"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2649"
            d="M146.77 101.989C146.089 102.491 145.273 102.79 144.421 102.849C144.35 102.66 145.481 101.653 146.54 100.965C147.423 100.371 148.518 99.8462 148.792 100.001C149.084 100.431 146.779 102.006 146.752 102.023"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2650"
            d="M426.257 86.8433C426.655 87.0842 428.615 88.375 428.677 88.4869C427.139 87.6864 425.664 86.7776 424.262 85.7676C424.948 86.1039 425.614 86.4774 426.257 86.8863"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2651"
            d="M414.691 286.327C414.09 287.506 413.693 288.289 412.836 288.298C412.306 288.217 411.809 287.998 411.395 287.665C410.982 287.332 410.668 286.897 410.488 286.404C410.3 285.703 410.375 284.959 410.699 284.307C411.022 283.654 411.573 283.135 412.254 282.842C412.916 282.842 413.137 283.358 413.455 284.296C413.569 284.589 413.569 284.589 414.761 286.138C414.761 286.198 414.691 286.275 414.664 286.327"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2652"
            d="M427.822 88.4011C428.672 88.9141 429.481 89.4895 430.241 90.1222C429.932 90.191 428.475 89.2616 425.879 87.334C426.547 87.6556 427.196 88.0148 427.822 88.4097"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2653"
            d="M95.6255 127.315C95.2146 127.695 94.7579 128.024 94.2656 128.296C94.2795 128.24 94.3067 128.187 94.3451 128.142C94.5217 127.849 94.7954 127.634 94.9721 127.341C95.1487 127.049 95.1133 126.945 95.237 126.765C95.3867 126.563 95.552 126.373 95.7315 126.197C96.7293 125.199 97.8596 124.39 98.0009 124.57C98.0009 125.035 95.9081 127.083 95.6343 127.298"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2654"
            d="M518.587 351.522C518.342 352.924 517.921 354.291 517.333 355.593C516.088 355.851 516.45 351.978 517.545 350.429C517.615 350.327 517.705 350.239 517.81 350.171C518.437 349.87 518.693 351.032 518.587 351.557"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2655"
            d="M151.362 122.006C150.406 122.759 149.376 123.419 148.289 123.977C147.582 123.529 149.984 120.638 151.697 120.336C151.81 120.308 151.929 120.308 152.042 120.336C152.448 120.586 151.998 121.472 151.362 122.006Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2656"
            d="M156.66 119.381C156.018 120.027 155.228 120.517 154.355 120.81C154.779 120.276 155.238 119.777 155.653 119.218C155.715 119.115 155.812 118.951 155.653 118.977C155.441 119.04 155.236 119.126 155.044 119.235C154.895 119.254 154.743 119.254 154.594 119.235C156.042 117.514 157.481 117.023 158.029 117.178L158.117 117.23C157.234 118.848 157.234 118.848 156.678 119.347"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2657"
            d="M517.723 213.869C517.855 214.146 517.958 214.434 518.032 214.73C518.106 215.405 518.106 216.086 518.032 216.761C517.423 216.675 515.728 212.811 516.266 212.002C516.558 211.727 517.229 212.535 517.794 213.861"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2658"
            d="M362.326 82.937C362.91 83.2762 363.444 83.6901 363.916 84.1676C363.1 84.0658 362.312 83.8147 361.593 83.4275C360.472 82.8079 359.288 81.9215 359.509 81.7494C359.96 81.6462 361.558 82.4379 362.326 82.9198"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2659"
            d="M277.52 185.936C276.743 186.745 276.178 187.322 275.604 187.141C275.162 186.917 274.786 186.587 274.513 186.182C274.239 185.777 274.077 185.31 274.041 184.826C273.97 183.019 276.169 182.391 276.186 182.391C276.646 182.391 276.646 182.391 277.652 185.833L277.52 185.962"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2660"
            d="M204.388 87.1706C203.455 87.7748 202.481 88.3182 201.474 88.7971C200.635 88.3926 203.726 86.3703 205.006 85.9659C205.124 85.9306 205.25 85.9306 205.368 85.9659C205.545 86.0691 205.413 86.4908 204.362 87.2051"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2661"
            d="M125.975 119.605C125.216 120.242 123.406 121.713 123.026 121.696C122.796 121.318 123.476 120.655 125.022 119.201C126.399 117.91 126.938 117.884 127.07 117.901C127.113 117.905 127.154 117.924 127.185 117.953C127.459 118.314 126.434 119.226 125.975 119.605Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2662"
            d="M53.8676 267.241C53.276 269.246 52.3488 269.452 52.1015 268.884C51.9822 268.593 51.9136 268.285 51.8984 267.972V267.826C51.8984 267.473 52.0485 267.137 52.0574 266.784C52.0662 266.432 51.9073 265.984 51.9161 265.58C51.9251 265.397 51.9517 265.215 51.9956 265.038C52.1898 264.263 53.0022 263.626 53.4172 263.764H53.4967C53.9824 264.117 54.2738 265.958 53.9029 267.206"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2663"
            d="M95.7842 123.03C95.2367 123.521 94.2742 124.329 94.1152 124.209C94.8847 123.257 95.7102 122.349 96.5877 121.49C96.7113 121.369 97.5591 120.784 97.9652 120.5C97.3859 121.447 96.6488 122.293 95.7842 123.004"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2664"
            d="M387.679 117.746C387.882 118.004 388.562 119.312 387.953 119.252C386.954 118.917 386.048 118.364 385.304 117.634C384.421 116.704 384.218 115.913 384.553 115.672C384.586 115.649 384.621 115.632 384.659 115.62C384.916 115.738 385.163 115.873 385.401 116.025L385.533 116.111C385.931 116.403 386.204 116.842 386.628 117.109C386.93 117.243 387.225 117.392 387.511 117.556C387.58 117.613 387.642 117.676 387.697 117.746"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2665"
            d="M359.396 114.002C359.622 114.439 359.811 114.894 359.961 115.362C359.961 115.474 359.961 115.577 359.961 115.689C360.047 115.924 360.112 116.166 360.155 116.412C360.155 116.575 360.014 116.558 359.97 116.549C359.106 116.197 358.373 115.597 357.868 114.828C357.55 114.363 357.55 114.363 357.232 112.539C359.228 113.727 359.228 113.727 359.405 114.002"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2666"
            d="M149.26 138.692C148.985 138.891 148.689 139.061 148.377 139.2L148.236 139.251C148.033 139.32 147.803 139.251 147.591 139.355C147.379 139.458 147.159 139.613 146.92 139.69H146.867C146.77 139.69 146.629 139.69 146.867 139.019C147.282 137.461 149.446 135.792 150.258 135.912C150.938 136.196 149.984 138.279 149.322 138.683"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2667"
            d="M301.345 104.993C301.345 104.993 301.265 106.585 300.824 106.266C300.55 106.068 300.559 105.604 300.117 105.561C299.913 105.566 299.714 105.501 299.553 105.379C299.392 105.257 299.279 105.084 299.234 104.889C298.978 104.029 299.234 103.039 299.799 102.91H299.879C300.682 102.91 301.318 104.631 301.345 105.01"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2668"
            d="M295.209 237.878C292.869 239.643 292.683 239.789 292.33 239.711C291.676 239.565 290.926 238.145 291.076 236.958C291.109 236.413 291.333 235.895 291.71 235.493C292.088 235.09 292.597 234.826 293.151 234.746C293.769 234.746 295.226 235.133 295.35 237.767L295.209 237.878Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2669"
            d="M306.317 199.936C306.317 202.036 304.551 202.983 303.244 201.658C303.042 201.485 302.882 201.27 302.778 201.028C302.674 200.787 302.628 200.525 302.644 200.263C302.763 199.18 303.244 198.164 304.012 197.372C304.339 197.391 304.658 197.472 304.952 197.613C305.246 197.753 305.507 197.95 305.722 198.19C305.937 198.431 306.1 198.711 306.202 199.013C306.304 199.316 306.343 199.636 306.317 199.954"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2670"
            d="M512.522 317.101C512.351 317.768 512.126 318.421 511.851 319.054C510.218 319.648 509.838 316.197 510.694 314.416C510.815 314.138 511.018 313.901 511.277 313.736C511.569 313.624 511.666 313.736 511.798 314.097C511.918 314.634 512.006 315.177 512.063 315.724C512.145 316.018 512.248 316.305 512.372 316.584C512.522 316.954 512.531 317.023 512.522 317.083"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2671"
            d="M416.853 410.693C415.14 413.86 413.171 412.414 412.906 411.554C412.729 410.713 412.891 409.838 413.357 409.111C413.824 408.383 414.561 407.859 415.414 407.647C415.543 407.616 415.68 407.627 415.802 407.679C415.925 407.73 416.027 407.818 416.094 407.931C416.126 407.989 416.152 408.049 416.173 408.111C416.27 408.447 416.235 408.808 416.332 409.144C416.473 409.669 416.703 410.168 416.871 410.684"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2672"
            d="M527.576 324.303C527.576 324.303 527.497 326.945 526.693 327.651C525.881 328.141 525.748 325.37 525.952 324.544C525.979 324.438 526.014 324.335 526.058 324.234L526.119 324.097C526.208 323.881 526.367 323.709 526.464 323.503C526.676 323.038 526.843 322.548 527.029 322.074C527.223 322.264 527.629 322.659 527.576 324.294"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2673"
            d="M120.059 123.271C119.398 123.84 118.643 124.294 117.825 124.613C117.462 124.338 117.877 123.753 118.275 123.288C118.275 123.288 119.758 121.567 120.376 121.567C120.456 121.567 120.527 121.627 120.606 121.627C120.683 121.621 120.758 121.601 120.827 121.567C121.71 121.162 121.71 121.162 121.71 121.171C121.807 121.326 121.021 122.402 120.041 123.236"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2674"
            d="M507.083 424.781C506.724 425.565 506.214 426.274 505.582 426.872C505.343 426.433 507.401 423.481 507.613 423.258C507.666 423.258 507.674 423.206 507.71 423.197C507.745 423.189 507.383 424.239 507.083 424.781Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2675"
            d="M260.938 103.891L260.681 104.364L260.522 103.77L260.955 103.856M261.838 104.786C262.085 103.985 261.997 102.806 261.282 102.789C260.982 102.789 260.982 102.789 260.522 103.65C260.249 103.65 259.993 103.65 259.825 104.631C259.757 104.876 259.745 105.132 259.791 105.382C259.837 105.632 259.939 105.869 260.09 106.076C260.558 106.532 261.503 105.827 261.856 104.768"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2676"
            d="M92.668 134.225C91.1934 135.387 91.1934 135.387 91.1934 135.206C91.7742 134.191 92.4639 133.239 93.2508 132.366C93.6923 131.893 94.6283 131.299 94.7696 131.402C94.7696 131.772 93.2155 133.743 92.6504 134.191"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2677"
            d="M136.104 91.8433C135.362 92.2908 134.108 92.9964 134.1 92.7038C134.409 92.0584 137.49 90.2857 137.543 90.475C137.393 90.9225 137.358 91.0257 136.104 91.783"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2678"
            d="M507.55 202.475C508.124 203.688 508.521 205.495 507.929 205.358C507.474 205.088 507.104 204.701 506.861 204.239C506.817 204.144 506.737 203.964 506.675 203.8C506.508 203.37 506.481 202.94 506.278 202.492C506.047 202.054 505.843 201.603 505.669 201.141C505.527 200.719 505.607 200.608 505.669 200.53C506.075 200.177 506.993 201.27 507.558 202.475"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2679"
            d="M186.03 109.613C184.538 110.982 183.487 111.068 183.522 110.474C183.8 109.678 184.324 108.986 185.023 108.495C185.173 108.335 185.368 108.223 185.584 108.173C185.8 108.124 186.027 108.14 186.233 108.219L186.586 108.34C186.878 108.434 187.205 108.529 186.012 109.63"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2680"
            d="M124.598 458.265C124.881 459.126 124.81 460.382 123.389 459.659C122.56 459.247 121.907 458.565 121.543 457.731C121.852 457.568 121.887 457.301 121.711 456.561C121.578 456.002 121.578 456.002 121.587 455.959C121.596 455.916 121.587 455.959 121.887 455.718C122.485 455.939 123.03 456.278 123.486 456.715C123.942 457.152 124.3 457.677 124.536 458.256"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2681"
            d="M173.199 94.6401C171.777 95.5781 171.062 96.0514 171.089 95.6728C171.089 95.6728 171.195 94.9672 172.59 94.055C173.506 93.4201 174.536 92.9589 175.627 92.6953C175.566 92.9965 175.115 93.2977 173.446 94.4164C173.349 94.4745 173.254 94.5377 173.164 94.6057"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2682"
            d="M113.746 205.943C113.534 206.382 113.357 206.605 113.013 206.605C112.873 206.602 112.734 206.584 112.598 206.554C112.298 206.494 112.015 206.347 111.715 206.287C111.415 206.227 111.274 206.287 111.062 206.218C111 206.206 110.943 206.18 110.895 206.141C110.847 206.101 110.81 206.051 110.788 205.994C110.523 205.315 112.183 202.44 113.631 202.716C114.408 203.094 114.338 204.764 113.746 205.943Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2683"
            d="M531.605 284.606C531.843 286.8 531.393 287.962 531.075 287.704C530.757 287.446 530.103 282.816 530.536 282.386C531.278 281.8 531.578 284.322 531.605 284.606Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2684"
            d="M217.678 91.6881C216.874 92.4023 215.409 93.2543 215.444 92.6002C215.479 91.9462 216.998 90.3112 217.881 90.5005C218.517 90.6554 218.517 90.6554 218.499 90.7501C218.286 91.1083 218.007 91.4261 217.678 91.6881Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2685"
            d="M392.871 407.328C392.839 407.885 392.744 408.436 392.589 408.972C392.425 409.275 392.146 409.504 391.812 409.609C391.404 409.734 390.963 409.71 390.572 409.542C390.18 409.374 389.865 409.074 389.683 408.697C388.739 407.182 390.346 404.902 391.759 404.592C392.536 404.592 393.357 405.065 392.854 407.328"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2686"
            d="M268.231 87.2993C267.737 88.8569 267.004 88.9257 266.695 88.375C266.637 88.2353 266.614 88.084 266.63 87.9341C266.645 87.7842 266.698 87.6401 266.783 87.5144L268.028 85.974C268.107 85.8794 268.222 85.8622 268.328 85.8105C268.328 86.1376 268.328 86.4559 268.328 86.783C268.316 86.9666 268.284 87.1484 268.231 87.3251"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2687"
            d="M164.414 101.215C163.425 101.903 161.854 102.557 161.924 102.075C162.347 101.325 162.953 100.69 163.69 100.225C164.688 99.571 164.9 99.6915 165.244 99.8894L165.545 100.036C165.801 100.242 164.662 101.068 164.397 101.232"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2688"
            d="M391.087 89.5624C391.758 89.9927 392.561 90.6897 392.261 90.7672C391.457 90.9651 388.861 89.0461 387.607 88.1855C388.804 88.5545 389.967 89.0179 391.087 89.571"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2689"
            d="M179.001 107.471C178.445 107.97 176.537 109.063 176.493 108.435C176.449 107.807 178.18 106.37 178.409 106.189C178.75 106.02 179.139 105.969 179.513 106.043C180.193 106.284 180.299 106.327 179.001 107.471Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2690"
            d="M277.186 237.578C277.018 239.737 274.819 240.753 273.512 239.299C273.17 238.924 272.945 238.461 272.864 237.965C272.783 237.47 272.848 236.962 273.053 236.502C273.167 236.337 273.341 236.221 273.539 236.175C273.662 236.175 273.662 236.175 276.188 236.175C276.523 236.175 277.318 236.226 277.203 237.621"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2691"
            d="M212.865 102.876C212.593 103.127 212.241 103.282 211.868 103.315C211.868 103.315 211.179 103.177 211.055 103.134C211.025 103.128 210.998 103.112 210.978 103.088C210.959 103.064 210.949 103.035 210.949 103.005C210.949 102.549 212.945 100.612 213.757 100.733C214.57 100.853 213.033 102.712 212.874 102.876"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2692"
            d="M156.855 129.854C154.788 132.031 153.87 131.799 153.844 131.179C153.799 130.387 154.585 129.622 155.565 128.658C155.477 129.269 155.477 129.269 155.565 129.303C155.654 129.338 156.113 129.226 156.325 129.079C156.52 128.924 156.701 128.751 156.864 128.563C157.376 128.03 157.455 127.969 157.658 127.952C157.923 128.073 157.446 129.269 156.89 129.854"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2693"
            d="M185.96 123.529C184.6 125.155 183.311 125.25 183.258 124.639C183.205 124.028 184.3 122.573 184.591 122.332C184.883 122.091 185.28 122.057 186.075 122.264H186.022C186.207 122.388 186.344 122.57 186.41 122.78C186.41 122.875 186.41 122.986 185.916 123.554"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2694"
            d="M145.464 499.235C146.179 500.242 146.047 500.629 145.605 500.56C145.356 500.475 145.114 500.372 144.881 500.25C144.759 500.077 144.661 499.889 144.59 499.691C144.583 499.562 144.545 499.436 144.481 499.323C144.417 499.209 144.327 499.112 144.219 499.037C143.733 498.658 143.054 498.615 142.612 498.176C142.391 497.935 142.232 497.367 142.4 497.221C142.568 497.075 142.612 497.161 142.744 497.221C143.792 497.713 144.719 498.417 145.464 499.286"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2695"
            d="M102.875 178.991C101.108 180.566 100.746 179.679 100.791 178.991C100.914 176.969 103.042 175.23 103.581 175.325C103.139 178.724 103.086 178.767 102.857 178.974"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2696"
            d="M405.498 339.105C405.332 339.551 405.064 339.954 404.713 340.284C404.363 340.613 403.94 340.861 403.476 341.006C401.799 341.058 401.216 339.182 401.207 339.165C401.071 338.587 401.08 337.985 401.234 337.411C401.388 336.838 401.681 336.309 402.09 335.869C402.173 335.779 402.28 335.714 402.399 335.68C402.77 335.68 405.578 338.494 405.516 339.122"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2697"
            d="M155.831 102.721C155.566 102.884 153.614 103.926 153.747 103.375C153.747 103.375 154.7 101.43 158.1 100.251L156.44 102.101C156.383 102.203 156.315 102.298 156.237 102.385C156.114 102.511 155.978 102.624 155.831 102.721Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2698"
            d="M356.693 222.225C356.187 223.427 355.487 224.543 354.618 225.529C354.221 225.719 353.32 224.841 352.958 224.066C352.659 223.427 352.63 222.698 352.878 222.038C353.126 221.377 353.63 220.839 354.282 220.538C354.768 220.538 354.768 220.538 356.746 222.087C356.724 222.131 356.707 222.177 356.693 222.225Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2699"
            d="M405.993 97.8065C406.876 98.4003 407.671 99.1576 407.503 99.3899C406.946 99.5707 403.812 97.7377 403.971 97.316C403.971 97.2472 404.077 97.2214 404.112 97.1525H404.174C404.527 96.989 404.677 96.9202 405.993 97.841"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2700"
            d="M251.269 79.4948C250.739 80.0714 249.344 81.3106 249.344 80.6479C249.344 80.6479 249.344 79.9423 250.412 79.0043C250.518 78.9182 250.739 78.7203 251.445 78.8064C251.507 78.8022 251.569 78.8022 251.631 78.8064C251.575 79.0557 251.45 79.2851 251.269 79.469"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2701"
            d="M107.643 204.42C106.654 205.461 106.363 205.78 105.877 205.797C105.391 205.814 104.923 205.659 104.888 204.523C104.844 203.249 105.842 201.701 106.584 201.89H106.637C106.893 202.019 107.069 202.277 107.352 202.38C107.543 202.414 107.732 202.46 107.917 202.518C107.994 202.572 108.052 202.647 108.085 202.733C108.151 203.327 107.983 203.924 107.617 204.403"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2702"
            d="M162.081 110.018C161.007 110.73 159.855 111.325 158.646 111.791C160.059 109.209 162.47 108.495 162.814 108.607C163.159 108.719 162.717 109.519 162.081 110.018Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2703"
            d="M147.909 220.263C147.732 220.685 147.538 221.055 147.026 221.081C147.026 221.081 144.827 220.96 144.553 220.418C144.094 219.557 145.675 216.916 147.203 217.225C148.898 217.699 148.024 220.005 147.918 220.263"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2704"
            d="M510.578 334.647C510.459 335.483 510.242 336.302 509.934 337.091C509.669 337.538 509.66 337.53 509.245 336.91C508.256 335.43 508.256 335.189 508.256 334.802C508.256 334.122 508.653 332.65 509.271 332.298C509.625 332.151 509.863 332.409 509.995 332.65C510.247 333.173 510.471 333.707 510.667 334.251C510.667 334.363 510.605 334.526 510.578 334.647Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2705"
            d="M536.099 304.717C536.152 306.628 535.789 308.211 535.498 307.884C535.498 307.884 534.853 303.384 535.427 302.316C535.86 302.041 536.072 303.642 536.099 304.717Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2706"
            d="M137.331 108.917C136.448 109.554 135 110.259 135.018 109.872C135.132 109.588 135.31 109.332 135.539 109.123C136.04 108.525 136.636 108.008 137.305 107.592C137.481 107.458 137.677 107.354 137.888 107.282C138.081 107.26 138.277 107.26 138.47 107.282L138.294 107.428L138.373 107.471C138.373 107.471 138.373 107.583 138.444 107.626C138.515 107.669 138.559 107.703 138.568 107.721C138.581 107.744 138.588 107.771 138.588 107.798C138.588 107.825 138.581 107.852 138.568 107.876C138.221 108.271 137.827 108.623 137.393 108.925"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2707"
            d="M310.307 145.421C310.475 146.463 310.422 147.736 309.248 147.865C308.891 147.816 308.554 147.675 308.272 147.458C307.99 147.24 307.772 146.953 307.641 146.626C307.641 146.514 307.641 146.514 308.983 143.7C309.407 144.165 309.866 144.561 310.254 145.094C310.316 145.172 310.299 145.318 310.316 145.421"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2708"
            d="M184.793 104.304C184.484 104.595 184.117 104.823 183.716 104.975C183.666 104.983 183.615 104.983 183.566 104.975C183.478 104.934 183.392 104.888 183.31 104.837H183.265C183.212 104.837 183.16 104.837 183.107 104.837C183.054 104.837 182.992 104.777 182.93 104.76L182.815 104.708C182.674 104.656 182.674 104.588 182.674 104.562C182.674 104.02 185.243 102.187 185.968 102.41C186.533 102.686 185.5 103.744 184.793 104.329"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2709"
            d="M419.141 291.146C418.946 292.824 418.946 292.824 418.372 293.073C418.003 293.165 417.613 293.142 417.258 293.006C416.903 292.87 416.601 292.629 416.394 292.316C414.77 290.44 416.112 287.902 417.18 287.661C418.452 287.609 418.637 288.951 418.946 290.802L419.229 290.879C419.229 290.957 419.229 291.068 419.229 291.146"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2710"
            d="M498.993 167.064C499.991 169.086 500.432 170.067 500.432 170.093C499.929 170.213 497.465 166.401 497.545 165.601C498.128 165.989 498.615 166.501 498.967 167.098"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2711"
            d="M308.03 363.432C307.944 363.763 307.752 364.058 307.482 364.275C307.212 364.491 306.878 364.618 306.528 364.637C306.122 364.697 304.232 364.801 304.224 364.801C304.215 364.801 303.341 364.525 303.164 364.233C302.987 363.94 303.288 360.704 305.363 360.489C306.678 360.489 308.215 362.408 308.012 363.432"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2712"
            d="M359.484 145.998C359.722 146.583 359.916 148.666 358.3 148.02C357.417 147.676 355.501 145.705 356.755 145.12C356.881 145.064 357.015 145.029 357.152 145.017C358.239 144.871 358.239 144.871 358.592 145.017C358.803 145.108 358.992 145.243 359.145 145.412C359.297 145.582 359.41 145.782 359.475 145.998"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2713"
            d="M133.305 103.805C132.263 104.519 130.832 105.337 130.903 104.924C131.474 104.136 132.197 103.464 133.031 102.945C133.304 102.754 133.601 102.598 133.914 102.48C133.98 102.461 134.051 102.461 134.117 102.48C134.214 102.54 134.276 102.652 134.373 102.712C134.117 103.168 134.091 103.22 133.278 103.771"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2714"
            d="M224.168 148.131C223.717 149.069 222.914 148.992 222.198 148.889L222.084 149.104C221.863 148.975 221.624 148.889 221.404 148.76C221.334 148.704 221.274 148.637 221.227 148.562C221.153 148.274 221.184 147.969 221.315 147.701C221.702 146.829 222.393 146.118 223.267 145.696C223.355 145.687 223.444 145.687 223.532 145.696C224.415 145.825 224.494 147.417 224.168 148.123"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2715"
            d="M90.0887 329.837C90.0887 330.259 90.0887 330.276 89.762 330.37C89.3167 330.508 88.8386 330.508 88.3933 330.37V330.413C87.0776 330.018 86.8392 329.949 86.7773 329.088C86.8514 328.063 87.3329 327.106 88.1196 326.42C88.1856 326.389 88.2581 326.373 88.3315 326.373C88.4049 326.373 88.4773 326.389 88.5434 326.42C89.7355 326.894 90.2565 328.056 90.0887 329.863"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2716"
            d="M534.818 294.287C534.818 295.44 534.818 295.44 534.695 295.733C534.593 295.165 534.566 294.586 534.615 294.012L534.818 294.209V294.278M535.401 293.504C535.378 292.679 535.18 291.867 534.818 291.12V291.198C534.792 291.693 534.675 292.179 534.474 292.635C534.311 292.844 534.233 293.104 534.253 293.366C534.253 293.366 534.253 295.758 534.757 295.836C534.757 296.18 534.889 296.817 535.074 296.628C535.365 295.611 535.496 294.558 535.463 293.504"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2717"
            d="M386.442 107.591C387.635 108.727 387.891 109.45 387.449 109.501C386.628 109.588 384.412 107.78 384.332 107.324C384.506 107.118 384.735 106.963 384.994 106.877C385.277 106.774 385.303 106.791 385.339 106.808C385.74 107.021 386.111 107.285 386.442 107.591Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2718"
            d="M404.792 223.687C405.172 225.486 404.05 227.13 402.576 226.157C401.339 225.34 401.348 224.901 401.357 224.608C401.404 224.341 401.519 224.089 401.693 223.877C401.89 223.612 402.05 223.322 402.169 223.016C402.39 222.474 402.531 221.906 402.734 221.347C403.261 221.532 403.729 221.849 404.09 222.265C404.451 222.682 404.693 223.185 404.792 223.722"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2719"
            d="M150.796 360.98C150.408 362.537 150.408 362.537 149.913 362.64C149.595 362.709 148.774 362.744 148.085 361.573C146.611 359.078 147.873 356.883 149.41 357.064C150.611 357.4 150.602 358.596 150.584 360.575L150.84 360.721C150.84 360.799 150.787 360.902 150.77 360.98"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2720"
            d="M337.743 86.491C338.317 87.0074 338.255 87.1365 337.743 87.2483C337.557 87.2483 337.39 87.3688 337.204 87.2914C336.88 87.1391 336.582 86.9388 336.321 86.6976C335.509 85.9747 335.173 85.2691 335.341 85.14H335.385C335.774 85.0711 336.824 85.6907 337.734 86.5083"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2721"
            d="M87.8808 257.112C87.7819 257.432 87.5648 257.704 87.2715 257.878C86.7551 257.984 86.2251 258.013 85.6997 257.964C85.5055 257.671 85.2494 257.404 85.0551 257.103C84.9879 256.98 84.9346 256.85 84.8962 256.716C84.8472 256.348 84.8834 255.975 85.0021 255.623C85.426 254.358 86.521 253.721 87.0861 253.842H87.1832C88.1457 254.272 88.2164 256.234 87.8808 257.086"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2722"
            d="M285.882 216.106C285.803 218.292 283.807 219.032 282.624 217.827C282.289 217.474 282.051 217.045 281.932 216.578C281.813 216.112 281.817 215.624 281.944 215.159C282.147 214.755 282.906 214.574 284.196 214.626C284.434 214.626 284.673 214.626 284.911 214.626C285.317 214.626 285.944 214.669 285.882 216.106Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2723"
            d="M131.123 477.833C131.265 478.108 131.6 478.737 130.011 478.134C129.65 478.044 129.295 477.929 128.951 477.79C128.678 477.642 128.445 477.433 128.271 477.179C127.265 475.776 127.997 475.406 128.033 475.389C128.168 475.347 128.313 475.347 128.448 475.389C129.585 475.903 130.516 476.768 131.097 477.85"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2724"
            d="M331.289 183.698C331.351 185.144 331.351 185.144 331.228 185.239C330.954 185.367 330.651 185.42 330.349 185.393C330.047 185.366 329.759 185.259 329.514 185.084C328.871 184.415 328.449 183.572 328.305 182.666C328.305 182.063 328.729 180.833 329.506 180.721C330.592 180.721 330.892 182.278 331.113 183.414H331.289V183.664"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2725"
            d="M524.814 358.613C524.767 358.983 524.644 359.34 524.452 359.663C524.311 359.904 523.984 359.955 523.834 360.196C523.772 360.196 523.728 360.196 523.596 360.325C523.463 360.454 523.322 360.532 523.251 360.497C523.181 360.463 523.163 360.119 523.189 359.809C523.294 358.666 523.63 357.554 524.178 356.539C524.254 356.431 524.343 356.333 524.443 356.246C524.991 355.859 525.097 356.952 524.885 358.225C524.885 358.346 524.885 358.501 524.814 358.613Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2726"
            d="M316.992 168.432C317.399 171.014 317.434 171.22 316.586 171.332C314.979 171.332 313.646 168.535 315.023 167.176C315.255 166.936 315.569 166.789 315.906 166.763C316.789 166.763 316.789 167.159 317.019 168.432"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2727"
            d="M366.769 136.42C367.652 138.02 366.981 138.674 366.116 138.433C365.251 138.192 363.264 136.17 364.023 135.68C364.469 135.479 364.947 135.354 365.436 135.31C365.71 135.31 366.134 135.232 366.769 136.42Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2728"
            d="M210.093 81.1728C208.883 81.8871 207.497 82.4722 207.506 82.1624C207.515 81.8526 209.952 79.9853 210.561 80.1746C210.667 80.2262 210.72 80.3553 210.853 80.3639C210.985 80.3725 211.082 80.3639 211.118 80.3639C211.153 80.3639 211.118 80.5102 210.093 81.1384"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2729"
            d="M277.723 194.868C277.723 195.144 277.582 196.039 276.937 196.176H276.664C276.318 196.079 275.967 195.999 275.613 195.936C275.319 195.963 275.024 195.963 274.73 195.936C274.526 195.892 274.344 195.779 274.218 195.617C274.004 195.276 273.869 194.895 273.82 194.498C274.703 192.777 274.986 192.519 275.825 192.502C276.396 192.583 276.914 192.873 277.275 193.312C277.635 193.751 277.812 194.308 277.767 194.868"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2730"
            d="M526.252 379.266C525.956 380.196 525.54 381.087 525.016 381.916C525.332 380.136 525.875 378.402 526.632 376.753C526.659 376.73 526.693 376.715 526.729 376.71C526.941 376.977 526.367 378.99 526.278 379.292"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2731"
            d="M258.43 108.856C258.271 109.192 257.07 110.681 256.549 109.244C256.169 108.177 257.176 106.309 258.138 106.292C258.221 106.297 258.302 106.317 258.377 106.352C258.271 106.903 258.271 106.903 258.244 106.946C258.103 107.221 257.803 107.394 257.759 107.729C257.715 108.065 257.803 108.048 258.121 108.211C258.439 108.375 258.606 108.469 258.43 108.856Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2732"
            d="M481.386 135.732C482.06 136.435 482.652 137.208 483.152 138.038C482.843 137.831 479.973 134.596 479.814 134.32C480.106 134.381 480.433 134.673 481.377 135.732"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2733"
            d="M250.616 129.166C249.733 129.854 249.503 130.026 249.062 129.923C248.787 129.786 248.565 129.566 248.428 129.296C248.292 129.027 248.247 128.721 248.302 128.425C248.364 127.358 249.185 126.111 249.812 126.119C249.909 126.119 250.112 126.119 250.739 128.202C250.739 128.202 250.739 128.202 250.739 129.062L250.642 129.148"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2734"
            d="M508.734 180.428C509.244 181.25 509.608 182.149 509.812 183.088C509.457 182.794 509.158 182.441 508.929 182.046C508.152 180.618 507.56 179.095 506.871 177.623C508.637 180.127 508.637 180.127 508.805 180.403"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2735"
            d="M145.702 107.101C144.386 107.316 144.359 107.316 143.724 108.091C143.538 108.323 143.22 108.719 142.929 108.727C142.637 108.736 142.452 108.65 142.876 108.108C143.821 106.877 146.558 105.122 147.053 105.423C147.3 105.767 146.037 107.049 145.702 107.101Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2736"
            d="M508.874 177.804C509.475 178.836 509.431 179.138 509.413 179.292C509.395 179.447 509.413 179.404 509.554 179.697C509.068 179.439 509.068 179.439 508.671 178.767C508.149 177.889 507.714 176.964 507.373 176.005C507.417 175.721 507.903 176.375 508.168 176.728C508.291 176.9 508.583 177.296 508.883 177.804"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2737"
            d="M454.19 106.989C454.79 107.506 456.017 108.573 457.501 109.889C456.132 108.685 454.737 107.523 453.386 106.318C453.361 106.303 453.34 106.282 453.324 106.258C453.395 106.309 453.978 106.8 454.207 106.989"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2738"
            d="M459.725 113.065C461.792 115.061 462.163 115.466 462.233 115.543C460.889 114.547 459.639 113.435 458.498 112.222C458.498 112.222 458.498 112.222 458.498 112.17C458.943 112.425 459.355 112.731 459.725 113.082"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2739"
            d="M51.7909 246.604C51.5352 247.347 51.0847 248.012 50.484 248.532C50.1485 248.248 50.0779 245.641 50.2456 244.66C50.3251 244.229 50.643 243.954 51.4112 243.309C52.1883 243.894 52.1883 245.443 51.7909 246.604Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2740"
            d="M115.396 417.466C115.59 419.127 114.468 419.394 113.788 418.903C113.305 418.509 112.975 417.965 112.852 417.363C112.164 414.437 112.499 414.36 112.702 414.308C112.807 414.287 112.915 414.287 113.02 414.308C114.301 414.626 115.254 416.184 115.396 417.449"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2741"
            d="M358.812 110.147C359.007 110.847 359.149 111.561 359.236 112.281C358.363 111.982 357.605 111.429 357.064 110.698C356.838 110.452 356.681 110.153 356.608 109.83C356.536 109.507 356.55 109.172 356.649 108.856C356.68 108.825 356.72 108.804 356.764 108.796C357.591 108.991 358.319 109.471 358.812 110.147Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2742"
            d="M368.306 448.231C368.306 448.369 368.244 448.506 368.218 448.644L368.165 448.928C368.112 449.367 368.014 449.799 367.873 450.219C367.742 450.46 367.526 450.646 367.264 450.744C366.866 450.925 366.412 450.954 365.993 450.827C365.573 450.7 365.217 450.425 364.995 450.055C364.279 448.799 365.648 446.82 367.388 446.613C367.564 446.613 367.935 446.57 368.076 446.828C368.268 447.283 368.343 447.776 368.297 448.265"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2743"
            d="M326.052 447.524C325.875 448.583 324.427 449.418 324.41 449.426C323.527 449.874 322.926 449.607 322.644 449.246C322.512 449.051 322.439 448.824 322.432 448.592V448.316C322.432 447.929 322.538 447.542 322.564 447.154C322.591 446.767 322.414 446.389 322.564 446.001C322.776 445.364 323.562 445.218 324.012 445.192C324.306 445.204 324.595 445.276 324.859 445.402C325.124 445.528 325.358 445.707 325.549 445.926C325.739 446.145 325.88 446.4 325.964 446.676C326.047 446.951 326.071 447.24 326.034 447.524"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2744"
            d="M52.8246 260.218C52.5435 260.929 52.2039 261.617 51.8091 262.275C51.8091 262.318 51.8091 262.318 51.4117 262.429C51.1115 259.228 51.0762 258.213 51.0762 258.204C51.0762 258.196 51.7296 256.664 52.4272 256.965C53.1248 257.266 53.0983 259.306 52.8246 260.218Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2745"
            d="M431.353 311.025C429.313 314.269 429.313 314.269 429.013 314.364C428.554 313.792 428.138 313.188 427.768 312.557C427.59 312.174 427.512 311.754 427.538 311.335C427.567 310.786 427.76 310.258 428.093 309.815C428.425 309.372 428.883 309.033 429.41 308.839C429.668 308.804 429.932 308.841 430.168 308.948C430.405 309.055 430.605 309.226 430.744 309.442C431.247 310.01 431.689 310.5 431.353 311.025Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2746"
            d="M428.634 288.504C428.731 288.972 428.701 289.457 428.546 289.91C428.391 290.363 428.117 290.769 427.751 291.086C427.404 291.358 426.99 291.536 426.55 291.602C426.55 291.602 425.896 291.155 425.755 291.043C425.207 290.621 424.775 290.286 425.004 289.425C425.101 289.072 426.346 286.559 427.018 286.275C427.185 286.275 428.422 286.594 428.642 288.521"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2747"
            d="M521.148 205.823C521.234 206.32 521.364 206.81 521.537 207.286C521.752 207.869 521.909 208.471 522.005 209.084C521.484 208.964 519.85 205.083 520.009 204.368C520.212 203.869 521.113 205.694 521.148 205.814"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2748"
            d="M360.19 142.452C360.128 142.891 360.128 142.891 360.022 142.951C359.89 143.026 359.743 143.073 359.59 143.087C359.438 143.102 359.284 143.085 359.139 143.037C358.514 142.759 357.977 142.322 357.585 141.772C356.79 140.739 356.622 139.139 357.541 138.924C357.643 138.894 357.75 138.885 357.856 138.899C357.962 138.912 358.063 138.946 358.155 138.999C358.246 139.053 358.325 139.124 358.386 139.209C358.448 139.293 358.491 139.39 358.512 139.491C358.265 139.887 358.097 140.352 358.989 141.066C359.422 141.428 360.225 142.108 360.181 142.452"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2749"
            d="M140.996 218.937C139.345 220.598 138.797 221.157 138.744 220.4C138.691 219.643 139.265 219.023 139.186 218.266C139.186 218.051 139.08 217.862 138.515 218.266C138.294 218.429 138.153 218.67 137.905 218.799C137.905 218.799 137.782 218.748 137.711 218.679C137.64 218.61 137.393 218.378 137.711 217.5C137.863 216.959 138.223 216.495 138.716 216.205C139.209 215.915 139.798 215.821 140.36 215.942C140.515 215.965 140.66 216.029 140.778 216.128C140.897 216.227 140.985 216.356 141.031 216.502C141.189 217.204 141.28 217.918 141.305 218.636C141.217 218.713 141.111 218.825 141.031 218.911"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2750"
            d="M83.0254 149.707C82.3632 150.412 82.2219 150.386 82.1424 150.077C82.1424 149.93 82.0806 149.887 81.9835 149.93C81.7009 149.672 81.6214 149.595 82.5133 148.553C83.1844 147.753 84.2087 146.832 84.5531 146.884C84.5896 146.892 84.6231 146.91 84.6502 146.936C84.765 147.34 83.9879 148.657 82.9901 149.707"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2751"
            d="M165.782 95.8446C164.713 96.49 164.475 96.3265 164.298 96.206L164.254 96.1544C164.254 96.0081 164.157 95.8618 164.254 95.7155C164.351 95.5692 164.395 95.509 164.819 95.2336C164.819 95.2336 167.133 93.7621 167.627 93.9514C167.91 94.1923 166.691 95.2938 165.808 95.836"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2752"
            d="M102.142 202.329C101.453 203.508 99.8375 204.558 99.4931 203.491C99.4398 203.235 99.4398 202.972 99.4931 202.716C99.4292 201.93 99.6443 201.146 100.102 200.496C100.394 200.126 100.544 200.074 100.641 200.04C100.958 199.984 101.282 199.967 101.604 199.988L101.524 200.204L101.745 200.281L102.398 200.574C102.444 200.596 102.485 200.628 102.519 200.666C102.552 200.705 102.577 200.749 102.593 200.797C102.734 201.288 102.151 202.32 102.142 202.329Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2753"
            d="M313.408 189.085C313.611 191.245 311.792 191.882 311.068 191.348C310.913 191.223 310.807 191.05 310.767 190.858C310.767 190.858 310.582 189.05 310.582 188.964C310.595 188.268 310.648 187.573 310.741 186.882C312.684 187.467 313.266 187.639 313.39 189.085"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2754"
            d="M533.255 362.796C533.02 363.859 532.677 364.897 532.23 365.894C532.49 364.119 532.925 362.372 533.528 360.679C533.549 360.645 533.573 360.613 533.599 360.584C533.602 361.336 533.492 362.084 533.272 362.804"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2755"
            d="M209.95 119.225C209.067 120.413 207.496 121.342 207.381 120.706C207.389 120.409 207.502 120.123 207.699 119.897C207.813 119.746 207.937 119.602 208.07 119.466C208.326 119.217 208.617 119.002 208.873 118.761C209.044 118.64 209.179 118.478 209.267 118.291C209.355 118.104 209.393 117.899 209.377 117.694C209.553 117.496 209.968 117.04 210.162 117.074C210.825 117.272 210.463 118.494 209.924 119.251"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2756"
            d="M351.696 246.011C351.811 248.317 349.842 248.885 349.047 248.283C348.849 248.113 348.715 247.882 348.667 247.629C348.663 247.56 348.663 247.491 348.667 247.422L348.182 244.178C348.182 243.67 348.491 243.403 349.135 243.317H349.162C350.928 243.317 351.608 244.952 351.67 246.02"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2757"
            d="M536.521 353.234C536.317 354.318 536.04 355.387 535.691 356.436C535.841 355.131 536.137 353.847 536.574 352.606C536.617 352.488 536.673 352.376 536.742 352.271C536.713 352.599 536.654 352.925 536.566 353.243"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2758"
            d="M158.912 210.987C158.55 211.752 156.555 213.697 156.131 212.708C156.025 212.458 155.769 211.46 156.934 208.947C157.016 208.666 157.195 208.422 157.442 208.257C157.689 208.092 157.987 208.016 158.286 208.043C159.354 208.388 159.248 210.281 158.912 210.995"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2759"
            d="M265.785 363.587C265.8 363.729 265.784 363.872 265.739 364.008C265.694 364.144 265.621 364.269 265.524 364.375C265.427 364.482 265.308 364.568 265.175 364.628C265.042 364.688 264.898 364.72 264.752 364.723C263.85 364.797 262.943 364.797 262.041 364.723C261.767 364.721 261.504 364.617 261.308 364.43C260.328 363.415 261.564 360.627 263.401 360.653C264.106 360.763 264.743 361.128 265.184 361.675C265.626 362.221 265.841 362.91 265.785 363.604"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2760"
            d="M48.4709 257.86C48.0294 259.263 47.1552 260.192 46.7755 259.684C46.3958 259.177 46.8196 257.722 46.9521 257.24C47.4024 255.623 47.7203 254.461 48.206 254.512C48.2471 254.523 48.286 254.54 48.3208 254.564C48.8417 255.003 48.8241 256.724 48.4709 257.86Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2761"
            d="M223.012 77.3519C222.305 77.7649 220.557 78.5652 220.619 78.2124C220.619 78.2124 220.946 77.5498 222.588 76.672C222.775 76.5232 222.998 76.423 223.236 76.3809C223.474 76.3387 223.719 76.356 223.948 76.4311C223.904 76.7151 223.895 76.8011 223.012 77.2916"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2762"
            d="M151.566 155.334C150.568 156.659 149.103 157.374 148.917 156.608C148.838 156.203 148.864 156.169 150.065 154.405C150.904 153.131 151.363 152.425 152.07 152.606C152.635 152.813 152.546 153.957 151.522 155.325"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2763"
            d="M281.601 210.599C281.539 211.193 281.327 211.511 280.912 211.589C280.71 211.612 280.505 211.612 280.303 211.589C280.101 211.616 279.897 211.63 279.694 211.632C279.479 211.612 279.263 211.612 279.049 211.632C278.369 211.632 278.122 211.528 277.954 211.313C277.256 210.392 278.025 208.026 279.72 207.871C280.683 207.871 281.769 209.162 281.627 210.582"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2764"
            d="M65.9292 297.368C65.6819 298.547 64.8077 298.177 64.3309 297.979L64.1543 297.902C63.8629 297.721 63.4744 297.695 63.2713 297.359C63.0956 296.943 63.0347 296.489 63.0947 296.043C63.1285 295.579 63.2856 295.133 63.5503 294.746C63.8151 294.36 64.1784 294.048 64.6046 293.84C64.6943 293.816 64.7887 293.816 64.8784 293.84C65.6466 294.244 66.1058 296.421 65.9204 297.282"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2765"
            d="M377.647 260.098C377.797 261.931 376.357 263.592 375.289 262.835C374.653 262.387 374.512 261.492 374.344 260.459L374.291 260.115C374.153 259.537 374.085 258.945 374.088 258.351C374.127 258.202 374.216 258.069 374.341 257.974C374.465 257.879 374.619 257.827 374.777 257.826C374.777 257.826 377.479 257.938 377.647 260.098Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2766"
            d="M536.654 298.082C536.716 300.122 536.716 300.122 536.654 300.277C536.654 300.612 536.46 301.026 536.257 300.819C535.745 300.303 535.533 296.344 536.107 295.441C536.407 295.114 536.451 295.759 536.601 297.876C536.606 297.945 536.606 298.014 536.601 298.082"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2767"
            d="M391.962 416.45C391.724 417.715 391.583 418.404 390.655 419.118C389.728 419.832 389.507 419.978 388.775 418.885C387.291 416.786 390.055 415.03 391.035 414.884C391.865 414.815 392.174 415.34 391.962 416.45Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2768"
            d="M186.967 100.578C186.287 101.111 184.38 102.213 184.468 101.542C184.772 100.86 185.253 100.267 185.863 99.8206C186.79 99.1408 187.143 99.2699 187.426 99.3732H187.514C187.603 99.5108 187.965 99.4506 187.876 99.6399C187.634 99.9954 187.336 100.312 186.993 100.578"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2769"
            d="M163.187 142.787C162.171 144.113 160.538 144.689 160.379 143.992C160.512 143.086 160.89 142.231 161.474 141.514C161.652 141.316 161.887 141.176 162.149 141.111C162.411 141.046 162.687 141.061 162.94 141.152C163.046 141.152 163.125 141.256 163.231 141.281C163.339 141.293 163.445 141.313 163.549 141.342H163.628C163.668 141.358 163.703 141.384 163.729 141.417C163.756 141.45 163.773 141.489 163.779 141.531C163.84 141.901 163.196 142.753 163.187 142.762"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2770"
            d="M374.123 221.046C374.689 223.765 372.799 224.161 372.481 223.955C372.275 223.753 372.107 223.517 371.986 223.257C371.916 223.111 371.916 223.111 372.057 222.853C372.191 222.643 372.244 222.394 372.207 222.15C372.17 221.905 372.045 221.682 371.854 221.519C371.651 221.33 371.404 221.184 371.209 220.977C371.033 220.79 370.893 220.575 370.794 220.34C370.752 220.25 370.722 220.154 370.706 220.056C370.653 219.832 370.609 218.877 371.324 218.714H371.43C373.09 218.662 374.079 220.865 374.079 221.072"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2771"
            d="M129.286 176.125C128.818 176.848 127.573 178.062 126.726 177.915C125.719 177.442 126.62 174.766 127.609 174.473C127.995 174.387 128.399 174.408 128.774 174.533C130.046 174.998 129.746 175.48 129.313 176.143"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2772"
            d="M204.786 131.79C204.459 132.254 204.159 132.513 203.903 132.538C203.744 132.547 203.585 132.526 203.435 132.478C203.311 132.478 203.187 132.478 203.064 132.478L202.993 132.435C202.746 132.315 202.296 132.435 202.251 132.065C202.163 131.394 203.806 129.483 204.901 129.483C205.678 129.707 205.369 131.007 204.812 131.807"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2773"
            d="M336.322 281.077C336.275 281.55 336.129 282.008 335.892 282.422C335.655 282.837 335.332 283.2 334.945 283.487C334.568 283.672 334.143 283.742 333.724 283.69C333.306 283.638 332.913 283.465 332.596 283.194C331.51 282.222 331.015 279.855 332.428 279.692C332.728 279.692 333.011 279.692 333.311 279.692C333.611 279.692 333.735 279.632 333.947 279.623C334.335 279.623 334.733 279.623 335.121 279.623H335.289C335.397 279.627 335.504 279.644 335.607 279.675C336.304 279.924 336.322 281.077 336.322 281.086"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2774"
            d="M361.524 132.555C362.168 133.7 362.142 134.879 361.188 134.629C359.775 134.259 358.142 132.495 358.963 131.927C359.051 131.858 359.051 131.858 359.678 131.764H359.846C360.188 131.666 360.556 131.7 360.872 131.861C361.188 132.021 361.428 132.295 361.541 132.624"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2775"
            d="M146.868 474.597C147.398 475.811 147.61 476.37 146.524 475.992C144.696 475.355 143.239 472.782 144.44 472.326C144.631 472.274 144.832 472.274 145.023 472.326C145.818 472.498 146.056 472.799 146.868 474.623"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2776"
            d="M429.278 90.122C430.09 90.5843 430.843 91.1389 431.521 91.7742C430.726 91.4761 429.98 91.0674 429.305 90.5609C429.146 90.4576 428.74 90.1995 428.554 90.0446C428.369 89.8897 428.342 89.7692 428.201 89.6573C428.201 89.5885 428.113 89.554 428.104 89.4852C428.095 89.4163 428.219 89.4852 429.261 90.1306"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2777"
            d="M413.623 239.22C413.923 240.855 412.74 242.275 411.857 241.742C411.542 241.541 411.312 241.235 411.212 240.881L410.329 237.671C410.305 237.574 410.302 237.473 410.321 237.375C410.34 237.276 410.381 237.183 410.441 237.101C410.5 237.02 410.577 236.951 410.666 236.901C410.755 236.851 410.854 236.82 410.956 236.811C412.237 236.587 413.402 237.921 413.605 239.255"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2778"
            d="M86.2912 143.321L86.0175 143.476C86.0994 143.277 86.2159 143.094 86.3618 142.934C86.5571 142.717 86.7729 142.518 87.0065 142.34C86.8002 142.699 86.5608 143.039 86.2912 143.356M86.459 144.053C86.9005 143.571 87.9866 142.159 87.7482 141.85C87.5363 141.695 87.4215 141.85 87.0594 142.28C86.3353 142.013 84.534 144.715 84.6223 145.576C85.2728 145.131 85.8658 144.611 86.3883 144.027"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2779"
            d="M234.676 76.5086C233.502 77.2401 232.027 78.1523 232.195 77.6876C232.555 77.144 233.034 76.6854 233.599 76.3451C234.411 75.8116 234.588 75.8288 234.976 75.8718C235.206 75.9751 235.206 75.9751 235.171 76.0525C235.048 76.2432 234.878 76.4002 234.676 76.5086Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2780"
            d="M259.605 111.876C259.216 112.427 258.404 113.598 258.316 113.598C258.227 113.598 257.927 113.787 257.759 112.883C257.521 111.653 258.731 110.121 259.34 110.069C259.525 110.069 259.64 110.233 259.675 110.474C259.711 110.715 259.675 111.713 259.675 111.722C259.675 111.73 259.614 111.808 259.587 111.842"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2781"
            d="M93.5329 348.02C93.4357 349.922 92.2083 351.126 90.9809 349.741C90.676 349.455 90.4535 349.097 90.3357 348.701C90.2179 348.305 90.209 347.886 90.3098 347.486C90.8096 346.575 91.5389 345.802 92.4291 345.24C92.8618 345.873 93.2314 346.544 93.5329 347.245C93.5815 347.493 93.5815 347.747 93.5329 347.994"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2782"
            d="M175.866 437.491C175.76 437.844 175.645 438.205 173.897 437.99C172.714 437.852 172.184 437.594 171.795 436.992C171.592 436.665 172.131 435.271 172.254 435.133C172.378 434.995 172.599 434.651 173.438 434.737C173.688 434.755 173.932 434.825 174.153 434.942C174.374 435.06 174.567 435.221 174.718 435.417C174.868 435.581 175.963 437.138 175.866 437.491Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2783"
            d="M196.175 154.139C195.857 154.767 194.506 156.548 194.18 155.688C193.932 155.059 194.259 151.497 195.822 151.678C196.705 151.832 196.705 153.072 196.175 154.139Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2784"
            d="M247.499 159.371C247.198 160.369 246.43 160.318 245.618 160.231H245.406C245.277 160.219 245.147 160.219 245.017 160.231C244.682 160.231 244.187 160.171 244.24 159.491C244.289 158.696 244.657 157.951 245.265 157.417C245.396 157.273 245.558 157.159 245.74 157.081C245.921 157.004 246.117 156.966 246.315 156.97C247.499 157.108 247.666 158.915 247.525 159.38"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2785"
            d="M131.433 100.741C130.718 101.206 129.57 101.817 129.42 101.653C129.482 101.18 132.405 98.9599 132.652 99.2008C132.705 99.2525 132.652 99.3902 132.784 99.3902C132.784 99.4504 132.731 99.4934 132.784 99.5451C132.837 99.5967 132.855 99.6655 132.687 99.8376C132.3 100.17 131.887 100.472 131.451 100.741"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2786"
            d="M273.672 189.946C273.407 192.226 271.535 192.089 271.402 191.882C271.309 191.695 271.263 191.488 271.27 191.28C271.27 191.159 271.27 191.159 271.429 191.022C271.591 190.919 271.714 190.768 271.78 190.591C271.845 190.413 271.849 190.22 271.791 190.041C271.658 189.731 271.314 189.559 271.19 189.249C271.074 188.911 271.031 188.554 271.067 188.199C271.12 187.665 271.12 187.666 271.888 187.184C273.522 187.459 273.778 189.051 273.654 189.946"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2787"
            d="M299.482 164.99C299.457 165.377 299.336 165.752 299.129 166.083C298.802 166.651 298.334 167.133 297.954 167.667C296.409 166.238 296.25 166.083 296.25 165.558C296.25 165.231 296.656 165.162 296.868 165.119C297.027 165.092 297.176 165.027 297.303 164.929C297.429 164.831 297.529 164.705 297.592 164.56C297.707 164.216 297.75 163.853 297.716 163.493C297.689 163.288 297.719 163.08 297.804 162.891C297.849 162.838 297.912 162.804 297.981 162.796C298.401 163.008 298.76 163.32 299.023 163.704C299.285 164.088 299.443 164.53 299.482 164.99Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2788"
            d="M356.896 102.945C357.25 103.366 357.779 104.313 357.188 104.261C356.596 104.21 353.726 102.411 354.45 101.878C354.527 101.831 354.61 101.796 354.698 101.774C355.121 101.774 355.121 101.774 355.183 101.826C356.375 102.463 356.561 102.566 356.887 102.945"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2789"
            d="M334.908 87.1878C335.959 88.2118 335.791 88.6077 335.5 88.6163C335.337 88.5606 335.18 88.4885 335.032 88.4012C334.865 88.1557 334.715 87.8999 334.582 87.6353C334.342 87.3344 334.007 87.1195 333.628 87.0243C333.345 86.9382 332.86 86.7231 332.86 86.7231C332.654 86.4055 332.483 86.0681 332.348 85.7163H332.48C333.054 85.6646 334.14 86.4563 334.908 87.205"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2790"
            d="M173.517 88.5213C172.431 89.1323 170.868 89.8293 170.974 89.3818C171.08 88.9344 173.164 87.7296 173.844 87.3682C173.526 87.6866 173.279 87.9275 173.57 87.9275C173.614 87.9275 173.614 87.9275 173.897 87.7984C174.135 87.6597 174.405 87.5827 174.683 87.5747C174.683 87.5747 174.815 87.807 173.517 88.5299"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2791"
            d="M178.948 141.222C178.135 142.323 176.987 141.979 176.926 141.334C176.811 140.249 176.811 140.249 177.164 139.845C177.164 139.845 178.312 138.649 179.186 138.718C179.787 138.89 179.469 140.525 178.948 141.222Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2792"
            d="M182.745 108.34C181.235 109.682 179.946 110.061 179.981 109.459C180.043 108.409 182.401 107.041 183.169 107.221C183.451 107.325 183.487 107.677 182.745 108.34Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2793"
            d="M515.074 321.61C515.074 321.61 514.456 324.691 513.546 323.537C513.255 323.176 512.204 320.715 513.811 319.708C514.474 319.906 514.474 319.906 514.588 320.078C514.894 320.534 515.062 321.065 515.074 321.61Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2794"
            d="M410.974 94.2868C411.857 95.2248 411.927 95.3022 411.68 95.285C411.433 95.2678 407.963 93.6758 408.298 93.2025C408.554 92.9099 410.78 94.0631 410.947 94.2868"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2795"
            d="M438.418 297.446C438.037 298.846 437.361 300.153 436.432 301.284C435.178 300.363 434.666 300.019 434.719 298.745C434.772 297.472 435.266 296.267 436.485 295.957C436.714 295.923 436.948 295.936 437.173 295.993C437.397 296.051 437.607 296.152 437.79 296.292C437.972 296.431 438.124 296.605 438.235 296.804C438.346 297.002 438.415 297.221 438.436 297.446"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2796"
            d="M462.242 292.764C462.321 293.082 462.274 293.417 462.11 293.702C461.947 293.988 461.678 294.203 461.359 294.304C460.379 294.425 460.044 294.459 459.514 293.65C458.719 292.437 458.631 291.636 459.178 290.974C459.371 290.724 459.64 290.54 459.946 290.449C461.262 290.182 462.154 291.645 462.269 292.764"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2797"
            d="M533.176 331.885C533.123 332.564 532.743 334.174 532.46 333.924C532.149 333.32 532.012 332.645 532.063 331.971C532.13 330.927 532.326 329.896 532.646 328.898C532.646 328.898 532.752 328.959 532.761 329.028C532.805 329.389 532.761 329.75 532.761 330.103C532.831 331.067 533.008 331.041 533.167 330.964C533.167 331.119 533.167 331.644 533.167 331.91"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2798"
            d="M530.465 332.4C530.2 333.815 529.825 335.207 529.343 336.566C528.957 335.859 528.826 335.047 528.972 334.259C529.066 333.223 529.366 332.215 529.855 331.29C529.97 331.222 530.562 331.402 530.482 332.4"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2799"
            d="M264.16 81.3365C264.16 81.4139 263.144 82.6015 262.959 81.9991C262.894 81.5661 262.978 81.1243 263.197 80.7427C263.348 80.4243 263.348 80.4243 263.895 79.9424C264.001 80.0112 264.125 80.0457 264.222 80.1231C264.279 80.1681 264.317 80.2327 264.328 80.3038C264.338 80.6552 264.282 81.0055 264.16 81.3365Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2800"
            d="M162.568 508.589C162.647 508.718 162.656 508.804 162.568 508.847H162.515C162.303 508.933 162.082 508.795 161.861 508.847C161.861 508.847 161.782 508.847 161.764 508.993C161.746 509.14 161.764 509.234 161.552 509.2C160.837 509.079 158.85 507.229 159.247 507.04C159.399 507.031 159.55 507.057 159.689 507.117C160.007 507.221 160.113 507.238 160.192 507.203C160.616 507.393 161.075 507.367 161.534 507.599C161.923 507.853 162.261 508.174 162.532 508.546"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2801"
            d="M528.92 372.494C528.678 373.486 528.279 374.436 527.737 375.308C527.551 375.213 527.737 374.318 527.798 374.043C527.881 373.745 527.996 373.456 528.143 373.182C528.668 371.961 529.102 370.704 529.441 369.422C529.363 370.459 529.188 371.487 528.92 372.494Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2802"
            d="M379.44 119.665C380.464 120.99 380.173 121.55 379.652 121.507C378.904 121.3 378.235 120.884 377.727 120.31C377.536 120.074 377.432 119.781 377.432 119.48C377.432 119.179 377.536 118.886 377.727 118.65C377.782 118.614 377.841 118.585 377.903 118.564C378.071 118.564 378.513 118.486 379.431 119.674"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2803"
            d="M507.982 330.361C507.982 331.514 507.346 333.287 506.905 332.831C506.463 332.375 505.448 328.58 506.64 327.917H506.702C507.982 327.444 507.991 329.888 507.982 330.387"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2804"
            d="M176.016 105.612C173.491 107.333 173.208 107.333 173.279 107.032C173.517 105.974 176.44 103.822 177.349 104.037C177.641 104.184 176.361 105.371 176.016 105.612Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2805"
            d="M268.018 237.396C267.86 239.737 265.776 240.649 264.292 239.031C264.165 238.931 264.062 238.805 263.992 238.661C263.921 238.518 263.885 238.36 263.885 238.201C263.885 238.042 263.921 237.884 263.992 237.741C264.062 237.597 264.165 237.471 264.292 237.371C264.672 236.911 265.136 236.523 265.661 236.226C265.995 236.022 266.392 235.94 266.782 235.994C267.365 235.994 268.089 235.994 268.001 237.396"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2806"
            d="M486.657 332.461C486.596 333.141 485.73 336.179 484.891 335.095C483.876 333.838 484.538 330.431 485.889 329.931C486.896 329.656 486.702 332.41 486.693 332.436"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2807"
            d="M487.311 149.13C487.761 149.689 487.788 149.879 487.77 149.991C487.567 150.584 484.953 147.289 484.52 146.394C484.511 146.348 484.511 146.301 484.52 146.256C485.576 147.099 486.522 148.064 487.337 149.13"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2808"
            d="M47.9506 251.045C47.4472 252.37 46.679 251.355 46.5819 251.097C46.431 250.358 46.431 249.598 46.5819 248.859C46.5819 248.859 47.3148 247.138 48.0036 247.465C48.5245 247.887 48.2596 250.322 48.0036 251.045"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2809"
            d="M300.232 94.3564C300.365 94.8642 300.117 95.8022 299.349 95.3891C298.581 94.976 297.927 93.2463 298.413 93.0742H298.501C298.883 93.1178 299.244 93.2618 299.548 93.4908C299.851 93.7197 300.085 94.025 300.223 94.3737"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2810"
            d="M529.81 273.212C530.111 275.002 529.81 276.258 529.51 276.327C529.2 275.838 528.965 275.308 528.813 274.752C528.813 274.752 528.812 271.13 528.901 271.018C529.175 270.854 529.625 272.085 529.784 273.212"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2811"
            d="M374.715 442.947C374.034 443.945 372.979 444.643 371.775 444.891C370.989 444.969 370.512 445.012 370.362 444.513C370.328 444.15 370.376 443.785 370.501 443.441C370.626 443.098 370.825 442.786 371.086 442.525C371.568 441.956 372.252 441.584 373.002 441.484C374.265 441.415 375.051 442.224 374.768 442.912"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2812"
            d="M179.557 92.1273C179.054 92.4457 177.287 93.4526 177.323 93.0653C177.829 92.4025 178.461 91.8414 179.186 91.4131C179.955 90.8567 180.811 90.4238 181.72 90.1309C180.228 91.6971 180.228 91.6971 179.557 92.1273Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2813"
            d="M511.029 304.467C511.049 305.538 510.771 306.593 510.225 307.522C509.342 305.388 509.342 305.388 509.342 304.657C509.296 304.177 509.35 303.693 509.502 303.234C509.654 302.776 509.9 302.352 510.225 301.989C510.879 301.757 511.029 304.441 511.029 304.467Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2814"
            d="M98.2842 291.164C98.2842 291.164 96.7389 294.089 95.6351 291.843C94.9022 290.372 94.602 287.962 96.58 288.401C96.9173 288.526 97.2197 288.727 97.463 288.986C97.5034 289.038 97.5389 289.092 97.569 289.15C97.6484 289.288 97.6573 289.442 97.7279 289.589C97.7985 289.735 97.9751 289.959 98.0988 290.148C98.2002 290.292 98.2657 290.458 98.2902 290.631C98.3148 290.804 98.2976 290.981 98.2401 291.146"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2815"
            d="M77.1881 313.554C77.0468 314.639 76.3051 316.196 75.7135 315.508C75.1601 314.496 74.8919 313.358 74.9364 312.212C74.9364 310.852 75.0335 310.078 75.6428 310.13C75.7162 310.122 75.7902 310.122 75.8636 310.13C76.9144 310.517 77.3647 312.152 77.1881 313.511"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2816"
            d="M515.276 332.969C515.22 333.115 515.146 333.254 515.056 333.382C514.852 333.701 514.57 333.95 514.358 334.243C514.146 334.535 514.093 334.923 513.89 335.232C513.837 335.31 513.828 335.301 513.766 335.301C513.705 335.301 513.342 330.362 514.393 329.716C515.435 329.346 515.276 332.918 515.276 332.952"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2817"
            d="M404.209 240.021C404.209 240.064 404.359 244.168 402.752 242.964C401.145 241.759 400.73 238.661 402.752 238.162C403.185 238.162 403.847 238.067 404.183 240.029"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2818"
            d="M361.524 123.459C361.727 123.804 361.833 124.251 361.242 124.415H361.074C360.332 124.595 359.255 123.554 358.937 123.244C358.751 123.055 358.186 122.478 358.566 121.85C358.599 121.788 358.645 121.734 358.702 121.691C358.758 121.648 358.823 121.617 358.893 121.601C359.975 121.873 360.917 122.522 361.542 123.425"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2819"
            d="M125.755 333.244C125.57 334.251 124.272 335.671 123.212 334.208C122.524 333.332 122.157 332.256 122.17 331.153C122.303 330.405 122.479 330.422 123.866 330.559C124.123 330.632 124.371 330.73 124.607 330.852L125.402 331.876C125.553 332.069 125.661 332.29 125.722 332.525C125.783 332.76 125.794 333.005 125.755 333.244Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2820"
            d="M296.178 170.084C296.189 170.586 296.002 171.072 295.657 171.444C295.481 171.616 295.481 171.616 294.147 172.072C294.095 171.831 294.024 171.599 293.98 171.358C293.98 171.263 293.98 171.263 294.147 171.126C294.265 171.048 294.361 170.945 294.429 170.823C294.497 170.702 294.533 170.567 294.536 170.429C294.492 170.188 294.395 169.99 293.944 169.568C293.75 169.379 293.644 169.275 293.547 168.707C293.534 168.59 293.534 168.472 293.547 168.355C293.547 168.096 293.547 168.096 294.483 167.262C296.037 168.931 296.205 169.103 296.187 170.11"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2821"
            d="M529.22 317.453C529.22 319.131 528.708 321.222 528.072 320.525C527.639 320.052 527.648 319.303 527.657 317.668C528.001 317.806 528.001 317.806 528.028 317.797C528.134 317.654 528.22 317.498 528.284 317.332C528.458 316.877 528.665 316.434 528.902 316.007C529.184 315.869 529.229 316.713 529.22 317.453Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2822"
            d="M83.1567 327.633C83.0684 328.253 83.0684 328.253 82.2737 328.494C81.0639 327.926 80.852 327.822 80.6136 326.936C80.1986 325.387 80.022 324.423 81.0816 324.079C81.2911 324.013 81.5167 324.013 81.7262 324.079C82.8653 324.509 83.3686 326.239 83.1744 327.599"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2823"
            d="M342.626 257.137C342.626 257.998 342.556 258.91 342.512 259.796C341.807 259.562 341.122 259.274 340.463 258.936C340.017 258.684 339.624 258.351 339.306 257.955C339.227 257.74 340.03 255.253 340.701 254.994C340.822 254.955 340.946 254.929 341.072 254.917C341.814 254.917 341.955 255.132 342.123 255.33C342.411 255.894 342.582 256.508 342.626 257.137Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2824"
            d="M525.078 342.908C525.043 343.508 524.801 344.079 524.389 344.526C523.903 344.801 523.85 344.836 523.682 344.423C523.405 343.793 523.349 343.091 523.524 342.426C523.681 341.795 523.983 341.206 524.407 340.705C524.866 340.412 525.219 341.824 525.086 342.925"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2825"
            d="M130.169 340.016C127.997 341.531 127.997 341.531 127.458 341.23C126.945 340.798 126.547 340.252 126.297 339.637C126.048 339.023 125.956 338.359 126.028 337.702C126.195 336.841 126.964 335.903 127.467 335.937C127.776 336.049 127.9 336.333 128.014 337.159C128.041 337.618 128.2 338.059 128.474 338.433C128.529 338.494 128.591 338.549 128.659 338.597C128.924 338.812 129.286 338.846 129.542 339.053C129.827 339.319 130.09 339.606 130.328 339.913L130.16 340.025"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2826"
            d="M264.628 83.6591C264.311 84.3507 263.872 84.9827 263.33 85.5264C263.259 85.3802 263.18 85.2339 263.109 85.079C263.382 84.7751 263.633 84.453 263.86 84.1152C263.86 84.0205 263.86 84.0205 263.683 83.7537C263.619 83.6093 263.569 83.4595 263.533 83.3062C264.098 82.5748 264.249 82.3768 264.593 82.3682C264.937 82.3596 264.875 83.0825 264.593 83.6591"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2827"
            d="M93.7627 336.557C93.6567 337.366 93.0739 338.545 92.4381 338.468C91.405 337.96 90.7251 333.304 91.6346 333.012C91.7623 332.982 91.8954 332.982 92.0231 333.012C93.1622 333.356 93.9305 335.241 93.7892 336.549"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2828"
            d="M407.141 217.681C407.697 219.677 406.258 220.572 405.79 220.357C405.583 220.138 405.389 219.908 405.207 219.669C405.737 218.808 405.481 218.438 404.677 217.655C403.326 216.338 403.794 215.607 404.377 215.452C405.525 215.263 406.735 216.244 407.141 217.681Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2829"
            d="M322.043 433.369C322.043 433.369 320.674 436.915 319.182 434.669C318.653 433.836 318.426 432.854 318.538 431.88C318.577 431.627 318.714 431.396 318.92 431.237C319.127 431.077 319.387 431 319.65 431.02H319.694C320.016 430.991 320.341 431.032 320.645 431.141C320.948 431.25 321.223 431.424 321.448 431.65C321.674 431.877 321.844 432.149 321.947 432.448C322.05 432.747 322.083 433.065 322.043 433.378"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2830"
            d="M422.204 97.3336C423.423 98.5039 423.467 98.5469 423.246 98.5555C423.026 98.5641 419.714 96.9549 419.864 96.3525C420.71 96.5043 421.51 96.8396 422.204 97.3336Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2831"
            d="M182.455 428.265C182.375 428.613 182.271 428.955 182.146 429.289C181.881 429.745 181.564 430.169 181.201 430.554C180.715 430.709 179.832 429.694 179.514 429.195C178.543 427.543 179.585 425.976 181.06 426.019C181.24 426.04 181.411 426.108 181.556 426.215C181.7 426.323 181.813 426.466 181.881 426.63C181.969 426.768 182.508 428.059 182.517 428.076C182.525 428.093 182.517 428.214 182.455 428.265Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2832"
            d="M108.704 299.64C108.474 300.285 106.779 302.402 105.649 300.302C104.986 299.072 104.96 296.671 106.532 296.86C106.941 296.986 107.305 297.222 107.582 297.54C108.032 298.135 108.439 298.759 108.801 299.407C108.786 299.475 108.765 299.541 108.739 299.605"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2833"
            d="M195.823 98.504C195.505 98.7622 195.505 98.7622 194.569 99.0978C194.474 99.0221 194.382 98.9416 194.295 98.8569C194.286 98.8412 194.281 98.8234 194.281 98.8052C194.281 98.7871 194.286 98.7693 194.295 98.7536C194.497 98.3333 194.762 97.9446 195.081 97.6005C195.636 97.1097 196.297 96.7478 197.015 96.542H197.077C197.421 96.6625 197.077 97.4886 195.85 98.4954"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2834"
            d="M372.235 135.533C373.047 136.987 372.359 137.684 371.511 137.435C370.879 137.164 370.324 136.748 369.895 136.221C369.503 135.807 369.291 135.261 369.303 134.698C370.186 135.619 370.513 135.731 370.548 135.154C370.601 134.38 370.601 134.38 370.937 134.294C371.458 134.234 372.023 135.154 372.235 135.516"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2835"
            d="M421.967 95.2853C422.77 95.8016 424.104 96.7138 423.936 97.0839C422.912 96.7569 420.51 95.3628 420.571 95.053C420.624 94.9583 420.722 95.053 421.022 95.1562C421.631 95.4488 421.684 95.423 421.711 95.4058C421.737 95.3886 421.569 95.2165 421.154 94.8809C421.438 94.99 421.71 95.1255 421.967 95.2853Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2836"
            d="M147.423 148.39C146.646 149.354 144.774 151.024 144.58 149.888C144.546 149.263 144.754 148.649 145.163 148.167C145.584 147.817 146.054 147.528 146.558 147.306C146.628 147.306 146.628 147.306 147.556 147.229C147.617 147.444 147.794 147.616 147.767 147.857C147.741 148.098 147.767 148.063 147.423 148.468"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2837"
            d="M170.064 133.425C169.075 134.457 167.247 134.802 167.221 134.147C167.194 133.493 169.075 131.445 170.259 131.643C170.771 131.798 170.576 132.891 170.064 133.425Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2838"
            d="M530.968 353.105C530.836 353.82 530.5 355.498 530.156 355.687C529.97 355.601 530.103 355.343 529.917 355.248C529.732 355.154 529.758 355.3 529.688 355.248C529.663 355.132 529.663 355.012 529.688 354.895C529.709 354.109 529.937 353.342 530.35 352.667L530.394 352.624V352.581L530.447 352.529L530.509 352.469L530.58 352.408L530.659 352.322L530.756 352.236C531.003 352.391 531.065 352.434 530.924 353.226"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2839"
            d="M521.395 293.418C521.507 294.012 521.507 294.622 521.395 295.216C521.298 295.509 521.024 295.827 520.662 295.535C519.585 294.674 519.673 291.077 520.194 290.552C520.495 290.406 521.21 292.041 521.36 293.418"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2840"
            d="M528.151 355.808C527.895 357.288 527.162 359.25 526.783 358.803C526.059 357.942 527.48 355.18 527.842 354.793C528.187 354.543 528.381 354.405 528.143 355.808"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2841"
            d="M267.233 258.867C267.136 259.487 265.696 260.657 264.46 260.718C263.577 260.614 263.091 260.554 263.021 259.857C262.979 259.374 263.056 258.888 263.245 258.439C263.434 257.99 263.729 257.591 264.107 257.275C264.429 256.985 264.851 256.823 265.29 256.819C265.802 256.929 266.266 257.191 266.618 257.569C266.971 257.946 267.195 258.421 267.259 258.928"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2842"
            d="M51.8982 254.229C51.6391 254.646 51.3436 255.04 51.0152 255.408C50.9445 255.46 50.7238 255.262 50.6973 255.046C50.7551 253.692 50.8937 252.341 51.1123 251.002C51.1123 250.881 51.4302 249.857 51.9953 250.141C53.002 251.002 52.0748 254.048 51.9335 254.246"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2843"
            d="M306.599 100.724C306.846 101.585 306.599 102.282 306.378 102.213C305.798 101.488 305.275 100.719 304.815 99.9155C304.489 99.1582 304.815 98.9431 304.921 98.8829C304.978 98.8604 305.037 98.846 305.098 98.8398C305.813 99.2736 306.345 99.9419 306.599 100.724Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2844"
            d="M180.572 98.7277C180.572 98.7277 178.418 100.122 178.621 99.2785C178.718 98.8568 180.511 96.7915 181.579 97.1701C181.985 97.3422 180.952 98.4782 180.572 98.7277Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2845"
            d="M144.977 215.547C144.58 216.502 143.467 216.64 142.999 215.487C142.248 213.671 144.015 211.322 145.03 211.296C145.215 211.632 145.215 211.632 145.215 211.649C145.195 211.744 145.162 211.837 145.118 211.924C145.015 212.2 144.958 212.491 144.951 212.785C144.951 213.464 145.145 214.119 145.136 214.798C145.134 215.064 145.08 215.327 144.977 215.573"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2846"
            d="M119.961 306.55C119.688 307.081 119.244 307.51 118.697 307.771C118.15 308.032 117.53 308.111 116.932 307.995C116.208 306.928 115.97 306.575 116.367 305.543C116.367 305.482 116.994 303.985 118.407 304.183C119.06 304.415 120.129 305.904 119.961 306.55Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2847"
            d="M541.714 321.696C541.608 322.866 541.467 324.114 541.405 324.278C541.335 324.054 541.635 320.835 541.714 320.345C541.711 320.316 541.711 320.287 541.714 320.259C541.75 320.723 541.75 321.189 541.714 321.653"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2848"
            d="M406.62 95.9994C408.88 97.5398 409.56 98.0131 409.048 98.0389C408.351 98.0906 405.357 96.2232 405.604 95.8962C405.984 95.595 405.993 95.595 406.602 96.008"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2849"
            d="M350.362 275.14C350.25 275.585 350.025 275.996 349.709 276.336C349.393 276.675 348.996 276.933 348.552 277.085C348.173 277.098 347.795 277.022 347.452 276.865C347.108 276.708 346.807 276.474 346.574 276.182C346.574 276.182 344.446 273.385 347.36 273.127C348.019 273.091 348.671 273.269 349.214 273.633C349.758 273.998 350.162 274.528 350.362 275.14Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2850"
            d="M418.037 278.927C418.09 279.486 417.931 279.847 417.543 280.002C417.543 280.002 415.997 280.467 415.406 280.002C414.814 279.538 414.523 277.266 414.523 277.249C414.509 277.125 414.522 277.001 414.56 276.882C414.597 276.764 414.659 276.654 414.742 276.56C414.825 276.466 414.926 276.389 415.04 276.334C415.154 276.28 415.279 276.248 415.406 276.242C416.986 276.035 417.905 277.739 418.055 278.952"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2851"
            d="M153.305 117.926C151.989 119.105 150.479 119.923 150.514 119.2C150.514 119.011 150.514 118.839 151.53 118.055L151.865 117.806C152.342 117.488 152.881 117.255 153.331 116.894C154.408 116.033 154.408 116.033 154.523 116.033C154.638 116.033 154.435 116.894 153.305 117.918"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2852"
            d="M314.776 272.3C314.644 273.238 312.825 273.574 311.827 272.3C311.553 271.994 311.349 271.634 311.229 271.245C311.109 270.856 311.076 270.446 311.132 270.043C311.188 269.641 311.332 269.254 311.554 268.91C311.776 268.566 312.07 268.273 312.418 268.049C312.606 268.048 312.789 268.101 312.945 268.2C313.102 268.3 313.226 268.441 313.302 268.608C313.902 269.71 314.909 271.646 314.811 272.317"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2853"
            d="M159.699 121.102C159.116 121.601 157.279 122.823 157.297 122.109C157.315 121.395 158.992 119.209 159.849 119.424C159.94 119.474 160.015 119.548 160.063 119.638C160.112 119.728 160.133 119.83 160.123 119.932C160.061 120.275 159.979 120.614 159.875 120.947C159.875 121.016 159.752 121.059 159.699 121.102Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2854"
            d="M313.142 380.987C312.926 381.853 312.392 382.612 311.64 383.121C311.199 383.268 310.44 382.579 310.042 382.037C309.811 381.783 309.655 381.473 309.593 381.139C309.531 380.805 309.564 380.461 309.689 380.144C309.848 379.567 309.848 379.567 311.172 378.001C311.503 378.037 311.819 378.153 312.09 378.34C312.362 378.526 312.581 378.777 312.727 379.068C313.081 379.645 313.227 380.32 313.142 380.987Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2855"
            d="M338.564 252.534C337.91 254.143 337.637 254.805 337.169 254.788C337.018 254.788 336.851 254.788 336.63 254.435C336.542 254.306 335.57 252.465 335.561 252.456C335.262 251.703 335.061 250.917 334.961 250.115C335.42 250.064 335.844 249.935 336.33 249.883C336.521 249.86 336.715 249.886 336.893 249.959C337.071 250.031 337.227 250.147 337.345 250.296C337.85 250.895 338.302 251.534 338.696 252.207L338.564 252.534Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2856"
            d="M192.635 92.8501C192.369 93.0664 192.072 93.2432 191.752 93.375C191.532 93.4181 191.046 93.375 191.055 93.2029C191.064 93.0308 191.055 92.8931 193.024 91.5851C193.616 91.1892 193.907 91.0602 194.154 91.1376C194.534 91.3183 193.642 92.1531 192.662 92.8587"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2857"
            d="M127.521 151.101C126.551 152.017 125.515 152.865 124.422 153.639C124.435 152.727 124.819 151.857 125.49 151.221C126.214 150.404 127.256 149.603 127.919 149.776C128.431 150.059 127.698 150.92 127.548 151.092"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2858"
            d="M416.599 250.606C416.599 250.606 416.148 252.396 415.565 252.714C415.216 252.783 414.855 252.779 414.507 252.704C414.158 252.628 413.83 252.482 413.543 252.275C413.288 251.976 413.055 251.66 412.846 251.329C413.455 250.589 414.011 249.806 414.665 249.109C414.82 248.904 415.04 248.756 415.292 248.687C416.29 248.592 416.599 250.589 416.599 250.606Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2859"
            d="M157.085 505.525C157.023 505.835 157.023 505.835 156.891 505.827C156.545 505.777 156.214 505.657 155.919 505.474C155.151 505.068 154.482 504.504 153.959 503.821C153.544 503.193 153.597 502.772 153.915 502.832C154.136 502.884 154.136 502.883 157.076 505.474V505.517"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2860"
            d="M238.958 139.552C238.129 140.231 237.243 140.841 236.309 141.377C236.15 140.258 236.15 140.258 236.15 140.232C236.218 139.886 236.353 139.556 236.548 139.26C236.61 139.139 237.245 138.098 238.217 138.106C238.808 138.184 238.844 138.356 239.047 139.466L238.941 139.552"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2861"
            d="M401.26 425.943C401.139 426.456 400.911 426.939 400.588 427.361C400.266 427.783 399.857 428.135 399.388 428.395C398.937 428.103 398.937 428.103 398.937 428.085C398.92 428.006 398.92 427.924 398.937 427.845C398.959 427.411 398.878 426.978 398.699 426.579C398.505 426.115 398.107 425.719 398.107 425.194C398.137 424.866 398.284 424.559 398.523 424.326C398.762 424.093 399.078 423.949 399.414 423.92H399.494C399.764 423.881 400.041 423.91 400.297 424.004C400.553 424.098 400.78 424.254 400.957 424.457C401.134 424.66 401.254 424.904 401.307 425.166C401.36 425.427 401.344 425.698 401.26 425.951"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2862"
            d="M414.506 325.474C414.506 325.474 414.506 325.818 414.409 325.878C413.888 326.3 411.539 327.866 411.336 327.995C411.199 328.035 411.054 328.041 410.914 328.014C410.774 327.987 410.643 327.928 410.532 327.84C409.72 327.083 410.241 324.08 412.36 323.701C412.875 323.701 413.374 323.879 413.767 324.203C414.16 324.528 414.422 324.978 414.506 325.474Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2863"
            d="M190.455 96.0342C190.348 96.1133 190.233 96.1798 190.11 96.2321C189.934 96.2838 189.748 96.2321 189.572 96.2924C189.086 96.3956 188.689 96.6882 188.194 96.8173C187.92 96.8948 187.797 96.8173 187.797 96.8173C187.797 96.4731 190.587 94.4508 191.382 94.6918C191.665 94.8553 190.834 95.7674 190.499 96.0342H190.437"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2864"
            d="M295.297 97.6695C295.482 98.814 294.97 99.3905 294.414 99.0463C294.096 98.8398 294.096 98.8398 294.008 98.0051C294.345 98.2046 294.734 98.3061 295.129 98.2977C294.37 97.1875 294.158 96.8691 293.654 96.0947C293.796 96.0172 293.902 95.8881 294.078 95.8623C294.493 95.8623 295.102 96.654 295.261 97.6695"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2865"
            d="M304.365 236.57C304.136 236.906 304.136 236.906 304.083 236.94C303.21 237.56 302.285 238.107 301.319 238.575C300.815 238.73 300.436 238.472 300.259 237.869C300.145 237.516 300.109 237.143 300.153 236.776C300.197 236.408 300.32 236.053 300.514 235.735C300.708 235.416 300.969 235.141 301.28 234.928C301.59 234.715 301.944 234.567 302.316 234.496C302.537 234.505 302.754 234.557 302.952 234.65C303.151 234.744 303.329 234.876 303.473 235.038C303.967 235.396 304.3 235.927 304.4 236.518V236.57"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2866"
            d="M530.642 311.051C530.501 311.791 530.342 312.531 530.192 313.271C529.759 313.529 529.415 312.892 529.309 312.35C529.341 311.642 529.445 310.939 529.618 310.251C529.768 309.648 529.856 309.545 529.909 309.485C529.98 309.485 530.059 309.433 530.236 309.82C530.686 310.793 530.668 310.913 530.633 311.06"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2867"
            d="M329.602 172.907C329.664 173.234 329.717 173.552 329.797 173.879C329.279 173.853 328.766 173.767 328.269 173.621L328.163 173.57C327.942 173.449 327.748 173.286 327.536 173.165C327.183 172.976 326.759 172.924 326.476 172.623C326.061 172.201 326.715 171.427 327.139 171.126C327.395 170.935 327.701 170.818 328.022 170.79C329.214 171.573 329.399 171.651 329.638 172.907"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2868"
            d="M353.161 146.832C352.905 147.133 352.905 147.133 352.852 147.142C352.799 147.151 352.852 147.142 352.623 147.004C352.199 146.703 351.616 146.428 351.201 146.806C351.077 146.929 350.981 147.076 350.919 147.237C350.848 147.374 350.68 147.504 350.583 147.478C350.486 147.452 350.053 147.22 349.921 146.187C349.824 145.447 350.009 144.612 350.645 144.526C351.281 144.44 352.164 144.862 353.197 146.798"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2869"
            d="M225.342 84.8731C225.236 84.9564 225.117 85.0232 224.989 85.071C224.689 85.1915 224.371 85.2603 224.106 85.3808C223.841 85.5013 223.453 85.6648 223.117 85.7939C223.612 85.0882 223.612 85.0882 223.691 85.0366C223.771 84.985 225.775 83.5823 226.199 83.6425C226.623 83.7027 225.598 84.7096 225.378 84.8731"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2870"
            d="M517.431 394.842C516.548 396.563 516.027 397.742 515.832 397.364C515.779 396.896 515.846 396.423 516.027 395.987C516.532 395.098 517.086 394.236 517.687 393.405H517.74C517.899 393.509 517.519 394.705 517.431 394.877"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2871"
            d="M198.931 89.7267C198.745 89.8214 198.745 89.8214 196.449 90.8541C197.791 89.3481 197.791 89.3481 198.039 89.176C198.61 88.8085 199.253 88.5622 199.928 88.4531C200.07 88.608 199.266 89.5374 198.931 89.7267Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2872"
            d="M280.603 187.855C280.506 187.984 280.427 188.122 280.33 188.251C280.155 188.406 279.996 188.575 279.853 188.759C279.704 188.991 279.625 189.259 279.623 189.533C279.707 190.349 279.988 191.134 280.445 191.822C279.438 192.072 279.438 192.072 279.314 192.037C278.879 191.885 278.519 191.577 278.308 191.177C278.04 190.713 277.903 190.189 277.91 189.656C277.918 189.124 278.07 188.604 278.352 188.148C278.496 187.894 278.705 187.681 278.959 187.53C279.213 187.38 279.503 187.296 279.8 187.287C279.985 187.287 279.985 187.287 280.683 187.692L280.595 187.821"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2873"
            d="M516.574 196.176C517.148 197.733 517.316 198.198 517.457 198.671C517.519 198.912 517.457 198.972 517.351 198.921C517.245 198.869 517.166 198.835 516.627 197.69C516.574 197.57 516.512 197.415 516.459 197.303C516.23 196.769 516.071 196.21 515.823 195.677L515.17 194.248C515.747 194.797 516.217 195.445 516.556 196.158"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2874"
            d="M461.236 113.486C461.413 113.649 461.819 114.011 463.329 115.534C461.563 114.028 460.247 112.694 460.238 112.686V112.643C460.603 112.905 460.949 113.193 461.271 113.503"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2875"
            d="M272.002 265.571C271.719 267.894 269.582 268.626 268.655 267.601C268.523 267.418 268.442 267.204 268.42 266.981C268.398 266.757 268.437 266.532 268.531 266.328C268.991 265.269 270.192 263.23 270.2 263.213C270.209 263.195 270.253 263.127 270.66 262.929C271.121 263.196 271.493 263.588 271.731 264.056C271.97 264.525 272.063 265.051 272.002 265.571Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2876"
            d="M422.734 83.8916C424.456 84.9673 424.739 85.1738 424.774 85.2082C424.668 85.2082 422.293 83.7453 422.045 83.5646C422.006 83.5407 421.971 83.5117 421.939 83.4785C422.219 83.5886 422.485 83.727 422.734 83.8916Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2877"
            d="M290.298 210.78C289.671 211.993 288.806 213.663 288.453 212.604C288.285 212.131 288.347 211.615 288.161 211.141C288.056 210.885 287.871 210.668 287.631 210.522C287.467 210.425 287.288 210.355 287.102 210.315C287.016 210.306 286.934 210.277 286.863 210.229C286.664 209.948 286.531 209.628 286.475 209.291C286.895 208.922 287.343 208.585 287.817 208.284C287.906 208.574 288.076 208.834 288.309 209.035C288.541 209.235 288.826 209.369 289.133 209.42C289.742 209.584 289.742 209.584 289.768 209.609C290.011 209.899 290.229 210.207 290.422 210.53L290.298 210.78Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2878"
            d="M349.161 262.928C349.042 263.435 348.725 263.876 348.278 264.159C348.155 264.236 348.155 264.237 346.883 264.305C345.912 264.409 345.912 264.409 345.806 264.374C345.442 264.224 345.121 263.991 344.87 263.694C344.561 263.324 345.029 262.317 345.126 262.188C345.223 262.059 345.355 261.861 346.698 261.75C348.322 261.638 349.4 261.56 349.179 262.946"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2879"
            d="M364.437 238.911C364.437 239.393 363.554 243.214 361.585 241.493C361.109 241.123 360.748 240.631 360.543 240.073C360.746 239.643 360.746 239.643 361.832 238.782C362.918 237.921 363.174 237.921 363.289 237.921C363.583 237.912 363.868 238.016 364.083 238.212C364.298 238.407 364.426 238.677 364.437 238.963"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2880"
            d="M532.336 339.913C532.114 340.862 531.943 341.821 531.824 342.787C531.612 344.138 531.506 344.818 531.242 344.947C530.954 343.097 531.285 341.205 532.186 339.552L532.363 339.767V339.913"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2881"
            d="M177.394 85.277C176.511 85.7245 174.86 86.3871 174.922 86.0773C175.779 85.1454 176.877 84.4532 178.101 84.0723C177.756 84.3218 177.112 84.7865 177.385 84.8296C177.793 84.772 178.191 84.6621 178.569 84.5025C178.498 84.5886 178.357 84.7693 177.394 85.277Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2882"
            d="M534.941 323.744C534.941 324.028 534.703 325.138 534.535 325.018C534.376 325.147 534.262 325.302 534.094 325.422C533.926 325.543 533.944 325.482 533.926 325.474C533.908 325.465 533.741 324.691 533.749 324.441C533.706 324.129 533.746 323.812 533.865 323.52C533.984 323.227 534.178 322.97 534.429 322.772C534.549 322.635 534.689 322.516 534.844 322.419C534.943 322.86 534.973 323.312 534.933 323.761"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2883"
            d="M165.739 117.798C165.297 118.193 165.05 118.262 164.935 118.271C164.82 118.279 164.14 118.142 164.167 117.901C164.193 117.66 166.198 115.267 166.922 115.448C167.487 115.672 166.736 116.92 165.739 117.798Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2884"
            d="M508.601 300.157C508.665 301.051 508.472 301.945 508.044 302.738C507.963 302.642 507.892 302.539 507.832 302.428C507.612 301.929 507.629 301.379 507.426 300.879C507.347 300.622 507.186 300.396 506.967 300.234C506.808 300.139 506.799 300.139 506.729 299.735C506.419 298.091 506.799 297.85 507.082 297.67C507.364 297.489 508.486 298.427 508.601 300.122"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2885"
            d="M160.891 374.241C160.688 374.679 160.34 375.038 159.903 375.261C159.467 375.484 158.966 375.558 158.48 375.471C158.11 374.972 157.686 374.516 157.359 373.991C157.032 373.466 156.953 373.26 156.962 373.131C157.103 372.74 157.35 372.393 157.676 372.126C158.002 371.86 158.396 371.684 158.816 371.616C159.337 371.702 160.979 373.664 160.891 374.267"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2886"
            d="M265.273 200.496C264.276 202.045 263.772 202.82 263.507 201.727C263.388 201.141 263.22 200.565 263.004 200.006C262.774 199.522 262.677 198.988 262.721 198.457C262.721 197.769 263.039 197.502 263.737 197.493C265.3 197.545 265.556 200.075 265.291 200.488"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2887"
            d="M394.391 128.769C395.441 130.594 395.962 131.6 395.962 131.6C395.962 131.6 395.962 131.841 395.724 131.764C394.373 131.334 392.095 128.502 393.013 127.978H393.084C393.351 128.008 393.608 128.097 393.834 128.239C394.061 128.38 394.251 128.571 394.391 128.795"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2888"
            d="M538.403 330.302C538.403 330.302 538.032 333.055 537.723 332.599C537.557 331.432 537.632 330.244 537.943 329.105C537.996 329.037 538.093 328.95 538.138 329.191C538.132 329.41 538.161 329.628 538.226 329.837C538.297 329.785 538.314 329.699 538.385 329.648C538.456 329.596 538.447 329.699 538.385 330.293"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2889"
            d="M48.7626 264.116C48.5314 264.846 48.0478 265.476 47.3939 265.897C47.3454 265.691 47.3188 265.481 47.3145 265.269C47.3145 264.968 47.4822 264.692 47.4999 264.408C47.4999 263.909 47.3763 263.41 47.3939 262.903C47.3982 262.693 47.4432 262.485 47.5264 262.292C47.6446 262.013 47.8119 261.757 48.0209 261.534C48.3456 261.417 48.7028 261.417 49.0275 261.534C49.3278 261.732 48.7715 264.116 48.7626 264.116Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2890"
            d="M152.978 216.382L151.274 217.828C150.889 217.671 150.559 217.41 150.321 217.076C150.083 216.742 149.948 216.349 149.932 215.943C149.985 215.315 151.565 214.386 151.583 214.377C151.755 214.266 151.961 214.218 152.166 214.239C152.259 214.268 152.344 214.317 152.414 214.383C152.484 214.448 152.538 214.529 152.572 214.618C152.819 215.152 152.907 215.728 153.111 216.27L152.978 216.382Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2891"
            d="M352.074 152.477C352.189 152.959 351.703 154.448 350.573 153.759C350.023 153.365 349.509 152.924 349.036 152.443C349.036 152.443 348.392 150.954 349.036 150.765H349.107C349.986 150.989 350.831 151.321 351.624 151.754C351.85 151.953 352.015 152.21 352.101 152.494"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2892"
            d="M153.331 140.61C151.689 141.471 151.689 141.471 151.424 141.471C151.159 141.471 150.965 141.04 151.159 140.438C151.477 139.44 152.554 138.166 153.172 138.295C153.49 138.416 153.711 139.913 153.711 139.93C153.638 140.151 153.549 140.367 153.446 140.576C153.446 140.576 153.366 140.576 153.331 140.627"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2893"
            d="M532.258 313.701C532.243 313.852 532.219 314.001 532.187 314.149C532.072 314.527 531.852 314.872 531.746 315.25C531.64 315.629 531.569 316.214 531.401 316.679C531.313 316.954 531.216 316.937 531.163 316.877C530.695 316.369 530.925 313.039 531.622 312.359C532.055 312.023 532.276 313.348 532.276 313.624C532.28 313.649 532.28 313.676 532.276 313.701"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2894"
            d="M323.553 257.516C323.553 259.099 323.553 259.426 323.191 259.71C322.791 259.899 322.352 259.997 321.907 259.997C321.462 259.997 321.022 259.899 320.622 259.71C319.739 258.927 320.525 256.096 322.308 256.001C322.715 256.001 323.545 256.001 323.553 257.498"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2895"
            d="M305.637 260.037C305.637 260.295 305.637 260.364 304.877 261.294C304.626 261.653 304.29 261.948 303.897 262.154C303.518 262.188 303.136 262.135 302.782 261.999C302.428 261.863 302.111 261.648 301.857 261.371C301.557 261.096 301.257 260.717 301.645 260.278C301.722 260.187 301.808 260.103 301.902 260.029L302.069 259.9C302.272 259.753 302.493 259.641 302.696 259.487C303.102 259.185 303.456 258.833 303.88 258.549C304.069 258.403 304.301 258.319 304.542 258.308C305.522 258.351 305.637 259.538 305.637 260.029"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2896"
            d="M401.763 83.7371C402.546 84.1226 403.291 84.5777 403.988 85.0967C403.52 85.1742 399.979 83.4703 399.67 82.9023C400.404 83.0965 401.108 83.3859 401.763 83.7629"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2897"
            d="M269.52 195.35C268.505 196.985 268.425 197.123 267.984 197.2C267.568 197.211 267.159 197.097 266.811 196.874C266.464 196.651 266.195 196.329 266.041 195.952C266.037 195.801 266.074 195.651 266.149 195.518C266.223 195.385 266.332 195.274 266.465 195.195C266.58 195.092 268.231 193.922 268.231 193.913C268.392 193.801 268.581 193.735 268.779 193.724C269.573 193.801 269.662 195.1 269.538 195.35"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2898"
            d="M523.515 385.6C523.25 386.363 522.925 387.105 522.543 387.82C522.226 388.345 522.023 388.104 522.014 388.096C522.041 387.768 522.094 387.443 522.173 387.123C522.173 387.123 523.638 384.404 523.709 384.335C523.78 384.266 523.789 384.275 523.815 384.275C523.772 384.727 523.672 385.172 523.515 385.6Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2899"
            d="M259.666 102.316C259.49 102.514 258.836 102.867 258.783 101.74C258.746 101.139 258.89 100.54 259.198 100.019C259.366 99.683 259.366 99.683 260.081 99.1064C259.887 101.344 259.702 102.273 259.702 102.282"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2900"
            d="M383.378 94.1325C383.749 94.4596 384.376 94.9931 384.508 95.1738C384.508 95.1738 384.508 95.1738 384.508 95.475C383.347 95.0721 382.259 94.4911 381.285 93.7539C381.601 93.8565 381.938 93.8802 382.265 93.8228C382.666 93.7802 383.069 93.8845 383.396 94.1153"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2901"
            d="M96.7022 392.768C96.8435 394.153 96.0665 393.689 95.7221 393.422C94.9796 392.768 94.4178 391.942 94.0885 391.021C93.6999 389.782 94.5035 389.558 94.5123 389.549C94.5647 389.538 94.6189 389.538 94.6713 389.549C95.6779 389.876 96.5963 391.718 96.7022 392.751"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2902"
            d="M400.845 80.6566C400.845 80.6566 402.478 81.4655 404.916 82.9112C403.503 82.3519 400.182 80.9836 400.006 80.4329C400.006 80.4329 400.227 80.3468 400.845 80.6566Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2903"
            d="M89.3481 139.174C89.3481 139.174 87.9441 140.611 87.582 140.551C87.6195 140.445 87.6699 140.344 87.7321 140.249C88.1118 139.742 88.571 139.294 88.9419 138.778C89.2642 138.288 89.612 137.814 89.9839 137.358C89.9839 137.358 90.8669 136.36 91.0435 136.618C91.2201 136.876 90.4695 137.969 89.2774 139.199"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2904"
            d="M232.398 76.0957C230.959 77.0337 230.005 77.3865 230.032 77.1283C230.59 76.3522 231.37 75.7536 232.275 75.4072C232.072 75.6052 231.692 75.9666 231.992 75.9666C232.297 75.9108 232.594 75.8183 232.875 75.6912C232.736 75.8529 232.568 75.9898 232.381 76.0957"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2905"
            d="M329.566 460.769C329.364 461.473 328.91 462.084 328.286 462.49C328.153 462.585 327.606 462.43 327.526 462.344C327.447 462.257 327.526 462.206 327.526 462.146C327.496 461.797 327.381 461.461 327.191 461.165C327.005 460.854 326.782 460.565 326.528 460.304C326.454 460.238 326.389 460.162 326.334 460.08L326.272 459.968C326.25 459.892 326.25 459.812 326.272 459.736C326.272 459.409 326.581 458.876 328.038 458.626C328.312 458.841 328.63 459.005 328.921 459.22C329.103 459.354 329.266 459.51 329.407 459.684C329.525 459.838 329.609 460.013 329.656 460.2C329.703 460.386 329.712 460.579 329.681 460.769"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2906"
            d="M175.204 130.465C174.374 131.239 174.374 131.239 174.259 131.256C174.144 131.274 173.702 131.256 173.694 130.766C173.694 130.568 173.694 130.379 173.694 130.189C173.663 129.967 173.645 129.743 173.641 129.518C173.674 129.343 173.749 129.177 173.861 129.036C174.078 128.794 174.352 128.608 174.66 128.494C174.968 128.38 175.301 128.341 175.627 128.382C175.875 128.46 175.954 128.597 175.884 128.89C175.741 129.364 175.57 129.829 175.371 130.284C175.371 130.361 175.239 130.413 175.177 130.465"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2907"
            d="M153.534 463.635L153.649 463.583C153.303 463.174 152.935 462.783 152.545 462.413C152.295 462.282 152.017 462.208 151.733 462.198C149.164 461.948 150.629 464.134 150.647 464.16C150.665 464.185 150.735 464.263 151.609 464.607C151.504 464.323 151.353 463.944 151.53 463.85C151.707 463.755 151.627 463.85 152.413 463.97C152.66 464.031 152.66 464.031 153.561 463.635"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2908"
            d="M431.831 282.42C431.645 283.436 431.319 285.32 430.55 284.279C430.312 283.961 430.153 283.599 429.923 283.272C429.579 282.773 429.164 282.308 428.837 281.792C428.693 281.601 428.588 281.385 428.528 281.155C428.471 281.009 428.448 280.853 428.46 280.697C428.473 280.541 428.52 280.39 428.599 280.254C428.679 280.119 428.788 280.002 428.919 279.912C429.05 279.822 429.2 279.762 429.358 279.735C431.486 279.443 431.831 282.386 431.831 282.42Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2909"
            d="M412.624 273.574C412.624 273.987 412.624 274.434 412.624 274.822L412.403 274.667C411.856 274.262 411.821 274.237 410.54 274.219C409.887 274.241 409.236 274.151 408.615 273.953C408.827 272.636 408.827 272.636 408.915 272.464C409.16 272.065 409.495 271.726 409.896 271.474C410.219 271.426 410.548 271.443 410.864 271.524C411.18 271.605 411.475 271.748 411.732 271.945C411.989 272.142 412.201 272.388 412.356 272.668C412.511 272.948 412.605 273.257 412.633 273.574"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2910"
            d="M327.712 251.733C327.712 252.344 327.712 252.344 325.513 254.78C325.175 254.442 324.878 254.066 324.63 253.661C324.542 253.454 324.533 253.429 324.63 252.8C325.027 250.331 325.911 250.219 326.237 250.219C326.44 250.226 326.639 250.273 326.823 250.356C327.007 250.439 327.172 250.557 327.309 250.703C327.446 250.848 327.552 251.019 327.622 251.205C327.691 251.39 327.721 251.588 327.712 251.785"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2911"
            d="M267.039 271.001C266.677 273.583 264.451 274.108 263.507 272.937C263.22 272.549 262.964 272.14 262.738 271.715C263.533 271.264 264.363 270.872 265.22 270.545C266.403 270.072 266.5 270.037 266.906 269.856C267.118 270.717 267.118 270.717 267.083 271.001"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2912"
            d="M307.96 150.653C308.057 151.643 307.439 152.753 306.467 152.761C305.911 152.021 305.761 151.694 305.876 151.04C305.991 150.386 306.503 149.466 307.068 149.405C307.386 149.405 307.898 150.033 307.951 150.636"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2913"
            d="M506.746 176.04C506.952 176.37 507.092 176.735 507.161 177.115C507.1 177.374 506.49 176.651 505.952 175.833C505.593 175.285 505.28 174.709 505.016 174.112C505.104 173.819 506.199 175.059 506.782 175.988"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2914"
            d="M162.392 198.982C162.236 199.322 162.021 199.633 161.756 199.902L160.299 201.064C159.619 201.494 159.355 201.124 159.293 200.995C159.233 200.409 159.373 199.819 159.69 199.317C159.973 198.904 160.944 197.545 161.597 197.596C162.48 197.811 162.728 198.276 162.392 198.999"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2915"
            d="M152.449 135.611L152.538 135.559L152.582 135.464L152.458 135.602M155.381 134.337C155.646 134.26 156.131 133.184 156.14 133.175C156.149 133.167 156.352 132.599 156.14 132.427C156.093 132.402 156.043 132.384 155.99 132.375C155.199 132.577 154.473 132.971 153.88 133.52C153.318 134.065 152.89 134.727 152.626 135.456C152.947 135.064 153.342 134.736 153.791 134.492C154.07 134.374 154.383 134.355 154.674 134.44C154.842 134.483 154.842 134.483 155.39 134.337"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2916"
            d="M323.819 248.05C323.819 248.05 323.624 248.799 323.421 248.825C323.124 248.907 322.808 248.905 322.511 248.819C322.214 248.734 321.948 248.569 321.744 248.343C321.202 247.53 320.75 246.665 320.393 245.761C320.482 245.552 320.633 245.372 320.826 245.246C321.019 245.119 321.246 245.05 321.479 245.047C323.624 245.047 323.863 247.766 323.819 248.016"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2917"
            d="M531.64 337.478C531.564 338.489 531.291 339.476 530.836 340.387H530.783C530.658 340.027 530.596 339.649 530.598 339.268C530.66 338.692 530.845 338.132 530.942 337.547C531.039 336.962 531.039 336.411 531.136 335.826C531.148 335.688 531.193 335.556 531.269 335.439C531.498 335.284 531.755 336.661 531.64 337.435"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2918"
            d="M368.182 261.243C368.367 263.97 366.177 264.297 365.356 263.437C365.176 263.224 365.058 262.968 365.015 262.695C364.971 262.423 365.004 262.144 365.109 261.888C365.181 261.77 365.274 261.666 365.382 261.578L365.515 261.501C366.098 261.148 366.689 260.812 367.281 260.451C366.009 259.41 365.983 259.392 366.124 258.988L366.592 258.833C367.041 259.077 367.422 259.424 367.702 259.844C367.982 260.264 368.152 260.744 368.199 261.243"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2919"
            d="M212.345 81.9984C212.138 82.0755 211.926 82.136 211.709 82.1791C212.142 81.7919 212.186 81.6886 212 81.5681C211.815 81.4476 212.133 81.1206 212.142 81.112C212.15 81.1034 213.519 80.2945 214.12 80.3978C214.332 80.4924 212.513 81.9468 212.495 81.964H212.345"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2920"
            d="M264.063 86.9984L264.01 87.0586C264.032 87.046 264.05 87.0283 264.063 87.007M265.096 87.1447C265.246 86.7402 265.264 86.3271 265.017 86.3271C264.769 86.3271 264.054 87.0242 264.045 87.0328C264.036 87.0414 263.356 88.2806 263.639 88.5215C263.922 88.7625 264.681 88.2978 265.096 87.1447Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2921"
            d="M403.442 80.4496C404.731 81.3102 404.757 81.3102 404.802 81.422C403.629 81.1233 402.511 80.6471 401.49 80.0107C402.188 80.2517 402.205 80.2345 402.373 80.0882C402.541 79.9419 403.406 80.441 403.415 80.4496"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2922"
            d="M199.779 169.293C199.637 169.586 199.47 169.87 199.319 170.154C199.061 169.795 198.678 169.538 198.242 169.431C197.359 169.121 197.359 169.112 197.156 168.811C197.103 168.734 197.041 168.665 196.988 168.596C197.598 167.779 197.598 167.779 197.686 167.736C198.569 167.013 198.798 167.038 198.984 167.064C200.52 167.357 199.787 169.293 199.779 169.31"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2923"
            d="M321.919 175.11C321.381 175.85 321.213 176.074 320.577 175.867C320.24 175.719 319.942 175.497 319.707 175.218C319.473 174.938 319.308 174.61 319.226 174.258C319.182 173.685 319.182 173.11 319.226 172.537C319.518 172.649 319.835 172.718 320.109 172.838C320.288 172.906 320.453 173.005 320.595 173.131C321.13 173.703 321.608 174.322 322.025 174.981C321.993 175.029 321.957 175.075 321.919 175.119"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2924"
            d="M193.112 97.2048C192.865 97.4027 192.608 97.592 192.361 97.7986C192.361 97.3941 192.361 97.3941 192.229 97.265C192.128 97.1599 192.072 97.0215 192.072 96.8778C192.072 96.7341 192.128 96.5957 192.229 96.4905C192.835 96.0237 193.569 95.7423 194.339 95.6816C194.103 96.2894 193.692 96.8181 193.156 97.2048"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2925"
            d="M182.268 102.644C181.712 102.971 181.694 102.979 181.297 102.876C180.899 102.773 180.652 102.79 179.751 103.323C179.54 102.755 182.401 101.146 182.869 101.293C183.337 101.439 182.48 102.523 182.268 102.644Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2926"
            d="M316.488 230.409C315.985 228.86 315.932 228.688 314.969 228.834C313.716 229.247 313.645 230.856 313.707 231.786C313.724 232.126 313.802 232.461 313.936 232.775C314.026 232.891 314.129 232.995 314.245 233.085C314.59 233.335 314.969 233.532 315.314 233.765C315.45 233.197 315.556 232.622 315.632 232.044C315.599 231.52 315.629 230.995 315.72 230.478C315.72 230.478 315.764 230.426 315.905 230.409C316.047 230.391 316.294 230.409 316.488 230.409Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2927"
            d="M110.592 411.46C110.448 411.579 110.298 411.691 110.142 411.795C110.04 411.861 109.917 411.886 109.797 411.864C108.853 411.58 106.813 408.938 107.89 408.267C107.948 408.227 108.015 408.201 108.085 408.189C108.155 408.177 108.227 408.18 108.296 408.198C109.25 408.482 110.371 410.066 110.601 411.46"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2928"
            d="M112.209 136.497C111.485 137.194 111.485 137.194 109.348 138.347C109.661 137.573 110.175 136.891 110.84 136.368C111.343 135.929 112.279 135.249 112.606 135.507C112.933 135.765 112.474 136.264 112.2 136.531"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2929"
            d="M500.937 167.313C501.105 167.709 501.211 167.942 501.043 167.907C500.451 167.769 498.191 164.198 498.164 163.828C498.27 163.828 498.455 164.026 498.455 164.026C498.959 164.663 499.4 165.351 499.895 165.988L500.018 166.152C500.363 166.5 500.66 166.89 500.901 167.313"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2930"
            d="M244.963 154.56C244.963 154.637 244.389 156.634 242.888 156.642C242.403 156.582 242.182 156.358 242.005 155.739C241.901 155.54 241.869 155.312 241.915 155.092C241.962 154.873 242.084 154.676 242.261 154.534C242.451 154.474 242.655 154.474 242.844 154.534C243.453 154.586 243.498 154.534 244.61 153.519C244.785 153.635 244.914 153.805 244.977 154.002C245.039 154.199 245.031 154.411 244.955 154.603"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2931"
            d="M400.879 95.6988C400.95 95.9053 400.95 95.9053 400.729 96.0688H400.659H400.606C400.509 96.1377 400.376 96.2237 399.793 95.8881C399.793 95.8881 398.257 94.9845 398.266 94.7436C398.266 94.7436 398.266 94.6919 398.433 94.5542C398.601 94.4165 398.725 94.5026 399.211 94.7091C399.785 94.961 400.343 95.2484 400.879 95.5697"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2932"
            d="M540.036 331.127C540.026 331.324 539.987 331.518 539.921 331.704C539.921 331.239 539.921 331.239 539.921 331.205C539.921 331.17 539.815 331.394 539.745 331.48C539.497 331.334 540.054 328.752 540.107 328.615C540.16 328.477 540.168 328.511 540.204 328.52C540.245 329.383 540.197 330.248 540.063 331.102"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2933"
            d="M376.808 129.612C377.373 130.645 377.373 130.645 377.135 131.488C376.879 131.239 376.623 131.006 376.384 130.757C375.925 130.275 375.59 129.716 375.184 129.199C374.919 128.864 374.468 128.769 374.203 128.442C374.133 128.356 373.93 127.908 374.203 127.822C375.131 127.702 376.526 129.191 376.764 129.612"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2934"
            d="M397.877 217.483C398.01 218.377 397.826 219.287 397.356 220.065C397.003 220.624 396.676 220.693 396.085 220.323C395.493 219.953 395.59 217.896 395.599 217.879C395.705 216.933 396.244 216.804 396.482 216.743C397.586 216.597 397.824 217.311 397.86 217.458"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2935"
            d="M167.018 165.42C166.594 166.151 166.241 166.168 165.923 166.117C165.26 165.91 165.137 164.964 165.322 164.525C165.508 164.086 167.026 163.096 167.221 163.002C167.356 162.916 167.51 162.863 167.671 162.847C167.83 162.898 168.104 162.976 168.015 163.337C167.979 163.468 167.929 163.595 167.865 163.716L166.982 165.437"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2936"
            d="M271.949 198.086C271.464 200.918 271.314 201.77 271.234 201.924C271.148 202.014 271.044 202.086 270.929 202.136C270.813 202.187 270.689 202.214 270.563 202.217C270.348 202.011 270.153 201.787 269.98 201.546C268.938 200.1 269.821 197.665 271.411 197.604C271.616 197.615 271.815 197.68 271.985 197.794C271.985 197.88 271.985 198 271.985 198.086"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2937"
            d="M369.242 84.1671C369.242 84.1671 367.087 82.9107 366.857 83.0742C366.628 83.2378 368.491 84.4511 368.509 84.4683C368.827 84.6749 369.604 85.1482 369.957 85.0879C370.089 85.0879 369.957 84.8986 369.242 84.1671Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2938"
            d="M531.329 365.695C531.122 366.715 530.753 367.696 530.234 368.604C530.407 367.165 530.821 365.763 531.462 364.456C531.454 364.878 531.391 365.297 531.276 365.704"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2939"
            d="M418.444 89.0896C419.185 89.537 420.016 90.2169 419.812 90.2599C418.772 89.923 417.79 89.4356 416.898 88.8142L416.978 88.8658C417.605 88.7023 417.605 88.7023 417.649 88.7109C417.924 88.8153 418.19 88.942 418.444 89.0896Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2940"
            d="M394.947 223.378C395.424 227.053 395.388 227.104 395.018 227.629C393.013 227.182 392.068 223.826 393.667 222.853C393.807 222.773 393.959 222.715 394.117 222.681C394.285 222.681 394.85 222.638 394.947 223.378Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2941"
            d="M385.215 112.815C386.496 114.105 386.681 114.914 386.098 114.923C385.215 114.923 383.644 112.591 383.988 112.272C383.988 112.272 383.988 112.272 384.041 112.272C384.48 112.33 384.886 112.534 385.189 112.849"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2942"
            d="M322.097 157.718C322.274 158.94 321.161 160.61 320.331 159.947C319.501 159.284 319.704 156.918 321.152 156.771C321.278 156.773 321.403 156.798 321.519 156.846C321.634 156.895 321.739 156.965 321.827 157.053C321.915 157.141 321.984 157.245 322.03 157.359C322.077 157.473 322.099 157.595 322.097 157.718Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2943"
            d="M531.842 326.962C531.849 327.234 531.778 327.501 531.639 327.737C531.639 327.737 531.1 328.339 530.959 328.158C530.849 327.641 530.816 327.11 530.862 326.583C530.959 325.783 531.224 325.009 531.401 324.226C531.886 326.282 531.886 326.282 531.842 326.962Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2944"
            d="M421.764 99.2784C422.471 99.9152 422.647 100.087 422.329 100.079C421.702 100.079 420.722 99.3644 418.947 98.0908C419.406 98.0908 419.406 98.0908 419.53 98.1425C420.304 98.4632 421.051 98.843 421.764 99.2784Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2945"
            d="M306.865 196.116L306.927 196.03V196.133M309.885 194.532C309.973 193.328 308.825 191.779 307.589 191.89C306.353 192.002 306.079 194.025 306.079 194.042C305.992 194.422 306.03 194.819 306.189 195.176C306.347 195.533 306.618 195.832 306.962 196.03C307.28 194.489 307.28 194.489 308.578 193.293C309.302 194.816 309.302 194.816 309.664 195.169C309.765 194.966 309.842 194.752 309.894 194.532"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2946"
            d="M239.613 81.0181C239.036 81.4714 238.422 81.8773 237.776 82.2315C237.352 82.395 237.432 81.8614 237.432 81.8614C237.593 81.6015 237.782 81.3591 237.997 81.1386C238.555 80.9317 239.128 80.7621 239.71 80.6309C239.71 80.6309 239.71 80.6309 239.71 80.6825C239.71 80.7341 239.71 80.8374 239.71 80.9148L239.551 81.0181"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2947"
            d="M89.8862 353.071C89.6654 353.501 89.5153 353.931 89.2681 354.37C89.1697 354.551 89.0193 354.701 88.8354 354.8C88.7566 354.825 88.6735 354.833 88.5914 354.824C88.5092 354.815 88.4299 354.789 88.3585 354.749C87.97 354.577 87.6521 354.319 87.2812 354.129C87.8111 351.978 88.076 350.894 89.1003 351.152C89.4712 351.272 89.5242 351.401 89.5771 351.522C89.6301 351.642 89.948 352.95 89.948 352.959C89.948 352.968 89.948 353.036 89.8862 353.071Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2948"
            d="M170.788 108.521C170.276 108.736 170.276 108.736 168.66 108.633C168.951 108.34 169.267 108.072 169.605 107.833C170.885 107.127 171.592 106.835 171.813 106.903C172.033 106.972 171.018 108.418 170.788 108.521Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2949"
            d="M78.1421 283.634C77.6299 285.682 76.2259 286.826 75.5548 285.733C75.4135 285.492 75.4135 285.492 75.1133 283.599C77.4621 283.031 77.4621 283.031 77.6387 283.091C77.8272 283.202 78.0072 283.326 78.1774 283.461C78.1774 283.513 78.1774 283.582 78.1774 283.634"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2950"
            d="M332.268 83.3752C332.833 84.2357 332.833 84.2357 332.816 84.2959C332.763 84.5369 332.056 84.3906 332.048 84.3906C331.733 84.158 331.438 83.9019 331.165 83.6247C331.12 83.5473 331.12 83.5473 331.226 82.9707C331.562 83.0826 331.906 83.1858 332.251 83.3149C332.251 83.3149 332.251 83.3149 332.251 83.3752"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2951"
            d="M354.159 107.393C354.892 108.314 355.042 109.175 354.424 109.115C353.987 108.908 353.599 108.615 353.285 108.254C353.285 108.254 353.161 107.936 353.055 107.6C352.87 106.989 352.702 106.361 352.508 105.741C353.108 106.236 353.661 106.783 354.159 107.376"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2952"
            d="M158.736 103.762C158.082 104.265 157.336 104.641 156.537 104.872C156.969 104.433 157.439 104.03 157.941 103.667C158.541 103.271 159.077 102.789 159.531 102.239C159.531 102.239 159.531 102.144 159.592 102.127C159.654 102.11 160.475 101.585 160.599 101.654C160.846 101.886 159.99 102.85 158.736 103.762Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2953"
            d="M386.027 127.462C386.486 128.391 386.548 128.512 385.877 128.322C385.744 128.283 385.614 128.234 385.488 128.176L385.409 128.124C385.223 128.004 385.064 127.858 384.879 127.737C384.693 127.617 384.278 127.41 383.996 127.221C383.876 127.135 383.761 127.043 383.651 126.946C383.36 126.662 382.874 125.921 383.236 125.758H383.289C384.261 125.586 385.859 127.195 386.027 127.479"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2954"
            d="M412.395 87.8845C413.278 88.4094 413.278 88.5213 413.278 88.6676C413.278 88.6676 413.225 88.6676 413.216 88.7192C413.207 88.7709 413.216 88.8053 413.083 88.7795C412.878 88.7076 412.683 88.6122 412.501 88.4955C411.583 87.9747 410.698 87.3999 409.852 86.7744C410.719 87.0738 411.553 87.4572 412.342 87.9189"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2955"
            d="M255.453 150.361C255.091 152.263 253.625 153.192 253.007 152.28C252.823 151.847 252.673 151.401 252.557 150.946C253.344 150.455 254.209 150.094 255.117 149.879C255.162 149.879 255.162 149.879 255.488 150.085C255.488 150.171 255.488 150.275 255.488 150.361"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2956"
            d="M298.184 100.939C298.184 101.163 298.228 101.395 298.246 101.619C298.049 101.451 297.833 101.307 297.601 101.189C297.431 101.13 297.265 101.058 297.106 100.974C296.968 100.886 296.855 100.764 296.78 100.621C296.611 100.339 296.505 100.025 296.471 99.7002C296.476 99.6436 296.491 99.5884 296.515 99.5367C296.603 99.3559 296.718 99.1924 296.806 99.0117C298.14 100.664 298.14 100.664 298.184 100.939Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2957"
            d="M209.563 159.715C208.751 160.455 208.751 160.455 208.618 160.524C208.486 160.592 208.062 160.584 207.788 160.67C207.405 160.816 207.034 160.988 206.676 161.186C206.888 160.756 207.055 160.326 207.276 159.887C207.391 159.654 207.537 159.437 207.709 159.241C208.149 158.817 208.72 158.545 209.334 158.467C209.422 158.518 209.431 158.518 209.501 158.699C209.572 158.88 209.652 159.276 209.74 159.56L209.563 159.723"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2958"
            d="M126.876 469.865C127.167 470.476 127.397 471.758 126.293 471.336C126.107 471.268 125.937 471.163 125.795 471.026C125.654 470.89 125.544 470.726 125.472 470.545C125.869 470.304 125.657 469.865 125.224 469.013C124.974 468.601 124.84 468.132 124.836 467.653C125.68 468.229 126.378 468.986 126.876 469.865Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2959"
            d="M329.002 188.138C329.077 188.664 329.047 189.198 328.914 189.713C328.914 189.713 328.914 189.713 328.031 190.436C327.148 188.491 327.148 188.216 327.28 186.056C327.625 186.314 328.005 186.529 328.34 186.796C328.47 186.895 328.586 187.011 328.684 187.14C328.887 187.437 329.006 187.782 329.029 188.138"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2960"
            d="M248.495 135.421C248.345 135.929 248.345 135.929 246.287 136.962C246.173 136.514 246.173 136.514 246.217 136.342C246.304 135.955 246.466 135.587 246.694 135.258C246.844 135.025 247.4 134.285 248.089 134.294C248.778 134.303 248.61 135.051 248.495 135.421Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2961"
            d="M397.454 80.8802C398.179 81.276 399.521 81.9989 399.221 82.0763C398.524 81.9175 397.851 81.6747 397.216 81.3535C397.216 81.3535 396.775 80.992 396.783 80.906C396.787 80.8861 396.787 80.8656 396.783 80.8457C397.243 80.8027 397.243 80.8027 397.393 80.8457"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2962"
            d="M308.285 242.018C308.285 242.302 308.285 242.577 308.285 242.878C308.343 243.585 308.256 244.296 308.029 244.969C306.422 242.448 306.263 242.121 306.166 241.579C305.936 240.297 306.493 240.064 307.137 240.013C308.162 240.013 308.25 242.026 308.25 242.044"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2963"
            d="M82.2025 316.722C82.0966 317.582 81.5844 317.582 81.3195 317.342C80.8125 316.482 80.602 315.487 80.719 314.502C80.7632 314.235 81.3372 312.66 82.0966 312.695C82.1551 312.686 82.2146 312.686 82.2732 312.695C82.5028 312.961 82.4144 313.856 82.2732 314.975V315.328C82.1849 315.775 82.2114 316.231 82.1495 316.679"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2964"
            d="M103.934 302.996C103.095 303.22 103.095 303.22 102.866 303.194C102.336 303.093 101.82 302.931 101.329 302.712C101.329 302.712 100.667 302.29 101.232 301.318C101.38 301.024 101.618 300.782 101.915 300.627C102.211 300.473 102.55 300.413 102.883 300.457C103.059 300.51 103.222 300.598 103.362 300.715C103.502 300.832 103.616 300.976 103.696 301.137C103.79 301.334 103.855 301.543 103.89 301.757C103.97 302.161 103.996 302.566 104.067 302.97H103.934"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2965"
            d="M110.249 222.208C110.423 221.931 110.502 221.608 110.475 221.285C110.448 220.962 110.316 220.655 110.099 220.409L110.055 220.547C109.737 221.562 109.49 221.984 108.766 221.95C108.492 221.95 107.75 221.898 107.379 223C107.009 224.101 107.733 224.488 107.945 224.385C108.899 223.873 109.695 223.122 110.249 222.208ZM110.099 220.409V220.323L110.037 220.383"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2966"
            d="M73.7268 288.538C73.3735 289.976 72.8437 290.337 72.6141 290.457C72.155 290.75 72.0225 290.621 71.3867 290.01C71.8517 289.282 72.1861 288.481 72.3757 287.644C72.6495 286.783 72.9938 285.699 73.3382 285.673C73.8346 286.546 73.9734 287.57 73.7268 288.538Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2967"
            d="M444.529 284.606C444.259 284.843 443.963 285.051 443.646 285.226C443.505 285.286 442.675 285.458 442.675 285.458C442.187 285.427 441.706 285.331 441.244 285.174C441.292 284.586 441.42 284.008 441.624 283.453C441.668 283.367 441.792 283.134 442.427 282.979C442.916 282.865 443.429 282.904 443.893 283.091C443.989 283.177 444.065 283.283 444.114 283.401C444.308 283.771 444.458 284.158 444.644 284.528L444.529 284.606Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2968"
            d="M374.839 86.2759C375.06 86.5168 375.06 86.5168 375.033 86.7578C375.007 86.9987 375.033 87.0848 375.21 87.3085L375.36 87.472C374.903 87.2424 374.461 86.9866 374.035 86.7062C373.894 86.5943 372.658 85.6047 372.843 85.4756C373.56 85.5999 374.24 85.8728 374.839 86.2759Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2969"
            d="M87.0067 368.845C87.0067 369.783 87.0067 369.783 85.903 370.661C85.1877 368.079 85.1877 368.079 85.6292 365.756C86.9891 367.942 86.9891 367.942 87.0067 368.845Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2970"
            d="M101.074 296.361C100.819 296.468 100.548 296.532 100.271 296.55C100.121 296.55 99.2022 296.284 99.1934 296.284C99.0168 296.223 99.0168 296.223 98.1426 295.423C98.3554 294.969 98.6096 294.534 98.902 294.124C99.0474 293.978 99.2296 293.873 99.43 293.819C99.6304 293.764 99.8421 293.762 100.044 293.813C100.245 293.863 100.429 293.965 100.578 294.107C100.726 294.249 100.833 294.427 100.889 294.623C101.079 295.178 101.178 295.759 101.18 296.344H101.074"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2971"
            d="M354.547 242.224C353.514 243.506 351.298 241.966 351.015 240.202C350.856 239.143 351.501 238.842 351.766 238.816C352.251 238.816 352.463 238.937 352.56 239.057C352.658 239.178 352.728 239.264 354.538 242.207"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2972"
            d="M135.237 93.8053C133.816 94.4507 133.816 94.4507 133.86 94.3216C134.346 93.8211 134.895 93.3817 135.494 93.0136C135.494 93.0136 135.97 92.8845 135.988 92.9447C135.993 92.9761 135.993 93.008 135.988 93.0394V93.091C135.37 93.745 135.37 93.745 135.264 93.7967"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2973"
            d="M519.33 200.978C519.462 201.27 519.471 201.287 520.028 202.604C519.586 202.26 519.586 202.26 518.977 200.986C518.563 200.224 518.265 199.406 518.094 198.56C518.853 199.936 518.853 199.936 519.348 200.978"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2974"
            d="M515.665 207.811L515.877 208.267C515.921 208.362 515.966 208.465 516.019 208.56C516.248 209.188 516.469 209.816 516.69 210.444C515.557 209.292 514.878 207.786 514.773 206.193C515.1 206.715 515.395 207.256 515.656 207.811"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2975"
            d="M188.449 104.933C187.884 105.079 187.884 105.079 187.566 104.933C187.284 104.855 187.284 104.855 187.275 104.829C187.266 104.804 187.275 104.829 187.195 104.356C187.586 104.064 187.996 103.796 188.423 103.556C188.691 103.406 188.997 103.331 189.306 103.341C189.809 103.582 188.546 104.847 188.529 104.864H188.449"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2976"
            d="M121.11 449.315C121.366 450.063 121.286 450.253 121.224 450.39C121.11 450.666 121.11 450.666 120.465 450.881C119.644 449.633 119.635 449.607 118.973 447.086C120.403 448.067 120.783 448.334 121.11 449.315Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2977"
            d="M387.733 299.132C387.874 300.853 386.532 300.922 385.967 300.431C385.687 300.084 385.512 299.666 385.463 299.227C385.234 298.16 385.022 297.867 384.342 297.66L384.986 296.705C385.337 296.665 385.692 296.698 386.028 296.802C386.364 296.905 386.673 297.078 386.936 297.308C387.198 297.537 387.407 297.819 387.549 298.133C387.691 298.448 387.763 298.788 387.759 299.132"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2978"
            d="M190.631 91.3445C189.81 91.5252 189.81 91.5252 189.748 91.4994L189.545 91.4047C189.557 91.2365 189.583 91.0695 189.624 90.9056C191.39 90.0451 191.39 90.0451 191.629 90.1139C191.867 90.1828 191.735 90.2516 191.629 90.3979C191.523 90.5442 190.746 91.3187 190.746 91.3187C190.708 91.3227 190.669 91.3227 190.631 91.3187"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2979"
            d="M510.464 410.65C510.293 411.008 510.101 411.355 509.89 411.692C509.89 411.692 509.564 411.959 509.537 411.761C509.576 411.43 509.668 411.108 509.811 410.805C509.908 410.547 510.032 410.203 510.058 410.108C510.085 410.014 510.058 409.816 510.058 409.67C510.072 409.619 510.09 409.571 510.111 409.523C510.264 409.197 510.483 408.904 510.756 408.663C510.95 408.525 510.976 408.551 510.87 409.145C510.822 409.68 510.682 410.204 510.455 410.694"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2980"
            d="M223.145 88.2979C222.421 88.4958 222.421 88.4958 222.262 88.4528C222.103 88.4098 222.059 88.2549 222.059 88.1C222.059 88.1 222.059 87.9537 222.279 87.773C222.736 87.4247 223.278 87.1965 223.851 87.1104C223.851 87.1104 224.001 87.1706 223.975 87.2308C223.948 87.2911 223.26 88.2721 223.251 88.2721C223.242 88.2721 223.18 88.2721 223.154 88.2721"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2981"
            d="M78.6719 289.769C78.5127 290.692 78.2582 291.597 77.9125 292.471C77.0294 290.561 77.0294 290.526 77.1354 289.786C77.3297 288.598 77.3297 288.598 78.716 287.583C78.788 288.31 78.788 289.042 78.716 289.769"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2982"
            d="M266.306 92.5751C266.106 92.8901 265.94 93.2247 265.811 93.5734C265.737 93.8332 265.627 94.0822 265.485 94.3134C265.299 94.5458 264.875 94.7523 264.796 94.5458C264.716 94.3392 264.61 94.0467 265.114 93.1345C265.467 92.4719 265.909 92.3428 265.997 92.3428C266.124 92.3751 266.246 92.4243 266.359 92.4891L266.306 92.5751Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2983"
            d="M539.533 338.76C539.486 339.104 539.412 339.443 539.312 339.776V339.844C539.25 339.965 539.259 340.103 539.197 340.223C539.197 339.999 539.577 337.745 539.709 337.22C539.682 337.739 539.623 338.256 539.533 338.769"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2984"
            d="M309.619 103.65C309.5 103.785 309.36 103.901 309.204 103.994C309.004 104.008 308.804 104.008 308.604 103.994C308.604 103.994 307.721 103.745 307.721 103.134C307.721 102.919 307.88 102.411 308.304 102.342C308.41 102.331 308.517 102.341 308.619 102.373C308.72 102.404 308.815 102.455 308.895 102.523C309.17 102.841 309.419 103.181 309.637 103.538V103.581"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2985"
            d="M166.56 127.057C166.401 127.177 164.723 127.917 164.008 128.227C164.052 127.943 164.052 127.943 164.087 127.9C164.596 127.357 165.138 126.846 165.712 126.368C166.128 126.067 166.646 125.932 167.16 125.99C167.004 126.369 166.799 126.727 166.551 127.057"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2986"
            d="M488.617 144.13C489.05 144.664 489.906 145.851 489.951 145.963C489.437 145.415 488.954 144.84 488.502 144.242C488.432 144.156 488.361 144.079 488.299 143.993C486.957 142.332 486.957 142.332 486.939 142.271C487.533 142.852 488.09 143.467 488.608 144.113"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2987"
            d="M533.661 335.895C533.661 336.11 533.538 336.815 533.361 338.003C533.023 337.418 532.916 336.732 533.061 336.075C533.123 335.091 533.34 334.121 533.705 333.201C533.801 333.927 533.801 334.661 533.705 335.387C533.705 335.559 533.705 335.723 533.705 335.895"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2988"
            d="M406.32 435.607C406.241 436.336 405.933 437.025 405.437 437.577C405.022 437.99 404.668 437.982 404.359 437.577C404.161 437.252 404.057 436.88 404.057 436.502C404.057 436.123 404.161 435.751 404.359 435.426C404.624 434.901 404.624 434.901 406.178 433.963C406.373 435.168 406.373 435.168 406.267 435.684"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2989"
            d="M302.421 160.257C302.501 162.581 300.655 162.159 300.249 161.746C300.125 161.578 300.052 161.379 300.036 161.173C300.021 160.966 300.064 160.76 300.161 160.576C300.373 160.257 300.77 160.197 301.044 159.973C301.318 159.749 301.512 159.448 301.786 159.224L302.024 159.035C302.293 159.387 302.427 159.819 302.404 160.257"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2990"
            d="M175.204 91.8438C174.913 91.9298 174.542 92.0331 174.728 91.6889C175.063 91.0607 177.456 89.5289 177.977 89.6063C178.083 89.7957 175.24 91.8352 175.204 91.8438Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2991"
            d="M139.203 115.741C138.986 115.793 138.764 115.83 138.541 115.853C138.541 115.853 138.541 115.853 138.312 115.724C138.312 115.526 138.206 115.345 138.267 115.147C138.327 114.981 138.438 114.837 138.585 114.734C138.939 114.45 139.919 114.02 140.272 114.157C140.272 114.157 140.431 114.295 140.272 114.562C140.184 114.699 139.389 115.62 139.327 115.715C139.265 115.81 139.256 115.715 139.23 115.715"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2992"
            d="M178.869 145.654L177.244 146.764C177.385 145.972 177.527 145.18 177.677 144.389C178.701 143.786 178.701 143.786 178.878 143.821C179.672 144.079 179.187 145.438 178.878 145.654"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2993"
            d="M516.504 325.361C516.504 328.545 516.019 328.296 516.01 328.296C515.586 327.566 515.389 326.732 515.445 325.895C515.489 324.853 515.489 324.853 516.231 323.382C516.411 324.027 516.503 324.692 516.504 325.361Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2994"
            d="M370.574 226.545C370.383 226.734 370.155 226.882 369.902 226.982C369.65 227.081 369.38 227.128 369.108 227.122H368.852C368.296 227.018 367.916 226.95 367.086 226.777C367.157 225.805 367.157 225.805 367.43 225.366C367.523 225.208 367.65 225.071 367.802 224.965C367.955 224.86 368.129 224.788 368.313 224.755C368.949 224.704 369.196 224.686 370.636 226.476L370.539 226.545"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2995"
            d="M319.623 186.951C319.12 189.636 319.12 189.636 318.961 189.842C318.802 190.049 318.007 189.92 317.672 189.49C317.062 188.698 317.354 186.529 318.899 186.392C319.252 186.392 319.252 186.392 319.667 186.744C319.667 186.805 319.667 186.891 319.623 186.951Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2996"
            d="M323.384 402.364C323.252 403.224 321.716 405.358 321.124 404.661C320.93 404.429 320.938 404.317 320.991 402.544H320.947V402.217C321.106 401.529 321.274 400.841 321.433 400.144C322.316 400.359 322.44 400.385 322.934 400.944C323.112 401.133 323.244 401.359 323.32 401.605C323.396 401.851 323.415 402.11 323.376 402.364"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2997"
            d="M372.791 239.728C372.791 240.89 371.458 241.312 370.221 240.158C369.804 239.752 369.5 239.249 369.338 238.695C369.206 238.265 369.206 238.265 369.427 237.422C370.427 237.851 371.395 238.349 372.323 238.911C372.486 238.987 372.621 239.111 372.709 239.265C372.798 239.419 372.836 239.596 372.818 239.771"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2998"
            d="M229.713 75.9146C228 76.7149 228 76.7149 227.885 76.6374C227.885 76.4137 228.927 75.665 228.936 75.665C229.175 75.4959 229.461 75.4001 229.757 75.3896L229.88 75.4499H229.933C229.933 75.5876 229.845 75.7253 229.801 75.8629L229.695 75.9146"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_2999"
            d="M78.1776 302.755C77.895 303.615 77.7008 304.14 77.1709 304.39C76.9295 304.456 76.6715 304.433 76.4465 304.325C76.2215 304.217 76.0455 304.032 75.9524 303.805C75.6689 303.185 75.5301 302.512 75.5462 301.834C75.6168 301.584 76.0937 301.412 76.544 301.559C76.9943 301.705 77.0473 301.748 78.2306 302.617L78.1776 302.755Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3000"
            d="M534.597 300.431C534.65 301.516 534.385 303.71 533.996 303.512C533.846 303.426 533.643 301.258 534.049 300.75C534.208 300.56 534.261 300.397 534.173 299.554C534.23 299.425 534.298 299.301 534.376 299.184C534.498 299.595 534.563 300.02 534.57 300.449"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3001"
            d="M281.433 266.595C281.347 266.958 281.154 267.288 280.878 267.545C280.601 267.802 280.253 267.975 279.877 268.041C279.501 268.107 279.113 268.064 278.761 267.917C278.41 267.77 278.111 267.526 277.901 267.214C277.669 266.779 277.497 266.316 277.389 265.837C277.745 265.654 278.13 265.529 278.528 265.467C278.944 265.418 279.365 265.442 279.773 265.536C280.11 265.533 280.447 265.504 280.78 265.45C281.468 265.493 281.53 266.096 281.451 266.595"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3002"
            d="M276.063 362.916C275.939 363.51 274.915 363.51 273.829 363.51C273.767 363.51 273.767 363.51 271.754 362.925C272.195 361.746 272.54 360.808 273.811 360.73C274.052 360.732 274.29 360.782 274.51 360.877C274.73 360.972 274.928 361.109 275.092 361.281C275.092 361.281 276.187 362.366 276.063 362.916Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3003"
            d="M346.3 146.918C346.043 147.113 345.761 147.275 345.461 147.4C345.046 147.49 344.615 147.49 344.199 147.4C343.985 147.268 343.822 147.07 343.737 146.838C343.652 146.605 343.65 146.352 343.731 146.118C343.737 145.923 343.811 145.736 343.941 145.587C344.071 145.439 344.249 145.337 344.446 145.3C344.737 145.291 345.021 145.389 345.241 145.576C345.665 145.98 346.055 146.418 346.406 146.884L346.3 146.944"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3004"
            d="M186.614 119.476C184.945 120.569 184.945 120.569 184.724 120.621C184.503 120.672 184.256 120.448 184.336 120.027C184.383 119.767 184.487 119.52 184.639 119.303C184.791 119.085 184.988 118.9 185.219 118.762C185.28 118.762 185.28 118.762 186.419 118.822L186.711 119.399L186.587 119.476"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3005"
            d="M514.676 413.585C514.42 414.041 514.244 414.342 514.164 414.445C514.164 414.376 514.217 414.187 514.217 414.187C514.217 414.187 515.18 412.38 515.321 412.156C515.331 412.135 515.346 412.118 515.365 412.104C515.321 412.285 514.738 413.456 514.667 413.567"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3006"
            d="M117.48 331.704C117.295 332.315 116.421 333.106 115.908 332.986C115.396 332.865 114.195 330.92 114.195 330.903C114.195 330.886 114.434 330.198 114.672 330.137C114.911 330.077 114.796 330.137 116.438 330.352C117.383 331.058 117.604 331.213 117.454 331.704"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3007"
            d="M144.183 141.368C143.565 141.497 143.477 141.514 143.053 141.282C142.991 141.282 142.973 141.144 142.938 141.093L142.885 140.989L142.77 140.791C142.709 140.697 142.656 140.594 142.594 140.49C143.874 139.466 144.219 139.182 144.704 139.311C145.19 139.44 145.075 139.879 144.272 141.351H144.183"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3008"
            d="M240.593 81.9726V81.9296ZM241.75 81.7402C242.094 81.4046 242.5 80.9313 242.5 80.9313C242.5 80.9313 242.5 80.742 242.438 80.7334C242.003 80.9581 241.606 81.2485 241.264 81.5939C240.831 81.9726 240.646 82.1017 240.593 81.964C240.037 82.5664 240.037 82.5664 240.019 82.6094C240.001 82.6524 240.019 82.7901 240.231 82.7643C240.443 82.7385 240.849 82.6782 241.75 81.7747"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3009"
            d="M176.724 95.2857C176.592 95.3938 176.431 95.4619 176.26 95.4818C176.089 95.5017 175.916 95.4724 175.761 95.3975C175.708 95.234 175.673 95.0705 175.629 94.907C176.98 94.1325 176.98 94.1325 177.042 94.1067C177.266 94.0191 177.515 94.0191 177.739 94.1067C177.942 94.21 177.925 94.2272 177.801 94.3735C177.478 94.7261 177.117 95.0435 176.724 95.3201"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3010"
            d="M296.523 94.6226C296.524 94.9385 296.408 95.2442 296.196 95.4832C296.002 95.6725 295.896 95.6983 295.604 95.4832C295.262 95.2482 295.001 94.9184 294.854 94.5366C294.757 94.2354 295.083 94.0719 295.172 94.0547H295.233C295.331 94.0547 295.419 94.0547 295.507 94.0547H295.613C295.848 94.116 296.075 94.1996 296.293 94.3042C296.35 94.3387 296.4 94.3837 296.44 94.4369C296.479 94.49 296.507 94.5502 296.523 94.614"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3011"
            d="M323.351 183.724C323.294 184.475 323.011 185.193 322.538 185.789C322.441 185.875 322.3 186.013 321.593 185.324C320.887 184.636 320.878 184.378 320.966 184.085C321.116 183.612 321.735 182.614 322.503 182.45C323.386 183.397 323.386 183.397 323.386 183.724"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3012"
            d="M522.544 220.125C523.497 223.034 523.497 223.034 523.621 224.247C522.032 221.76 522.252 220.116 522.261 220.099C522.27 220.082 522.42 220.03 522.491 219.987L522.544 220.125Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3013"
            d="M295.71 260.365C295.534 261.165 295.304 262.163 294.244 262.439C293.185 262.714 292.478 261.742 292.178 261.311C291.878 260.881 291.843 260.451 291.904 260.365C292.066 260.202 292.26 260.071 292.473 259.981C292.687 259.891 292.917 259.843 293.15 259.84C294.028 259.853 294.901 259.981 295.746 260.218V260.365"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3014"
            d="M160.015 92.0757C158.461 92.7986 158.461 92.7986 158.355 92.833C157.958 92.9621 157.949 92.833 157.976 92.7297C158.073 92.4027 159.486 91.4733 159.494 91.4647C159.73 91.4196 159.97 91.4051 160.21 91.4217C160.21 91.4647 160.21 91.4647 160.316 91.525C160.275 91.6982 160.213 91.8659 160.13 92.0241C160.13 92.0241 160.051 92.0241 160.015 92.0757Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3015"
            d="M111.793 304.811C111.034 305.147 111.034 305.147 110.91 305.164C110.787 305.182 110.857 305.164 110.257 305.009C109.807 304.906 109.807 304.906 108.57 303.839C108.886 303.378 109.241 302.944 109.63 302.54C109.713 302.456 109.817 302.394 109.931 302.363C110.046 302.331 110.168 302.33 110.283 302.359C110.819 302.594 111.284 302.958 111.634 303.417C111.634 303.417 111.634 303.417 111.961 304.743L111.838 304.794"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3016"
            d="M523.71 354.939C523.277 356.728 522.659 357.796 522.332 357.322C522.133 356.931 522.069 356.489 522.148 356.059C522.228 355.63 522.447 355.237 522.774 354.939L523.056 354.758C523.285 354.759 523.512 354.782 523.736 354.827V354.939"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3017"
            d="M338.282 135.12C338.441 135.903 338.52 136.29 338.282 136.643C338.043 136.996 337.31 136.772 337.143 136.643C336.691 136.297 336.3 135.881 335.986 135.413C335.862 135.129 335.986 134.957 336.357 134.862L336.595 134.819C337.154 134.773 337.718 134.814 338.264 134.939V135.086"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3018"
            d="M175.363 381.684C174.895 382.083 174.396 382.445 173.871 382.769C173.73 382.855 173.573 382.913 173.409 382.938C173.245 382.963 173.078 382.955 172.917 382.915C172.453 382.763 172.039 382.495 171.714 382.139C171.388 381.783 171.164 381.35 171.062 380.884C171.062 380.712 171.239 380.333 171.38 380.308C171.38 380.308 172.343 380.351 173.667 380.514C174.04 380.534 174.41 380.592 174.771 380.686C174.9 380.801 175.013 380.931 175.107 381.074C175.292 381.323 175.31 381.349 175.495 381.581L175.363 381.684Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3019"
            d="M386.646 421.623C385.931 422.681 385.869 422.767 385.613 422.905C385.357 423.043 385.427 423.008 384.862 423.034C384.297 423.06 384.103 423.826 383.538 423.963C383.114 424.075 382.973 423.567 382.973 423.559C382.902 423.042 383.803 420.874 385.401 420.607C385.665 420.566 385.935 420.602 386.178 420.71C386.421 420.911 386.624 421.153 386.778 421.425L386.646 421.623Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3020"
            d="M336.994 172.003C336.941 172.364 336.773 172.382 335.448 172.476V172.519C335.311 172.538 335.171 172.538 335.033 172.519C334.601 172.416 334.194 172.235 333.779 172.098C333.859 171.736 333.912 171.375 334 171.022C334.029 170.891 334.074 170.764 334.133 170.643C334.188 170.512 334.28 170.398 334.399 170.316C334.518 170.234 334.658 170.186 334.804 170.179C335.952 170.179 337.064 171.427 336.976 171.969"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3021"
            d="M517.82 269.564C517.873 269.865 517.917 270.158 517.97 270.425C517.414 270.149 517.414 270.149 517.158 270.192C516.681 270.27 516.663 270.278 516.169 269.96L515.992 269.857C516.027 269.214 516.097 268.573 516.204 267.938C516.198 267.909 516.198 267.88 516.204 267.852L516.531 267.576C517.652 268.833 517.652 268.85 517.776 269.538"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3022"
            d="M59.8982 295.311C59.8326 295.419 59.7556 295.52 59.6686 295.612C59.1369 294.752 58.6706 293.854 58.2734 292.928C58.3548 292.429 58.5188 291.946 58.7591 291.499C59.257 291.742 59.6374 292.165 59.8187 292.678C59.9568 293.549 59.9805 294.434 59.8894 295.311"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3023"
            d="M189.332 85.8622C188.741 85.931 188.741 85.931 188.661 85.8622L188.608 85.8191C188.603 85.6994 188.621 85.5797 188.661 85.4663C188.75 85.3399 188.872 85.2389 189.014 85.1737C189.578 84.8458 190.202 84.6267 190.851 84.5283C190.851 84.5283 190.957 84.6574 190.648 84.95C190.339 85.2426 189.438 85.8105 189.429 85.8105H189.332"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3024"
            d="M458.7 110.982C459.353 111.575 460.342 112.479 461.349 113.391C458.7 110.956 458.7 110.956 458.391 110.689L458.32 110.62C458.32 110.62 458.417 110.689 458.735 110.982"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3025"
            d="M389.542 112.849H389.604L389.516 112.772M388.359 111.309C387.476 110.448 386.593 110.156 386.293 110.293C385.842 110.68 387.176 111.705 387.6 111.73C388.024 111.756 388.341 111.73 389.489 112.729C389.171 112.214 388.792 111.737 388.359 111.309Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3026"
            d="M376.085 111.456C376.553 112.635 376.615 112.789 376.218 112.617C375.586 112.287 375.011 111.863 374.513 111.361C374.384 111.228 374.271 111.081 374.178 110.922C374.01 110.638 373.878 110.32 373.719 110.062C376.05 111.404 376.05 111.404 376.085 111.49"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3027"
            d="M511.825 212.587C512.063 213.198 512.195 214.523 511.913 214.703C511.489 214.006 510.2 211.898 510.677 211.261C510.88 211.072 511.683 212.277 511.825 212.621"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3028"
            d="M111.432 242.009L111.502 241.931C111.492 241.89 111.477 241.85 111.458 241.811V242.009M114.037 238.515L114.16 238.36C113.869 238.308 113.613 238.162 113.277 238.085C112.491 237.938 111.697 238.945 111.458 239.436C111.295 239.811 111.211 240.215 111.211 240.623C111.211 241.031 111.295 241.435 111.458 241.811C111.505 241.48 111.632 241.164 111.829 240.89C111.994 240.669 112.193 240.474 112.421 240.314C113.038 239.793 113.573 239.187 114.01 238.515"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3029"
            d="M536.452 333.296C536.393 333.998 536.195 334.681 535.869 335.31C535.734 334.744 535.734 334.155 535.869 333.589C535.92 333.193 536.092 332.821 536.364 332.521C536.442 332.632 536.495 332.758 536.518 332.89C536.54 333.022 536.533 333.158 536.496 333.287"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3030"
            d="M167.61 90.9143C166.954 91.3236 166.24 91.6368 165.49 91.8437C166.106 91.3282 166.775 90.8754 167.486 90.4926C168.298 90.0279 168.625 89.8902 168.784 89.9591C168.468 90.3559 168.064 90.6768 167.601 90.8971"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3031"
            d="M366.573 124.243C366.035 124.544 366.035 124.544 365.938 124.536C365.584 124.426 365.252 124.257 364.958 124.036C364.218 123.541 363.669 122.821 363.395 121.988C364.547 122.628 365.638 123.368 366.653 124.2L366.573 124.243Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3032"
            d="M262.104 276.939C261.927 277.877 261.556 278.1 260.188 278.918C259.868 278.046 259.795 277.107 259.976 276.199C260.27 275.711 260.749 275.356 261.309 275.209C261.698 275.209 262.236 276.259 262.104 276.93"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3033"
            d="M162.727 168.802C162.577 169.017 162.577 169.017 160.378 170.618C159.875 169.929 159.875 169.929 160.299 169.456C161.228 168.689 162.22 167.999 163.266 167.391C162.754 168.768 162.754 168.768 162.727 168.802Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3034"
            d="M358.698 98.3745C358.954 98.6412 359.483 99.4846 358.795 99.476C358.309 99.476 357.029 98.2626 357.294 98.0044C357.357 97.9531 357.433 97.9177 357.514 97.9012C357.753 97.8409 357.753 97.8409 358.15 98.0044C358.359 98.0852 358.547 98.2118 358.698 98.3745Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3035"
            d="M413.41 420.039C413.367 420.571 413.15 421.074 412.792 421.476C412.792 421.476 412.023 422.079 411.697 421.623C410.814 420.427 412.067 418.723 413.03 418.499C413.304 418.576 413.507 419.273 413.507 419.282C413.507 419.291 413.507 419.669 413.41 420.039Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3036"
            d="M149.022 204.705H148.942V204.834L149.004 204.713M148.58 203.319L148.624 203.44C148.641 203.4 148.656 203.36 148.668 203.319H148.58ZM150.231 203.775C150.515 203.219 150.617 202.591 150.523 201.977C150.373 201.34 150.108 201.254 149.64 201.116H149.534L149.128 201.021V201.202C149.135 201.925 148.994 202.642 148.713 203.311C149.657 203.311 149.392 204.042 149.048 204.705C149.56 204.705 149.869 204.705 150.275 203.767"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3037"
            d="M531.312 277.145C531.683 279.537 531.312 279.726 531.259 279.778C530.897 279.503 530.455 276.663 530.906 275.966C531.047 276.009 531.241 276.826 531.294 277.153"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3038"
            d="M93.7172 275.949C93.523 276.448 92.8342 276.457 92.8342 276.457C92.8342 276.457 91.9512 276.345 91.6245 275.803C91.4604 275.348 91.4825 274.848 91.6863 274.409C91.8099 274.194 92.3397 273.772 92.7459 273.892C93.1113 274.09 93.4031 274.396 93.5792 274.765C93.7553 275.135 93.8067 275.549 93.7261 275.949"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3039"
            d="M158.904 114.992C158.448 115.397 157.94 115.744 157.394 116.024C157.164 116.145 156.988 116.231 157.014 115.947C157.939 114.982 158.924 114.074 159.964 113.228C159.964 113.228 159.964 113.228 160.326 113.09V113.185C159.988 113.885 159.493 114.503 158.878 114.992"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3040"
            d="M523.48 338.872C523.144 340.094 523.082 340.309 522.817 339.87C522.748 339.741 522.7 339.601 522.676 339.457C522.597 338.992 522.729 338.536 522.676 338.072C522.676 337.814 522.42 337.624 522.411 337.357C522.399 337.046 522.503 336.74 522.703 336.497C523.02 336.333 523.365 336.91 523.374 336.953C523.513 337.574 523.549 338.214 523.48 338.846"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3041"
            d="M457.34 287.816C457.34 288.168 457.34 288.521 457.34 288.874C456.566 288.323 455.842 287.706 455.177 287.032C454.915 286.784 454.7 286.493 454.541 286.172C454.965 285.561 454.965 285.561 455.124 285.518C456.89 285.131 457.331 287.798 457.331 287.824"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3042"
            d="M137.367 88.59C135.999 89.3903 135.372 89.7087 135.266 89.6313C135.363 89.201 138.206 88.0565 138.233 88.0479C137.588 88.4609 137.588 88.4609 137.35 88.59"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3043"
            d="M452.715 283.952C452.715 284.15 452.503 284.262 452.397 284.417L451.275 283.177L450.392 282.196C450.172 282.014 449.978 281.803 449.818 281.568C449.827 281.407 449.882 281.252 449.977 281.121C450.153 280.991 450.355 280.897 450.569 280.845C450.819 280.786 451.082 280.812 451.315 280.919C451.547 281.025 451.736 281.206 451.849 281.431C452.255 282.23 452.552 283.078 452.732 283.952"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3044"
            d="M77.4357 160.911C77.2768 161.065 77.1267 161.229 76.9766 161.384C77.3646 160.145 77.9199 158.961 78.6278 157.864C78.8927 157.864 78.8927 157.864 78.9192 157.864C79.2636 158.553 77.5594 160.773 77.4357 160.911Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3045"
            d="M285.582 362.365C285.563 362.661 285.432 362.939 285.216 363.147C284.999 363.355 284.711 363.478 284.408 363.492C284.047 363.611 283.658 363.624 283.29 363.531C282.921 363.438 282.589 363.242 282.332 362.968C281.856 362.305 283.048 360.937 284.178 360.851C284.178 360.851 285.697 361.066 285.582 362.365Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3046"
            d="M520.185 275.553C520.3 276.328 520.477 277.498 520.035 277.223C519.594 276.947 518.649 274.271 519.223 273.66C519.391 273.583 519.7 273.849 519.841 274.125C519.991 274.592 520.107 275.07 520.185 275.553Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3047"
            d="M107.617 195.849C107.458 196.021 105.851 197.329 105.445 197.622C105.401 197.622 105.4 197.622 104.959 197.811C105.031 196.998 105.39 196.235 105.974 195.651C106.319 195.264 106.999 194.653 107.626 194.842C107.626 194.842 107.891 194.954 107.891 195.066C107.856 195.345 107.763 195.615 107.617 195.858"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3048"
            d="M483.928 327.745C483.928 328.812 483.266 330.938 482.771 330.241C482.771 330.181 482.568 325.938 482.568 325.869C482.568 325.8 482.568 325.869 482.851 325.508C483.866 325.938 483.928 327.444 483.928 327.745Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3049"
            d="M308.895 252.094C308.895 252.903 308.745 253.29 308.321 253.454C307.818 253.591 307.297 253.649 306.775 253.626C306.49 253.511 306.218 253.366 305.963 253.196C305.204 252.507 306.608 251.087 307.809 251.01C308.736 251.01 308.859 251.612 308.868 252.077"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3050"
            d="M374.371 290.2C374.371 290.2 374.415 292.652 373.073 291.413C371.58 290.028 371.89 288.16 372.949 288.031C373.753 288.031 374.247 288.341 374.371 290.2Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3051"
            d="M220.963 76.6807C220.392 77.027 219.785 77.3153 219.153 77.5412C218.809 77.6445 218.774 77.6359 218.756 77.6273C218.738 77.6187 218.756 77.4724 218.756 77.3863C219.775 76.8704 220.835 76.4359 221.926 76.0869C221.64 76.3287 221.326 76.5366 220.99 76.7065"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3052"
            d="M150.311 496.731C150.126 497.196 149.958 497.591 149.79 498.013C149.437 497.901 148.227 496.61 148.837 496.292C149.157 496.215 149.49 496.198 149.817 496.24C150.001 496.367 150.173 496.512 150.329 496.671"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3053"
            d="M384.305 88.9428C384.385 88.9945 384.817 89.3387 384.826 89.4506C384.34 89.396 383.868 89.2591 383.431 89.0461L382.751 88.6933C382.751 88.6933 382.477 88.3491 382.504 88.306C382.53 88.263 382.601 88.2544 382.645 88.2286C382.689 88.2028 382.645 88.2286 382.645 88.1855C383.221 88.3744 383.772 88.6285 384.287 88.9428"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3054"
            d="M367.059 84.7785C367.35 84.9678 367.359 85.0367 367.395 85.2088C367.271 85.3121 367.086 85.4067 366.512 85.1055C365.514 84.6064 365.099 84.1503 365.108 84.0557C365.804 84.1726 366.468 84.4274 367.059 84.8043"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3055"
            d="M412.315 77.6617C412.758 77.889 413.188 78.1389 413.604 78.4104C412.872 78.0573 412.162 77.6637 411.476 77.2314H411.432C411.734 77.3713 412.029 77.5264 412.315 77.6961"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3056"
            d="M75.59 158.725C75.8395 157.783 76.2483 156.887 76.7998 156.075C76.994 155.748 76.994 155.748 77.5503 155.464C77.3917 155.948 77.1969 156.419 76.9675 156.875C76.8881 157.004 76.8881 157.004 75.5547 158.854C75.5547 158.811 75.5547 158.76 75.5547 158.725"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3057"
            d="M262.934 96.8089C262.731 97.4715 262.731 97.4715 262.616 97.6092C262.501 97.7469 262.174 98.0825 262.033 97.8674C261.959 97.694 261.926 97.5069 261.935 97.3195C261.944 97.132 261.996 96.9489 262.086 96.7831C262.292 96.3684 262.595 96.0061 262.969 95.7246C263.11 95.7246 263.252 95.7935 262.969 96.8089"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3058"
            d="M183.24 133.718C182.931 134.096 181.854 134.75 181.73 134.096C181.73 133.89 181.73 133.847 181.96 132.866L182.666 132.487C182.719 132.451 182.775 132.419 182.834 132.393C182.968 132.401 183.101 132.418 183.232 132.444C184.115 132.668 183.452 133.46 183.232 133.718"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3059"
            d="M113.993 212.716C113.534 213.619 111.927 214.29 111.644 213.499C111.621 212.804 111.878 212.127 112.359 211.614C112.472 211.498 112.628 211.43 112.792 211.425C113.09 211.452 113.386 211.504 113.675 211.58C113.819 211.761 113.938 211.961 114.028 212.173C114.056 212.264 114.063 212.358 114.051 212.452C114.039 212.545 114.007 212.635 113.958 212.716"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3060"
            d="M520.708 235.357C521.008 236.26 521.008 236.26 520.849 236.544C519.719 234.866 519.719 234.866 519.242 232.078L519.498 232.285C519.517 233.101 519.802 233.891 520.311 234.539C520.51 234.795 520.654 235.088 520.735 235.4"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3061"
            d="M91.316 287.601C91.2366 287.816 91.0865 287.807 90.8392 287.713C90.592 287.618 90.4595 287.644 90.38 287.79C90.1681 287.678 89.9473 287.601 89.7354 287.48C89.6715 287.441 89.6148 287.391 89.5676 287.334C89.4737 287.099 89.4114 286.853 89.3822 286.602C89.2409 285.535 89.3822 285.157 90.0092 284.933C90.0795 284.925 90.1507 284.925 90.2211 284.933C90.3535 284.985 91.7311 286.542 91.316 287.626"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3062"
            d="M355.165 111.43C355.402 111.683 355.557 111.999 355.609 112.337C355.661 112.676 355.608 113.022 355.457 113.332L354.944 113.082L354.768 112.996C354.768 112.996 354.697 112.996 354.679 112.953C354.662 112.91 354.309 111.8 354.309 111.791C354.309 111.628 354.309 111.619 354.309 111.593C354.309 111.567 354.397 111.533 354.309 111.344C354.224 111.152 354.156 110.953 354.105 110.75C354.105 110.681 354.105 110.672 354.105 110.655C354.503 110.806 354.841 111.078 355.068 111.43"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3063"
            d="M417.226 91.4648C417.946 91.8407 418.592 92.3391 419.133 92.9364C417.915 92.2651 416.237 91.3444 416.148 91.0087C416.148 91.0087 416.387 90.9657 417.226 91.4648Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3064"
            d="M268.912 89.8987C268.873 90.0909 268.759 90.261 268.595 90.3733C268.43 90.4857 268.227 90.5314 268.029 90.5011C267.781 90.131 267.755 90.088 267.896 89.7782C268.037 89.4684 268.399 89.0295 268.717 88.9951C268.832 88.9951 269.088 89.3307 268.938 89.8987"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3065"
            d="M381.322 95.2851C381.64 95.5518 382.408 96.421 381.966 96.4296C381.427 96.2607 380.939 95.9652 380.545 95.569C379.9 95.0785 379.988 94.9667 380.033 94.9064L380.218 94.7773C380.916 94.9581 381.022 94.9839 381.322 95.242"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3066"
            d="M138.628 144.079C138.433 144.35 138.168 144.566 137.86 144.707C137.701 144.759 137.701 144.759 137.074 144.629C137.059 144.397 137.059 144.164 137.074 143.932C137.074 143.889 137.074 143.889 137.375 143.614C138.125 142.9 138.69 142.607 139.052 142.753C139.185 142.822 139.556 142.994 138.611 144.096"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3067"
            d="M313.937 260.932C313.451 261.793 313.283 262.034 312.727 261.965C312.355 261.891 312.008 261.725 311.719 261.485C311.43 261.244 311.209 260.935 311.076 260.588C311.01 260.447 311 260.288 311.047 260.14C311.094 259.993 311.196 259.867 311.332 259.788C311.501 259.64 311.7 259.529 311.916 259.46C312.132 259.392 312.36 259.369 312.586 259.392C312.764 259.371 312.944 259.386 313.116 259.436C313.288 259.487 313.447 259.571 313.584 259.685C313.739 259.854 313.848 260.058 313.901 260.278C314.034 260.76 314.034 260.76 313.901 260.932"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3068"
            d="M191.982 102.876C191.347 103.34 190.932 103.641 190.932 103.435C190.932 102.91 192.601 101.163 193.342 101.335C193.678 101.49 192 102.867 191.982 102.876Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3069"
            d="M105.895 322.857C105.604 323.15 105.33 323.468 105.012 323.718C104.865 323.855 104.698 323.971 104.517 324.062C104.421 324.099 104.314 324.099 104.217 324.062C103.961 323.95 103.749 323.77 103.502 323.641C103.581 323.21 103.626 322.78 103.714 322.341C103.734 322.164 103.788 321.992 103.873 321.833C103.935 321.739 104.208 321.67 104.659 321.773C105.029 321.883 105.391 322.016 105.745 322.169C105.839 322.357 105.916 322.553 105.974 322.754L105.886 322.849"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3070"
            d="M319.324 162.408C318.732 163.355 318.335 163.983 317.832 164.706L317.496 164.482C317.549 162.589 317.567 161.72 318.379 161.634C318.821 161.634 318.821 161.634 319.333 162.331L319.289 162.408"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3071"
            d="M520.795 217.88C521.096 218.595 521.307 219.343 521.422 220.108C521.501 220.771 521.422 220.762 521.104 220.685C520.741 219.424 520.447 218.146 520.221 216.855C520.431 217.181 520.611 217.523 520.759 217.88"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3072"
            d="M509.934 420.986C509.934 420.986 508.759 423.042 507.982 424.17C508.548 422.999 509.212 421.876 509.969 420.813C509.969 420.874 509.969 420.874 509.969 420.986"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3073"
            d="M296.762 179.671C296.762 180.704 295.994 180.876 295.738 180.532C295.624 180.22 295.624 179.879 295.738 179.568C295.888 179.069 295.985 178.587 295.676 178.397C295.517 178.324 295.341 178.288 295.164 178.294C294.994 178.307 294.824 178.267 294.679 178.179C294.534 178.09 294.423 177.959 294.361 177.804C294.343 177.78 294.328 177.754 294.316 177.726C294.263 177.546 294.316 177.52 294.661 177.115H294.705C295.959 177.115 296.736 178.415 296.762 179.645"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3074"
            d="M524.884 251.398C524.981 252.186 525.022 252.979 525.008 253.773C525.005 253.846 524.977 253.915 524.928 253.971C524.814 254.057 524.664 254.057 524.549 254.126C524.257 250.683 524.434 250.443 524.505 250.356C524.677 250.686 524.804 251.036 524.884 251.398Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3075"
            d="M118.047 440.28C118.047 441.312 118.047 441.312 117.994 441.424C117.92 441.518 117.818 441.587 117.702 441.622C117.508 441.699 117.287 441.717 117.084 441.785C116.74 440.271 116.731 440.245 116.757 440.064C116.784 439.884 117.102 439.316 117.393 439.35C117.693 439.6 117.919 439.924 118.047 440.288"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3076"
            d="M519.47 211.76C519.728 212.485 519.92 213.231 520.044 213.989C520.044 214.127 520.044 214.178 519.991 214.23C519.938 214.282 519.797 214.29 519.709 214.342C518.914 210.9 518.914 210.9 518.967 210.779C519.162 211.092 519.33 211.42 519.47 211.76Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3077"
            d="M349.063 139.63C348.895 139.81 348.737 140 348.578 140.189C348.33 140.051 348.065 139.948 347.818 139.802C347.672 139.716 347.533 139.618 347.403 139.509C347.097 139.148 346.828 138.759 346.6 138.347C346.794 138.347 346.979 138.27 347.174 138.244C347.544 138.244 347.677 138.287 347.686 138.296C347.96 138.524 348.219 138.768 348.463 139.027C348.675 139.208 348.904 139.38 349.125 139.552L349.063 139.63Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3078"
            d="M202.578 82.5324C202.013 82.868 201.263 83.2897 201.298 83.1176C201.647 82.6886 202.04 82.2963 202.473 81.9473C202.601 81.8908 202.738 81.8559 202.879 81.844L203.55 81.6719C203.691 81.801 203.435 82.0247 202.578 82.5324Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3079"
            d="M170.515 86.086C170.312 86.1893 168.52 86.8863 168.316 86.9466C168.316 86.7573 169.641 86.086 169.65 86.086C169.659 86.086 171.495 85.346 171.61 85.3546C171.277 85.631 170.909 85.8652 170.515 86.0516"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3080"
            d="M503.815 429.351C503.367 430.133 502.837 430.869 502.234 431.545C502.645 430.649 503.193 429.819 503.859 429.084C503.912 429.032 503.93 429.024 503.956 429.032C503.92 429.143 503.873 429.249 503.815 429.351Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3081"
            d="M516.318 338.441C516.286 337.6 516.128 336.768 515.85 335.972L515.788 336.144C515.486 336.847 515.333 337.602 515.338 338.364C515.393 338.741 515.51 339.107 515.682 339.448C515.682 339.517 515.682 339.517 516.124 339.698C516.186 339.276 516.283 338.837 516.318 338.441ZM515.85 335.954C515.85 335.954 515.85 335.903 515.85 335.877V335.963"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3082"
            d="M245.847 133.701C245.763 133.766 245.666 133.814 245.562 133.842C245.459 133.871 245.35 133.879 245.244 133.866C245.137 133.853 245.034 133.82 244.94 133.768C244.847 133.716 244.765 133.646 244.699 133.563C244.549 133.333 244.451 133.073 244.415 132.803C244.378 132.532 244.403 132.257 244.487 131.997C244.646 131.351 244.717 131.291 245.052 131.024C245.097 131.11 245.176 131.179 245.185 131.283C245.171 131.41 245.139 131.535 245.088 131.653C245.004 131.932 244.98 132.225 245.017 132.513C245.046 132.696 245.134 132.866 245.269 132.996C245.405 133.126 245.579 133.21 245.768 133.236C245.856 133.236 245.856 133.236 245.874 133.305C245.887 133.425 245.887 133.546 245.874 133.666H245.829"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3083"
            d="M345.833 253.48C345.065 254.065 345.065 254.065 344.95 254.074C344.709 253.994 344.483 253.877 344.279 253.73C343.955 253.507 343.697 253.206 343.531 252.855C343.365 252.504 343.297 252.118 343.334 251.733C343.391 251.402 343.48 251.076 343.599 250.761C344.19 251.122 344.19 251.122 344.27 251.208C344.349 251.294 344.349 251.294 345.948 253.368L345.798 253.48"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3084"
            d="M419.654 332.108C419.451 332.504 419.301 332.917 419.063 333.287C418.961 333.475 418.812 333.635 418.63 333.752C418.108 334.006 417.542 334.164 416.961 334.216C416.899 334.216 416.793 334.036 416.749 333.881C416.581 333.27 417.897 331.738 418.798 331.497C418.897 331.478 419 331.494 419.089 331.54C419.31 331.676 419.522 331.826 419.725 331.988C419.705 332.029 419.681 332.07 419.654 332.108Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3085"
            d="M469.359 124.174C470.171 124.966 470.242 125.155 470.304 125.396C470.078 125.283 469.874 125.132 469.703 124.949C469.279 124.582 468.913 124.155 468.617 123.684C468.617 123.684 468.617 123.684 468.617 123.632C468.617 123.58 468.838 123.684 469.35 124.174"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3086"
            d="M67.7752 303.873C67.6251 303.873 67.2365 303.873 66.9805 303.28C66.8666 303 66.7751 302.712 66.7067 302.419C66.65 302.18 66.5793 301.945 66.4948 301.713C66.4913 301.694 66.4913 301.673 66.4948 301.653C66.4948 301.498 66.5831 301.3 66.6272 301.146C66.7912 301.22 66.9592 301.286 67.1306 301.343C67.89 301.653 67.8988 301.765 68.1284 303.572C68.0313 303.667 67.9253 303.839 67.8017 303.899"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3087"
            d="M515.859 335.903V335.981V335.929M517.343 335.748L517.405 335.611C517.272 335.31 517.184 335 517.051 334.699C517.007 334.588 516.948 334.484 516.875 334.389C516.808 334.313 516.714 334.265 516.612 334.256C516.51 334.246 516.408 334.275 516.327 334.337C516.036 334.569 515.895 335.034 515.859 335.929C516.84 336.618 516.84 336.618 516.954 336.6C517.069 336.583 516.998 336.6 517.343 335.74"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3088"
            d="M154.126 366.134C154.102 366.376 154.021 366.608 153.888 366.814C153.605 367.278 153.235 367.674 152.926 368.139C152.678 367.562 152.369 367.02 152.157 366.418C152.062 366.141 152.02 365.849 152.034 365.557C152.034 364.508 152.643 364.568 152.917 364.585C153.102 364.602 153.281 364.655 153.445 364.741C153.608 364.827 153.752 364.944 153.868 365.085C153.984 365.227 154.07 365.389 154.121 365.563C154.171 365.738 154.185 365.92 154.162 366.1"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3089"
            d="M355.138 137.374C355.138 137.374 355.085 137.951 354.918 138.037C354.791 138.093 354.65 138.11 354.513 138.087C354.376 138.064 354.25 138.002 354.149 137.908C353.567 137.435 353.346 136.187 353.964 136.084C354.582 135.98 355.138 137.366 355.138 137.374Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3090"
            d="M80.005 155.369C79.6959 155.73 79.3516 155.988 79.2898 155.73C79.2898 155.653 79.2191 155.446 80.5083 153.837C80.5414 153.803 80.5768 153.772 80.6143 153.742L81.5591 152.882C81.1137 153.744 80.5937 154.567 80.005 155.343"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3091"
            d="M96.6153 300.957C96.4034 301.318 96.2003 301.37 96.0943 301.37C96.0211 301.379 95.9469 301.379 95.8736 301.37C95.6175 301.301 95.4056 301.146 95.1495 301.069C94.8934 300.991 94.8051 301.069 94.6373 300.991H94.549C93.9398 300.785 93.9398 300.785 93.666 299.993C94.0899 299.623 94.0899 299.623 94.187 299.623C94.2841 299.623 94.293 299.623 95.2378 299.89L95.3173 299.657C95.7765 300.045 96.2533 300.423 96.7213 300.802L96.6153 300.957Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3092"
            d="M182.983 88.6678C182.738 88.8374 182.467 88.9681 182.18 89.055C183.61 87.5404 184.52 87.1876 184.926 87.1016C184.926 87.3683 183.778 88.1945 182.983 88.6678Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3093"
            d="M235.418 77.9108C235.418 77.9108 235.507 77.9108 235.524 77.842C235.672 77.5633 235.802 77.2759 235.913 76.9814H235.869C235.701 77.0417 235.242 77.2654 235.242 77.2654L234.579 78.0399C234.491 78.1604 234.482 78.2551 234.579 78.2809C234.676 78.3067 234.835 78.2809 235.462 77.8764"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3094"
            d="M148.952 204.833V204.704L148.908 204.824H148.961M148.793 205.1C148.837 205.014 148.873 204.902 148.908 204.816C148.087 204.738 148.29 204.024 148.563 203.413H148.422C148.102 203.331 147.773 203.285 147.442 203.275C147.248 203.275 146.912 203.74 146.895 203.775C146.968 204.213 147.069 204.647 147.195 205.074C147.254 205.313 147.372 205.535 147.539 205.719C147.901 206.038 148.343 205.9 148.669 205.358C148.718 205.284 148.759 205.206 148.793 205.126M148.652 203.448L148.608 203.327C148.589 203.36 148.574 203.394 148.563 203.43H148.652"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3095"
            d="M325.523 195.281L325.452 195.204L325.514 195.247M325.443 195.333C325.439 195.29 325.439 195.247 325.443 195.204C323.933 194.283 323.368 193.939 323.156 193.999C322.926 194.192 322.721 194.411 322.547 194.653C322.944 195.049 323.262 195.513 323.686 195.866C323.879 196.049 324.121 196.174 324.384 196.228C324.825 196.34 325.337 196.159 325.434 195.367"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3096"
            d="M247.948 151.652C247.524 152.745 246.544 152.082 246.27 151.48C246.145 151.21 246.108 150.91 246.164 150.619C246.195 150.527 246.25 150.444 246.323 150.378C246.491 150.189 246.685 150.025 246.862 149.836C247.224 150.43 247.612 151.006 247.957 151.609"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3097"
            d="M504.406 293.969C504.423 294.258 504.394 294.549 504.318 294.829C504.203 295.285 503.991 295.69 503.841 296.163C503.585 295.948 503.303 295.776 503.047 295.552C502.983 295.497 502.937 295.426 502.914 295.346C502.914 294.872 502.914 293.728 503.665 293.315C503.991 293.169 504.345 293.315 504.406 293.952"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3098"
            d="M371.572 126.205C371.572 126.205 371.634 126.256 371.634 126.291C371.634 126.325 371.634 126.721 371.634 126.764C371.634 126.807 371.51 126.902 371.457 126.893C370.866 126.807 369.576 125.611 370.009 125.224C370.062 125.188 370.122 125.165 370.186 125.155C370.468 125.103 370.848 125.155 371.572 126.205Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3099"
            d="M235.249 81.0952L235.338 81.0435C235.338 81.0435 235.338 81.0435 235.249 81.0952ZM237.015 79.8474C237.236 79.6476 237.436 79.4287 237.616 79.1934L237.51 79.2536C236.888 79.5615 236.297 79.9244 235.744 80.3379C235.258 80.7337 235.073 80.9919 235.258 81.0349C235.875 80.681 236.466 80.2842 237.024 79.8474"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3100"
            d="M332.666 472.437C332.615 472.546 332.539 472.641 332.444 472.715C332.349 472.79 332.238 472.842 332.119 472.868C331.658 472.849 331.201 472.777 330.759 472.653C330.432 472.549 330.193 472.076 329.955 471.62L330.767 471.241C331.024 471.301 331.297 471.319 331.562 471.387C331.653 471.409 331.739 471.443 331.818 471.491C331.898 471.542 331.942 471.637 332.021 471.697C332.234 471.817 332.435 471.955 332.622 472.11C332.663 472.154 332.69 472.208 332.698 472.267C332.706 472.326 332.694 472.385 332.666 472.437Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3101"
            d="M142.258 222.345C141.278 223.352 140.951 223.688 140.659 223.808C140.585 223.844 140.504 223.862 140.421 223.862C140.338 223.862 140.257 223.844 140.183 223.808C139.67 223.619 139.67 223.567 139.662 223.455C139.653 223.343 139.662 222.784 140.368 222.208C140.579 222.025 140.825 221.886 141.092 221.797C141.359 221.709 141.641 221.673 141.922 221.691C142.328 221.786 142.496 222.122 142.258 222.371"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3102"
            d="M88.7291 282.498C88.1997 282.943 87.6455 283.36 87.069 283.746C86.5039 283.203 86.5039 283.203 86.5039 283.169C86.5646 282.902 86.6626 282.644 86.7953 282.403C86.7953 282.334 86.9278 282.3 86.9807 282.257C87.5547 281.577 87.8638 281.284 88.2611 281.396C88.9499 281.646 88.738 282.506 88.7291 282.515"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3103"
            d="M471.656 124.002C472.018 124.344 472.358 124.709 472.671 125.095C472.073 124.551 471.504 123.976 470.967 123.374C471.228 123.561 471.465 123.777 471.673 124.019"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3104"
            d="M139.794 110.224C139.529 110.371 139.255 110.5 138.973 110.612C139.167 110.345 139.317 110.052 139.52 109.794C139.645 109.633 139.784 109.484 139.935 109.347C140.363 108.945 140.859 108.619 141.401 108.383C140.924 108.968 140.518 109.57 139.988 110.104C139.988 110.156 139.856 110.164 139.794 110.19"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3105"
            d="M196.396 88.7539C195.884 89.0723 195.61 89.0379 195.628 88.9088C195.95 88.4671 196.33 88.0679 196.758 87.7213C196.758 87.7213 197.279 87.4717 197.403 87.5148C197.818 87.7127 196.405 88.7453 196.396 88.7539Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3106"
            d="M145.782 99.9579H145.729C145.597 99.8547 145.509 99.7858 146.356 98.9941C146.624 98.7118 146.976 98.519 147.363 98.4434C147.319 98.5466 147.319 98.6585 147.275 98.7532C147.228 98.8489 147.166 98.9362 147.089 99.0113C147.036 99.063 147.036 99.063 145.791 100.027"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3107"
            d="M339.13 85.0793C339.325 85.3718 339.51 85.673 339.687 85.9398C339.305 85.8033 338.934 85.6423 338.574 85.4579C338.574 85.4579 337.691 84.6748 337.965 84.5371C338.053 84.5371 338.115 84.5371 338.3 84.6146C339.033 84.9588 339.077 85.0448 339.095 85.0707"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3108"
            d="M142.196 120.853C142.032 120.835 141.872 120.794 141.719 120.732C141.68 120.711 141.649 120.677 141.631 120.637C141.614 120.596 141.61 120.551 141.622 120.509C141.907 120.007 142.251 119.539 142.646 119.115C142.894 118.968 143.167 118.882 143.423 118.762L143.026 119.622C142.907 119.916 142.771 120.203 142.62 120.483C142.54 120.621 142.329 120.715 142.196 120.818"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3109"
            d="M381.647 100.062C381.873 100.255 382.075 100.475 382.247 100.716C382.246 100.939 382.217 101.162 382.159 101.378C381.946 101.253 381.745 101.109 381.559 100.948C381.559 100.948 381.453 100.802 381.011 100.087L380.711 99.6055C381.488 99.9153 381.488 99.9153 381.647 100.053"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3110"
            d="M211.762 78.9352C211.417 79.0643 211.417 79.0643 211.382 79.0643C211.347 79.0643 211.135 79.0643 211.17 78.9438C211.455 78.6535 211.768 78.3912 212.106 78.1608C212.315 78.0461 212.534 77.9482 212.759 77.8682C212.759 77.9284 212.812 78.0317 212.636 78.2296C212.37 78.4973 212.074 78.7341 211.753 78.9352"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3111"
            d="M166.576 171.014C166.375 171.172 166.152 171.302 165.914 171.401C165.914 170.755 166.046 169.267 166.117 169C166.117 168.871 166.559 168.415 166.726 168.441C167.283 168.638 166.771 170.807 166.576 171.022"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3112"
            d="M167.884 157.804C167.791 157.927 167.681 158.037 167.558 158.131C167.222 158.415 166.86 158.656 166.516 158.932C166.586 158.545 166.613 158.149 166.701 157.753C166.741 157.569 166.804 157.39 166.886 157.219C167.134 156.746 167.443 156.505 167.77 156.557C168.096 156.608 168.114 157.486 167.893 157.804"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3113"
            d="M181.942 86.3619C180.768 87.0331 180.662 86.9213 180.679 86.8352C180.798 86.6858 180.931 86.5475 181.077 86.4221C181.077 86.4221 182.604 85.8026 182.684 85.7939C182.613 85.9747 182.225 86.1984 181.942 86.3619Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3114"
            d="M240.954 310.595C240.924 311.087 240.746 311.56 240.442 311.954C239.85 312.703 239.488 312.755 239.206 312.815C238.844 311.997 238.729 311.748 238.959 311.094C239.048 310.859 239.183 310.643 239.356 310.457C239.683 310.07 240.106 309.777 240.451 309.407C240.989 310.001 240.989 310.001 240.954 310.595Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3115"
            d="M469.748 130.068C470.773 131.566 470.764 131.583 470.755 131.617C470.561 131.686 469.872 130.8 469.828 130.757C469.608 130.422 469.416 130.071 469.254 129.707C469.407 129.782 469.555 129.869 469.695 129.965C469.73 130.002 469.759 130.042 469.784 130.086"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3116"
            d="M154.471 94.4254C155.001 94.1069 155.593 93.6681 156.237 93.212L156.079 93.298C153.677 94.4942 152.873 94.8987 152.935 95.0966C153.494 94.9695 154.024 94.7419 154.498 94.4254M156.335 93.1689H156.264H156.361"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3117"
            d="M325.098 88.4446C325.212 88.7199 325.318 88.9953 325.424 89.3051L324.082 88.4446C324.082 88.2466 324.082 88.0573 324.082 87.8594C324.418 88.0401 324.771 88.2122 325.106 88.4101C325.106 88.4101 325.106 88.4101 325.106 88.4618"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3118"
            d="M423.026 282.722C422.743 283.341 422.681 283.479 422.028 283.315C421.692 283.233 421.377 283.082 421.105 282.873C420.832 282.664 420.608 282.401 420.447 282.102C420.394 281.973 420.394 281.973 420.677 281.388C420.818 281.388 420.95 281.31 421.092 281.276C421.233 281.241 421.233 281.276 423.07 282.627C423.05 282.656 423.035 282.688 423.026 282.722Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3119"
            d="M402.628 416.83C401.745 417.561 401.224 417.931 400.809 418.249C400.721 417.991 400.58 417.75 400.483 417.484C400.385 417.217 400.483 417.2 400.483 417.191C400.862 416.73 401.354 416.369 401.913 416.141C401.952 416.136 401.992 416.139 402.03 416.149C402.068 416.16 402.103 416.177 402.134 416.201C402.372 416.356 402.575 416.554 402.805 416.709L402.655 416.83"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3120"
            d="M98.6094 280.071C98.6094 280.174 98.6094 280.286 98.53 280.381C98.36 280.568 98.1739 280.741 97.9737 280.897C97.6823 279.942 97.5057 279.357 97.3379 278.729C98.0355 278.341 98.0355 278.341 98.2209 278.393C98.4064 278.445 98.8832 278.789 98.5918 280.114"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3121"
            d="M240.293 333.58L239.949 334.527C239.401 334.226 239.401 334.226 239.331 334.131C238.926 333.568 238.583 332.965 238.306 332.332C238.268 332.215 238.268 332.088 238.306 331.971C238.403 331.652 238.589 331.377 238.712 331.11C239.419 331.463 239.419 331.463 239.472 331.541C239.754 331.98 240.355 333.408 240.355 333.425C240.355 333.442 240.355 333.554 240.293 333.615"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3122"
            d="M144.44 478.806L144.52 478.849C144.087 478.16 144.087 478.16 143.089 476.37H142.992C142.409 476.37 142.409 476.37 142.498 476.749C142.678 477.279 143.005 477.749 143.442 478.109C143.743 478.379 144.078 478.611 144.44 478.797M143.125 476.353L143.054 476.293V476.362"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3123"
            d="M330.997 248.118C329.664 248.635 329.584 248.661 329.178 248.42C328.962 248.218 328.813 247.957 328.75 247.672C328.687 247.387 328.713 247.09 328.825 246.819C328.913 246.612 329.046 246.415 329.143 246.208C329.637 246.69 330.22 247.249 331.103 248.075L330.997 248.118Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3124"
            d="M496.936 160.558C497.201 161.101 497.201 161.101 497.218 161.152C497.236 161.204 497.28 161.376 497.218 161.342C496.684 160.79 496.23 160.171 495.867 159.5C496.892 160.361 496.892 160.361 496.971 160.558"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3125"
            d="M192.707 82.9449L191.947 83.2978C192.698 82.6437 193.166 82.2565 193.166 82.2565C193.166 82.2565 193.722 82.0155 193.916 82.1016C194.111 82.1876 192.724 82.9621 192.707 82.9621"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3126"
            d="M305.23 155.412C305.007 156.053 304.741 156.68 304.436 157.288C304.25 157.111 304.108 156.895 304.021 156.656C303.934 156.418 303.904 156.163 303.932 155.911C304.06 155.404 304.336 154.943 304.727 154.586C304.885 154.703 305.03 154.836 305.16 154.982L305.23 155.412Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3127"
            d="M356.119 116.154C356.579 117.015 356.119 117.591 355.731 117.462C355.608 117.405 355.49 117.339 355.378 117.264C355.317 117.198 355.27 117.123 355.236 117.04C355.395 116.602 355.554 116.18 355.731 115.775C355.89 115.869 356.023 115.999 356.119 116.154Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3128"
            d="M200.352 81.7316C200.055 81.9111 199.734 82.0501 199.398 82.1447L200.802 81.069C200.802 81.069 201.209 80.8969 201.359 80.9571C201.509 81.0174 201.403 81.1464 200.352 81.7316Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3129"
            d="M472.308 313.546C472.345 313.72 472.344 313.9 472.305 314.074C472.265 314.247 472.188 314.411 472.078 314.553C471.195 313.787 470.948 313.555 470.824 313.142C470.754 312.646 470.736 312.144 470.771 311.644C470.771 311.644 470.771 311.644 471.08 311.317C471.743 311.421 472.237 312.677 472.308 313.546Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3130"
            d="M532.53 352.323V352.263C532.529 352.283 532.529 352.303 532.53 352.323ZM532.99 350.223V350.085C532.91 350.085 532.822 350.025 532.76 350.085C532.473 350.877 532.318 351.708 532.301 352.547C532.389 352.547 532.504 352.254 532.504 352.254C532.61 351.893 532.636 351.522 532.716 351.161C532.795 350.8 532.875 350.516 532.954 350.197"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3131"
            d="M199.257 98.3405C198.127 99.3129 198.092 98.831 198.092 98.8224C198.087 98.7336 198.087 98.6445 198.092 98.5557C198.648 98.2287 199.805 97.5574 199.858 97.5488C199.814 97.6759 199.761 97.7996 199.699 97.9189L199.239 98.3405"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3132"
            d="M380.482 426.648C380.199 426.952 379.882 427.223 379.537 427.457C378.995 427.795 378.421 428.083 377.824 428.318C377.762 428.128 377.7 427.896 377.762 427.844C378.338 427.273 378.999 426.788 379.722 426.407C380.019 426.416 380.315 426.451 380.605 426.51L380.482 426.622"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3133"
            d="M188.52 389.705C188.255 389.972 188.255 389.972 188.114 389.989C187.772 390.042 187.421 390.006 187.098 389.885C186.829 389.767 186.587 389.596 186.388 389.383C186.189 389.17 186.037 388.919 185.941 388.646C185.941 388.595 185.941 388.595 186.092 388.087L188.502 389.343L188.573 389.662L188.52 389.705Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3134"
            d="M137.835 103.693C137.773 103.693 137.587 103.779 137.614 103.693C137.918 103.316 138.249 102.959 138.603 102.626C138.603 102.626 139.265 102.23 139.389 102.334C139.512 102.437 137.852 103.659 137.835 103.667"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3135"
            d="M180.776 85.1226C180.646 85.1567 180.513 85.1825 180.379 85.2C180.379 85.2 180.317 85.2 180.379 85.1484C180.517 84.9939 180.665 84.8474 180.821 84.7095C180.856 84.7054 180.891 84.7054 180.927 84.7095L181.121 84.6665L181.474 84.5977C181.271 84.7698 181.077 84.9591 180.865 85.1226C180.865 85.1226 180.821 85.1226 180.803 85.1226"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3136"
            d="M533.432 291.491C533.321 290.785 533.091 290.102 532.752 289.468V289.589C532.776 290.2 532.838 290.809 532.937 291.413C533.043 291.869 533.291 291.964 533.3 291.964C533.308 291.964 533.299 291.921 533.423 291.491M532.734 289.313V289.417V289.468C532.734 289.417 532.734 289.365 532.734 289.313Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3137"
            d="M372.014 375.135V375.023L371.908 375.092C371.51 375.316 371.369 375.402 371.325 375.565C371.216 376.263 371.175 376.969 371.201 377.674L371.457 377.717C371.797 376.895 371.982 376.021 372.005 375.135M372.12 374.963L372.058 374.877V374.997L372.12 374.963Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3138"
            d="M91.6339 125.534C91.8193 125.362 91.8193 125.362 93.4882 123.649C92.9555 124.036 92.459 124.468 92.0048 124.94C91.7431 125.181 91.5382 125.475 91.4043 125.801L91.6692 125.543M93.5412 123.641C93.5699 123.614 93.5964 123.585 93.6207 123.555L93.5236 123.649"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3139"
            d="M306.095 95.604C306.015 95.7675 305.927 95.931 305.839 96.0945L305.221 95.5351C305.309 95.4749 305.38 95.3888 305.468 95.3286C305.517 95.2921 305.575 95.2684 305.636 95.2598C305.693 95.2637 305.746 95.2882 305.786 95.3286C305.901 95.3975 305.998 95.4921 306.104 95.5696"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3140"
            d="M511.418 186.719C511.517 186.925 511.599 187.137 511.665 187.355C511.134 186.598 510.692 185.784 510.35 184.929C511.065 186.108 511.286 186.469 511.418 186.719Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3141"
            d="M321.85 414.549L321.929 414.626C321.933 414.592 321.933 414.557 321.929 414.523H321.867M323.033 413.232C323.039 412.832 322.954 412.435 322.786 412.07C322.786 412.027 322.786 412.027 322.256 411.976V412.105C321.876 414.093 321.876 414.093 321.956 414.497C322.243 414.409 322.5 414.244 322.695 414.02C322.89 413.796 323.017 413.523 323.059 413.232"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3142"
            d="M399.828 321.033C399.839 320.672 399.746 320.315 399.558 320.003C399.371 319.691 399.097 319.437 398.769 319.269V319.407C398.769 320.603 398.769 321.326 398.84 321.55C398.91 321.773 398.839 321.653 399.369 322.195C399.837 321.481 399.837 321.481 399.828 321.033ZM398.769 319.14L398.707 319.243H398.769V319.114"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3143"
            d="M185.669 93.2035C185.121 93.1518 185.121 93.1518 185.059 93.1174C184.997 93.083 185.395 92.7388 185.395 92.7388C185.637 92.6966 185.882 92.6736 186.128 92.6699C186.128 92.6699 186.128 92.6699 186.084 92.7732C186.039 92.8764 185.836 93.0572 185.722 93.2121H185.669"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3144"
            d="M471.266 127.358C471.46 127.573 471.637 127.805 471.822 128.029C471.165 127.458 470.554 126.84 469.994 126.179C470.356 126.506 471.107 127.194 471.266 127.358Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3145"
            d="M513.007 327.383C512.971 327.888 512.894 328.388 512.778 328.881C512.725 328.709 512.663 328.528 512.61 328.347C512.557 328.167 512.486 327.977 512.433 327.797C512.433 327.797 512.433 327.736 512.433 327.71C512.428 327.679 512.428 327.647 512.433 327.616L512.795 326.359C512.902 326.693 512.973 327.036 513.007 327.383Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3146"
            d="M519.162 279.038L518.95 280.191L518.402 279.718C518.473 279.469 518.517 279.219 518.597 278.97C518.676 278.72 518.703 278.746 518.729 278.729C518.748 278.717 518.769 278.711 518.791 278.711C518.813 278.711 518.834 278.717 518.853 278.729C518.967 278.797 519.065 278.901 519.179 278.97V279.038"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3147"
            d="M244.637 153.484L244.69 153.433H244.645V153.493M244.645 153.407C244.698 152.727 244.601 152.262 243.957 152.236C243.792 152.239 243.629 152.262 243.471 152.305C243.347 152.357 243.294 152.486 243.197 152.572C243.645 152.88 244.118 153.154 244.61 153.389"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3148"
            d="M123.387 164.602L122.434 165.076C122.434 164.766 122.434 164.456 122.434 164.146C122.628 164.103 122.813 164.034 122.999 164.009C123.095 163.987 123.194 163.987 123.29 164.009C123.352 164.009 123.361 164.06 123.396 164.164C123.431 164.267 123.396 164.43 123.458 164.568H123.396"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3149"
            d="M159.99 94.1241L159.283 94.279L159.504 94.0294L159.628 93.8831C159.628 93.8831 159.672 93.8143 159.707 93.7971C159.95 93.719 160.202 93.6755 160.458 93.668C160.337 93.8508 160.177 94.0063 159.99 94.1241Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3150"
            d="M514.226 400.832C514.374 400.488 514.472 400.126 514.517 399.756L514.464 399.868C513.678 401.589 513.678 401.589 513.404 402.131C513.493 402.131 513.758 402.131 514.226 400.832Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3151"
            d="M212.813 155.377V155.326ZM212.734 155.395L212.787 155.3C212.737 155.248 212.677 155.208 212.61 155.181C212.543 155.154 212.471 155.142 212.398 155.145C212.169 155.197 211.974 155.369 211.709 156.117C211.652 156.255 211.616 156.4 211.604 156.548C211.604 156.754 211.665 156.961 211.683 157.176C212.066 156.601 212.417 156.007 212.734 155.395Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3152"
            d="M169.464 95.6811C169.266 95.7571 169.062 95.8203 168.855 95.8704C168.767 95.8704 168.705 95.8704 168.758 95.7843L168.97 95.4745C169.069 95.4312 169.173 95.3966 169.279 95.3713H169.385H169.438L169.835 95.2422C169.729 95.4057 169.632 95.5692 169.517 95.7155C169.402 95.8618 169.517 95.7155 169.464 95.7155"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3153"
            d="M176.971 99.4587L176.088 99.8459C176.229 99.6136 176.379 99.3812 176.529 99.1489L176.927 99.0542C176.979 99.0418 177.034 99.0418 177.086 99.0542C177.086 99.0542 177.13 99.0542 177.086 99.1403C177.042 99.2263 176.997 99.3382 176.962 99.4415H176.918"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3154"
            d="M103.22 214.359L102.566 215.013C102.606 214.536 102.704 214.065 102.858 213.61C102.912 213.516 102.974 213.427 103.043 213.344C103.202 213.163 103.379 213.008 103.538 212.836C103.467 213.309 103.423 213.783 103.326 214.256C103.326 214.299 103.255 214.325 103.22 214.359Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3155"
            d="M421.216 336.91C420.862 337.615 420.668 337.968 420.571 338.14C420.483 337.908 420.359 337.684 420.271 337.452C420.217 337.317 420.184 337.175 420.174 337.03C420.166 336.876 420.184 336.722 420.227 336.574C420.28 336.307 420.377 336.049 420.439 335.791C420.721 336.127 420.995 336.462 421.269 336.806L421.216 336.91Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3156"
            d="M352.111 142.125C352.111 142.392 352.111 142.65 352.111 142.917C351.934 142.736 351.74 142.572 351.563 142.383C351.467 142.283 351.386 142.17 351.325 142.047C351.274 141.933 351.244 141.811 351.236 141.686C351.236 141.479 351.236 141.273 351.236 141.066L352.119 142.065C352.119 142.065 352.119 142.065 352.119 142.125"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3157"
            d="M261.74 252.697V252.758ZM262.871 253.549C262.915 252.887 262.72 252.56 262.288 252.577C262.096 252.6 261.91 252.658 261.74 252.749C261.762 253.376 261.833 254 261.952 254.616C261.97 254.696 262.017 254.766 262.085 254.814C262.208 254.918 262.367 254.986 262.491 255.09C262.665 254.588 262.792 254.073 262.871 253.549Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3158"
            d="M511.065 206.081C511.18 206.477 511.286 206.873 511.401 207.26C510.871 206.442 510.871 206.442 510.827 206.356C510.783 206.27 510.783 206.253 510.465 205.298C511.03 205.96 511.03 205.96 511.065 206.081Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3159"
            d="M380.448 114.596L380.792 115.224C380.43 114.958 380.085 114.671 379.759 114.364C379.685 114.285 379.619 114.198 379.565 114.106C379.468 113.959 379.397 113.796 379.309 113.649C380.386 114.458 380.386 114.458 380.448 114.57"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3160"
            d="M134.036 178.526C133.552 178.969 133.039 179.38 132.5 179.757C132.672 179.155 132.921 178.577 133.242 178.036C133.489 177.683 133.736 177.537 133.983 177.588C134.469 177.752 134.045 178.5 134.036 178.509"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3161"
            d="M250.738 132.478C250.579 132.685 250.42 132.883 250.252 133.081C250.252 132.865 250.155 132.659 250.129 132.435C250.103 132.214 250.166 131.992 250.305 131.816C250.429 131.626 250.579 131.463 250.703 131.273C250.703 131.643 250.8 132.014 250.817 132.384C250.817 132.384 250.764 132.452 250.738 132.478Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3162"
            d="M387.554 122.281C387.554 122.471 387.643 122.66 387.678 122.849L387.077 122.341C386.989 122.27 386.91 122.189 386.839 122.1C386.653 121.861 386.501 121.597 386.389 121.317C387.545 122.238 387.545 122.238 387.554 122.281Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3163"
            d="M388.897 121.67C388.515 121.243 388.072 120.872 387.581 120.569L387.679 120.706C388.191 121.438 388.676 122.186 389.206 122.909C389.317 123.042 389.435 123.168 389.559 123.288C389.745 123.185 389.745 123.185 389.559 122.737C389.393 122.35 389.167 121.99 388.888 121.67M387.511 120.474V120.534H387.564L387.502 120.439"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3164"
            d="M95.7938 124.192L94.9902 124.854C95.5185 124.243 96.1104 123.686 96.7563 123.193C96.4659 123.545 96.1532 123.878 95.8203 124.192"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3165"
            d="M172.978 122.823L172.757 123.004L172.643 123.09H172.59C172.545 123.09 171.848 123.09 171.83 122.995C171.812 122.9 171.83 122.926 171.83 122.892L172.016 122.522C172.227 122.522 172.439 122.444 172.66 122.419C172.751 122.409 172.843 122.409 172.934 122.419C172.951 122.427 172.965 122.439 172.976 122.454C172.987 122.469 172.993 122.486 172.996 122.505C172.996 122.591 172.996 122.677 172.996 122.754L172.943 122.797"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3166"
            d="M381.603 239.435C380.322 239.745 380.322 239.745 380.208 239.719C380.093 239.694 380.119 239.719 380.013 239.582C379.907 239.444 379.766 239.255 379.643 239.1L379.952 239.014L380.685 238.824L380.994 238.756C381.101 238.745 381.208 238.724 381.311 238.695C381.311 238.695 381.312 238.695 381.682 239.418H381.603"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3167"
            d="M122.972 461.681C123.017 462.049 123.017 462.422 122.972 462.791C122.972 462.851 122.972 462.851 122.593 462.885C122.419 462.301 122.286 461.706 122.195 461.104C122.195 461.018 122.195 461.018 122.31 460.717C122.99 461.577 122.99 461.577 122.99 461.681"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3168"
            d="M501.846 290.63C501.861 291.121 501.838 291.613 501.775 292.101C501.378 291.335 501.378 291.335 501.342 291.129C501.262 290.4 501.22 289.668 501.219 288.935C501.466 289.494 501.676 290.069 501.846 290.656"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3169"
            d="M303.535 106.679C303.464 106.8 303.411 106.929 303.341 107.041C303.27 107.153 303.279 107.118 303.261 107.118C303.243 107.118 303.199 107.118 302.555 106.972C302.582 106.82 302.624 106.67 302.678 106.525C302.691 106.499 302.711 106.478 302.736 106.464C302.762 106.45 302.791 106.444 302.82 106.447C302.878 106.441 302.938 106.441 302.996 106.447L303.57 106.611V106.662"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3170"
            d="M369.205 274.632C368.87 274.822 368.561 275.037 368.225 275.217C368.131 275.267 368.03 275.302 367.925 275.321C367.748 275.321 367.748 275.321 366.848 274.968C367.731 274.168 367.731 274.168 367.863 274.15C367.959 274.13 368.059 274.13 368.155 274.15C368.561 274.262 368.949 274.4 369.355 274.512L369.205 274.598"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3171"
            d="M200.521 84.5807L199.682 84.8819L199.779 84.7528L199.867 84.6496L199.964 84.5205C199.964 84.5205 199.964 84.4689 200.026 84.4517C200.264 84.3533 200.513 84.2839 200.768 84.2451C200.768 84.2451 200.768 84.2882 200.715 84.3742C200.662 84.4603 200.582 84.5549 200.521 84.5807Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3172"
            d="M533.431 283.746C533.263 282.885 533.122 282.205 533.069 282.025C533.016 281.844 533.069 281.973 533.016 282.025C532.963 282.076 532.937 282.094 532.893 282.119V282.223C532.91 282.736 532.981 283.246 533.104 283.746C533.104 283.746 533.104 283.823 533.193 283.823C533.281 283.823 533.325 283.772 533.396 283.754"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3173"
            d="M188.864 82.6954L188.148 82.9105L188.36 82.7126L188.475 82.6094C188.515 82.5621 188.559 82.5189 188.608 82.4803C188.807 82.4094 189.015 82.3603 189.226 82.334C189.226 82.334 188.978 82.661 188.864 82.6954Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3174"
            d="M300.057 182.898C298.909 183.131 298.909 183.131 298.82 183.113C298.732 183.096 298.759 183.113 298.396 182.554C298.71 182.409 299.043 182.308 299.385 182.253C299.438 182.253 299.465 182.253 299.571 182.348C299.677 182.442 299.959 182.709 300.171 182.873H300.057"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3175"
            d="M49.8138 240.099V240.15ZM49.8138 240.056V240.099C50.1935 238.653 50.1935 238.653 50.22 238.515C50.2029 238.34 50.1673 238.167 50.1141 237.999L50.0699 238.094C49.8379 238.529 49.6572 238.988 49.5312 239.462C49.5313 239.6 49.5313 239.6 49.7962 240.056"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3176"
            d="M167.963 216.124C167.689 216.124 167.415 216.227 167.133 216.244C167.022 216.27 166.907 216.27 166.797 216.244C166.729 216.189 166.682 216.113 166.665 216.029C166.612 215.831 166.594 215.633 166.541 215.435C166.899 215.347 167.267 215.306 167.636 215.315C167.689 215.315 167.715 215.315 167.777 215.461C167.839 215.607 167.954 215.926 168.06 216.149H167.963"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3177"
            d="M170.701 439.324C170.568 439.453 170.445 439.599 170.303 439.728C170.264 439.77 170.211 439.797 170.153 439.805C170.013 439.783 169.876 439.743 169.747 439.685C169.473 439.573 169.208 439.427 168.943 439.306C169.015 439.18 169.101 439.062 169.199 438.954C169.261 438.902 169.323 438.911 169.42 438.954L170.771 439.298L170.701 439.349"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3178"
            d="M329.981 277.593C329.672 277.782 329.381 277.997 329.098 278.178C329.008 278.239 328.906 278.28 328.798 278.298C328.621 278.298 328.621 278.298 327.721 277.971C327.84 277.737 327.979 277.513 328.136 277.3C328.173 277.259 328.219 277.226 328.271 277.205C328.323 277.184 328.38 277.176 328.436 277.18C328.69 277.182 328.943 277.219 329.187 277.292C329.681 277.403 329.822 277.438 330.167 277.498L330.008 277.593"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3179"
            d="M198.454 86.1813C198.26 86.2727 198.059 86.3503 197.854 86.4136C197.854 86.4136 197.801 86.4136 197.854 86.3362C197.907 86.2587 198.119 85.9661 198.119 85.9575C198.177 85.9285 198.239 85.9082 198.304 85.8973H198.401H198.489L198.913 85.751C198.525 86.2329 198.525 86.2329 198.481 86.2501"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3180"
            d="M269.936 85.4408L269.557 85.7764C269.486 85.6215 269.433 85.458 269.371 85.2859C269.459 85.2256 269.53 85.1482 269.61 85.0879L269.698 85.0449C269.743 85.0593 269.781 85.0901 269.804 85.131C269.859 85.2258 269.909 85.3235 269.954 85.4236"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3181"
            d="M188.06 92.8584C187.892 92.9231 187.717 92.9721 187.539 93.0047C187.539 93.0047 187.469 93.0047 187.539 92.8412L187.698 92.5572H187.742L188.511 92.2646C188.104 92.8584 188.104 92.8584 188.06 92.8756"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3182"
            d="M276.152 217.982C275.481 218.344 275.481 218.344 275.34 218.335C275.231 218.33 275.127 218.294 275.039 218.232C274.863 218.128 274.713 217.982 274.545 217.87L275.287 217.01L276.17 217.931H276.126"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3183"
            d="M459.857 114.313C459.857 114.313 459.795 114.313 459.777 114.313C459.76 114.313 459.777 114.312 459.777 114.364C460.106 114.721 460.463 115.052 460.846 115.354L459.892 114.313"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3184"
            d="M496.264 162.735L496.75 163.596C496.553 163.397 496.367 163.187 496.194 162.968C496.114 162.856 496.114 162.856 495.514 161.857C495.788 162.123 496.039 162.411 496.264 162.718"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3185"
            d="M160.554 159.371C160.4 159.448 160.24 159.517 160.078 159.577C159.989 159.577 159.927 159.577 159.883 159.577C159.809 159.538 159.753 159.474 159.724 159.397C159.666 159.25 159.616 159.101 159.574 158.949L160.457 158.493C160.501 158.777 160.546 159.053 160.599 159.354"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3186"
            d="M324.993 456.81C325.012 456.327 324.961 455.843 324.842 455.373C324.789 455.27 324.789 455.27 324.18 455.124V455.21C324.18 455.511 324.18 455.657 324.251 455.821C324.339 456.208 324.472 456.578 324.56 456.965L324.984 456.81M324.162 455.089C324.158 455.063 324.158 455.037 324.162 455.012L324.092 455.063H324.162"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3187"
            d="M240.548 81.9042L240.866 81.5513C241.034 81.3534 241.228 81.1641 241.378 80.949C241.378 80.949 241.378 80.949 241.378 80.8973C241.378 80.8457 241.307 80.8973 241.281 80.8457L241.228 80.8973C240.522 81.5514 240.522 81.5514 240.451 81.6546C240.38 81.7579 240.398 81.7321 240.451 81.7751C240.504 81.8181 240.522 81.8525 240.557 81.8956"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3188"
            d="M154.674 152.082V152.039L154.603 152.091H154.647M155.451 152.736L155.504 152.676C155.354 152.521 155.212 152.349 155.062 152.194C154.912 152.039 154.947 152.073 154.886 152.065H154.647C154.365 152.512 154.206 152.831 154.294 153.011C154.294 153.055 154.365 153.098 154.524 153.106C154.721 153.099 154.919 153.082 155.115 153.055C155.115 153.055 155.115 153.055 155.451 152.719"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3189"
            d="M466.604 297.265C466.675 297.807 466.604 298.357 466.666 298.9C466.507 298.659 466.322 298.435 466.154 298.194C466.11 298.125 466.078 298.049 466.057 297.97C465.989 297.431 465.975 296.886 466.013 296.344C466.56 296.903 466.56 296.903 466.604 297.265Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3190"
            d="M150.321 151.54C150.1 151.54 149.888 151.609 149.667 151.609C149.627 151.616 149.586 151.615 149.547 151.606C149.507 151.598 149.47 151.581 149.438 151.557C149.438 151.557 149.438 151.471 149.438 151.368C149.475 151.19 149.522 151.015 149.579 150.843C149.848 150.804 150.122 150.804 150.391 150.843C150.391 150.843 150.435 150.843 150.435 150.972C150.435 151.101 150.435 151.359 150.435 151.557H150.365"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3191"
            d="M325.319 168.501L325.001 169.482C324.895 169.284 324.763 169.095 324.666 168.888C324.608 168.779 324.563 168.664 324.533 168.544C324.533 168.398 324.533 168.398 324.674 167.632L325.346 168.424V168.501"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3192"
            d="M385.101 310.887C385.116 311.384 385.093 311.882 385.03 312.376C384.403 312.143 384.403 312.143 384.394 312.092C384.348 311.341 384.348 310.588 384.394 309.837C384.615 310.104 384.871 310.345 385.074 310.629C385.127 310.704 385.152 310.795 385.145 310.887"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3193"
            d="M131.936 147.512L130.32 148.897C130.493 148.453 130.754 148.047 131.089 147.701C131.442 147.314 131.645 147.185 131.83 147.236C132.016 147.288 131.945 147.331 131.989 147.469L131.936 147.512Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3194"
            d="M502.465 170.566C502.8 171.065 503.118 171.573 503.445 172.081C503.259 171.874 503.056 171.685 502.889 171.461C502.588 171.031 502.332 170.6 502.059 170.136C502.271 170.265 502.27 170.265 502.465 170.566Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3195"
            d="M361.999 227.113C361.972 227.264 361.937 227.413 361.893 227.56L360.039 226.227L360.33 225.882C360.392 225.813 360.401 225.813 360.436 225.796C360.472 225.779 361.187 226.356 361.611 226.717C362.035 227.078 362.008 227.044 361.999 227.113Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3196"
            d="M51.3769 278.341C51.3063 278.686 51.2268 279.038 51.1562 279.391C51.0767 279.322 50.9707 279.279 50.8912 279.21C50.8118 279.142 50.8206 279.116 50.8206 279.081C50.8206 279.047 50.8206 278.651 50.9531 277.128C51.0497 277.2 51.1532 277.264 51.2621 277.317C51.3769 277.377 51.5005 277.438 51.5182 277.489C51.492 277.779 51.4448 278.067 51.3769 278.35"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3197"
            d="M282.554 174.8C282.422 175.058 282.298 175.316 282.166 175.583C282.086 175.394 281.963 175.221 281.874 175.041C281.837 174.959 281.816 174.872 281.812 174.783C281.823 174.653 281.853 174.525 281.901 174.404C281.998 174.163 282.139 173.939 282.254 173.698C282.581 174.748 282.581 174.748 282.554 174.8Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3198"
            d="M235.32 81.6453C235.334 81.4907 235.334 81.3353 235.32 81.1807C235.32 81.1807 235.32 81.1807 235.267 81.129L235.162 81.2151C234.95 81.413 234.499 81.8261 234.817 81.8347C234.985 81.8347 235.003 81.8347 235.285 81.6626M235.338 81.0602L235.25 81.1118C235.269 81.0847 235.293 81.0613 235.32 81.043"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3199"
            d="M405.428 85.0369C405.428 85.1229 405.525 85.2004 405.561 85.2864C405.566 85.2971 405.569 85.3089 405.569 85.3208C405.569 85.3328 405.566 85.3446 405.561 85.3553C405.358 85.2908 405.161 85.2131 404.969 85.1229C404.872 85.0267 404.792 84.9163 404.73 84.7959C404.73 84.7959 404.73 84.7959 404.836 84.7959L405.41 84.9938"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3200"
            d="M405.128 80.7772L405.649 81.1042C405.755 81.1644 405.764 81.173 405.773 81.1902C405.661 81.2083 405.548 81.2083 405.437 81.1902C405.437 81.1902 404.934 80.9235 404.916 80.846C404.952 80.8173 404.991 80.7914 405.031 80.7686L405.11 80.8202"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3201"
            d="M519.586 346.238C519.51 346.692 519.404 347.14 519.268 347.58L519.215 347.193C519.16 347.009 519.13 346.818 519.127 346.625C519.165 346.415 519.221 346.207 519.295 346.006C519.348 345.816 519.401 345.627 519.445 345.446C519.604 346.126 519.604 346.126 519.586 346.238Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3202"
            d="M520.99 346.565C520.796 346.758 520.576 346.923 520.336 347.056C520.306 346.836 520.288 346.615 520.283 346.393C520.483 346.224 520.692 346.066 520.91 345.92L520.972 345.877C521.005 346.094 521.022 346.312 521.025 346.531"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3203"
            d="M145.772 117.763C145.578 117.841 145.392 117.935 145.198 118.021C145.004 118.107 145.065 118.073 145.048 118.064C145.03 118.056 144.977 118.013 144.951 117.952C144.924 117.892 144.906 117.746 144.871 117.643C145.542 117.29 145.542 117.29 145.63 117.333C145.659 117.356 145.686 117.382 145.71 117.41C145.755 117.516 145.805 117.619 145.86 117.72L145.772 117.763Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3204"
            d="M435.38 284.873L434.815 285.803L433.932 285.123C434.091 284.959 434.205 284.77 434.364 284.624C434.423 284.564 434.496 284.519 434.576 284.494C434.654 284.473 434.737 284.473 434.815 284.494C435.018 284.572 435.186 284.701 435.38 284.787V284.839"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3205"
            d="M392.165 384.515L392.112 384.464H392.174M392.086 384.524V384.446C391.872 384.366 391.638 384.351 391.415 384.403C391.061 384.532 390.929 384.782 390.885 385.393C390.885 385.445 390.885 385.935 390.885 386.013C390.978 386.221 391.097 386.417 391.238 386.598C391.561 385.926 391.835 385.233 392.059 384.524"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3206"
            d="M176.501 103.349C175.901 103.297 175.901 103.297 175.83 103.263C175.76 103.229 175.83 103.263 176.113 102.859C176.364 102.8 176.622 102.772 176.881 102.772C176.881 102.772 176.881 102.772 176.881 102.85C176.881 102.927 176.678 103.186 176.581 103.349H176.519"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3207"
            d="M333.09 246.742C333.09 246.862 333.09 246.862 333.09 248.712C332.887 248.454 332.667 248.205 332.463 247.947C332.418 247.896 332.382 247.837 332.358 247.775C332.344 247.177 332.395 246.58 332.508 245.993C332.689 246.108 332.857 246.241 333.011 246.389C333.082 246.475 333.082 246.509 333.099 246.742"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3208"
            d="M382.221 294.167C382.199 293.85 382.146 293.536 382.062 293.229C381.974 293.151 381.285 293.229 381.276 293.229C381.151 293.254 381.024 293.268 380.896 293.272C381.065 293.887 381.283 294.489 381.55 295.07C381.55 295.113 381.612 295.131 381.753 295.139C381.894 295.148 382.115 295.139 382.292 295.139C382.292 294.812 382.292 294.494 382.221 294.167Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3209"
            d="M66.2826 267.275L65.7086 267.972C65.6726 267.591 65.6726 267.208 65.7086 266.827C65.7419 266.693 65.7923 266.563 65.8587 266.44C65.9647 266.225 66.0883 266.036 66.1943 265.829C66.2384 266.294 66.2914 266.75 66.3179 267.215C66.3179 267.215 66.3179 267.215 66.2649 267.275"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3210"
            d="M139.884 174.207L138.215 175.601C138.215 175.429 138.215 175.256 138.215 175.084C138.215 175.084 138.215 175.007 138.215 174.981C138.215 174.955 139.716 173.854 139.778 173.862C139.84 173.871 139.778 173.862 139.893 174.164L139.84 174.207"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3211"
            d="M316.047 133.554L316.126 133.872C316.012 133.924 315.906 133.993 315.782 134.036C315.724 134.051 315.663 134.051 315.605 134.036C315.524 134.026 315.446 133.999 315.376 133.958C315.235 133.889 315.111 133.795 314.979 133.717C315.065 133.594 315.159 133.476 315.261 133.365C315.309 133.307 315.379 133.269 315.455 133.261C315.511 133.25 315.568 133.25 315.623 133.261C315.764 133.313 315.897 133.39 316.038 133.451V133.494"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3212"
            d="M137.013 102.169C136.837 102.238 136.66 102.316 136.475 102.376C136.289 102.436 136.395 102.376 136.395 102.376C136.424 102.224 136.481 102.078 136.563 101.946C136.747 101.845 136.939 101.759 137.137 101.688C137.148 101.724 137.148 101.763 137.137 101.799C137.137 101.911 137.084 102.015 137.066 102.126H136.987"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3213"
            d="M157.747 99.1152C157.663 99.1468 157.573 99.1614 157.482 99.1582C157.783 98.8398 158.63 98.0309 158.648 98.0137H158.895L157.8 99.1496H157.747"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3214"
            d="M415.132 79.3486L415.556 79.5982C415.607 79.6247 415.654 79.6565 415.698 79.6928C415.309 79.5231 414.939 79.3155 414.594 79.0732C414.779 79.1547 414.959 79.2466 415.132 79.3486Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3215"
            d="M191.355 393.294L190.984 394.361C190.834 394.094 190.649 393.853 190.516 393.586C190.426 393.441 190.366 393.28 190.34 393.113C190.33 393.001 190.358 392.889 190.419 392.795C190.516 392.588 190.675 392.424 190.79 392.227C190.993 392.554 191.187 392.881 191.382 393.208V393.294"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3216"
            d="M364.879 125.301C364.879 125.465 364.879 125.637 364.879 125.8C364.879 125.964 364.879 125.878 364.826 125.886C364.492 125.857 364.161 125.8 363.837 125.714C363.802 125.573 363.776 125.429 363.758 125.284C363.758 125.232 363.758 125.224 363.828 125.215C363.931 125.208 364.035 125.208 364.138 125.215C364.394 125.215 364.65 125.215 364.906 125.215C364.91 125.238 364.91 125.261 364.906 125.284"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3217"
            d="M221.122 86.6799L220.61 86.9466C220.57 86.9732 220.525 86.9908 220.478 86.9983C220.416 86.9983 220.407 86.9983 220.266 86.7745C220.76 86.4389 220.76 86.4389 220.831 86.4475L221.193 86.6368L221.122 86.6799Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3218"
            d="M282.465 196.649L282.535 196.606L282.482 196.684M284.319 197.157L284.39 196.908C283.366 196.675 282.694 196.555 282.686 196.555C282.677 196.555 282.588 196.615 282.553 196.641C282.65 196.873 282.853 197.346 283.286 197.501C283.471 197.522 283.658 197.505 283.836 197.451C284.014 197.398 284.178 197.31 284.319 197.192"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3219"
            d="M423.477 88.8232C423.751 89.1675 423.751 89.1674 423.76 89.2019C423.606 89.1416 423.456 89.0726 423.31 88.9953C423.151 88.8547 423.001 88.7053 422.859 88.5479C422.859 88.5479 423.319 88.7372 423.451 88.7888"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3220"
            d="M483.126 148.493L483.081 148.433C482.64 148.433 482.64 148.433 482.587 148.433L482.702 148.562C483.117 149.044 483.399 149.319 483.426 149.13C483.436 149.03 483.436 148.929 483.426 148.829C483.354 148.697 483.268 148.573 483.17 148.459M482.578 148.364L482.631 148.45L482.578 148.381"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3221"
            d="M91.5807 270.132C91.5188 270.295 91.5188 270.295 90.9979 270.992C90.8919 270.003 90.8919 270.003 90.9361 269.848C91.1401 269.332 91.376 268.829 91.6425 268.342C91.6641 268.945 91.6435 269.549 91.5807 270.149"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3222"
            d="M304.471 91.5164C304.383 91.723 304.383 91.723 304.339 91.723C304.295 91.723 304.251 91.723 303.686 91.4992C303.686 91.3874 303.686 91.2841 303.686 91.1722C303.686 91.0603 303.686 91.1292 303.739 91.1206C303.929 91.1301 304.118 91.156 304.304 91.198C304.365 91.2927 304.427 91.3874 304.498 91.482"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3223"
            d="M300.745 91.6708C300.692 91.8257 300.648 91.9892 300.604 92.1441L300.109 91.731C300.109 91.6191 300.109 91.5072 300.171 91.3954C300.233 91.2835 300.215 91.3351 300.224 91.3351C300.281 91.3357 300.337 91.3538 300.383 91.3868C300.516 91.4642 300.622 91.5675 300.754 91.6449"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3224"
            d="M143.017 102.893L142.258 103.194L142.558 102.867L142.717 102.687C142.744 102.653 142.773 102.621 142.805 102.592C142.805 102.592 143.3 102.377 143.37 102.42C143.441 102.463 143.37 102.463 143.3 102.575C143.079 102.867 143.053 102.876 143.017 102.893Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3225"
            d="M261.202 78.4701C260.919 78.582 260.919 78.582 260.893 78.5647C260.866 78.5475 260.893 78.5647 260.84 78.5131C260.787 78.4615 260.84 78.3754 260.84 78.3152C260.931 78.236 261.028 78.1641 261.131 78.1C261.166 78.0712 261.209 78.0532 261.255 78.0484C261.275 78.0412 261.297 78.0412 261.317 78.0484C261.344 78.0955 261.368 78.1443 261.387 78.1947"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3226"
            d="M183.39 413.8L182.869 414.609C182.718 414.368 182.551 414.144 182.409 413.895C182.326 413.763 182.27 413.617 182.242 413.464C182.206 413.358 182.206 413.244 182.242 413.137C182.312 412.94 182.436 412.767 182.515 412.578C182.745 412.897 183.001 413.232 183.398 413.731L183.354 413.8"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3227"
            d="M532.91 352.666C532.957 352.399 532.989 352.129 533.007 351.857C532.837 352.011 532.681 352.178 532.539 352.357C532.522 352.43 532.522 352.507 532.539 352.58C532.424 353.157 532.318 353.759 532.539 353.604C532.76 353.449 532.83 353.338 532.963 352.666M532.601 352.305V352.365V352.314"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3228"
            d="M258.589 78.3156L258.051 78.6598L258.157 78.2123L258.466 78.0402C258.498 78.0322 258.531 78.0322 258.563 78.0402C258.563 78.0402 258.563 78.0402 258.607 78.109C258.612 78.1807 258.612 78.2525 258.607 78.3242"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3229"
            d="M82.1503 259.694L81.4615 260.313C81.4558 259.824 81.4913 259.334 81.5675 258.85C81.5675 258.721 81.5675 258.721 82.009 258.239C82.0797 258.713 82.1415 259.177 82.2121 259.642L82.1503 259.694Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3230"
            d="M205.925 101.085C206.446 100.577 206.34 100.336 206.216 100.225L206.154 100.293C205.66 100.896 205.36 101.257 205.271 101.369C205.183 101.481 205.148 101.601 205.271 101.593C205.395 101.584 205.837 101.223 205.951 101.111"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3231"
            d="M158.338 144.38C158.197 144.38 158.055 144.492 157.914 144.526C157.839 144.546 157.76 144.546 157.684 144.526C157.658 144.512 157.634 144.492 157.616 144.468C157.598 144.445 157.585 144.417 157.578 144.388C157.546 144.27 157.523 144.149 157.508 144.027L158.391 143.459C158.391 143.752 158.391 144.053 158.391 144.32H158.347"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3232"
            d="M406.716 330.412H406.637L406.707 330.481C406.707 330.481 406.707 330.412 406.707 330.387M408.518 329.285C408.518 328.907 408.12 328.631 407.634 328.726C407.149 328.82 406.857 329.371 406.707 330.378C407.239 330.199 407.758 329.986 408.261 329.741C408.403 329.626 408.494 329.463 408.518 329.285Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3233"
            d="M230.835 84.1676L230.084 84.5462L230.208 84.2708L230.261 84.1676C230.254 84.1422 230.254 84.1155 230.261 84.0901C230.439 83.9965 230.629 83.9242 230.826 83.875C230.826 83.875 230.87 83.875 230.87 83.9266C230.87 83.9783 230.87 84.0729 230.87 84.1504H230.826"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3234"
            d="M428.774 329.062C428.298 329.272 427.801 329.434 427.291 329.544C427.246 329.544 427.229 329.544 427.176 329.407C427.123 329.269 427.035 329.08 426.955 328.916C427.838 328.391 427.838 328.391 428.121 328.322C428.151 328.323 428.18 328.329 428.207 328.341C428.235 328.353 428.259 328.37 428.28 328.391C428.5 328.598 428.686 328.821 428.898 329.028L428.765 329.071"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3235"
            d="M535.127 287.334C535.127 287.661 535.083 287.979 535.066 288.306C534.855 287.615 534.786 286.889 534.863 286.172C534.991 286.549 535.08 286.939 535.127 287.334Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3236"
            d="M101.179 213.396L100.49 214.093C100.49 213.86 100.49 213.628 100.534 213.396C100.546 213.3 100.573 213.207 100.614 213.12C100.676 213 100.676 213 101.417 212.165C101.356 212.552 101.311 212.948 101.232 213.344L101.179 213.396Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3237"
            d="M399.115 80.4582L399.503 80.7594C399.274 80.7266 399.049 80.6688 398.832 80.5873L398.188 80.1484C398.516 80.2375 398.838 80.3497 399.15 80.4841"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3238"
            d="M306.961 185.229C305.716 185.522 305.716 185.522 305.636 185.505C305.557 185.488 305.636 185.505 305.301 184.834C305.632 184.678 305.982 184.565 306.343 184.498C306.401 184.509 306.454 184.54 306.493 184.584C306.696 184.782 306.881 185.006 307.085 185.204H306.961"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3239"
            d="M332.444 88.8227C332.444 88.9088 332.497 88.9862 332.506 89.0723C332.515 89.1584 332.506 89.1498 332.462 89.1584C332.403 89.1549 332.345 89.1371 332.294 89.1067C332.162 89.0551 332.038 88.9776 331.906 88.9174C331.773 88.8572 331.826 88.6248 331.791 88.4785L332.444 88.7969"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3240"
            d="M512.945 220.753V220.71ZM512.186 218.989C512.062 218.679 511.868 218.851 511.868 218.851C511.744 219.153 512.574 221.089 512.795 221.003C513.016 220.917 512.901 220.693 512.901 220.693C512.806 220.42 512.694 220.153 512.566 219.893C512.442 219.591 512.318 219.29 512.186 218.989Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3241"
            d="M473.994 127.892C473.718 127.588 473.423 127.301 473.111 127.031C473.579 127.62 474.103 128.164 474.674 128.658C474.674 128.658 474.674 128.58 474.03 127.918"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3242"
            d="M330.616 84.9078C330.729 85.1426 330.826 85.384 330.908 85.6306C330.811 85.5965 330.717 85.5562 330.625 85.5101C330.475 85.1831 330.307 84.8475 330.148 84.5205C330.616 84.8819 330.616 84.8819 330.634 84.9336"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3243"
            d="M143.053 476.302L143.124 476.362V476.31M144.466 476.216C144.356 476.002 144.19 475.82 143.986 475.688C143.781 475.555 143.545 475.476 143.301 475.459C143.15 475.459 142.833 475.39 142.797 475.579C142.762 475.768 143.142 476.31 143.15 476.319H143.239L144.537 476.612C144.533 476.471 144.509 476.332 144.466 476.199"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3244"
            d="M518.704 343.614L518.28 344.604C518.174 344.406 518.068 344.199 517.953 344.001C518.041 343.829 518.121 343.657 518.218 343.493C518.315 343.33 518.324 343.339 518.368 343.313C518.388 343.297 518.413 343.289 518.439 343.289C518.464 343.289 518.489 343.297 518.509 343.313C518.587 343.383 518.661 343.458 518.73 343.537V343.597"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3245"
            d="M514.455 209.971C514.562 210.22 514.651 210.476 514.72 210.737L514.641 210.866C514.208 210.135 513.758 209.438 513.334 208.68C513.34 208.582 513.355 208.484 513.378 208.388C513.806 208.866 514.166 209.399 514.447 209.971"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3246"
            d="M85.4877 358.614H85.4082L85.4612 358.717V358.614M86.2383 357.073C86.2383 356.918 86.2382 356.918 85.9557 356.565V356.643C85.6201 357.968 85.6201 357.968 85.4788 358.605C86.0793 358.605 86.0793 358.605 86.1411 358.476C86.2678 358.019 86.3067 357.544 86.2559 357.073"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3247"
            d="M326.917 244.204C326.608 244.307 326.317 244.436 326.034 244.522C325.898 244.552 325.756 244.552 325.619 244.522C325.531 244.522 325.478 244.471 325.363 244.341C325.248 244.212 325.098 243.954 324.957 243.773C325.355 243.62 325.77 243.513 326.193 243.455C326.255 243.455 326.29 243.455 326.405 243.567C326.52 243.679 326.838 243.954 327.067 244.135H326.953"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3248"
            d="M372.119 374.964H372.049L372.111 375.05C372.111 375.05 372.111 375.05 372.111 374.998M374.186 374.654H374.292C374.292 374.508 374.248 374.362 374.221 374.215C374.229 374.175 374.229 374.135 374.221 374.095H374.097C373.502 374.172 372.912 374.287 372.331 374.439C372.331 374.439 372.331 374.439 372.172 374.998C372.473 375.119 372.534 375.145 372.64 375.127C373.186 375.007 373.723 374.851 374.248 374.663"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3249"
            d="M294.043 206.098C293.548 206.77 293.107 207.303 292.957 207.475L292.727 207.432C292.603 207.183 292.55 207.028 292.577 206.985C292.603 206.942 292.727 206.804 293.301 206.167L293.804 205.625C294.087 206.038 294.087 206.038 294.043 206.098Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3250"
            d="M127.654 226.622C127.506 226.944 127.341 227.257 127.159 227.56C126.841 227.182 126.841 227.182 126.85 227.121C127.037 226.529 127.277 225.953 127.565 225.4C127.565 225.684 127.698 225.96 127.724 226.261C127.735 226.383 127.71 226.505 127.654 226.614"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3251"
            d="M359.351 137.942C359.37 138.007 359.37 138.076 359.351 138.14C359.351 138.33 359.262 138.502 359.236 138.683C358.309 137.099 358.309 137.099 358.291 137.039C358.273 136.979 358.291 136.97 358.291 136.936C358.291 136.901 358.441 136.824 358.512 136.755C358.796 137.135 359.055 137.531 359.289 137.942"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3252"
            d="M110.063 220.383L110.125 220.323H110.072V220.392M108.686 219.867H108.633V219.927M109.984 220.357H110.028C110.028 219.497 110.028 219.178 109.737 219.127C109.445 219.075 109.198 219.333 108.633 219.901L109.984 220.34"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3253"
            d="M313.336 369.422C313.098 369.706 312.85 369.981 312.621 370.283C312.524 370.042 312.382 369.826 312.294 369.585C312.239 369.465 312.211 369.334 312.211 369.203C312.211 369.071 312.239 368.94 312.294 368.82C312.4 368.561 312.55 368.312 312.665 368.054C312.894 368.432 313.08 368.759 313.424 369.37L313.362 369.431"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3254"
            d="M152.448 135.61H152.519L152.431 135.662M152.969 136.462L153.172 136.282C153.375 135.877 153.367 135.705 153.172 135.645C152.956 135.612 152.735 135.612 152.519 135.645C152.21 136.17 151.963 136.746 152.793 136.505C152.86 136.493 152.925 136.473 152.987 136.445"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3255"
            d="M171.672 97.0582C171.125 97.127 171.125 97.127 171.098 97.1012C171.072 97.0754 171.098 97.1012 171.16 96.998C171.222 96.8947 171.46 96.6623 171.601 96.4902L171.831 96.6021C171.912 96.6397 171.986 96.689 172.052 96.7484C172.052 96.7484 171.787 97.041 171.672 97.0582Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3256"
            d="M165.394 93.1253C165.288 93.1253 165.253 93.1856 164.582 93.4179L164.891 93.1167L165.05 92.9532C165.05 92.9532 165.112 92.8758 165.147 92.8586C165.321 92.7864 165.506 92.7428 165.695 92.7295C165.695 92.7295 165.695 92.7295 165.642 92.8586C165.456 93.0995 165.456 93.0995 165.394 93.1253Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3257"
            d="M85.5152 331.833C85.3739 331.902 85.2591 332.005 85.109 332.065C85.0759 332.081 85.0397 332.089 85.003 332.089C84.9664 332.089 84.9302 332.081 84.8971 332.065C84.785 332 84.6862 331.916 84.6057 331.816C84.4379 331.626 84.3054 331.411 84.1465 331.213C84.3937 331.041 84.6233 330.869 84.8617 330.688C85.1002 331.059 85.3297 331.437 85.5593 331.807"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3258"
            d="M282.466 196.649L282.519 196.572L282.466 196.451V196.64M282.519 194.687C282.519 194.532 282.519 194.532 282.237 194.205L282.175 194.274C281.839 194.696 281.839 194.696 281.848 194.764C282.024 195.334 282.23 195.894 282.466 196.442C282.466 195.634 282.519 195.186 282.519 194.721"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3259"
            d="M94.6361 285.648C93.5765 285.544 93.5765 285.544 93.4793 285.484C93.3822 285.424 93.4264 285.433 93.391 285.303C93.3557 285.174 93.3381 284.951 93.3027 284.77C93.4793 284.77 93.6471 284.692 93.8326 284.675C93.9317 284.656 94.0336 284.656 94.1328 284.675C94.1858 284.675 94.2211 284.718 94.3006 284.847C94.38 284.976 94.5831 285.381 94.7421 285.648H94.6361Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3260"
            d="M95.7307 271.698L95.1832 272.507C95.1832 272.249 95.0772 271.99 95.0419 271.724C95.0251 271.615 95.0251 271.505 95.0419 271.397C95.0728 271.282 95.1302 271.176 95.2097 271.087C95.3422 270.915 95.5099 270.777 95.6512 270.622C95.6512 270.966 95.7218 271.302 95.7395 271.646C95.7395 271.646 95.7395 271.646 95.7395 271.698"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3261"
            d="M389.604 112.884H389.543H389.595M389.595 112.944V112.884C389.26 112.884 388.933 112.884 388.598 112.884L388.677 113.004C389.56 114.063 389.816 113.736 389.834 113.632C389.852 113.529 389.834 113.564 389.622 112.935"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3262"
            d="M310.59 101.748C309.919 101.318 309.919 101.318 309.831 101.326H309.716C309.769 101.834 309.963 102.101 310.29 102.084C310.33 102.073 310.367 102.053 310.396 102.023C310.455 101.938 310.509 101.849 310.555 101.757M309.672 101.292V101.335Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3263"
            d="M318.36 255.761C317.239 255.933 317.195 255.933 317.071 255.898C316.947 255.864 317.009 255.898 316.947 255.743C316.886 255.588 316.806 255.408 316.727 255.244C317.157 255.151 317.594 255.09 318.033 255.063C318.033 255.063 318.086 255.063 318.148 255.184C318.21 255.304 318.334 255.563 318.44 255.752H318.36"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3264"
            d="M141.42 348.907C141.2 349.156 140.988 349.406 140.776 349.664L140.078 348.674C140.264 348.58 140.431 348.459 140.626 348.373C140.715 348.337 140.811 348.32 140.908 348.321C140.971 348.345 141.028 348.38 141.076 348.425C141.217 348.562 141.323 348.726 141.464 348.872"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3265"
            d="M157.667 103.323C157.358 103.538 157.004 103.701 156.678 103.891L157.764 102.961C158.043 102.784 158.338 102.634 158.647 102.514C157.764 103.245 157.764 103.245 157.658 103.323"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3266"
            d="M252.806 123.684C252.7 124.054 252.55 124.398 252.417 124.759C252.355 124.596 252.276 124.432 252.223 124.269C252.212 124.221 252.212 124.171 252.223 124.123C252.336 123.767 252.487 123.424 252.673 123.099C252.735 123.202 252.841 123.271 252.868 123.391C252.894 123.512 252.868 123.477 252.868 123.684"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3267"
            d="M150.427 493.9C150.312 494.098 150.215 494.313 150.1 494.511H149.941C149.812 494.406 149.699 494.285 149.605 494.149C149.605 494.149 149.729 493.461 149.817 493.461C149.926 493.497 150.032 493.54 150.135 493.59C150.238 493.683 150.33 493.787 150.409 493.9"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3268"
            d="M304.056 103.108C303.933 103.461 303.8 103.805 303.659 104.149C303.465 103.822 303.465 103.822 303.465 103.805C303.465 103.788 303.747 102.841 303.774 102.815C303.888 102.885 303.989 102.972 304.074 103.074"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3269"
            d="M248.707 79.185L248.266 79.512C248.352 79.33 248.462 79.1594 248.592 79.0043C248.712 78.8926 248.842 78.7918 248.981 78.7031C248.901 78.858 248.831 79.0129 248.742 79.1592"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3270"
            d="M509.732 311.463L509.599 312.47C509.448 312.089 509.335 311.694 509.264 311.291C509.274 310.986 509.321 310.682 509.405 310.388C509.74 311.377 509.741 311.377 509.732 311.463Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3271"
            d="M60.8955 191.185L60.2598 191.942C60.5058 191.211 60.8991 190.535 61.4165 189.954C61.2784 190.378 61.1041 190.789 60.8955 191.185Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3272"
            d="M505.457 423.73C505.63 423.379 505.777 423.017 505.899 422.646V422.723C505.546 423.33 505.25 423.967 505.016 424.625C505.192 424.333 505.378 424.04 505.537 423.765M506.058 422.534L506.005 422.62V422.689L506.084 422.543"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3273"
            d="M129.552 187.364C129.295 187.64 129.031 187.906 128.766 188.173C128.835 187.81 128.936 187.453 129.066 187.106C129.291 186.775 129.545 186.464 129.825 186.177C129.622 187.295 129.622 187.295 129.552 187.364Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3274"
            d="M352.694 255.829C352.694 255.571 352.614 255.52 352.552 255.468C352.491 255.416 352.438 255.382 351.89 255.218V255.27C351.981 255.528 352.099 255.776 352.243 256.01C352.273 256.053 352.312 256.088 352.358 256.113C352.473 256.174 352.597 256.208 352.711 256.26C352.711 256.122 352.711 255.976 352.711 255.829M351.899 255.218L351.846 255.115L351.793 255.184H351.89"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3275"
            d="M48.048 243.145C48.0716 243.027 48.0697 242.906 48.0423 242.789C48.0149 242.671 47.9627 242.561 47.889 242.465C47.8361 242.461 47.783 242.461 47.7301 242.465C47.7042 242.839 47.7461 243.215 47.8537 243.575C47.9155 243.437 47.9861 243.308 48.048 243.171"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3276"
            d="M297.194 201.942L296.912 202.286L296.576 201.684C296.664 201.632 296.735 201.563 296.823 201.512C296.855 201.491 296.891 201.476 296.929 201.469C296.929 201.469 296.991 201.469 297.044 201.563C297.097 201.658 297.15 201.804 297.212 201.916"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3277"
            d="M55.6945 200.497C55.4826 200.918 55.2441 201.357 55.0234 201.736C55.0104 201.607 55.0104 201.477 55.0234 201.348C55.0852 201.211 55.1117 201.159 55.7828 199.877C55.7828 200.393 55.7828 200.393 55.6856 200.497"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3278"
            d="M351.81 255.184L351.863 255.116H351.757L351.801 255.202M351.73 255.107C351.598 254.849 351.456 254.599 351.315 254.35L350.953 254.9L351.73 255.064"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3279"
            d="M111.848 242.715L111.477 242.13V242.99L111.848 242.732M111.494 242.121V241.94L111.424 242.018L111.477 242.113"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3280"
            d="M283.816 257.826C283.71 257.869 283.622 257.938 283.516 257.981C283.467 257.994 283.415 257.994 283.366 257.981C283.313 257.981 283.286 257.92 283.251 257.869C283.215 257.817 283.154 257.645 283.092 257.542L283.48 257.163C283.595 257.378 283.728 257.593 283.842 257.808"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3281"
            d="M170.612 191.736C170.467 191.917 170.308 192.087 170.136 192.244L169.959 191.951C170.088 191.725 170.236 191.509 170.4 191.306L170.639 191.702"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3282"
            d="M135.114 489.201C135.114 489.459 135.114 489.459 135.114 489.494L134.76 489.27C134.696 489.148 134.649 489.017 134.619 488.883L135.114 489.175"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3283"
            d="M126.814 181.667L126.443 181.96C126.443 181.745 126.488 181.538 126.514 181.323L126.788 181.203C126.802 181.197 126.817 181.193 126.832 181.193C126.847 181.193 126.862 181.197 126.876 181.203C126.876 181.203 126.876 181.203 126.876 181.323C126.859 181.433 126.835 181.543 126.805 181.65"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3284"
            d="M510.156 197.278V197.234ZM509.653 196.004H509.582V196.047C509.717 196.473 509.914 196.879 510.165 197.252C510.042 196.831 509.873 196.424 509.662 196.038"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3285"
            d="M510.181 197.304H510.04C510.031 197.3 510.022 197.298 510.013 197.298C510.004 197.298 509.995 197.3 509.987 197.304C509.976 197.327 509.971 197.352 509.971 197.377C509.971 197.402 509.976 197.427 509.987 197.45C510.048 197.648 510.128 197.837 510.207 198.027C510.349 197.829 510.349 197.829 510.207 197.338"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3286"
            d="M293.291 250.744C292.849 250.985 292.849 250.985 292.708 251.011L292.479 250.572C292.635 250.405 292.825 250.271 293.035 250.176C293.035 250.176 293.079 250.236 293.158 250.4L293.326 250.727"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3287"
            d="M261.741 252.714V252.654C261.737 252.68 261.737 252.706 261.741 252.732M261.883 252.043C261.883 251.708 261.883 251.708 261.3 251.363L261.088 251.51C261.3 252.267 261.326 252.293 261.759 252.645C261.814 252.448 261.855 252.246 261.883 252.043Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3288"
            d="M140.306 495.578C140.31 495.655 140.31 495.733 140.306 495.81C140.209 495.724 140.103 495.646 140.006 495.552C139.975 495.527 139.948 495.498 139.926 495.466C139.915 495.438 139.915 495.407 139.926 495.38C139.926 495.38 139.926 495.319 139.979 495.276L140.368 495.56"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3289"
            d="M159.522 509.347L159.407 509.39C159.375 509.395 159.342 509.395 159.31 509.39L159.204 509.321L158.957 509.123C158.984 509.081 159.002 509.034 159.01 508.985L159.531 509.373"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3290"
            d="M51.3507 240.778C50.971 240.778 50.9268 240.821 50.971 240.537C51.0593 239.995 51.0593 239.995 51.6509 238.937C51.5626 239.548 51.4743 240.167 51.3948 240.778"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3291"
            d="M357.754 275.51C357.613 276.078 357.613 276.078 357.595 276.087C357.51 276.074 357.427 276.051 357.348 276.018C357.208 275.964 357.072 275.9 356.941 275.829C356.964 275.691 357 275.556 357.047 275.424C357.047 275.364 357.047 275.364 357.162 275.355C357.368 275.37 357.571 275.402 357.771 275.45V275.51"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3292"
            d="M214.314 77.7214L214.014 77.7816L214.34 77.5578L214.835 77.3857L214.332 77.7128"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3293"
            d="M152.368 465.786L152.563 465.596C152.306 465.254 152.01 464.943 151.68 464.667C151.75 465.244 151.768 465.355 152.395 465.751"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3294"
            d="M49.5058 241.579L49.7442 241.363C49.8008 240.944 49.8185 240.521 49.7972 240.098V240.15C49.444 240.942 49.3998 241.011 49.5235 241.579M49.8413 240.047V239.995Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3295"
            d="M264.011 87.0501L264.064 86.9899H264.019V87.0501M264.019 86.9813C264.09 86.5252 264.09 86.5252 263.914 86.3789L263.816 86.5252C263.816 86.9383 263.816 86.9383 264.011 86.9813"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3296"
            d="M168.538 207.914L168.167 208.207C168.167 208.207 168.167 208.207 168.105 207.535C168.203 207.492 168.291 207.441 168.388 207.406H168.494C168.556 207.406 168.565 207.484 168.565 207.518C168.573 207.647 168.573 207.776 168.565 207.905"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3297"
            d="M47.7041 242.439C47.7041 242.396 47.7041 242.362 47.7041 242.327C47.7002 242.367 47.7002 242.408 47.7041 242.448M47.7041 242.284L48.0485 241.14H47.8454C47.8454 241.518 47.7747 241.906 47.7306 242.293"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3298"
            d="M317.557 230.658V230.392L316.674 230.46L317.557 230.676M316.674 230.46H316.523V230.512H316.674"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3299"
            d="M513.626 221.984V221.597C513.456 221.282 513.242 220.993 512.99 220.736V220.788C513.255 221.605 513.291 221.708 513.635 221.949M512.955 220.684V220.727V220.676"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3300"
            d="M154.435 470.674L154.117 470.88C154.037 470.777 153.949 470.691 153.869 470.579C153.861 470.545 153.861 470.51 153.869 470.476C153.869 470.476 153.869 470.476 153.922 470.399C153.985 470.366 154.05 470.338 154.117 470.312L154.496 470.665"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3301"
            d="M321.355 235.271C321.293 234.858 321.231 234.411 321.16 234.023H321.099L320.701 234.135L321.337 235.228"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3302"
            d="M506.915 174.086L507.233 174.662L507.136 174.619C507.003 174.404 506.879 174.189 506.756 173.974C506.897 174.051 506.897 174.051 506.915 174.086Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3303"
            d="M491.038 147.022C491.338 147.417 491.338 147.417 491.338 147.435H491.285C491.285 147.435 490.967 147.022 490.949 146.979C490.984 147.002 491.014 147.031 491.038 147.065"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3304"
            d="M263.755 208.439C263.755 208.439 263.755 208.508 263.631 208.568C263.534 208.608 263.434 208.64 263.331 208.663C263.269 208.525 263.207 208.37 263.154 208.224C263.326 208.138 263.503 208.063 263.684 208C263.684 208.069 263.684 208.138 263.684 208.207C263.699 208.283 263.699 208.362 263.684 208.439"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3305"
            d="M511.771 217.457V217.414H511.674V217.457C511.717 217.977 511.89 218.479 512.177 218.92C512.123 218.424 511.989 217.939 511.78 217.483"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3306"
            d="M322.547 235.641C322.537 235.798 322.513 235.953 322.476 236.106C322.054 235.925 321.675 235.661 321.363 235.331C321.506 235.275 321.654 235.232 321.805 235.202C321.928 235.202 322.555 235.417 322.555 235.641"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3307"
            d="M145.507 477.119C145.507 477.205 145.507 477.291 145.507 477.377C145.124 477.158 144.764 476.905 144.43 476.62C144.53 476.611 144.63 476.611 144.73 476.62C144.73 476.62 145.401 476.878 145.472 477.119"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3308"
            d="M108.553 219.979V219.928C108.359 220.004 108.175 220.102 108.005 220.22C107.888 220.364 107.787 220.52 107.705 220.685C107.811 220.685 107.917 220.685 108.014 220.685C108.057 220.7 108.104 220.7 108.147 220.685C108.331 220.477 108.474 220.238 108.57 219.979M108.65 219.893V219.833L108.597 219.919H108.659"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3309"
            d="M510.579 182.485C510.702 183.01 510.702 183.01 510.676 183.027C510.589 182.966 510.512 182.894 510.446 182.812C510.379 182.621 510.326 182.425 510.287 182.227C510.287 182.175 510.57 182.433 510.57 182.433C510.574 182.45 510.574 182.468 510.57 182.485"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3310"
            d="M235.541 77.8332L235.612 77.7816ZM236.451 77.3341C236.452 77.3054 236.452 77.2767 236.451 77.248C236.132 77.4019 235.835 77.5957 235.568 77.8246C235.856 77.7219 236.127 77.5768 236.371 77.3944"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3311"
            d="M367.122 133.279C367.122 133.382 367.122 133.486 367.122 133.589L366.619 133.184C366.619 133.184 366.663 133.09 366.69 133.047C366.707 133.023 366.732 133.005 366.76 132.995C366.76 132.995 366.805 132.995 366.866 133.038C366.928 133.081 367.043 133.193 367.131 133.262"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3312"
            d="M178.013 101.163C177.938 101.188 177.861 101.208 177.783 101.224C177.88 101.112 177.983 101.006 178.092 100.905H178.154H178.251H178.366L178.066 101.232"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3313"
            d="M537.855 316.317C537.855 316.472 537.811 316.636 537.784 316.799C537.784 316.645 537.74 316.481 537.723 316.317C537.745 316.196 537.775 316.075 537.811 315.956C537.851 316.084 537.869 316.218 537.864 316.352"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3314"
            d="M523.375 329.398C523.375 329.475 523.375 329.475 523.242 329.88L523.163 329.621C523.163 329.553 523.163 329.475 523.092 329.398C523.134 329.274 523.19 329.156 523.26 329.045C523.26 329.045 523.26 329.045 523.322 329.122C523.366 329.22 523.387 329.326 523.383 329.432"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3315"
            d="M408.138 364.818C408.053 364.833 407.967 364.833 407.882 364.818C407.732 364.775 407.599 364.706 407.449 364.654C407.546 364.474 407.626 364.293 407.723 364.112C407.735 364.076 407.756 364.043 407.785 364.018C408.008 364.089 408.213 364.206 408.385 364.362L408.138 364.861"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3316"
            d="M169.632 129.845H169.588H169.641M170.18 129.905L170.471 129.475L170.056 129.655L169.659 129.827L170.188 129.888"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3317"
            d="M305.795 142.263H305.742ZM306.404 142.159C306.351 142.013 306.307 141.858 306.263 141.712L305.742 142.254L306.396 142.151"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3318"
            d="M169.28 130.293C169.35 130.233 169.35 130.233 169.562 129.88H169.509C169.172 130.078 168.855 130.309 168.564 130.568C168.564 130.568 168.564 130.568 168.564 130.852C168.812 130.654 169.077 130.482 169.306 130.259"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3319"
            d="M305.892 142.702C305.892 142.641 305.892 142.641 305.759 142.314C305.6 142.578 305.498 142.871 305.459 143.175C305.459 143.175 305.459 143.175 305.733 143.424C305.927 142.762 305.927 142.762 305.918 142.659M305.786 142.263V142.306Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3320"
            d="M174.267 98.2799L173.914 98.4261L174.108 98.2282L174.205 98.1336C174.233 98.095 174.266 98.0603 174.303 98.0303C174.379 98.0185 174.456 98.0185 174.532 98.0303C174.532 98.0303 174.532 98.0303 174.532 98.0991C174.461 98.1792 174.381 98.2514 174.294 98.3143"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3321"
            d="M530.817 302.789V302.72C530.676 302.626 530.676 302.626 530.649 302.651C530.56 302.833 530.501 303.028 530.473 303.228C530.471 303.402 530.491 303.575 530.535 303.744C530.707 303.453 530.801 303.125 530.808 302.789"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3322"
            d="M359.359 104.665C359.334 104.708 359.32 104.757 359.32 104.807C359.32 104.857 359.334 104.906 359.359 104.949C359.469 105.106 359.59 105.256 359.721 105.397C359.721 105.397 359.783 105.319 359.792 105.267C359.801 105.24 359.801 105.209 359.792 105.181C359.693 105.001 359.571 104.834 359.43 104.682M359.43 104.622H359.368L359.421 104.682"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3323"
            d="M477.121 329.354C476.983 329.484 476.828 329.597 476.662 329.69L476.512 329.148C476.549 329.088 476.591 329.03 476.635 328.976C476.732 328.864 476.847 328.769 476.944 328.657C477.015 328.881 477.077 329.105 477.148 329.328"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3324"
            d="M534.209 299.553V299.235C534.209 298.856 534.121 298.486 534.068 298.116L533.971 298.314C533.971 298.314 533.971 298.314 533.971 298.366C534.083 298.733 534.172 299.106 534.236 299.484"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3325"
            d="M359.333 104.579H359.28H359.341M359.845 104.304L359.386 104.132C359.333 104.119 359.279 104.119 359.227 104.132C359.227 104.132 359.227 104.2 359.227 104.244C359.263 104.375 359.307 104.504 359.359 104.631C359.712 104.717 359.712 104.717 359.748 104.7C359.781 104.677 359.806 104.643 359.818 104.605C359.832 104.522 359.832 104.438 359.818 104.355"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3326"
            d="M530.872 301.249C530.891 301.735 530.873 302.221 530.819 302.704C530.727 302.197 530.727 301.679 530.819 301.172H530.907V301.224"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3327"
            d="M167.927 89.8642L167.574 89.9933L167.839 89.7868C167.901 89.7438 167.945 89.6921 168.007 89.6577C168.091 89.6225 168.181 89.5993 168.272 89.5889C168.172 89.6975 168.056 89.7904 167.927 89.8642Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3328"
            d="M167.672 88.109L167.434 88.1606L167.69 87.9885C167.772 87.9235 167.861 87.8659 167.955 87.8164H168.087C168.087 87.8164 167.707 88.1004 167.672 88.109Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3329"
            d="M532.734 289.434V289.33V289.408M532.734 289.339C532.684 289.147 532.616 288.96 532.531 288.78C532.531 288.719 532.434 288.702 532.381 288.659V288.728C532.381 289.167 532.381 289.227 532.46 289.382C532.54 289.537 532.602 289.434 532.716 289.382"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3330"
            d="M406.559 330.628C406.276 330.792 405.985 330.955 405.676 331.101C405.808 331.239 405.932 331.377 406.064 331.506L406.559 330.645M406.727 330.525L406.656 330.456C406.63 330.509 406.6 330.561 406.568 330.611L406.727 330.516"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3331"
            d="M533.714 284.795C533.685 284.536 533.632 284.279 533.555 284.029C533.523 283.916 533.472 283.809 533.405 283.711V283.797C533.38 284.213 533.455 284.629 533.626 285.01C533.626 285.01 533.626 285.01 533.714 284.795Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3332"
            d="M212.795 155.326V155.378C212.799 155.352 212.799 155.326 212.795 155.3M213.625 154.577L213.519 154.268C213.016 154.509 212.989 154.629 212.839 155.292C213.155 155.121 213.427 154.883 213.634 154.595"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3333"
            d="M321.566 414.738L320.754 415.03L320.86 415.289L321.575 414.746M321.928 414.609L321.849 414.531L321.602 414.721L321.937 414.6"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3334"
            d="M168.025 113.253L167.098 113.882C167.307 113.609 167.531 113.348 167.769 113.099C167.983 112.911 168.214 112.741 168.458 112.591C168.095 113.21 168.095 113.21 168.025 113.253Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3335"
            d="M388.535 112.91C388.49 112.953 388.482 113.013 388.42 113.048C388.399 113.058 388.377 113.064 388.354 113.064C388.331 113.064 388.308 113.058 388.287 113.048C388.109 112.965 387.935 112.873 387.766 112.772C387.704 112.714 387.65 112.647 387.607 112.574C387.837 112.574 387.837 112.574 387.934 112.574C388.146 112.662 388.353 112.763 388.552 112.875"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3336"
            d="M528.514 359V359.052ZM528.796 359.121C528.867 358.802 528.796 358.794 528.734 358.811C528.71 358.826 528.688 358.847 528.673 358.871C528.615 358.927 528.562 358.988 528.514 359.052C528.526 359.19 528.555 359.326 528.602 359.456C528.602 359.456 528.602 359.456 528.717 359.456L528.796 359.095"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3337"
            d="M528.486 359.035V358.983V358.88V359.026M528.734 357.684L528.645 357.778C528.645 357.778 528.583 357.778 528.575 357.856V357.95L528.522 358.888C528.592 358.493 528.672 358.097 528.742 357.701"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3338"
            d="M423.026 90.1221C422.834 90.0157 422.633 89.9237 422.426 89.8467V89.8983C422.635 90.0303 422.857 90.1427 423.088 90.2339C423.088 90.2339 423.088 90.2339 423.088 90.1823L422.982 90.0704"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3339"
            d="M413.833 78.7369C413.688 78.6454 413.538 78.5621 413.383 78.4873L414.098 78.9176L413.807 78.7197"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3340"
            d="M533.344 351.169C533.344 351.006 533.344 350.842 533.406 350.688C533.26 351.038 533.136 351.398 533.035 351.763C533.123 351.763 533.123 351.763 533.141 351.72C533.232 351.532 533.306 351.336 533.362 351.135"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3341"
            d="M534.951 348.012L534.818 348.468C534.818 348.382 534.818 348.287 534.818 348.201C534.818 348.115 534.818 348.012 534.818 347.917C534.818 347.822 534.916 347.633 534.942 347.59C534.963 347.733 534.963 347.877 534.942 348.02"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3342"
            d="M391.086 397.613C390.954 397.682 390.839 397.768 390.707 397.837C390.647 397.865 390.585 397.888 390.521 397.905C390.46 397.776 390.389 397.613 390.336 397.484C390.511 397.284 390.709 397.105 390.928 396.95C390.998 397.165 391.051 397.372 391.122 397.587"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3343"
            d="M246.624 131.007C246.704 130.671 246.775 130.344 246.854 130.009H246.81L246.545 130.069L246.624 130.93"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3344"
            d="M424.086 90.664C423.759 90.4412 423.406 90.2563 423.035 90.1133H423.106C423.439 90.3575 423.801 90.5626 424.183 90.7243C424.183 90.6812 424.121 90.6554 424.086 90.621"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3345"
            d="M414.602 79.2023L414.188 78.9785H414.24C414.625 79.2227 415.023 79.4468 415.433 79.6497L414.602 79.1764"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3346"
            d="M205.228 126.549L204.839 126.919C204.751 126.6 204.751 126.601 204.76 126.549C204.768 126.497 204.989 126.325 205.077 126.23C205.108 126.246 205.132 126.27 205.148 126.299C205.192 126.368 205.219 126.446 205.263 126.514"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3347"
            d="M247.26 131.334C247.26 131.188 247.039 130.938 246.88 130.938C246.796 130.953 246.713 130.976 246.633 131.007C246.715 131.27 246.874 131.504 247.092 131.679C247.145 131.567 247.216 131.455 247.26 131.334Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3348"
            d="M387.494 120.534V120.473ZM387.441 120.473C387.306 120.358 387.162 120.254 387.008 120.164C386.833 120.078 386.65 120.009 386.461 119.957V120.009C386.673 120.336 386.673 120.336 386.814 120.405C387.256 120.637 387.309 120.568 387.388 120.482"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3349"
            d="M167.336 102.17C166.771 102.591 166.771 102.591 166.453 102.686C166.921 102.299 167.504 101.826 167.61 101.748C167.767 101.669 167.933 101.609 168.104 101.567L167.31 102.17"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3350"
            d="M405.764 92.3428L406.25 92.6698H406.082C406.023 92.6787 405.964 92.6787 405.905 92.6698C405.905 92.6698 405.49 92.403 405.517 92.3772C405.543 92.3514 405.702 92.3772 405.72 92.3772C405.734 92.3719 405.75 92.3719 405.764 92.3772"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3351"
            d="M156.236 93.1517H156.307L156.245 93.1947M156.307 93.1517C156.439 93.057 156.59 92.971 156.713 92.8677C156.837 92.7644 156.969 92.6096 157.102 92.4805L156.66 92.7042C156.538 92.8057 156.423 92.9149 156.316 93.0312C156.316 93.0915 156.316 93.1087 156.316 93.1431"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3352"
            d="M317.01 428.223C317.01 428.335 317.01 428.335 316.534 429.084C316.26 428.697 316.26 428.697 316.26 428.628C316.318 428.146 316.41 427.669 316.534 427.199C316.666 427.466 316.834 427.707 316.957 427.965C317.009 428.044 317.028 428.14 317.01 428.232"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3353"
            d="M392.111 384.43L392.164 384.482C392.164 384.482 392.164 384.421 392.164 384.387M393.047 383.32V383.268L392.721 382.976C392.067 383.397 392.085 383.518 392.217 384.318C392.595 384.054 392.899 383.702 393.1 383.294"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3354"
            d="M243.798 138.847C243.736 138.941 243.692 139.044 243.63 139.131C243.609 139.161 243.582 139.187 243.551 139.208C243.52 139.218 243.485 139.218 243.454 139.208C243.392 139.156 243.348 139.096 243.295 139.044L243.533 138.657L243.807 138.838"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3355"
            d="M85.4083 358.776L84.9668 359.207L85.4613 359.706V358.776M85.5143 358.699L85.4613 358.596V358.751H85.5143"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3356"
            d="M537.794 323.331C537.794 323.512 537.732 323.701 537.679 323.882C537.627 324.062 537.618 323.882 537.609 323.882C537.572 323.797 537.539 323.711 537.512 323.624C537.53 323.516 537.556 323.409 537.591 323.305L537.662 323.047V322.961C537.785 323.262 537.785 323.262 537.768 323.322"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3357"
            d="M519.833 266.681C519.833 266.56 519.78 266.431 519.745 266.311V266.354C519.697 266.578 519.668 266.805 519.656 267.033C519.672 267.219 519.702 267.403 519.745 267.584C519.745 267.498 519.833 267.429 519.868 267.352C519.876 267.3 519.876 267.248 519.868 267.197C519.874 267.022 519.85 266.848 519.798 266.681M519.718 266.25C519.718 266.25 519.718 266.199 519.718 266.173V266.302V266.242"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3358"
            d="M491.983 281.25L491.904 280.872C491.8 281.089 491.723 281.317 491.674 281.551C491.672 281.74 491.693 281.927 491.736 282.111C491.833 281.982 491.983 281.904 492.027 281.741C492.064 281.565 492.064 281.383 492.027 281.207M491.974 280.768V280.708C491.978 280.748 491.978 280.788 491.974 280.828V280.768Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3359"
            d="M519.772 266.181H519.719L519.763 266.259C519.763 266.259 519.763 266.207 519.763 266.19M520.469 265.674V265.605C520.373 265.504 520.27 265.409 520.16 265.321C520.16 265.321 520.09 265.269 520.054 265.321C520.019 265.372 519.975 265.39 519.789 266.181C520.143 266.181 520.16 266.181 520.275 266.181C520.328 266.13 520.328 266.13 520.434 265.725"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3360"
            d="M491.982 280.742H491.885V280.803L491.938 280.742M493.095 280.304V280.252C492.997 280.149 492.918 280.028 492.812 279.925C492.706 279.822 492.706 279.83 492.671 279.847C492.635 279.865 492.574 279.908 491.991 280.708C492.803 280.639 492.803 280.639 492.874 280.605C492.944 280.57 492.874 280.605 493.059 280.269"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3361"
            d="M370.69 84.8984L370.637 84.8037C370.217 84.5495 369.77 84.3417 369.303 84.1841H369.347C369.774 84.4474 370.223 84.6748 370.69 84.864M369.197 84.0895L369.25 84.1325H369.303L369.197 84.0723"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3362"
            d="M324.101 455.098L324.171 455.046H324.065V455.115M324.012 455.038C323.924 454.831 323.844 454.616 323.756 454.418C323.606 454.547 323.482 454.668 323.279 454.857L323.668 454.934L324.03 455.003"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3363"
            d="M469.465 127.685L469.765 128.021C469.794 128.05 469.821 128.082 469.845 128.115C469.845 128.115 469.845 128.115 469.704 128.253L469.465 127.995L469.403 127.917C469.392 127.858 469.392 127.796 469.403 127.737V127.573L469.5 127.685"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3364"
            d="M123.581 158.355H123.652C123.634 158.379 123.619 158.405 123.608 158.432M123.608 158.338C123.824 158.076 123.926 157.742 123.89 157.408L123.696 157.529C123.44 158.191 123.44 158.191 123.59 158.32"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3365"
            d="M367.554 82.2653C367.583 82.3064 367.61 82.3495 367.633 82.3944L367.183 82.1965C367.158 82.1777 367.137 82.1543 367.121 82.1276L366.918 81.9297C367.13 82.0243 367.342 82.1104 367.545 82.2137"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3366"
            d="M123.636 158.966C123.603 158.801 123.58 158.635 123.565 158.467L123.238 159.173L123.618 158.966M123.618 158.441L123.68 158.304H123.609V158.381"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3367"
            d="M473.059 135H473.006C473.26 135.359 473.557 135.688 473.889 135.981C473.633 135.633 473.352 135.302 473.05 134.991"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3368"
            d="M473.96 136.023H473.836C473.836 136.023 473.836 136.023 474.286 136.617C474.286 136.566 474.286 136.48 474.286 136.454C474.208 136.327 474.116 136.209 474.013 136.101"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3369"
            d="M327.686 89.6578L327.739 89.8729L327.307 89.5373C327.307 89.5373 327.307 89.4426 327.377 89.4254C327.448 89.4082 327.377 89.4254 327.483 89.477C327.589 89.5287 327.686 89.6491 327.686 89.6578Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3370"
            d="M474.87 128.959L474.543 128.632V128.683C474.825 129.068 475.154 129.418 475.523 129.725C475.294 129.467 475.073 129.208 474.834 128.959"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3371"
            d="M380.888 293.306V292.996C380.79 292.609 380.658 292.23 380.543 291.843H380.49L380.066 292.015C380.396 292.401 380.675 292.826 380.896 293.28"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3372"
            d="M428.695 283.659C428.439 283.952 428.439 283.952 428.413 283.943C428.386 283.934 428.36 283.943 428.104 283.513L428.324 283.014C428.457 283.229 428.589 283.427 428.713 283.642"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3373"
            d="M325.461 195.204L325.532 195.281V195.169M326.079 194.206V194.154L325.92 194.085C325.852 194.045 325.777 194.016 325.699 193.999C325.578 194.189 325.502 194.404 325.479 194.627C325.466 194.8 325.493 194.974 325.558 195.135C325.902 194.903 325.902 194.903 326.061 194.206"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3374"
            d="M307.782 196.727L307.87 196.452C307.632 196.383 307.384 196.297 307.146 196.211L307.755 196.71M307.111 196.193L306.89 196.013L306.828 196.099L307.102 196.193"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3375"
            d="M505.916 422.578L505.969 422.492C505.945 422.517 505.927 422.546 505.916 422.578ZM505.916 422.578C505.975 422.461 506.02 422.336 506.048 422.208C506.048 422.139 506.048 422.088 506.048 422.027V422.096L505.88 422.397C505.852 422.437 505.832 422.48 505.818 422.526C505.818 422.595 505.818 422.733 505.818 422.724C505.872 422.682 505.919 422.633 505.96 422.578"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3376"
            d="M482.579 148.485L482.526 148.399L482.367 148.296L482.57 148.476M482.349 148.278L481.828 147.822L481.961 148.037L482.34 148.27"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3377"
            d="M386.178 88.2115L386.337 88.3922L385.702 88.0738C385.702 88.0738 385.64 87.9791 385.657 87.9619C385.855 88.0245 386.042 88.1143 386.214 88.2287"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3378"
            d="M163.044 150.919L163.124 150.859L162.974 150.808L163.044 150.911M162.956 150.79L162.718 150.395L162.523 150.627L162.947 150.782"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3379"
            d="M172.397 153.123H172.309V153.183L172.388 153.123M172.918 153.037C172.918 152.908 172.918 152.778 172.971 152.649L172.397 153.097L172.918 153.019"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3380"
            d="M206.199 100.182V100.122L206.119 100.199M206.216 100.096L206.914 99.373H206.702C206.552 99.5968 206.411 99.8291 206.269 100.053"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3381"
            d="M162.789 152.013C163.036 151.29 163.036 151.29 163.009 150.963L162.965 151.015C162.804 151.19 162.68 151.395 162.603 151.617C162.543 151.75 162.498 151.888 162.471 152.031C162.471 152.031 162.471 152.031 162.541 152.031C162.612 152.039 162.683 152.039 162.753 152.031M163.08 150.886L163.001 150.946C163.027 150.917 163.051 150.885 163.071 150.852"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3382"
            d="M172.219 153.26V153.217C172.022 153.292 171.835 153.39 171.663 153.51C171.537 153.625 171.427 153.755 171.336 153.897C171.433 153.906 171.53 153.906 171.627 153.897C171.668 153.909 171.711 153.909 171.751 153.897C171.938 153.716 172.098 153.51 172.228 153.286M172.316 153.217V153.157C172.301 153.189 172.28 153.218 172.254 153.243H172.316"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3383"
            d="M154.629 152.082L154.7 152.03V152.108M154.7 152.013C154.801 151.88 154.871 151.728 154.908 151.566C154.944 151.404 154.946 151.237 154.912 151.075L154.709 151.196C154.506 151.85 154.506 151.875 154.709 151.996"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3384"
            d="M82.539 304.398L82.2211 304.579C82.2211 304.321 82.1681 304.071 82.1328 303.813C82.2299 303.813 82.3182 303.77 82.4065 303.813C82.4949 303.856 82.4948 303.891 82.5125 303.959C82.536 304.105 82.5507 304.251 82.5567 304.398"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3385"
            d="M207.267 97.7034L206.799 98.0304C206.812 97.9577 206.83 97.8859 206.852 97.8152C206.868 97.7686 206.892 97.7249 206.922 97.6861C206.951 97.6558 206.983 97.6297 207.02 97.6087C207.117 97.5312 207.223 97.4624 207.329 97.3936C207.329 97.4882 207.329 97.5829 207.329 97.6775H207.284"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3386"
            d="M398.708 319.234L398.77 319.131H398.443L398.691 319.217M398.399 319.131L397.578 318.855V319.157H398.399"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3387"
            d="M133.013 146.549C132.705 146.889 132.361 147.198 131.988 147.47C132.092 147.286 132.213 147.114 132.35 146.953C132.539 146.754 132.746 146.573 132.968 146.411L133.048 146.506"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3388"
            d="M513.043 403.112C513.149 402.802 513.246 402.492 513.352 402.174V402.234C513.21 402.526 513.06 402.819 512.91 403.094H513.069"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3389"
            d="M52.9303 284.365C52.9254 284.232 52.8923 284.101 52.833 283.981C52.7737 283.861 52.6896 283.755 52.5859 283.668H52.3828C52.4442 284.067 52.5788 284.452 52.7802 284.804C52.8381 284.663 52.8882 284.52 52.9303 284.374"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3390"
            d="M460.838 115.379C460.838 115.379 460.75 115.379 460.758 115.379C460.767 115.379 460.758 115.379 461.306 115.912C461.253 115.792 461.253 115.792 460.882 115.431"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3391"
            d="M97.3734 123.227L97.082 123.391C97.2145 123.253 97.3469 123.107 97.4971 122.978C97.6472 122.849 97.4971 122.978 97.7178 122.857L97.3999 123.219"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3392"
            d="M52.349 283.651V283.547V283.676M52.349 283.504V283.547C52.4108 283.143 52.4638 282.738 52.5256 282.334C52.4404 282.342 52.3547 282.342 52.2695 282.334C52.2695 282.738 52.349 283.143 52.3843 283.547"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3393"
            d="M114.222 302.678C114.098 302.678 113.983 302.678 113.86 302.678C113.786 302.683 113.713 302.669 113.648 302.635C113.648 302.635 113.648 302.635 113.648 302.506C113.648 302.377 113.648 302.23 113.604 302.093C114.036 301.955 114.036 301.955 114.195 301.955C114.195 301.955 114.195 301.955 114.195 302.05C114.195 302.144 114.239 302.446 114.275 302.644"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3394"
            d="M464.247 118.951L465.201 119.811C464.892 119.545 464.565 119.278 464.265 118.994C464.265 118.994 464.265 118.925 464.221 118.891"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3395"
            d="M463.433 116.903L463.742 117.212C463.592 117.092 463.442 116.98 463.301 116.851L463.098 116.636C463.214 116.722 463.326 116.814 463.433 116.911"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3396"
            d="M465.209 119.854C465.339 120.029 465.484 120.193 465.642 120.345C465.696 120.401 465.755 120.453 465.818 120.5C465.677 120.254 465.499 120.03 465.288 119.837"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3397"
            d="M93.5852 123.606V123.546L93.5059 123.632H93.5852M93.5852 123.753L93.8056 123.537L93.5764 123.623C93.5721 123.663 93.5721 123.704 93.5764 123.744"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3398"
            d="M50.9525 277.128V276.792L50.873 275.622H51.138C51.1216 276.132 51.0536 276.64 50.9349 277.137"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            id="Vector_3399"
            d="M309.716 101.31V101.267H309.672V101.31M309.628 101.267C309.257 100.819 309.24 100.793 309.089 100.802C309.046 100.81 309.001 100.81 308.957 100.802C309.043 100.953 309.153 101.09 309.284 101.206C309.321 101.233 309.365 101.252 309.41 101.261C309.456 101.27 309.503 101.269 309.549 101.258H309.61"
            fill="white"
            fill-opacity="0.3"
          />
        </g>
        <g id="lines">
          <path
            id="curve10"
            d="M526.56 393.19C566.05 411.89 473.175 452.7 344.752 385.39"
            stroke="#DEFFFF"
            stroke-miterlimit="10"
          />
          <path
            id="curve9"
            d="M120.5 169C79.4998 176.41 82.0305 215.13 120.501 246"
            stroke="#DEFFFF"
            stroke-miterlimit="10"
          />
          <path
            id="curve8"
            d="M324.37 191.415C249.154 132.002 100.976 146.544 80.032 166.553"
            stroke="#DEFFFF"
            stroke-miterlimit="10"
          />
          <path
            id="curve7"
            d="M465.379 212.735C502.905 143.916 386.742 68.96 324.048 137.489"
            stroke="#DEFFFF"
            stroke-miterlimit="10"
          />
          <path
            id="curve6"
            d="M428.535 189.764C508.3 162.074 547.61 249.246 515.619 292.845"
            stroke="#DEFFFF"
            stroke-miterlimit="10"
          />
          <path
            id="curve5"
            d="M112.333 194.667C-37.8079 200.015 -57.4027 477.999 187.138 507.293"
            stroke="#DEFFFF"
            stroke-miterlimit="10"
          />
          <path
            id="curve4"
            d="M120.326 117.768C30.8349 110.935 -18.7933 220.727 79.6083 269.652"
            stroke="#DEFFFF"
            stroke-miterlimit="10"
          />
          <path
            id="curve3"
            d="M368.422 84.3898C326.5 -59.4998 205.809 13.8892 205.809 77.0497"
            stroke="#DEFFFF"
            stroke-miterlimit="10"
          />
          <path
            id="curve2"
            d="M80.5 166C143.713 121.14 298.882 131.176 361.828 174.866"
            stroke="#DEFFFF"
            stroke-miterlimit="10"
          />
          <path
            id="curve1"
            d="M422.613 93.3897C388.5 -51.0008 260 22.8893 260 86.0496"
            stroke="#DEFFFF"
            stroke-miterlimit="10"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_352_87"
          x="-256.299"
          y="-285.609"
          width="710"
          height="659"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="100"
            result="effect1_foregroundBlur_352_87"
          />
        </filter>
        <clipPath id="clip0_352_87">
          <rect
            x="25.7012"
            y="40.3906"
            width="521.803"
            height="507"
            rx="253.5"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_352_87">
          <rect
            width="564"
            height="434"
            fill="white"
            transform="translate(1 75.3896)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Globe;
